<template>
  <main>
    <top-bar />
    <div id="main-container" class="main-container-custom">
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
import TopBar from "../components/TopBar.vue";

import { mapState } from "vuex";

export default {
  name: "ContentView",
  components: {
    TopBar,
  },
  data: () => ({
    routes: {},
  }),
  computed: {
    ...mapState("config", {
      fullScreen: (state) => state.fullScreen,
    }),
  },
  created() {
    this.routes = this.$router.options.routes;
  },
};
</script>
