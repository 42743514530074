<template>
  <div class="custom-backdrop"></div>
</template>
<script>
export default {
  name: "Backdrop",
};
</script>
<style scoped>
.custom-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
</style>
