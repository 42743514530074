<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("overpacksPage.updateTrackingStatus") }}
      </ModalTitle>
    </ModalHeader>

    <NbSelectInput
      v-model="form.trackingCode"
      :options="trackingOptions"
      :name="$t('overpacksPage.selectOneStatus')"
      id="tracking-status"
    />

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
      <NbButton
        @click="submit"
        class="ml-2"
        :disabled="loading || !formIsValid"
      >
        {{ $t("overpacksPage.updateInformation") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import OverpackService from "@/services/OverpackService";

const overpackService = new OverpackService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbSelectInput,
  },
  props: {
    overpackIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      errors: [],
      form: {
        trackingCode: "",
      },
    };
  },
  computed: {
    formIsValid() {
      return !!this.form.trackingCode;
    },
    trackingData() {
      return [
        {
          code: 617,
          description: this.$t(
            "overpacksPage.trackingOptions.forwardedlocalhub",
          ),
        },
        {
          code: 602,
          description: this.$t(
            "overpacksPage.trackingOptions.arrivedHubSorting",
          ),
        },
        {
          code: 605,
          description: this.$t(
            "overpacksPage.trackingOptions.clearanceCompleted",
          ),
        },
        {
          code: 607,
          description: this.$t(
            "overpacksPage.trackingOptions.shipmentArrivedDestinationCountry",
          ),
        },
        {
          code: 608,
          description: this.$t(
            "overpacksPage.trackingOptions.handedCustomsCustomsClearance",
          ),
        },
        {
          code: 610,
          description: this.$t(
            "overpacksPage.trackingOptions.customsClearanceCompleted",
          ),
        },
        {
          code: 616,
          description: this.$t(
            "overpacksPage.trackingOptions.arrivedHubSortingCenter",
          ),
        },
      ];
    },
    trackingOptions() {
      return this.trackingData.map((item) => ({
        text: `${item.code} - ${item.description}`,
        value: item.code,
      }));
    },
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        await overpackService.updateManyStatus({
          overpack_ids: this.overpackIds,
          tracking_code: this.form.trackingCode,
        });

        this.$emit("success");
        this.context?.close();
      } catch (error) {
        if (
          Object.prototype.hasOwnProperty.call(error, "response") &&
          Object.prototype.hasOwnProperty.call(error.response, "data") &&
          Object.prototype.hasOwnProperty.call(error.response.data, "messages")
        ) {
          this.errors = error.response.data.messages[0];
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
