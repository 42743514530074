<template>
  <div>
    <Datatable :data="order.volumes" :columns="columns">
      <template #items_amount="{ row }">
        {{ row.order_items.length }}
      </template>

      <template #freight_value="{ row }">
        {{ order.currency }} {{ row.freight_value }}
      </template>

      <template #products_value="{ row }">
        {{ order.currency }} {{ row.products_value }}
      </template>

      <template #weight="{ row }"> {{ row.weight }} kg </template>

      <template #collected_icms="{ row }">
        {{ order.collected_taxes_currency }} {{ row.collected_icms }}
      </template>

      <template #collected_ii="{ row }">
        {{ order.collected_taxes_currency }} {{ row.collected_ii }}
      </template>

      <template #actions="{ row }">
        <div class="d-flex gap-2">
          <NbButton
            variant="quaternary"
            @click="navigateTo(`/shipping/volumes?id=${row.id}`)"
          >
            <NbIcon icon="link" />
            {{ $t("ordersViewPage.goToVolume") }}
          </NbButton>
        </div>
      </template>
    </Datatable>
    <Modal v-model="isDimensionsModalOpen">
      <ModalHeader>
        <ModalTitle>{{ $t("ordersViewPage.dimensions") }}</ModalTitle>
      </ModalHeader>

      <BoxDimensions
        :height="selectedItem.height"
        :width="selectedItem.width"
        :depth="selectedItem.length"
        :weight="selectedItem.weight"
        :description="$t('ordersViewPage.informedBySeller')"
      />

      <ModalFooter class="d-flex justify-content-end">
        <ModalClose />
      </ModalFooter>
    </Modal>

    <Modal v-model="isCostsModalOpen">
      <ModalHeader>
        <ModalTitle>{{ $t("ordersViewPage.costs") }}</ModalTitle>
      </ModalHeader>

      <div class="costs grid grid-cols-3 gap-4">
        <div class="info">
          <small>Unit value</small>
          <span>
            {{ order.currency }} {{ selectedItem?.unit_products_value || "-" }}
          </span>
        </div>
        <div class="info">
          <small>Unit tax value</small>
          <span
            >{{ order.currency }}
            {{ selectedItem?.unit_tax_value || "-" }}</span
          >
        </div>
        <div class="info">
          <small>Unit freight value</small>
          <span>
            {{ order.currency }} {{ selectedItem?.unit_freight_value || "-" }}
          </span>
        </div>
      </div>

      <ModalFooter class="d-flex justify-content-end">
        <ModalClose />
      </ModalFooter>
    </Modal>

    <Modal v-model="isFullDescriptionModalOpen">
      <ModalHeader>
        <ModalTitle>{{ $t("ordersViewPage.itemDescription") }}</ModalTitle>
      </ModalHeader>
      <p class="body-04">{{ selectedItem.description }}</p>
      <ModalFooter class="d-flex justify-content-end">
        <ModalClose />
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import Datatable from "@/components/datatable/Datatable.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import BoxDimensions from "../../../../../components/BoxDimensions.vue";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    Datatable,
    NbButton,
    NbIcon,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    BoxDimensions,
  },
  data() {
    return {
      isDimensionsModalOpen: false,
      isCostsModalOpen: false,
      isFullDescriptionModalOpen: false,
      selectedItem: {},
    };
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t("ordersViewPage.orderReference"),
          key: "reference",
        },
        {
          label: this.$t("ordersViewPage.volumesFields.lastMileTrackingNumber"),
          key: "last_mile_tracking_number",
        },
        {
          label: this.$t("ordersViewPage.volumesFields.lastMileCarrierNumber"),
          key: "last_mile_carrier_number",
        },
        {
          label: this.$t("weight"),
          key: "weight",
        },
        {
          label: this.$t("ordersViewPage.volumesFields.productsValue"),
          key: "products_value",
        },
        {
          label: this.$t("ordersViewPage.volumesFields.freightValue"),
          key: "freight_value",
        },
        {
          label: this.$t("ordersViewPage.volumesFields.itemsAmount"),
          key: "items_amount",
        },
        {
          label: this.$t("ordersViewPage.volumesFields.collectedIcms"),
          key: "collected_icms",
        },
        {
          label: this.$t("ordersViewPage.volumesFields.collectedII"),
          key: "collected_ii",
        },
        {
          label: "",
          key: "actions",
        },
      ];
    },
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
    showDimensionsModal(item) {
      this.selectedItem = item;
      this.isDimensionsModalOpen = true;
    },
    showCostsModal(item) {
      this.selectedItem = item;
      this.isCostsModalOpen = true;
    },
    showFullDescriptionModal(item) {
      this.selectedItem = item;
      this.isFullDescriptionModalOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.costs {
  .info {
    display: flex;
    flex-direction: column;
    small {
      font-weight: 700;
      font-size: 0.75rem;
    }
    span {
      font-size: 0.875rem;
      line-height: 1.2;
    }
  }
}
.truncate.item-name {
  max-width: 10rem;
}
</style>
