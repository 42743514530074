<template>
  <div class="header-title-wrapper filters-wrapper">
    <div class="d-flex justify-content-between align-items-start">
      <div class="d-flex header-title">
        <back-button />
        <h4 class="text-uppercase mb-4 ml-4 page-title">{{ title }}</h4>
        <span
          ><div
            v-for="badge in badges"
            :key="badge.id"
            :class="`badge badge-${badge.variant || 'success'} ml-2`"
          >
            {{ badge.value }}
          </div></span
        >
      </div>
      <div class="buttons-action-wrapper">
        <slot name="buttonsTop"></slot>
      </div>
      <div v-if="logoUrl" class="buttons-action-wrapper">
        <img :src="logoUrl" height="128px" width="128px" class="icon mr-5" />
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton";

export default {
  name: "HeaderTitle",
  components: {
    BackButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    badges: {
      type: Array,
      required: false,
      default: () => [],
    },
    logoUrl: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
