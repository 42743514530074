<template>
  <div>
    <NbPageTitle
      :title="$t('downloadsPage.title')"
      :subTitle="$t('downloadsPage.subtitle')"
      class="mb-4"
    />

    <Datatable class="mt-5" :columns="tableColumns" :data="downloads">
      <template #file_type="{ row }">
        <span v-if="row.data_type == 'order_import'">Upload</span>
        <span v-else>Download</span>
      </template>

      <template #status="{ row }">
        <div>
          <NbBadge
            class="fit-content"
            :type="badgeType(row.status)"
            :text="badgeText(row.status)"
          />
        </div>
      </template>

      <template #file_name="{ row }">
        <span>{{ row.file_name ? row.file_name : "-" }}</span>
      </template>

      <template #errors="{ row }">
        <div
          v-if="
            row.df_errors.length > 0 &&
            !row.file_name.includes('volumes_mass_update')
          "
          variant="danger"
          class="link-1"
          title="Show Errors"
          data-toggle="modal"
          data-target="#showErrors"
          @click="currentErrors = row.df_errors"
        >
          {{ $t("downloadsPage.showErrors") }}
        </div>
      </template>
      <template #download="{ row }">
        <NbButton
          v-if="
            row.status == 'failed' &&
            row.file_name.includes('volumes_mass_update')
          "
          class="d-flex align-items-center gap-1"
          size="sm"
          variant="secondary"
          @click="downloadFiles(row.id)"
          ><NbIcon icon="arrow-down" :size="18" />
          {{ $t("downloadsPage.downloadReport") }}</NbButton
        >
        <NbButton
          v-else-if="
            !(row.data_type == 'volumes_mass_update') && row.status == 'done'
          "
          class="d-flex align-items-center gap-1"
          size="sm"
          variant="secondary"
          @click="downloadFiles(row.id)"
          ><NbIcon icon="arrow-down" :size="18" />
          {{ $t("downloadsPage.downloadReport") }}</NbButton
        >
      </template>
    </Datatable>

    <NbModal id="showErrors" modalConfig="modal-dialog-centered" width="46rem">
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">{{ $t("downloadsPage.errors") }}</h5>
        </div>
      </template>
      <div>
        <div
          v-for="currentError in currentErrors"
          :key="currentError"
          class="border-bottom pb-2 mb-2"
        >
          - {{ currentError }}
        </div>
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton variant="secondary" data-dismiss="modal">
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import Datatable from "@/components/datatable/Datatable.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import DownloadsService from "@/services/DownloadsService";
import NbIcon from "../components/icons/NbIcon.vue";

const downloadsService = new DownloadsService();

export default {
  name: "Downloads",
  components: {
    NbPageTitle,
    Datatable,
    NbBadge,
    NbModal,
    NbButton,
    NbIcon,
  },
  data: () => {
    return {
      downloads: [],
      currentErrors: null,
      namespace: "downloaded_files",
      loaded: false,
      setIntervalControl: null,
    };
  },
  created() {
    this.getData();
    this.loadItems();
  },
  beforeDestroy() {
    clearInterval(this.setIntervalControl);
  },

  methods: {
    badgeType(status) {
      if (status == "failed") {
        return "danger";
      }

      if (
        status == "processing" ||
        status == "starting_upload" ||
        status == "checking_csv_format" ||
        status == "checking_columns" ||
        status == "checking_equal_orders_volumes" ||
        status == "checking_cells" ||
        status == "validation_success"
      ) {
        return "warning";
      }

      return "success";
    },
    badgeText(status) {
      const statuses = {
        done: this.$t("downloadsPage.status.done"),
        failed: this.$t("downloadsPage.status.failed"),
        processing: this.$t("downloadsPage.status.processing"),
        starting_upload: this.$t("downloadsPage.status.starting_upload"),
        checking_csv_format: this.$t(
          "downloadsPage.status.checking_csv_format",
        ),

        checking_columns: this.$t("downloadsPage.status.checking_columns"),
        checking_equal_orders_volumes: this.$t(
          "downloadsPage.status.checking_equal_orders_volumes",
        ),
        checking_cells: this.$t("downloadsPage.status.checking_cells"),
        validation_success: this.$t("downloadsPage.status.validation_success"),
      };

      return statuses?.[status] || "Finished";
    },
    loadItems() {
      this.setIntervalControl = setInterval(() => {
        this.getData();
      }, 8000);
    },
    getData() {
      downloadsService.getDownloads().then((response) => {
        this.downloads = response.data.data;
        this.loaded = false;
        this.downloads.forEach((element, index) => {
          let time = element.created_at;
          let date = element.created_at;
          this.downloads[index].created_at = this.formateDate(date, time);
        });
      });
    },
    importPage(Id) {
      this.$router.push({
        path: "/orders/import_response",
        query: { orders: Id },
      });
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    downloadFiles(fileID) {
      downloadsService.generateLink(fileID).then((response) => {
        let csv = response.data.data.link;
        let filename = response.data.data.file_name;
        let anchor = document.createElement("a");
        anchor.href = csv;
        anchor.target = "_blank";
        anchor.download = filename;
        anchor.click();
      });
    },
  },
  computed: {
    tableColumns() {
      return [
        {
          key: "id",
          label: "ID",
          sortable: false,
        },
        {
          key: "data_type",
          label: this.$t("downloadsPage.fields.type"),
          sortable: false,
        },
        { key: "file_type", label: "File Type", sortable: false },
        {
          key: "status",
          label: this.$t("downloadsPage.fields.status"),
          sortable: false,
        },
        {
          key: "file_name",
          label: this.$t("downloadsPage.fields.fileName"),
          sortable: false,
        },
        {
          key: "errors",
          label: this.$t("downloadsPage.fields.messages"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "created_at",
          label: this.$t("downloadsPage.fields.creationDate"),
          sortable: false,
        },
        {
          key: "download",
          label: this.$t("actions"),
          class: "right--20 right-sticky",
          isCustomizable: false,
        },
      ];
    },
  },
};
</script>
