<template>
  <div>
    <div class="accessibility">
      <NbPageTitle
        :title="$t('pintingSettingsPage.title')"
        :subTitle="$t('pintingSettingsPage.subtitle')"
      />

      <div class="content">
        <div class="boxes">
          <NbCardBox class="w-49-5">
            <NbRadio
              v-for="printingInput in printingsInputs"
              :key="printingInput.key"
              :id="printingInput.key + 'radio'"
              class="my-2"
              :name="printingInput.name"
              :options="zplPdfOptions"
              v-model="printingSetting[printingInput.key]"
              @change="updatePrinting()"
            />
          </NbCardBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbCardBox from "@/components/cards/NbCardBox.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";
import PrintingService from "@/services/PrintingService";
import { eventBus, Events } from "../../utils/event-bus";

const printingService = new PrintingService();
export default {
  name: "PrintingSetting",
  components: {
    NbPageTitle,
    NbCardBox,
    NbRadio,
  },
  data: function () {
    return {
      printingSetting: {
        zpl_packing_slip: null,
        ceva_battery_confirmation: null,
        zpl_commercial_invoice: null,
        zpl_label: null,
      },

      zplPdfOptions: [
        { text: "ZPL", value: true },
        { text: "PDF", value: false },
      ],
    };
  },
  beforeMount() {
    this.getPrinting();
  },
  methods: {
    getPrinting() {
      printingService.getPrinting().then((response) => {
        const printingSetting = response.data.data;
        this.printingSetting = {
          zpl_packing_slip: printingSetting.zpl_packing_slip,
          ceva_battery_confirmation: printingSetting.ceva_battery_confirmation,
          zpl_commercial_invoice: printingSetting.zpl_commercial_invoice,
          zpl_label: printingSetting.zpl_label,
        };
      });
    },
    async updatePrinting() {
      try {
        eventBus.$emit(Events.AUTO_SAVE, true);
        await printingService.updatePrinting(this.printingSetting);
      } finally {
        eventBus.$emit(Events.AUTO_SAVE, false);
      }
    },
  },
  computed: {
    printingsInputs() {
      return [
        { key: "zpl_label", name: this.$t("pintingSettingsPage.cN23Label") },
        {
          key: "zpl_commercial_invoice",
          name: this.$t("pintingSettingsPage.commercialInvoice"),
        },
        {
          key: "zpl_packing_slip",
          name: this.$t("pintingSettingsPage.packingSlip"),
        },
        {
          key: "ceva_battery_confirmation",
          name: this.$t("pintingSettingsPage.cevaBatteryShipperConfirmation"),
        },
      ];
    },
  },
};
</script>
