<template>
  <div v-if="isMounted">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isMounted: false,
    };
  },
  mounted() {
    this.isMounted = true;
    const destination = document.querySelector(this.to);
    destination.appendChild(this.$el);
  },
};
</script>

<style lang="scss" scoped></style>
