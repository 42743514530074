import Platforms from "../../views/platforms/Platforms";
//import PlatformView from "../../views/platforms/View"
import store from "@/store/index";

const isSuper = store.state.user.is_super;

// const platform_view = {
//   path: '/platforms/:id',
//   name: 'platform_view',
//   component: PlatformView,
//   meta: {
//     visible: false,
//     label: 'Platform View',
//   }
// }

const platforms = {
  path: "/platforms",
  name: "platforms",
  component: Platforms,
  meta: {
    visible: isSuper,
    label: "Platforms",
    icon: "cpu",
  },
};

export default platforms;
