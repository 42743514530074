<template>
  <div>
    <ModalHeader>
      <div>
        <ModalTitle>
          {{ $t("masterboxesPage.createMasterbox") }}
        </ModalTitle>
        <p>{{ $t("masterboxesPage.findAndSelectVolumes") }}</p>
      </div>
    </ModalHeader>

    <div>
      <div class="mx-4">
        <div v-if="errorsFromMasterboxCreation.length > 0" class="">
          <div
            v-for="(erro, index) in errorsFromMasterboxCreation"
            :key="index"
          >
            <p class="text-danger pre-formatted" v-html="erro"></p>
          </div>
          <div v-if="masterboxErrorJson">
            <NbButton variant="tertiary" @click="downloadMasterboxErrors">
              <i class="fas fa-download"></i>
              {{ $t("masterboxesPage.downloadMasterboxErrors") }}
            </NbButton>
            <small class="d-block">
              {{ $t("masterboxesPage.commentDownloadMasterboxErrors") }}
            </small>
          </div>
        </div>
        <div v-else>
          <p class="body-4">{{ successMessage }}</p>
          <NbButton v-copy="requestId" v-if="requestId" class="mt-2 mb-4">
            {{ $t("clickToCopy") }}
          </NbButton>
        </div>
      </div>

      <template v-if="!successMessage">
        <NbCardBox id="select-volumes" class="mx-4 mb-4">
          <template>
            <NbListbox
              :placeholder="`${$t(
                'masterboxesPage.typeVolumeReference',
              )}: ex: NB000000000BR`"
              :name="$t('masterboxesPage.selectVolumes')"
              :options="volumes"
              :selected="selectedVolumeIds"
              @select="appendVolume"
              @search="loadVolumes"
              keyLabel="reference"
              :loading="fetching"
            >
            </NbListbox>
          </template>
        </NbCardBox>
        <NbListboxTable
          v-if="selectedVolumes.length"
          :title="$t('masterboxesPage.selectedVolumes')"
        >
          <template #header>
            <th>ID</th>
            <th>{{ $t("masterboxesPage.reference") }}</th>
            <th>{{ $t("actions") }}</th>
          </template>

          <template #body>
            <tr v-for="(item, index) in selectedVolumes" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.reference }}</td>
              <td>
                <NbButton variant="tertiary" @click="removeVolume(index)">
                  {{ $t("remove") }}
                </NbButton>
              </td>
            </tr>
          </template>
        </NbListboxTable>
      </template>
    </div>
    <ModalFooter class="d-flex justify-content-end gap-2">
      <ModalClose />

      <NbButton
        v-if="!successMessage"
        @click="createMasterbox()"
        :disabled="!selectedVolumes.length || loading"
        class="ml-2"
      >
        {{ $t("save") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbCardBox from "@/components/cards/NbCardBox.vue";
import HttpService from "../../../../services/GlobalService";
import MasterboxService from "../../../../services/MasterBoxService";
import DownloadsService from "../../../../services/DownloadsService";
import { copyClipboard } from "@/directives/copy-clipboard";
import NbListbox from "../../../../components/listbox/NbListbox.vue";
import NbListboxTable from "../../../../components/listbox/NbListboxTable.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";

const httpService = new HttpService();
const masterboxService = new MasterboxService();
const downloadsService = new DownloadsService();

export default {
  components: {
    NbButton,
    NbCardBox,
    NbListbox,
    NbListboxTable,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
  },
  directives: {
    copy: copyClipboard,
  },
  inject: ["context"],
  data() {
    return {
      volumes: [],
      selectedVolumes: [],
      fetchedVolumes: false,
      search: "",
      loading: false,
      fetching: false,
      errorsFromMasterboxCreation: [],
      masterboxErrorJson: null,
      requestId: null,
      successMessage: "",
    };
  },
  watch: {
    search(value) {
      if (!value) {
        this.volumes = [];
        this.fetchedVolumes = false;
      }
    },
  },
  computed: {
    selectedVolumeIds() {
      return this.selectedVolumes.map((item) => item.id);
    },
  },
  methods: {
    appendVolume(volume) {
      if (!this.selectedVolumes.find((item) => item.id === volume.id)) {
        this.selectedVolumes.push(volume);
      }
    },
    initiateFileCreation(dataQuery) {
      downloadsService.loadProcessing(dataQuery).then(() => {
        this.closeModal();
        this.$router.push("/archives");
      });
    },
    downloadMasterboxErrors() {
      if (this.masterboxErrorJson) {
        this.initiateFileCreation({
          data_type: "masterbox_errors",
          reference_ids: this.selectedVolumes.map((item) => item.id),
          error_json: this.masterboxErrorJson,
        });
      }
    },
    removeVolume(index) {
      this.selectedVolumes.splice(index, 1);
    },
    clearErrors() {
      this.successMessage = "";
      this.errorsFromMasterboxCreation = [];
      this.request_id = null;
    },
    async createMasterbox() {
      try {
        this.clearErrors();
        this.loading = true;
        const response = await masterboxService.createMasterbox(
          this.selectedVolumes.map((item) => item.id),
        );

        this.requestId = response.data.data.request_id;
        this.successMessage = response.data.messages[0].replace(
          "request_id",
          this.requestId,
        );

        this.$emit("success");
      } catch (error) {
        if (error && error.response && error.response.data) {
          if (error.response.data.messages) {
            this.errorsFromMasterboxCreation = error.response.data.messages;
          }
          if (error.response.data.data && error.response.data.data.error_json) {
            this.masterboxErrorJson = error.response.data.data.error_json;
          }
        }
      } finally {
        this.loading = false;
      }
    },
    closeModal() {
      this.context?.close();
    },
    async loadVolumes(search = "") {
      try {
        this.volumes = [];
        this.fetchedVolumes = false;
        this.fetching = true;
        const { data } = await httpService.getTwentyItems("volumes", 1, 20, {
          volume: {
            reference: search,
          },
        });

        this.volumes = data.data.elements;
        this.fetchedVolumes = true;
      } catch (error) {
        const message =
          error?.response?.data?.messages?.[0] ||
          this.$t("errorMessages.genericError");
        this.$bvToast.toast(message, {
          variant: "danger",
        });
      } finally {
        this.fetching = false;
      }
    },
  },
  mounted() {
    this.$helpers.openModal("masterbox-create-modal");
  },
};
</script>

<style lang="scss" scoped></style>
