import api from "./HttpService";

export default class OrderCostService {
  /**
   * Get order
   *
   * @param {*} id
   * @returns {Promise}
   */
  getOrderCost(id = null) {
    return api.get("/v1/order_costs/" + id);
  }

  /**
   * Get orders
   *
   * @param filters
   * @returns {Promise}
   */
  getOrderCosts(filters = {}) {
    return api.get("/v1/order_costs", {
      params: filters,
    });
  }

  /**
   * Set order
   *
   * @param data
   * @returns {Promise}
   */
  setOrderCost(data = {}) {
    return api.post("/v1/order_costs", data);
  }

  /**
   * Update order
   *
   * @param {*} id
   * @param {*} data
   * @returns {Promise}
   */
  updateOrderCost(id = null, data = {}) {
    return api.put("/v1/order_costs/" + id, data);
  }

  /**
   * Delete order
   *
   * @param {*} id
   * @returns {Promise}
   */
  deleteOrderCost(id = null) {
    return api.delete("/v1/order_costs/" + id);
  }

  /**
   * Get Total tariffs
   *
   * @param filters
   * @returns {Promise}
   */
  getTariffs() {
    return api.get("/v1/list_tariffs");
  }
}
