<template>
  <div>
    <NbModal
      :id="id"
      :preventClose="true"
      modalConfig="modal-dialog-centered"
      :zindex="zindex"
      width="76rem"
    >
      <template v-slot:header>
        <div class="d-flex mb-4">
          <div>
            <h5 class="heading-4 ml-3 mb-1">
              {{ `${$t("nobordistCheckpointsViewPage.title")} ${itemId}` }}
            </h5>
            <p class="mb-0 ml-3 body-4">
              {{ $t("checkpointsPage.subTitleView") }}
            </p>
          </div>
        </div>
      </template>
      <div>
        <NbCard
          id="checkpoint_chard"
          :title="$t('generalInformation')"
          class="bg-gray-05"
        >
          <template #body>
            <div class="grid grid-cols-2 gap-4">
              <NbTextInput
                id="tracking_checkpoint_code"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('nobordistCheckpointsViewPage.trackingCode')"
                v-model="checkpoint.tracking_code"
                disabled
              />
              <NbTextInput
                id="checkpoint_type"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('nobordistCheckpointsViewPage.type')"
                v-model="checkpoint.type"
                disabled
              />
              <div class="col-span-2 grid grid-cols-3 gap-4">
                <NbTextInput
                  id="checkpoint_slug"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  :name="$t('nobordistCheckpointsViewPage.slug')"
                  v-model="checkpoint.slug"
                  disabled
                />

                <div class="truncate">
                  <span class="input-label">
                    {{ $t("nobordistCheckpointsPage.fields.finalStatus") }}
                  </span>
                  <div class="input-view">
                    <IsCheckedIcon :checked="checkpoint.final_status" />
                  </div>
                </div>

                <div class="truncate">
                  <span class="input-label">
                    {{ $t("nobordistCheckpointsPage.fields.category") }}
                  </span>
                  <div class="input-view">
                    {{ getZoneCodeName(checkpoint.zone_code) }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </NbCard>

        <NbCard title="" id="english-info" class="bg-gray-05 my-4">
          <div class="d-flex align-items-start gap-2">
            <img src="/flags/gb.png" alt="" width="24" class="mt-1" />
            <h4 class="heading-3">English Info</h4>
          </div>

          <template #body>
            <div class="grid grid-cols-2 gap-4">
              <NbTextInput
                id="titleEN"
                variant="borderless-gray-10"
                placeholder="-"
                :name="$t('nobordistCheckpointsPage.fields.title')"
                v-model="checkpoint.title"
                disabled
              />
              <NbTextInput
                id="nameEN"
                variant="borderless-gray-10"
                placeholder="-"
                :name="$t('nobordistCheckpointsPage.fields.name')"
                v-model="checkpoint.name"
                disabled
              />
              <NbTextInput
                id="descriptionEN"
                variant="borderless-gray-10"
                placeholder="-"
                class="col-span-2"
                :name="$t('nobordistCheckpointsPage.fields.description')"
                v-model="checkpoint.description"
                disabled
              />
            </div>
          </template>
        </NbCard>

        <NbCard title="" id="portugues-info" class="bg-gray-05">
          <div class="d-flex align-items-start gap-2">
            <img src="/flags/br.png" alt="" width="24" />
            <h4 class="heading-3">Portuguese Info</h4>
          </div>
          <template #body>
            <div class="grid grid-cols-2 gap-4">
              <NbTextInput
                id="titlePT"
                variant="borderless-gray-10"
                placeholder="-"
                :name="$t('nobordistCheckpointsPage.fields.title')"
                v-model="checkpoint.title_pt"
                disabled
              />
              <NbTextInput
                id="namePT"
                variant="borderless-gray-10"
                placeholder="-"
                :name="$t('nobordistCheckpointsPage.fields.name')"
                v-model="checkpoint.name_pt"
                disabled
              />
              <NbTextInput
                id="descriptionPT"
                variant="borderless-gray-10"
                placeholder="-"
                class="col-span-2"
                :name="$t('nobordistCheckpointsPage.fields.description')"
                v-model="checkpoint.description_pt"
                disabled
              />
            </div>
          </template>
        </NbCard>

        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
            @click="resetQuery()"
          >
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import CheckpointService from "@/services/CheckpointService";
import { CHECKPOINT_GROUP } from "../../../../constants";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";

const checkpointService = new CheckpointService();

export default {
  components: {
    NbModal,
    NbButton,
    NbTextInput,
    NbCard,
    NbFooter,
    IsCheckedIcon,
  },
  name: "ModalNobordistCheckpointsView",
  props: {
    id: {
      type: String,
      default: "ModalNobordistCheckpointsView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
    zindex: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      checkpoint: {},
    };
  },
  created() {},
  computed: {},
  methods: {
    getZoneCodeName(zoneCode) {
      const subject = Object.values(CHECKPOINT_GROUP).find(
        (item) => item.zoneCode === zoneCode,
      );

      return subject?.name || "";
    },
    resetQuery() {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({ query: {} });
        return;
      }
    },
  },
  watch: {
    itemId(newVal) {
      if (newVal) {
        checkpointService.getCheckpoint(newVal).then((response) => {
          this.checkpoint = response.data.data;
        });
      }
    },
  },
};
</script>

<style scoped></style>
