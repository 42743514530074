<template>
  <div>
    <header-title :title="$t('groupsPage.title')" />
    <layout-content
      :namespace="namespace"
      :all-fields="allFields"
      :items="items"
      :has-limit="false"
    >
      <template #buttonsTop> </template>
      <template #buttonsAction> </template>
      <template #cell(id)="data">
        <div>
          <router-link :to="`/users_management/groups/` + data.item.id">{{
            data.value
          }}</router-link>
        </div>
      </template>
    </layout-content>
  </div>
</template>

<script>
import LayoutContent from "@/components/LayoutContent";
import HeaderTitle from "../../../components/HeaderTitle.vue";

export default {
  name: "Groups",
  components: {
    LayoutContent,
    HeaderTitle,
  },
  data: () => {
    return {
      selectMode: "single",
      filter: null,
      filterOptions: [],
      selected: [],
      allFields: [],

      namespace: "groups",
    };
  },
  computed: {
    items() {
      return this.$store.state[this.namespace].items;
    },
    fields() {
      return [
        { key: "id", label: "ID", sortable: false, thClass: ["small-content"] },
        {
          key: "name",
          label: this.$t("groupsPage.fields.name"),
          sortable: true,
          thClass: ["large-content"],
        },
      ];
    },
  },
  created() {
    this.allFields = this.fields;
    this.loadItems();
  },
  methods: {
    loadItems() {
      this.$store.dispatch(this.namespace + "/fetchItems", this.namespace);
    },
  },
};
</script>
