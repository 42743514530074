<template>
  <div class="pass-input-wrapper" :class="error[0] ? 'is-invalid' : ''">
    <label v-if="name" class="input-label" :for="id">
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
      </NbHelpText>
    </label>
    <!-- :type="hiddePassword ? 'password' : 'text'" -->
    <input
      :id="id"
      v-model="textContent"
      class="form-control input"
      :type="computedType"
      :placeholder="placeholder"
      aria-autocomplete="none"
      :autocomplete="autocomplete"
      :class="computedClasses"
    />
    <span class="pass-icon" @click="switchTypeInput()"
      ><i class="far" :class="hiddePassword ? 'fa-eye' : 'fa-eye-slash'"></i
    ></span>
    <ErrorFeedback :error="error[0]" />
  </div>
</template>

<script>
import ErrorFeedback from "../../generic/ErrorFeedback.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";

export default {
  name: "NbBaseInput",
  components: {
    ErrorFeedback,
    NbHelpText,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    helpText: {
      type: String,
      required: false,
      default: "",
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    prepend: {
      type: String,
      required: false,
      default: "",
    },
    append: {
      type: String,
      required: false,
      default: "",
    },
    autocomplete: {
      type: String,
      required: false,
      default: "",
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      textContent: this.value,
      hiddePassword: true,
    };
  },
  computed: {
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
    computedClasses() {
      let classes = "";
      if (this.error[0]) {
        classes += " is-invalid";
      }
      if (this.textContent.length > 0) {
        classes += this.hiddePassword ? " password" : " text";
      } else {
        classes += " text";
      }

      return classes;
    },
    computedType() {
      return this.hiddePassword ? "password" : "text";
    },
  },
  watch: {
    //altera o input
    textContent(newValue) {
      this.$emit("input", newValue);
    },
    //altera o modal do pai
    value(newValue) {
      this.textContent = newValue;
    },
  },
  methods: {
    switchTypeInput() {
      this.hiddePassword = !this.hiddePassword;
    },
  },
};
</script>

<style lang="scss" scoped>
.pass-input-wrapper {
  position: relative;
}
.pass-input-wrapper > .pass-icon {
  position: absolute;
  top: 2.375rem;
  right: 1rem;
  color: var(--gray-60);
  cursor: pointer;
}

.pass-input-wrapper > .form-control.password {
  font: normal normal 600 14px/20px var(--font-family-pass);
}

//inputs
.input-label {
  color: var(--black);
  text-align: left;
  font: normal normal 600 12px/16px var(--font-family-base);
  letter-spacing: 0px;
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: default;
}
.form-control.input:not(:placeholder-shown),
.form-control.input.is-invalid:not(:placeholder-shown) {
  border-bottom: 2px solid var(--black);
}
.form-control.input {
  transition: all 0.3s ease;
  height: 2.5rem;
  //height: calc(2em + 0.75rem + 2px);
  /* 
  border-radius: 4px;
  font: $text16_20;
  */
  padding: 9px 12px;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid var(--gray-40);
  font: normal normal normal 14px/20px var(--font-family-base);
  background: var(--gray-05) 0% 0% no-repeat padding-box !important;
  border-radius: 4px 4px 0px 0px;
}
.form-control.input:hover {
  //color: var(--gray-40);
  background: var(--gray-10) 0% 0% no-repeat padding-box !important;
}
.form-control.input:focus {
  box-shadow: none;
  border-bottom: 2px solid var(--black);
}
.form-control.input::placeholder {
  color: var(--gray-40);
}

.form-control.input.is-invalid {
  border-bottom: 2px solid var(--error);
  caret-color: var(--error);
  background-image: none;
}
.form-control.input.is-invalid:hover {
  background: var(--gray-10) 0% 0% no-repeat padding-box;
}
.form-control.input:disabled,
.form-control.input[disabled] {
  background-color: var(--gray-05) !important;
  color: var(--gray-20) !important;
  border-bottom: 2px solid var(--gray-20);
}
</style>
