import OverviewService from "@/services/OverviewService";

const overviewService = new OverviewService();

export default {
  async fetchBalance({ commit }) {
    const res = await overviewService.getSaldoCorreios();
    const balance_correios = res.data.data;
    commit("SET_BALANCE", balance_correios);
  },
};
