<template>
  <NbButton type="button" :variant="variant" @click.stop="downloadBoletoGNRE">
    <Spinner v-if="loading" class="d-flex mx-auto" />
    <div v-else class="d-flex align-items-center gap-1">
      <slot>
        {{ $t("download") }}
      </slot>
    </div>
  </NbButton>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import Spinner from "@/components/Spinner.vue";
import FiscalPaymentService from "@/services/FiscalPaymentService";

const fiscalPaymentService = new FiscalPaymentService();

export default {
  props: {
    fiscalPaymentId: {
      type: Number,
      required: true,
    },
    variant: {
      type: String,
      default: "tertiary",
    },
  },
  components: {
    NbButton,
    Spinner,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async downloadBoletoGNRE() {
      try {
        this.loading = true;
        const { data } = await fiscalPaymentService.getLabel(
          this.fiscalPaymentId,
        );
        if (data?.data?.download_url) {
          window.open(data.data.download_url);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
