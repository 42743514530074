<template>
  <div class="row justify-content-center">
    <div class="col-12 mb-5">
      <back-button />
      <h5 class="font-weight-light mb-4 text-uppercase">
        {{ $t("dutiesAndTaxesStatusViewPage.title") }}{{ payment.id }}
      </h5>
      <br />
      <div class="card bg-light border-white rounded py-4">
        <div v-if="payment.order_data" class="row mx-5 my-2">
          <field-view
            :field="$t('dutiesAndTaxesStatusViewPage.incoterm')"
            :val="payment.incoterm"
            :disabled="true"
          />
          <field-view
            :field="$t('dutiesAndTaxesStatusViewPage.status')"
            :val="payment.status"
            :disabled="true"
          />
        </div>
        <div v-if="payment" class="row mx-5 my-2">
          <field-view
            :field="$t('dutiesAndTaxesStatusViewPage.createdAt')"
            :val="formatTimestamp(payment.created_at)"
            :disabled="true"
          />
          <field-view
            :field="$t('dutiesAndTaxesStatusViewPage.dateIso')"
            :val="formatTimestamp(payment.date_iso)"
            :disabled="true"
          />
          <field-view
            :field="$t('dutiesAndTaxesStatusViewPage.orderID')"
            :val="payment.order_id"
            :link="`/shipping/orders?id=${payment.order_id}`"
            link-id=""
            :disabled="true"
          />
          <field-view
            :field="$t('dutiesAndTaxesStatusViewPage.orderNumber')"
            :val="payment.order_number"
            :disabled="true"
          />
        </div>
        <div class="row mx-5 my-2">
          <field-view
            v-if="payment.order_data"
            :field="$t('dutiesAndTaxesStatusViewPage.volumeID')"
            :val="payment.volume_id"
            :link="`/shipping/volumes?id=`"
            :link-id="payment.volume_id"
            :disabled="true"
          />
          <field-view
            :field="$t('dutiesAndTaxesStatusViewPage.sellerID')"
            :val="payment.seller_id"
            :link="`/users_management/sellers?id=${payment.seller_id}`"
            link-id=""
            :disabled="true"
          />
        </div>

        <div v-if="payment" class="row mx-5 my-2">
          <field-view
            :field="$t('dutiesAndTaxesStatusViewPage.taxID')"
            :val="payment.tax_id"
            :disabled="true"
          />
          <field-view
            :field="$t('dutiesAndTaxesStatusViewPage.lastMileNumber')"
            :val="payment.last_mile_number"
            :disabled="true"
          />
        </div>

        <div v-if="payment" class="row mx-5 my-2">
          <field-view
            :field="$t('dutiesAndTaxesStatusViewPage.productsValue')"
            standardize="true"
            :val="formatMoney(payment.products_value)"
            :currency="payment.currency"
            :disabled="true"
          />
          <field-view
            :field="$t('dutiesAndTaxesStatusViewPage.freightValue')"
            standardize="true"
            :val="formatMoney(payment.freight_value)"
            :currency="payment.currency"
            :disabled="true"
          />
        </div>

        <div v-if="payment" class="row mx-5 my-2">
          <field-view
            :field="$t('dutiesAndTaxesStatusViewPage.expectedTax')"
            standardize="true"
            :val="formatMoney(payment.expected_tax)"
            :currency="payment.currency"
            :disabled="true"
          />
          <field-view
            :field="$t('dutiesAndTaxesStatusViewPage.realTax')"
            standardize="true"
            :val="formatMoney(payment.real_tax)"
            :currency="payment.currency"
            :disabled="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton";
import DutiesAndTaxesStatusService from "@/services/DutiesAndTaxesStatusService";
import FieldView from "@/components/FieldView";

const dutiesAndTaxesStatusService = new DutiesAndTaxesStatusService();

export default {
  name: "DutiesAndTaxesView",

  components: {
    BackButton,
    "field-view": FieldView,
  },
  data: () => {
    return {
      payment: {},
      errors: [],
      watchControl: null,
    };
  },
  beforeMount() {
    // if (this.$store.state.user.group_name!=='Platform-Finance' && !this.$store.state.user.is_super) {
    //   this.$router.push('/')
    // } else {
    this.reload();
    // }
  },
  methods: {
    reload() {
      dutiesAndTaxesStatusService
        .get(this.$route.params.id)
        .then((response) => {
          this.payment = response.data.data;

          this.payment.date_iso = this.formatTimestamp(this.payment.date_iso);
          this.payment.created_at = this.formatTimestamp(
            this.payment.created_at,
          );
        });
    },
    formatTimestamp(string) {
      return this.$helpers.formatTimestamp(string, {});
    },
    formatMoney(string, currency) {
      return this.$helpers.formatMoney(string, currency);
    },
  },
};
</script>

<style></style>
