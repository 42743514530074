import api from "./HttpService";

export default class StatusCheckpointService {
  /**
   * Get statusCheckpointService
   *
   * @param {*} id
   * @returns {Promise}
   */
  getStatusCheckpoint(id = null) {
    return api.get("/v1/status_checkpoints/" + id);
  }

  /**
   * Get statusCheckpointService
   *
   * @param offset = number, track = filters
   * @returns {Promise}
   */
  getTwentyStatusCheckpoints(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/status_checkpoints`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/status_checkpoints?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  getAllStatusCheckpoints() {
    return api.get("/v1/status_checkpoints");
  }

  /**
   * Update StatusCheckpoint
   *
   * @param id
   * @param data
   * @returns {Promise}
   */
  updateStatusCheckpoint(id, data = {}) {
    return api.put("/v1/status_checkpoints/" + id, data);
  }
  /**
   * Set StatusCheckpoint
   *
   * @param data
   * @returns {Promise}
   */
  setStatusCheckpoint(data = {}) {
    return api.post("/v1/status_checkpoints/", data);
  }
}
