<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("overpacksPage.updateFirstMileInformation") }}
      </ModalTitle>
    </ModalHeader>

    <NbTextInput
      v-model="form.firstMileCarrierName"
      :name="$t('overpacksPage.carriername')"
      id="first_mile_carrier_name"
      required
      :error="errors['first_mile_carrier_name']"
    />

    <NbTextInput
      v-model="form.firstMileTrackingNumber"
      :name="$t('overpacksPage.trackingNumber')"
      id="tracking_number"
      required
      :error="errors['first_mile_tracking_number']"
    />

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
      <NbButton
        class="ml-2"
        @click="submit"
        :disabled="loading || !formIsValid"
      >
        {{ $t("overpacksPage.updateInformation") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import OverpackService from "@/services/OverpackService";

const overpackService = new OverpackService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbTextInput,
  },
  props: {
    overpackIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      errors: [],
      form: {
        firstMileCarrierName: "",
        firstMileTrackingNumber: "",
      },
    };
  },
  computed: {
    formIsValid() {
      return (
        this.form.firstMileCarrierName && this.form.firstMileTrackingNumber
      );
    },
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        await overpackService.updateManyOverpacks({
          overpack_ids: this.overpackIds,
          first_mile_carrier_name: this.form.firstMileCarrierName,
          first_mile_tracking_number: this.form.firstMileTrackingNumber,
        });
        this.$emit("success");
        this.context?.close();
      } catch (error) {
        this.errors = error.response?.data?.messages?.[0] || [];
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
