<template>
  <div>
    <NbPageTitle
      class="pb-3"
      :title="$t('priceTablesPage.title')"
      :subTitle="$t('priceTablesPage.subTitle')"
    >
      <template #title>
        <NbHelpText
          id="pricetable-deliverytimes-help"
          class="mx-1 text-gray-40"
          size="lg"
          placement="topright"
        >
          {{ $t("priceTablesPage.helpTextTitle") }}
        </NbHelpText>
      </template>
      <!-- Botoes -->
      <NbButton @click="checkImportation(currentTable)" icon="upload">
        {{
          currentTable == "priceTables"
            ? $t("priceTablesPage.importPricesTable")
            : $t("priceTablesPage.importDeliveryTime")
        }}
      </NbButton>
    </NbPageTitle>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <PriceTablesList
        :class="currentTable == 'priceTables' ? '' : 'display-hidde-absolute'"
        @switchTab="switchTab($event)"
        @total="totalPriceTables = $event"
      />
      <DeliveryTimesList
        :class="currentTable == 'deliveryTimes' ? '' : 'display-hidde-absolute'"
        @switchTab="switchTab($event)"
        @total="totalDeliveryTimes = $event"
      />
    </section>

    <NbFooter
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import PriceTablesList from "@/views/contracts/price_tables/price_tables/PriceTablesList.vue";
import DeliveryTimesList from "@/views/contracts/price_tables/delivery_times/DeliveryTimesList.vue";
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  name: "PriceTables",
  components: {
    NbPageTitle,
    NbHelpText,
    NbTabs,
    NbFooter,
    PriceTablesList,
    DeliveryTimesList,
    NbButton,
  },
  data() {
    return {
      currentTable: "priceTables",
      totalDeliveryTimes: -1,
      totalPriceTables: -1,
    };
  },
  created() {},
  methods: {
    checkImportation(currentTable) {
      this.$helpers.openModal(`add${currentTable}`);
    },
    switchTab(tab) {
      this.currentTable = tab;
    },
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "priceTables",
          label: "Prices Table",
          found: this.totalPriceTables,
          current: this.currentTable,
        },
        {
          id: "deliveryTimes",
          label: "Delivery Times",
          found: this.totalDeliveryTimes,
          current: this.currentTable,
        },
      ];
    },
  },
};
</script>
