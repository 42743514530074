<template>
  <div class="bg-gray-05 p-2 my-1 radius grab">
    <NbIcon
      :class="`${prependClass}`"
      :icon="prependIcon"
      :attributes="{
        width: '1.2rem',
        height: '1.2rem',
      }"
    />
    <span class="body-3">
      {{ label }}
    </span>
    <NbIcon
      :class="`${postpendClass} mr-1 float-right pointer`"
      :icon="postpendIcon"
      :attributes="{
        width: '1.2rem',
        height: '1.2rem',
      }"
      @click="$emit('toggle')"
    />
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  name: "NbSelectTableButtonFields",
  components: { NbIcon },
  props: {
    currentPage: String,
    label: {
      type: String,
      required: true,
    },
    prependIcon: {
      type: String,
      default: "more-vertical",
    },
    postpendIcon: {
      type: String,
      default: "eye",
    },
    prependClass: {
      type: String,
      default: "text-gray-40",
    },
    postpendClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isDragging: false,
    };
  },
  created() {},
  methods: {
    startDrag(e) {
      this.initialX = e.clientX - this.left;
      this.initialY = e.clientY - this.top;
      this.isDragging = true;
    },
    stopDrag() {
      this.isDragging = false;
    },
    onDrag(e) {
      if (this.isDragging) {
        this.currentX = e.clientX - this.initialX;
        this.currentY = e.clientY - this.initialY;
        this.xOffset = this.currentX;
        this.yOffset = this.currentY;
        this.left = this.currentX;
        this.top = this.currentY;
      }
    },
  },
};
</script>

<style lang="scss"></style>
