<template>
  <div>
    <header-title :title="title" />
    <div class="row justify-content-center">
      <div class="col-12 mb-5">
        <!-- campos api_name, 
                    function, 
                    user name (usando GET /users para achar o nome),
                    creation date,
                    params 
                    response. -->
        <div class="card border-white rounded py-4">
          <div class="row mx-5 my-2">
            <!-- <div class="col">
                            <h6>API Name</h6>
                            <div v-if="api_log.api_name" class="border rounded bg-white px-3 py-2">{{ api_log.api_name }}</div>
                        </div> -->
            <field-view
              :field="$t('externalViewLogs.apiName')"
              :val="api_log.api_name"
            />
            <field-view
              :field="$t('externalViewLogs.userName')"
              :val="userName"
              :link="`/users_management/users?id=${api_log.user_id}`"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('externalViewLogs.function')"
              :val="api_log.function"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('externalViewLogs.creationDate')"
              :val="api_log.created_at"
            />
            <field-view
              :field="$t('externalViewLogs.updateDate')"
              :val="api_log.updated_at"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('externalViewLogs.params')"
              :val="api_log.params"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('externalViewLogs.response')"
              :val="api_log.response"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExternalApiLogService from "../../../services/ExternalApiLogService";
import UsersService from "../../../services/UsersService";
import FieldView from "@/components/FieldView";
import HeaderTitle from "@/components/HeaderTitle";

const externalApiLogService = new ExternalApiLogService();
const usersService = new UsersService();

export default {
  name: "NobordistLogsView",
  components: {
    "field-view": FieldView,
    HeaderTitle,
  },
  data: () => {
    return {
      isVisible: true,
      api_log: {},
      userName: "",
    };
  },
  computed: {
    title() {
      return this.$t("externalViewLogs.title") + this.$route.params.id;
    },
  },
  beforeMount() {
    this.reloadExternalApiLogs();
  },
  methods: {
    addZero(number) {
      if (number <= 9) return "0" + number;
      else return number;
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    reloadExternalApiLogs() {
      externalApiLogService
        .getExternalApiLog(this.$route.params.id)
        .then((response) => {
          this.api_log = response.data.data;
          const time = this.api_log.created_at;
          const date = this.api_log.created_at;
          const updated = this.api_log.updated_at;
          const updatedTime = this.api_log.updated_at;
          this.api_log.created_at = this.formateDate(date, time);
          this.api_log.updated_at = this.formateDate(updated, updatedTime);
          if (this.api_log.user_id) {
            this.loadCurrentUser(this.api_log.user_id);
          }
        });
    },
    loadCurrentUser(id) {
      usersService.getUser(id).then((response) => {
        this.userName = response.data.data.name;
      });
    },
  },
};
</script>
