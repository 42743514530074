<template>
  <tbody>
    <tr v-for="row in rows" :key="row">
      <td v-for="col in columns" :key="col">
        <Skeleton w="64" />
      </td>
    </tr>
  </tbody>
</template>

<script>
import Skeleton from "../Skeleton.vue";

export default {
  props: {
    rows: {
      type: Number,
      required: true,
    },
    columns: {
      type: Number,
      required: true,
    },
  },
  components: {
    Skeleton,
  },
};
</script>

<style lang="scss" scoped>
tbody {
  tr {
    border-bottom: 1px solid #c8c8dc; //gray-20
    td {
      padding: 1.25rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
}
</style>
