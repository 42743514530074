<template>
  <NbBadge v-show="status.title" :text="status.title" :type="status.variant" />
</template>
<script>
import NbBadge from "@/components/alerts/NbBadge.vue";
export default {
  components: {
    NbBadge,
  },
  props: {
    overpack: {
      type: Object,
      required: true,
    },
  },

  computed: {
    sentAt() {
      return this.overpack.sent_at;
    },
    isDispatched() {
      return this.overpack.is_dispatched;
    },
    status() {
      if (this.sentAt === null) {
        return {
          title: this.$t("overpacksViewPage.new"),
          variant: "primary",
        };
      }

      if (this.sentAt !== null) {
        return {
          title: this.$t("overpacksViewPage.ready"),
          variant: "warning",
        };
      }

      if (this.isDispatched) {
        return {
          title: this.$t("overpacksViewPage.dispatched"),
          variant: "success",
        };
      }

      return {
        title: "",
        variant: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
