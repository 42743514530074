<template>
  <div class="grid grid-cols-2">
    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('name')"
      v-model="volume.order.seller_name"
      id="volume.order.seller_full_name"
      placeholder="-"
    />
    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('phone')"
      v-model="volume.order.seller_phone"
      id="volume.order.seller_phone"
      placeholder="-"
    />
    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('email')"
      v-model="volume.order.seller_email"
      id="volume.order.seller_email"
    />

    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('website')"
      v-model="volume.order.seller_website"
      id="volume.order.seller_website"
      placeholder="-"
    />

    <div class="grid grid-cols-3 gap-4 col-span-2">
      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('taxNumber')"
        v-model="volume.order.seller_tax_number"
        id="volume.order.seller_tax_number"
        placeholder="-"
      />

      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('rfbCode')"
        v-model="volume.order.seller_rfb_code"
        id="volume.order.seller_rfb_code"
        :helpText="$t('volumesViewPage.rfbCodeDescription')"
        placeholder="-"
      />

      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('legalName')"
        v-model="volume.order.seller_legal_name"
        id="volume.order.seller_legal_name"
        placeholder="-"
      />
    </div>

    <div class="col-span-2 grid grid-cols-4 gap-4">
      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('address')"
        v-model="volume.order.seller_address"
        id="volume.order.seller_address"
        class="col-span-2"
        placeholder="-"
      />
      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('addressNumber')"
        v-model="volume.order.seller_address_number"
        id="volume.order.seller_address_number"
        placeholder="-"
      />

      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('addressComplement')"
        v-model="volume.order.seller_address_complement"
        id="volume.order.seller_address_complement"
        placeholder="-"
      />
    </div>

    <div class="grid grid-cols-4 gap-4 col-span-2">
      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('city')"
        v-model="volume.order.seller_city"
        id="volume.order.seller_city"
        placeholder="-"
      />
      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('postalCode')"
        v-model="volume.order.seller_zip_code"
        id="volume.order.seller_zip_code"
        placeholder="-"
      />
      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('state')"
        v-model="volume.order.seller_state"
        id="volume.order.seller_state"
        placeholder="-"
      />

      <div>
        <label class="input-label">
          {{ $t("country") }}
        </label>
        <div class="input-view d-flex align-items-center gap-2">
          <img
            :src="`https://flagcdn.com/24x18/${volume.order.seller_country.toLowerCase()}.png`"
            alt=""
          />
          <span v-if="volume.order?.seller_country">
            {{
              $helpers.getCountryNameByCode(
                volume.order.seller_country,
                $i18n.locale.replace("_", "-"),
              )
            }}
          </span>
          <span v-else>-</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NbTextInput from "@/components/input/text/NbTextInput.vue";

export default {
  props: {
    volume: {
      type: Object,
      required: true,
    },
  },
  components: {
    NbTextInput,
  },
};
</script>

<style lang="scss" scoped></style>
