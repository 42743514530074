<template>
  <div class="nb-card-box">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "NbCardBox",
};
</script>
<style lang="scss" scoped>
.nb-card-box {
  padding: 1.5rem;
  border: 1px solid var(--gray-05);
  box-shadow: 4px 4px 8px var(--gray-05);
  background-color: var(--white);
}
</style>
