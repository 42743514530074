import actions from "./actions";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    all_items: [],
  },
  mutations: mutations,
  actions: actions,
};
