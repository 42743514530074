import AlertLogsService from "../../../../services/AlertLogsService";

const alertLogsService = new AlertLogsService();

export default {
  data() {
    return {
      isLoadingPaymentPdf: false,
    };
  },
  methods: {
    openDocument(attachment) {
      window.open(attachment);
    },
    async getPaymentPdf(logId) {
      try {
        this.isLoadingPaymentPdf = true;
        const { data } = await alertLogsService.downloadPaymentCerti(logId);
        window.open(data.data.download_url);
      } finally {
        this.isLoadingPaymentPdf = false;
      }
    },
  },
};
