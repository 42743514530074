<template>
  <div class="costs">
    <section>
      <h3 class="heading-3 m-0">
        {{ $t("ordersViewPage.exchangeOnCreation") }}
      </h3>
      <div class="info">
        <small>{{ $t("ordersViewPage.currency") }}</small>
        <span>{{ order.currency }}</span>
      </div>
    </section>

    <section>
      <h3 class="heading-3 m-0">{{ $t("ordersViewPage.freight") }}</h3>
      <div class="grid grid-cols-6 gap-4">
        <div class="info">
          <div>
            <small>{{ $t("ordersViewPage.freightValue") }}</small>
            <NbHelpText
              id="freight-value-popover"
              class="mx-1"
              size="sm"
              placement="topright"
            >
              {{ $t("ordersViewPage.informedBySeller") }}
            </NbHelpText>
          </div>
          <span>{{ order.currency }} {{ order.freight_value }}</span>
        </div>

        <div class="info">
          <div>
            <small>{{
              $t("ordersViewPage.volumesFields.estimatedFreightCost")
            }}</small>
            <NbHelpText
              id="estimated-freight-cost-popover"
              class="mx-1"
              size="sm"
              placement="topright"
            >
              {{ $t("ordersViewPage.estimatedFreightCostDescription") }}
            </NbHelpText>
          </div>
          <span>{{ order.currency }} {{ order.estimated_freight_cost }}</span>
        </div>

        <div class="info">
          <div>
            <small>{{ $t("ordersViewPage.freightCost") }}</small>
            <NbHelpText
              id="freight-cost-popover"
              class="mx-1"
              size="sm"
              placement="topright"
            >
              {{ $t("ordersViewPage.freightCostDescription") }}
            </NbHelpText>
          </div>
          <span>{{ order.currency }} {{ order.freight_cost }}</span>
        </div>
      </div>
    </section>

    <section>
      <h3 class="heading-3 m-0">
        {{ $t("ordersViewPage.estimatedTaxes") }}
        <NbHelpText
          id="estimated-tax-popover"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("ordersViewPage.computedByNbSystem") }}
        </NbHelpText>
      </h3>
      <div class="grid grid-cols-6 gap-4">
        <div class="info">
          <div>
            <small>{{ $t("ordersViewPage.dutiesTax") }}</small>
            <NbHelpText
              id="duties-tax-popover"
              class="mx-1"
              size="sm"
              placement="topright"
            >
              {{ $t("ordersViewPage.dutiesTaxDescription") }}
            </NbHelpText>
          </div>
          <span>
            {{ order.currency }} {{ order.estimated_duties_tax_value }}
          </span>
        </div>

        <div class="info">
          <div>
            <small>{{ $t("ordersViewPage.salesTax") }}</small>
            <NbHelpText
              id="sales-tax-popover"
              class="mx-1"
              size="sm"
              placement="topright"
            >
              {{ $t("ordersViewPage.salesTaxDescription") }}
            </NbHelpText>
          </div>
          <span>
            {{ order.currency }} {{ order.estimated_sales_tax_value }}
          </span>
        </div>

        <div class="info col-span-4">
          <small>{{ $t("ordersViewPage.totalTax") }}</small>
          <span>
            {{ $t("ordersViewPage.dutiesTax") }}:
            {{ order.estimated_duties_tax_value }} +
            {{ $t("ordersViewPage.salesTax") }}:
            {{ order.estimated_sales_tax_value }} =
            {{ $t("ordersViewPage.totalTax") }}: {{ order.currency }}
            {{ order.estimated_tax_value }}
          </span>
        </div>
      </div>
    </section>

    <section>
      <h3 class="heading-3 m-0">
        {{ $t("ordersViewPage.actualTaxes") }}
        <NbHelpText
          id="actual-taxes-popover"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("ordersViewPage.informedBySeller") }}
        </NbHelpText>
      </h3>
      <div class="grid grid-cols-6 gap-4">
        <div class="info">
          <div>
            <small>{{ $t("ordersViewPage.dutiesTax") }}</small>
            <NbHelpText
              id="duties-tax-value-popover"
              class="mx-1"
              size="sm"
              placement="topright"
            >
              {{ $t("ordersViewPage.dutiesTaxDescription") }}
            </NbHelpText>
          </div>
          <span> {{ order.currency }} {{ order.duties_tax_value }} </span>
        </div>

        <div class="info">
          <div>
            <small>{{ $t("ordersViewPage.salesTax") }}</small>
            <NbHelpText
              id="sales-tax-value-popover"
              class="mx-1"
              size="sm"
              placement="topright"
            >
              {{ $t("ordersViewPage.salesTaxDescription") }}
            </NbHelpText>
          </div>
          <span> {{ order.currency }} {{ order.sales_tax_value }} </span>
        </div>

        <div class="info col-span-4">
          <small>{{ $t("ordersViewPage.totalTax") }}</small>
          <span>
            {{ $t("ordersViewPage.dutiesTax") }}: {{ order.duties_tax_value }} +
            {{ $t("ordersViewPage.salesTax") }}: {{ order.sales_tax_value }} =
            {{ $t("ordersViewPage.totalTax") }}:
            {{ order.currency }}
            {{ order.tax_value }}
          </span>
        </div>
      </div>
    </section>

    <section>
      <h3 class="heading-3 m-0">{{ $t("ordersViewPage.summary") }}</h3>
      <div class="grid grid-cols-6 gap-4">
        <div class="info">
          <small>{{ $t("ordersViewPage.volumesFields.productsValue") }}</small>
          <span> {{ order.currency }} {{ order.products_value }} </span>
        </div>

        <div class="info">
          <small>{{ $t("ordersViewPage.freightValue") }}</small>
          <span> {{ order.currency }} {{ order.freight_value }} </span>
        </div>

        <div class="info col-span-4">
          <small>{{ $t("ordersViewPage.declaredValue") }}</small>
          <span>
            {{ $t("ordersViewPage.products") }}: {{ order.products_value }} +
            {{ $t("ordersViewPage.freight") }}: {{ order.freight_value }} =
            {{ order.currency }} {{ order.declared_value }}
          </span>
        </div>

        <div class="info">
          <small>{{ $t("ordersViewPage.taxValue") }}</small>
          <span>{{ order.currency }} {{ order.tax_value }}</span>
        </div>

        <div class="info col-span-4">
          <small>{{ $t("ordersViewPage.totalValue") }}</small>
          <span>
            {{ $t("ordersViewPage.declaredValue") }}:
            {{ order.declared_value }} + {{ $t("ordersViewPage.taxValue") }}:
            {{ order.tax_value }} =
            {{ order.currency }}
            {{ order.total_value }}
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NbHelpText from "@/components/generic/NbHelpText.vue";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    NbHelpText,
  },
};
</script>

<style lang="scss" scoped>
.costs {
  .info {
    display: flex;
    flex-direction: column;
    small {
      font-weight: 700;
      font-size: 0.75rem;
    }
    span {
      line-height: 1.2;
    }
  }

  section {
    & + section {
      margin-top: 1.5rem;
    }
  }
}
</style>
