<template>
  <div>
    <header-title :title="title" :badges="isMadeBySeller" />
    <div class="row justify-content-center">
      <div class="col-12 mb-5">
        <div class="card border-white rounded py-4">
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('deliveryMethodsViewPage.name')"
              :val="deliveryMethod.name"
            />
            <field-view
              :field="$t('deliveryMethodsViewPage.service')"
              :val="deliveryMethod.service"
            />
          </div>

          <div class="row mx-5 my-2">
            <field-view
              :field="$t('deliveryMethodsViewPage.type')"
              :val="deliveryMethod.type"
            />
            <field-view
              :field="$t('deliveryMethodsViewPage.carrier')"
              :val="carrier"
              :link="`/users_management/carriers/`"
              :linkId="deliveryMethod.carrier_id"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('deliveryMethodsViewPage.owner')"
              :val="deliveryMethod.owner"
            />
            <field-view
              :field="$t('deliveryMethodsViewPage.ownerReference')"
              :val="deliveryMethod.owner_reference"
            />
          </div>

          <div class="row mx-5 my-2">
            <field-view
              :field="$t('deliveryMethodsViewPage.cooperationSlug')"
              :val="deliveryMethod.slug"
            />
            <field-view
              :field="$t('deliveryMethodsViewPage.nationalization')"
              :val="deliveryMethod.additional_settings.nationalization"
              v-if="['correios', 'skypostal'].includes(deliveryMethod.slug)"
            />
          </div>
          <div class="row mx-5 my-2">
            <div class="col">
              <br />
              <h6>{{ $t("deliveryMethodsViewPage.trackingCredentials") }}</h6>
              <div
                v-for="(
                  tracking_api_credentials, key
                ) in deliveryMethod.tracking_api_credentials"
                :key="tracking_api_credentials.id"
              >
                <b-card no-body :header="key">
                  <b-card-body>
                    {{ tracking_api_credentials }}
                  </b-card-body>
                </b-card>
              </div>
            </div>
          </div>
          <div class="row mx-5 my-2">
            <div class="col">
              <br />
              <h6>{{ $t("deliveryMethodsViewPage.dispatchCredentials") }}</h6>
              <div
                v-for="(
                  dispatch_api_credentials, key
                ) in deliveryMethod.dispatch_api_credentials"
                :key="dispatch_api_credentials.id"
              >
                <b-card no-body :header="key">
                  <b-card-body>
                    {{ dispatch_api_credentials }}
                  </b-card-body>
                </b-card>
              </div>
            </div>
          </div>
          <div
            v-if="site !== 'carrier'"
            class="row mx-5 mt-3 d-flex justify-content-center"
          >
            <button
              v-if="deliveryMethod.is_made_by_seller"
              :disable="disable"
              class="btn btn-success btn-disabled mt-2 text-white"
              v-b-popover.hover="
                'You can not edit delivery method created by a seller'
              "
            >
              {{ $t("deliveryMethodsViewPage.editDeliveryMethod") }}
            </button>
            <a
              v-else
              data-toggle="modal"
              data-target="#editDeliveryMethod"
              class="btn btn-success mt-2 text-white"
              @click="copyDeliveryMethod()"
              title="Edit Delivery Method"
            >
              {{ $t("deliveryMethodsViewPage.editDeliveryMethod") }}
            </a>
          </div>
        </div>
      </div>
      <!-- modals -->
      <div class="modal fade" id="editDeliveryMethod" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="settingsTitle">
                {{ $t("deliveryMethodsViewPage.edit") }}
                {{ deliveryMethod.name }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="name">{{
                      $t("deliveryMethodsViewPage.name")
                    }}</label>
                    <input
                      v-model="newDeliveryMethod.name"
                      type="text"
                      :class="{ 'is-invalid': errors['name'] }"
                      class="form-control"
                      id="name"
                      aria-describedby="nameHelp"
                      placeholder="Name .."
                    />
                    <div
                      class="invalid-feedback"
                      v-for="(error, index) in errors['name']"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="service">{{
                      $t("deliveryMethodsViewPage.service")
                    }}</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': errors['service'] }"
                      id="service"
                      v-model="newDeliveryMethod.service"
                    >
                      <option v-if="!newDeliveryMethod.service" value="">
                        {{ $t("deliveryMethodsViewPage.selectgroup") }}
                      </option>
                      <option
                        v-for="(service, index) in services"
                        :key="index"
                        :value="service"
                      >
                        {{ service }}
                      </option>
                    </select>
                    <div
                      class="invalid-feedback"
                      v-for="(error, index) in errors['service']"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="type">{{
                      $t("deliveryMethodsViewPage.type")
                    }}</label>
                    <input
                      v-model="newDeliveryMethod.type"
                      :class="{ 'is-invalid': errors['type'] }"
                      type="text"
                      class="form-control"
                      id="type"
                      aria-describedby="typeHelp"
                      placeholder="Type .."
                    />
                    <div
                      class="invalid-feedback"
                      v-for="(error, index) in errors['type']"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="carrier_id">{{
                      $t("deliveryMethodsViewPage.carrier")
                    }}</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': errors['carrier'] }"
                      id="carrier"
                      v-model="newDeliveryMethod.carrier_id"
                    >
                      <option value="">
                        {{ $t("deliveryMethodsViewPage.selectcarrier") }}
                      </option>
                      <option
                        v-for="(carrier, index) in carriers"
                        :key="index"
                        :value="carrier.id"
                      >
                        {{ carrier.name }}
                      </option>
                    </select>
                    <div
                      class="invalid-feedback"
                      v-for="(error, index) in errors['carrier']"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col m-2">
                  <filter-select
                    class="mt-3"
                    :label="$t('deliveryMethodsViewPage.cooperationSlug')"
                    @emitValue="setSlug($event)"
                    :items="cooperations"
                    placehol="Select Cooperation .."
                    errorTarget="slug"
                    itemValue="id"
                    itemText="title"
                    :shows="['title']"
                    :errors="errors"
                    :valEdit="newDeliveryMethod.slug_id"
                  />
                </div>

                <div class="col" v-if="ifNationalization">
                  <label for="service">{{
                    $t("deliveryMethodsViewPage.nationalization")
                  }}</label>
                  <select
                    class="form-control"
                    :class="{ 'is-invalid': errors['additional_settings'] }"
                    id="service"
                    v-model="
                      newDeliveryMethod.additional_settings.nationalization
                    "
                  >
                    <option
                      v-for="(option, index) in nationalizationOptions"
                      :key="index"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
              <div>
                <div class="track-credentials my-3">
                  {{ $t("deliveryMethodsViewPage.trackingCredentials") }}
                </div>

                <div
                  v-for="(
                    value, key, index
                  ) in newDeliveryMethod.tracking_api_credentials"
                  :key="index"
                  class="row"
                >
                  <div class="col-7">
                    <div class="form-group">
                      <label :for="index"> {{ key }}</label>
                      <input
                        v-model="
                          newDeliveryMethod.tracking_api_credentials[key]
                        "
                        type="text"
                        :class="{
                          'is-invalid': errors['tracking_api_credentials'],
                        }"
                        class="form-control"
                        :id="index"
                        :placeholder="key"
                      />
                      <div
                        class="invalid-feedback"
                        v-for="(error, index) in errors[
                          'tracking_api_credentials'
                        ]"
                        :key="index"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="botton-button">
                      <button
                        class="btn btn-danger btn-sm"
                        @click="removeTCredential(key)"
                      >
                        - {{ key }}
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-sm ml-3 mb-2"
                  :class="trackShow ? 'btn-secondary' : 'btn-info'"
                  @click="trackShow = !trackShow"
                >
                  {{ trackShow ? "-" : "+" }}
                  {{ $t("deliveryMethodsViewPage.addTracking") }}
                </button>
                <div v-if="trackShow" class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="key">{{
                        $t("deliveryMethodsViewPage.key")
                      }}</label>
                      <input
                        v-model="tkey"
                        type="text"
                        id="key"
                        class="form-control"
                        placeholder="Key .."
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="value">{{
                        $t("deliveryMethodsViewPage.value")
                      }}</label>
                      <input
                        v-model="tvalue"
                        type="text"
                        id="value"
                        class="form-control"
                        placeholder="Value .."
                      />
                    </div>
                  </div>
                </div>
                <button
                  v-if="trackShow"
                  class="btn btn-success btn-sm ml-3 mb-2"
                  :class="tkey ? 'anim-fade-one' : 'visible-hidden'"
                  @click="addTCredential(tvalue, tkey)"
                >
                  {{ $t("deliveryMethodsViewPage.addTracking") }}
                </button>
              </div>
              <!-- dispatch -->
              <div>
                <div class="track-credentials mb-3">
                  {{ $t("deliveryMethodsViewPage.dispatchCredentials") }}
                </div>

                <div
                  v-for="(
                    value, key, index
                  ) in newDeliveryMethod.dispatch_api_credentials"
                  :key="index"
                  class="row"
                >
                  <div class="col-7">
                    <div class="form-group">
                      <label :for="index"> {{ key }}</label>
                      <input
                        v-model="
                          newDeliveryMethod.dispatch_api_credentials[key]
                        "
                        type="text"
                        :class="{
                          'is-invalid': errors['dispatch_api_credentials'],
                        }"
                        class="form-control"
                        :id="index"
                        :placeholder="key"
                      />
                      <div
                        class="invalid-feedback"
                        v-for="(error, index) in errors[
                          'dispatch_api_credentials'
                        ]"
                        :key="index"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <div
                      class="botton-button"
                      v-if="!mandatoryCredential.includes(key)"
                    >
                      <button
                        class="btn btn-danger btn-sm"
                        @click="removeDCredential(key)"
                      >
                        - {{ key }}
                      </button>
                    </div>
                    <div class="botton-button" v-else>Mandatory Credential</div>
                  </div>
                </div>
                <button
                  class="btn btn-sm ml-3 mb-2"
                  :class="dispatchShow ? 'btn-secondary' : 'btn-info'"
                  @click="dispatchShow = !dispatchShow"
                >
                  {{ dispatchShow ? "-" : "+" }}
                  {{ $t("deliveryMethodsViewPage.addDispatch") }}
                </button>
                <div v-if="dispatchShow" class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="key">{{
                        $t("deliveryMethodsViewPage.key")
                      }}</label>
                      <input
                        v-model="dkey"
                        type="text"
                        id="key"
                        class="form-control"
                        placeholder="Key .."
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="value">{{
                        $t("deliveryMethodsViewPage.value")
                      }}</label>
                      <input
                        v-model="dvalue"
                        type="text"
                        id="value"
                        class="form-control"
                        placeholder="Value .."
                      />
                    </div>
                  </div>
                </div>
                <button
                  v-if="dispatchShow"
                  class="btn btn-success btn-sm ml-3 mb-2"
                  :class="dkey ? 'anim-fade-one' : 'visible-hidden'"
                  @click="addDCredential(dvalue, dkey)"
                >
                  {{ $t("deliveryMethodsViewPage.addDispatch") }}
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <a
                href="#"
                class="btn btn-primary float-right ml-3"
                v-on:click="updateDeliveryMethod()"
                >{{ $t("update") }}</a
              >
              <a
                id="editDeliveryMethodClose"
                href="#"
                class="btn btn-secondary float-right ml-3"
                data-dismiss="modal"
                >{{ $t("close") }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- end modal -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DeliveryMethodsService from "../../../services/DeliveryMethodsService";
import CarrierService from "../../../services/CarrierService";
import { BCard, BCardBody } from "bootstrap-vue";
import NProgress from "nprogress";
import HeaderTitle from "@/components/HeaderTitle";
import FieldView from "@/components/FieldView";
import FilterSelect from "@/components/FilterSelect";
import CooperationService from "../../../services/CooperationService";

const deliveryMethodsService = new DeliveryMethodsService();
const carrierService = new CarrierService();
const cooperationService = new CooperationService();

export default {
  name: "DeliveryMethodView",

  components: {
    "b-card": BCard,
    "b-card-body": BCardBody,
    "field-view": FieldView,
    "filter-select": FilterSelect,
    HeaderTitle,
  },
  data: () => {
    return {
      newDeliveryMethod: {
        name: "",
        service: "",
        type: "",
        carrier_id: "",
        owner: "",
        owner_reference: "",
        tracking_api_credentials: {},
        dispatch_api_credentials: {},
        slug_id: null,
        additional_settings: { nationalization: null },
      },
      nationalizationOptions: ["return to origin", "treat as abandoned"],
      ifNationalization: false,
      additional_settings: {},
      deliveryMethod: {},
      carrier: "",
      carriers: [],
      errors: [],
      services: ["postal", "courier"],
      trackShow: false,
      dispatchShow: false,
      tkey: "",
      tvalue: "",
      dkey: "",
      dvalue: "",
      mandatoryCredential: [],
      cooperations: [
        {
          name: null,
          id: null,
          integration_credentials: [
            {
              credential: "",
            },
          ],
        },
      ],
    };
  },
  beforeMount() {
    this.loadCurrentDeliveryMethod();
    this.loadCarriers();
    this.getCooperations();
  },
  methods: {
    setSlug(slug_id) {
      this.newDeliveryMethod.slug_id = slug_id;
      const selectedSlug = this.cooperations.find((c) => c.id === slug_id);
      this.mandatoryCredential = selectedSlug.integration_credentials.map(
        (c) => c.credential,
      );
      this.setNationalization(selectedSlug.title);
      this.mandatoryCredential.forEach((c) => {
        !Object.keys(this.newDeliveryMethod.dispatch_api_credentials).includes(
          c,
        ) && this.addDCredential("", c);
      });
    },
    setNationalization(selectedSlug) {
      this.ifNationalization = ["correios", "skypostal"].includes(selectedSlug);
      if (!this.ifNationalization) {
        this.newDeliveryMethod.additional_settings.nationalization = null;
      }
    },
    setcredentialsDefault(selectedSlug, credentials) {
      this.newDeliveryMethod.dispatch_api_credentials = {};
      this.mandatoryCredential = credentials;
      credentials.forEach((c) => {
        this.addDCredential("", c);
      });
    },
    loadCurrentDeliveryMethod() {
      deliveryMethodsService
        .getOneDeliveryMethod(this.$route.params.id)
        .then((response) => {
          this.deliveryMethod = response.data.data;
          this.deliveryMethod.slug = this.deliveryMethod.slug.title;
          carrierService
            .getOneCarrier(this.deliveryMethod.carrier_id)
            .then((response) => {
              this.carrier = response.data.data.name;
            });
        });
    },
    loadCarriers() {
      carrierService.getCarriers().then((response) => {
        this.carriers = response.data.data.elements;
      });
    },
    copyDeliveryMethod() {
      this.errors = [];
      //this.newDeliveryMethod = JSON.parse(JSON.stringify(this.deliveryMethod));
      this.newDeliveryMethod = this.deliveryMethod;
    },
    stringToArray(string) {
      string = string + " ";
      string = string.split(" ");
      string.pop();
      return string;
    },
    getCooperations() {
      cooperationService.getCooperations().then((response) => {
        this.cooperations = response.data.data;
      });
    },
    updateDeliveryMethod() {
      deliveryMethodsService
        .updateDeliveryMethod(this.$route.params.id, this.newDeliveryMethod)
        .then(() => {
          document.getElementById("editDeliveryMethodClose").click();
          this.loadCurrentDeliveryMethod();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
          NProgress.done();
        });
    },
    removeTCredential(key) {
      delete this.newDeliveryMethod.tracking_api_credentials[key];
      /* reload tracking_api_credentials */
      this.trackShow = !this.trackShow;
      this.trackShow = !this.trackShow;
    },
    addTCredential(value, key) {
      this.newDeliveryMethod.tracking_api_credentials[key] = value;
      this.tkey = "";
      this.tvalue = "";
    },
    removeDCredential(key) {
      delete this.newDeliveryMethod.dispatch_api_credentials[key];
      /* reload dispatch_api_credentials */
      this.dispatchShow = !this.dispatchShow;
      this.dispatchShow = !this.dispatchShow;
    },
    addDCredential(value, key) {
      this.newDeliveryMethod.dispatch_api_credentials[key] = value;
      this.dkey = "";
      this.dvalue = "";
    },
  },
  computed: {
    ...mapState({
      site: (state) => state.site,
    }),
    title() {
      return this.$t("deliveryMethodsViewPage.title") + this.$route.params.id;
    },
    isMadeBySeller() {
      let dataToReturn = [];
      if (this.deliveryMethod.is_made_by_seller) {
        const badge = {
          value: "Created By Seller",
          variant: "danger",
        };
        dataToReturn.push(badge);
      }
      return dataToReturn;
    },
  },
};
</script>

<style scoped>
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.2rem 0.6rem;
  margin-left: 3px;
  margin-right: 4px;
}
.botton-button {
  position: absolute;
  bottom: 24px;
}
</style>
