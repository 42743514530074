import api from "./HttpService";

export default class PackingSlipService {
  /**
   * Generate packing slip label
   *
   * @param orderId
   * @returns {Promise}
   */
  generate(orderId) {
    return api.put("/v1/orders/" + orderId + "/packing-slip");
  }
}
