<template>
  <NbButton
    :variant="variant"
    @click="generateVolumeLabel()"
    :disabled="loading"
  >
    <slot>
      {{ $t("volumesViewPage.label") }}
    </slot>
  </NbButton>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import OrderLabelService from "@/services/OrderLabelService.js";
const orderLabelService = new OrderLabelService();

export default {
  props: {
    volumeId: {
      type: [String, Number],
      required: true,
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
  components: {
    NbButton,
  },
  data() {
    return { loading: false };
  },
  methods: {
    async generateVolumeLabel() {
      try {
        this.loading = true;
        await orderLabelService
          .generateVolumeLabel(this.volumeId)
          .then((response) => {
            if (response?.data?.data?.download_url) {
              window.open(response.data.data.download_url);
            }
          });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
