import api from "./HttpService";

export default class DeliveryMethodsService {
  /**
   * Get DeliveryMethods by id
   *
   * @param id
   * @returns {Promise}
   */
  getOneDeliveryMethod(id) {
    return api.get("/v1/delivery_methods/" + id);
  }

  /**
   * Get DeliveryMethods
   *
   * @param offset = number, track = filters
   * @returns {Promise}
   */
  getTwentyDeliveryMethod(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/delivery_methods`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/delivery_methods?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  /**
     * Delete DeliveryMethods
     *
     * @param id
     * @returns {Promise}
     
    deleteDeliveryMethod (id) {
        return api.delete('/v1/delivery_methods/' + id);
    }
    */

  /**
   * Get DeliveryMethods
   *
   * @param filters
   * @returns {Promise}
   */
  getAllDeliveryMethods(/* filters = {} */) {
    return api.get("/v1/delivery_methods?limit=99999");
    /* return api.get('/v1/delivery_methods', {
            params: filters
        }); */
  }

  /**
   * Get DM Not Made By Seller
   *
   * @returns {Promise}
   */
  getDmNotMadeBySeller() {
    return api.get("/v1/dm_not_made_by_seller");
  }

  /**
   * Update DeliveryMethods
   *
   * @param id
   * @param data
   * @returns {Promise}
   */
  updateDeliveryMethod(id, data = {}) {
    return api.put("/v1/delivery_methods/" + id, data);
  }
  /**
   * Set DeliveryMethods
   *
   * @param data
   * @returns {Promise}
   */
  setDeliveryMethod(data = {}) {
    return api.post("/v1/delivery_methods/", data);
  }
}
