import actions from "./actions";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    items: [],
    all_items: [],
    filters: [],
    currentPage: 1,
    totalRows: 1,
    limit: 20,
  },
  mutations: mutations,
  actions: actions,
};
