<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 168 40.159">
    <g data-name="Grupo 3">
      <g data-name="Grupo 2" :fill="fill">
        <path
          data-name="Caminho 40"
          d="M78.45 13.293a1.343 1.343 0 0 0-1.529 1.335c0 .521.381.845 1.147.845.973 0 1.547-.538 1.547-1.3 0-.521-.384-.878-1.165-.878"
        />
        <path
          data-name="Caminho 41"
          d="M79.167 16.918h-2.06a.5.5 0 0 0-.488.417l-1.407 8.842h2.482l1.308-8.222a4.417 4.417 0 0 1 .256-1.037Z"
        />
        <path
          data-name="Caminho 42"
          d="M110.98 16.739a4.1 4.1 0 0 0-3.752 1.8l.706-4.364a.613.613 0 0 0-.592-.726h-2.007a4.523 4.523 0 0 1-.057 2.285l-1.635 10.439h2.378l.5-2.749c.613-3.435 1.822-5 3.418-5 .649 0 .966.465.966 1.355a17.976 17.976 0 0 1-.3 2.323l-.706 4.066h2.617l.743-4.364a12.668 12.668 0 0 0 .223-2.137c0-1.8-.891-2.934-2.508-2.934"
        />
        <path
          data-name="Caminho 43"
          d="m88.327 17.11-.485 1.171a2.834 2.834 0 0 0-2.767-1.542c-3.064-.075-5.071 3.232-5.016 6.3 0 2.061 1.134 3.472 2.879 3.472a4.573 4.573 0 0 0 3.73-2.019l.219 1.258a.513.513 0 0 0 .508.43h1.56s-.024-.055-.064-.176a1.622 1.622 0 0 1-.058-.706l1.238-7.516a5.557 5.557 0 0 1 .26-1.041h-1.412a.655.655 0 0 0-.592.371m-4.441 7.874c-.816 0-1.226-.779-1.226-2.116-.018-2.116 1.187-4.735 2.768-4.662a2.005 2.005 0 0 1 1.765 1.617l-.093.538c-.41 2.49-1.859 4.624-3.214 4.624"
        />
        <path
          data-name="Caminho 44"
          d="M117.489 15.474c.973 0 1.549-.538 1.549-1.3 0-.521-.384-.878-1.165-.878a1.343 1.343 0 0 0-1.531 1.335c0 .519.381.845 1.147.845"
        />
        <path
          data-name="Caminho 45"
          d="M118.673 16.918h-2.153a.492.492 0 0 0-.487.417l-1.407 8.842h2.482l1.31-8.24a4.364 4.364 0 0 1 .255-1.019"
        />
        <path
          data-name="Caminho 46"
          d="M126.533 16.739a4.365 4.365 0 0 0-3.731 1.765l-.187-1.152a.537.537 0 0 0-.521-.426h-1.561l.059.164a1.432 1.432 0 0 1 .053.781l-2.06 12.464h2.6l.909-5.368a2.38 2.38 0 0 0 2.528 1.542c2.8.11 5.107-3.045 5.068-6.166 0-2.193-1.152-3.6-3.157-3.6m-2.3 8.154c-1.023 0-1.578-1.023-1.578-2.583a6.622 6.622 0 0 1 .093-1.023l.167-1.059a3.543 3.543 0 0 1 2.656-1.987c.948 0 1.43.688 1.43 2.082.018 1.893-1.189 4.642-2.767 4.569"
        />
        <path
          data-name="Caminho 47"
          d="M153.787 16.746a7.555 7.555 0 0 0-1.482.159 4.407 4.407 0 0 0-2.676 1.575c-.006.005-.009.013-.015.018l-.2-1.149a.515.515 0 0 0-.509-.43h-1.562s.026.055.066.176a1.712 1.712 0 0 1 .049.781l-1.4 8.3h2.625l.5-2.573c.609-3.241 1.954-5.286 3.548-5.286.629 0 .942.433.942 1.278a11.476 11.476 0 0 1-.293 2.16l-.84 4.421h2.647l.858-4.7a10.946 10.946 0 0 0 .23-2c0-1.652-.88-2.733-2.5-2.733"
        />
        <path
          data-name="Caminho 48"
          d="M75.008 17.607a4.871 4.871 0 0 0-3.312-.845 3.907 3.907 0 0 0-3.024 1.339 2.833 2.833 0 0 0-.423 2.288c.26 1.14 1.979 1.983 2.04 2.01a1.575 1.575 0 0 1 .8 1.026 1.675 1.675 0 0 1-.37 1.233 2.241 2.241 0 0 1-2 .595 4.8 4.8 0 0 1-2.065-1.218s-.031 1.293 0 1.536c.031.227.2.278.516.457a4.637 4.637 0 0 0 2.08.479c.119 0 .258.007.411.007a4.857 4.857 0 0 0 3.537-1.249 3.052 3.052 0 0 0 .761-2.446 2.871 2.871 0 0 0-1.242-1.774 15.2 15.2 0 0 0-1.335-.8c-.757-.505-.871-.75-.842-1.109.04-.574.538-1 1.333-1.134a2.676 2.676 0 0 1 2.294.681l.094.071.946-1Z"
        />
        <path
          data-name="Caminho 49"
          d="M100.274 14.823a3.088 3.088 0 0 1 2.879.958l.966-1.09-.091-.095a5.25 5.25 0 0 0-3.883-1.129c-3.938.339-4.494 3.084-4.216 4.78a4.054 4.054 0 0 0 2.323 2.652 2.1 2.1 0 0 1 1.087 2.7c-1.292 2.683-4.2.85-4.858.353-.234-.178-.39-.3-.39-.3s-.036 1.353 0 1.619a.75.75 0 0 0 .576.715 8.616 8.616 0 0 0 3.084.527c4.739 0 6.168-4.5 3.459-6.867-.527-.461-1.891-1.394-1.891-1.394-.854-.677-.918-1.074-.863-1.7a2 2 0 0 1 1.82-1.729"
        />
        <path
          data-name="Caminho 50"
          d="m65.294 24.818-.13-10.289a.84.84 0 0 0-.891-.856H62.1l-.018.114a5.639 5.639 0 0 1-.836 1.761c-1.728 2.6-6.373 9.826-6.835 10.423l-.187.2h2.266l.038-.075c.371-.741 2.581-4.346 2.581-4.346a4.344 4.344 0 0 1 2.879.8 1.285 1.285 0 0 1 .5.984v1.857a.784.784 0 0 0 .223.558.727.727 0 0 0 .558.223h2.358a1.563 1.563 0 0 1-.278-.706 13.285 13.285 0 0 1-.057-.65m-2.8-3.752a6.52 6.52 0 0 0-2.6-.633l2.711-4.549Z"
        />
        <path
          data-name="Caminho 51"
          d="M137.452 16.739a4.365 4.365 0 0 0-3.731 1.765l-.207-1.152a.511.511 0 0 0-.5-.426h-1.562l.057.164a1.83 1.83 0 0 1 .055.781l-2.061 12.464h2.6l.891-5.368a2.4 2.4 0 0 0 2.544 1.542c2.786.11 5.109-3.045 5.07-6.166 0-2.193-1.168-3.6-3.157-3.6m-2.3 8.154c-1.039 0-1.578-1.023-1.578-2.583a8.989 8.989 0 0 1 .075-1.023l.166-1.059a3.572 3.572 0 0 1 2.674-1.987c.947 0 1.43.688 1.43 2.082.018 1.893-1.207 4.642-2.768 4.569"
        />
        <path
          data-name="Caminho 52"
          d="M144.753 13.293a1.345 1.345 0 0 0-1.531 1.335c0 .521.38.845 1.147.845.973 0 1.549-.538 1.549-1.3 0-.521-.384-.878-1.165-.878"
        />
        <path
          data-name="Caminho 53"
          d="M145.464 16.918h-2.062a.5.5 0 0 0-.488.417l-1.4 8.842h2.481l1.311-8.242a4.343 4.343 0 0 1 .254-1.017Z"
        />
        <path
          data-name="Caminho 54"
          d="M22.779 20.509c-.02-.166-.039-.371-.059-.647l-.128-10.229a.835.835 0 0 0-.885-.851h-2.16l-.02.112a5.584 5.584 0 0 1-.831 1.755c-1.716 2.585-6.333 9.768-6.8 10.358l-.183.2h2.251l.038-.073c.37-.737 2.566-4.319 2.566-4.319a4.331 4.331 0 0 1 2.863.794 1.28 1.28 0 0 1 .5.979v1.848a.78.78 0 0 0 .221.552.726.726 0 0 0 .554.22h2.345a1.511 1.511 0 0 1-.276-.7m-2.844-4.377a6.411 6.411 0 0 0-2.584-.627l2.7-4.523Z"
        />
        <path
          data-name="Caminho 55"
          d="M28.741 16.561a3.072 3.072 0 0 1 2.86.953l.96-1.083-.093-.1a5.214 5.214 0 0 0-3.857-1.121c-3.913.337-4.469 3.066-4.191 4.75a4.018 4.018 0 0 0 2.306 2.638 2.089 2.089 0 0 1 1.081 2.682c-1.283 2.667-4.175.843-4.827.349-.234-.177-.39-.3-.39-.3s-.037 1.346 0 1.61a.741.741 0 0 0 .572.708 8.517 8.517 0 0 0 3.066.527c4.712 0 6.131-4.472 3.441-6.826-.525-.459-1.882-1.387-1.882-1.387-.848-.673-.913-1.066-.856-1.685a1.986 1.986 0 0 1 1.809-1.719"
        />
        <path
          data-name="Caminho 56"
          d="M9.399 6.545S9.792 2.907 15.426 0c0 0 2 4.379-6.027 6.545"
        />
        <path
          data-name="Caminho 57"
          d="M5.689 8.532S8.85 6.686 9.203.358c0 0-4.815-.031-3.514 8.174"
        />
        <path
          data-name="Caminho 58"
          d="M3.25 15.499s2.219-2.91.133-8.9c0 0-4.461 1.8-.133 8.9"
        />
        <path
          data-name="Caminho 59"
          d="M3.874 24.327s1.1-3.488-2.881-8.418c0 0-3.591 3.206 2.881 8.418"
        />
        <path
          data-name="Caminho 60"
          d="M5.114 15.631s-.828-3.563 3.528-8.169c0 0 3.329 3.476-3.528 8.169"
        />
        <path
          data-name="Caminho 61"
          d="M6.22 25.374s-2.561-2.612-1.235-8.811c0 0 4.651 1.236 1.235 8.811"
        />
        <path
          data-name="Caminho 62"
          d="M12.448 33.279s-3.56-.848-5.736-6.8c0 0 4.6-1.429 5.736 6.8"
        />
        <path
          data-name="Caminho 63"
          d="M7.808 31.513s-.331-3.642-5.912-6.649c0 0-2.07 4.346 5.912 6.649"
        />
        <path
          data-name="Caminho 64"
          d="M13.715 36.252s-1.739-3.219-8.054-3.783c0 0-.192 4.811 8.054 3.783"
        />
        <path
          data-name="Caminho 65"
          d="M20.55 38.407s-2.851-2.292-8.889-.364c0 0 1.687 4.509 8.889.364"
        />
        <path
          data-name="Caminho 66"
          d="M22.279 36.678s-3.441 1.244-8.526-2.542c0 0 3.058-3.715 8.526 2.542"
        />
        <path
          data-name="Caminho 67"
          d="M36.704 6.545S36.311 2.907 30.677 0c0 0-2 4.379 6.027 6.545"
        />
        <path
          data-name="Caminho 68"
          d="M40.414 8.532S37.253 6.686 36.9.358c0 0 4.815-.031 3.514 8.174"
        />
        <path
          data-name="Caminho 69"
          d="M42.853 15.499s-2.217-2.91-.133-8.9c0 0 4.463 1.8.133 8.9"
        />
        <path
          data-name="Caminho 70"
          d="M42.229 24.327s-1.1-3.488 2.881-8.418c0 0 3.591 3.206-2.881 8.418"
        />
        <path
          data-name="Caminho 71"
          d="M40.989 15.631s.828-3.563-3.529-8.169c0 0-3.329 3.476 3.529 8.169"
        />
        <path
          data-name="Caminho 72"
          d="M39.884 25.374s2.561-2.612 1.235-8.811c0 0-4.651 1.236-1.235 8.811"
        />
        <path
          data-name="Caminho 73"
          d="M33.655 33.279s3.56-.848 5.736-6.8c0 0-4.6-1.429-5.736 6.8"
        />
        <path
          data-name="Caminho 74"
          d="M38.296 31.513s.331-3.642 5.912-6.649c0 0 2.071 4.346-5.912 6.649"
        />
        <path
          data-name="Caminho 75"
          d="M32.388 36.252s1.739-3.219 8.052-3.783c0 0 .194 4.811-8.052 3.783"
        />
        <path
          data-name="Caminho 76"
          d="M25.552 38.407s2.852-2.292 8.888-.364c0 0-1.685 4.509-8.888.364"
        />
        <path
          data-name="Caminho 77"
          d="M23.825 36.678s3.44 1.244 8.525-2.542c0 0-3.058-3.715-8.525 2.542"
        />
        <path
          data-name="Caminho 78"
          d="M168 16.934h-1.412a.613.613 0 0 0-.576.351l-.463 1c-.412-1.116-1.487-1.524-2.844-1.524-3.157-.093-5.217 3.214-5.162 6.462 0 2.025 1.132 3.326 2.952 3.326a3.976 3.976 0 0 0 3.548-1.579l-.187 1.1a3.91 3.91 0 0 1-1.282 2.656 2.511 2.511 0 0 1-1.542.481 4.423 4.423 0 0 1-1.948-.556 10.207 10.207 0 0 1-1.191-.8s-.019 1.469 0 1.617a.612.612 0 0 0 .13.409 1 1 0 0 0 .207.185 2.293 2.293 0 0 0 .241.111 7.1 7.1 0 0 0 2.488.481 6.341 6.341 0 0 0 1.615-.185c2.155-.518 3.474-2.173 3.956-5.087 0 0 .9-5.619 1.227-7.487a4.731 4.731 0 0 1 .241-.964m-3.232 3.472c-.41 2.544-1.9 4.549-3.307 4.549-.818 0-1.244-.629-1.244-1.913-.038-2.246 1.207-4.827 2.841-4.752a2.008 2.008 0 0 1 1.82 1.577Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "AsiaLogoStamp",
  props: {
    fill: {
      type: String,
      default: "#fff",
    },
  },
};
</script>
