<template>
  <div ref="teste">
    <Backdrop v-if="open" />
    <Transition name="slide-fade" mode="out-in">
      <div
        v-if="open"
        class="custom-modal-container"
        ref="modal"
        @click="onBackdropClick"
      >
        <div class="custom-modal-content" :data-size="size">
          <button
            v-if="!preventClose"
            class="custom-modal-close"
            @click="closeModal"
          >
            <NbIcon icon="x" size="20" />
          </button>
          <slot></slot>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script>
import NbIcon from "../icons/NbIcon.vue";
import Backdrop from "./Backdrop.vue";

export default {
  components: {
    Backdrop,
    NbIcon,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    size: {
      // sm, md, lg
      type: String,
      default: "md",
    },
    preventClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentCount: null,
      id: Date.now(),
    };
  },
  watch: {
    open(newValue) {
      if (newValue) {
        this.createEventListener();
        this.incrementBodyCount();
      } else {
        this.$emit("close");
        this.decrementBodyCount();
        this.removeEventListener();
      }
    },
  },
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  provide() {
    const close = () => {
      this.closeModal();
    };
    return {
      context: {
        close,
      },
    };
  },
  methods: {
    closeModal() {
      this.open = false;
    },
    incrementBodyCount() {
      const modalCount = document.body.dataset.modalCount || 0;
      const count = Number(modalCount) + 1;
      document.body.dataset.modalCount = count;
      this.currentCount = count;
    },
    decrementBodyCount() {
      const modalCount = Number(document.body.dataset.modalCount) - 1;
      if (modalCount <= 0) {
        delete document.body.dataset.modalCount;
        return;
      }
      document.body.dataset.modalCount = modalCount;
    },
    onBackdropClick(event) {
      if (!this.preventClose && event.target === this.$refs?.modal) {
        this.closeModal();
        return;
      }
    },
    handleKeyUp(event) {
      if (event.key === "Escape") {
        const currentModalCount = Number(document.body.dataset.modalCount);

        if (
          !this.preventClose &&
          !isNaN(currentModalCount) &&
          this.currentCount === currentModalCount
        ) {
          this.closeModal();
        }
      }
    },
    createEventListener() {
      document.addEventListener("keyup", this.handleKeyUp);
    },
    removeEventListener() {
      document.removeEventListener("keyup", this.handleKeyUp);
    },
  },
  created() {
    if (this.value) {
      this.createEventListener();
      this.incrementBodyCount();
    }
  },
  beforeDestroy() {
    if (this.open) {
      this.decrementBodyCount();
    }
  },
};
</script>
<style lang="scss" scoped>
.custom-modal-container {
  position: fixed;
  inset: 0;
  z-index: 10;
  overflow-y: auto;
  padding: 0;
  @media screen and (min-width: 768px) {
    padding: 0.5rem;
  }
}
.custom-modal-content {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  // transform: translateY(0);
  position: relative;
  margin: 1rem auto;
  width: 100%;
  padding: 3.12rem 2.5rem 2.5rem 2.5rem;
  &[data-size="sm"] {
    max-width: 500px;
  }
  &[data-size="md"] {
    max-width: 800px;
  }
  &[data-size="lg"] {
    max-width: 1260px;
  }
  &[data-size="xl"] {
    max-width: 90vw;
  }
}
.custom-modal-close {
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  background-color: transparent;
  border: none;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}
</style>
