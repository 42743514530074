<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("trackingRuleNotificationsPage.ruleCreatedSuccessfully") }}
      </ModalTitle>
    </ModalHeader>

    <div>
      <p class="body-4">
        {{ $t("trackingRuleNotificationsPage.youWantActivate") }}
      </p>
    </div>

    <div>
      <p class="text-danger" v-for="error in errors" :key="error">
        {{ error }}
      </p>
    </div>

    <ModalFooter class="d-flex justify-content-end gap-4">
      <NbButton variant="secondary" @click="ruleContext?.success()">
        {{ $t("trackingRuleNotificationsPage.maybeLater") }}
      </NbButton>
      <NbButton @click="enable" :disabled="loading">
        {{ $t("enable") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import TrackingRuleNotificationsService from "@/services/TrackingRuleNotificationsService";

const trackingRuleNotificationsService = new TrackingRuleNotificationsService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    NbButton,
  },
  props: {
    ruleId: {
      type: [Number, null],
      default: null,
    },
  },
  inject: ["ruleContext"],
  data() {
    return {
      loading: false,
      errors: [],
    };
  },
  methods: {
    async enable() {
      try {
        this.loading = true;
        this.errors = [];
        await trackingRuleNotificationsService.toggleActivation(this.ruleId, {
          active: true,
        });

        this.ruleContext?.success();
      } catch (error) {
        const errorMessages = error?.response?.data?.messages;

        if (errorMessages?.length) {
          if (Array.isArray(errorMessages)) {
            const errorData = errorMessages.map((error) => {
              if (!Array.isArray(error)) {
                return Object.values(error)?.flatMap((item) => item);
              }

              return error;
            });

            this.errors = errorData?.flat();
            return;
          }

          this.errors = [errorMessages];
          return;
        }

        this.$bvToast.toast(this.$t("errorMessages.genericError"), {
          variant: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
