<template>
  <div>
    <ModalHeader>
      <div class="d-flex flex-column align-items-start">
        <ModalTitle>{{ currentUser?.name }}</ModalTitle>
        <NbBadge
          v-if="currentUser?.id"
          :text="
            currentUser?.is_blocked
              ? $t('usersPage.blocked')
              : $t('usersPage.active')
          "
          :type="currentUser?.is_blocked ? 'danger' : 'success'"
        />
      </div>
    </ModalHeader>

    <Spinner v-if="loading" size="lg" class="mx-auto" />

    <div v-else>
      <div class="grid gap-4 grid-cols-3">
        <NbCard
          class="grid-column-2"
          id="suggested"
          :title="$t('suggestedActions')"
        >
          <template #body>
            <div class="grid gap-2">
              <NbButton @click="isEditModalOpen = true">
                {{ $t("edit") }}
              </NbButton>
              <GenerateApiTokenButton
                v-if="currentUser.profile === 'seller'"
                :user-id="currentUser.id"
              />
            </div>
          </template>
        </NbCard>
        <NbCard class="grid-column-3" id="actions" :title="$t('actions')">
          <template #body>
            <div class="grid gap-2">
              <ToggleUserStatusButton
                :id="currentUser.id"
                :is-user-blocked="currentUser.is_blocked"
                @success="onUpdateUserStatus"
              />
              <ArchiveUserButton :user="currentUser" @archived="onRemoveUser" />
              <ResetUserPasswordButton :user="currentUser" />
            </div>
          </template>
        </NbCard>
      </div>
      <div class="grid gap-4 mt-4">
        <NbCardBox class="personal-data">
          <h3 class="heading-3">{{ $t("usersPage.personalData") }}</h3>

          <div class="grid grid-cols-2 gap-4">
            <NbTextInput
              :value="currentUser.name"
              id="name"
              :name="$t('usersPage.name')"
              placeholder="-"
              disabled
              variant="borderless-gray-60"
            />
            <NbTextInput
              :value="currentUser.email"
              id="email"
              :name="$t('usersPage.email')"
              disabled
              variant="borderless-gray-60"
              placeholder="-"
            />
            <NbTextInput
              :value="currentUser.phone"
              id="phone"
              :name="$t('usersPage.phone')"
              placeholder="-"
              :masks="['+#*']"
              disabled
              variant="borderless-gray-60"
            />
            <NbTextInput
              :value="currentUser.whatsapp_number"
              id="whatsapp_number"
              :name="$t('usersPage.whatsappNumber')"
              placeholder="-"
              :masks="['+#*']"
              disabled
              variant="borderless-gray-60"
            />
            <router-link
              v-if="currentUser.profile !== 'admin'"
              :to="getCompanyRedirect"
            >
              <NbTextInput
                :value="currentUser.company_name"
                id="company_name"
                :name="$t('usersPage.fields.company')"
                placeholder="-"
                disabled
                variant="borderless-gray-60"
              />
            </router-link>
            <NbTextInput
              v-else
              :value="currentUser.company_name"
              id="company_name"
              :name="$t('usersPage.fields.company')"
              placeholder="-"
              disabled
              variant="borderless-gray-60"
            />
          </div>
          <div class="d-flex flex-column justify-content-end align-items-end">
            <strong>{{ $t("sellersPage.fields.creationDate") }}</strong>
            <span class="d-block">
              {{ currentUser.created_at | dateTime }}
            </span>
          </div>
        </NbCardBox>

        <NbCardBox class="permissions">
          <h3 class="heading-3">{{ $t("usersPage.permissionsAndAccess") }}</h3>

          <div class="grid grid-cols-2 gap-4">
            <NbTextInput
              :value="currentUser.profile"
              id="user.profile"
              :name="$t('usersPage.profile')"
              placeholder="-"
              disabled
              variant="borderless-gray-60"
            />
            <NbTextInput
              :value="sanitizeGroupName(currentUser.group_name)"
              id="user.group_name"
              :name="$t('usersPage.group')"
              placeholder="-"
              disabled
              variant="borderless-gray-60"
            />
            <NbTextInput
              :value="currentUser.is_supervisor"
              id="user.is_supervisor"
              :name="$t('usersPage.fields.supervisor')"
              placeholder="-"
              disabled
              variant="borderless-gray-60"
            />
          </div>
        </NbCardBox>
      </div>
    </div>

    <ModalFooter>
      <ModalClose class="d-flex ml-auto" />
    </ModalFooter>

    <Modal v-model="isEditModalOpen">
      <UserUpsertModal :user="currentUser" @success="onUpdateUser" />
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCardBox from "@/components/cards/NbCardBox.vue";
import CarrierService from "@/services/CarrierService";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbCard from "@/components/cards/NbCard.vue";
import ArchiveUserButton from "./ArchiveUserButton.vue";
import GenerateApiTokenButton from "./GenerateApiTokenButton.vue";
import UserUpsertModal from "./UserUpsertModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import { sanitizeGroupName } from "@/utils/parser";
import ToggleUserStatusButton from "./ToggleUserStatusButton.vue";
import { Profile } from "@/constants";
import moment from "moment";
import UsersService from "../../../../services/UsersService";
import Spinner from "@/components/Spinner.vue";
import ResetUserPasswordButton from "./ResetUserPasswordButton.vue";

const carrierService = new CarrierService();
const userService = new UsersService();

export default {
  components: {
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbTextInput,
    NbBadge,
    NbCardBox,
    NbCard,
    ArchiveUserButton,
    GenerateApiTokenButton,
    UserUpsertModal,
    NbButton,
    ToggleUserStatusButton,
    Spinner,
    ResetUserPasswordButton,
  },
  filters: {
    dateTime(value) {
      return value && moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  inject: ["context"],
  data() {
    return {
      loading: false,
      userId: null,
      carrier: null,
      isEditModalOpen: false,
      sanitizeGroupName,
      currentUser: {},
    };
  },
  computed: {
    getCompanyRedirect() {
      if (this.currentUser?.profile === Profile.CARRIER) {
        return `/users_management/carriers?id=${this.currentUser?.carrier_id}`;
      }

      return `/users_management/sellers?id=${this.currentUser?.seller_id}`;
    },
  },
  methods: {
    onRemoveUser() {
      this.$emit("refresh");
      this.context?.close();
    },
    onUpdateUserStatus(isBlocked) {
      this.currentUser.is_blocked = isBlocked;
      this.$emit("updated", this.currentUser);
    },
    onUpdateUser(data) {
      this.currentUser = data;
      this.isEditModalOpen = false;
      this.$emit("updated", data);
    },
    async loadCarrier() {
      const response = await carrierService.getCarrier(
        this.currentUser?.carrier_id,
      );
      this.carrier = response.data.data;
    },
    async loadUser() {
      try {
        this.loading = true;
        const { data } = await userService.getUser(this.userId);

        this.currentUser = {
          ...data.data,
          company_name:
            data.data.profile !== Profile.ADMIN
              ? `${data.data.company_name} (${data.data.profile})`
              : data.data.company_name,
        };

        if (data?.data?.carrier_id) {
          await this.loadCarrier();
        }
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    const { id } = this.$route.query;
    this.userId = id;
    this.loadUser();
  },
};
</script>
