<template>
  <div
    class="card-items borded d-flex justify-content-between pointer"
    :class="classes"
    @click="select"
  >
    <div class="d-flex justify-content-between">
      <div class="img-empty" :class="image ? 'img-box' : ''">
        <img
          v-if="image"
          :src="image"
          style="
            height: 4.25rem;
            width: 4.45rem;
            object-fit: contain;
            padding: 0.2rem;
          "
        />
      </div>
      <div class="block-text-1">
        <div>{{ title }}</div>
        <div class="mt-2">
          <RatingStars
            v-if="ratedStarts"
            :current-rating="ratedStarts"
            size="0.6rem"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div class="mr-5">
        <div class="card-text-title">
          {{ secondTitle }}
          <NbHelpText
            v-if="secondHelpText"
            :id="`helptext-cardItems-${id}-1`"
            size="sm"
            >{{ secondHelpText }}</NbHelpText
          >
        </div>
        <div class="card-text">{{ secondText }}</div>
      </div>
      <div>
        <div class="card-text-title">
          {{ thirdTitle }}
          <NbHelpText
            v-if="thirdHelpText"
            :id="`helptext-cardItems-${id}-2`"
            size="sm"
            >{{ thirdHelpText }}</NbHelpText
          >
        </div>
        <div class="card-text">{{ thirdText }}</div>
      </div>
    </div>
    <div class="center mt-06rem">
      <NbRadio
        :id="`radio-cardItems-${id}`"
        v-model="content"
        name=""
        :options="cardConfig"
      />
    </div>
  </div>
</template>

<script>
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";
import RatingStars from "@/components/ratingstars/RatingStars.vue";

export default {
  name: "NbCardItems",
  components: {
    NbHelpText,
    NbRadio,
    RatingStars,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    cardConfig: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    ratedStarts: {
      type: Number,
      default: null,
    },
    secondTitle: {
      type: String,
      default: "",
    },
    secondText: {
      type: String,
      default: "",
    },
    secondHelpText: {
      type: String,
      default: "",
    },
    thirdTitle: {
      type: String,
      default: "",
    },
    thirdText: {
      type: String,
      default: "",
    },
    thirdHelpText: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      /* cardConfig: [
        { text: '', value: 'Value1' },
      ], */
      content: this.value,
    };
  },
  computed: {
    classes() {
      //cardSelected != '' ? 'selected' : ''
      if (this.content == this.cardConfig[0].value) {
        return "selected";
      }
      return "";
    },
  },
  watch: {
    //altera o input (way data bind ->)
    content(newValue) {
      this.$emit("input", newValue);
    },
    //altera o input vindo do pai (way data bind <-)
    value(newValue) {
      this.content = newValue;
    },
  },
  methods: {
    select() {
      if (this.content == this.cardConfig[0].value) {
        this.reset();
        return;
      }
      this.content = this.cardConfig[0].value;
    },
    reset() {
      this.content = "";
    },
  },
};
</script>

<style scoped>
.card-items {
  transition: all 0.3s ease;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 8px var(--gray-05);
  border: 1px solid var(--gray-05);
  border-radius: 4px;
  padding: 1rem 0rem 1rem 1.5rem;
}
.card-items.borded.selected {
  border: 1px solid var(--black);
}
.card-items:hover {
  background: var(--gray-05) 0% 0% no-repeat padding-box;
  border: 1px solid var(--gray-20);
}
.borded {
  border: 1px solid var(--gray-20);
}
.img-empty {
  height: 4.25rem;
  width: 4.45rem;
  background: var(--gray-10);
  margin-right: 1rem;
}
.img-empty.img-box {
  background: var(--gray-05);
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.mt-06rem {
  margin-top: 0.6rem !important;
}
.block-text-1 {
  max-width: 9.38rem;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-16) / var(--unnamed-line-spacing-20)
    var(--unnamed-font-family-nunito-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--black);
  text-align: left;
  font: normal normal bold 16px/20px Nunito Sans;
  letter-spacing: 0px;
}
.card-text-title {
  color: var(--black);
  text-align: center;
  font: normal normal 600 12px/16px Nunito Sans;
  letter-spacing: 0px;
  margin-bottom: 0.5rem;
}
.card-text {
  color: var(--black);
  text-align: center;
  font: normal normal bold 20px/24px Nunito Sans;
  letter-spacing: 0px;
}
</style>
