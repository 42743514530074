<template>
  <div>
    <h3 class="heading-4 mb-4">
      {{ $t("trackingRuleNotificationsPage.applyRuleTo") }}
    </h3>
    <div class="d-flex flex-column gap-4">
      <NbSelectInput
        id="subject-type"
        v-model="subjectType"
        :options="subjectOptions"
        :name="$t('selectAnOption')"
        required
      />
    </div>

    <div class="d-flex justify-content-between mt-4">
      <NbButton variant="secondary" @click="goBack">{{ $t("back") }}</NbButton>
      <NbButton :disabled="!isValid" @click="goNext()">
        {{ $t("next") }}
      </NbButton>
    </div>
  </div>
</template>

<script>
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import { mapState, mapMutations, mapActions } from "vuex";

const ALL_SHIPMENTS = "all_shipments";
const SOME_SHIPMENTS = "some_shipments";

export default {
  components: {
    NbSelectInput,
    NbButton,
  },
  data() {
    return {
      sellerOptions: [],
    };
  },
  computed: {
    ...mapState({
      applyRuleTo: (state) => state.notificationRuleSettings.applyRuleTo,
    }),
    subjectType: {
      get() {
        return this.applyRuleTo;
      },
      set(val) {
        this.updateApplyRuleTo(val);
      },
    },
    subjectOptions() {
      return [
        {
          text: this.$t("trackingRuleNotificationsPage.allShipments"),
          value: ALL_SHIPMENTS,
        },
        {
          text: this.$t("trackingRuleNotificationsPage.someShipmentsOnly"),
          value: SOME_SHIPMENTS,
        },
      ];
    },
    isValid() {
      return !!this.subjectType;
    },
  },
  methods: {
    ...mapMutations("notificationRuleSettings", {
      updateApplyRuleTo: "UPDATE_APPLY_RULE_TO",
    }),
    ...mapActions("notificationRuleSettings", {
      applyRuleToAllShipments: "applyRuleToAllShipments",
      applyRuleToSomeShipments: "applyRuleToSomeShipments",
    }),
    goNext() {
      if (this.applyRuleTo === ALL_SHIPMENTS) {
        this.applyRuleToAllShipments();
      } else {
        this.applyRuleToSomeShipments();
      }

      this.$emit("next");
    },
    goBack() {
      this.$emit("back");
    },
  },
};
</script>

<style lang="scss" scoped></style>
