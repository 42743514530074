<template>
  <div>
    <header-title :title="title" />
    <div class="c justify-content-center">
      <div class="col-12 mb-5">
        <div class="card border-white rounded py-4">
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('masterboxesViewPage.dispatchID')"
              :val="box.dispatch_id"
            />
            <field-view
              :field="$t('masterboxesViewPage.reference')"
              :val="box.reference"
              :copyable="box.reference ? true : false"
            />
            <div class="col-2">
              <h6>{{ $t("masterboxesViewPage.cN35Label") }}</h6>
              <div v-if="box.reference">
                <a
                  href="#"
                  title="cn35 Label"
                  @click="generateMasterBoxLabel(box.id)"
                >
                  <i class="far fa-file-alt fa-lg text-dark mt-2"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="row mx-5 my-2">
            <field-view
              :field="$t('masterboxesViewPage.shipmentID')"
              :val="box.shipment_id"
              :link="`/shipping/shipments?id=${box.shipment_id}`"
              link-id=""
            />
            <field-view
              :field="$t('masterboxesViewPage.shipmentSequenceNumber')"
              :val="box.shipment_sequence_number"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('masterboxesViewPage.date')"
              :val="box.created_at"
            />
          </div>
        </div>
        <div class="card border-white rounded py-4 my-5">
          <div class="row mx-5 my-2">
            <div class="col">
              <!-- volumes table -->
              <div v-if="box.volumes && box.volumes.length > 0" class="card">
                <div class="card-header">
                  {{ $t("masterboxesViewPage.volumes") }} ({{
                    box.volumes.length
                  }})
                  <b-button
                    class="float-right"
                    variant="primary"
                    title="Download Masterbox Volumes"
                    @click="downloadMasterboxVolumes(box.id)"
                    >{{ $t("download") }}</b-button
                  >
                </div>
                <div class="card-body">
                  <b-table
                    id="volumes-table"
                    responsive="md"
                    hover
                    small
                    striped
                    :items="box.volumes"
                    :fields="volumesFields"
                  >
                    <template #cell(id)="data">
                      <router-link :to="`/shipping/volumes?id=` + data.value">{{
                        data.value
                      }}</router-link>
                    </template>
                    <template #cell(weight)="data">
                      {{ standardizeValue(data.value) }}
                    </template>
                    <template #cell(cubed_weight)="data">
                      {{ standardizeValue(data.value) }} kg
                    </template>
                    <template #cell(order_items)="data">
                      {{ data.item.items_count }}
                    </template>
                    <template #cell(last_mile_carrier_number)="data">
                      {{ data.item.last_mile_carrier_number }}
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between mx-5">
      <div></div>
      <div>
        <b-button
          variant="danger"
          title="Delete this Masterbox"
          @click="$bvModal.show('delete-masterbox')"
          >{{ $t("delete") }}</b-button
        >
      </div>
    </div>

    <!-- modals -->
    <b-modal
      id="delete-masterbox"
      title="Delete Masterbox"
      no-close-on-esc
      no-close-on-backdrop
      and
      hide-header-close
    >
      <div class="row px-4">
        {{ deleteMessage }}
      </div>
      <div v-if="request_id" class="py-3 px-1">
        {{ $t("masterboxesViewPage.copyReference_id") }} --><CopyClipboard
          class="ml-2"
          title="Copy reference id to Clipboard"
          :copy-val="request_id"
        />
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="closeDeleteMasterboxModal">{{
          $t("close")
        }}</b-button>
        <b-button
          v-if="!request_id"
          variant="danger"
          title="Delete Masterbox"
          @click="deleteMasterbox"
          >{{ $t("delete") }}</b-button
        >
      </template>
    </b-modal>
    <!-- end modal -->
  </div>
</template>

<script>
import { /* BPagination ,*/ BTable } from "bootstrap-vue";
import MasterBoxService from "../../../services/MasterBoxService";
import FieldView from "@/components/FieldView";
import HeaderTitle from "@/components/HeaderTitle";
import CopyClipboard from "@/components/CopyClipboard";

const masterBoxService = new MasterBoxService();

export default {
  name: "MasterBoxesView",
  components: {
    /* "b-pagination": BPagination, */
    "b-table": BTable,
    "field-view": FieldView,
    HeaderTitle,
    CopyClipboard,
  },
  data: () => {
    return {
      box: {},
      deleteMessage: "Do you want to remove this masterbox permanently?",
      request_id: null,
    };
  },
  computed: {
    currentItems() {
      let end = this.currentPage * this.perPage;
      let begin = end - (this.perPage - 1);
      return `${begin} - ${end}`;
    },
    title() {
      return this.$t("masterboxesViewPage.title") + this.$route.params.id;
    },
    volumesFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "weight",
          label: this.$t("masterboxesViewPage.fields.weight"),
          sortable: false,
        },
        {
          key: "cubed_weight",
          label: this.$t("masterboxesViewPage.fields.cubedWeight"),
          sortable: false,
        },
        {
          key: "last_mile_tracking_number",
          label: this.$t("masterboxesViewPage.fields.lastMileTrackingNumber"),
          sortable: false,
        },
        {
          key: "last_mile_carrier_number",
          label: this.$t("masterboxesViewPage.fields.lastMileCarrierNumber"),
          sortable: false,
        },
        {
          key: "order_items",
          label: this.$t("masterboxesViewPage.fields.totalItems"),
          sortable: false,
        },
      ];
    },
  },
  beforeMount() {
    this.loadCurrentBox();
  },
  methods: {
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    downloadMasterboxVolumes(masterbox_id) {
      const data = { item_id: masterbox_id, data_type: "masterbox_volumes" };
      masterBoxService.downloadMasterboxVolumes(data).then(() => {
        this.$router.push("/archives");
      });
    },
    loadCurrentBox() {
      masterBoxService.getMasterBox(this.$route.params.id).then((response) => {
        this.box = response.data.data;
        let time = this.box.created_at;
        let date = this.box.created_at;

        this.box.created_at = this.formateDate(date, time);
      });
    },
    generateMasterBoxLabel(id) {
      masterBoxService.generateMasterBoxLabel(id).then((response) => {
        if (
          // eslint-disable-next-line no-prototype-builtins
          response.data.hasOwnProperty("data") &&
          // eslint-disable-next-line no-prototype-builtins
          response.data.data.hasOwnProperty("download_url")
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    deleteMasterbox() {
      masterBoxService
        .deleteMasterBox(this.box.reference)
        .then((response) => {
          this.request_id = response.data.data.request_id;
          this.deleteMessage = response.data.messages[0].replace(
            "request_id",
            this.request_id,
          );
        })
        .catch(() => {
          this.deleteMessage =
            "Do you want to remove this masterbox permanently?";
          this.request_id = null;
        });
    },
    closeDeleteMasterboxModal() {
      this.$bvModal.hide("delete-masterbox");
      this.deleteMessage = "Do you want to remove this masterbox permanently?";
      this.request_id = null;
    },
    standardizeValue(val) {
      const finalVal = parseFloat(val).toFixed(2);
      if (finalVal === "NaN") {
        return "0.00";
      }
      return finalVal;
    },
  },
};
</script>
