<template>
  <div class="container-fluid login-page bg-primary">
    <div class="row d-flex justify-content-center">
      <div class="col-10 col-lg-4 col-xl-2 px-4 py-3">
        <div class="row align-items-center" style="height: calc(100vh - 150px)">
          <div class="col">
            <TotalLogoStamp
              v-if="$store.state.platform === 'total'"
              :image="require('../assets/img/PNG/total-express-logo-white.png')"
              class="mb-3"
              height="40"
              width="auto"
            />
            <IconLogo v-else class="mb-3" />
            <form @submit.prevent="resetPassword">
              <div class="form-group">
                <input
                  v-model="registered_email"
                  type="email"
                  class="form-control"
                  :placeholder="'Registered email'"
                  required
                />
              </div>

              <div class="form-group">
                <input
                  v-model="password"
                  :type="passwordOrText"
                  class="form-control"
                  :placeholder="'New password'"
                  required
                  :class="{ 'is-invalid': errors['password'].length > 0 }"
                />

                <div
                  v-for="(error, index) in errors['password']"
                  :key="index"
                  class="invalid-feedback text-left"
                >
                  {{ error }}
                </div>
              </div>

              <div class="form-group">
                <input
                  v-model="password_confirmation"
                  class="form-control"
                  placeholder="Confirm new password"
                  :type="passwordOrText"
                  required
                />
              </div>

              <button
                type="submit"
                class="btn btn-danger button-secondary float-right ml-3 btn-block"
              >
                <span v-if="loading">
                  <span class="spinner-border spinner-border-sm"></span>
                  {{ $t("loading") }}
                </span>

                <span v-if="!loading">{{ $t("changePass") }}</span>
              </button>
              <label
                v-if="passwordOrText === 'text'"
                for="password"
                @click="passwordOrText = 'password'"
              >
                <i class="fas fa-eye display-flex-right"></i>
                {{ $t("hidePass") }}
              </label>
              <label v-else for="password" @click="passwordOrText = 'text'">
                <i class="far fa-eye-slash"></i> {{ $t("showPass") }}
              </label>
            </form>
            <a
              :href="getCurrentPlatform()"
              class="btn btn-light button-danger float-right mt-3"
              >{{ $t("close") }}</a
            >
            <!-- <button @click="$router.push('/login')" class="button-danger float-right mt-3">Cancel</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PasswordService from "../services/PasswordService";
import TotalLogoStamp from "@/components/icons/TotalLogoStamp.vue";
import IconLogo from "@/components/icons/IconLogo.vue";
// import LoadingPageLogin from '@/components/loadings/LoadingPageLogin.vue';

const passwordService = new PasswordService();

export default {
  name: "PasswordNew",
  components: {
    IconLogo,
    TotalLogoStamp,
    // LoadingPageLogin,
  },
  data: function () {
    return {
      errors: {
        password: [],
      },
      registered_email: "",
      password: "",
      password_confirmation: "",
      loading: false,
      passwordOrText: "password",
      uglyPassword: false,
      pwErrorMsg: "",
    };
  },
  methods: {
    resetPassword() {
      this.loading = true;
      this.errors["password"] = [];
      if (this.password != this.password_confirmation) {
        this.errors["password"].push(
          "The password and password confirmation doesn't match",
        );
      }
      if (this.password.match(/\s/)) {
        this.errors["password"].push("should not contain white space");
      }
      if (this.password.length < 11) {
        this.errors["password"].push("at least 11 characters");
      }
      if (!this.password.match(/[a-z]/)) {
        this.errors["password"].push("at least 1 lowercase letter");
      }
      if (!this.password.match(/[A-Z]/)) {
        this.errors["password"].push("at least 1 uppercase letter");
      }
      if (!this.password.match(/\d/)) {
        this.errors["password"].push("at least 1 number");
      }
      if (!this.password.match(/\W/)) {
        this.errors["password"].push("at least 1 special character");
      }
      if (this.errors["password"].length > 0) {
        this.loading = false;
        return;
      }
      const platform = this.$helpers.getPlatform();
      const redirectToCarrier =
        this.$route.query?.redirect?.toLowerCase() === "carrier";
      let data = {
        email: this.registered_email,
        password: this.password,
        token: this.$route.query.token,
        platform: this.$route.query.platform,
      };
      passwordService
        .resetPassword(data)
        .then(() => {
          let prefix = "nb";
          if (platform && platform != "nobordist") {
            prefix = platform;
          }
          this.$bvToast.toast("Password changed.", {
            title: "Success",
            solid: true,
            variant: "success",
            autoHideDelay: 2000,
          });

          const pushRoute = redirectToCarrier
            ? `/${prefix}-carrier-login`
            : `/${prefix}-login`;

          this.$router.push(pushRoute);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          this.loading = false;

          errors.forEach((message) => {
            this.$bvToast.toast(message, {
              title: "Error",
              solid: true,
              variant: "danger",
              autoHideDelay: 2000,
            });
          });

          window.scrollTo(0, 0);
        });
    },
    getCurrentPlatform() {
      let prefix = "nb";
      const platform = this.$helpers.getPlatform();
      if (platform && platform != "nobordist") {
        prefix = platform;
      }
      return `/${prefix}-login`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.login-banner {
  height: 100vh;
  background-image: url(../assets/img/login-banner.jpg);
  background-size: cover;
}

.login-wrapper {
  animation: animate-login-screen 2.3s ease-in;
  animation-fill-mode: forwards;
  width: 345px;
}

.login-page {
  height: 100vh;
  overflow: hidden;
  background: var(--navBg);
  color: var(--white);
  fill: var(--white);
}

@keyframes animate-login-screen {
  0% {
    margin-top: 20%;
    height: 100vh;
    transform: scale(1);
  }

  50% {
    margin-top: 20%;
    height: 100vh;
    transform: scale(1);
  }

  100% {
    margin-top: -5px;
    height: 80px;

    transform: scale(0.5);
  }
}
</style>
