<template>
  <thead>
    <tr>
      <th v-for="col in columns" :key="col">
        <Skeleton w="64" />
      </th>
    </tr>
  </thead>
</template>

<script>
import Skeleton from "../Skeleton.vue";

export default {
  props: {
    columns: {
      type: Number,
      default: 5,
    },
  },
  components: {
    Skeleton,
  },
};
</script>

<style lang="scss" scoped>
thead {
  background-color: #f0f0fa; //gray-05
  font-size: 0.875rem;
  color: #000;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
  &::after {
    border: none;
  }
  th {
    padding: 0.75rem;
    div {
      display: flex;
      align-items: center;
    }
  }
}
</style>
