<template>
  <div>
    <div
      class="d-flex flex-column align-items-start flex-md-row justify-content-md-between"
    >
      <NbPageTitle
        :title="$t('ordersPage.title')"
        :subTitle="$t('ordersPage.subtitle')"
      />
      <div class="d-flex flex-column">
        <NbButton
          @click="showImportOrdersCostsModal = true"
          class="d-flex justify-content-start"
        >
          <NbIcon icon="upload" class="mr-2" size="20" />
          {{ $t("ordersPage.importOrderCosts") }}
        </NbButton>
        <NbButton
          @click="importOrdersMabang"
          :disabled="loadingOrdersMabang"
          class="mt-2 d-flex justify-content-start"
          variant="secondary"
          v-b-popover.hover
          title="This button trigger the all proccess of Mabang's orders importation. Use it with precaution. Some errors may occur."
        >
          <NbIcon icon="upload" class="mr-2" size="20" />
          {{ $t("ordersPage.importMabangOrders") }}
        </NbButton>
      </div>
    </div>

    <section class="page-container">
      <NbTabs class="mt-5 mb-4" :tabs="tabsOptions" />
      <NbTablev2
        tableOf="ordersTable"
        namespace="orders"
        ref="ordersTableRef"
        :clickable="true"
        :allFields="fields"
        :fields="allFields"
        :tabs="true"
        :optionsWidth="260"
        :filterOptions="filterOptions"
        :buttonOptions="tableOptions"
        selectable
        :selectedItems.sync="selected"
        @optionTook="optionTook($event)"
        @reloadFields="allFields = $event"
        @total="totalOrders = $event"
        @clickedRow="showOrder($event.id)"
      >
        <template #cell(seller_id)="data">
          <router-link
            class="link-1"
            :to="`/users_management/sellers?id=` + data.item.seller_id"
          >
            {{ data.item.seller_id }}
          </router-link>
        </template>
        <template #cell(contract_id)="data">
          <router-link
            class="link-1"
            :to="`/contracts/contracts?contractId=` + data.item.contract_id"
          >
            {{ data.item.contract_id }}
          </router-link>
        </template>
        <template #cell(overpack_id)="data">
          <router-link
            v-if="data.item.overpack_id"
            class="link-1"
            :to="`/shipping/overpacks?id=` + data.item.overpack_id"
          >
            {{ data.item.overpack_id }}
          </router-link>
          <span v-else>-</span>
        </template>
        <template #cell(prc)="data">
          <IsCheckedIcon :checked="data.item.prc" />
        </template>

        <template #cell(is_commercial_destination)="data">
          <IsCheckedIcon :checked="data.item.is_commercial_destination" />
        </template>

        <!-- orders values -->
        <template #cell(products_value)="data">
          {{ currency.format(data.item.products_value, data.item.currency) }}
        </template>
        <template #cell(freight_value)="data">
          {{ currency.format(data.item.freight_value, data.item.currency) }}
        </template>
        <template #cell(declared_value)="data">
          {{ currency.format(data.item.declared_value, data.item.currency) }}
        </template>
        <template #cell(duties_tax_value)="data">
          {{ currency.format(data.item.duties_tax_value, data.item.currency) }}
        </template>
        <template #cell(sales_tax_value)="data">
          {{ currency.format(data.item.sales_tax_value, data.item.currency) }}
        </template>
        <template #cell(tax_value)="data">
          {{ currency.format(data.item.tax_value, data.item.currency) }}
        </template>
        <template #cell(total_value)="data">
          {{ currency.format(data.item.total_value, data.item.currency) }}
        </template>
        <template #cell(estimated_duties_tax_value)="data">
          {{
            currency.format(
              data.item.estimated_duties_tax_value,
              data.item.currency,
            )
          }}
        </template>
        <template #cell(estimated_sales_tax_value)="data">
          {{
            currency.format(
              data.item.estimated_sales_tax_value,
              data.item.currency,
            )
          }}
        </template>
        <template #cell(estimated_tax_value)="data">
          {{
            currency.format(data.item.estimated_tax_value, data.item.currency)
          }}
        </template>
        <template #cell(freight_cost)="data">
          {{ currency.format(data.item.freight_cost, data.item.currency) }}
        </template>
        <!-- end orders values -->
        <template #cell(estimated_freight_cost)="data">
          {{
            currency.format(
              data.item.estimated_freight_cost,
              data.item.currency,
            )
          }}
        </template>
        <template #cell(tax_cost)="data">
          {{ currency.format(data.item.tax_cost, data.item.currency) }}
        </template>
        <template #cell(insurance_cost)="data">
          {{ currency.format(data.item.insurance_cost, data.item.currency) }}
        </template>
        <template #cell(insurance_coverage)="data">
          {{
            currency.format(data.item.insurance_coverage, data.item.currency)
          }}
        </template>
        <template #cell(ddp_cost)="data">
          {{ currency.format(data.item.ddp_cost, data.item.currency) }}
        </template>

        <template #cell(order_costs)="data">
          <a
            href="#"
            class="link-1"
            @click="setGeneralCosts(data.item.order_costs)"
          >
            {{ data.item.currency == "USD" ? "$" : "R$"
            }}{{ sumRealValues(data.item.order_costs) }}
          </a>
        </template>

        <template #cell(reference)="data">
          <NbButton
            variant="tertiary"
            v-b-tooltip.hover
            title="Open Tracking Page"
            @click="trackSearch(data.item.reference)"
          >
            {{ data.item.reference }}
          </NbButton>
        </template>

        <template #cell(first_mile_tracking_number)="data">
          {{ getFirstMileTrackingNumbers(data.item.volumes) }}
        </template>

        <template #cell(last_mile_carrier_number)="data">
          <div v-if="data.item.volumes.length < 3">
            <div
              v-if="
                checkLastMileCarrierNumber(data.item.volumes) ===
                'Last Mile Carrier Nº not found'
              "
              class="text-danger"
            >
              {{ $t("ordersPage.lastMileCarrierNotFound") }}
            </div>
            <div v-else-if="data.item.volumes.length === 1">
              {{ checkLastMileCarrierNumber(data.item.volumes) }}
            </div>
            <div v-else-if="data.item.volumes.length === 2">
              {{ checkLastMileCarrierNumber(data.item.volumes) }},
              {{ data.item.volumes[0].last_mile_carrier_number }}
            </div>
          </div>
          <div v-else-if="data.item.volumes.length > 2">
            {{ checkLastMileCarrierNumber(data.item.volumes) }}
            <a
              v-if="
                checkLastMileCarrierNumber(data.item.volumes) !==
                'Last Mile Carrier Nº not found'
              "
              href="#"
              data-toggle="modal"
              data-target="#showLastMileCarrierNumber"
              title="Show all Last Mile Carrier Number"
              @click="loadLastMileCarrierNumber(data.item)"
              class="link-1"
            >
              , and {{ data.item.volumes.length - 1 }} more
            </a>
          </div>
        </template>

        <template #cell(label)="data">
          <div class="d-flex justify-content-center mt-1">
            <a
              v-if="
                checkTrackingNumber(data.item.volumes) === 'Track Nº not found'
              "
              href="#"
              title="Track Nº not found!"
              @click="generateOrderLabel(data.item.id)"
            >
              <div>
                <i
                  class="fa fa-clock-o text-danger"
                  aria-hidden="true"
                  style="
                    font-size: 8px;
                    height: 25px;
                    position: relative;
                    left: 7px;
                  "
                ></i>
                <i
                  class="far fa-file fa-lg text-dark"
                  style="
                    font-size: 18px;
                    position: relative;
                    left: -4px;
                    bottom: 0px;
                  "
                ></i>
              </div>
            </a>
            <a
              v-else
              href="#"
              title="Order Label"
              @click="generateOrderLabel(data.item.id)"
            >
              <i class="far fa-file-alt fa-lg text-dark"></i>
            </a>
          </div>
        </template>
        <template #cell(first_mile_label)="data">
          <div class="d-flex justify-content-center mt-1">
            <a
              v-if="
                checkFirstMileNumber(data.item.volumes) ===
                'First Mile Nº not found'
              "
              href="#"
              title="First Mile Nº not found!"
              @click="generateFirstMile(data.item.id)"
            >
              <div>
                <i
                  class="fa fa-clock-o text-danger"
                  aria-hidden="true"
                  style="
                    font-size: 8px;
                    height: 25px;
                    position: relative;
                    left: 7px;
                  "
                ></i>
                <i
                  class="far fa-file fa-lg text-dark"
                  style="
                    font-size: 18px;
                    position: relative;
                    left: -4px;
                    bottom: 0px;
                  "
                ></i>
              </div>
            </a>
            <a
              v-else
              href="#"
              title="Order Label"
              @click="generateFirstMile(data.item.id)"
            >
              <i class="far fa-file-alt fa-lg text-dark"></i>
            </a>
          </div>
        </template>
        <template #cell(shipConfirmation)="data">
          <NbButton
            v-if="data.item.contain_battery"
            variant="tertiary"
            v-b-tooltip.hover
            title="This document is mandatory for this order"
            @click="generateShipperConfirmation(data.item.id)"
          >
            {{ $t("download") }}
          </NbButton>
          <span v-else>-</span>
        </template>
        <template #cell(is_mini)="data">
          <NbIcon
            :icon="data.item.is_mini ? 'check-circle' : 'x-circle'"
            :class="data.item.is_mini ? 'text-success' : 'text-danger'"
            size="16"
          />
        </template>
        <template #cell(msds)="data">
          <NbButton
            variant="quaternary"
            v-b-tooltip.hover
            title="Check if there are dangerous products"
            @click="downloadMsds(data.item.id)"
          >
            <div class="text-center" style="width: 35px; height: 25px">
              <i class="fas fa-fire-alt fa-lg"></i>
            </div>
          </NbButton>
        </template>
        <template #cell(financial_status)="data">
          <OrderFinancialStatusBadge :status="data.item.financial_status" />
        </template>
        <template #cell(is_landed_cost)="data">
          <NbBadge
            v-b-popover.hover.top="
              data.item.is_landed_cost ? 'HAS Landing Cost!' : 'NO Landing Cost'
            "
            :variant="data.item.is_landed_cost ? 'danger' : 'info'"
          >
            <i
              :class="
                data.value ? 'fas fa-dollar-sign' : 'fas fa-plane-arrival'
              "
            ></i>
          </NbBadge>
        </template>
      </NbTablev2>
    </section>

    <Modal v-model="showImportOrdersCostsModal">
      <ImportOrderCosts @success="showImportOrdersCostsModal = false" />
    </Modal>
    <Modal v-model="showGeneralCostsModal">
      <GeneralCostsModal :order-costs="currentOrderCosts" />
    </Modal>
    <Modal size="sm" v-model="showConfirmRemoveOrders">
      <RemoveOrders
        :order-ids="selected"
        :orders-with-masterboxes="selectedOrdersWithMasterboxes"
        @success="loadItems"
      />
    </Modal>
    <Modal size="sm" v-model="showConfirmDownloadOrders">
      <DownloadOrders :order-ids="selected" />
    </Modal>
    <Modal size="sm" v-model="showConfirmDownloadOrdersCosts">
      <DownloadOrders :order-ids="selected" type="order_costs" />
    </Modal>
    <Modal size="sm" v-model="showUpdateFinancialStatus">
      <UpdateFinancialStatus :order-ids="selected" @new-status="loadItems" />
    </Modal>

    <Modal
      v-model="showOrderDetails"
      size="lg"
      @close="setUrlSearchParam('id')"
    >
      <Order :order-id="selectedOrderId" @load="loadItems" />
    </Modal>

    <div id="showLastMileCarrierNumber" class="modal fade" role="dialog">
      <div class="modal-dialog" style="width: 320px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="settingsTitle" class="modal-title">
              {{ $t("ordersPage.orderNo") }} {{ lastMileCarrierNumbers.name }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="list-group list-group-flush">
              <li
                v-for="(cn23, index) in lastMileCarrierNumbers.numbers"
                :key="index"
                class="list-group-item"
              >
                {{ cn23.last_mile_carrier_number }}
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <a
              href="#"
              class="btn btn-secondary float-right ml-3"
              data-dismiss="modal"
              >{{ $t("close") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderService from "../../../services/OrderService";
import OrderLabelService from "../../../services/OrderLabelService";
import ProductDocumentService from "../../../services/ProductDocumentService";
import DownloadsService from "@/services/DownloadsService";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import OrderFinancialStatusBadge from "@/components/OrderFinancialStatusBadge.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import { currency } from "@/utils/filters/currency";
import ImportOrderCosts from "./components/ImportOrderCosts.vue";
import Modal from "../../../components/modal/Modal.vue";
import GeneralCostsModal from "./components/GeneralCostsModal.vue";
import RemoveOrders from "./components/RemoveOrders.vue";
import DownloadOrders from "./components/DownloadOrders.vue";
import Order from "./components/Order.vue";
import queryParamsMixin from "@/mixins/query-params-mixin.js";
import UpdateFinancialStatus from "./components/UpdateFinancialStatus.vue";
import IsCheckedIcon from "../../../components/IsCheckedIcon.vue";

const orderService = new OrderService();
const productDocumentService = new ProductDocumentService();
const orderLabelService = new OrderLabelService();
const downloadsService = new DownloadsService();

const tabs = {
  ALL: "all",
};

export default {
  name: "Orders",
  components: {
    NbPageTitle,
    NbTablev2,
    NbTabs,
    NbIcon,
    NbButton,
    OrderFinancialStatusBadge,
    NbBadge,
    ImportOrderCosts,
    Modal,
    GeneralCostsModal,
    RemoveOrders,
    DownloadOrders,
    Order,
    UpdateFinancialStatus,
    IsCheckedIcon,
  },
  mixins: [queryParamsMixin],
  data: () => {
    return {
      currentTab: tabs.ALL,
      totalOrders: 0,
      allFields: [],
      currency,
      showImportOrdersCostsModal: false,
      showGeneralCostsModal: false,
      selected: [],
      showConfirmDownloadOrders: false,
      showConfirmDownloadOrdersCosts: false,
      showConfirmRemoveOrders: false,
      showOrderDetails: false,
      selectedOrderId: null,
      selectedOrdersWithMasterboxes: [],
      loadingOrdersMabang: false,
      showUpdateFinancialStatus: false,

      financial_status_selected: null,
      lastMileCarrierNumbers: {},
      isAlertVisible: false,
      currentOrderCosts: [],
      trackingBaseUrl: process.env.VUE_APP_TRACKING_BASE_URL,
    };
  },
  computed: {
    tableOptions() {
      return [
        {
          text: this.$t("ordersPage.downloadOrders"),
          value: "confirmDownloadOrders",
        },
        {
          text: this.$t("ordersPage.downloadOrdersCosts"),
          value: "confirmDownloadOrdersCosts",
        },
        {
          text: this.$t("ordersPage.removeOrders"),
          value: "confirmRemoveOrders",
        },
        {
          text: this.$t("ordersPage.updateFinancialStatus"),
          value: "showUpdateMassiveFinancialStatus",
        },
      ];
    },
    tabsOptions() {
      return [
        {
          id: tabs.ALL,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.totalOrders,
        },
      ];
    },
    fields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "seller_id",
          label: this.$t("ordersPage.fields.sellerID"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "shipper_name",
          label: this.$t("ordersPage.fields.sellerName"),
          sortable: false,

          class: "whitespace-nowrap",
        },
        {
          key: "shipper_tax_number",
          label: this.$t("ordersPage.fields.sellerTaxNumber"),
          sortable: false,

          class: "whitespace-nowrap",
        },
        {
          key: "contract_id",
          label: this.$t("ordersPage.fields.contractID"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        { key: "prc", label: "PRC", sortable: true },
        {
          key: "overpack_id",
          label: this.$t("ordersPage.fields.overpackID"),
          sortable: false,

          class: "whitespace-nowrap",
        },
        {
          key: "customer_full_name",
          label: this.$t("ordersPage.fields.customerName"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "customer_postal_code",
          label: this.$t("ordersPage.fields.postalCode"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "customer_city",
          label: this.$t("ordersPage.fields.customerCity"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "customer_state",
          label: this.$t("ordersPage.fields.customerState"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "incoterm",
          label: this.$t("ordersPage.fields.incoterm"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "order_number",
          label: this.$t("ordersPage.fields.orderNumber"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "sales_channel_order_number",
          label: this.$t("ordersPage.fields.salesChOrderN"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "is_commercial_destination",
          label: this.$t("volumesPage.fields.isCommercialDestination"),
          sortable: true,
          class: "whitespace-nowrap",
        },
        {
          key: "seller_rfb_code",
          label: this.$t("volumesPage.fields.sellerRfbCode"),
          sortable: true,
          class: "whitespace-nowrap",
        },
        {
          key: "seller_legal_name",
          label: this.$t("volumesPage.fields.sellerLegalName"),
          sortable: true,
          class: "whitespace-nowrap",
        },
        //detail order vals
        {
          key: "products_value",
          label: this.$t("ordersPage.fields.productsValue"),
          sortable: true,
          class: "whitespace-nowrap",
        },
        {
          key: "freight_value",
          label: this.$t("ordersPage.fields.freightValue"),
          sortable: true,
          class: "whitespace-nowrap",
        },
        {
          key: "declared_value",
          label: this.$t("ordersPage.fields.declaredValue"),
          sortable: true,
          class: "whitespace-nowrap",
          isHiddenByDefault: true,
        },
        {
          key: "duties_tax_value",
          label: this.$t("ordersPage.fields.dutiesTaxValue"),
          sortable: true,

          class: "whitespace-nowrap",
          isHiddenByDefault: true,
        },
        {
          key: "sales_tax_value",
          label: this.$t("ordersPage.fields.salesTaxValue"),
          sortable: true,

          class: "whitespace-nowrap",
          isHiddenByDefault: true,
        },
        {
          key: "tax_value",
          label: this.$t("ordersPage.fields.taxValue"),
          sortable: true,
          class: "whitespace-nowrap",
        },
        {
          key: "total_value",
          label: this.$t("ordersPage.fields.totalValue"),
          sortable: true,
          class: "whitespace-nowrap",
        },
        {
          key: "estimated_duties_tax_value",
          label: this.$t("ordersPage.fields.estimatedDutiesTaxValue"),
          sortable: true,
          class: "whitespace-nowrap",
          isHiddenByDefault: true,
        },
        {
          key: "estimated_sales_tax_value",
          label: this.$t("ordersPage.fields.estimatedSalesTaxValue"),
          sortable: true,
          class: "whitespace-nowrap",
          isHiddenByDefault: true,
        },
        {
          key: "estimated_tax_value",
          label: this.$t("ordersPage.fields.estimatedTaxValue"),
          sortable: true,
          class: "whitespace-nowrap",
        },
        {
          key: "freight_cost",
          label: this.$t("ordersPage.fields.freightCost"),
          sortable: true,
          class: "whitespace-nowrap",
        },

        {
          key: "estimated_freight_cost",
          label: this.$t("ordersPage.fields.estimatedFreightCost"),
          sortable: true,
          class: "whitespace-nowrap",
        },
        {
          key: "tax_cost",
          label: this.$t("ordersPage.fields.taxCost"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "insurance_cost",
          label: this.$t("ordersPage.fields.insuranceCost"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "insurance_coverage",
          label: this.$t("ordersPage.fields.insuranceCoverage"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "ddp_cost",
          label: this.$t("ordersPage.fields.dDPCost"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "order_costs",
          label: this.$t("ordersPage.fields.totalGeneralCosts"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "reference",
          label: this.$t("ordersPage.fields.trackingNumber"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "first_mile_tracking_number",
          label: this.$t("volumesPage.fields.firstMileTrackingNumber"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "last_mile_carrier_number",
          label: this.$t("ordersPage.fields.lastMileCarrierNumber"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "is_mini",
          label: this.$t("ordersPage.fields.isMini"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "financial_status",
          label: this.$t("ordersPage.fields.financialStatus"),
          sortable: true,
          class: "whitespace-nowrap",
        },
        {
          key: "created_at",
          label: this.$t("ordersPage.fields.creationDate"),
          sortable: true,
          class: "whitespace-nowrap",
        },
        {
          key: "msds",
          label: this.$t("ordersPage.fields.mSDS"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "label",
          label: this.$t("ordersPage.fields.label"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "first_mile_label",
          label: this.$t("ordersPage.fields.firstMileLabel"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "shipConfirmation",
          label: this.$t("ordersPage.fields.shipConfirmation"),
          sortable: false,
          class: "whitespace-nowrap",
        },
        {
          key: "is_landed_cost",
          label: this.$t("ordersPage.fields.isLanded"),
          sortable: false,
          class: "whitespace-nowrap",
        },
      ];
    },
    filterOptions() {
      const filters = [
        {
          key: "seller_id",
          label: this.$t("ordersPage.fields.sellerID"),
          initialVal: this.$route.query.sellerId || "",
        },
        { key: "shipper_name", label: this.$t("ordersPage.fields.sellerName") },
        {
          key: "shipper_tax_number",
          label: this.$t("ordersPage.fields.sellerTaxNumber"),
        },
        { key: "contract_id", label: this.$t("ordersPage.fields.contractID") },
        { key: "overpack_id", label: this.$t("ordersPage.fields.overpackID") },
        {
          key: "prc",
          label: "PRC",
          type: "select",
          options: [
            { value: true, text: "True" },
            { value: false, text: "False" },
          ],
        },
        {
          key: "customer_full_name",
          label: this.$t("ordersPage.fields.customerName"),
        },
        {
          key: "customer_postal_code",
          label: this.$t("ordersPage.fields.postalCode"),
        },
        {
          key: "incoterm",
          label: this.$t("ordersPage.fields.incoterm"),
          type: "select",
          options: [
            { value: "DDU", text: "DDU" },
            { value: "DDP", text: "DDP" },
          ],
        },
        {
          key: "order_number",
          label: this.$t("ordersPage.fields.orderNumber"),
        },
        {
          key: "sales_channel_order_number",
          label: this.$t("ordersPage.fields.salesChOrderN"),
        },
        {
          key: "is_commercial_destination",
          label: this.$t("volumesPage.fields.isCommercialDestination"),
          type: "boolean",
        },
        {
          key: "seller_rfb_code",
          label: this.$t("volumesPage.fields.sellerRfbCode"),
        },
        {
          key: "seller_legal_name",
          label: this.$t("volumesPage.fields.sellerLegalName"),
        },
        {
          key: "reference",
          label: this.$t("ordersPage.fields.trackingNumber"),
        },
        {
          key: "last_mile_tracking_number",
          label: this.$t("volumesPage.filterOptions.lastMileTrackingNumber"),
          popoverText: this.$t("volumesPage.filterOptions.lmtnPopover"),
        },
        {
          key: "first_mile_tracking_number",
          label: this.$t("volumesPage.fields.firstMileTrackingNumber"),
        },
        {
          key: "financial_status",
          label: this.$t("ordersPage.fields.financialStatus"),
          type: "select",
          options: this.financial_statuses,
        },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
      ];
      return filters;
    },
    financial_statuses() {
      return [
        {
          value: "new_order",
          text: this.$t("ordersPage.financial_statuses.new"),
        },
        {
          value: "to_invoice",
          text: this.$t("ordersPage.financial_statuses.toInvoice"),
        },
        {
          value: "invoiced",
          text: this.$t("ordersPage.financial_statuses.invoiced"),
        },
        {
          value: "canceled",
          text: this.$t("ordersPage.financial_statuses.canceled"),
        },
        {
          value: "not_charged",
          text: this.$t("ordersPage.financial_statuses.notCharged"),
        },
      ];
    },
  },
  watch: {
    isAlertVisible: function () {
      if (this.isAlertVisible) {
        this.$toasted.show("Processing", {
          type: "warning",
          theme: "bubble",
          duration: 3000,
        });
      }
    },
  },
  created() {
    this.allFields = this.fields;
  },
  methods: {
    showOrder(orderId) {
      this.setUrlSearchParam("id", orderId);
      this.showOrderDetails = true;
      this.selectedOrderId = orderId;
    },
    confirmDownloadOrders() {
      this.showConfirmDownloadOrders = true;
    },
    confirmDownloadOrdersCosts() {
      this.showConfirmDownloadOrdersCosts = true;
    },
    setOrdersWithMasterboxes() {
      const ordersWithMasterbox = this.$refs?.ordersTableRef?.items?.filter(
        (item) =>
          this.selected.includes(item.id) && item?.volumes?.[0]?.masterbox_id,
      );

      this.selectedOrdersWithMasterboxes = ordersWithMasterbox || [];
    },
    confirmRemoveOrders() {
      this.setOrdersWithMasterboxes();
      this.showConfirmRemoveOrders = true;
    },
    showUpdateMassiveFinancialStatus() {
      this.showUpdateFinancialStatus = true;
    },
    optionTook(event) {
      this[event]();
    },
    setGeneralCosts(data) {
      this.currentOrderCosts = data || [];
      this.showGeneralCostsModal = true;
    },
    loadItems() {
      this.$refs.ordersTableRef?.getData();
    },
    selectedRows(items) {
      this.selected = items;
    },
    initiateFileCreation(dataQuery) {
      downloadsService.loadProcessing(dataQuery).then(() => {
        this.$router.push("/archives");
      });
    },
    checkLastMileCarrierNumber(volumes) {
      let lastMileCarrierIsNull = "";
      volumes?.forEach((volume) => {
        if (volume.last_mile_carrier_number != null) {
          lastMileCarrierIsNull = volume.last_mile_carrier_number;
          return lastMileCarrierIsNull;
        } else {
          lastMileCarrierIsNull = "Last Mile Carrier Nº not found";
        }
      });
      return lastMileCarrierIsNull;
    },
    loadLastMileCarrierNumber(order) {
      this.lastMileCarrierNumbers = {};
      this.lastMileCarrierNumbers.numbers = order.volumes;
      this.lastMileCarrierNumbers.name = order.order_number;
    },
    downloadOrders(data_type) {
      this.initiateFileCreation({
        data_type: data_type,
        reference_ids: this.selected,
      });
    },
    downloadMsds(orderId) {
      this.isAlertVisible = true;
      productDocumentService.downloadMsds(orderId).then((response) => {
        this.isAlertVisible = false;
        const file_path = response.data.data;
        if (file_path) window.open(response.data.data);
        else {
          this.$toasted.clear();
          this.$toasted.show("Nothing to download for this order.", {
            type: "success",
            theme: "bubble",
            duration: 3000,
          });
        }
      });
    },
    trackSearch(traking_number) {
      if (traking_number) {
        const trackingURL = this.$helpers.buildTrackingURL(traking_number);
        window.open(trackingURL, "_blank");
        return;
      }

      location.replace(this.trackingBaseUrl + "404");
    },
    checkTrackingNumber(volumes) {
      let trackIsNull = "";
      volumes?.forEach((volume) => {
        if (volume.last_mile_tracking_number != null) {
          trackIsNull = volume.last_mile_tracking_number;
          return trackIsNull;
        } else {
          trackIsNull = "Track Nº not found";
        }
      });
      return trackIsNull;
    },
    checkFirstMileNumber(volumes) {
      let trackIsNull = "";
      volumes?.forEach((volume) => {
        if (volume.first_mile_tracking_number != null) {
          trackIsNull = volume.first_mile_tracking_number;
          return trackIsNull;
        } else {
          trackIsNull = "First Mile Nº not found";
        }
      });
      return trackIsNull;
    },
    generateShipperConfirmation(orderId) {
      orderLabelService
        .generateShipperConfirmation(orderId)
        .then((response) => {
          window.open(response.data.data);
        });
    },
    generateOrderLabel(orderId) {
      orderLabelService.generateOrderLabel(orderId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    generateFirstMile(orderId) {
      orderLabelService.generateFirstMile(orderId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    importOrdersMabang() {
      this.loadingOrdersMabang = true;
      orderService
        .importOrdersMabang()
        .then((response) => {
          this.$toasted.show(response.data.messages[0], {
            type: "success",
            theme: "bubble",
            duration: 3000,
            singleton: "true",
          });
          response.data.data.messages?.forEach((message) => {
            this.$bvToast.toast(message, {
              title: "Importation information",
              toaster: "b-toaster-top-right",
              variant: "info",
              noAutoHide: true,
              appendToast: true,
              solid: true,
            });
          });
        })
        .finally(() => {
          this.loadingOrdersMabang = false;
        });
    },
    sumRealValues(arrayObjs) {
      let sumTotal = 0;
      arrayObjs?.forEach((obj) => {
        if (
          obj.external_id === 43 &&
          arrayObjs.find((item) => item.external_id === 8)
        )
          return;
        if (
          obj.external_id === 44 &&
          arrayObjs.find((item) => item.external_id === 9)
        )
          return;
        if (
          obj.external_id === 45 &&
          arrayObjs.find((item) => item.external_id === 7)
        )
          return;
        sumTotal += Number(obj.value);
      });
      return sumTotal.toFixed(2);
    },
    sumValuesIsCharged(arrayObjs) {
      let sumTotal = 0;
      arrayObjs?.forEach((arrayObj) => {
        if (arrayObj.is_charged == false) sumTotal += Number(arrayObj.value);
      });
      return sumTotal.toFixed(2);
    },
    getFirstMileTrackingNumbers(volumes) {
      const trackingNumbers = volumes
        .filter((volume) => volume.first_mile_tracking_number)
        .map((volume) => volume.first_mile_tracking_number);
      return trackingNumbers.join(", ") || "-";
    },
  },
  mounted() {
    const { id } = this.$route.query;
    if (id) {
      this.showOrder(id);
    }
  },
};
</script>
