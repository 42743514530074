<template>
  <div>
    <NbPageTitle
      :title="$t('volumeReversePage.title')"
      :subTitle="$t('volumeReversePage.subtitle')"
    />

    <DownloadVolumesReverseModal :show.sync="showVolumesReverseModal" />

    <VolumeReverseModal
      @close="setUrlSearchParam('id')"
      ref="volumeReverseModal"
    />

    <section class="page-container mt-5">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />
      <DatatableRoot
        namespace="volume_reverses"
        clickable
        selectable
        :columns="tableColumns"
        :filters="tableFilters"
        :actions="tableActions"
        :selected.sync="selected"
        @count="totalVolumesReverse = $event"
        @rowClick="showVolumeReverseDetails($event.id)"
        @showDownloadVolumesReverseModal="showDownloadVolumesReverseModal"
      >
        <template #volume.order_id="{ row }">
          <router-link
            class="link-1"
            :to="'/shipping/orders?id=' + row.volume.order_id"
          >
            {{ row.volume.order.order_number }}
          </router-link>
        </template>
        <template #order_items_name="{ row }">
          <span v-for="item in row.volume.order_items" :key="item.id">
            {{ item.name }}
          </span>
        </template>
        <template #order_items_description="{ row }">
          <span v-for="item in row.volume.order_items" :key="item.id">
            {{ item.description }}
          </span>
        </template>
        <template #order_items_hs_code="{ row }">
          <span v-for="item in row.volume.order_items" :key="item.id">
            {{ item.hs_code }}
          </span>
        </template>

        <template #volume_id="{ row }">
          <router-link
            class="link-1"
            :to="`/shipping/volumes?id=` + row.volume_id"
          >
            {{ row.volume_id }}
          </router-link>
        </template>
        <template #volume.order.seller_id="{ row }">
          <router-link
            class="link-1"
            :to="'/users_management/sellers?id=' + row.volume.order.seller_id"
          >
            {{ row.volume.order.seller.name }}
          </router-link>
        </template>

        <template #created_at="{ row }">
          {{ row.created_at | datetime }}
        </template>

        <template #volume.last_mile_tracking_number="{ row }">
          <router-link
            v-if="row.volume?.last_mile_tracking_number"
            :to="`/shipping/volumes?id=${row.volume_id}`"
          >
            {{ row.volume.last_mile_tracking_number }}
          </router-link>
          <span v-else>-</span>
        </template>
      </DatatableRoot>
    </section>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import DownloadVolumesReverseModal from "./components/DownloadVolumesReverseModal.vue";
import VolumeReverseModal from "./components/VolumeReverseModal.vue";
import queryParamsMixin from "@/mixins/query-params-mixin.js";
import SellerService from "@/services/SellerService";

const sellerService = new SellerService();

export default {
  name: "VolumeReverse",
  mixins: [queryParamsMixin],
  components: {
    NbPageTitle,
    DatatableRoot,
    NbTabs,
    DownloadVolumesReverseModal,
    VolumeReverseModal,
  },
  data: () => {
    return {
      showVolumesReverseModal: false,
      volumesReverseFields: [],
      namespace: "volume_reverses",
      selected: [],
      totalVolumesReverse: 0,
      currentTab: "all",
      sellersOption: [{ text: "Loading...", value: null, disabled: true }],
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: this.currentTab,
          label: this.$t("components.nbTable.all"),
          found: this.totalVolumesReverse || 0,
          current: this.currentTab,
        },
      ];
    },
    tableActions() {
      return [
        {
          label: this.$t("downloadReport"),
          action: "showDownloadVolumesReverseModal",
        },
      ];
    },
    tableColumns() {
      return [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "volume.last_mile_tracking_number",
          label: this.$t("volumeReversePage.fields.volume"),
        },
        {
          key: "volume.order_id",
          label: this.$t("volumeReversePage.fields.order"),
        },
        {
          key: "volume.order.seller_id",
          label: this.$t("volumeReversePage.fields.seller"),
        },
        {
          key: "created_at",
          label: this.$t("volumeReversePage.fields.creationdate"),
        },
        {
          key: "reverse_number",
          label: this.$t("volumeReversePage.fields.reverseNumber"),
        },
        {
          key: "name",
          label: this.$t("volumeReversePage.fields.customerName"),
        },
        {
          key: "address",
          label: this.$t("volumeReversePage.fields.address"),
        },
        {
          key: "address_number",
          label: this.$t("volumeReversePage.fields.addressNumber"),
        },
        {
          key: "address_complement",
          label: this.$t("volumeReversePage.fields.addressComplement"),
        },
        {
          key: "state",
          label: this.$t("volumeReversePage.fields.state"),
        },
        {
          key: "postal_code",
          label: this.$t("volumeReversePage.fields.postalCode"),
        },
        {
          key: "country",
          label: this.$t("volumeReversePage.fields.country"),
        },
        {
          key: "city",
          label: this.$t("volumeReversePage.fields.city"),
        },
        {
          key: "order_items_name",
          label: this.$t("volumeReversePage.fields.orderItemsName"),
        },
        {
          key: "order_items_description",
          label: this.$t("volumeReversePage.fields.orderItemsdescription"),
        },
        {
          key: "order_items_hs_code",
          label: this.$t("volumeReversePage.fields.orderItemsHSCode"),
        },
      ];
    },
    downloadFields() {
      return [
        { key: "id", label: "ID", show: true },
        {
          key: "volume.order.order_number",
          label: this.$t("volumeReversePage.fields.orderNumber"),
        },
        {
          key: "volume.order_id",
          label: this.$t("volumeReversePage.fields.orderID"),
          type: "number",
        },
        {
          key: "volume_id",
          label: this.$t("volumeReversePage.fields.volumeID"),
          type: "number",
        },
        {
          key: "volume.order.seller_id",
          label: this.$t("volumeReversePage.fields.sellerID"),
          type: "number",
        },
        {
          key: "name",
          label: this.$t("volumeReversePage.fields.name"),
        },
        {
          key: "created_at",
          label: this.$t("volumeReversePage.fields.creationdate"),
          type: "date",
        },
        {
          key: "reverse_number",
          label: this.$t("volumeReversePage.fields.reverseNumber"),
        },
        {
          key: "address",
          label: this.$t("volumeReversePage.fields.address"),
        },
        {
          key: "address_number",
          label: this.$t("volumeReversePage.fields.addressNumber"),
        },
        {
          key: "address_complement",
          label: this.$t("volumeReversePage.fields.addressComplement"),
        },
        {
          key: "state",
          label: this.$t("volumeReversePage.fields.state"),
        },
        {
          key: "postal_code",
          label: this.$t("volumeReversePage.fields.postalCode"),
        },
        {
          key: "country",
          label: this.$t("volumeReversePage.fields.country"),
        },
        {
          key: "city",
          label: this.$t("volumeReversePage.fields.city"),
        },
        {
          key: "volume.order.customer_full_name",
          label: this.$t("volumeReversePage.fields.customerName"),
        },
        {
          key: "order_items_name",
          label: this.$t("volumeReversePage.fields.orderItemsName"),
        },
        {
          key: "order_items_description",
          label: this.$t("volumeReversePage.fields.orderItemsdescription"),
        },
        {
          key: "order_items_hs_code",
          label: this.$t("volumeReversePage.fields.orderItemsHSCode"),
        },
      ];
    },
    tableFilters() {
      return [
        {
          label: this.$t("volumeReversePage.fields.volumeID"),
          inputs: [
            {
              key: "volume_id",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("volumeReversePage.fields.seller"),
          inputs: [
            {
              key: "seller_id",
              type: "select",
              options: this.sellersOption,
            },
          ],
        },
        {
          label: this.$t("volumeReversePage.fields.customerName"),
          inputs: [
            {
              key: "name",
            },
          ],
        },
        {
          label: this.$t("volumeReversePage.fields.reverseNumber"),
          inputs: [{ key: "reverse_number" }],
        },
        {
          label: this.$t("volumeReversePage.fields.address"),
          inputs: [{ key: "address" }],
        },
        {
          label: this.$t("volumeReversePage.fields.addressNumber"),
          inputs: [
            {
              key: "address_number",
            },
          ],
        },
        {
          label: this.$t("volumeReversePage.fields.addressComplement"),
          inputs: [
            {
              key: "address_complement",
            },
          ],
        },
        {
          label: this.$t("volumeReversePage.fields.state"),
          inputs: [
            {
              key: "state",
            },
          ],
        },
        {
          label: this.$t("volumeReversePage.fields.postalCode"),
          inputs: [
            {
              key: "postal_code",
            },
          ],
        },
        {
          label: this.$t("volumeReversePage.fields.country"),
          inputs: [{ key: "country" }],
        },
        {
          label: this.$t("volumeReversePage.fields.city"),
          inputs: [{ key: "city" }],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
      ];
    },
  },
  methods: {
    async getSellers() {
      const { data } = await sellerService.getSellers();

      this.sellersOption = data?.data?.elements?.map((item) => ({
        text: `(${item.id}) ${item.name}`,
        value: item.id,
      }));
    },
    showVolumeReverseDetails(id) {
      this.setUrlSearchParam("id", id);
      this.$refs?.volumeReverseModal?.showModal();
    },
    showDownloadVolumesReverseModal() {
      this.showVolumesReverseModal = true;
    },
  },
  mounted() {
    this.getSellers();
    const queryId = this.$route.query?.id;
    if (queryId) {
      this.showVolumeReverseDetails(queryId);
    }
  },
};
</script>
