<template>
  <div>
    <ModalHeader>
      <ModalTitle>Remove order</ModalTitle>
    </ModalHeader>

    <p>
      {{ $t("ordersViewPage.wantToRemore") }}
    </p>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
      <NbButton class="ml-2" :disabled="loading" @click="removeOrder">
        {{ $t("delete") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import OrderLabelService from "@/services/OrderLabelService";

const orderLabelService = new OrderLabelService();

export default {
  components: {
    ModalFooter,
    ModalHeader,
    ModalTitle,
    NbButton,
    ModalClose,
  },
  inject: ["context"],
  props: {
    orderId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async removeOrder() {
      try {
        this.loading = true;
        orderLabelService.generateArchivingConfirmation(this.orderId);
        this.$emit("deleted");
        this.context.close();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
