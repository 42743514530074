export default class UserService {
  /**
   * Set logged in user
   * @param userData
   */
  setLoggedInUser(userData) {
    return localStorage.setItem("user", userData);
  }

  /**
   * Get logged in user
   * @returns {any}
   */
  getLoggedInUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  /**
   * Delete the logged in user data
   */
  deleteLoggedInUser() {
    return localStorage.removeItem("user");
  }
}
