var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between flex-wrap pt-4 dashed-middle border-bottom border-gray-20 pb-4 mb-4"},_vm._l((_vm.deliveryMethods),function(deliveryMethod){return _c('div',{key:deliveryMethod.icon,staticClass:"w-24"},[_c('div',{staticClass:"body-2 mb-2 pb-1"},[_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(deliveryMethod.type)+" ")]),_c('div',[_vm._v("("+_vm._s(_vm.$t("contractsViewPage.deliveryMethod"))+")")])]),_c('div',{staticClass:"text-gray-60 icon-stamp mb-3"},[(deliveryMethod.icon !== 'plane')?_c('NbIcon',{attrs:{"icon":deliveryMethod.icon,"attributes":{
            'stroke-width': 3,
            width: '1.2rem',
            height: '1.2rem',
          }}}):_c('div',{staticClass:"icon"},[_c('Plane')],1)],1),_c('div',{class:deliveryMethod.name ? 'heading-2 mb-2 link-1' : 'heading-2 mb-2',on:{"click":function($event){return _vm.pushToNewTab(
            `/contracts/delivery_methods?deliveryMethodId=${deliveryMethod.id}`,
          )}}},[_vm._v(" "+_vm._s(deliveryMethod.name)+" ")]),_c('div',{staticClass:"body-2"})])}),0),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-gray-60 icon-stamp mr-4"},[_c('NbIcon',{attrs:{"icon":"repeat","attributes":{
          'stroke-width': 3,
          width: '1.2rem',
          height: '1.2rem',
        }}})],1),_c('div',[_c('div',{staticClass:"body-2 mb-2"},[_vm._v(" "+_vm._s(_vm.$t("contractsViewPage.reverseDeliveryMethod"))+" ")]),_c('div',{class:_vm.deliveryMethodNamesIds?.reverse_delivery_method_name
            ? 'heading-2 mb-2 link-1'
            : 'heading-2 mb-2',on:{"click":function($event){return _vm.pushToNewTab(
            `/contracts/delivery_methods?deliveryMethodId=${_vm.deliveryMethodNamesIds?.reverse_delivery_method_id}`,
          )}}},[_vm._v(" "+_vm._s(_vm.deliveryMethodNamesIds?.reverse_delivery_method_name ? _vm.deliveryMethodNamesIds?.reverse_delivery_method_name : "N/A")+" ")]),_c('div',{staticClass:"body-2"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }