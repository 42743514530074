<template>
  <div v-if="!order?.observations && !orderHasIntegrationData">
    {{ $t("none") }}
  </div>
  <div v-else class="grid gap-4">
    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('ordersViewPage.observations')"
      v-model="order.observations"
      placeholder="-"
      id="order.observations"
      :helpText="$t('ordersViewPage.freeFieldCreatedBySeller')"
    />

    <section>
      <h3 class="heading-3 m-0">
        {{ $t("ordersViewPage.integrationData") }}
      </h3>

      <div v-if="orderHasIntegrationData" class="grid grid-cols-6 gap-4">
        <div
          v-for="(value, key, index) in order.integration_data"
          class="info"
          :key="index"
        >
          <small>{{ key }}</small>
          <span>{{ value }}</span>
        </div>
      </div>
      <span v-else>{{ $t("none") }}</span>
    </section>
  </div>
</template>

<script>
import NbTextInput from "@/components/input/text/NbTextInput.vue";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    NbTextInput,
  },
  computed: {
    orderHasIntegrationData() {
      return (
        this.order.integration_data &&
        !!Object.keys(this.order.integration_data).length
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
