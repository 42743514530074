<template>
  <div class="card-form">
    <h4 class="heading-3">{{ title }}</h4>
    <NbTextInput
      v-if="!currentInput"
      :id="`name-${id}`"
      v-model="overpack.name"
      name="Nome do overpack"
      placeholder="digite o nome do overpack"
      :error="errors.name"
      required
    />
    <NbSelectInput
      v-else
      :id="`name-${id}`"
      v-model="overpack.name"
      required
      name="Nome do overpack"
      :error="errors.name"
      :options="options"
    />
    <div class="link-2 pointer mb-3" @click="switchInput">{{ linkText }}</div>
    <div class="d-flex justify-content-between mb-3">
      <NbTextInput
        :id="`profundidade-${id}`"
        v-model="overpack.length"
        class="mr-2"
        name="Profundidade (cm)"
        placeholder="0"
        :error="errors.length"
        required
      />
      <NbTextInput
        :id="`altura-${id}`"
        v-model="overpack.width"
        class="mr-2"
        name="Altura (cm)"
        placeholder="0"
        :error="errors.width"
        required
      />
      <NbTextInput
        :id="`largura-${id}`"
        v-model="overpack.height"
        name="Largura (cm)"
        placeholder="0"
        :error="errors.height"
        required
      />
    </div>
    <div class="text-right">
      <NbButton variant="secondary" size="sm" icon="fas fa-plus">{{
        btnLabel
      }}</NbButton>
    </div>
  </div>
</template>

<script>
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  components: {
    NbTextInput,
    NbSelectInput,
    NbButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    linkText: {
      type: String,
      default: "",
    },
    btnLabel: {
      type: String,
      default: "",
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      textForInput: "",
      overpack: {
        name: "",
        height: "",
        width: "",
      },
      currentInput: true,
      options: [],
    };
  },
  methods: {
    switchInput() {
      this.currentInput = !this.currentInput;
      if (this.options.length <= 0) {
        const exp1 = { text: "pipi1", value: "pe" };
        const exp2 = { text: "pipi2", value: "pe2" };
        this.options.push(exp1, exp2);
      }
    },
  },
};
</script>

<style scoped>
.card-form {
  width: 25rem;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 8px var(--gray-05);
  border: 1px solid var(--gray-05);
  border-radius: 4px;
  padding: 1.5rem;
}
.heading-3 {
  color: var(--black);
  font: normal normal bold 16px/20px Nunito Sans;
  letter-spacing: 0px;
}
.link-2 {
  color: var(--gray-60) !important;
  text-decoration: underline;
  font: normal normal 600 12px/18px Nunito Sans;
  letter-spacing: 0px;
  cursor: pointer;
}
</style>
