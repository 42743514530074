<template>
  <div>
    <DatatableRoot
      namespace="checkpoints"
      clickable
      :columns="tableColumns"
      :filters="tableFilters"
      @count="$emit('total', $event)"
      @rowClick="loadModalData($event.id)"
      :url-state="false"
      @ids="elementIds = $event"
    >
      <template #actions>
        <DatatableActions>
          <DatatableAction @click="downloadCheckpointCsv">
            {{ $t("nobordistCheckpointsPage.downloadCheckpointsCSV") }}
          </DatatableAction>
          <DatatableAction @click="downloadCheckpointXls">
            {{ $t("nobordistCheckpointsPage.downloadCheckpointsExcel") }}
          </DatatableAction>
        </DatatableActions>
      </template>

      <template #final_status="{ row }">
        <IsCheckedIcon :checked="row.final_status" />
      </template>

      <template #zone_code="{ row }">
        {{ getZoneCodeName(row.zone_code) }}
      </template>
    </DatatableRoot>

    <ModalNobordistCheckpointsView :itemId="currentItemId" />
  </div>
</template>
<script>
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import DatatableActions from "@/components/datatable/DatatableActions.vue";
import DatatableAction from "@/components/datatable/DatatableAction.vue";
import DownloadsService from "@/services/DownloadsService";
import ModalNobordistCheckpointsView from "@/views/checkpoints/nobordist_checkpoints/components/ModalNobordistCheckpointsView.vue";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";
import { CHECKPOINT_GROUP } from "../../../constants";

const downloadsService = new DownloadsService();

export default {
  name: "NobordistCheckpoints",
  components: {
    DatatableRoot,
    DatatableActions,
    DatatableAction,
    ModalNobordistCheckpointsView,
    IsCheckedIcon,
  },
  props: {},
  data: () => {
    return {
      currentItemId: "",
      elementIds: [],
    };
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    getZoneCodeName(zoneCode) {
      const subject = Object.values(CHECKPOINT_GROUP).find(
        (item) => item.zoneCode === zoneCode,
      );

      return subject.name || "";
    },
    checkQuery() {
      if (this.$route.query.nobordistCheckpointId) {
        this.$emit("switchTab", "nobordistCheckpoints");
        this.loadModalData(this.$route.query.nobordistCheckpointId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.nobordistCheckpointId) {
          this.$router.push({ query: { nobordistCheckpointId: id } });
        }
        /* this.$router.push({ query: {} }); */

        this.$helpers.openModal("ModalNobordistCheckpointsView");
        return;
      }
    },
    initiateFileCreation(dataQuery) {
      downloadsService.loadProcessing(dataQuery).then(() => {
        this.$router.push("/archives");
      });
    },
    downloadCheckpointCsv() {
      this.initiateFileCreation({
        data_type: "checkpoints",
        reference_ids: this.elementIds,
        doc_type: "csv",
      });
    },
    downloadCheckpointXls() {
      this.initiateFileCreation({
        data_type: "checkpoints",
        reference_ids: this.elementIds,
        doc_type: "xls",
      });
    },
    optionTook(event) {
      this[event]();
    },
  },
  computed: {
    tableColumns() {
      return [
        {
          label: this.$t("nobordistCheckpointsPage.fields.trackingCode"),
          key: "tracking_code",
        },
        {
          label: this.$t("nobordistCheckpointsPage.fields.slug"),
          key: "slug",
        },
        {
          label: this.$t("nobordistCheckpointsPage.fields.name"),
          key: "name",
        },
        {
          label: this.$t("nobordistCheckpointsPage.fields.title"),
          key: "title",
        },
        {
          label: this.$t("nobordistCheckpointsPage.fields.namePT"),
          key: "name_pt",
        },
        {
          label: this.$t("nobordistCheckpointsPage.fields.titlePT"),
          key: "title_pt",
        },
        {
          label: this.$t("nobordistCheckpointsPage.fields.finalStatus"),
          key: "final_status",
        },
        {
          label: this.$t("nobordistCheckpointsPage.fields.category"),
          key: "zone_code",
        },
      ];
    },
    tableFilters() {
      return [
        {
          label: this.$t("nobordistCheckpointsPage.fields.slug"),
          inputs: [{ key: "slug" }],
        },
        {
          label: this.$t("nobordistCheckpointsPage.fields.name"),
          inputs: [{ key: "name" }],
        },
        {
          label: this.$t("nobordistCheckpointsPage.fields.title"),
          inputs: [{ key: "title" }],
        },
        {
          label: this.$t("nobordistCheckpointsPage.fields.trackingCode"),
          inputs: [{ key: "tracking_code" }],
        },
      ];
    },
    buttonOptions() {
      return [
        {
          text: this.$t("nobordistCheckpointsPage.downloadCheckpointsCSV"),
          value: "downloadCheckpointCsv",
        },
        {
          text: this.$t("nobordistCheckpointsPage.downloadCheckpointsExcel"),
          value: "downloadCheckpointXls",
        },
      ];
    },
  },
  watch: {},
};
</script>
