import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";
import NProgress from "nprogress";
import store from "@/store/index";
import PasswordNew from "../views/PasswordNew";
import Login from "../views/logins/Login";
import UserProfile from "../views/account/UserProfile.vue";
import HsCodeQuery from "../views/HsCodeQuery.vue";
import Overview from "../views/Overview";
import shipping from "./shipping";
import platforms from "./platforms";
import users_management from "./users_management";
import finances from "./finances";
import contracts from "./contracts";
import logs from "./logs";
import checkpoints from "./checkpoints";
import settings from "./settings";
import Downloads from "../views/Downloads";
import Products from "../views/products/Products";
import AsyncRequest from "../views/AsyncRequest";
import Components from "../components/Components";

import Helpers from "../helpers";
import UsersService from "@/services/UsersService";
import GroupService from "@/services/GroupService";
import SendNotifications from "@/views/notifications/send_notifications/SendNotifications.vue";
import UserNotifications from "@/views/notifications/user_notifications/UserNotifications.vue";
import PassThrough from "@/components/PassThrough";
import NotFound from "../views/not_found/NotFound.vue";

const helpers = Helpers;
const usersService = new UsersService();
const groupService = new GroupService();

Vue.use(VueRouter);

const isSuper = store.state.user.is_super;

const home = {
  path: "/",
  name: "home",
  component: Home,
  meta: {
    visible: true,
    label: "Overview",
    icon: "grid",
  },
};

const login = {
  path: "/nb-login",
  alias: [
    "/nb-login",
    "/total-login",
    "/asia-login",
    "/e-cross-login",
    "/anjun-login",
    "/tiendamia-login",
    "/mirakl-login",
    "/skypostal-login",
    "/jt-login",
    "/correios-login",
    "/shopee-login",
    "/loggi-login",
    "/shipsmart-login",
    //carrier
    "/nb-carrier-login",
    "/total-carrier-login",
    "/asia-carrier-login",
    "/e-cross-carrier-login",
    "/anjun-carrier-login",
    "/tiendamia-carrier-login",
    "/mirakl-carrier-login",
    "/skypostal-carrier-login",
    "/jt-carrier-login",
    "/correios-carrier-login",
    "/shopee-carrier-login",
    "/loggi-carrier-login",
    "/shipsmart-carrier-login",
  ],
  name: "login",
  component: Login,
  meta: {
    visible: false,
    label: "Login",
  },
};

const reset_password = {
  path: "/reset_password",
  name: "reset_password",
  component: PasswordNew,
  meta: {
    visible: false,
    label: "Reset Password",
  },
};

const user_profile = {
  path: "/user_profile",
  name: "user_profile",
  component: UserProfile,
  meta: {
    visible: true,
    label: "Account",
    icon: "user",
    head_and_link: true,
    searchData: "userProfile",
  },
};

const products = {
  path: "/products",
  component: Products,
  meta: {
    visible: false,
    label: "Products",
    icon: "fas fa-shopping-basket",
    head_and_link: true,
  },
  children: [
    {
      path: "/",
      name: "products",
      component: Products,
      meta: {
        visible: true,
        label: "Overview",
      },
    },
  ],
};

const hscode_query = {
  path: "/hscodequery",
  name: "hscodequery",
  component: HsCodeQuery,
  meta: {
    label: "SearchHsCode",
    head_and_link: true,
  },
  children: [
    {
      path: "/hscodequery",
      component: HsCodeQuery,
      meta: {
        searchData: "searchHsCode",
      },
    },
  ],
};

const overview = {
  path: "/overview",
  name: "overview",
  component: Overview,
  meta: {
    visible: isSuper,
    label: "Overview",
    icon: "pie-chart",
    head_and_link: true,
  },
  children: [
    {
      path: "/overview",
      component: Overview,
      meta: {
        visible: isSuper,
        label: "Overview",
      },
    },
  ],
};

const downloads = {
  path: "/archives",
  name: "downloads",
  component: Downloads,
  meta: {
    visible: false,
    label: "Archives",
    icon: "download",
    searchData: "downloads",
  },
};

const asyncRequest = {
  path: "/requests",
  name: "asyncrequest",
  component: AsyncRequest,
  meta: {
    visible: true,
    label: "Requests",
    icon: "refresh-cw",
  },
};
const components = {
  path: "/components",
  name: "components",
  component: Components,
  meta: {
    visible: false,
    label: "components",
    icon: "",
  },
};

const sendNotifications = {
  path: "/notifications/send-notifications",
  name: "SendNotifications",
  component: SendNotifications,
  meta: {
    visible: true,
    label: "SendNotifications",
    searchData: "sendNotifications",
    icon: "send",
  },
};
const logs_user_notifications = {
  path: "/notifications/user_notifications",
  name: "user_notifications",
  component: UserNotifications,
  meta: {
    visible: false,
    label: "UserNotifications",
    searchData: "userNotifications",
  },
};
const notifications_children = [sendNotifications, logs_user_notifications];

const notifications_pages = {
  path: "/",
  name: "notifications",
  component: PassThrough,
  meta: {
    visible: true,
    label: "Notifications",
    icon: "send",
  },
  children: notifications_children,
};

const routes = [
  home,
  login,
  reset_password,
  shipping,
  users_management,
  finances,
  contracts,
  logs,
  checkpoints,
  platforms,
  products,
  notifications_pages,
  hscode_query,
  overview,
  downloads,
  asyncRequest,
  components,
  settings,
  user_profile,
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: {
      visible: false,
      head_and_link: false,
    },
  },
];

function removeRouteGroup(routeGroupName) {
  const indexToRemove = routes.findIndex(
    (route) => route.name === routeGroupName,
  );
  if (indexToRemove > -1) {
    routes.splice(indexToRemove, 1);
  }
}

function removeRouteByName(routeGroupName, routeName) {
  const routeGroup = routes.find((route) => route.name === routeGroupName);
  if (routeGroup) {
    const indexToRemove = routeGroup.children.findIndex(
      (route) => route.name === routeName,
    );
    if (indexToRemove > -1) {
      routeGroup.children.splice(indexToRemove, 1);
    }
  }
}

//remove routes
(async function () {
  const platform = helpers.getPlatform();
  const pathname = window.location.pathname;

  //skypostal
  if (platform === "skypostal" && pathname != "/reset_password") {
    usersService.getCurrentUser().then((response) => {
      groupService.getGroup(response.data.data.group_id).then((response) => {
        const user_group = response.data.data.name.toLowerCase();
        if (
          user_group === "platform-viewer" ||
          user_group === "platform-operation"
        ) {
          removeRouteGroup("contracts");
          removeRouteGroup("logs");
          removeRouteGroup("checkpoints");
          removeRouteGroup("hscodequery");
          removeRouteGroup("asyncrequest");
        }
      });
    });
  }
  //site ADM or CARRIER
  const pathName = window.location.pathname;
  let siteUrl = "admin";
  if (pathName.includes("-carrier")) {
    siteUrl = "carrier";
  }

  //setTimeOut to run this function after page mount
  // setTimeout(() => {
  if (store.state.site == "carrier" || siteUrl == "carrier") {
    //shipping:
    removeRouteByName("shipping", "shipping_volume_reverse");
    removeRouteByName("shipping", "shipping_orders");
    removeRouteByName("shipping", "shipping_overpacks");
    removeRouteByName("shipping", "ShippingRules");
    removeRouteByName("shipping", "DutiesAndTaxesStatus");
    //users_management;
    removeRouteByName("users_management", "users_management_carriers");
    removeRouteByName("users_management", "users_management_sellers");
    //finances: all
    removeRouteGroup("finances");
    //contracts:
    removeRouteByName("contracts", "contracts_contracts");
    removeRouteByName("contracts", "contracts_prices_table");
    removeRouteByName("contracts", "contracts_shipstation_contracts");
    removeRouteByName("contracts", "contracts_carriers");
    removeRouteByName("contracts", "contracts_carrier_view");
    //logs: all
    removeRouteGroup("logs");
    //checkpoints:
    //N/A
    //sendNotifications: all
    removeRouteGroup("SendNotifications");
    //settings:
    //N/A
    //user_profile (account): all
    removeRouteGroup("user_profile");
    removeRouteGroup("notifications");
    removeRouteByName("settings", "printing_setting");
  }
  // }, 200);
})();

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  },
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.beforeEach((to, from, next) => {
  if (
    window.location.host.endsWith(".adm.nobordist.com") ||
    window.location.host.endsWith(".carrier.nobordist.com") ||
    window.location.host.includes("localhost")
  ) {
    /* localStorage.setItem(`platform`, 'nb');
    localStorage.getItem(`columns${this.modalKey}`) */
    let prefix = localStorage.getItem("platform");

    if (!prefix) {
      prefix = helpers.getPlatform("prefix");
    }

    if (
      !store.state.user &&
      to.name !== "login" &&
      to.name !== "passwordNew" &&
      to.name !== "reset_password"
    ) {
      next(
        `/${prefix}-${
          window.location.host.endsWith(".carrier.nobordist.com")
            ? "carrier-"
            : ""
        }login`,
      );
    } else {
      next();
    }
    if (store.state.user && to.name === "login") {
      next("/");
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
