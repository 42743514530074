<template>
  <NbCard id="actions" :title="$t('actions')" class="col-span-2">
    <template #body>
      <div class="grid grid-cols-2 gap-4">
        <NbButton
          v-if="order?.seller_id"
          variant="primary"
          class="align-items-center d-flex"
          @click="navigateTo(`/users_management/sellers?id=${order.seller_id}`)"
        >
          <NbIcon :size="18" class="mr-2" icon="user" />
          {{ $t("ordersViewPage.goToSeller") }}
        </NbButton>
        <NbButton
          v-if="order?.contract_id"
          variant="primary"
          class="align-items-center d-flex"
          @click="
            navigateTo(`/contracts/contracts?contractId=${order.contract_id}`)
          "
        >
          <NbIcon :size="18" class="mr-2" icon="shield" />
          {{ $t("ordersViewPage.goToContract") }}
        </NbButton>

        <NbButton
          v-if="order?.reference"
          variant="primary"
          class="align-items-center d-flex"
          @click="goToTrackingPage"
        >
          <NbIcon :size="18" class="mr-2" icon="truck" />
          {{ $t("ordersViewPage.goToTrackingPage") }}
        </NbButton>

        <NbButton
          v-if="$store.state.platform === 'total'"
          variant="secondary"
          class="align-items-center d-flex"
          @click="$emit('edit')"
        >
          <NbIcon :size="18" class="mr-2" icon="edit" />
          {{ $t("edit") }}
        </NbButton>

        <NbButton
          variant="secondary"
          @click="$emit('add-order-cost')"
          class="align-items-center d-flex"
        >
          <NbIcon :size="18" class="mr-2" icon="plus" />
          {{ $t("ordersViewPage.addCost") }}
        </NbButton>
        <NbButton
          variant="secondary"
          @click="$emit('update-financial-status')"
          class="align-items-center d-flex"
        >
          <NbIcon :size="18" class="mr-2" icon="dollar-sign" />

          {{ $t("ordersPage.updateFinancialStatus") }}
        </NbButton>

        <NbButton
          variant="secondary"
          @click="$emit('remove')"
          class="align-items-center d-flex"
        >
          <NbIcon :size="18" class="mr-2" icon="trash-2" />
          {{ $t("remove") }}
        </NbButton>
      </div>
    </template>
  </NbCard>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    NbCard,
    NbButton,
    NbIcon,
  },
  methods: {
    goToTrackingPage() {
      const trackingURL = this.$helpers.buildTrackingURL(this.order.reference);
      window.open(trackingURL, "_blank");
    },
    navigateTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped></style>
