import PassThrough from "../../components/PassThrough";
import Volumes from "../../views/shipping/volumes/Volumes";
import VolumesView from "../../views/shipping/volumes/View";
import Orders from "../../views/shipping/orders/Orders";
import Overpacks from "../../views/shipping/overpacks/Overpacks";
import Masterboxes from "../../views/shipping/masterboxes/Masterboxes";
import MasterboxesView from "../../views/shipping/masterboxes/View";
import Flights from "../../views/shipping/flights/Flights";
import FlightsView from "../../views/shipping/flights/View";
import VolumesReverse from "../../views/shipping/volume_reverse/VolumeReverse";
import ShippingRules from "../../views/shipping/shippingrules/ShippingRules";
import DutiesAndTaxesStatus from "../../views/shipping/duties_and_taxes_status/DutiesAndTaxesStatus";
import DutiesAndTaxesStatusView from "../../views/shipping/duties_and_taxes_status/View";

const shipping_volumes_reverse = {
  path: "/shipping/volumes_reverse",
  name: "shipping_volume_reverse",
  component: VolumesReverse,
  meta: {
    visible: true,
    label: "VolumesReverse",
    searchData: "volumesReverse",
  },
};

const shipping_volumes = {
  path: "/shipping/volumes",
  name: "shipping_volumes",
  component: Volumes,
  meta: {
    visible: true,
    label: "Volumes",
    searchData: "volumes",
  },
};

const shipping_volumes_view = {
  path: "/shipping/volumes/:id",
  name: "shipping_volumes_view",
  component: VolumesView,
  meta: {
    visible: false,
    label: "Volumes view",
  },
};

const shipping_orders = {
  path: "/shipping/orders",
  name: "shipping_orders",
  component: Orders,
  meta: {
    visible: true,
    label: "Orders",
    searchData: "orders",
  },
};

const shipping_overpacks = {
  path: "/shipping/overpacks",
  name: "shipping_overpacks",
  component: Overpacks,
  meta: {
    visible: true,
    label: "Overpacks",
    searchData: "overpacks",
  },
};

const shipping_masterboxes = {
  path: "/shipping/masterboxes",
  name: "shipping_masterboxes",
  component: Masterboxes,
  meta: {
    visible: true,
    label: "Masterboxes",
    searchData: "masterboxes",
  },
};

const shipping_masterboxes_view = {
  path: "/shipping/masterboxes/:id",
  name: "shipping_masterboxes_view",
  component: MasterboxesView,
  meta: {
    visible: false,
    label: "Masterbox view",
  },
};

const shipping_flights = {
  path: "/shipping/flights",
  name: "shipping_flights",
  component: Flights,
  meta: {
    visible: true,
    label: "Flights",
    searchData: "flights",
  },
};

const shipping_flights_view = {
  path: "/shipping/flights/:id",
  name: "shipping_flights_view",
  component: FlightsView,
  meta: {
    visible: false,
    label: "Flights view",
  },
};

const shipping_rules = {
  path: "/shipping/shipping_rules",
  name: "ShippingRules",
  component: ShippingRules,
  meta: {
    visible: true,
    label: "ShippingRules",
    searchData: "shippingRules",
  },
};

const duties_and_taxes_status = {
  path: "/shipping/duties_and_taxes_status",
  name: "DutiesAndTaxesStatus",
  component: DutiesAndTaxesStatus,
  meta: {
    visible: true,
    label: "DutiesAndTaxesStatus",
    searchData: "dutiesAndTaxesStatus",
  },
};

const duties_and_taxes_status_view = {
  path: "/shipping/duties_and_taxes_status/:id",
  name: "DutiesAndTaxesStatusView",
  component: DutiesAndTaxesStatusView,
  meta: {
    visible: false,
    label: "DutiesAndTaxesStatusView",
  },
};

const shipping_children = [
  shipping_volumes,
  shipping_volumes_view,
  shipping_volumes_reverse,
  shipping_orders,
  shipping_overpacks,
  shipping_masterboxes,
  shipping_masterboxes_view,
  shipping_flights,
  shipping_flights_view,
  shipping_rules,
  duties_and_taxes_status,
  duties_and_taxes_status_view,
];

const shipping = {
  path: "/shipping",
  name: "shipping",
  component: PassThrough,
  meta: {
    visible: true,
    label: "Shipping",
    icon: "shopping-bag",
  },
  children: shipping_children,
};

export default shipping;
