<template>
  <NbBadge :text="statusType.text" :type="statusType.type" />
</template>

<script>
import NbBadge from "@/components/alerts/NbBadge.vue";
export default {
  components: {
    NbBadge,
  },
  props: {
    // processed, processing, error
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    statusType() {
      const statuses = {
        processed: {
          text: this.$t("fiscalPaymentsPage.status.processed"),
          type: "success",
        },
        processing: {
          text: this.$t("fiscalPaymentsPage.status.processing"),
          type: "info",
        },
        error: {
          text: this.$t("fiscalPaymentsPage.status.error"),
          type: "danger",
        },
      };

      return statuses[this.status] || statuses.processing;
    },
  },
};
</script>

<style lang="scss" scoped></style>
