<template>
  <div :class="{ expanded, children }">
    <div :class="['item', { children, isOpen }]">
      <div v-if="route.children && !route.meta.head_and_link">
        <button @click="toggleChildren()" class="w-100">
          <div>
            <NbIcon :icon="route.meta.icon" :size="iconSize" />
          </div>
          {{ $t(`components.nav.${route.meta.label}`) }}
          <i
            v-show="showNestedRoutes"
            class="fas fa-chevron-down ml-auto mr-4"
          ></i>
        </button>
      </div>
      <div v-else>
        <router-link @click.native="emitCloseAll()" :to="route.path">
          <div>
            <NbIcon :icon="route.meta.icon" :size="iconSize" />
          </div>
          {{ $t(`components.nav.${route.meta.label}`) }}
        </router-link>
      </div>

      <div
        v-show="showNestedRoutes && route.children && !route.meta.head_and_link"
        class="childrens"
      >
        <router-link
          v-for="(routeChildren, indexChildren) in childrens"
          :key="indexChildren"
          :to="routeChildren.path"
        >
          <span>{{ $t(`components.nav.${routeChildren.meta.label}`) }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import NbIcon from "./icons/NbIcon.vue";
export default {
  props: {
    route: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    NbIcon,
  },
  data() {
    return {
      children: false,
      isOpen: false,
      iconSize: 16,
    };
  },
  watch: {
    expanded(value) {
      if (!value) {
        this.isOpen = false;
        return;
      }

      this.isOpen = true;
    },
  },
  computed: {
    showNestedRoutes() {
      return this.children && this.expanded && this.isOpen;
    },
    childrens() {
      return this.route.children?.filter((item) => item.meta.visible);
    },
  },
  methods: {
    toggleChildren() {
      this.children = !this.children;
      if (this.children) {
        this.$root.$emit("nav-item::open", this);
      }
    },
    rootCloseListener(instance) {
      if (this !== instance) {
        this.children = false;
      }
    },
    emitCloseAll() {
      this.$root.$emit("nav-item::close", this);
    },
    close() {
      this.children = false;
    },
  },
  created() {
    this.$root.$on("nav-item::open", this.rootCloseListener);
    this.$root.$on("nav-item::close", this.close);
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-radius: 4px;
  transition: all 0.3s;
  button,
  a {
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    border: none;
    padding: 0;
    text-decoration: none;
    color: var(--white);
    font-weight: 400;
    background-color: transparent;
    &:hover {
      background-color: transparent;
      filter: none;
    }
    &.router-link-exact-active {
      background-color: var(--primaryActive);
    }
    & > div {
      min-width: 64px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    i.fa-chevron-down {
      font-size: 10px;
    }
  }
  &.children.isOpen {
    background-color: var(--primaryActive);
    padding-bottom: 0.5rem;
  }
  &:has(.router-link-exact-active) {
    button,
    a {
      background-color: var(--primaryActive);
    }
  }
}

.expanded.children {
  padding: 0 1rem;
}

.childrens {
  padding-left: 64px;
  color: var(--white);
  font-weight: 400;
  font-size: 0.75rem;
  .router-link-exact-active {
    font-weight: bold;
  }
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
