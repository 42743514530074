export default {
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_ALL_ITEMS(state, payload) {
    state.all_items = payload;
  },
  SET_TOTAL_ROWS(state, payload) {
    state.totalRows = payload;
  },
  SET_CURRENT_PAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_LIMIT(state, payload) {
    state.limit = payload;
  },
  SET_FILTERS(state, payload) {
    state.filters = payload;
  },
  SET_NOT_BY_SELLER_ITEMS(state, payload) {
    state.notBySellerItems = payload;
  },
  SET_NOT_BY_SELLER_TOTAL_ROWS(state, payload) {
    state.notBySellerTotalRows = payload;
  },
};
