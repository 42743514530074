<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("volumesPage.createMasterbox") }}
      </ModalTitle>
    </ModalHeader>

    <div>
      <div v-if="successMessage.length < 1">
        {{ $t("volumesPage.wantCreateMasterbox") }}
        <hr />
        <span>
          {{ $t("volumesPage.volumes") }}
        </span>
        <strong class="d-block">{{ volumeIds.join(", ") }}</strong>
      </div>
      <div v-if="errorsFromMasterboxCreation.length > 0" class="">
        <div v-for="(erro, index) in errorsFromMasterboxCreation" :key="index">
          <p class="text-danger pre-formatted" v-html="erro"></p>
        </div>
        <div v-if="masterboxErrorJson" class="text-center">
          <NbButton variant="tertiary" @click="downloadMasterboxErrors"
            ><i class="fas fa-download"></i>
            {{ $t("volumesPage.downloadMasterboxErrors") }}
          </NbButton>
          <p>{{ $t("volumesPage.commentDownloadMasterboxErrors") }}</p>
        </div>
      </div>
      <div v-else>
        <div>{{ successMessage }}</div>
        <div v-if="requestId" class="py-3 px-1">
          <NbButton variant="tertiary" v-copy="requestId">
            {{ $t("volumesPage.copyReference_id") }}
          </NbButton>
        </div>
      </div>
    </div>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
      <NbButton
        @click="createMasterbox"
        :disabled="errorsFromMasterboxCreation.length || loading"
        class="ml-2"
      >
        {{ $t("save") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import MasterBoxService from "@/services/MasterBoxService.js";
import DownloadsService from "@/services/DownloadsService.js";
import { copyClipboard } from "@/directives/copy-clipboard.js";

const masterBoxService = new MasterBoxService();
const downloadsService = new DownloadsService();

export default {
  props: {
    volumeIds: {
      type: Array,
      required: true,
    },
  },
  directives: {
    copy: copyClipboard,
  },
  data() {
    return {
      masterboxErrorJson: null,
      errorsFromMasterboxCreation: [],
      loading: false,
      successMessage: "",
      requestId: null,
    };
  },
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
  },
  methods: {
    downloadMasterboxErrors() {
      let errorsJson = null;
      if (this.masterboxErrorJson) {
        errorsJson = this.masterboxErrorJson;
        downloadsService
          .loadProcessing({
            data_type: "masterbox_errors",
            reference_ids: this.selected,
            error_json: errorsJson,
          })
          .then(() => {
            this.$router.push("/archives");
          });
      }
    },
    async createMasterbox() {
      try {
        this.loading = true;
        this.masterboxErrorJson = null;
        const response = await masterBoxService.createMasterbox(this.volumeIds);

        this.requestId = response.data.data.request_id;
        this.successMessage = response.data.messages[0].replace(
          "request_id",
          this.requestId,
        );
      } catch (error) {
        if (error && error.response && error.response.data) {
          if (error.response.data.messages) {
            this.errorsFromMasterboxCreation = error.response.data.messages;
          }
          if (error.response.data.data && error.response.data.data.error_json) {
            this.masterboxErrorJson = error.response.data.data.error_json;
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
