import api from "./HttpService";
export default class CooperationService {
  getCooperations(params = {}) {
    return api.get("/v1/cooperations", {
      params,
    });
  }

  /**
   * Update contracts
   *
   * @param id
   * @param data
   * @returns {Promise}
   */
  deleteCooperation(id) {
    return api.delete("/v1/cooperations/" + id);
  }

  /**
   * Update contracts
   *
   * @param data
   * @returns {Promise}
   */
  addCooperation(data = {}) {
    return api.post("/v1/cooperations", data);
  }
}
