<template>
  <NbModal id="downloadVolumesReverse" @close="closeModal" prevent-close>
    <template #header>
      <div class="d-flex flex-column">
        <h4 class="heading-4" id="settingsTitle">
          {{ $t("components.selectTableColumns.title") }}
        </h4>

        <div class="d-flex justify-content-end">
          <NbButton variant="quaternary" @click="clearSelected()">{{
            $t("clearSelected")
          }}</NbButton>
          <NbButton variant="quaternary" @click="selectAll()" class="ml-2">{{
            $t("selectAll")
          }}</NbButton>
        </div>
      </div>
    </template>

    <template #body>
      <div>
        <NbCheckbox
          v-for="volume in volumeFields"
          :key="volume.key"
          v-model="volume.selected"
          :name="volume.label"
          :id="volume.key"
        />
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end">
        <NbButton variant="secondary" @click="closeModal()">{{
          $t("cancel")
        }}</NbButton>
        <NbButton
          @click="downloadVolumesReverse()"
          class="ml-2"
          :disabled="loading || !selectedColumns.length"
          >{{ $t("download") }}</NbButton
        >
      </div>
    </template>
  </NbModal>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";
import DownloadService from "@/services/DownloadsService";

const downloadsService = new DownloadService();

export default {
  name: "ModalDownloadVolumes",
  components: {
    NbModal,
    NbButton,
    NbCheckbox,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      volumeFields: [
        { key: "id", label: "ID", selected: true },
        {
          key: "volume.order.order_number",
          label: this.$t("volumeReversePage.fields.orderNumber"),
          selected: true,
        },
        {
          key: "volume.order_id",
          label: this.$t("volumeReversePage.fields.orderID"),
          type: "number",
          selected: true,
        },
        {
          key: "volume_id",
          label: this.$t("volumeReversePage.fields.volumeID"),
          type: "number",
          selected: true,
        },
        {
          key: "volume.order.seller_id",
          label: this.$t("volumeReversePage.fields.sellerID"),
          type: "number",
          selected: true,
        },
        {
          key: "name",
          label: this.$t("volumeReversePage.fields.name"),
          selected: true,
        },
        {
          key: "created_at",
          label: this.$t("volumeReversePage.fields.creationdate"),
          type: "date",
          selected: true,
        },
        {
          key: "reverse_number",
          label: this.$t("volumeReversePage.fields.reverseNumber"),
          selected: true,
        },
        {
          key: "address",
          label: this.$t("volumeReversePage.fields.address"),
          selected: true,
        },
        {
          key: "address_number",
          label: this.$t("volumeReversePage.fields.addressNumber"),
          selected: true,
        },
        {
          key: "address_complement",
          label: this.$t("volumeReversePage.fields.addressComplement"),
          selected: true,
        },
        {
          key: "state",
          label: this.$t("volumeReversePage.fields.state"),
          selected: true,
        },
        {
          key: "postal_code",
          label: this.$t("volumeReversePage.fields.postalCode"),
          selected: true,
        },
        {
          key: "country",
          label: this.$t("volumeReversePage.fields.country"),
          selected: true,
        },
        {
          key: "city",
          label: this.$t("volumeReversePage.fields.city"),
          selected: true,
        },
        {
          key: "volume.order.customer_full_name",
          label: this.$t("volumeReversePage.fields.customerName"),
          selected: true,
        },
        {
          key: "order_items_name",
          label: this.$t("volumeReversePage.fields.orderItemsName"),
          selected: true,
        },
        {
          key: "order_items_description",
          label: this.$t("volumeReversePage.fields.orderItemsdescription"),
          selected: true,
        },
        {
          key: "order_items_hs_code",
          label: this.$t("volumeReversePage.fields.orderItemsHSCode"),
          selected: true,
        },
      ],
    };
  },
  watch: {
    show(oldValue, newValue) {
      if (oldValue === newValue) return;

      if (newValue) {
        this.selectAll();
        this.$helpers.openModal("downloadVolumesReverse");
        return;
      }

      this.closeModal();
    },
  },
  computed: {
    selectedColumns() {
      return this.volumeFields
        .filter((field) => field.selected === true)
        .map((field) => field.key);
    },
  },
  methods: {
    clearSelected() {
      this.volumeFields = this.volumeFields.map((item) => ({
        ...item,
        selected: false,
      }));
    },
    selectAll() {
      this.volumeFields = this.volumeFields.map((item) => ({
        ...item,
        selected: true,
      }));
    },
    closeModal() {
      this.$helpers.closeModal("downloadVolumesReverse");
      this.$emit("update:show", false);
    },
    async downloadVolumesReverse() {
      try {
        this.loading = true;
        await downloadsService.loadProcessing({
          data_type: "volumes_reverse",
          reference_ids: this.selected,
          columns: this.selectedColumns,
        });

        this.closeModal();
        this.$router.push("/archives");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
