export default {
  bind(el, binding) {
    if (!el.src) {
      el.src = binding.value;
    }

    el.onerror = function () {
      el.src = binding.value;
    };
  },
};
