import GlobalService from "@/services/GlobalService";
const globalService = new GlobalService();

export default {
  async fetchAllItems({ commit }, namespace) {
    const res = await globalService.getAllItems(namespace);
    const countries = res.data.data;
    commit("SET_ALL_ITEMS", countries);
  },
};
