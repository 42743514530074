<template>
  <div class="nb-card-simple" :style="`width: ${width}; height: ${height};`">
    <slot />
    <div class="d-flex justify-content-between">
      <div class="nb-card-simple-header heading-3">
        <slot name="header"></slot>
        {{ title }}
        <NbHelpText v-if="helpText" :id="`nbcard-simple-${id}`" size="sm">
          {{ helpText }}
        </NbHelpText>
      </div>
    </div>
    <div class="nb-card-simple-body body-3">
      <div v-if="subTitle" class="body-3 mb-4">{{ subTitle }}</div>
      <slot name="body"></slot>
      {{ text }}
    </div>
    <div v-if="btnLabel" class="cardbtn-footer">
      <slot name="footer"></slot>
      <NbButton @click="$emit('click')">
        {{ btnLabel }}
      </NbButton>
    </div>
  </div>
</template>

<script>
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  name: "NbCard",
  components: { NbHelpText, NbButton },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    btnLabel: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "",
    },
    width: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: null,
    },
  },
};
</script>

<style scoped>
.nb-card-simple {
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 2px var(--gray-05);
  border: 1px solid var(--gray-05);
  border-radius: 4px;
  padding: 1.5rem;
}
.nb-card-simple-header {
  margin-bottom: 0.5rem;
}
.cardbtn-footer {
  margin-top: 0.5rem;
}
/* .nb-card-simple-body {
  margin-bottom: 0.5rem;
} */
</style>
