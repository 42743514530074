<template>
  <div>
    <NbPageTitle
      :title="$t('userProfilePage.title')"
      :subTitle="$t('userProfilePage.subtitle')"
    />

    <div class="content">
      <UserDataForm />
      <UserLoginForm />
    </div>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import UserDataForm from "./components/UserDataForm.vue";
import UserLoginForm from "./components/UserLoginForm.vue";

export default {
  name: "UserProfile",
  components: {
    NbPageTitle,
    UserDataForm,
    UserLoginForm,
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: grid;
  gap: 0.4rem;
  display: flex;
  flex-direction: column;
  max-width: 38.375rem;
}
</style>
