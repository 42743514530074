<template>
  <NbButton
    @click.stop="$emit('click')"
    class="relative"
    type="button"
    :variant="variant"
  >
    <div class="loader">
      <Spinner v-if="loading" />
    </div>
    <div class="d-flex align-items-center gap-1" :data-loading="loading">
      <slot>
        {{ $t("download") }}
      </slot>
    </div>
  </NbButton>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NbButton,
    Spinner,
  },
};
</script>

<style lang="scss" scoped>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

[data-loading="true"] {
  visibility: hidden;
}
</style>
