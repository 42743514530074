import { render, staticRenderFns } from "./AlertLogStatusBadge.vue?vue&type=template&id=2fee8684&scoped=true"
import script from "./AlertLogStatusBadge.vue?vue&type=script&lang=js"
export * from "./AlertLogStatusBadge.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fee8684",
  null
  
)

export default component.exports