import api from "./HttpService";

export default class LogService {
  /**
   * Get log
   *
   * @param {*} id
   * @returns {Promise}
   */
  getLog(id = null) {
    return api.get("/v1/logs/" + id);
  }

  getTwentyLogs(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/logs`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("v1/logs?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  getAllLogs() {
    return api.get("/v1/logs");
  }

  getLogsActionTypes() {
    return api.get("/v1/logs_action_types");
  }

  cancelImport(id) {
    return api.post("/v1/cancel-import/" + id);
  }

  validImport(log) {
    return api.post("/v1/confirm-import", { log: log });
  }

  removeOrder(orderIndex, logIndex) {
    return api.delete("/v1/delete-rejected/" + logIndex + "/" + orderIndex);
  }

  validateOrder(logIndex, orderIndex) {
    return api.put("/v1/validate-rejected/" + logIndex + "/" + orderIndex);
  }

  updateOrderLog(data, logIndex, orderIndex) {
    return api.put("/v1/update-rejected/" + logIndex + "/" + orderIndex, {
      order_log: data,
    });
  }

  getFirstLogUserId() {
    return api.get("/v1/get-first-log-user");
  }

  testUpdateDelay(id) {
    return api.put("/v1/delay-first-log-user/" + id);
  }

  getAllDelayedJobs() {
    return api.get("v1/delayed_jobs");
  }
}
