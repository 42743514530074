<template>
  <div @click="trigger" class="accordion-trigger">
    <h2 class="heading-2 m-0">
      <slot></slot>
    </h2>
    <NbIcon
      icon="chevron-down"
      :size="16"
      :attributes="{
        style: `${context.isOpen ? 'transform: rotate(180deg)' : ''}`,
      }"
    />
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
export default {
  components: {
    NbIcon,
  },
  inject: ["context"],
  methods: {
    trigger() {
      this.context.toggle();
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1rem 0;
  &:hover {
    h2 {
      text-decoration: underline;
    }
  }
}
</style>
