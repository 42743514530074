<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("attention") }}
      </ModalTitle>
    </ModalHeader>

    <div>
      <p class="text-bold">{{ $t("sellersViewPage.sellerIsCarrier") }}</p>
      <p>{{ $t("sellersViewPage.carrierReminder") }}</p>
    </div>

    <ModalFooter class="d-flex justify-content-end gap-2">
      <ModalClose />
      <NbButton @click="onConfirm">
        {{ $t("ok") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
  },
  methods: {
    onConfirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped></style>
