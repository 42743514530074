<template>
  <div class="d-inline-block">
    <b-button
      v-b-popover.hover.top="'Filters'"
      pill
      variant="secondary"
      class="btn-sm"
      @click="$bvModal.show('modal-filters-' + namespace)"
    >
      <i class="fas fa-search"></i>
    </b-button>
    <div class="d-inline-block my-1">
      <b-button pill variant="secondary" class="btn-sm" @click="clearFilter">
        {{ $t("clearFilter") }}<i class="fas fa-trash ml-1"></i>
      </b-button>
    </div>
    <!-- manter filtro em TODAS as paginas
        <div class="d-inline-block my-1">
            <b-button pill variant="secondary" class="btn-sm text-white" :class="permanentFilter ? 'bg-success' : 'bg-danger'"  @click="switchPermanentFilter" v-b-popover.hover.top="'Turn on to keep filters for all pages (each page will keep its filter)'">
                Fix All Filters <i class="fas fa-anchor ml-1"></i>
            </b-button>
        </div>
        -->

    <div
      v-for="(value, key) in filterPillsComputed"
      :key="key"
      class="d-inline-block my-1"
    >
      <b-button
        v-if="key != 'limit' && key != 'offset' && key != 'source'"
        pill
        variant="danger"
        class="btn btn-danger btn-sm"
        @click="removeFilter(key)"
      >
        {{ findLabel(key) }}: {{ checkVal(key, value) }}
        <i class="fas fa-search-minus ml-1"></i>
      </b-button>
    </div>
    <!-- modals -->
    <b-modal
      :id="'modal-filters-' + namespace"
      :size="size"
      role="dialog"
      scrollable
      title="Filters"
    >
      <template #modal-header="{ close }">
        <div class="w-100">
          <div class="d-flex flex-column">
            <div class="d-flex justify-content-between">
              <h5 id="settingsTitle" class="modal-title float-left">
                {{ $t("filters") }}
              </h5>
              <div>
                <button
                  type="button"
                  class="close float-right"
                  @click="close()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-center">
              <button
                class="btn mr-1 mt-2"
                :class="btnSizeClass('')"
                style="width: 53px; height: 40px"
                @click="
                  size = '';
                  btnClass = '';
                "
              >
                <i class="far fa-window-maximize"></i>
              </button>
              <button
                class="btn mr-1 mt-2"
                :class="btnSizeClass('lg')"
                style="width: 53px; height: 40px"
                @click="
                  size = 'lg';
                  btnClass = 'lg';
                "
              >
                <i class="far fa-window-maximize fa-2x"></i>
              </button>
              <button
                class="btn mr-1 mt-2"
                :class="btnSizeClass('xl')"
                style="width: 53px; height: 40px"
                @click="
                  size = 'xl';
                  btnClass = 'xl';
                "
              >
                <i class="far fa-window-maximize" style="font-size: 2.3em"></i>
              </button>
            </div>
          </div>
        </div>
      </template>
      <div class="d-flex flex-wrap">
        <div
          v-for="filter in filterOptions"
          :id="filter.key + 'filterOptions'"
          :key="filter.key"
          class="form-group my-2 mx-1"
        >
          <b-popover
            v-if="filter.popoverText"
            :target="filter.key + 'filterOptions'"
            triggers="hover"
            placement="top"
          >
            {{ filter.popoverText }}
          </b-popover>
          <div
            v-if="filter.key && filter.type == 'boolean'"
            style="width: 210px"
          >
            <label :for="filter.key + filter.id">{{ filter.label }}</label>
            <b-form-select
              :id="filter.key + filter.id"
              v-model="filtersNotNested[filter.key]"
              :options="options"
              class="form-control"
            ></b-form-select>
          </div>
          <div
            v-else-if="filter.key && filter.type == 'dates'"
            style="width: 210px"
            class="d-inline"
          >
            <div class="d-inline-block mr-2" style="width: 210px">
              <label :for="filter.key + filter.id"
                >{{ $t("start") }} {{ filter.label }}</label
              >
              <input
                :id="filter.key + filter.id"
                v-model="filtersNotNested[`start_${filter.key}`]"
                type="date"
                class="form-control"
                :placeholder="`${filter.label} ..`"
              />
            </div>
            <div class="d-inline-block" style="width: 210px">
              <label :for="filter.key + filter.id"
                >{{ $t("end") }} {{ filter.label }}</label
              >
              <input
                :id="filter.key + filter.id"
                v-model="filtersNotNested[`end_${filter.key}`]"
                type="date"
                class="form-control"
                :placeholder="`${filter.label} ..`"
              />
            </div>
          </div>

          <div
            v-else-if="filter.key && filter.between"
            style="width: 210px"
            class="d-inline"
          >
            <div class="d-inline-block mr-2" style="width: 210px">
              <label :for="filter.key + filter.id"
                >{{ filter.label }} {{ $t("between") }}:</label
              >
              <input
                :id="filter.key + filter.id"
                v-model="filtersNotNested[`${filter.key}_gte`]"
                :type="filter.type"
                class="form-control"
                :placeholder="`${filter.label} ..`"
              />
            </div>
            <div class="d-inline-block" style="width: 210px">
              <label :for="filter.key + filter.id">{{ $t("and") }}:</label>
              <input
                :id="filter.key + filter.id"
                v-model="filtersNotNested[`${filter.key}_lte`]"
                :type="filter.type"
                class="form-control"
                :placeholder="`${filter.label} ..`"
              />
            </div>
          </div>

          <div
            v-else-if="filter.key && filter.type == 'select'"
            style="width: 210px"
          >
            <label :for="filter.key + filter.id">{{ filter.label }}</label>
            <b-form-select
              :id="filter.key + filter.id"
              v-model="filtersNotNested[filter.key]"
              :label="filter.label"
              :options="filter.options"
              class="form-control"
            >
              <template #first>
                <b-form-select-option :value="null">{{
                  $t("selectAnOption")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div v-else-if="filter.key" style="width: 210px">
            <label :for="filter.key + filter.id">{{ filter.label }}</label>
            <input
              :id="filter.key + filter.id"
              v-model="filtersNotNested[filter.key]"
              :type="filter.type ? filter.type : 'text'"
              class="form-control"
              :placeholder="`${filter.label} ..`"
            />
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button class="btn btn-secondary ml-3" @click="clearFilter">
          {{ $t("clearFilter") }} <i class="fas fa-trash ml-1"></i>
        </button>
        <button class="btn btn-primary text-white" @click="emitFilter">
          {{ $t("filter") }} <i class="fas fa-search ml-1"></i>
        </button>
      </template>
    </b-modal>
    <!-- end modal -->
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "FiltersforTable",

  props: {
    filterOptions: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: null,
    },
    namespace: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      filtersNotNested: {},
      filterPills: {},
      options: [
        { value: null, text: "Please select an option" },
        { value: true, text: "True" },
        { value: false, text: "False" },
      ],
      size: "",
    };
  },
  computed: {
    filterPillsComputed() {
      return this.filterPills;
    },
    ...mapState("shared", {
      keepFiltering: (state) => state.keepFiltering,
      //manter filtro em todas as paginas permanentFilter: state => state.permanentFilter,
    }),
  },
  created() {
    const startedFilter = this.$route.params.filteredVal || false;

    if (this.$route.params.namespace === this.namespace) {
      if (startedFilter) {
        this.filtersNotNested = startedFilter;
        this.emitFilter();
        return;
      }
    }
    if (!this.keepFiltering) {
      /*manter filtro em todas as paginas => && !this.permanentFilter */
      this.clearFilter();
    }
    const filtersStore = this.$store.state[this.namespace].filters;
    this.filterPills = this.dotify(filtersStore);
    this.setKeepFiltering(false);
  },
  methods: {
    ...mapMutations("shared", {
      setKeepFiltering: "SET_KEEPFILTERING",
      //manter filtro em todas as paginas setPermanentFilter: 'SET_PERMANENTFILTER'
    }),
    /* manter filtro em todas as paginas
        switchPermanentFilter() {
            this.setPermanentFilter(!this.permanentFilter);
        },
        */
    emitFilter() {
      this.$bvModal.hide("modal-filters-" + this.namespace);
      this.filterPills = this.filtersNotNested;
      const filtersToEmit = this.createNestedObject(this.filtersNotNested);
      this.$store.commit(this.namespace + "/SET_FILTERS", filtersToEmit);
      this.dotify(filtersToEmit);
    },
    clearFilter() {
      this.$bvModal.hide("modal-filters-" + this.namespace);
      this.filtersNotNested = {};
      this.filterPills = this.filtersNotNested;
      this.$store.commit(
        this.namespace + "/SET_FILTERS",
        this.filtersNotNested,
      );
    },
    removeFilter(key) {
      this.filtersNotNested[key] = "";
      delete this.filtersNotNested[key];
      this.filterPills = this.filtersNotNested;
      const filtersToEmit = this.createNestedObject(this.filtersNotNested);
      this.$store.commit(this.namespace + "/SET_FILTERS", filtersToEmit);
    },
    createNestedObject(obj) {
      const result = {};
      let change_filter = obj;
      for (const objectPath in change_filter) {
        const parts = objectPath.split(".");
        let target = result;
        while (parts.length > 1) {
          const part = parts.shift();
          target = target[part] = target[part] || {};
        }
        target[parts[0]] = change_filter[objectPath];
      }
      return result;
    },
    dotify(obj) {
      const res = {};
      function recurse(obj, current) {
        for (const key in obj) {
          const value = obj[key];
          if (value != undefined) {
            const newKey = current ? current + "." + key : key;
            if (value && typeof value === "object") {
              recurse(value, newKey);
            } else {
              res[newKey] = value;
            }
          }
        }
      }
      recurse(obj);
      return res;
    },
    findLabel(key) {
      const currentFilterOption = this.filterOptions.find(
        (filterOption) => filterOption.key === key,
      );
      if (currentFilterOption) {
        return currentFilterOption.label;
      }
    },
    checkVal(key, value) {
      const currentFilterOption = this.filterOptions.find(
        (filterOption) => filterOption.key === key,
      );
      if (currentFilterOption && currentFilterOption.type === "select") {
        const currentSelectText = currentFilterOption.options.find(
          (option) => option.value === value,
        );
        return currentSelectText.text;
      }

      return this.displayMaxCharacters(value, 20);
    },
    displayMaxCharacters(word, maxChars) {
      if (word.length > maxChars) {
        return word.substring(0, maxChars - 5) + "(...)";
      }
      return word;
    },
    btnSizeClass(word) {
      if (word == this.size) {
        return "btn-primary";
      }
      return "btn-secondary";
    },
  },
};
</script>

<style scoped></style>
