<template>
  <div :id="id">
    <label v-show="label" class="input-label" :for="'dropfile-' + _uid"
      >{{ label }} <slot name="labelSlot"></slot
    ></label>
    <div
      :id="`${id}-trigger`"
      :class="['nb-drop-file', { isDragging: isDragging }, imgClass]"
      @dragover="onDragOver"
      @dragleave="onDragLeave"
      @drop="onDrop"
      @click="trigger"
      :style="`min-height: ${minHeight};`"
    >
      <input
        type="file"
        :multiple="multiple"
        @change="onChange"
        ref="file"
        :accept="fileTypes"
        :id="'dropfile-' + _uid"
      />
      <img
        v-if="imgShow && !files.length"
        :src="imgShow"
        class="logo-previsualization"
        :style="`width: ${imgWidth}; height: ${imgHeight}`"
      />
      <embed
        v-else-if="showMiniaturePdf && checkHasFile"
        type="application/pdf"
        toolbar="0"
        :src="pdfUrl"
        width="100%"
        :height="embedHeight"
      />
      <slot v-else>
        <p class="body-3 m-0 text-center">
          {{ $t("dragDropFile") }}
        </p>
      </slot>

      <PreviewFiles
        v-if="files.length && showPreviewFiles"
        :files="files"
        @remove="remove"
      />
    </div>
    <ErrorFeedback :error="errors[0]" />
  </div>
</template>

<script>
import ErrorFeedback from "@/components/generic/ErrorFeedback.vue";
import PreviewFiles from "./PreviewFiles.vue";

export default {
  name: "NbDropFile",
  components: {
    PreviewFiles,
    ErrorFeedback,
  },
  props: {
    value: {
      type: [String, File],
      required: true,
    },
    id: {
      type: String,
      default: "dropfile",
    },
    minHeight: {
      type: String,
      required: false,
    },
    embedHeight: {
      type: String,
      default: "404px",
    },
    showPreviewFiles: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    accept: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    patternFile: {
      required: false,
    },
    imgShow: {
      type: String,
      default: "",
    },
    imgHeight: {
      type: String,
      required: false,
    },
    imgWidth: {
      type: String,
      required: false,
    },
    showMiniaturePdf: {
      type: Boolean,
      default: false,
    },
    /*  pdfUrl: {
      type: String,
      default: "",
    }, */
  },
  data() {
    return {
      isDragging: false,
      files: [],
      errors: [],
      file64: null,
      processedFile: null,
    };
  },
  computed: {
    fileTypes() {
      return this.accept.length ? this.accept.join(",") : "";
    },
    imgClass() {
      if (this.imgShow && !this.files.length) {
        return "img-show";
      }
      return "";
    },
    checkHasFile() {
      if (this.files.length > 0) {
        return true;
      }
      if (
        (this.value &&
          typeof this.value == "object" &&
          this.value.name.length > 0) ||
        this.value.length > 0
      ) {
        return true;
      }
      return false;
    },
    pdfUrl() {
      if (!this.file64 && !this.value) {
        return false;
      }
      if (!this.file64 || typeof this.value === "string") {
        return this.value + "#toolbar=0&navpanes=0&scrollbar=0";
      }
      return this.file64 + "#toolbar=0&navpanes=0&scrollbar=0";
    },
  },
  methods: {
    trigger(event) {
      this.$emit("click");
      event.stopPropagation();
      this.$refs.file?.click();
    },
    onDragOver(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    onDragLeave() {
      this.isDragging = false;
    },
    onDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.isDragging = false;
      if (!files?.length) return;
      this.handleFiles(files);
    },
    validFiles(files) {
      if (!this.accept.length) return true;

      const sanitizeAcceptFiles = this.accept.map((item) =>
        item
          .replace(/[^A-z/]/g, "")
          ?.split("/")
          ?.pop(),
      );

      return Array.from(files).every((file) => {
        return sanitizeAcceptFiles.includes(file.type.split("/").pop());
      });
    },
    processFile(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const parts = reader.result.split("base64,");
        this.processedFile = parts[1];
        this.$emit("stringFile", this.processedFile);
      };
    },
    convertToBase64(file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.file64 = reader.result;
      };
      reader.readAsDataURL(file);
    },
    onChange(event) {
      const files = event?.target?.files;
      if (!files?.length) return;
      this.handleFiles(files);
    },
    handleFiles(files) {
      this.processFile(files[0]);
      if (this.showMiniaturePdf) {
        const file = files[0];
        this.convertToBase64(file);
      }

      if (!files.length) return;

      this.errors = [];

      if (!this.validFiles(files)) {
        this.errors.push(
          `${this.$t("invalidFileFormat")}: ${this.accept.join(",")}`,
        );
        return;
      }

      if (this.multiple) {
        this.files.push(...files);
      } else {
        this.files = [files[0]];
      }
      this.emitFiles();
    },
    remove(index) {
      this.files.splice(index, 1);
      this.emitFiles();
      this.$emit("input", "");
      if (!this.files || !this.files.length) {
        this.$refs.file.value = "";
      }
    },
    emitFiles() {
      if (this.files.length) {
        this.$emit("input", this.multiple ? this.files : this.files[0]);
        return;
      }

      this.$emit("input", "");
    },
  },
  watch: {
    value(newValue) {
      if (newValue === "") this.files = [];
    },
    patternFile(newVal) {
      if (newVal) {
        this.files = newVal;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nb-drop-file {
  cursor: pointer;
  &.img-show {
    padding: 0rem;
    border: 2px solid var(--gray-10);
    background-color: var(--gray-10);
  }
  border-radius: 8px;
  padding: 1.5rem;
  border: 2px dashed var(--gray-40);
  &:hover,
  &.isDragging {
    background-color: var(--gray-10);
  }

  &.isDragging {
    border-color: var(--primary);
  }

  input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
  }

  .logo-previsualization {
    object-fit: contain;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
</style>
