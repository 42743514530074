import Vue from "vue";
import VueI18n from "vue-i18n";
//import dos arquivos de linguas
import en from "./locales/en";
import pt_BR from "./locales/pt_BR";
import zh_CN from "./locales/zh_CN";
import es from "./locales/es";

Vue.use(VueI18n); //por no main.js ???

//uso dos arquivos
const messages = {
  en,
  pt_BR,
  zh_CN,
  es,
};

export default new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  warnHtmlInMessage: "on",
  escapeParameterHtml: true,
  messages,
});
