<template>
  <div>
    <ModalHeader>
      <div class="d-flex flex-column align-items-start">
        <ModalTitle>
          {{ $t("fiscalPaymentsPage.fiscalInfo") }}
          <span v-show="fiscalPayment?.id">#{{ fiscalPayment.id }}</span>
        </ModalTitle>

        <FiscalPaymentStatus v-if="!loading" :status="fiscalPayment.status" />
      </div>
    </ModalHeader>

    <Spinner v-if="loading" class="mx-auto" size="lg" />

    <form v-else class="grid gap-4 mt-4">
      <NbCard :title="$t('suggestedActions')" id="suggested-actions">
        <template #body>
          <div>
            <DownloadGNRE
              :fiscal-payment-id="fiscalPaymentId"
              variant="primary"
            >
              <NbIcon icon="download" :size="18" />
              Download Boleto GNRE
            </DownloadGNRE>
          </div>
        </template>
      </NbCard>

      <NbCard :title="$t('generalData')" id="general-data">
        <template #body>
          <div class="grid grid-cols-2 gap-4">
            <NbTextInput
              v-model="fiscalPayment.reference_number"
              id="fiscalPayment.reference_number"
              :name="$t('fiscalPaymentsPage.fields.reference_number')"
              variant="borderless-gray-60"
              placeholder="-"
              disabled
            />
            <NbTextInput
              v-model="fiscalPayment.payment_date"
              id="fiscalPayment.payment_date"
              :name="$t('fiscalPaymentsPage.fields.payment_date')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            />
            <NbTextInput
              v-model="fiscalPayment.authentication"
              id="fiscalPayment.authentication"
              :name="$t('fiscalPaymentsPage.fields.authentication')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            />

            <NbTextInput
              v-model="fiscalPayment.payment_type"
              id="fiscalPayment.payment_type"
              :name="$t('fiscalPaymentsPage.fields.payment_type')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            />
            <NbTextInput
              v-model="fiscalPayment.value"
              id="fiscalPayment.value"
              :name="$t('fiscalPaymentsPage.fields.value')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            />
            <NbTextInput
              v-model="fiscalPayment.fiscal_parcel_id"
              id="fiscalPayment.fiscal_parcel_id"
              :name="$t('fiscalPaymentsPage.fields.fiscal_parcel_id')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            />
            <NbTextInput
              v-model="fiscalPayment.courrier_name"
              id="fiscalPayment.courrier_name"
              :name="$t('fiscalPaymentsPage.fields.courrierName')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            />
            <NbTextInput
              v-model="fiscalPayment.courrier_cnpj"
              id="fiscalPayment.courrier_cnpj"
              :name="$t('fiscalPaymentsPage.fields.courrierCNPJ')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            />
            <NbTextInput
              v-model="fiscalPayment.barcode"
              id="fiscalPayment.barcode"
              :name="$t('fiscalPaymentsPage.fields.barcode')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
              class="col-span-2"
            />

            <NbTextInput
              v-model="fiscalPayment.messages"
              id="fiscalPayment.messages"
              :name="$t('fiscalPaymentsPage.fields.messages')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
              class="col-span-2"
            />
            <!-- <NbTextInput
              v-model="fiscalPayment.created_at"
              id="fiscalPayment.created_at"
              :name="$t('fiscalPaymentsPage.fields.created_at')"
              variant="borderless-gray-60"
              disabled
              placeholder="-"
            /> -->
            <div class="col-span-2 d-flex align-items-end justify-content-end">
              <div>
                <label class="input-label">{{
                  $t("fiscalPaymentsPage.fields.created_at")
                }}</label>
                <span class="d-block">{{ fiscalPayment.created_at }}</span>
              </div>
            </div>
          </div>
        </template>
      </NbCard>

      <NbCard
        :title="$t('fiscalPaymentsPage.fiscalParcelData')"
        id="fiscal-parcel"
      >
        <template #body>
          <div class="grid gap-4">
            <div class="grid grid-cols-2 gap-4">
              <NbTextInput
                v-model="fiscalPayment.fiscal_parcel.dir_number"
                id="fiscalPayment.dir_number"
                :name="$t('fiscalPaymentsPage.fields.dir_number')"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />
              <NbTextInput
                v-model="fiscalPayment.fiscal_parcel.dir_date"
                id="fiscalPayment.dir_date"
                :name="$t('fiscalPaymentsPage.fields.dir_date')"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />
              <NbTextInput
                v-model="fiscalPayment.fiscal_parcel.master_number"
                id="fiscalPayment.master_number"
                :name="$t('fiscalPaymentsPage.fields.masterNumber')"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />
              <router-link
                v-if="fiscalPayment.seller_name"
                :to="`/users_management/sellers?id=${fiscalPayment.fiscal_parcel.seller_id}`"
                class="link-1"
              >
                <NbTextInput
                  :value="
                    fiscalPayment.seller_name +
                    ` (${fiscalPayment.fiscal_parcel.seller_id})`
                  "
                  id="fiscalPayment.seller_id"
                  :name="$t('seller')"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />
              </router-link>
              <NbTextInput
                v-else
                v-model="fiscalPayment.fiscal_parcel.seller_id"
                id="fiscalPayment.seller_id"
                :name="$t('seller')"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />
            </div>
            <div class="grid gap-4">
              <div class="truncate">
                <span class="input-label">
                  {{ $t("fiscalPaymentsPage.fields.products_description") }}
                </span>
                <div class="input-view">
                  {{
                    fiscalPayment?.fiscal_parcel?.products_description || "-"
                  }}
                </div>
              </div>

              <NbTextInput
                v-model="fiscalPayment.fiscal_parcel.tracking_number"
                id="fiscalPayment.tracking_number"
                :name="$t('fiscalPaymentsPage.fields.tracking_number')"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />
            </div>
          </div>
        </template>
      </NbCard>

      <NbCard :title="$t('fiscalPaymentsPage.customerData')" id="customer-data">
        <template #body>
          <div class="grid grid-cols-2 gap-4">
            <NbTextInput
              v-model="fiscalPayment.fiscal_parcel.customer_name"
              id="fiscalPayment.customer_name"
              :name="$t('fiscalPaymentsPage.fields.customer_name')"
              variant="borderless-gray-60"
              placeholder="-"
              disabled
            />

            <NbTextInput
              v-model="fiscalPayment.fiscal_parcel.customer_cpf"
              id="fiscalPayment.customer_cpf"
              :name="$t('fiscalPaymentsPage.fields.customer_cpf')"
              variant="borderless-gray-60"
              placeholder="-"
              disabled
            />
            <NbTextInput
              v-model="fiscalPayment.fiscal_parcel.customer_cep"
              id="fiscalPayment.customer_cep"
              :name="$t('fiscalPaymentsPage.fields.customer_cep')"
              variant="borderless-gray-60"
              placeholder="-"
              disabled
            />
            <NbTextInput
              v-model="fiscalPayment.fiscal_parcel.customer_state"
              id="fiscalPayment.customer_state"
              :name="$t('fiscalPaymentsPage.fields.customer_state')"
              variant="borderless-gray-60"
              placeholder="-"
              disabled
            />
          </div>
        </template>
      </NbCard>
    </form>

    <ModalFooter>
      <ModalClose class="d-flex ml-auto" />
    </ModalFooter>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

import FiscalPaymentService from "@/services/FiscalPaymentService";
import FiscalPaymentStatus from "./FiscalPaymentStatus.vue";
import moment from "moment";
import DownloadGNRE from "./DownloadGNRE.vue";
const fiscalPaymentService = new FiscalPaymentService();

const currencyFormat = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    Spinner,
    NbTextInput,
    FiscalPaymentStatus,
    NbCard,
    DownloadGNRE,
    NbIcon,
  },
  data() {
    return {
      loading: false,
      fiscalPaymentId: null,
      fiscalPayment: {
        fiscal_parcel: {
          customer_name: "",
          customer_cpf: "",
          customer_cep: "",
          customer_state: "",
          dir_date: "",
          dir_number: "",
          products_description: "",
          tracking_number: "",
          seller_id: null,
        },
        status: null,
        value: "",
      },
    };
  },
  methods: {
    async loadFiscalPayment() {
      try {
        this.loading = true;
        const { data } = await fiscalPaymentService.findById(
          this.fiscalPaymentId,
        );
        this.fiscalPayment = {
          ...data.data,
          value: data.data.value ? currencyFormat.format(data.data.value) : "",
          payment_date: data.data?.payment_date
            ? moment(data.data.payment_date).format("DD/MM/YYYY")
            : "-",
          created_at: moment(data.data.created_at).format(
            "DD/MM/YYYY HH:mm.ss",
          ),
          fiscal_parcel: {
            ...data.data.fiscal_parcel,
            dir_date: moment(data.data.dir_date).format("DD/MM/YYYY HH:mm.ss"),
          },
        };
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    const { id } = this.$route.query;
    if (id) {
      this.fiscalPaymentId = Number(id);
      this.loadFiscalPayment();
    }
  },
};
</script>

<style lang="scss" scoped></style>
