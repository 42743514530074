<template>
  <div>
    <NbButton
      variant="quaternary"
      @click="isOptionsOpen = !isOptionsOpen"
      class="d-flex gap-2 align-items-center"
      :disabled="disabled"
    >
      <NbIcon icon="chevron-down" :size="18" />
      {{ $t("actions") }}
    </NbButton>
    <div class="options-wrapper">
      <transition name="top-down" mode="out-in">
        <div
          v-if="isOptionsOpen"
          class="options"
          v-click-outside="closeOptions"
        >
          <slot></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import { directive } from "v-click-outside";

export default {
  directives: { clickOutside: directive },
  components: {
    NbButton,
    NbIcon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOptionsOpen: false,
    };
  },
  methods: {
    closeOptions() {
      this.isOptionsOpen = false;
    },
  },
  provide() {
    const datatableOptionsContext = {
      close: () => this.closeOptions(),
    };

    return {
      datatableOptionsContext,
    };
  },
};
</script>

<style lang="scss" scoped>
.options-wrapper {
  position: relative;

  .options {
    position: absolute;
    right: 0;
    top: 0.5rem;
    z-index: 5;
    min-width: 18rem;
    background: var(--gray-05);
    box-shadow: 4px 4px 12px var(--gray-10);
    border-radius: 4px;
    border-top: 1px solid var(--gray-10);
    border-right: 2px solid var(--gray-10);
    border-bottom: 2px solid var(--gray-10);
    border-left: 2px solid var(--gray-10);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.top-down-enter-active {
  transition: all 0.3s ease-out;
}

.top-down-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.top-down-enter,
.top-down-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
