<template>
  <div class="sellers-wrapper mt-4">
    <NbTabs :tabs="allTabs" @switchTab="currentTab = $event" class="mb-4" />

    <template v-if="sellersRankingData.length">
      <div class="heading d-flex justify-content-between mb-2">
        <small class="heading-1">{{ $t("homePage.seller") }}</small>
        <small class="heading-1">{{ tableCountDescription }}</small>
      </div>
      <div
        class="boxes grid gap-4"
        v-for="(seller, index) in sellersRankingData"
        :key="seller.id"
      >
        <div class="box d-flex align-items-center justify-content-between">
          <div class="description">
            <h2 class="seller heading-2 m-0" :data-ranking="index + 1">
              {{ seller.name }}
            </h2>
          </div>
          <div class="value heading-3">{{ seller.orders_count }}</div>
        </div>
      </div>
    </template>
    <div v-else class="body-1">
      {{ $t("homePage.noSellerRanking") }}
    </div>
  </div>
</template>

<script>
import NbTabs from "@/components/tables/NbTabs.vue";

const Tabs = {
  DELIVERED_ORDERS: "delivered_orders",
  CREATED_ORDERS: "created_orders",
};

export default {
  props: {
    sellersRanking: {
      type: Object,
      default: () => ({
        deliveredOrders: [],
        createdOrders: [],
      }),
    },
  },
  components: {
    NbTabs,
  },
  data() {
    return {
      currentTab: Tabs.DELIVERED_ORDERS,
    };
  },
  computed: {
    tableCountDescription() {
      if (this.currentTab === Tabs.DELIVERED_ORDERS) {
        return this.$t("homePage.deliveredOrders");
      }

      return this.$t("homePage.createdOrders");
    },
    allTabs() {
      return [
        {
          id: Tabs.DELIVERED_ORDERS,
          label: this.$t("homePage.deliveredOrders"),
          found: null,
          current: this.currentTab,
        },
        {
          id: Tabs.CREATED_ORDERS,
          label: this.$t("homePage.createdOrders"),
          found: null,
          current: this.currentTab,
        },
      ];
    },
    sellersRankingData() {
      if (this.currentTab === Tabs.DELIVERED_ORDERS) {
        return this.sellersRanking.deliveredOrders;
      }

      return this.sellersRanking.createdOrders;
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  .heading-1 {
    color: var(--gray-60);
  }
}
.boxes {
  .box {
    background-color: var(--gray-00);
    padding: 1rem;
    border-radius: 4px;
    .description {
      padding-left: 1.5rem;
      position: relative;
      .seller {
        &::before {
          content: attr(data-ranking) "º";
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .country {
        color: var(--gray-60);
        font-weight: 400;
      }
    }
    .value {
      background-color: var(--gray-05);
      border-radius: 4px;
      padding: 0.5rem;
    }
  }
}
</style>
