<template>
  <div>
    <DatatableRoot
      namespace="flights"
      ref="flighstTableRef"
      clickable
      :columns="tableColumns"
      :filters="tableFilters"
      @count="$emit('count', $event)"
      @rowClick="$emit('rowClick', $event)"
      :search-params="{ status }"
      :url-state="false"
    >
      <template #flight_label="{ row }">
        <div v-if="row.mawb_number">
          <DownloadFlightLabelButton variant="tertiary" :flight-id="row.id" />
        </div>
      </template>

      <template #freight_value="{ row }">
        {{ row.freight_value | currency("USD") }} USD
      </template>

      <template #status="{ row }">
        <FlightStatus :status="row.status" />
      </template>

      <template #departure_date="{ row }">
        {{ row.departure_date | date }}
      </template>

      <template #arrival_date="{ row }">
        {{ row.arrival_date | date }}
      </template>

      <template #created_at="{ row }">
        {{ row.created_at | date }} {{ row.created_at | time }}
      </template>
    </DatatableRoot>
  </div>
</template>

<script>
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import DownloadFlightLabelButton from "./DownloadFlightLabelButton.vue";
import FlightStatus from "../components/FlightStatus.vue";
import moment from "moment";

export default {
  components: {
    DatatableRoot,
    FlightStatus,
    DownloadFlightLabelButton,
  },
  props: {
    status: {
      type: String,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  filters: {
    date(val) {
      return val && moment(val).format("DD/MM/YYYY");
    },
    time(val) {
      return val && moment(val).format("HH:mm:ss");
    },
  },
  computed: {
    tableColumns() {
      return [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "airline",
          label: this.$t("flightsPage.fields.airline"),
        },
        {
          key: "flight_number",
          label: this.$t("flightsPage.fields.flightNumber"),
        },
        {
          key: "flight_label",
          label: this.$t("flightsPage.fields.flightLabel"),
        },
        {
          key: "mawb_number",
          label: this.$t("flightsPage.fields.mawbNumber"),
        },
        {
          key: "status",
          label: this.$t("flightsPage.fields.status"),
        },
        {
          key: "freight_value",
          label: this.$t("flightsPage.fields.freightValue"),
        },
        {
          key: "departure_airport",
          label: this.$t("flightsPage.fields.departureAirport"),
        },
        {
          key: "departure_date",
          label: this.$t("flightsPage.fields.departureDate"),
        },
        {
          key: "departure_time",
          label: this.$t("flightsPage.fields.departureTime"),
        },
        {
          key: "arrival_airport",
          label: this.$t("flightsPage.fields.arrivalAirport"),
        },
        {
          key: "arrival_date",
          label: this.$t("flightsPage.fields.arrivalDate"),
        },
        {
          key: "arrival_time",
          label: this.$t("flightsPage.fields.arrivalTime"),
        },
        {
          key: "stopover_airport",
          label: this.$t("flightsPage.fields.stopoverAirport"),
        },
        {
          key: "created_at",
          label: this.$t("flightsPage.fields.creationDate"),
          sortable: true,
        },
      ];
    },
    tableFilters() {
      const options = [
        {
          label: this.$t("flightsPage.fields.airline"),
          inputs: [
            {
              key: "airline",
            },
          ],
        },
        {
          label: this.$t("flightsPage.fields.flightNumber"),
          inputs: [{ key: "flight_number" }],
        },
        {
          label: "Status",
          inputs: [
            {
              key: "status",
              type: "select",
              options: [
                {
                  text: "Manifest Closed",
                  value: "manifest_closed",
                },
                {
                  text: "Open manifest",
                  value: "open_manifest",
                },
              ],
            },
          ],
        },
        {
          label: this.$t("flightsPage.fields.mawbNumber"),
          inputs: [
            {
              key: "mawb_number",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("flightsPage.fields.freightValue"),
          inputs: [
            {
              key: "start_freight_value",
              type: "money",
              placeholder: "Min",
              currency: {
                prefix: "USD",
                decimal: ".",
                thousands: ",",
              },
            },
            {
              key: "end_freight_value",
              type: "money",
              placeholder: "Max",
              currency: {
                prefix: "USD",
                decimal: ".",
                thousands: ",",
              },
            },
          ],
        },
        {
          label: this.$t("flightsPage.fields.departureAirport"),
          inputs: [
            {
              key: "departure_airport",
            },
          ],
        },
        {
          label: this.$t("flightsPage.fields.departureDate"),
          inputs: [
            {
              key: "start_departure_date",
              type: "date",
            },
            {
              key: "end_departure_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("flightsPage.fields.departureTime"),
          inputs: [
            {
              key: "departure_time",
            },
          ],
        },
        {
          label: this.$t("flightsPage.fields.arrivalAirport"),
          inputs: [
            {
              key: "arrival_airport",
            },
          ],
        },
        {
          label: this.$t("flightsPage.fields.arrivalDate"),
          inputs: [
            {
              key: "start_arrival_date",
              type: "date",
            },
            {
              key: "end_arrival_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("flightsPage.fields.arrivalTime"),
          inputs: [
            {
              key: "arrival_time",
            },
          ],
        },
        {
          label: this.$t("flightsPage.fields.stopoverAirport"),
          inputs: [
            {
              key: "stopover_airport",
            },
          ],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
      ];

      if (!this.status) {
        return options;
      }

      return options.filter(
        (option) => !option.inputs.some((input) => input.key === "status"),
      );
    },
  },
  methods: {
    getData() {
      this.$refs?.flighstTableRef?.getData();
    },
  },
  activated() {
    if (this.active) {
      this.getData();
    }

    this.active = true;
  },
};
</script>

<style lang="scss" scoped></style>
