<template>
  <div class="container">
    <div class="d-flex justify-content-between">
      <div>
        <h5 class="font-weight-bold text-uppercase mb-4">NB Components</h5>
      </div>
      <div>
        <NbButton
          variant="tertiary"
          icon="fas fa-microscope"
          @click="testLangs()"
          >Test Langs files</NbButton
        >
      </div>
    </div>
    <div>
      <div v-if="showLangsTests">
        <div v-for="(result, key) in testResult" :key="key">
          <b>Falta em {{ key }}:</b>
          {{ result.length > 0 ? result : "All Ok" }}
        </div>
      </div>
      <h5 class="heading-2 pointer" @click="show.buttons = !show.buttons">
        <span class="text-primary">Botoes</span> props:
        variant="primary/secondary/tertiary" icon="string" size="sm/xs" disabled
      </h5>
      <div v-if="show.buttons">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-25">default</div>
            <div class="w-25">icon="fas fa-plus"</div>
            <div class="w-25">size="sm"</div>
            <div class="w-25">size="sm" icon="fas fa-plus"</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-25">
              <!-- <span class="text-primary"></span> props: variant="secondary" icon="fas fa-plus" disabled -->
              <NbButton>ButaoM</NbButton>
            </div>
            <div class="w-25">
              <NbButton icon="fas fa-plus">ButaoM</NbButton>
            </div>
            <div class="w-25">
              <NbButton size="sm">ButaoS</NbButton>
            </div>
            <div class="w-25">
              <NbButton size="sm" icon="fas fa-plus">ButaoS</NbButton>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-25">secondary</div>
            <div class="w-25">icon="fas fa-plus"</div>
            <div class="w-25">size="sm"</div>
            <div class="w-25">size="sm" icon="fas fa-plus"</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-25">
              <!-- <span class="text-primary"></span> props: variant="secondary" icon="fas fa-plus" -->
              <NbButton variant="secondary">ButaoM</NbButton>
            </div>
            <div class="w-25">
              <NbButton variant="secondary" icon="fas fa-plus">ButaoM</NbButton>
            </div>
            <div class="w-25">
              <NbButton variant="secondary" size="sm">ButaoS</NbButton>
            </div>
            <div class="w-25">
              <NbButton variant="secondary" size="sm" icon="fas fa-plus"
                >ButaoS</NbButton
              >
            </div>
          </div>
        </div>
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-25">tertiary</div>
            <div class="w-25">icon="far fa-clone"</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-25">
              <!-- <span class="text-primary"></span> props: variant="secondary" icon="fas fa-plus" -->
              <NbButton variant="tertiary">ButaoM</NbButton>
            </div>
            <div class="w-25">
              <NbButton variant="tertiary" icon="far fa-clone">ButaoM</NbButton>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-25">quaternary</div>
            <div class="w-25">icon="fas fa-plus"</div>
            <div class="w-25">size="sm"</div>
            <div class="w-25">size="sm" icon="fas fa-plus"</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-25">
              <!-- <span class="text-primary"></span> props: variant="quaternary" icon="fas fa-plus" -->
              <NbButton variant="quaternary">ButaoM</NbButton>
            </div>
            <div class="w-25">
              <NbButton variant="quaternary" icon="fas fa-plus"
                >ButaoM</NbButton
              >
            </div>
            <div class="w-25">
              <NbButton variant="quaternary" size="sm">ButaoS</NbButton>
            </div>
            <div class="w-25">
              <NbButton variant="quaternary" size="sm" icon="fas fa-plus"
                >ButaoS</NbButton
              >
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.options = !show.options">
        <span class="text-primary">Option Button</span> props: id="unique"
        options="[{ text:'option1' }, { text:'option2' }]"
        @input="optionClicked($event)"
      </h5>
      <div v-if="show.options">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-25">default</div>
            <div class="w-25">size="sm"</div>
            <div class="w-25">
              icon="fas fa-plus" @input="optionClicked($event)"
            </div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-25">
              <NbOption
                id="options-1"
                v-model="buttonOptionChosen"
                :options="buttonOptions"
                >ButaoM</NbOption
              >
            </div>
            <div class="w-25">
              <NbOption
                id="options-2"
                v-model="buttonOptionChosen"
                :options="buttonOptions"
                size="sm"
                @input="optionClicked($event)"
                >ButaoM</NbOption
              >
            </div>
            <div class="w-25">
              <NbOption
                id="options-2"
                v-model="buttonOptionChosen"
                :options="buttonOptions"
                icon="fas fa-plus"
                @input="optionClicked($event)"
                >ButaoM</NbOption
              >
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.checkbox = !show.checkbox">
        <span class="text-primary">Checkbox</span> props: id="unique"
        v-model="boolean" label="Um texto referente ao checkbox"
        :disabled="true"
      </h5>
      <div v-if="show.checkbox">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-50">default</div>
            <div class="w-50">disabled</div>
            <div class="w-50">Error</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-50">
              <NbCheckbox
                id="checkbox-example"
                v-model="check"
                name="Um texto referente ao checkbox"
              />
            </div>
            <div class="w-50">
              <NbCheckbox
                id="checkbox-example-1"
                v-model="check"
                name="Um texto referente ao checkbox"
                :disabled="true"
              />
            </div>
            <div class="w-50">
              <NbCheckbox
                id="checkbox-example-2"
                v-model="check"
                name="Um texto referente ao checkbox"
                :error="errors.textForInput2"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.radioBtn = !show.radioBtn">
        <span class="text-primary">Radio Button</span> props: id="unique"
        v-model="boolean" name="Um texto referente ao checkbox" :disabled="true"
      </h5>
      <div v-if="show.radioBtn">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-50">default</div>
            <div class="w-50">Error feedback</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-50">
              <NbRadio
                id="radio-example"
                v-model="radioSelected"
                name="Label do radioBtn"
                :options="radioOptions"
              />
            </div>
            <div class="w-50">
              <NbRadio
                id="radio-example-2"
                v-model="radioSelected2"
                name="Label do radioBtn"
                :options="radioOptions"
                :error="errors.textForInput2"
                help-text="Testo de auto ajuda"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.inputs = !show.inputs">
        <span class="text-primary">Text input</span> props: id="unique"
        v-model="any" name="Um texto referente ao input" :required="true"
        :disabled="true" disabled
      </h5>
      <div v-if="show.inputs">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-25 mr-4">default</div>
            <div class="w-25 mr-4">Error feedback</div>
            <div class="w-25 mr-4">Help text</div>
            <div class="w-25 mr-4">Disabled</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-25 mr-4">
              <NbTextInput
                id="text-input-example"
                v-model="textForInput"
                name="Label do textInput"
                placeholder="placeholder"
                :error="errors.textForInput1"
              />
            </div>
            <div class="w-25 mr-4">
              <NbTextInput
                id="text-input-example-2"
                v-model="textForInput2"
                name="Label do textInput"
                placeholder="placeholder"
                :required="true"
                :error="errors.textForInput2"
              />
            </div>
            <div class="w-25 mr-4">
              <NbTextInput
                id="text-input-example-3"
                v-model="textForInput2"
                name="Label do textInput"
                placeholder="placeholder"
                :error="errors.textForInput1"
                help-text="Esse input e um exemplo"
              />
            </div>
            <div class="w-25 mr-4">
              <NbTextInput
                id="text-input-example-4"
                v-model="textForInput2"
                name="Label do textInput"
                placeholder="placeholder"
                :error="errors.textForInput1"
                help-text="Esse input 'e disabled'"
                :disabled="true"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.bInputs = !show.bInputs">
        <span class="text-primary">Berder Text input</span> props: id="unique"
        v-model="any" name="Um texto referente ao input" :required="true"
        :disabled="true"
      </h5>
      <div v-if="show.bInputs">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-25 mr-4">default</div>
            <div class="w-25 mr-4">Error feedback</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-25 mr-4">
              <NbBorderTextInput
                id="border-text-input-example"
                v-model="textForInput"
                name="Label do BordertextInput"
                placeholder="placeholder"
                :error="errors.textForInput1"
              />
            </div>
            <div class="w-25 mr-4">
              <NbBorderTextInput
                id="border-text-input-example-2"
                v-model="textForInput2"
                name="Label do BordertextInput"
                placeholder="placeholder"
                :required="true"
                :error="errors.textForInput2"
              />
            </div>
            <div class="w-25 mr-4">
              <NbBorderTextInput
                id="border-text-input-example-3"
                v-model="textForInput2"
                name="Label do BordertextInput"
                placeholder="placeholder"
                :error="errors.textForInput1"
                help-text="Input com bordas"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5
        class="heading-2 pointer"
        @click="show.searchInputs = !show.searchInputs"
      >
        <span class="text-primary">search input</span> props: id="unique"
        v-model="any" name="Um texto referente ao input" :required="true"
        :disabled="true"
      </h5>
      <div v-if="show.searchInputs">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-25 mr-4">default</div>
            <div class="w-25 mr-4">Error feedback</div>
            <div class="w-25 mr-4">size="sm"</div>
            <div class="w-25 mr-4">size="sm" Error feedback</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-25 mr-4">
              <NbSearchInput
                id="search-input-example"
                v-model="textForInput"
                name="Label do Search"
                placeholder="placeholder"
                :error="errors.textForInput1"
              />
            </div>
            <div class="w-25 mr-4">
              <NbSearchInput
                id="search-input-example-2"
                v-model="textForInput2"
                name="Label do Search"
                placeholder="placeholder"
                :required="true"
                :error="errors.textForInput2"
              />
            </div>
            <div class="w-25 mr-4">
              <NbSearchInput
                id="search-input-example-3"
                v-model="textForInput2"
                name="Label do Search"
                placeholder="placeholder"
                size="sm"
                help-text="um texto muxo loko"
                help-text-size="md"
              />
            </div>
            <div class="w-25 mr-4">
              <NbSearchInput
                id="search-input-example-4"
                v-model="textForInput2"
                name="Label do Search"
                placeholder="placeholder"
                size="sm"
                :required="true"
                :error="errors.textForInput2"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.passInputs = !show.passInputs">
        <span class="text-primary">password input</span> props: id="unique"
        v-model="any" name="Um texto referente ao input" :required="true"
        :disabled="true"
      </h5>
      <div v-if="show.passInputs">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-25 mr-4">default</div>
            <div class="w-25 mr-4">Error feedback</div>
            <div class="w-25 mr-4">helpText</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-25 mr-4">
              <NbPassInput
                id="pass-input-example"
                v-model="textForInput"
                name="Label do Search"
                :error="errors.textForInput1"
              />
            </div>
            <div class="w-25 mr-4">
              <NbPassInput
                id="pass-input-example-2"
                v-model="textForInput2"
                name="Label do Search"
                placeholder="placeholder"
                :required="true"
                :error="errors.textForInput2"
              />
            </div>
            <div class="w-25 mr-4">
              <NbPassInput
                id="pass-input-example-3"
                v-model="textForInput2"
                name="Label do Search"
                placeholder="placeholder"
                :required="true"
                :error="errors.textForInput1"
                help-text="Ponha o password da sua conta do banco!"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5
        class="heading-2 pointer"
        @click="show.selectInputs = !show.selectInputs"
      >
        <span class="text-primary">select input</span> props: id="unique"
        v-model="any" name="Um texto referente ao input" :required="true"
        :disabled="true"
      </h5>
      <div v-if="show.selectInputs">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-25 mr-4">default</div>
            <div class="w-25 mr-4">Error feedback</div>
            <div class="w-25 mr-4">size</div>
            <div class="w-25 mr-4">helpText</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-25 mr-4">
              <NbSelectInput
                id="select-input-example-1"
                v-model="selectInputVal"
                name="label do select"
                :options="buttonOptions"
                placeholder="An col placeholder"
              />
            </div>
            <div class="w-25 mr-4">
              <NbSelectInput
                id="select-input-example-2"
                v-model="selectInputVal"
                name="label do select"
                required
                :error="errors.textForInput2"
                :options="buttonOptions"
              />
            </div>
            <div class="w-25 mr-4">
              <NbSelectInput
                id="select-input-example-4"
                v-model="selectInputVal"
                size="sm"
                name="label do select"
                required
                :error="errors.textForInput1"
                :options="buttonOptions"
              />
            </div>
            <div class="w-25 mr-4">
              <NbSelectInput
                id="select-input-example-3"
                v-model="selectInputVal"
                :disabled="true"
                size="sm"
                name="label do select"
                required
                :error="errors.textForInput2"
                :options="buttonOptions"
                help-text="Selecione algo importante!"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.popover = !show.popover">
        <span class="text-primary">Help popover</span> props: id="unique"
        size="sm/lg"
        placement="topright/top/topleft/bottomright/bottom/bottomleft/righttop/right/lefttop/rightbottom/left/leftbottom"
      </h5>
      <div v-if="show.popover">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-25 mr-4">size="sm"</div>
            <div class="w-25 mr-4">default</div>
            <div class="w-25 mr-4">size="lg"</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-25 mr-4">
              <NbHelpText id="popover-example-1" size="sm" placement="topright"
                >Informação objetiva explicando melhor o paranaue.</NbHelpText
              >
            </div>
            <div class="w-25 mr-4">
              <NbHelpText id="popover-example-2"
                >Informação nao tao objetiva explicando melhor os paranaue
                detalhadamente para exemplificar como fica em um texto um pouco
                mais longo mas nem tao longo assim porque nao temos tanta
                criatividade nao e mesmo?</NbHelpText
              >
            </div>
            <div class="w-25 mr-4">
              <NbHelpText id="popover-example-3" size="lg" placement="topleft"
                >Informação nao tao objetiva explicando melhor os paranaue
                detalhadamente para exemplificar como fica em um texto um pouco
                mais longo mas nem tao longo assim porque nao temos tanta
                criatividade nao e mesmo?</NbHelpText
              >
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.toast = !show.toast">
        <span class="text-primary">Toast</span> variant: success/warning/danger
      </h5>
      <div v-if="show.toast">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-25">Success</div>
            <div class="w-25">Warning</div>
            <div class="w-25">Danger</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-25">
              <NbButton @click="showToast('success')">Success</NbButton>
            </div>
            <div class="w-25">
              <NbButton @click="showToast('warning')">Warning</NbButton>
            </div>
            <div class="w-25">
              <NbButton @click="showToast('danger')">Danger</NbButton>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.alert = !show.alert">
        <span class="text-primary">Alert</span> type="success/warning/danger"
        icon="far fa-check-circle"
      </h5>
      <div v-if="show.alert">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-50 mx-1">Success</div>
            <div class="w-50 mx-1">Warning :link="obj with src and text"</div>
            <div class="w-50 mx-1">Danger</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-50 mx-1">
              <Alert
                type="success"
                icon="far fa-check-circle"
                :message="'Mensagem para alert de success'"
              />
            </div>
            <div class="w-50 mx-1">
              <Alert
                type="warning"
                icon="fas fa-exclamation-circle"
                :message="'Mensagem para alert de warning'"
                :link="{ src: '#', text: 'link text' }"
              />
            </div>
            <div class="w-50 mx-1">
              <Alert
                type="danger"
                icon="far fa-times-circle"
                :message="'Mensagem para alert de danger'"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.alertCard = !show.alertCard">
        <span class="text-primary">Alert Card</span>
        type="success/warning/danger" icon="far fa-check-circle"
      </h5>
      <div v-if="show.alertCard">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-50 mx-1">
              height="102" size="lg" verticalAling="center"
            </div>
            <div class="w-50 mx-1">
              height="102" size="lg" verticalAling="center"
            </div>
            <div class="w-50 mx-1">
              height="102" size="lg" verticalAling="center"
            </div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-50 mx-1">
              <Alert
                height="102"
                size="lg"
                vertical-aling="center"
                type="success"
                icon="far fa-check-circle"
                :message="'Mensagem para alert de success'"
              />
            </div>
            <div class="w-50 mx-1">
              <Alert
                height="102"
                size="lg"
                vertical-aling="center"
                type="warning"
                icon="fas fa-exclamation-circle"
                :message="'Mensagem para alert de warning'"
                :link="{ src: '#', text: 'link text' }"
              />
            </div>
            <div class="w-50 mx-1">
              <Alert
                height="102"
                size="lg"
                vertical-aling="center"
                type="danger"
                icon="far fa-times-circle"
                :message="'Mensagem para alert de danger'"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.badge = !show.badge">
        <span class="text-primary">badge</span> type="success/warning/danger"
        text="string"
      </h5>
      <div v-if="show.badge">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-50 mx-1">Success</div>
            <div class="w-50 mx-1">Warning</div>
            <div class="w-50 mx-1">Danger</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-50 mx-1">
              <span>
                <NbBadge type="success" :text="'Finalizado'" />
              </span>
            </div>
            <div class="w-50 mx-1">
              <NbBadge type="warning" :text="'Aguardando'" />
            </div>
            <div class="w-50 mx-1">
              <NbBadge type="danger" :text="'Erro'" />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.status = !show.status">
        <span class="text-primary">status</span> type="success/warning/danger"
      </h5>
      <div v-if="show.status">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-25 mx-1">Default</div>
            <div class="w-25 mx-1">Success</div>
            <div class="w-25 mx-1">Warning</div>
            <div class="w-25 mx-1">Danger</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-25 mx-1">
              <NbStatus type="" :text="'Não despachado'" />
            </div>
            <div class="w-25 mx-1">
              <NbStatus type="success" :text="'Em trânsito'" />
            </div>
            <div class="w-25 mx-1">
              <NbStatus type="warning" :text="'Atrasado (2 dias)'" />
            </div>
            <div class="w-25 mx-1">
              <NbStatus type="danger" :text="'Extraviado'" />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <!-- <div>
        <h5 class="heading-2 pointer" @click="show.banner = !show.banner">
          <span class="text-primary">Clicklable Banner</span> :progress="number"
          title="string" text="string" command="string"
        </h5>
        <div v-if="show.banner">
          <div class="d-flex flex-column bd-highlight mt-3">
            <div class="d-flex justify-content-start">
              <div class="w-100 mx-1">Banner with progress</div>
            </div>
            <div class="d-flex justify-content-start">
              <NbBannerLink
                :progress="progress"
                title="Complete sua experiencia!"
                text="Clique para configurar a nobordist do seu jeito."
                command="Continuar"
                @click="progress = Math.floor(Math.random() * 101)"
              />
            </div>
          </div>
          <div class="d-flex flex-column bd-highlight mb-3">
            <div class="d-flex justify-content-start">
              <div class="w-100 mx-1">Banner without progress</div>
            </div>
            <div class="d-flex justify-content-start">
              <NbBannerLink
                title="Um titulo para o banner!"
                text="Subtitulo do banner."
                command="Va para la"
                @click="progress = Math.floor(Math.random() * 101)"
              />
            </div>
          </div>
        </div>
        <hr class="my-1" />
      </div> -->

    <!-- <div>
      <h5
        class="heading-2 pointer"
        @click="show.ratingStarts = !show.ratingStarts"
      >
        <span class="text-primary">RatingStarts</span> stars="number"(default 5)
        ratable="boolean"(default false) currentRating="number" size="1.5rem"
      </h5>
      <div v-if="show.ratingStarts">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-50 mx-1">Clicklable</div>
            <div class="w-50 mx-1">
              Disabled (10 stars) (currentRating 6) (size 1.5rem)
            </div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-50 mx-1">
              <RatingStars :ratable="true" @changeRated="changeRated($event)" />
            </div>
            <div class="w-50 mx-1">
              <RatingStars :stars="10" :current-rating="6" size="1.5rem" />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div> -->

    <div>
      <h5 class="heading-2 pointer" @click="show.cardItems = !show.cardItems">
        <span class="text-primary">CardItems</span> id="string"
        :cardConfig="object" :image="'string'" title="string"
        :ratedStarts="number" secondTitle="string" secondText="string"
        secondHelpText="string" thirdTitle="string" thirdHelpText="string"
        v-model="useit"
      </h5>
      <div v-if="show.cardItems">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-100 mx-1">Clicklable</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-100 mx-1">
              <NbCardItems
                id="card-item1"
                v-model="cardItemsValue"
                :card-config="cardConfigOne"
                :image="
                  require('@/assets/img/integrations/shipstation-logo.png')
                "
                title="Ceva Miami Correios Standart"
                :rated-starts="3"
                second-title="Prazo para entrega"
                second-text="5 dias"
                second-help-text="Prazo limite para entrega do produto ate o cliente."
                third-title="Preço do frete"
                third-text="R$5,85"
                third-help-text="Preço do frete cobrado pela transportadora sem impostos."
              />
            </div>
          </div>
        </div>
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-100 mx-1">Clicklable</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-100 mx-1">
              <NbCardItems
                id="card-item2"
                v-model="cardItemsValue"
                :card-config="cardConfigTwo"
                :image="require('@/assets/img/integrations/amazon-logo.png')"
                title="Nobordists Standart"
                :rated-starts="5"
                second-title="Prazo para entrega"
                second-text="1 dia"
                second-help-text="Prazo limite para entrega do produto ate o cliente."
                third-title="Preço do frete"
                third-text="R$10,85"
                third-help-text="Preço do frete cobrado pela transportadora sem impostos."
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5
        class="heading-2 pointer"
        @click="show.cardLinkBrand = !show.cardLinkBrand"
      >
        <span class="text-primary">CardLinkBrand</span> :isConnected="boolean"
        image="string" label="string"
      </h5>
      <div v-if="show.cardLinkBrand">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-50 mx-1">Clicklable</div>
            <div class="w-50 mx-1">Modal Open</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-50 mx-1">
              <NbCardLinkBrand
                :is-connected="mabangConnect"
                :image="require('@/assets/img/integrations/mabang-logo.png')"
                label="MABANG ERP"
                @click="execMabangConnect"
              />
            </div>
            <div class="w-50 mx-1">
              <NbCardLinkBrand
                :is-connected="true"
                modal-target="#modalMabang"
                :image="require('@/assets/img/integrations/mabang-logo.png')"
                label="MABANG ERP"
              >
                <div
                  id="modalMabang"
                  class="modal fade"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="modalAmazon"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div class="modal-content">
                      <div
                        class="modal-body"
                        style="
                          text-align: center;
                          padding-top: 20px;
                          padding-right: 20px;
                          padding-left: 20px;
                          padding-bottom: 20px;
                        "
                      >
                        Example with modal
                      </div>
                    </div>
                  </div>
                </div>
              </NbCardLinkBrand>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5
        class="heading-2 pointer"
        @click="show.cardWithBtm = !show.cardWithBtm"
      >
        <span class="text-primary">cardWithBtm</span> id="card-btn1"
        title="string" text="string" btnLabel="string" helpText="string"
      </h5>
      <div v-if="show.cardWithBtm">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-50 mx-1"></div>
            <div class="w-50 mx-1"></div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-50 mx-1">
              <NbCardWithBtn
                id="card-btn1"
                title="Ver meus pedidos"
                text="Ver todos os pedidos criados e gerenciar o status de cada um."
                btn-label="Ir para 'Meus pedidos' >"
                help-text="Informação objetiva explicando melhor o paranaue."
                @click="functionCall"
              />
            </div>
            <div class="w-50 mx-1">
              <NbCardWithBtn
                id="card-btn2"
                title="Um exemplo"
                text="Ver todos os pedidos criados e gerenciar o status de cada um."
                btn-label="Ir para 'Meus pedidos' >"
                help-text="Informação objetiva explicando melhor o paranaue."
                @click="functionCall"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5
        class="heading-2 pointer"
        @click="show.cardCreateOverpack = !show.cardCreateOverpack"
      >
        <span class="text-danger">CardCreateOverpack (W.I.P)</span>
        id="card-btn1" title="string" text="string" btnLabel="string"
        helpText="string"
      </h5>
      <div v-if="show.cardCreateOverpack">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-100 mx-1"></div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-100 mx-1">
              <NbCardCreateOverpack
                id="card-overpack-create"
                title="Novo Overpack"
                link-text="ou selecione um novo overpack existente."
                btn-label="Criar overpack"
                help-text="Informação objetiva explicando melhor o paranaue."
                @click="functionCall"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.switch = !show.switch">
        <span class="text-primary">Switch</span> :required="true"
        :disabled="boolean" helpText="string" image="string" error="array"
      </h5>
      <div v-if="show.switch">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-25 mx-1">Default (value: {{ switchValue }})</div>
            <div class="w-25 mx-1">Witch label</div>
            <div class="w-25 mx-1">Disabled</div>
            <div class="w-25 mx-1">Error + helpText</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-25 mx-1">
              <NbSwitch id="switch-example" v-model="switchValue" />
            </div>
            <div class="w-25 mx-1">
              <NbSwitch
                id="switch-example-2"
                v-model="switchValue"
                name="Switch Label"
                :required="true"
              />
            </div>
            <div class="w-25 mx-1">
              <NbSwitch
                id="switch-example-3"
                v-model="switchValue"
                name="Switch Label"
                :required="true"
                :disabled="true"
              />
            </div>
            <div class="w-25 mx-1">
              <NbSwitch
                id="switch-example-4"
                v-model="switchValue"
                name="Switch Label"
                :required="true"
                help-text="Testo de auto ajuda"
                :error="errors.textForInput2"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5
        class="heading-2 pointer"
        @click="show.progressBar = !show.progressBar"
      >
        <span class="text-primary">ProgressBar</span> :required="true"
        :disabled="boolean" helpText="string" image="string" error="array"
      </h5>
      <div v-if="show.progressBar">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start mb-1">
            <div class="w-25 mx-1">
              Progress value: {{ progressValue }}
              <NbButton
                size="sm"
                @click="progressValue = Math.floor(Math.random() * 101)"
                >Randomize</NbButton
              >
            </div>
          </div>
          <div class="">
            <div class="w-100 m-1">
              <div>Default</div>
              <NbProgressBar
                id="progressBar-example-1"
                :progress-val="progressValue"
              />
            </div>
            <div class="w-100 m-1">
              <div>:addInfoColor="'primary'"</div>
              <NbProgressBar
                id="progressBar-example-2"
                :progress-val="progressValue"
                :add-info-color="'primary'"
              />
            </div>
            <div class="w-100 m-1">
              <div>:addInfoColor="'secondary'"</div>
              <NbProgressBar
                id="progressBar-example-3"
                :progress-val="progressValue"
                :add-info-color="'secondary'"
              />
            </div>
            <div class="w-100 m-1">
              <div>:addInfoColor="'warning'"</div>
              <NbProgressBar
                id="progressBar-example-4"
                :progress-val="progressValue"
                :add-info-color="'warning'"
              />
            </div>
            <div class="w-100 m-1">
              <div>:addInfoColor="'danger'"</div>
              <NbProgressBar
                id="progressBar-example-5"
                :progress-val="progressValue"
                :add-info-color="'danger'"
              />
            </div>
            <div class="w-100 m-1">
              <div>:addInfoColor="'success'" :height="6"</div>
              <NbProgressBar
                id="progressBar-example-6"
                :progress-val="progressValue"
                :add-info-color="'success'"
                :height="6"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <!-- tables and tab -->
    <div>
      <h5 class="heading-2 pointer" @click="show.tab = !show.tab">
        <span class="text-primary">tabs</span> :required="true"
        :disabled="boolean" helpText="string" image="string" error="array"
      </h5>
      <div v-if="show.tab">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-100 mx-1">
              Default
              <!-- <router-link :to="`/components/tables`"
                ><NbButton variant="tertiary" size="sm" icon="fas fa-plus"
                  >More examples</NbButton
                >
              </router-link> -->
            </div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-100 mx-1">
              <NbTabs :tabs="tabsOptions" @switchTab="switchTab($event)" />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.table = !show.table">
        <span class="text-primary">table</span> :required="true"
        :disabled="boolean" helpText="string" image="string" error="array"
      </h5>
      <div v-if="show.table">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-100 mx-1">
              Default
              <!-- <router-link :to="`/components/tables`"
                ><NbButton variant="tertiary" size="sm" icon="fas fa-plus"
                  >More examples</NbButton
                >
              </router-link
              > -->
            </div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-100 mx-1">
              <!-- <NbTablev2
                  table-of="tableItemsListPage"
                  namespace="orders"
                  :filter-options="filterOptions"
                  :all-fields="allFields"
                  :fields="fields"
                  :selectable="true"
                  @reloadFields="fields = $event"
                  @selectedItems="selectedItems"
                  @total="totalOrders = $event"
                >
                  <template #cell(order_number)="data">
                    <router-link
                      :to="`/orders/${data.item.id}`"
                      class="text-link"
                    >
                      {{ data.item.order_number }}
                    </router-link>
                  </template>
                  <template #vista(reference)="data">
                    <NbStatus
                      v-if="data.item.current_status_id === 1"
                      type="danger"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 20"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 3"
                      type="success"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 2"
                      type="warning"
                      :text="data.item.current_status.name"
                    />
                  </template>
                </NbTablev2> -->
              <!-- 
              class="pb-1"
              tableOf="ordersListPage"
              :total="totalRows"
              :itemsIds="ordersIds"
              :items="orders"
              :fields="fields"
              :selectable="true"
              @selectedItems="selectedItems"
              -->
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>
  </div>
</template>

<script>
//import langs
import en from "@/locales/en.js";
import pt_BR from "@/locales/pt_BR.js";
import zh_CN from "@/locales/zh_CN.js";

import NbButton from "@/components/buttons/NbButton.vue";
import NbOption from "@/components/buttons/NbOption.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbBorderTextInput from "@/components/input/text/NbBorderTextInput.vue";
import NbSearchInput from "@/components/input/text/NbSearchInput.vue";
import NbPassInput from "@/components/input/text/NbPassInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import Alert from "@/components/alerts/Alert.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbStatus from "@/components/alerts/NbStatus.vue";
//import NbBannerLink from "@/components/banners/NbBannerLink.vue";
//import RatingStars from "@/components/ratingstars/RatingStars.vue";
import NbCardItems from "@/components/cards/NbCardItems.vue";
import NbCardLinkBrand from "@/components/cards/NbCardLinkBrand.vue";
import NbCardWithBtn from "@/components/cards/NbCardWithBtn.vue";
import NbCardCreateOverpack from "@/components/cards/NbCardCreateOverpack.vue";
import NbSwitch from "@/components/buttons/NbSwitch.vue";
import NbProgressBar from "@/components/progressBar/NbProgressBar.vue";
//import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbTabs from "@/components/tables/NbTabs.vue";

export default {
  components: {
    NbButton,
    NbOption,
    NbCheckbox,
    NbRadio,
    NbTextInput,
    NbBorderTextInput,
    NbSearchInput,
    NbPassInput,
    NbHelpText,
    Alert,
    NbBadge,
    NbStatus,
    //NbBannerLink,
    //RatingStars,
    NbCardItems,
    NbCardLinkBrand,
    NbCardWithBtn,
    NbCardCreateOverpack,
    NbSwitch,
    NbProgressBar,
    //NbTablev2,
    NbTabs,
    NbSelectInput,
  },
  data() {
    return {
      show: {
        buttons: true,
        options: true,
        checkbox: true,
        radioBtn: true,
        inputs: true,
        bInputs: true,
        searchInputs: true,
        passInputs: true,
        selectInputs: true,
        popover: true,
        toast: true,
        alert: true,
        alertCard: true,
        badge: true,
        status: true,
        banner: true,
        ratingStarts: true,
        cardItems: true,
        cardLinkBrand: true,
        cardWithBtm: true,
        cardCreateOverpack: true,
        switch: true,
        progressBar: true,
        tab: true,
        table: true,
      },
      progress: 10,
      buttonOptions: [
        { text: "option 1", value: "action1" },
        { text: "option 2", value: "action2", disabled: true },
        { text: "option 3", value: "action3" },
        { text: "option 4", value: "action4" },
      ],
      buttonOptionChosen: "",
      actions: {
        action1: () => {
          return "action1 example of a function call";
        },
        action2: () => {
          return "action2 example of a function call";
        },
        action3: () => {
          return "action3 example of a function call";
        },
      },
      check: false,
      radioSelected: "radioVal2",
      radioSelected2: "",
      radioOptions: [
        { text: "Radio option 1", value: "radioVal1" },
        { text: "Radio option 2", value: "radioVal2" },
        { text: "Radio option 3", value: "radioVal3" },
      ],
      textForInput: "Texto inicial",
      textForInput2: "",
      selectInputVal: null,
      errors: {
        textForInput2: ["mensagem de erro!", "mensagem dois"],
      },
      cardItemsValue: "",
      cardConfigOne: [{ text: "", value: "Value1" }],
      cardConfigTwo: [{ text: "", value: "Value2" }],
      mabangConnect: false,
      switchValue: true,
      progressValue: 30,
      selectedTableItems: [],
      fields: [],
      currentTable: "Table Label 1",
      totalOrders: 0,
      showLangsTests: false,
      testResult: {},
    };
  },
  computed: {
    allFields() {
      return [
        {
          key: "order_number",
          label: this.$t("ordersListPage.orderNo"),
          sortable: true,
        },
        {
          key: "sales_channel_order_number",
          label: this.$t("ordersListPage.salesChOrderNo"),
          sortable: false,
        },
        {
          key: "total_value",
          label: this.$t("ordersListPage.value"),
          sortable: true,
        },
        { key: "incoterm", label: this.$t("ordersListPage.incoterm") },
        { key: "freight_cost", label: this.$t("ordersListPage.freightCost") },
        {
          key: "customer_postal_code",
          label: this.$t("ordersListPage.postalCode"),
        },
        {
          key: "sales_channel_name",
          label: this.$t("ordersListPage.salesChannel"),
        },
        {
          key: "customer_full_name",
          label: this.$t("ordersListPage.customerName"),
        },
        { key: "customer_country", label: this.$t("ordersListPage.country") },
        { key: "customer_city", label: this.$t("ordersListPage.city") },
        { key: "contract_name", label: this.$t("ordersListPage.contractName") },
        { key: "created_at", label: this.$t("ordersListPage.creationDate") },
        {
          key: "estimated_delivery_date",
          label: this.$t("ordersListPage.estimatedDeliveryDate"),
        },
        { key: "label", label: this.$t("ordersListPage.label") },
        { key: "commercial_invoice", label: this.$t("ordersListPage.invoice") },
        {
          key: "contain_battery",
          label: this.$t("ordersListPage.shipperConfirmationPDF"),
        },
        {
          key: "dangerous_good",
          label: this.$t("ordersListPage.dangerousGoodPDF"),
        },
        /* { key: "current_status", label: this.$t('ordersListPage.status') }, */
        { key: "reference", label: this.$t("ordersListPage.trackingNumber") },
        {
          key: "insurance_coverage",
          label: this.$t("ordersListPage.insuranceCoverage"),
        },
        {
          key: "last_mile_tracking_number",
          label: this.$t("ordersListPage.lastMileTrackingNumber"),
        },
        {
          key: "last_mile_carrier_number",
          label: this.$t("ordersListPage.lastMileCarrierNumber"),
        },
        {
          key: "first_mile_carrier_name",
          label: this.$t("ordersListPage.firstMileCarrier"),
        },
        {
          key: "first_mile_tracking_number",
          label: this.$t("ordersListPage.firstMileTrackingNumber"),
        },
      ];
    },
    filterOptions() {
      let filtersToReturn = [
        { key: "order_number", label: this.$t("orderNumber") },
        { key: "customer_full_name", label: this.$t("customerName") },
        { key: "customer_country", label: this.$t("customerCountry") },
        { key: "sales_channel", label: this.$t("salesChannel") },
        {
          key: "sales_channel_order_number",
          label: this.$t("salesChannelOrderNumber"),
        },
        { key: "reference", label: this.$t("trackingNumber") },
        {
          key: "last_mile_tracking_number",
          label: this.$t("lastMileTrackingNumber"),
        },
        {
          key: "current_status",
          label: this.$t("status"),
          type: "select",
          options: this.allStatus,
        },
        {
          key: "contain_battery",
          label: this.$t("shipmentConfirmation"),
          type: "boolean",
        },
        {
          key: "dangerous_good",
          label: this.$t("printingsPage.dangerousGood"),
          type: "boolean",
        },
        {
          key: "start_registration_date",
          label: this.$t("startRegistrationDate"),
          type: "date",
        },
        {
          key: "end_registration_date",
          label: this.$t("endRegistrationDate"),
          type: "date",
        },
      ];

      return filtersToReturn;
    },
    tabsOptions() {
      return [
        {
          label: "Table Label 1",
          found: this.totalOrders || 0,
          current: this.currentTable,
        },
        {
          label: "Table Label 2",
          found: this.totalOrders - 500 || 0,
          current: this.currentTable,
        },
        {
          label: "Table Label 3",
          found: this.totalOrders - 122 || 0,
          current: this.currentTable,
        },
      ];
    },
    allStatus() {
      return [
        { value: null, text: this.$t("orderStatus.selectOption") },
        { value: 100, text: this.$t("orderStatus.new") },
        { value: 300, text: this.$t("orderStatus.readyToShip") },
        { value: 400, text: this.$t("orderStatus.pickUp") },
        { value: 500, text: this.$t("orderStatus.shipped") },
        { value: 600, text: this.$t("orderStatus.inTransit") },
        { value: 800, text: this.$t("orderStatus.failedAttempt") },
        { value: 700, text: this.$t("orderStatus.outForDelivery") },
        { value: 900, text: this.$t("orderStatus.delivered") },
        { value: 1000, text: this.$t("orderStatus.deliveryFailed") },
        { value: 1300, text: this.$t("orderStatus.partialDelivery") },
        { value: 1400, text: this.$t("orderStatus.partialDeliveryFailed") },
      ];
    },
  },
  mounted() {
    /*
    let botCss = document.createElement('link')
    botCss.setAttribute('rel', 'stylesheet')
    botCss.setAttribute('type', 'text/css')
    botCss.setAttribute('href', 'https://www11.directtalk.com.br/clientes/custom/TotalExpress/area_logada/style.css')
    document.head.appendChild(botCss)

    let botScript1 = document.createElement('script')
    botScript1.setAttribute('id', 'dtbot-script')
    botScript1.setAttribute('src', "https://dtbot.directtalk.com.br/1.0/staticbot/dist/js/dtbot.js?token=58628a34-c65b-4e80-8eeb-f2ee32d085bc&amp;widget=true&amp;top=40&amp;text=Alguma%20d%C3%BAvida%3F&amp;textcolor=ffffff&amp;bgcolor=4E1D3A&amp;from=bottomRight")
    document.head.appendChild(botScript1)
    */
    /* 2 esta funfando
    let botScript2 = document.createElement('script')
    botScript2.setAttribute('id', 'dt-widget')
    botScript2.setAttribute('src', "https://www11.directtalk.com.br/clientes/custom/TotalExpress/area_logada_ingles/widget.min.js?nome_usuario=&cpf=&origem=Area-logada")
    document.head.appendChild(botScript2)

    let botScript3 = document.createElement('script')
    botScript3.setAttribute('id', 'dt-widget')
    botScript3.setAttribute('src', "https://www11.directtalk.com.br/clientes/custom/TotalExpress/area_logada_ingles/widget.min.js?nome_usuario=&cpf=&reid=&consultor=&origem=Area-logada")
    document.head.appendChild(botScript3)  */
    //simulating an requisition
  },
  methods: {
    selectedItems(event) {
      this.selectedTableItems = event;
    },
    optionClicked(event) {
      //event is value of buttonOption clicked it's just a example
      this.actions[event]();
    },
    showToast(variant) {
      const message = `An example for ${variant} toast!`;
      const time = 2000;
      this.$helpers.toast(message, variant, time);
    },
    changeRated(num) {
      return `do something with evaluation ${num}`;
    },
    execMabangConnect() {
      this.mabangConnect = !this.mabangConnect;
    },
    functionCall() {
      return "it's just a example";
    },
    switchTab(tab) {
      this.currentTable = tab;
    },
    testLangs() {
      this.showLangsTests = true;
      this.testResult.en = [];
      this.testResult.pt_BR = [];
      this.testResult.zh_CN = [];
      let enArr = [];
      let pt_BRArr = [];
      let zh_CNArr = [];

      pt_BRArr = pt_BRArr.concat(this.checkSameKeys(en, pt_BR));
      pt_BRArr = pt_BRArr.concat(this.checkSameKeys(zh_CN, pt_BR));

      zh_CNArr = zh_CNArr.concat(this.checkSameKeys(en, zh_CN));
      zh_CNArr = zh_CNArr.concat(this.checkSameKeys(pt_BR, zh_CN));

      enArr = enArr.concat(this.checkSameKeys(pt_BR, en));
      enArr = enArr.concat(this.checkSameKeys(zh_CN, en));

      this.testResult.en = [...new Set(enArr)];
      this.testResult.pt_BR = [...new Set(pt_BRArr)];
      this.testResult.zh_CN = [...new Set(zh_CNArr)];
    },
    checkSameKeys(obj1, obj2, currentKey = "", differentKeys = []) {
      //get all keys
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      for (let key of keys1) {
        //checa se a key no obj1 existe no obj2
        if (!keys2.includes(key)) {
          //se currentKey vier de outra interaçao use ela caso contrario use a key atual
          differentKeys.push(currentKey ? `${currentKey}.${key}` : key);
        } else {
          const val1 = obj1[key];
          const val2 = obj2[key];
          //checa se o valor da respectiva key continua sendo um objeto para entrar dentro dele
          if (typeof val1 === "object" && typeof val2 === "object") {
            //se currentKey vier de outra interaçau use ela caso contrario use a key atual e joga para a interaçao seguinte
            const nestedKey = currentKey ? `${currentKey}.${key}` : key;
            this.checkSameKeys(val1, val2, nestedKey, differentKeys);
          }
        }
      }

      return differentKeys;
    },
  },
};
</script>

<style>
.pointer {
  transition: all 0.3s ease;
  cursor: pointer;
}
.not-allowed {
  cursor: not-allowed;
}
.heading-2.pointer {
  transition: all 0.3s ease;
  padding: 0.3rem;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-bottom: 0px !important;
}
.heading-2.pointer:hover {
  transition: all 0.3s ease;
  border: 1px solid var(--primary);
}
</style>
