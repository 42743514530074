<template>
  <div class="home">
    <NbPageTitle
      :title="$t('homePage.greeting', { name: user.name })"
      :subTitle="$t('homePage.subtitle')"
    />

    <section class="page-container my-5">
      <div class="links">
        <div v-for="link in quickLinks" :key="link.title" class="link">
          <div class="icon">
            <NbIcon :icon="link.icon" :attributes="{ height: 18, width: 18 }" />
          </div>
          <div>
            <h4 class="heading-4">{{ link.title }}</h4>
            <p class="body-3">{{ link.description }}</p>
            <NbButton variant="secondary" @click="navigateTo(link.path)">
              {{ link.action }} >
            </NbButton>
          </div>
        </div>
      </div>
    </section>

    <Metrics />
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import Metrics from "./components/Metrics.vue";
import { mapState } from "vuex";

export default {
  name: "Home",
  components: {
    NbPageTitle,
    NbIcon,
    NbButton,
    Metrics,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    quickLinks() {
      return [
        {
          title: this.$t("homePage.links.orders.title"),
          description: this.$t("homePage.links.orders.description"),
          action: this.$t("homePage.links.orders.action"),
          icon: "shopping-bag",
          path: "/shipping/orders",
        },
        {
          title: this.$t("homePage.links.flights.title"),
          description: this.$t("homePage.links.flights.description"),
          action: this.$t("homePage.links.flights.action"),
          icon: "navigation",
          path: "/shipping/flights",
        },
        {
          title: this.$t("homePage.links.masterboxes.title"),
          description: this.$t("homePage.links.masterboxes.description"),
          action: this.$t("homePage.links.masterboxes.action"),
          icon: "package",
          path: "/shipping/masterboxes",
        },
        {
          title: this.$t("homePage.links.contracts.title"),
          description: this.$t("homePage.links.contracts.description"),
          action: this.$t("homePage.links.contracts.action"),
          icon: "file-text",
          path: "/contracts/contracts",
        },
        {
          title: this.$t("homePage.links.sellers.title"),
          description: this.$t("homePage.links.sellers.description"),
          action: this.$t("homePage.links.sellers.action"),
          icon: "users",
          path: "/users_management/sellers",
        },
        {
          title: this.$t("homePage.links.checkpoints.title"),
          description: this.$t("homePage.links.checkpoints.description"),
          action: this.$t("homePage.links.checkpoints.action"),
          icon: "target",
          path: "/checkpoints/checkpoints",
        },
      ];
    },
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.links {
  display: grid;
  gap: 1rem;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  .link {
    padding: 1.5rem 1rem;
    border-radius: 0 8px 8px 0;
    border: 1px solid var(--gray-05);
    background-color: var(--white);
    box-shadow: 4px 4px 8px var(--gray-05);
    border-left: 4px solid var(--primary);
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
    .icon {
      background-color: var(--gray-05);
      padding: 0.5rem;
      border-radius: 4px;
      color: var(--primary);
    }
  }
}
</style>
