<template>
  <div>
    <NbPageTitle
      class="pb-3"
      :title="$t('requestsPage.title')"
      :subTitle="$t('requestsPage.subTitle')"
    >
    </NbPageTitle>
    <section class="page-container d-flex">
      <NbCard
        id="request-card"
        :title="$t('requestsPage.asynchronousRequests')"
        class="bg-gray-05"
        width="34rem"
      >
        <template #body>
          <NbTextInput
            required
            id="requestIdInput"
            class="w-100 mb-2 mt-4"
            :name="$t('requestsPage.requestId')"
            placeholder="Example: 25ba321as6as2w1q6"
            v-model="requestIdInput"
          />
          <NbRadio
            required
            id="requestOptionSelected"
            class="w-100 mb-2 mt-4"
            :name="$t('usersPage.userStatus')"
            :options="requestOptions"
            v-model="requestOptionSelected"
          />
          <div class="d-flex justify-content-between flex-wrap pt-3">
            <div></div>
            <NbButton
              class="my-1 px-2 text-left text-14-20"
              title="Send Request"
              icon="external-link"
              :disabled="checkRequestInputs"
              @click="sendRequest()"
            >
              {{ $t("confirm") }}
            </NbButton>
          </div>
        </template>
      </NbCard>
      <NbCard
        v-if="requestIdResponse"
        id="result-card"
        :title="$t('requestsPage.result')"
        class="bg-gray-05 w-fit ml-4"
      >
        <template #body>
          <pre
            class="border rounded mt-4 px-3"
            :class="
              requestIdResponse.status === 'ERROR'
                ? 'border-danger text-danger'
                : 'border-success'
            "
          >
          <code>
{{ requestIdResponse | pretty }}
          </code>
          </pre>
        </template>
      </NbCard>
    </section>
    <NbFooter
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />
  </div>
</template>
<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";

import AsyncRequestService from "../services/AsyncRequestService";
const asyncRequestService = new AsyncRequestService();

export default {
  name: "AsyncRequest",
  components: {
    NbPageTitle,
    NbCard,
    NbTextInput,
    NbRadio,
    NbButton,
    NbFooter,
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(value, null, 2);
    },
  },
  data: () => {
    return {
      requestIdInput: "",
      requestIdResponse: "",
      requestOptionSelected: "",
      requestOptions: [
        { text: "Create Masterbox", value: "create_masterbox" },
        { text: "Remove Masterbox", value: "remove_masterbox" },
        { text: "Close Manifest", value: "close_manifest" },
      ],
    };
  },
  computed: {
    checkRequestInputs() {
      if (this.requestIdInput === "" || this.requestOptionSelected === "") {
        return true;
      }
      return false;
    },
  },
  methods: {
    sendRequest() {
      asyncRequestService
        .sendRequest(this.requestOptionSelected, this.requestIdInput)
        .then((response) => {
          this.requestIdResponse = response.data;
        })
        .catch((error) => {
          this.requestIdResponse = error.response.data;
        });
    },
  },
};
</script>
