<template>
  <div class="content">
    <h4 class="heading-4">
      {{ $t("searchHsCodePage.title") }}
      <NbHelpText
        id="hscode-popover"
        class="mx-1"
        size="md"
        placement="topright"
      >
        {{ $t("searchHsCodePage.tooltip") }}
      </NbHelpText>
    </h4>

    <p class="body-4 mb-0">{{ $t("searchHsCodePage.subtitle") }}</p>

    <NbCardBox class="my-4">
      <form @submit.prevent="hsQuery()" class="search d-flex align-items-end">
        <NbTextInput
          class="w-100"
          v-model="hsToSearch"
          id="hscode"
          :name="$t('searchHsCodePage.code')"
          :masks="['#*']"
          type="text"
          :maxlength="8"
          :placeholder="$t('errorMessages.betweenDigits', { min: 6, max: 8 })"
        />
        <NbButton class="mb-1 ml-2" :disabled="loading || !hsToSearch.trim()">
          {{ $t("search") }}
        </NbButton>
      </form>
    </NbCardBox>

    <CardsHsCode :result="cards" />
  </div>
</template>

<script>
import NbCardBox from "@/components/cards/NbCardBox.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";

import HsCodeService from "../services/HsCodeService";
import CardsHsCode from "../components/CardsHsCode";
import NProgress from "nprogress";

const hsCodeService = new HsCodeService();

export default {
  name: "HSCodeQuery",
  components: {
    CardsHsCode,
    NbCardBox,
    NbTextInput,
    NbButton,
    NbHelpText,
  },
  data: () => {
    return {
      hsToSearch: "",
      loading: false,
      cards: [],
    };
  },
  methods: {
    hsQuery() {
      if (this.hsToSearch.trim()) {
        this.cards = [];

        this.loading = true;
        hsCodeService
          .getAuthHsCode(this.hsToSearch)
          .then((response) => {
            const hs_code = response.data.data;
            this.cards = [
              {
                hscode: this.hsToSearch,
                category: hs_code.no_ncm,
                success: true,
              },
            ];
          })
          .catch(() => {
            this.cards = [
              {
                hscode: this.hsToSearch,
                category: "",
                success: false,
              },
            ];
          })
          .finally(() => {
            this.loading = false;
            this.hsToSearch = "";
            NProgress.done();
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  max-width: 44rem;
  margin: 0 auto;
}
</style>
