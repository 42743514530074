<template>
  <NbBadge :text="badge.label" :type="badge.type" />
</template>
<script>
import NbBadge from "@/components/alerts/NbBadge.vue";
export default {
  props: {
    status: {
      type: [String, null],
      default: "new_order",
    },
  },
  components: {
    NbBadge,
  },
  computed: {
    badge() {
      const statuses = {
        new_order: {
          label: this.$t("volumesPage.new"),
          type: "info",
        },
        invoiced: {
          label: this.$t("volumesPage.invoiced"),
          type: "success",
        },
        to_invoice: {
          label: this.$t("volumesPage.toInvoice"),
          type: "warning",
        },
        canceled: {
          label: this.$t("volumesPage.canceled"),
          type: "danger",
        },
        not_charged: {
          label: this.$t("volumesPage.notCharged"),
          type: "warning",
        },
      };
      return statuses[this.status];
    },
  },
};
</script>

<style lang="scss" scoped></style>
