<template>
  <div>
    <header-title title="$t('overViewPage.title')" />
    <div class="d-flex justify-content-center">
      <div class="mb-5">
        <div class="border-white py-4">
          <Cards :cards="cards" />
        </div>
        <b-container>
          <h5 class="mb-5">{{ $t("overViewPage.getInfoCorreios") }}</h5>
          <b-row class="mb-5">
            <b-col>
              <b-form-group :label="$t('overViewPage.putTrackingNumber')">
                <div
                  class="d-flex flex-row align-items-center justify-content-center"
                >
                  <b-form-input
                    v-model="trackingCode"
                    type="text"
                    placeholder="Tracking Number"
                  />
                  <div>
                    <b-button
                      variant="primary"
                      class="ml-2"
                      title="getCn23Info"
                      :disabled="trackingCode == ''"
                      @click="getCn23Info(trackingCode)"
                    >
                      <i class="fas fa-search"></i>
                    </b-button>
                  </div>
                </div>
              </b-form-group>
              <div
                v-if="cn23Info"
                class="cn35-info-square border rounded px-3 py-2"
                :class="borderType23"
              >
                <pre>{{ cn23Info | pretty }}</pre>
              </div>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('overViewPage.putDispatchNumberUnitCode')"
              >
                <div
                  class="d-flex flex-row align-items-center justify-content-center"
                >
                  <b-form-input
                    v-model="code"
                    type="text"
                    placeholder="Dispatch Number/ Unit Code"
                  />
                  <div>
                    <b-button
                      variant="primary"
                      class="ml-2"
                      title="getCn35Info"
                      :disabled="code == ''"
                      @click="getCn35Info(code)"
                    >
                      <i class="fas fa-search"></i>
                    </b-button>
                  </div>
                </div>
              </b-form-group>
              <div
                v-if="cn35Info"
                class="cn35-info-square border border-success rounded bg-white px-3 py-2"
                :class="borderType35"
              >
                <pre>{{ cn35Info | pretty }}</pre>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <div class="border-white py-4">
                <h5 class="mx-5 mb-4 text-center">
                  {{ $t("overViewPage.manuallyUpdateCN38Number") }}
                </h5>
                <div class="row mx-5 my-2">
                  <div class="col mx-5 text-center">
                    <b-form-group :label="$t('overViewPage.shipmentID')">
                      <b-form-input
                        v-model="shipmentId"
                        type="text"
                        placeholder="Shipment ID"
                      />
                    </b-form-group>
                    <b-form-group :label="$t('overViewPage.newCN38Number')">
                      <b-form-input
                        v-model="newCn38"
                        type="text"
                        placeholder="CN38 number"
                      />
                    </b-form-group>
                    <b-button
                      variant="primary"
                      :disabled="shipmentId == '' || newCn38 == ''"
                      class="mb-1 mt-1 text-white"
                      title="Update cn38 number"
                      data-toggle="modal"
                      data-target="#updateCn38"
                    >
                      {{ $t("overViewPage.updateCN38") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="border-white py-4 text-center">
                <h5 class="mx-5">
                  {{ $t("overViewPage.resetDispatchNumbersCounter") }}
                </h5>
                <br />
                <div class="row mx-5 my-2">
                  <div class="col mx-5">
                    <b-form-group :label="$t('overViewPage.newCounterStart')">
                      <b-form-input
                        v-model="newCounterStart"
                        type="number"
                        placeholder="Dispatch ID"
                      />
                    </b-form-group>
                    <b-button
                      variant="primary"
                      :disabled="newCounterStart == ''"
                      class="mb-1 mt-1"
                      data-toggle="modal"
                      data-target="#resetDispatch"
                      title="Reset Dispatch numbers"
                    >
                      {{ $t("overViewPage.reset") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="justify-content-center mt-3">
            <b-col>
              <div class="border-white py-4 text-center">
                <h5 class="mx-5">{{ $t("overViewPage.removeOverpack") }}</h5>
                <br />
                <div class="row mx-5 my-2">
                  <div class="col mx-5">
                    <b-form-group>
                      <b-form-input
                        v-model="removeOverpack"
                        placeholder="Dispatch ID / CN35"
                      />
                    </b-form-group>
                    <b-button
                      variant="primary"
                      :disabled="removeOverpack == ''"
                      class="mb-1 mt-1"
                      data-toggle="modal"
                      data-target="#removeOverpack"
                      title="Remove Overpack"
                    >
                      {{ $t("remove") }}
                    </b-button>
                  </div>
                </div>
                <div
                  v-if="removeOverpackInfo"
                  class="cn35-info-square border rounded mt-4"
                  :class="borderTypeRemoveOverpack"
                >
                  <pre>{{ removeOverpackInfo | pretty }}</pre>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col>
              <b-form-group :label="$t('overViewPage.')">
                <div
                  class="d-flex flex-row align-items-center justify-content-center"
                >
                  <b-form-input
                    :value="testLogUSerId"
                    type="number"
                    readonly="readonly"
                  />
                  <b-form-input
                    v-model="testLogUserIdToSend"
                    type="number"
                    placeholder="Input an integer here"
                  />
                  <div>
                    <b-button
                      variant="primary"
                      class="ml-2"
                      title="testUpdateDelay"
                      :disabled="testLogUserIdToSend == ''"
                      @click="testUpdateDelay()"
                    >
                      {{ $t("overViewPage.test") }}
                    </b-button>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col>
              <div
                class="d-flex flex-row align-items-center justify-content-center"
              >
                <b-button
                  variant="primary"
                  class="ml-2"
                  title="getCn23Info"
                  @click="generateRequestId()"
                >
                  {{ $t("overViewPage.generateRequestID") }}
                </b-button>
                <h6 class="mx-5">{{ requestId }}</h6>
              </div>
              <b-form-group>
                <div
                  class="d-flex flex-row align-items-center justify-content-center mt-3"
                >
                  <b-form-input
                    v-model="requestIdInput"
                    type="text"
                    placeholder="Input Request ID here"
                  />
                  <b-button
                    variant="primary"
                    class="ml-2"
                    title="getApiStatus"
                    :disabled="requestIdInput == ''"
                    @click="getApiStatus()"
                  >
                    <i class="fas fa-search"></i>
                  </b-button>
                </div>
                <div
                  v-if="integratorStatus"
                  class="cn35-info-square border rounded mt-4"
                  :class="borderTypeRemoveOverpack"
                >
                  <pre>{{ integratorStatus | pretty }}</pre>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <span class="my-2">v1.12</span>
    </div>
    <!-- modals -->
    <div id="updateCn38" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="settingsTitle" class="modal-title">
              {{ $t("overViewPage.updateCn38Number") }}
            </h5>
            <button
              id="updateCn38-close"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                {{ $t("overViewPage.sureOverwrite") }}
                <span class="font-weight-bold">{{ shipmentId }}</span
                >. {{ $t("overViewPage.definitiveAction") }}?
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a
              class="btn btn-primary mt-2 text-white"
              title="Save Column List"
              @click="updateCn38"
            >
              {{ $t("yes") }}
            </a>
            <a
              id="settings-cancel-button"
              href="#"
              class="btn btn-secondary float-right ml-3"
              data-dismiss="modal"
            >
              {{ $t("no") }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div id="resetDispatch" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="settingsTitle" class="modal-title">
              {{ $t("overViewPage.resetDispatchNumbersCounter") }}
            </h5>
            <button
              id="resetDispatch-close"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                {{ $t("overViewPage.sureResetCorreiosCounter") }}.
                <span class="font-weight-bold"
                  >{{ $t("overViewPage.definitiveAction") }}?</span
                >
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a
              class="btn btn-primary mt-2 text-white"
              title="Save Column List"
              @click="resetDispatch"
            >
              {{ $t("yes") }}
            </a>
            <a
              id="settings-cancel-button"
              href="#"
              class="btn btn-secondary float-right ml-3"
              data-dismiss="modal"
            >
              {{ $t("no") }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div id="removeOverpack" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="settingsTitle" class="modal-title">
              {{ $t("overViewPage.removeOverpack") }}
            </h5>
            <button
              id="removeOverpack-close"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                {{ $t("overViewPage.sureRemoveOverpack") }}?
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a
              class="btn btn-primary mt-2 text-white"
              data-dismiss="modal"
              title="Save Column List"
              @click="deleteRemoveOverpack(removeOverpack)"
            >
              {{ $t("yes") }}
            </a>
            <a
              id="settings-cancel-button"
              href="#"
              class="btn btn-secondary float-right ml-3"
              data-dismiss="modal"
            >
              {{ $t("no") }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OverviewService from "../services/OverviewService";
import LogService from "../services/LogService";
import MasterBoxService from "../services/MasterBoxService";
import ShipmentService from "../services/ShipmentService";
import Cards from "../components/Cards";
import HeaderTitle from "../components/HeaderTitle.vue";

const overviewService = new OverviewService();
const logService = new LogService();
const masterBoxService = new MasterBoxService();
const shipmentService = new ShipmentService();

export default {
  name: "BalanceCorreios",
  components: {
    Cards,
    HeaderTitle,
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(value, null, 2);
    },
  },
  data: () => {
    return {
      saldoCorreios: "0,00",
      trackingCode: "",
      code: "",
      cn23Info: "",
      borderType23: "",
      cn35Info: "",
      borderType35: "",
      shipmentId: "",
      newCn38: null,
      newCounterStart: "",
      removeOverpack: "",
      removeOverpackInfo: "",
      cards: [],
      errors: [],
      namespace: "overview",
      testLogUSerId: "",
      testLogUserIdToSend: "",
      requestId: "",
      requestIdInput: "",
      integratorStatus: "",
    };
  },
  computed: {
    correiosBalance() {
      return this.$store.state[this.namespace].balance;
    },
  },
  beforeMount() {
    this.getFirstLogUSerId();
    this.loadCorreiosBalance();
    this.showCorreiosBalance();
    this.showCevaBalance();
  },
  methods: {
    loadCorreiosBalance() {
      this.$store.dispatch(this.namespace + "/fetchBalance");
    },
    getFirstLogUSerId() {
      logService.getFirstLogUserId().then((response) => {
        this.testLogUSerId = response.data.data;
      });
    },
    testUpdateDelay() {
      logService.testUpdateDelay(this.testLogUserIdToSend);
      this.borderType23 = "border-success";
    },
    getApiStatus() {
      overviewService.getApiStatus(this.requestIdInput).then((response) => {
        this.integratorStatus = response.data.data;
      });
    },
    generateRequestId() {
      overviewService.generateRequestId().then((response) => {
        this.requestId = response.data.data;
      });
    },
    showCorreiosBalance() {
      let saldo = {};
      if (this.correiosBalance) {
        saldo = {
          text: "Saldo Correios",
          val: `${this.correiosBalance}`,
          icon: "fa fa-calculator text-lightblue",
          currency: false,
        };
      } else {
        saldo = {
          text: "Saldo Correios",
          val: "Not Found",
          icon: "fa fa-calculator text-lightblue",
          currency: false,
        };
      }
      this.cards.unshift(saldo);
    },
    showCevaBalance(code) {
      let pendingRequests = {};

      overviewService.getPendingCevaRequests(code).then((response) => {
        this.ceva = response.data.data;

        pendingRequests = {
          text: "Pending Ceva requests",
          val: this.ceva,
          icon: "fas fa-clipboard-list text-lightblue",
        };
        this.cards.push(pendingRequests);
      });
    },
    getCn23Info(code) {
      overviewService
        .getCn23Info(code)
        .then((response) => {
          this.cn23Info = response.data.data;
          this.borderType23 = "border-success";
        })
        .catch((error) => {
          this.cn23Info = error.response.data;
          this.borderType23 = "border-danger";
        });
    },
    getCn35Info(code) {
      overviewService
        .getCn35Info(code)
        .then((response) => {
          this.cn35Info = response.data.data;
          this.borderType35 = "border-success";
        })
        .catch((error) => {
          this.cn35Info = error.response.data;
          this.borderType35 = "border-danger";
        });
    },
    deleteRemoveOverpack(code) {
      overviewService
        .deleteRemoveOverpack(code)
        .then((response) => {
          this.removeOverpackInfo = {
            data: response.data,
            status: response.status,
            statusText: response.statusText,
          };
          this.borderTypeRemoveOverpack = "border-success";
        })
        .catch((error) => {
          this.removeOverpackInfo = error.response.data;
          this.borderTypeRemoveOverpack = "border-danger";
        });
    },
    updateCn38() {
      const dataToSend = { cn38: this.newCn38 };
      if (this.newCn38 == "") {
        this.newCn38 == null;
      }
      document.getElementById("updateCn38-close").click();
      shipmentService.updateCn38(this.shipmentId, dataToSend);
    },
    resetDispatch() {
      const dataToSend = { dispatch_id: this.newCounterStart };
      document.getElementById("resetDispatch-close").click();
      masterBoxService.resetDispatch(dataToSend);
    },
  },
};
</script>

<style scoped>
.cn35-info-square {
  max-width: 400px !important;
  margin-left: calc(50% - 200px);
}
</style>
