<template>
  <NbButton
    class="relative"
    type="button"
    :variant="variant"
    @click.stop="generateShipmentLabel"
  >
    <div class="loader">
      <Spinner v-if="loading" />
    </div>
    <div class="d-flex align-items-center gap-1" :data-loading="loading">
      <slot>
        {{ $t("download") }}
      </slot>
    </div>
  </NbButton>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import ShipmentService from "@/services/ShipmentService";
import Spinner from "@/components/Spinner.vue";

const shipmentService = new ShipmentService();

export default {
  props: {
    shipmentId: {
      type: Number,
      required: true,
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
  components: {
    Spinner,
    NbButton,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async generateShipmentLabel() {
      try {
        this.loading = true;
        const {
          data: { data },
        } = await shipmentService.generateShipmentLabel(this.shipmentId);

        if (data?.download_url) {
          window.open(data.download_url);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

[data-loading="true"] {
  visibility: hidden;
}
</style>
