<template>
  <div class="skeleton" :style="{ height, width }"></div>
</template>

<script>
export default {
  props: {
    h: {
      type: String,
      default: "16",
    },
    w: {
      type: String,
      default: "32",
    },
  },
  computed: {
    height() {
      return parseFloat(this.h) / 16 + "rem";
    },
    width() {
      return parseFloat(this.w) / 16 + "rem";
    },
  },
};
</script>

<style lang="scss" scoped>
.skeleton {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background-color: #0f172a1a;
  border-radius: 0.375rem;
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
</style>
