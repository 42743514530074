<template>
  <div>
    <label class="input-label">
      {{ name }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
        <a
          v-if="helpTextLink"
          :href="helpTextLink.href"
          target="_blank"
          class="link-2"
          style="color: white !important"
        >
          {{ helpTextLink.text }}
        </a>
      </NbHelpText>
    </label>
    <div class="input-view">
      <slot>
        {{ value }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    helpTextLink: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.input-view {
  transition: all 0.3s ease;
  height: 2.37rem;
  padding: 9px 12px;
  box-shadow: none;
  border: none;
  background: var(--gray-10);
  border-radius: 4px;
  color: var(--black);
  font-size: 0.75rem;
  font-family: "Nunito Sans";
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
}
</style>
