<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("download") }}
      </ModalTitle>
    </ModalHeader>

    <p>
      {{ $t("ordersPage.checkDownloadsAt") }}
    </p>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose class="mr-2" />
      <NbButton @click="download" :disabled="loading">
        {{ $t("download") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import DownloadsService from "@/services/DownloadsService";

const downloadsService = new DownloadsService();

export default {
  props: {
    referenceIds: {
      type: Array,
      required: true,
    },
  },
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async download() {
      try {
        this.loading = true;
        await downloadsService.loadProcessing({
          data_type: "seller_account_events",
          reference_ids: this.referenceIds,
        });
        this.$router.push("/archives");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
