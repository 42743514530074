<template>
  <NbTextField
    v-if="disabled && variant"
    :placeholder="placeholder"
    :textarea="textarea"
    :id="id"
    :name="configuredName"
    variant="borderless-gray-10"
    :prepend="prepend"
    :append="append"
    :type="type"
    :decimals="decimals"
    :value="textContent"
    :helpText="helpText"
  />
  <div
    v-else
    class="nb-input"
    :class="errorsFeedback ? `is-invalid ${variant}` : `${variant}`"
  >
    <label
      v-if="name"
      class="input-label"
      :class="disabled ? 'not-allowed' : ''"
      :for="id"
    >
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
        <a
          v-if="helpTextLink"
          :href="helpTextLink.href"
          target="_blank"
          class="link-2"
          style="color: white !important"
        >
          {{ helpTextLink.text }}
        </a>
      </NbHelpText>
    </label>
    <div
      :id="`${id}-input-wrapper`"
      class="d-flex input-wrapper"
      :class="wrapperClasses"
    >
      <div
        v-if="prepend"
        class="fake-input pointer prepend"
        :class="`
          ${textContent && !disabled ? 'text-black ' : ''}
          ${errorsFeedback ? 'is-invalid ' : ''}
          ${disabled ? 'disabled' : ''}
        `"
        :style="prependStyle"
      >
        {{ prepend }}
      </div>
      <InputFacade
        v-if="masks"
        :tabindex="tabindex"
        :type="type == 'money' ? 'number' : type"
        :min="min"
        :step="step"
        class="nb form-control input"
        :class="`${
          errorsFeedback
            ? disabled
              ? 'is-invalid not-allowed'
              : 'is-invalid'
            : disabled
              ? 'not-allowed'
              : ''
        }`"
        :id="id"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        :onkeypress="onkeypress"
        @input="onInput($event)"
        @blur="onBlur"
        @focus="
          autoSelect ? $event.target.select() : false;
          inputFocused();
        "
        v-model="textContent"
        :mask="masks"
      />
      <b-form-textarea
        v-else-if="type == 'textarea'"
        :tabindex="tabindex"
        class="nb form-control input textarea"
        :class="`${
          errorsFeedback
            ? disabled
              ? 'is-invalid not-allowed'
              : 'is-invalid'
            : disabled
              ? 'not-allowed'
              : ''
        }`"
        :id="id"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        :onkeypress="onkeypress"
        @input="onInput($event)"
        @blur="onBlur"
        @focus="
          autoSelect ? $event.target.select() : false;
          inputFocused();
        "
        v-model="textContent"
        :rows="rows"
      ></b-form-textarea>
      <input
        v-else
        :tabindex="tabindex"
        :type="type == 'money' ? 'number' : type"
        :min="min"
        :step="step"
        class="nb form-control input"
        :class="`${
          errorsFeedback
            ? disabled
              ? 'is-invalid not-allowed'
              : 'is-invalid'
            : disabled
              ? 'not-allowed'
              : ''
        }`"
        :id="id"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        :onkeypress="onkeypress"
        @input="onInput($event)"
        @blur="onBlur"
        @focus="
          autoSelect ? $event.target.select() : false;
          inputFocused();
        "
        v-model="textContent"
      />

      <div
        v-if="append"
        class="fake-input pointer append"
        :class="` 
          ${textContent ? 'text-black ' : ''}
          ${errorsFeedback ? 'is-invalid' : ''}
        `"
      >
        {{ append }}
      </div>
    </div>
    <ErrorFeedback :error="errorsFeedback" />
  </div>
</template>

<script>
import NbTextField from "@/components/input/text/NbTextField.vue";
import { InputFacade, facade } from "vue-input-facade";
import ErrorFeedback from "../../generic/ErrorFeedback.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";

export default {
  name: "NbTextInput",
  directives: { facade },
  components: {
    NbTextField,
    ErrorFeedback,
    NbHelpText,
    InputFacade,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    checkOnChange: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    autoSelect: {
      type: Boolean,
      required: false,
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    helpTextLink: {
      type: Object,
      required: false,
    },
    prepend: {
      type: String,
      required: false,
    },
    prependStyle: {
      type: String,
      default: "",
    },
    append: {
      type: String,
      required: false,
    },
    autocomplete: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      required: false,
      default: "",
    },
    decimals: {
      type: Number,
      required: false,
    },
    maxlength: {
      type: Number,
      required: false,
    },
    min: {
      type: [Number, String],
      required: false,
    },
    step: {
      type: String,
      required: false,
    },
    onkeypress: {
      type: String,
      required: false,
    },
    tabindex: {
      type: Number,
      required: false,
    },
    rules: {
      type: String,
      required: false,
    },
    masks: {
      type: Array,
      required: false,
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
    textarea: {
      type: Boolean,
      required: false,
    },
    rows: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      textContent: this.value,
      internalErrors: [],
      isFocused: false,
    };
  },
  mounted() {
    if (this.decimals) {
      if (typeof this.textContent == "string") {
        this.textContent = this.textContent.replace(",", ".");
      }
      if (!isNaN(this.textContent) && this.textContent.length > 0) {
        this.textContent = parseFloat(this.textContent).toFixed(this.decimals);
      }
    }
  },
  methods: {
    onInput(event) {
      if (this.decimals && this.type == "money") {
        if (event.inputType !== "insertText") {
          if (typeof this.textContent == "string") {
            this.textContent = this.textContent.replace(",", ".");
          }
          if (!this.textContent.includes(".")) {
            this.textContent = (this.textContent / 100).toFixed(this.decimals);
            return;
          }
          return;
        }
        if (this.hasDescimals(this.textContent)) {
          this.textContent = (this.textContent * 10).toFixed(this.decimals);
          return;
        }
        if (typeof this.textContent == "string") {
          this.textContent = this.textContent.replace(",", ".");
          this.textContent = this.textContent / 10 ** this.decimals;
        }
      }
      if (typeof this.min !== "undefined" && this.min > this.textContent) {
        this.textContent = this.min;
      }
      // emitting twince?
      //this.$emit('input', this.textContent)
    },
    onBlur() {
      this.isFocused = false;
      this.$emit("blur");
      this.validateTextContent();
      if (this.decimals && this.type == "money") {
        if (this.hasDescimals(this.textContent)) {
          this.textContent = Number(this.textContent).toFixed(this.decimals);
          this.checkRules(this.textContent);
          this.$emit("input", this.textContent);
          return;
        }
        if (typeof this.textContent == "string") {
          this.textContent = this.textContent.replace(",", ".");
          this.textContent = this.textContent / 10 ** this.decimals;
        }
      }
      if (this.decimals) {
        if (typeof this.textContent == "string") {
          this.textContent = this.textContent.replace(",", ".");
        }
        if (
          !isNaN(this.textContent) &&
          this.textContent &&
          this.textContent.length > 0
        ) {
          this.textContent = parseFloat(this.textContent).toFixed(
            this.decimals,
          );
        }
      }
      this.checkRules(this.textContent);
      //Verificar se e necessario'
      //this.$emit('input', this.textContent)
    },
    validateTextContent() {
      //take care with || this.error.length > 0
      if (this.required && this.textContent && this.textContent.length < 1) {
        this.$emit("invalid", {
          id: this.id,
          message: this.$t("errorMessages.required"),
        });
        return;
      }
      this.$emit("valid", this.id);
    },
    hasDescimals(num) {
      if (num) {
        const numStr = num.toString();
        if (!numStr.includes(".")) {
          return false;
        }
        return true;
      }
      return false;
    },
    checkRules(newValue) {
      this.internalErrors = [];
      this.internalErrors = this.$helpers.inputRules(
        newValue,
        this.rules,
        this.required,
      );
    },
    allOk() {
      const element = document.getElementById(this.id + "-input-wrapper");
      if (this.error.length > 0 || this.internalErrors.length > 0) {
        this.$emit("invalid", {
          id: this.id,
          message: this.internalErrors[0],
        });
        element.classList.remove("is-valid");
        return;
      }
      setTimeout(() => {
        element.classList.add("is-valid");
      }, 200);
    },
    inputFocused() {
      this.isFocused = true;
    },
  },
  computed: {
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
    errorsFeedback() {
      if (this.error.length > 0) {
        return this.error[0];
      }
      if (this.internalErrors.length > 0) {
        return this.internalErrors[0];
      }
      return "";
    },
    wrapperClasses() {
      if (this.disabled) {
        return "disabled ";
      }

      if (this.errorsFeedback) {
        return "is-invalid ";
      }

      if (this.textContent || this.isFocused) {
        return "is-typeing ";
      }
      return "";
    },
  },
  watch: {
    //altera o input (way data bind ->)
    textContent(newValue) {
      if (this.checkOnChange) {
        this.checkRules(this.textContent);
      }
      this.$emit("input", newValue);
    },
    //altera o input vindo do pai (way data bind <-)
    value(newValue) {
      this.textContent = newValue;
    },

    internalErrors() {
      this.allOk();
    },
  },
};
</script>

<style lang="scss" scoped>
.nb-input {
  position: relative;
}
//inputs
.input-label {
  color: var(--black);
  text-align: left;
  font: normal normal 600 12px/16px var(--font-family-base);
  letter-spacing: 0px;
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: default;
}
.input-wrapper {
  border-bottom: 2px solid var(--gray-40);
}
.input-wrapper.is-typeing.disabled,
.input-wrapper.disabled {
  border-bottom: 2px solid var(--gray-20);
}
.input-wrapper.is-typeing {
  border-bottom: 2px solid var(--black);
}
.input-wrapper.is-typeing.is-invalid,
.input-wrapper.is-invalid {
  border-bottom: 2px solid var(--error) !important;
}
.input-wrapper.is-typeing.is-valid,
.input-wrapper.is-valid {
  border-bottom: 2px solid var(--success);
}

.form-control.input {
  transition: all 0.3s ease;
  padding: 9px 12px;
  box-shadow: none;
  border: none;
  font: normal normal normal 14px/20px var(--font-family-base);
  background: var(--gray-05) 0% 0% no-repeat padding-box !important;
  border-radius: 4px 4px 0px 0px;
  &:not(.textarea) {
    height: 2.37rem;
  }
}
.form-control.input:hover {
  //color: var(--gray-40);
  background: var(--gray-10) 0% 0% no-repeat padding-box !important;
}
.form-control.input:focus {
  box-shadow: none;
}
.form-control.input::placeholder {
  color: var(--gray-40);
}

.form-control.input.is-invalid {
  caret-color: var(--error);
  background-image: none;
}

.input-wrapper.is-valid .fake-input,
.input-wrapper.is-valid .form-control.input {
  caret-color: var(--success);
  background-image: none;
}
.nb-input:not(:has(:disabled)) .input-wrapper:hover .fake-input,
.form-control.input.is-invalid:hover {
  background: var(--gray-10) 0% 0% no-repeat padding-box !important;
}

.input-wrapper .fake-input.disabled,
.form-control.input:disabled,
.form-control.input[disabled] {
  background-color: var(--gray-05) !important;
  color: var(--gray-20) !important;
}
.text-gray-60 .input-wrapper .fake-input.disabled,
.text-gray-60 .form-control.input:disabled,
.text-gray-60 .form-control.input[disabled] {
  color: var(--gray-60) !important;
}

.nb-input .nb.form-control.prepend-padding {
  padding-left: 2rem !important;
}
.nb-input .fake-input {
  color: var(--gray-40);
  transition: all 0.3s ease;
  height: 2.37rem;
  padding: 0.56rem 0.37rem;
  box-shadow: none;
  border: none;
  font: normal normal normal 14px/20px var(--font-family-base);
  background: var(--gray-05) 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 0px 0px;
}
/* .nb-input .fake-input.is-invalid:not(:placeholder-shown) {
  //border-bottom: 2px solid var(--black);
} */

.nb-input .fake-input.is-invalid {
  caret-color: var(--error);
  background-image: none;
}
.nb-input .prepend-text {
  z-index: 1;
  position: absolute;
  top: 2.3rem;
  left: 0.8rem;
  color: var(--gray-40);
}

.text-black {
  color: #495057 !important;
}

.nb-input .nb.form-control.append-padding {
  padding-right: 2rem !important;
}
.nb-input .append-text {
  z-index: 1;
  position: absolute;
  top: 2.3rem;
  right: 0.8rem;
  color: var(--gray-40);
}

.text-black {
  color: #495057 !important;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.nb-input:has(.prepend) .input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.nb-input:has(.append) .input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* borderless input */
.borderless.nb-input .input-wrapper.is-typeing.disabled,
.borderless.nb-input .input-wrapper.disabled,
.borderless.nb-input .input-wrapper.is-typeing,
.borderless.nb-input .input-wrapper {
  background: var(--gray-20) !important;
  border-radius: 4px !important;
  border-bottom: 2px solid transparent;
}

.borderless.nb-input .input-wrapper .fake-input.disabled,
.borderless.nb-input .form-control.input:disabled,
.borderless.nb-input .form-control.input[disabled],
.borderless.nb-input .form-control.input {
  background: var(--gray-20) !important;
  color: var(--black) !important;
}

/* borderless-gray-10 input */
.borderless-gray-10.nb-input .input-wrapper.is-typeing.disabled,
.borderless-gray-10.nb-input .input-wrapper.disabled,
.borderless-gray-10.nb-input .input-wrapper.is-typeing,
.borderless-gray-10.nb-input .input-wrapper {
  background: var(--gray-10) !important;
  border-radius: 4px !important;
  border-bottom: 2px solid transparent;
}

.borderless-gray-10.nb-input .input-wrapper .fake-input.disabled,
.borderless-gray-10.nb-input .form-control.input:disabled,
.borderless-gray-10.nb-input .form-control.input[disabled],
.borderless-gray-10.nb-input .form-control.input {
  background: var(--gray-10) !important;
  color: var(--black) !important;
}
.borderless-gray-10.nb-input .input-wrapper .prepend {
  background: var(--gray-10) 0% 0% no-repeat padding-box !important;
  padding-right: 0px;
  padding-left: 0.75rem;
}

.outline {
  .form-control.input {
    border: 1px solid var(--gray-30);
    height: 1.75rem;
    border-radius: 4px;
    &::placeholder {
      font: normal normal 600 12px/16px Nunito Sans;
      color: var(--gray-40);
    }
  }

  .input-wrapper {
    border-bottom: none;
  }

  .input-wrapper.is-typeing.is-valid,
  .input-wrapper.is-valid {
    border-bottom: none;
  }
}
</style>
