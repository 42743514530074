<template>
  <div>
    <div>
      <ModalHeader>
        <div class="d-flex mb-4 gap-4">
          <ModalTitle class="heading-4 ml-3 mb-1">
            {{ `${$t("alertLogsViewPage.title")}${itemId}` }}
          </ModalTitle>
          <div v-if="alertLog.status" class="d-flex gap-4">
            <AlertLogStatusBadge :data="alertLog.status" />
            <AlertLogTypeBadge :data="alertLog.type" />
          </div>
        </div>
      </ModalHeader>
      <div>
        <NbCard id="actions" :title="$t('actions')">
          <template #body>
            <div class="d-flex gap-4">
              <NbButton
                @click="openDocument(alertLog.document)"
                :disabled="!alertLog.document"
                class="d-flex align-items-center gap-2"
              >
                <NbIcon icon="download" :size="18" />
                {{ $t("alertLogsViewPage.downloadAttachment") }}
              </NbButton>
              <NbButton
                @click="getPaymentPdf(alertLog.id)"
                class="d-flex align-items-center gap-2"
                :disabled="isLoadingPaymentPdf"
              >
                <NbIcon icon="download" :size="18" />
                {{ $t("alertLogsViewPage.downloadPaymentCertificate") }}
              </NbButton>
            </div>
          </template>
        </NbCard>

        <NbCard
          id="alertLog_chard"
          :title="$t('generalInformation')"
          class="bg-gray-05 mt-4"
        >
          <template #body>
            <div class="grid grid-cols-4 gap-4">
              <NbTextInput
                v-if="alertLog.order_data"
                id="incoterm"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('alertLogsViewPage.incoterm')"
                v-model="alertLog.incoterm"
                disabled
              />
              <NbTextInput
                v-if="alertLog.order_data"
                id="status"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('alertLogsViewPage.status')"
                v-model="alertLog.status"
                disabled
              />
              <NbTextInput
                id="date"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('alertLogsViewPage.date')"
                v-model="alertLog.created_at"
                disabled
              />
              <NbTextInput
                id="dateIso"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('alertLogsViewPage.dateIso')"
                v-model="alertLog.date_iso"
                disabled
              />
              <div
                class="link-1"
                @click="pushTo(`/shipping/orders?id=${alertLog.order_id}`)"
              >
                <NbTextInput
                  id="orderID"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  :name="$t('alertLogsViewPage.orderID')"
                  v-model="alertLog.order_id"
                  disabled
                />
              </div>

              <NbTextInput
                id="orderNumber"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('alertLogsViewPage.orderNumber')"
                v-model="alertLog.order_number"
                disabled
              />
              <div
                v-if="alertLog.order_data"
                class="link-1"
                @click="pushTo(`/shipping/volumes?id=${alertLog.volume_id}`)"
              >
                <NbTextInput
                  id="volume"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  :name="$t('alertLogsPage.fields.volume')"
                  v-model="alertLog.last_mile_number"
                  disabled
                />
              </div>
              <div
                class="link-1"
                @click="
                  pushTo(`/users_management/sellers?id=${alertLog.seller_id}`)
                "
              >
                <NbTextInput
                  id="sellerID"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  :name="$t('alertLogsPage.fields.seller')"
                  v-model="alertLog.seller_name"
                  disabled
                />
              </div>
              <NbTextInput
                v-if="alertLog"
                id="taxID"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="col-span-4"
                :name="$t('alertLogsViewPage.taxID')"
                v-model="alertLog.tax_id"
                disabled
              />
              <NbTextInput
                v-if="alertLog"
                id="productsValue"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('alertLogsViewPage.productsValue')"
                :prepend="getCurrency(alertLog.currency)"
                v-model="alertLog.products_value"
                disabled
              />
              <NbTextInput
                id="freightValue"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('alertLogsViewPage.freightValue')"
                :prepend="getCurrency(alertLog.currency)"
                v-model="alertLog.freight_value"
                disabled
              />
              <NbTextInput
                id="expectedTax"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('alertLogsViewPage.expectedTax')"
                :prepend="getCurrency(alertLog.currency)"
                v-model="alertLog.expected_tax"
                disabled
              />
              <NbTextInput
                id="realTax"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('alertLogsViewPage.realTax')"
                prepend="R$"
                v-model="alertLog.real_tax"
                disabled
              />
              <div
                @click="
                  pushTo(
                    `/checkpoints/checkpoints?nobordistCheckpointId=${alertLog.checkpoint_id}`,
                  )
                "
                class="link-1 col-span-4"
              >
                <NbTextInput
                  id="title"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  :name="$t('alertLogsPage.fields.title')"
                  v-model="alertLog.title"
                  disabled
                />
              </div>
              <NbTextInput
                id="barcode"
                class="col-span-4"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('alertLogsPage.barcode')"
                v-model="alertLog.barcode"
                disabled
              />
              <NbTextInput
                id="message"
                class="col-span-4"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('alertLogsPage.fields.message')"
                v-model="alertLog.message"
                disabled
              />
              <NbTextInput
                id="comment"
                class="col-span-4"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('alertLogsPage.fields.comment')"
                v-model="alertLog.comment"
                disabled
              />
              <NbTextInput
                id="rule"
                class="col-span-2"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                :name="$t('alertLogsPage.fields.rule')"
                :value="
                  alertLogContext.getTrackingRuleName(alertLog.tracking_rule_id)
                "
                disabled
              />
              <NbTextInput
                id="duties_status"
                class="col-span-2"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                name="Duties Status"
                v-model="alertLog.duties_status"
                disabled
              />
            </div>
          </template>
        </NbCard>
        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <ModalFooter class="d-flex justify-content-end">
        <ModalClose />
        <!-- <div class="d-flex justify-content-end">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
            @click="resetQuery()"
          >
            {{ $t("close") }}
          </NbButton>
        </div> -->
      </ModalFooter>
    </div>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import AlertLogsService from "@/services/AlertLogsService";
import AlertLogStatusBadge from "./AlertLogStatusBadge.vue";
import AlertLogTypeBadge from "./AlertLogTypeBadge.vue";
import alertLogMixin from "../mixins/alert-log-mixin";

const alertLogsService = new AlertLogsService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbTextInput,
    NbCard,
    NbFooter,
    NbIcon,
    AlertLogStatusBadge,
    AlertLogTypeBadge,
  },
  name: "ModalAlertLogsView",
  props: {
    id: {
      type: String,
      default: "ModalAlertLogsView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  mixins: [alertLogMixin],
  inject: ["alertLogContext"],
  data() {
    return {
      alertLog: {},
      errors: {},
    };
  },
  methods: {
    openDocument(url) {
      window.open(url);
    },
    getCurrency(currency) {
      if (currency) {
        if (currency.toUpperCase() === "USD") {
          return "$ ";
        }
        return "R$ ";
      }
      return;
    },
    pushTo(path) {
      this.$router.push({
        path: path,
      });
    },
    resetQuery() {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({ query: {} });
        return;
      }
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    load() {
      alertLogsService.getLog(this.itemId).then((response) => {
        this.alertLog = response.data.data;
        //formatacao de datas iso e created at
        const time_date_iso = this.alertLog.date_iso;
        const date_date_iso = this.alertLog.date_iso;
        this.alertLog.date_iso = this.formateDate(date_date_iso, time_date_iso);

        const time = this.alertLog.created_at;
        const date = this.alertLog.created_at;
        this.alertLog.created_at = this.formateDate(date, time);
      });
    },
  },
  computed: {
    value_brl: {
      get() {
        if (this.alertLog.charged_amount && this.alertLog.exchange) {
          const finalResult =
            Number(this.alertLog.charged_amount) *
            Number(this.alertLog.exchange);
          return finalResult.toFixed(2);
        } else {
          return "0.00";
        }
      },
      set(val) {
        return val;
      },
    },
  },
  created() {
    this.load();
  },
};
</script>

<style scoped></style>
