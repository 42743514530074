<template>
  <div class="accordion-item">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["accordion"],
  data() {
    return {
      instance: this._uid,
      isOpen: this.open,
    };
  },
  provide() {
    const vm = this;
    const context = {
      toggle: () => vm.toggle(),
    };

    Object.defineProperty(context, "isOpen", {
      get: () => this.isOpen,
      enumerable: true,
    });

    return {
      context,
    };
  },
  computed: {
    current() {
      return this.accordion.current;
    },
  },
  watch: {
    current(newVal) {
      if (this.accordion.mode === "always-open") {
        return;
      }
      if (newVal !== this.instance && this.isOpen) {
        this.isOpen = false;
      }
    },
  },
  methods: {
    toggle() {
      const newVal = !this.isOpen;
      if (newVal) {
        this.accordion.set(this.instance);
      }

      this.isOpen = newVal;
    },
  },
  created() {
    if (this.open) {
      this.accordion.set(this.instance);
    }
  },
};
</script>

<style lang="scss" scoped>
.accordion-item {
  display: block;
  border-bottom: 1px solid var(--gray-20);
}
</style>
