<template>
  <div>
    <NbModal
      :id="id"
      :preventClose="true"
      modalConfig="modal-dialog-centered"
      width="76rem"
    >
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ `${$t("statusCheckpointsPageView.title")} ${itemId}` }}
          </h5>
        </div>
      </template>
      <div>
        <NbCard
          id="checkpoint_chard"
          :title="$t('generalInformation')"
          class="bg-gray-05"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="checkpoint-status_code"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-100 my-2"
                :name="$t('statusCheckpointsPage.fields.statusCode')"
                v-model="checkpoint.status_code"
                disabled
              />
              <NbTextInput
                id="checkpoint-tracking_function"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('statusCheckpointsPage.fields.trackingFunction')"
                v-model="checkpoint.tracking_function"
                disabled
              />
              <NbTextInput
                id="checkpoint-checkpoint_id"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('statusCheckpointsPage.fields.checkpoint')"
                v-model="checkpoint.checkpoint_id"
                disabled
              />
            </div>
          </template>
        </NbCard>
        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
            @click="resetQuery()"
          >
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import StatusCheckpointService from "@/services/StatusCheckpointService";

const statusCheckpointService = new StatusCheckpointService();

export default {
  components: { NbModal, NbButton, NbTextInput, NbCard, NbFooter },
  name: "ModalStatusCheckpointsView",
  props: {
    id: {
      type: String,
      default: "ModalStatusCheckpointsView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      checkpoint: {},
    };
  },
  created() {},
  computed: {},
  methods: {
    resetQuery() {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({ query: {} });
        return;
      }
    },
  },
  watch: {
    itemId(newVal) {
      if (newVal) {
        statusCheckpointService.getStatusCheckpoint(newVal).then((response) => {
          this.checkpoint = response.data.data;
        });
      }
    },
  },
};
</script>

<style scoped></style>
