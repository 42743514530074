<template>
  <ul :class="['special-link mt-4', { expanded }]">
    <li>
      <router-link to="/hscodequery">
        <NbIcon class="icon" icon="tag" :size="iconSize" />
        <span>{{ $t("components.nav.SearchHsCode") }}</span>
      </router-link>
    </li>

    <li>
      <router-link to="/archives">
        <NbIcon class="icon" icon="arrow-down" :size="iconSize" />
        <span>{{ $t("components.nav.Downloads") }}</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
import NbIcon from "./icons/NbIcon.vue";

export default {
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
  },
  components: { NbIcon },
  data() {
    return {
      iconSize: 16,
    };
  },
};
</script>

<style lang="scss" scoped>
.special-link {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  a {
    color: var(--white);
    width: 2rem;
    display: flex;
    justify-content: center;
    padding: 0.4rem 0 0.4rem 0;
    margin: 0 1rem;
    border: 1px solid var(--white);
    border-radius: 4px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    &.router-link-exact-active {
      background-color: var(--primaryActive);
      border-color: var(--primaryActive);
    }
    .icon {
      min-width: 2rem;
      text-align: center;
    }
    span:last-child {
      display: none;
    }
  }
  &.expanded {
    a {
      width: auto;
      justify-content: flex-start;
      span:last-child {
        display: flex;
      }
    }
  }
}
</style>
