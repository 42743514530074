export default {
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_TOTAL_ROWS(state, payload) {
    state.totalRows = payload;
  },
  SET_CURRENT_PAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_LIMIT(state, payload) {
    state.limit = payload;
  },
  SET_FILTERS(state, payload) {
    state.filters = payload;
  },
};
