<template>
  <div>
    <header-title :title="title" />
    <div class="row justify-content-center">
      <div class="col-12 mb-5">
        <div class="card border-white rounded py-4">
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('nobordistLogsViewPage.action')"
              :val="log.action"
            />
            <field-view
              :field="$t('nobordistLogsViewPage.userName')"
              :val="userName"
              :link="`/users_management/users?id=${log.user_id}`"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('nobordistLogsViewPage.creationDate')"
              :val="log.created_at"
            />
            <field-view
              :field="$t('nobordistLogsViewPage.updateDate')"
              :val="log.updated_at"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('nobordistLogsViewPage.logs')"
              :val="log.log"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogService from "../../../services/LogService";
import UsersService from "../../../services/UsersService";
import HeaderTitle from "@/components/HeaderTitle";
import FieldView from "@/components/FieldView";

const logService = new LogService();
const usersService = new UsersService();

export default {
  name: "NobordistLogsView",

  components: {
    HeaderTitle,
    "field-view": FieldView,
  },
  data: () => {
    return {
      log: {},
      userName: "",
    };
  },
  computed: {
    title() {
      return this.$t("nobordistLogsViewPage.title") + this.$route.params.id;
    },
  },
  beforeMount() {
    this.loadCurrentLog();
    /* this.loadCarriers();
            this.loadCountries(); */
  },
  methods: {
    addZero(number) {
      if (number <= 9) return "0" + number;
      else return number;
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    loadCurrentLog() {
      logService.getLog(this.$route.params.id).then((response) => {
        this.log = response.data.data;
        const time = this.log.created_at;
        const date = this.log.created_at;
        const updated = this.log.updated_at;
        const updatedTime = this.log.updated_at;
        this.log.created_at = this.formateDate(date, time);
        this.log.updated_at = this.formateDate(updated, updatedTime);
        if (this.log.user_id) {
          this.loadCurrentUser(this.log.user_id);
        }
      });
    },
    loadCurrentUser(id) {
      usersService.getUser(id).then((response) => {
        this.userName = response.data.data.name;
      });
    },
  },
};
</script>
