<template>
  <div class="result">
    <h3 class="heading-3">
      {{ title }}
    </h3>

    <div class="d-flex flex-direction-column flex-direction-md-row">
      <table>
        <thead>
          <slot name="header"></slot>
        </thead>
        <tbody>
          <slot name="body"></slot>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  thead {
    background-color: transparent;
    border: none;
    &:after {
      border: none;
    }
    th {
      text-align: left;
      padding: 0 1rem;
    }
  }
  tbody {
    tr {
      background-color: var(--gray-10);
      td {
        text-align: left;
        padding: 0.5rem 1rem;
        font-weight: 500;
        font-size: 0.85rem;
        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}
</style>
