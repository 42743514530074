export const Variables = {
  VOLUME: 1,
  ITEM: 2,
  DAYS_KG: 3,
  KILO: 4,
};

export const VariableTypes = {
  FLAT: 0,
  PERCENT: 1,
};
