<template>
  <div class="grid grid-cols-2 gap-4">
    <router-link :to="`/shipping/orders?id=${volume.order_id}`" class="link-1">
      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('order')"
        v-model="volume.order.order_number"
        id="related.order"
      />
    </router-link>
    <router-link
      :to="`/users_management/sellers?id=${volume.order.seller_id}`"
      class="link-1"
    >
      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('seller')"
        v-model="volume.order.seller_name"
        id="related.seller"
      />
    </router-link>
    <router-link
      :to="`/contracts/contracts?id=${volume.order.contract_id}`"
      class="link-1"
    >
      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('contract')"
        v-model="volume.order.contract_id"
        id="related.contract"
      />
    </router-link>

    <NbInputView :name="$t('volumesPage.fields.currentStatus')">
      <div>
        <router-link
          v-if="volume.current_status?.id"
          :to="`/checkpoints/checkpoints?nobordistCheckpointId=${volume.current_status?.id}`"
          class="link-1"
        >
          <span>
            {{ volume.current_status.title }}
          </span>
        </router-link>
        <div v-else class="input-view d-flex">
          <span>-</span>
        </div>
      </div>
    </NbInputView>

    <NbInputView :name="$t('masterbox')">
      <div>
        <router-link
          v-if="masterbox?.id"
          :to="`/shipping/masterboxes?id=${volume.masterbox_id}`"
          class="link-1"
        >
          <span>
            {{ masterbox?.reference }}
          </span>
        </router-link>
        <div v-else class="input-view d-flex">
          <span>-</span>
        </div>
      </div>
    </NbInputView>

    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('volumesPage.fields.salesChannel')"
      v-model="volume.order.sales_channel_name"
      id="sales_channel"
      placeholder="-"
    />

    <NbInputView :name="$t('flight')">
      <div>
        <router-link
          v-if="flight?.id"
          :to="`/shipping/flights?id=${flight.id}`"
          class="link-1"
        >
          <span>
            {{ flight.mawb_number || $t("volumesViewPage.withoutMAWBNumber") }}
          </span>
        </router-link>
        <div v-else class="input-view d-flex">
          <span>-</span>
        </div>
      </div>
    </NbInputView>

    <div class="col-span-2">
      <h3 class="heading-3">{{ $t("actions") }}</h3>
      <div class="d-flex gap-4">
        <NbButton
          v-if="volume?.last_mile_tracking_number"
          @click="
            navigateTo(
              `/logs/alert_logs?last_mile_tracking_number=${volume.last_mile_tracking_number}`,
            )
          "
          class="d-flex gap-2 align-items-center"
        >
          <NbIcon icon="terminal" :size="18" />
          {{ $t("volumesViewPage.seeLogs") }}
        </NbButton>

        <NbButton
          v-if="volumeReverse?.id"
          @click="
            navigateTo(`/shipping/volumes_reverse?id=${volumeReverse.id}`)
          "
          class="d-flex gap-2 align-items-center"
        >
          <NbIcon icon="refresh-cw" :size="18" />
          {{ $t("volumesViewPage.seeVolumeReverse") }}
        </NbButton>
      </div>
    </div>
  </div>
</template>

<script>
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbInputView from "@/components/input/text/NbInputView.vue";

export default {
  props: {
    volume: {
      type: Object,
      required: true,
    },
    volumeReverse: {
      type: [Object, null],
      default: () => null,
    },
    flight: {
      type: [Object, null],
      default: () => null,
    },
    masterbox: {
      type: [Object, null],
      default: () => null,
    },
  },
  components: {
    NbTextInput,
    NbButton,
    NbIcon,
    NbInputView,
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped></style>
