<template>
  <svg
    id="number_ball"
    data-name="number ball"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect id="base" width="24" height="24" fill="#fff" opacity="0" />
    <g id="number">
      <text
        id="_1"
        data-name="1"
        transform="translate(12 16)"
        fill="#000014"
        font-size="12"
        font-family="NunitoSans-Bold, Nunito Sans"
        font-weight="700"
        letter-spacing="0.032em"
      >
        <tspan x="-3" y="0">{{ number }}</tspan>
      </text>
      <path
        id="curves"
        d="M8.5-1A9.5,9.5,0,1,1-1,8.5,9.511,9.511,0,0,1,8.5-1Zm0,17A7.5,7.5,0,1,0,1,8.5,7.509,7.509,0,0,0,8.5,16Z"
        transform="translate(3.5 3.5)"
        fill="#9696aa"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    number: {
      type: [String, Number],
      require: false,
    },
  },
};
</script>
