const addZero = function (number) {
  if (number <= 9) {
    return "0" + number;
  } else {
    return number;
  }
};

const formateDate = function (date) {
  let notZonedDate = date;
  if (typeof date === "string" && date.includes("Z")) {
    notZonedDate = date.slice(0, -1);
  }
  const data = new Date(notZonedDate);
  const formatedDate =
    addZero(
      data.getDate().toString() +
        "/" +
        addZero(data.getMonth() + 1).toString() +
        "/" +
        data.getFullYear(),
    ) +
    "  " +
    addZero(data.getHours()) +
    ":" +
    addZero(data.getMinutes()) +
    ":" +
    addZero(data.getSeconds());
  return formatedDate;
};

export function addTimeAndDate(payload) {
  payload.forEach((element, index) => {
    let date = element.created_at;
    const date_iso = element.date_iso;
    const run_at = element.run_at;
    const run_at_time = element.run_at;
    if (date) payload[index].created_at = formateDate(date);
    if (date_iso) payload[index].date_iso = formateDate(date_iso);
    if (element.beginning_date)
      payload[index].beginning_date = formateDate(element.beginning_date);
    if (element.end_date)
      payload[index].end_date = formateDate(element.end_date);
    payload[index].run_at = formateDate(run_at, run_at_time);
  });
  return payload;
}
