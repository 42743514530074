import api from "./HttpServiceUntoasted";
export default class UntoastedNotificationService {
  // SELLER NOTIFICATIONS
  /**
   * create seller_notification
   *
   * @param data
   * @returns {Promise}
   */
  addSellerNotification(data) {
    return api.post("/v1/seller_notifications", data);
  }

  /**
   * Get seller_notifications
   *
   * @param filters
   * @returns {Promise}
   */
  getSellerNotifications(filters = {}) {
    return api.get("/v1/seller_notifications?offset=0&limit=1000", {
      params: filters,
    });
  }

  /**
   * Get seller_notifications
   *
   * @param offset = number
   * @param track = obj filters to apply
   * @returns {Promise}
   */
  getTwentySellerNotifications(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/seller_notifications`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/seller_notifications?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  /**
   * Get seller_notifications list
   *
   * @param filters
   * @returns {Promise}
   */
  getSellerNotificationList(filters = {}) {
    return api.get("/v1/my_notifications?offset=0&limit=1000", {
      params: filters,
    });
  }

  /**
   * Mass Update user_notification
   *
   * @param {*} data
   * @returns {Promise}
   */
  massUpdateSellerNotification(data = {}) {
    return api.put("/v1/seller_notifications/massive_update", data);
  }

  /**
   * Get one seller_notification
   *
   * @param {*} id
   * @returns {Promise}
   */
  getOneSellerNotification(id) {
    return api.get("/v1/seller_notifications/" + id);
  }

  /**
   * Delete seller_notification
   *
   * @param {*} id
   * @returns {Promise}
   */
  deleteSellerNotification(id) {
    return api.delete("/v1/seller_notifications/" + id);
  }

  /**
   * Update seller_notification
   *
   * @param {*} id
   * @param {*} data
   * @returns {Promise}
   */
  updateSellerNotification(id = null, data = {}) {
    return api.put("/v1/seller_notifications/" + id, data);
  }

  /**
   * Get seller_notifications titles
   *
   * @returns {Promise}
   */
  getSellerNotificationsTitles() {
    return api.get("/v1/my_notifications/fetch_titles");
  }

  updateNotificationIsReadseller_notifications(data = {}) {
    return api.put("/v1/seller_notifications/massive_update", data);
  }

  // USER NOTIFICATIONS
  /**
   * create user_notification
   *
   * @param data
   * @returns {Promise}
   */
  addUserNotification(data) {
    return api.post("/v1/user_notifications", data);
  }

  /**
   * Get user_notifications
   *
   * @param filters
   * @returns {Promise}
   */
  getUserNotifications(filters = {}) {
    return api.get("/v1/user_notifications?offset=0&limit=1000", {
      params: filters,
    });
  }

  /**
   * Get user_notifications
   *
   * @param offset = number
   * @param track = obj filters to apply
   * @returns {Promise}
   */
  getTwentyUserNotifications(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/user_notifications`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/user_notifications?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  /**
   * Get one user_notification
   *
   * @param {*} id
   * @returns {Promise}
   */
  getOneUserNotification(id) {
    return api.get("/v1/user_notifications/" + id);
  }

  /**
   * Delete user_notification
   *
   * @param {*} id
   * @returns {Promise}
   */
  deleteUserNotification(id) {
    return api.delete("/v1/user_notifications/" + id);
  }

  /**
   * Update user_notification
   *
   * @param {*} id
   * @param {*} data
   * @returns {Promise}
   */
  updateUserNotification(id = null, data = {}) {
    return api.put("/v1/user_notifications/" + id, data);
  }

  /**
   * Get user_notifications list
   *
   * @param filters
   * @returns {Promise}
   */
  getUserNotificationList(filters = {}) {
    return api.get("/v1/notifications?offset=0&limit=1000", {
      params: filters,
    });
  }

  /**
   * Mass Update user_notification
   *
   * @param {*} data
   * @returns {Promise}
   */
  massUpdateUserNotification(data = {}) {
    return api.put("/v1/user_notifications/massive_update", data);
  }

  /**
   * Get user_notifications titles
   *
   * @returns {Promise}
   */
  getUserNotificationsTitles() {
    return api.get("/v1/notifications/fetch_titles");
  }

  updateNotificationIsReaduser_notifications(data = {}) {
    return api.put("/v1/user_notifications/massive_update", data);
  }
}
