<template>
  <NbCard
    id="typography"
    class="box"
    :title="$t('accessibilitySettingsPage.typography')"
  >
    <template #body>
      <p class="body-3">
        {{ $t("accessibilitySettingsPage.textPreferenceDescription") }}
      </p>
      <div class="sizes-container">
        <h2 class="heading-2">
          {{ $t("accessibilitySettingsPage.textSize") }}
        </h2>

        <div class="sizes">
          <div v-for="item in sizes" :key="item" class="size">
            <input
              type="radio"
              v-model="size"
              :id="'size-' + item"
              :value="item"
            />
            <label :for="'size-' + item" class="m-0"> {{ item }}% </label>
          </div>
        </div>
      </div>
    </template>
  </NbCard>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";

export default {
  name: "NbTypographies",
  computed: {
    sizes() {
      return this.$store.state.accessibility.sizes;
    },
    size: {
      get() {
        return this.$store.state.accessibility.size;
      },
      set(value) {
        this.$store.dispatch("accessibility/handleSize", value);
      },
    },
  },
  components: { NbCard },
};
</script>

<style lang="scss" scoped>
.sizes-container {
  .sizes {
    display: grid;
    gap: 1rem;
    @media screen and (min-width: 750px) {
      grid-template-columns: repeat(3, 1fr);
    }

    .size {
      label {
        width: 100%;
        background-color: var(--gray-05);
        font-weight: bold;
        padding: 0.5rem;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s;
        font-size: 0.75rem;
      }
      input[type="radio"] {
        appearance: none;
        visibility: hidden;
        &:checked + label {
          background-color: var(--primary);
          color: #f7f7fc;
        }
      }
    }
  }
}
</style>
