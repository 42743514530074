<template>
  <div class="d-inline-block my-1">
    <b-button
      id="popover-reactive"
      ref="button"
      pill
      variant="secondary"
      class="btn-sm px-3"
    >
      {{ val > 0 ? val : " - " }} ▾
    </b-button>
    <b-popover
      ref="popover"
      target="popover-reactive"
      triggers="click"
      :title="$t('components.itensPerPage')"
      placement="auto"
      @show="onShow"
      @shown="onShown"
    >
      <template #title>
        <b-button class="close" aria-label="Close" @click="onClose">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        {{ $t("components.itensPerPage") }}
      </template>
      <div class="text-center">
        <input
          id="popover-input"
          ref="input"
          v-model="val"
          class="form-control mb-2"
          style="max-width: 120px"
          type="tel"
          maxlength="3"
          autocomplete="off"
          required
          @keyup.enter="saveItemsPerPage"
        />
        <b-button variant="primary" @click="saveItemsPerPage">{{
          $t("save")
        }}</b-button>
      </div>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "ItemsPerPage",
  props: {
    perPage: {
      type: [String, Number],
      required: true,
      default: 20,
    },
  },
  data: () => {
    return {
      itemsPerPage: {},
      val: null,
    };
  },
  beforeMount() {
    this.val = this.perPage;
  },
  methods: {
    onClose() {
      this.$root.$emit("bv::hide::popover");
    },
    onShow() {
      this.onClose();
    },
    onShown() {
      this.$refs.input.focus();
      this.$refs.input.select();
    },
    saveItemsPerPage() {
      this.onClose();
      if (this.val > 0) {
        this.val = parseInt(this.$refs.input.value);
      } else {
        this.val = 15;
      }
      this.$emit("emitValue", this.val);
    },
  },
};
</script>
