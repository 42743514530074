import api from "./HttpService";

export default class TrackingRuleNotificationsService {
  create(data) {
    return api.post(`/v1/tracking_notification_rules`, data);
  }

  update(id, data) {
    return api.put(`/v1/tracking_notification_rules/${id}`, data);
  }

  destroy(id) {
    return api.delete(`/v1/tracking_notification_rules/${id}`);
  }

  toggleActivation(id, data) {
    return api.put(
      `/v1/tracking_notification_rules/${id}/toggle_activation`,
      data,
    );
  }

  getRules(filters = {}) {
    return api.get("/v1/tracking_notification_rules", { params: filters });
  }

  getById(id) {
    return api.get(`/v1/tracking_notification_rules/${id}`);
  }

  checkRuleName(params) {
    return api.get(`/v1/check_rule_name`, {
      params,
    });
  }
}
