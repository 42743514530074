<template>
  <NbCard id="actions" :title="$t('actions')" class="col-span-2">
    <template #body>
      <div class="grid grid-cols-2 gap-4">
        <NbButton
          variant="primary"
          class="align-items-center d-flex"
          @click="navigateTo(`/shipping/orders?id=${volume.order.id}`)"
        >
          <NbIcon :size="18" class="mr-2" icon="shopping-bag" />
          {{ $t("volumesViewPage.goToOrder") }}
        </NbButton>
        <NbButton
          v-if="volume?.order?.seller_id"
          variant="primary"
          class="align-items-center d-flex"
          @click="
            navigateTo(`/users_management/sellers?id=${volume.order.seller_id}`)
          "
        >
          <NbIcon :size="18" class="mr-2" icon="user" />
          {{ $t("volumesViewPage.goToSeller") }}
        </NbButton>
        <NbButton
          v-if="volume?.order?.contract_id"
          variant="primary"
          class="align-items-center d-flex"
          @click="
            navigateTo(
              `/contracts/contracts?contractId=${volume.order.contract_id}`,
            )
          "
        >
          <NbIcon :size="18" class="mr-2" icon="shield" />
          {{ $t("volumesViewPage.goToContract") }}
        </NbButton>
        <NbButton
          v-if="volume?.masterbox_id"
          variant="primary"
          class="align-items-center d-flex"
          @click="navigateTo(`/shipping/masterboxes?id=${volume.masterbox_id}`)"
        >
          <NbIcon :size="18" class="mr-2" icon="package" />
          {{ $t("volumesViewPage.goToMasterbox") }}
        </NbButton>
        <NbButton
          v-if="flightId"
          variant="primary"
          class="align-items-center d-flex"
          @click="navigateTo(`/shipping/flights?id=${flightId}`)"
        >
          <NbIcon :size="18" class="mr-2" icon="navigation" />
          {{ $t("volumesViewPage.goToFlight") }}
        </NbButton>
        <NbButton
          v-if="volume?.order?.reference"
          variant="primary"
          class="align-items-center d-flex"
          @click="goToTrackingPage"
        >
          <NbIcon :size="18" class="mr-2" icon="truck" />
          {{ $t("volumesViewPage.goToTracking") }}
        </NbButton>
        <NbButton
          v-if="volumeReverseId"
          variant="primary"
          class="align-items-center d-flex"
          @click="navigateTo(`/shipping/volumes_reverse?id=${volumeReverseId}`)"
        >
          <NbIcon :size="18" class="mr-2" icon="refresh-cw" />
          {{ $t("volumesViewPage.goToVolumeReverse") }}
        </NbButton>
        <NbButton
          v-if="volume?.last_mile_tracking_number"
          variant="primary"
          class="align-items-center d-flex"
          @click="
            navigateTo(
              `/logs/alert_logs?last_mile_tracking_number=${volume.last_mile_tracking_number}`,
            )
          "
        >
          <NbIcon :size="18" class="mr-2" icon="activity" />
          {{ $t("volumesViewPage.seeTrackingAlertLogs") }}
        </NbButton>
      </div>
    </template>
  </NbCard>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  props: {
    volume: {
      type: Object,
      required: true,
    },
    volumeReverseId: {
      type: [Number, String, undefined],
      default: "",
    },
    flightId: {
      type: [Number, String, undefined],
      default: "",
    },
  },

  components: {
    NbCard,
    NbButton,
    NbIcon,
  },

  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
    goToTrackingPage() {
      const trackingURL = this.$helpers.buildTrackingURL(
        this.volume.order.reference,
      );
      window.open(trackingURL, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped></style>
