<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("sellersViewPage.allowedContracts") }}
      </ModalTitle>
    </ModalHeader>

    <div>
      <p class="body-4">
        {{ $t("sellersViewPage.wantAllowContract") }}
        <strong
          ><u>{{ contract.name }}</u></strong
        >
        {{ $t("sellersViewPage.forSeller") }}
        <strong
          ><u>{{ this.seller.name }}?</u></strong
        >
      </p>
      <p class="body-4 m-0">
        {{ $t("sellersViewPage.needsSupervisorApproval") }}
      </p>
      <p class="body-4">{{ $t("sellersViewPage.chooseSendRequest") }}</p>

      <div>
        <div v-for="admin in admins" :key="admin.value">
          <NbCheckbox
            v-model="admin.selected"
            :name="admin.text"
            :id="`admin-${admin.value}`"
          />
        </div>
      </div>
    </div>

    <ModalFooter class="d-flex justify-content-end gap-2">
      <ModalClose />
      <NbButton
        :disabled="loading || !selectedAdminIds.length"
        @click="addToChoosenContracts"
      >
        {{ $t("confirm") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import PendingActionService from "@/services/PendingActionService";
import SellerService from "@/services/SellerService";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";

const pendingActionService = new PendingActionService();
const sellersService = new SellerService();

export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },
    seller: {
      type: Object,
      required: true,
    },
  },
  inject: ["context"],
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbCheckbox,
  },
  data() {
    return {
      admins: [],
      loading: false,
    };
  },
  computed: {
    selectedAdminIds() {
      return this.admins
        .filter((data) => data.selected)
        .map((item) => item.value);
    },
  },
  methods: {
    async addToChoosenContracts() {
      try {
        this.loading = true;
        await sellersService.addContract(
          {
            contract_id: this.contract.id,
            approver_ids: this.selectedAdminIds,
            action_type: "permit",
          },
          this.seller.id,
        );
        this.$emit("success", this.contract.id);
        this.context?.close();
      } finally {
        this.loading = false;
      }
    },
    async loadAdmins() {
      try {
        this.loadingAdming = true;
        const { data } = await pendingActionService.fetchAdminUsers();
        this.admins = data?.data.map((admin) => ({
          text: admin.name,
          value: admin.id,
          selected: false,
        }));
      } finally {
        this.loadingAdming = false;
      }
    },
  },
  beforeMount() {
    this.loadAdmins();
  },
};
</script>

<style lang="scss" scoped></style>
