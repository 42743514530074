<template>
  <div>
    <div class="dimensions">
      <svg
        width="155"
        height="158"
        viewBox="0 0 155 158"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_4_93)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.80875 110.848L97.1681 123L133.174 99.761L133.672 11.2948L50.486 0L2.80875 21.5396V110.848Z"
            fill="#C28F60"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M133.672 11.2948L96.8381 34.2616L2.80875 21.5396L50.486 0L133.672 11.2948Z"
            fill="#AA7950"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M97.1836 123L96.8381 34.2617L133.672 11.2948L134.809 100.108L97.1836 123Z"
            fill="#D2A06D"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M67.2 2.26923L94.4482 5.96826L55.4437 29.1739L55.4205 69.3396L41.5425 59.929L27.6619 67.7404L29.3944 25.5672L67.2 2.26923Z"
            fill="#65472F"
          />
        </g>
        <path
          d="M147.516 7.29289C147.125 6.90237 146.492 6.90237 146.102 7.29289L139.738 13.6569C139.347 14.0474 139.347 14.6805 139.738 15.0711C140.128 15.4616 140.761 15.4616 141.152 15.0711L146.809 9.41422L152.466 15.0711C152.856 15.4616 153.489 15.4616 153.88 15.0711C154.27 14.6805 154.27 14.0474 153.88 13.6569L147.516 7.29289ZM147.809 94L147.809 8L145.809 8L145.809 94L147.809 94Z"
          fill="#65472F"
        />
        <path
          d="M149.137 109.168C149.238 108.625 148.88 108.103 148.337 108.002L139.488 106.357C138.945 106.256 138.423 106.615 138.323 107.158C138.222 107.701 138.58 108.223 139.123 108.323L146.988 109.785L145.527 117.651C145.426 118.194 145.784 118.716 146.327 118.816C146.87 118.917 147.392 118.559 147.493 118.016L149.137 109.168ZM106.748 138.625L148.72 109.809L147.588 108.16L105.616 136.976L106.748 138.625Z"
          fill="#65472F"
        />
        <path
          d="M85.5508 137.686C85.9939 137.356 86.0858 136.729 85.7561 136.286L80.3835 129.066C80.0538 128.623 79.4273 128.531 78.9843 128.861C78.5412 129.19 78.4492 129.817 78.7789 130.26L83.5546 136.678L77.1364 141.454C76.6933 141.783 76.6014 142.41 76.9311 142.853C77.2608 143.296 77.8872 143.388 78.3303 143.058L85.5508 137.686ZM0.85482 125.554L84.8086 137.873L85.099 135.894L1.14518 123.575L0.85482 125.554Z"
          fill="#65472F"
        />
        <defs>
          <clipPath id="clip0_4_93">
            <rect
              width="132"
              height="123"
              fill="white"
              transform="translate(2.80875)"
            />
          </clipPath>
        </defs>
      </svg>

      <div class="info weight">
        <div class="weight-wrapper">
          <span>{{ toKilogram(weight) }}</span>
          <NbHelpText
            v-if="description"
            id="weight-popover"
            class="mx-1"
            size="sm"
            placement="topright"
          >
            {{ description }}
          </NbHelpText>
        </div>
      </div>

      <div class="info width">
        <div>
          <small>{{ $t("volumesPage.fields.Width") }}</small>
          <NbHelpText
            v-if="description"
            id="width-popover"
            class="mx-1"
            size="sm"
            placement="topright"
          >
            {{ description }}
          </NbHelpText>
        </div>
        <span>{{ toCentimeter(width) }}</span>
      </div>

      <div class="info height">
        <div>
          <small>{{ $t("volumesPage.fields.height") }}</small>
          <NbHelpText
            v-if="description"
            id="height-popover"
            class="mx-1"
            size="sm"
            placement="topright"
          >
            {{ description }}
          </NbHelpText>
        </div>
        <span>{{ toCentimeter(height) }}</span>
      </div>

      <div class="info depth">
        <div>
          <small>{{ $t("volumesPage.fields.length") }}</small>
          <NbHelpText
            v-if="description"
            id="depth-popover"
            class="mx-1"
            size="sm"
            placement="topright"
          >
            {{ description }}
          </NbHelpText>
        </div>
        <span>{{ toCentimeter(depth) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import NbHelpText from "@/components/generic/NbHelpText.vue";

export default {
  props: {
    unitMeasurement: {
      type: String,
      default: "cm",
    },
    unitWeight: {
      type: String,
      default: "kg",
    },
    height: {
      type: [String, null],
      default: () => null,
    },
    width: {
      type: [String, null],
      default: () => null,
    },
    depth: {
      type: [String, null],
      default: () => null,
    },
    weight: {
      type: [String, null],
      default: () => null,
    },
    description: {
      type: String,
      default: "",
    },
  },
  components: {
    NbHelpText,
  },
  methods: {
    toCentimeter(val) {
      if (val === null) {
        return this.$t("none");
      }
      return new Intl.NumberFormat("pt-BR", {
        style: "unit",
        unit: "centimeter",
      }).format(val);
    },
    toKilogram(val) {
      if (val === null) {
        return this.$t("none");
      }

      return new Intl.NumberFormat("pt-BR", {
        style: "unit",
        unit: "kilogram",
      }).format(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.dimensions {
  position: relative;
  width: fit-content;
  padding: 1.5rem 1rem;
}

.info {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 6.25rem;
  small {
    font-size: 0.75rem;
    font-weight: 700;
  }
  span {
    font-size: 0.675rem;
    line-height: 1;
  }
}

.width {
  left: 1rem;
  bottom: 1rem;
  transform: rotate(8deg);
}

.depth {
  right: -1.8rem;
  bottom: 2rem;
  transform: rotate(-33deg);
}

.height {
  right: -5.5rem;
  top: 3rem;
}

.weight {
  top: 50%;
  left: 1.5rem;
  transform: rotate(8deg);
  span {
    font-size: 0.875rem;
    color: #65472f;
    font-weight: 700;
    display: block;
  }
  .weight-wrapper {
    width: 5.25rem;
    display: flex;
    justify-content: center;
    word-break: break-all;
  }
}
</style>
