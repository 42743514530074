<template>
  <div class="timeline-event">
    <div class="event-heading">
      <slot>
        <h3 class="heading-3">{{ title }}</h3>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-event {
  width: var(--timeline-gap);
  display: flex;
  justify-content: center;
}
</style>
