<template>
  <div>
    <label v-if="label" :for="label">{{ label }}</label>
    <cool-select
      :id="label"
      v-model="val"
      :items="addIdSearch"
      :placeholder="val ? '' : placehol"
      :class="{ 'is-invalid': errors[errorTarget] }"
      :item-value="itemValue"
      :item-text="itemText"
      required
      @change="emitChanges()"
    >
      <!-- slot for each item in the menu -->
      <template slot="item" slot-scope="{ item: data }">
        <div>
          <div class="row">
            <div
              v-if="(showId || id) && !((showId || id) && (showName || name))"
              class="col-1"
            >
              <span> ({{ data.id }}) </span>
            </div>
            <div v-if="showTrackingCode || trackingCode" class="col-2">
              <span>
                {{ data.tracking_code }}
              </span>
            </div>
            <div v-if="showName || name" class="col">
              <span>
                {{ data.name }}
              </span>
            </div>
            <div v-if="showSku || sku" class="col">
              <span>
                {{ data.sku }}
              </span>
            </div>
            <div v-if="showTitle || title" class="col">
              <span>
                {{ data.title }}
              </span>
            </div>
          </div>
        </div>
      </template>
      <!-- slot for the selected item (in the text field) -->
      <template slot="selection" slot-scope="{ item: data }">
        <div class="row">
          <div
            v-if="(showId || id) && !((showId || id) && (showName || name))"
            class="col-1"
          >
            <span> ({{ data.id }}) </span>
          </div>
          <div v-if="showTrackingCode || trackingCode" class="col-2">
            <span>
              {{ data.tracking_code }}
            </span>
          </div>
          <div v-if="showName || name" class="col">
            <span>
              {{ data.name }}
            </span>
          </div>
          <div v-if="showSku || sku" class="col">
            <span>
              {{ data.sku }}
            </span>
          </div>
          <div v-if="showTitle || title" class="col">
            <span>
              {{ data.title }}
            </span>
          </div>
        </div>
      </template>
    </cool-select>
    <div
      v-for="(error, index) in errors[errorTarget]"
      :key="index"
      class="invalid-feedback"
    >
      {{ error }}
    </div>
  </div>
</template>
<script>
import { CoolSelect } from "vue-cool-select";

export default {
  name: "SelectWithFilter",
  components: {
    "cool-select": CoolSelect,
  },
  props: {
    valEdit: {
      type: [String, Number, Array],
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    target: {
      type: String,
      required: false,
      default: "",
    },
    placehol: {
      type: String,
      required: false,
      default: "",
    },
    errorTarget: {
      type: String,
      required: false,
      default: "",
    },
    itemValue: {
      type: String,
      required: false,
      default: "",
    },
    itemText: {
      type: String,
      required: false,
      default: "",
    },
    showId: {
      type: String,
      required: false,
      default: "",
    },
    showName: {
      type: String,
      required: false,
      default: "",
    },
    showSku: {
      type: String,
      required: false,
      default: "",
    },
    showTitle: {
      type: String,
      required: false,
      default: "",
    },
    showTrackingCode: {
      type: [Number, Boolean],
      required: false,
      default: false,
    },
    shows: {
      type: Array,
      default() {
        return [];
      },
    },
    items: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    itemsSort: {
      type: Object,
      required: false,
      default: () => {},
    },
    errors: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
  },
  data: () => {
    return {
      val: "",
      id: false,
      name: false,
      sku: false,
      title: false,
      trackingCode: false,
    };
  },
  computed: {
    addIdSearch() {
      let finalResult = [];
      //adiciona id na hora de busca junto do nome. Adicionar mais opções se usar id + sku por exemplo
      finalResult = this.items.map((item) => {
        let returnItem = item;
        if (this.id && this.name) {
          returnItem = { id: item.id, name: item.id + " - " + item.name };
        }
        return returnItem;
      });

      //sort items
      if (this.itemsSort && this.itemsSort.key) {
        if (this.itemsSort.flow && this.itemsSort.flow.includes("desc")) {
          finalResult.sort((a, b) =>
            a[this.itemsSort.key] > b[this.itemsSort.key] ? -1 : 1,
          );
          return finalResult;
        }
        finalResult.sort((a, b) =>
          a[this.itemsSort.key] > b[this.itemsSort.key] ? 1 : -1,
        );
      }

      return finalResult;
    },
  },
  watch: {
    val(value) {
      this.$emit("emitValue", value);
    },
    valEdit() {
      if (this.valEdit) {
        if (Array.isArray(this.valEdit)) {
          this.val = this.valEdit[0];
        } else {
          this.val = this.valEdit;
        }
      }
    },
  },
  beforeMount() {
    this.setAttributes();
    this.setEditingValue();
  },
  methods: {
    setAttributes() {
      this.id = this.shows.includes("id");
      this.name = this.shows.includes("name");
      this.sku = this.shows.includes("sku");
      this.title = this.shows.includes("title");
      this.trackingCode = this.shows.includes("trackingCode");
    },
    setEditingValue() {
      if (this.valEdit) {
        this.val = this.valEdit;
      }
    },
  },
};
</script>
