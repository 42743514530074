<template>
  <div class="col">
    <h6>
      {{ field }} <copy-clipboard v-if="copyable" :copy-val="val" />
      <button
        v-for="(action, index) in actions"
        :key="index"
        class="btn btn-a mx-1"
        :title="action.title"
      >
        <i
          :class="action.icon"
          class="fa-lg"
          @click="emitClick(action.actionName)"
          >{{ action.text }}</i
        >
      </button>
    </h6>
    <router-link
      v-if="redirectByName"
      :to="{
        name: redirectByName,
        params: { filteredVal: startFilter, namespace: namespace },
      }"
    >
      <!-- { key: val } deixar key dinamica thyago do futuro -->
      <div
        class="border rounded px-3 py-2"
        :class="disabled ? 'bg-light text-muted' : 'bg-white'"
      >
        {{ getCurrency(currency) }}{{ standardizeValue(val) }}
      </div>
    </router-link>
    <router-link
      v-else-if="link"
      :to="{
        path: `${link + linkId}`,
        query: { filteredVal: val, filteredKey: 'name' },
      }"
    >
      <div
        class="border rounded px-3 py-2"
        :class="disabled ? 'bg-light text-muted' : 'bg-white'"
      >
        {{ getCurrency(currency) }}{{ standardizeValue(val) }}
      </div>
    </router-link>
    <div
      v-else-if="val"
      class="border rounded px-3 py-2"
      :class="disabled ? 'bg-light text-muted' : 'bg-white'"
    >
      {{ getCurrency(currency) }}{{ standardizeValue(val) }}
    </div>
    <div
      v-else-if="val === false"
      class="border rounded bg-light text-muted px-3 py-2"
    >
      False
    </div>
    <div v-else class="border rounded bg-light text-muted px-3 py-2">
      {{ $t("no") }} {{ field }} {{ $t("found") }}
    </div>
  </div>
</template>

<script>
import CopyClipboard from "@/components/CopyClipboard";

export default {
  name: "FieldView",
  components: { CopyClipboard },

  props: {
    field: {
      type: String,
      required: true,
    },
    val: {
      type: [String, Array, Number, Boolean],
      default: null,
    },
    standardize: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
    linkId: {
      type: [String, Array, Number, Boolean],
      default: "",
    },
    copyable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    redirectByName: {
      type: String,
      default: null,
    },
    startFilter: {
      type: Object,
      default: () => {},
    },
    namespace: {
      type: String,
      default: "",
    },
    currency: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      /* disabled: false, */
    };
  },
  methods: {
    standardizeValue(val) {
      if (this.standardize) {
        const finalVal = parseFloat(val).toFixed(2);
        if (finalVal === "NaN") {
          return "0.00";
        }
        return finalVal;
      } else {
        return val;
      }
    },
    getCurrency(currency) {
      if (currency) {
        if (currency.toUpperCase() === "USD") {
          return "$ ";
        }
        return "R$ ";
      }
      return;
    },
    emitClick(eventToEmit) {
      this.$emit("action", eventToEmit);
    },
    async copyClipboard(value) {
      const stringifyValue = JSON.stringify(value);
      await navigator.clipboard.writeText(stringifyValue);
      this.$toasted.show(
        `${stringifyValue} is copied to your clipboard. Use CTRL+V to paste.`,
        {
          type: "success",
          theme: "bubble",
          duration: 4000,
        },
      );
    },
  },
};
</script>
