import api from "./HttpService";

export default class HsCodeService {
  /**
   * Get Autorization hs_code
   *
   * @param {*} hs_code
   * @returns {Promise}
   */

  getAuthHsCode(hs_code) {
    return api.get("/v1/hs_codes/" + hs_code);
  }
}
