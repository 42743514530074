import PassThrough from "../../components/PassThrough";
import SellerAccountEvents from "@/views/finances/seller_accounts/SellerAccountEvents.vue";
import FiscalPayments from "../../views/finances/fiscal_payments/FiscalPayments.vue";

const finances_seller_account_events = {
  path: "/finances/seller_account_events",
  name: "finances_seller_account_events",
  component: SellerAccountEvents,
  meta: {
    visible: true,
    label: "SellerAccounts",
    searchData: "sellerAccounts",
  },
};

const finances_fiscal_payments = {
  path: "/finances/fiscal_payments",
  name: "finances_fiscal_payments",
  component: FiscalPayments,
  meta: {
    visible: true,
    label: "FiscalPayments",
    searchData: "fiscalPayments",
  },
};

const finances_children = [
  finances_seller_account_events,
  finances_fiscal_payments,
];

const finances = {
  path: "/finances",
  name: "finances",
  component: PassThrough,
  meta: {
    visible: true,
    label: "Finances",
    icon: "dollar-sign",
  },
  children: finances_children,
};

export default finances;
