<template>
  <div class="form-wrapper">
    <div class="heading-5 mb-2 text-white">
      {{ platform !== "e-cross" ? $t("loginPage.welcome") + "!" : "TMS" }}
    </div>
    <p class="text-16 mb-2-5 text-white">
      {{ $t("loginPage.pleaseYourAcc") }}.
    </p>
    <form novalidate>
      <Alert
        v-if="errors.length > 0"
        class="mb-2"
        type="error"
        icon="far fa-times-circle"
        :message="errors[0]"
      />

      <NbTextInput
        id="email-login"
        v-model="user.email"
        required
        name="E-mail"
        class="mb-1-25 text-white"
        :placeholder="$t('placeholderEmail')"
      />
      <NbLoginPassInput
        id="password-login"
        v-model="user.password"
        required
        :name="$t('password')"
        class="mb-3 text-white"
        :placeholder="$t('password')"
      />

      <div class="d-flex justify-content-between align-items-center">
        <div class="">
          <a
            class="link-2 text-white"
            data-toggle="modal"
            data-target="#modalPassword"
            @click.prevent="openResetPasswordModal"
          >
            {{ $t("loginPage.forgotPassword") }}?
          </a>
        </div>
        <NbButton variant="secondary" @click.prevent="authenticate">
          <span v-if="loading">
            <span class="spinner-border spinner-border-sm"></span>
            {{ $t("loading") }}
          </span>
          <span v-if="!loading">{{ $t("loginPage.login") }} </span>
        </NbButton>
      </div>
    </form>
  </div>
</template>

<script>
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbLoginPassInput from "@/components/input/text/NbLoginPassInput.vue";
import Alert from "@/components/alerts/Alert.vue";
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  name: "LoginForm",
  components: {
    NbTextInput,
    NbLoginPassInput,
    Alert,
    NbButton,
  },
  props: {
    errors: {
      type: [Array, Object],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    platform: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      user: {
        email: "",
        password: "",
        agreement: false,
      },
    };
  },
  methods: {
    openResetPasswordModal() {
      this.$emit("openResetPasswordModal");
    },
    authenticate() {
      this.$emit("authenticate", this.user);
    },
  },
};
</script>
