<template>
  <div>
    <h3 class="heading-4 my-4">
      {{ $t("trackingRuleNotificationsPage.chooseSellers") }}
    </h3>

    <Spinner v-if="loading" class="mx-auto" size="lg" />

    <div v-else-if="!sellers.elements.length" class="mb-4">
      <p class="body-4">
        {{ $t("trackingRuleNotificationsPage.youDontHaveSeller") }}
      </p>

      <NbButton @click="navigateTo('/users_management/sellers')">
        {{ $t("trackingRuleNotificationsPage.goToSellersPage") }}
      </NbButton>
    </div>

    <div v-else class="my-4">
      <div class="d-flex gap-4 align-items-center mt-4 mb-2">
        <NbButton
          @click="selectAll"
          :variant="isAllSelected ? 'secondary' : 'primary'"
          :disabled="isAllSelected"
        >
          {{
            isAllSelected
              ? $t("trackingRuleNotificationsPage.allSelected")
              : $t("trackingRuleNotificationsPage.selectAll")
          }}
        </NbButton>
        <NbButton
          v-if="selectedSellerIds.length && !automaticallyAddRuleForNewSellers"
          variant="quaternary"
          @click="clear"
        >
          {{ $t("clear") }}
        </NbButton>
      </div>

      <div>
        <NbSelectInput
          v-if="isAllSelected"
          v-model="actionForNewSellers"
          :options="actionForNewSellersOptions"
          id="action-on-seller-creation"
          :name="$t('trackingRuleNotificationsPage.onSellerCreation')"
          style="margin-top: -16px"
          required
        />
        <small class="d-block" v-if="automaticallyAddRuleForNewSellers">
          <NbIcon icon="alert-circle" :size="16" class="text-danger" />
          {{ $t("trackingRuleNotificationsPage.toEditSellersList") }}
          <strong>
            "{{ $t("trackingRuleNotificationsPage.notAddThisRule") }}"
          </strong>
        </small>
      </div>

      <div class="my-4">
        <NbTextInput
          v-model="search"
          id="search"
          :placeholder="$t('trackingRuleNotificationsPage.searchByNameOrId')"
        />
        <div>
          <small v-if="selectedSellerIds.length">
            <strong class="text-primary">{{ selectedSellerIds.length }}</strong>
            {{ $t("trackingRuleNotificationsPage.sellersSelected") }}
          </small>
          <small v-else>
            {{ $t("trackingRuleNotificationsPage.noSellerSelected") }}
          </small>
        </div>
        <div class="sellers grid grid-cols-3 gap-2 mt-2">
          <div
            v-for="seller in filteredSellers"
            :key="seller.id"
            class="d-flex align-items-center gap-2"
          >
            <input
              v-model="selectedSellerIds"
              type="checkbox"
              :id="`seller-${seller.id}`"
              :value="seller.id"
              class="nb-checkbox"
              :disabled="automaticallyAddRuleForNewSellers"
            />
            <label
              :for="`seller-${seller.id}`"
              :class="[
                'input-label pointer',
                { disabled: automaticallyAddRuleForNewSellers },
              ]"
            >
              {{ seller.name }} - {{ seller.id }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loading" class="d-flex justify-content-between">
      <NbButton variant="secondary" @click="goBack()">
        {{ $t("back") }}
      </NbButton>
      <NbButton @click="goNext()" :disabled="!isValid">
        {{ $t("next") }}
      </NbButton>
    </div>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import Spinner from "@/components/Spinner.vue";

import SellerService from "@/services/SellerService.js";
const sellerService = new SellerService();

import { mapState, mapMutations } from "vuex";

const ADD_RULE_AUTOMATICALLY_ACTION = "auto_add_seller";
const ADD_RULE_MANUALLY_ACTION = "manual_add_seller";

export default {
  components: {
    NbButton,
    NbTextInput,
    NbSelectInput,
    NbIcon,
    Spinner,
  },
  inject: ["ruleContext"],
  data() {
    return {
      loading: false,
      search: "",
      sellers: {
        count: 0,
        elements: [],
        ids: [],
      },
      filteredSellers: [],
      searchTimeout: null,
    };
  },
  computed: {
    ...mapState({
      sellerIds: (state) => state.notificationRuleSettings.sellerIds,
      sellerCreation: (state) => state.notificationRuleSettings.sellerCreation,
      isAllSellersSelected: (state) =>
        state.notificationRuleSettings.isAllSellersSelected,
    }),
    selectedSellerIds: {
      get() {
        return this.sellerIds;
      },
      set(val) {
        this.updateSellerIds(val);
      },
    },
    actionForNewSellers: {
      get() {
        return this.sellerCreation;
      },
      set(val) {
        this.updateSellerCreation(val);
      },
    },
    isValid() {
      if (this.isAllSelected) {
        return !!this.sellerCreation;
      }

      if (!this.selectedSellerIds.length) {
        return false;
      }

      return true;
    },
    automaticallyAddRuleForNewSellers() {
      return this.actionForNewSellers === ADD_RULE_AUTOMATICALLY_ACTION;
    },
    actionForNewSellersOptions() {
      return [
        {
          text: this.$t("trackingRuleNotificationsPage.autoAddRule"),
          value: ADD_RULE_AUTOMATICALLY_ACTION,
        },
        {
          text: this.$t("trackingRuleNotificationsPage.notAddThisRule"),
          value: ADD_RULE_MANUALLY_ACTION,
        },
      ];
    },

    isAllSelected() {
      return this.selectedSellerIds.length === this.sellers.count;
    },
  },
  watch: {
    search() {
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => {
        this.setFilteredSellers();
      }, 500);
    },
    isAllSelected(newValue, oldValue) {
      if (!newValue) {
        this.actionForNewSellers = "";
      }

      if (oldValue !== newValue) {
        this.updateIsAllSellersSelected(newValue);
      }
    },
  },
  methods: {
    ...mapMutations("notificationRuleSettings", {
      updateSellerIds: "UPDATE_SELLER_IDS",
      updateSellerCreation: "UPDATE_SELLER_CREATION",
      updateApplyRuleTo: "UPDATE_APPLY_RULE_TO",
      updateIsAllSellersSelected: "UPDATE_IS_ALL_SELLERS_SELECTED",
    }),

    goBack() {
      this.$emit("back");
    },
    goNext() {
      this.$emit("next");
    },
    setFilteredSellers() {
      if (!this.search?.trim()) {
        this.filteredSellers = this.sellers.elements;
        return;
      }

      const regex = new RegExp(`${this.search}`, "i");
      this.filteredSellers = this.sellers.elements.filter(
        (seller) => regex.test(seller.name) || regex.test(seller.id),
      );
    },
    async loadSellers() {
      try {
        this.loading = true;
        const { data } = await sellerService.getSellers();
        this.sellers = data.data;
        this.setFilteredSellers();

        if (this.ruleContext?.ruleId && this.isAllSellersSelected) {
          this.selectAll();
        }
      } finally {
        this.loading = false;
      }
    },
    selectAll() {
      this.selectedSellerIds = this.sellers.ids;
    },
    clear() {
      this.selectedSellerIds = [];
    },
    navigateTo(path) {
      this.$router.push(path);
    },
  },
  created() {
    this.loadSellers();
  },
};
</script>

<style lang="scss" scoped>
.sellers {
  max-height: 500px;
  overflow-y: auto;
  label {
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
</style>
