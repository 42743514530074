var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('h6',[_vm._v(" "+_vm._s(_vm.field)+" "),(_vm.copyable)?_c('copy-clipboard',{attrs:{"copy-val":_vm.val}}):_vm._e(),_vm._l((_vm.actions),function(action,index){return _c('button',{key:index,staticClass:"btn btn-a mx-1",attrs:{"title":action.title}},[_c('i',{staticClass:"fa-lg",class:action.icon,on:{"click":function($event){return _vm.emitClick(action.actionName)}}},[_vm._v(_vm._s(action.text))])])})],2),(_vm.redirectByName)?_c('router-link',{attrs:{"to":{
      name: _vm.redirectByName,
      params: { filteredVal: _vm.startFilter, namespace: _vm.namespace },
    }}},[_c('div',{staticClass:"border rounded px-3 py-2",class:_vm.disabled ? 'bg-light text-muted' : 'bg-white'},[_vm._v(" "+_vm._s(_vm.getCurrency(_vm.currency))+_vm._s(_vm.standardizeValue(_vm.val))+" ")])]):(_vm.link)?_c('router-link',{attrs:{"to":{
      path: `${_vm.link + _vm.linkId}`,
      query: { filteredVal: _vm.val, filteredKey: 'name' },
    }}},[_c('div',{staticClass:"border rounded px-3 py-2",class:_vm.disabled ? 'bg-light text-muted' : 'bg-white'},[_vm._v(" "+_vm._s(_vm.getCurrency(_vm.currency))+_vm._s(_vm.standardizeValue(_vm.val))+" ")])]):(_vm.val)?_c('div',{staticClass:"border rounded px-3 py-2",class:_vm.disabled ? 'bg-light text-muted' : 'bg-white'},[_vm._v(" "+_vm._s(_vm.getCurrency(_vm.currency))+_vm._s(_vm.standardizeValue(_vm.val))+" ")]):(_vm.val === false)?_c('div',{staticClass:"border rounded bg-light text-muted px-3 py-2"},[_vm._v(" False ")]):_c('div',{staticClass:"border rounded bg-light text-muted px-3 py-2"},[_vm._v(" "+_vm._s(_vm.$t("no"))+" "+_vm._s(_vm.field)+" "+_vm._s(_vm.$t("found"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }