<template>
  <div class="nb-timeline" :style="{ '--timeline-gap': gap + 'px' }">
    <div class="d-flex">
      <slot name="events"></slot>
    </div>

    <div class="d-flex">
      <slot name="breakpoints"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gap: {
      type: Number,
      default: 300,
    },
  },
};
</script>

<style lang="scss">
.nb-timeline {
  display: grid;
  place-content: center;
}
</style>
