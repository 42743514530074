<template>
  <form @submit.prevent="user?.id ? updateUser() : createUser()">
    <ModalHeader>
      <div class="d-flex flex-column align-items-start">
        <ModalTitle>
          {{ user?.id ? $t("usersPage.editUser") : $t("usersPage.addNewUser") }}
        </ModalTitle>
      </div>
    </ModalHeader>

    <div>
      <div class="grid gap-4 mt-4">
        <NbCardBox class="personal-data">
          <h3 class="heading-3">{{ $t("usersPage.personalData") }}</h3>

          <div class="grid grid-cols-2 gap-4">
            <NbTextInput
              v-model="form.name"
              id="name"
              :name="$t('usersPage.name')"
              required
              :error="errors['name']"
              :placeholder="$t('fullName')"
            />
            <NbTextInput
              v-model="form.email"
              id="email"
              :name="$t('usersPage.email')"
              required
              :error="errors['email']"
              placeholder="example@mail.com"
            />
            <NbTextInput
              v-model="form.phone"
              id="phone"
              :name="$t('usersPage.phone')"
              :error="errors['phone']"
              placeholder="+00 0 0000-0000"
              :masks="['+#*']"
            />
            <NbTextInput
              v-model="form.whatsapp_number"
              id="whatsapp_number"
              :name="$t('usersPage.whatsappNumber')"
              :error="errors['whatsapp_number']"
              placeholder="+00 0 0000-0000"
              :masks="['+#*']"
            />
          </div>
        </NbCardBox>

        <NbCardBox class="permissions">
          <h3 class="heading-3">{{ $t("usersPage.permissionsAndAccess") }}</h3>

          <div class="grid grid-cols-2 gap-4">
            <NbSelectInput
              v-model="form.profile"
              :name="$t('usersPage.profile')"
              required
              :options="userContext.profileOptions"
              id="profile"
              :error="errors['profile']"
              :helpText="$t('usersPage.profileHelp')"
            />
            <NbSelectInput
              v-model="form.group_id"
              :name="$t('usersPage.group')"
              required
              :options="profileGroupOptions"
              id="group_id"
              :disabled="form.profile ? false : true"
              :error="errors['group']"
              :helpText="$t('usersPage.groupHelp')"
            />
            <div class="col-span-2">
              <NbSelectInput
                v-if="form.profile == 'seller'"
                v-model="form.seller_id"
                :name="$t('usersPage.seller')"
                required
                :options="userContext.sellerOptions"
                id="seller_id"
                :error="errors['seller_id']"
              />

              <NbSelectInput
                v-if="form.profile == 'carrier'"
                v-model="form.carrier_id"
                :name="$t('usersPage.carrier')"
                required
                :options="userContext.carrierOptions"
                id="carrier_id"
                :error="errors['carrier_id']"
              />
            </div>
          </div>
        </NbCardBox>
      </div>
    </div>

    <ModalFooter class="d-flex justify-content-end gap-2">
      <ModalClose />
      <NbButton v-if="user?.id" type="submit" :disabled="isLoading">
        {{ $t("edit") }}
      </NbButton>
      <NbButton v-else type="submit" :disabled="isLoading">
        {{ $t("save") }}
      </NbButton>
    </ModalFooter>
  </form>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbCardBox from "@/components/cards/NbCardBox.vue";
import NbButton from "@/components/buttons/NbButton";
import UsersService from "../../../../services/UsersService";
import { sanitizeGroupName } from "../../../../utils/parser";
import { Profile } from "../../../../constants";

const usersService = new UsersService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbTextInput,
    NbSelectInput,
    NbCardBox,
    NbButton,
  },
  props: {
    user: {
      type: [Object, undefined],
      default: null,
    },
    carrierId: {
      type: [Number, null],
      default: null,
    },
  },
  inject: ["userContext"],
  data() {
    return {
      carrier: null,
      form: {
        name: this.user?.name || "",
        email: this.user?.email || "",
        phone: this.user?.phone || "",
        whatsapp_number: this.user?.whatsapp_number || "",
        is_blocked: this.user?.is_blocked ?? false,
        profile: this.user?.profile || null,
        group_id: this.user?.group_id || null,
        seller_id: this.user?.seller_id || null,
        carrier_id: this.user?.carrier_id || null,
      },
      errors: [],
      isLoading: false,
    };
  },
  computed: {
    profileGroupOptions() {
      if (!this.form?.profile) {
        return [];
      }

      const groups = this.userContext.profileGroups?.[this.form.profile];
      return (
        groups?.map((group) => ({
          text: sanitizeGroupName(group.name),
          value: group.id,
        })) || []
      );
    },
  },
  methods: {
    async createUser() {
      try {
        this.errors = [];
        this.isLoading = true;

        const { data } = await usersService.setUser(this.form);
        this.$emit("success", data.data);
      } catch (error) {
        const messages = error?.response?.data?.messages || [];
        this.errors = messages?.[0] || [];

        let message = this.$t("errorMessages.tryAgain");

        if (typeof messages?.[0] === "string") {
          message = messages[0];
        }

        this.$bvToast.toast(message, {
          solid: true,
          variant: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async updateUser() {
      this.errors = [];

      try {
        this.isLoading = true;
        const { data } = await usersService.updateUser(this.user.id, this.form);
        this.$bvToast.toast(data?.data?.[0]);
        this.$emit("success", data.data);
      } catch (error) {
        const messages = error?.response?.data?.messages || [];
        this.errors = messages?.[0] || [];

        let message = this.$t("errorMessages.tryAgain");

        if (typeof messages?.[0] === "string") {
          message = messages[0];
        }

        this.$bvToast.toast(message, {
          solid: true,
          variant: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },

    handleSubmit() {},
    // async loadCarrier() {
    //   const response = await carrierService.getCarrier(this.user.carrier_id);
    //   this.carrier = response.data.data;
    // },
  },
  created() {
    if (this.carrierId) {
      this.form.carrier_id = this.carrierId;
      this.form.profile = this.carrierId ? Profile.CARRIER : "";
    }
  },
};
</script>
