import api from "./HttpService";

export default class ContractService {
  getContracts() {
    return api.get("/v1/contracts-names");
  }

  getContractsActive() {
    return api.get("/v1/contracts-active");
  }

  getActiveContractsForSellers(param = null) {
    return api.get(
      param
        ? `/v1/shipping_contracts?seller_ids=${param}`
        : "/v1/shipping_contracts",
    );
  }

  /**
   * Get Contracts
   *
   * @param offset = number, track = filters
   * @returns {Promise}
   */
  getTwentyContracts(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/contracts`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/contracts?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  /**
   * Get allContracts
   *
   * @returns {Promise}
   */
  getAllContracts() {
    return api.get("/v1/contracts?limit=1000&offset=0");
  }

  /**
   * Get one Contract by ID Contracts
   *
   * @param id
   * @returns {Promise}
   */
  getOneContract(id) {
    return api.get("/v1/contracts/" + id);
  }

  /**
   * Set contract
   *
   * @param data
   * @returns {Promise}
   */
  setContract(data = {}) {
    return api.post("/v1/contracts", data);
  }

  /**
   * Export contract
   *
   * @param id
   * @returns {Promise}
   */
  exportContract(id) {
    return api.get("/v1/contracts/" + id + "/export");
  }

  /**
   * Update contracts
   *
   * @param id
   * @param data
   * @returns {Promise}
   */
  updateContract(id, data = {}) {
    return api.put("/v1/contracts/" + id, data);
  }

  /**
   * Update massive date contracts
   *
   * @param data
   * @returns {Promise}
   */
  updateMassiveContracts(data = {}) {
    return api.put("/v1/massive_update_date/", data);
  }

  getSellersContracts(params) {
    return api.get("/v1/sellers_contracts", {
      params,
    });
  }
}
