<template>
  <button
    v-if="variant === 'tertiary'"
    :class="`button-${variant}`"
    v-on="$listeners"
  >
    <NbIcon v-if="icon" :class="$slots.default ? 'mr-2' : ''" :icon="icon" />
    <slot></slot>
  </button>
  <button
    v-else-if="icon"
    :class="` nb-button button-${variant} ${size}`"
    class="button-icon"
    v-on="$listeners"
  >
    <NbIcon
      class="mr-2"
      :icon="icon"
      :attributes="{
        class: 'mb-1',
        'stroke-width': 3,
        width: '1rem',
        height: '1rem',
      }"
    />
    <slot></slot>
  </button>
  <!-- with icon -->
  <button
    v-else
    :class="` nb-button button-${variant} ${size}`"
    v-on="$listeners"
  >
    <slot></slot>
  </button>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
export default {
  name: "nbButton",
  components: { NbIcon },
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
}

button:disabled.nb-button.button-primary,
button[disabled].nb-button.button-primary,
button[disabled].nb-button.button-tertiary {
  background-color: var(--gray-20) !important;
  color: var(--gray-30) !important;
  border: 1px solid transparent;
}
button:disabled.nb-button.button-secondary,
button[disabled].nb-button.button-secondary {
  border: 1px solid var(--gray-20) !important;
  background-color: var(--white) !important;
  color: var(--gray-20) !important;
}

.nb-button {
  text-decoration: none;
  font-weight: bolder;
  border-radius: 4px;
  padding: 0.44rem 1.19rem;
  font: normal normal 600 16px/24px var(--font-family-base);
  transition: 500ms;
  height: 2.5rem;
}

.nb-button.button-primary {
  border: 1px solid var(--primary);
  color: var(--white);
  background-color: var(--primary);
  transition: 500ms;
}
.nb-button.button-primary:hover {
  background-color: var(--primary-80);
  color: var(--white);
}
.nb-button.button-primary:focus {
  box-shadow: 0px 0px 0px 1px var(--primary) !important;
  background-color: var(--primary);
}

/* secondary */
.nb-button.button-secondary {
  border: 1px solid var(--primary);
  color: var(--primary);
  background: var(--btnSecondaryBg);
}
.nb-button.button-secondary:hover {
  background-color: var(--gray-05);
}
.nb-button.button-secondary:focus {
  box-shadow: 0px 0px 0px 1px var(--primary) !important;
  background: var(--btnSecondaryBg);
}

/* tertiary */
.button-tertiary {
  border: 1px solid var(--transparent);
  background-color: var(--transparent);
  border-radius: 4px;
  padding: 0.42rem 0.5rem;
  color: var(--gray-60);
  text-decoration: underline;
  font: normal normal 600 12px/12px var(--font-family-base);
  letter-spacing: 0px;
  transition: all 0.5s ease;
}
.button-tertiary:disabled {
  opacity: 50%;
}
.button-tertiary:not(:disabled):hover {
  color: var(--black);
}
.button-tertiary:not(:disabled):focus {
  border: 1px solid var(--gray-60);
}

/* quaternary */
.nb-button.button-quaternary {
  border: 1px solid var(--gray-05);
  color: var(--black);
  background-color: var(--gray-05);
  transition: 500ms;
}

.nb-button.button-quaternary:disabled {
  opacity: 50%;
}

.nb-button.button-quaternary:not(:disabled):hover,
.nb-button.button-quaternary:not(:disabled):focus,
.nb-button.button-quaternary.bg-gray-10 {
  border: 1px solid var(--gray-10);
  background-color: var(--gray-10);
  color: var(--black);
}

.nb-button.button-quaternary.bg-gray-10:hover {
  border: 1px solid var(--gray-05);
  background-color: var(--gray-05);
}

.nb-button.button-quaternary:focus {
  border: 1px solid var(--gray-10);
  background-color: var(--gray-10);
  color: var(--black);
}

/* icon and sm */
.nb-button.button-icon {
  padding: 0.44rem 0.94rem;
}
.nb-button.sm {
  font: normal normal 600 14px/23px var(--font-family-base); /* talvez 16/20 */
  padding: 0.44rem 0.94rem;
  height: 2.25rem;
}
.nb-button.xs {
  font: normal normal 600 14px/20px var(--font-family-base);
  padding: 0.3rem 0.5rem;
}
</style>
