<template>
  <div>
    <header-title :title="title" />
    <div class="row justify-content-center">
      <div class="col-12 mb-5">
        <div class="card border-white rounded py-4">
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('receivedCheckpointsViewPage.trackingNumber')"
              :val="checkpoint.tracking_number"
            />
            <field-view
              :field="$t('receivedCheckpointsViewPage.trackingFunction')"
              :val="checkpoint.tracking_function"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('receivedCheckpointsViewPage.statusCode')"
              :val="checkpoint.status_code"
            />
            <field-view
              :field="$t('receivedCheckpointsViewPage.dateIso')"
              :val="checkpoint.date_iso"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('receivedCheckpointsViewPage.date')"
              :val="checkpoint.created_at"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('receivedCheckpointsViewPage.message')"
              :val="checkpoint.message"
            />
          </div>
        </div>
        <br />
        <h6 class="mx-1 my-3 font-weight-light text-uppercase">
          <!-- Received Notification -->
          Sent Notification
        </h6>
        <b-table
          id="my-notifications"
          :items="notifications"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          small
          striped
          responsive="md"
        >
          <template #cell(id)="data">
            <div>
              <router-link
                :to="`/checkpoints/send_notifications/` + data.item.id"
                >{{ data.value }}</router-link
              >
            </div>
          </template>
        </b-table>
        <br />
        <b-pagination
          v-model="currentPage"
          aria-controls="my-notifications"
          align="center"
          size="sm"
          :total-rows="totalRows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination, BTable } from "bootstrap-vue";
import ReceivedCheckpointService from "../../../services/ReceivedCheckpointService";
import SendNotificationService from "../../../services/SendNotificationService";
import FieldView from "@/components/FieldView";
import HeaderTitle from "../../../components/HeaderTitle.vue";

const checkpointService = new ReceivedCheckpointService();
const notificationService = new SendNotificationService();

export default {
  name: "ReceivedCheckpointsView",
  components: {
    "b-pagination": BPagination,
    "b-table": BTable,
    "field-view": FieldView,
    HeaderTitle,
  },
  data: () => {
    return {
      checkpoint: {},
      notifications: [],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
    };
  },
  computed: {
    title() {
      return (
        this.$t("receivedCheckpointsViewPage.title") + this.$route.params.id
      );
    },
    fields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "receiver_type",
          label: this.$t("receivedCheckpointsViewPage.fields.receiverType"),
          sortable: false,
        },
        {
          key: "nb_notification_id",
          label: this.$t("receivedCheckpointsViewPage.fields.notificationID"),
          sortable: false,
        },
        {
          key: "volume_id",
          label: this.$t("receivedCheckpointsViewPage.fields.volumeID"),
          sortable: false,
        },
        {
          key: "created_at",
          label: this.$t("receivedCheckpointsViewPage.fields.creationDate"),
          sortable: true,
        },
      ];
    },
  },
  beforeMount() {
    this.loadCurrentCheckpoint();
  },
  methods: {
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    loadCurrentCheckpoint() {
      checkpointService
        .getReceivedCheckpoint(this.$route.params.id)
        .then((response) => {
          this.checkpoint = response.data.data;
          let time = this.checkpoint.created_at;
          let date = this.checkpoint.created_at;
          // let dateIso = this.checkpoint.date_iso;

          this.checkpoint.created_at = this.formateDate(date, time);
          // this.checkpoint.date_iso =  this.formateDate(dateIso);

          if (this.checkpoint.send_notification_id) {
            this.checkpoint.send_notification_id.forEach((element) => {
              notificationService
                .getSendNotification(element)
                .then((response) => {
                  let date = response.data.data.created_at;
                  response.data.data.created_at = this.formateDate(date);

                  this.notifications.push(response.data.data);
                });
            });
          } else {
            for (let i = 1; i < 3; i++) {
              notificationService.getSendNotification(i).then((response) => {
                let date = response.data.data.created_at;
                response.data.data.created_at = this.formateDate(date);

                this.notifications.push(response.data.data);
              });
            }
          }
        });
    },
  },
};
</script>
