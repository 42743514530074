<template>
  <div>
    <div class="d-flex justify-content-between mb-2">
      <Skeleton h="12" w="64" />
      <Skeleton h="12" w="64" />
    </div>
    <div class="box p-4">
      <div
        v-for="item in 2"
        :key="item"
        class="d-flex justify-content-between align-items-center"
      >
        <Skeleton h="16" w="64" />
        <Skeleton h="16" w="32" />
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "@/components/Skeleton.vue";

export default {
  components: { Skeleton },
};
</script>

<style lang="scss" scoped>
.box {
  background-color: var(--gray-00);
  border-radius: 0.375rem;
  display: grid;
  gap: 2.5rem;
}
</style>
