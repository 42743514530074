import { addTimeAndDate } from "../../shared/functions";
import OrderService from "../../../services/OrderService";
const orderService = new OrderService();
export default {
  async fetchItems({ state, commit }) {
    const res = await orderService.getRejectedOrders(
      state.currentPage,
      state.limit,
    );
    let items = new addTimeAndDate(res.data.data.elements);
    const totalRows = res.data.data.count;
    commit("SET_ITEMS", items);
    commit("SET_TOTAL_ROWS", totalRows);
  },
};
