import api from "./HttpService";

export default class OrderService {
  /**
   * Get order
   *
   * @param {*} id
   * @returns {Promise}
   */
  getOrder(id = null) {
    return api.get("/v1/orders/" + id);
  }

  /**
   * Get orders
   *
   * @param filters
   * @returns {Promise}
   */
  getOrders(filters = {}) {
    return api.get("/v1/orders", {
      params: filters,
    });
  }

  /**
   * Get Twenty orders
   *
   * @param offset = number, track = filters
   * @returns {Promise}
   */
  getTwentyOrders(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
    } else {
      offset = (offset - 1) * 20;
    }
    return api.get("/v1/orders", {
      params: {
        offset,
        limit,
        filters,
      },
    });
  }

  /**
   * Get imported order with errors
   *
   * @returns {Promise}
   */
  getRejectedOrders(currentPage, limit) {
    return api.get(
      "/v1/orders/import/errors" + "?offset=" + currentPage + "&limit=" + limit,
    );
  }

  /**
   * Get order log
   *
   * @param {*} id
   * @returns {Promise}
   */
  getOrderLog(id) {
    return api.get("/v1/rejected-order-log?id=" + id);
  }

  /**
   * Get order contract
   *
   * @param {*} id
   * @returns {Promise}
   */
  getContract(id = null) {
    return api.get("/v1/orders/" + id + "/contract");
  }

  /**
   * Get order overpack
   *
   * @param {*} id
   * @returns {Promise}
   */
  getOverpack(id = null) {
    return api.get("/v1/orders/" + id + "/overpack");
  }

  /**
   * Get order checkpoints
   *
   * @param {*} id
   * @returns {Promise}
   */
  getCheckpoints(id = null) {
    return api.get("/v1/orders/" + id + "/checkpoints");
  }

  /**
   * Set order
   *
   * @param data
   * @returns {Promise}
   */
  setOrder(data = {}) {
    return api.post("/v1/orders", data);
  }

  /**
   * Update order
   *
   * @param {*} id
   * @param {*} data
   * @returns {Promise}
   */
  updateOrder(id = null, data = {}) {
    return api.put("/v1/orders/" + id, data);
  }

  /**
   * Update financial status
   *
   * @param {*} data
   * @returns {Promise}
   */
  updateFinancialStatus(data = {}) {
    return api.put("/v1/update_financial_status/", data);
  }

  /**
   * Delete order
   *
   * @param {*} id
   * @returns {Promise}
   */
  deleteOrder(id = null) {
    return api.delete("/v1/orders/" + id);
  }

  /**
   * Import orders
   *
   * @param {*} data
   * @returns {Promise}
   */
  importOrders(data) {
    return api.post("/v1/orders/import", data);
  }

  addCheckpoint(id = null, params) {
    return api.post("/v1/orders/" + id + "/add-checkpoint", params);
  }
  //To print Labels, Commercial Invoice and Packing Slip in one PDF
  printingAll(ids) {
    return api.put("/v1/printings/all", ids);
  }

  // Import orders from Mabang
  importOrdersMabang() {
    return api.get("/v1/integrations/import_orders_mabang");
  }
}
