<template>
  <div v-click-outside="closeCollapse" class="mt-0 relative">
    <div v-if="name" class="input-label" :for="id">
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
      </NbHelpText>
    </div>
    <div
      :disabled="disabled"
      :id="id"
      v-b-toggle="[`collapse-${id}`]"
      :style="customStyle"
      class="button select-button w-100"
      :data-value="search"
      @input="onInput"
      @keydown.esc="onPressEsc"
      :class="
        error[0]
          ? `is-invalid ${filled} ${isDisabled} ${size} ${variant}`
          : `${filled} ${isDisabled} ${size} ${variant}`
      "
    >
      <input
        ref="input"
        :value="search"
        class="search"
        type="text"
        :data-value="search"
      />
      <span v-if="!search">
        {{ showOptionSelected }}
      </span>
      <span
        :class="[
          'placeholder',
          { filled: optionSelected || search || optionSelected === 0 },
        ]"
      >
        {{ placeholderText }}
      </span>
      <i class="fas fa-angle-right" :class="size ? '' : 'mt-1'"></i>
    </div>
    <div class="mt-0 relative">
      <b-collapse
        :id="`collapse-${id}`"
        v-model="visible"
        class="mt-0 select-colapse-card"
      >
        <b-card class="p-0 nb-selectinput-card">
          <div
            v-for="option in filteredOptions"
            :key="option.value"
            :class="{
              'text-disabled': option.disabled,
              selected: isSelected(option.value),
            }"
            @click="switchSelect(option)"
          >
            <div
              v-b-toggle="[`collapse-${option.disabled ? '' : id}`]"
              class="select-options w-100"
            >
              <span
                class="select-label"
                v-html="option.highlight || option.text"
              ></span>
            </div>
          </div>
        </b-card>
      </b-collapse>
    </div>
    <ErrorFeedback :error="error[0]" />
  </div>
</template>

<script>
import ErrorFeedback from "../../generic/ErrorFeedback.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import { directive } from "v-click-outside";

export default {
  name: "NbSelectInput",
  directives: { clickOutside: directive },
  components: { ErrorFeedback, NbHelpText },
  props: {
    value: {
      type: [Object, String, Number],
      default: function () {
        return "";
      },
    },
    options: {
      type: Array,
      required: true,
    },
    returnCompleteObject: {
      type: Boolean,
      required: false,
    },
    helpText: {
      type: String,
      required: false,
      default: "",
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    startOpen: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    variant: {
      type: String,
      required: false,
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
    size: {
      type: String,
      default: "",
    },
    customStyle: {
      type: [Object, Array],
      default: () => [],
    },
  },
  data() {
    return {
      optionSelected: "",
      filled: "",
      visible: this.startOpen,
      search: "",
    };
  },
  computed: {
    isDisabled() {
      if (this.disabled) {
        return "disabled";
      }
      return "";
    },
    filteredOptions() {
      if (!this.search) return this.options;
      const multableOptions = JSON.parse(JSON.stringify(this.options));

      const normalizeSearch = this.search
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      const match = new RegExp(normalizeSearch, "i");
      return multableOptions
        .filter((option) => {
          const normalizedOption = option.text
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

          return match.test(normalizedOption);
        })
        .map((option) => {
          return {
            ...option,
            disabled: !!option?.disabled,
            highlight: option.text.replace(
              match,
              `<span style="color: var(--primary); font-weight: bold;">${this.search}</span>`,
            ),
          };
        });
    },
    classes() {
      const baseClass = ["form-control"];

      if (this.error.length) {
        baseClass.push("is-invalid");
      }
      return baseClass;
    },
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
    showOptionSelected() {
      const selectedText = this.options.find((option) => {
        if (option.value) {
          return option.value == this.optionSelected;
        }

        return option.value === this.optionSelected;
      });
      if (selectedText) {
        return selectedText.text;
      }
      return "";
      /* placeholder */
      // return this.placeholder ? this.placeholder : this.$t("selectOption");
    },
    placeholderText() {
      return this.placeholder || this.$t("selectOption");
    },
  },
  watch: {
    optionSelected(newValue) {
      this.$emit("input", newValue);
      this.validateOptionSelected();
    },
    value(newValue) {
      if (newValue !== this.optionSelected) {
        this.optionSelected = newValue;
      }
    },
    visible(value) {
      if (value) {
        this.$nextTick(this.$refs.input?.focus());
        return;
      }

      this.search = "";
    },
  },
  created() {
    this.optionSelected = this.value;
  },
  methods: {
    isSelected(value) {
      if (this.optionSelected === "") {
        return false;
      }

      return value == this.optionSelected;
    },
    onInput(event) {
      const target = event.target;
      this.search = target.value;
      this.visible = true;
    },
    onBlur({ relatedTarget }) {
      const target = relatedTarget;
      if (target && target.classList.contains("select")) {
        return false;
      }
      this.visible = false;
    },
    onPressEsc() {
      this.search = "";
      this.visible = false;
    },
    switchSelect(option) {
      if (option?.disabled) return;
      if (option.value != this.optionSelected || option.value === 0) {
        this.optionSelected = option.value;
        this.$emit("change", option.value);
        this.filled = "filled";
      }
      if (this.returnCompleteObject) {
        this.$emit("objectSelected", option);
      }

      this.search = "";
    },
    closeCollapse() {
      this.visible = false;
    },
    validateOptionSelected() {
      const element = document.getElementById(this.id);
      //take care with || this.error.length > 0
      if (
        this.required &&
        this.optionSelected &&
        this.optionSelected.length < 1
      ) {
        this.$emit("invalid", {
          id: this.id,
          message: this.$t("errorMessages.required"),
        });
        element.classList.remove("is-valid");
        return;
      }
      setTimeout(() => {
        element?.classList?.add("is-valid");
      }, 200);
      this.$emit("valid", this.id);
    },
  },
};
</script>

<style scoped lang="scss">
.input-label {
  color: var(--black);
  text-align: left;
  font: normal normal 600 12px/16px var(--font-family-base);
  letter-spacing: 0px;
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: default;
}

.button.select-button {
  overflow: hidden;
  position: relative;
  background: var(--gray-05);
  border-radius: 4px 4px 0px 0px;
  border-bottom: 2px solid var(--gray-40);
  // color: var(--gray-40);
  text-align: left;
  font: normal normal normal 14px/24px var(--font-family-base);
  letter-spacing: 0px;
  box-shadow: none;
  padding: 9px 12px;
  height: 2.5rem;
  /* height: calc(2em + 0.75rem + 2px); */
}
.button.select-button.filled {
  color: var(--black);
}
.button.select-button.is-invalid {
  border-bottom: 2px solid var(--error);
}
.button.select-button.is-valid {
  border-bottom: 2px solid var(--success);
}
.form-control.input.is-valid {
  border-bottom: 2px solid var(--success) !important;
  caret-color: var(--success);
  background-image: none;
}
.button.select-button.disabled,
.text-disabled {
  color: var(--gray-20) !important;
}
.button.select-button:hover {
  background: var(--gray-10);
}
.button.select-button.not-collapsed {
  transition: all 0.3s ease;
  border-bottom: 0px !important;
}
.button.select-button.not-collapsed > i {
  transition: all 0.3s ease;
  transform: rotate(90deg);
}
.button.select-button > i {
  color: var(--gray-60);
  transition: all 0.3s ease;
}
.button.select-button.disabled > i {
  color: var(--gray-20);
}

.button.select-button.disabled {
  border-color: var(--gray-20);
}

/* size sm */
.button.select-button.sm {
  background: var(--gray-05);
  border: 1px solid var(--gray-50);
  border-radius: 4px;
  color: var(--gray-50);
  font: normal normal 600 12px/16px Nunito Sans;
  letter-spacing: 0px;
  padding: 0.4rem 0.5rem;
  height: 1.75rem;
}
.button.select-button.sm.filled {
  color: var(--black);
}
.button.select-button.sm.is-invalid {
  border: 1px solid var(--error);
}
.button.select-button.sm.is-valid {
  border: 1px solid var(--success);
}
.button.select-button.sm.disabled {
  color: var(--gray-20);
}
.button.select-button.sm:hover {
  background: var(--gray-10);
}
.button.select-button.sm.not-collapsed {
  transition: all 0.3s ease;
  border-bottom: 0px !important;
}
.button.select-button.sm.not-collapsed > i {
  transition: all 0.3s ease;
  transform: rotate(90deg);
}
.button.select-button.sm > i {
  color: var(--gray-60);
  transition: all 0.3s ease;
}
.button.select-button.sm.disabled > i {
  color: var(--gray-20);
}

.select-colapse-card {
  position: absolute;
  top: 0px;
  width: 100%;
}
.nb-selectinput-card {
  overflow: auto;
  max-height: 53vh;
  .selected {
    background-color: var(--gray-10);
  }
}
.card {
  z-index: 4;
  background-color: var(--gray-05);
  border-radius: 0px;
  border: none;
}
.card-body {
  border-radius: 0px !important;
  padding: 0px;
  color: var(--black);
  text-align: left;
  font: normal normal normal 14px/20px var(--font-family-base);
  letter-spacing: 0px;
}
.card-body > div:last-child {
  border-bottom: 2px solid var(--black);
}
.relative {
  position: relative;
}
.select-options {
  cursor: pointer;
  padding: 9px 12px;
  border-bottom: 2px solid var(--gray-10);
}
.select-options:hover {
  background-color: var(--gray-10);
}
.text-disabled > .select-options:hover {
  cursor: not-allowed;
  background-color: var(--gray-05);
}

.mt-2px {
  margin-top: 2px;
}

/* borderless input */
.borderless.button.select-button {
  background: var(--gray-20) !important;
  border-radius: 4px !important;
  border-bottom: 2px solid transparent;
  color: var(--black) !important;
}

.borderless.button.select-button > .i span svg,
.borderless.button.select-button > i,
.borderless.button.select-button.disabled > .i span svg,
.borderless.button.select-button.disabled > i {
  color: var(--black) !important;
}

.select-button {
  grid-area: 1 / 1 / 2 / 3;
  grid-template-columns: 0px min-content;
  flex: 1 1 auto;
  display: inline-grid;
  visibility: visible;
  white-space: nowrap;
  margin: 0px;
  &::after {
    content: attr(data-value);
    font-weight: 600;
    padding: 0;
    outline-width: 1px;
    margin: 0;
    padding: 0;
    border: none;
    min-width: 2px;
    white-space: pre;
    visibility: hidden;
    font: inherit;
    grid-area: 1/2 / auto/auto;
  }
  i {
    position: absolute;
    right: 1rem;
    top: calc(2.5rem / 4);
  }
  .placeholder {
    position: absolute;
    top: 0;
    left: 0.7rem;
    bottom: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    color: var(--gray-40);
    &.filled {
      display: none;
    }
  }
}

.search {
  color: inherit;
  opacity: 1;
  width: 100%;
  min-width: 2px;
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
  background: 0px center;
  grid-area: 1 / 2 / auto / auto;
  font: inherit;
}
</style>
