<template>
  <div class="relative fix-top" :class="`${size} ${classes}`">
    <div v-click-outside="closeCollapse">
      <a
        :id="`${id}-1`"
        v-b-toggle
        class="nb-button button-option"
        :class="`${size} ${disabled ? 'disabled' : ''}`"
        :href="`#${id}`"
        :disabled="disabled"
        @click.prevent
      >
        <NbIcon
          v-if="icon"
          :class="$slots.default ? 'mr-2' : ''"
          :icon="icon"
        />
        <slot>Options</slot>
      </a>
      <b-collapse
        :id="id"
        v-model="visible"
        class="mt-0 colapse-card"
        :style="`width: ${optionsWidth}px; ${expandTo}: 0`"
      >
        <b-card class="p-0">
          <div
            v-for="(option, index) in options"
            :key="index"
            :class="option.disabled ? 'text-disabled' : ''"
            data-toggle="modal"
            :data-target="option.value.includes('#') ? option.value : ''"
            @click="option.disabled ? null : emitClicked(option)"
          >
            <div
              v-if="
                option.value === 'showModalConditionService' &&
                getPlatform() === 'tiendamia'
              "
            ></div>
            <div
              v-else
              class="button-options"
              @click="option.disabled ? null : closeCollapse()"
            >
              <span
                v-if="
                  option.value === 'user_balance' && option.text.includes('+')
                "
                style="color: #00cb8b"
                class="button-label"
                >{{ option.text }}</span
              >
              <span
                v-else-if="
                  option.value === 'user_balance' && option.text.includes('-')
                "
                style="color: #fb1f1f"
                class="button-label"
                >{{ option.text }}</span
              >
              <span v-else class="button-label">{{ option.text }}</span>
            </div>
          </div>
        </b-card>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { directive } from "v-click-outside";
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  name: "NbOption",
  components: { NbIcon },
  directives: { clickOutside: directive },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    classes: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    optionsWidth: {
      type: [Number, String],
      default: null,
    },
    expandTo: {
      type: String,
      default: "bottom" /* can use right */,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: this.value,
      visible: false,
    };
  },
  methods: {
    emitClicked(option) {
      this.content = option.value;
      this.$emit("input", option.value);
    },
    closeCollapse() {
      this.visible = false;
      //document.getElementById(`${this.id}-1`).click()
    },
    getPlatform() {
      return this.$helpers.getPlatform();
    },
  },
};
</script>

<style scoped>
.relative.fix-top {
  height: calc(40px - 0.44rem);
}
.relative.fix-top.sm {
  height: calc(32px - 0.44rem);
}
.fix-top {
  margin-top: 0.44rem;
}
.nb-button {
  text-decoration: none;
  font-weight: bolder;
  border-radius: 4px;
  padding: 11px 1.19rem;
  font: normal normal 600 12px/18px var(--font-family-base);
  /* padding: 0.44rem 1.19rem;
  font: normal normal 600 16px/24px var(--font-family-base); */
  transition: 500ms;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
}
.nb-button.button-option {
  border: 1px solid var(--gray-05);
  color: var(--black);
  background-color: var(--gray-05);
  transition: 500ms;
}
.nb-button.button-option:hover {
  border: 1px solid var(--gray-10);
  background-color: var(--gray-10);
  color: var(--black);
}
.nb-button.button-option:focus {
  border: 1px solid var(--gray-10);
  background-color: var(--gray-10);
  color: var(--black);
  /* 
  border: 1px solid var(--gray-60);
  background: var(--gray-05);
   */
}
.nb-button.button-option.disabled {
  cursor: not-allowed;
  color: var(--gray-20);
}
.nb-button.button-icon {
  padding: 0.44rem 0.94rem;
}
.nb-button.sm {
  font: normal normal bold 12px/18px var(--font-family-base);
  padding: 7px 0.94rem;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
  /* font: normal normal 600 14px/20px var(--font-family-base); /* talvez 16/20 */
  /*padding: 0.44rem 0.94rem; */
}
.nb-button.xs {
  font: normal normal 600 14px/20px var(--font-family-base);
  padding: 0.3rem 0.5rem;
}
.button-options:hover,
.button-options > .button-label:hover {
  cursor: pointer;
}

.button.select-button.disabled,
.text-disabled {
  color: var(--gray-60);
  background: var(--gray-10) 0% 0% no-repeat padding-box;
}
.text-disabled > .button-options:hover,
.text-disabled > .button-options > .button-label:hover {
  cursor: not-allowed;
  background-color: var(--gray-05);
}

.button-label {
  color: var(--black);
  text-align: center;
  font: normal normal normal 12px/18px var(--font-family-base);
  white-space: nowrap;
}
.card-body {
  z-index: 3;
  background: var(--gray-05);
  box-shadow: 4px 4px 12px var(--gray-10);
  border-radius: 4px 4px 0px 0px;
  padding: 0px;
  border-radius: 4px !important;

  /* border: 1px solid var(--gray-10); */
  border-top: 1px solid var(--gray-10);
  border-right: 2px solid var(--gray-10);
  border-bottom: 2px solid var(--gray-10);
  border-left: 2px solid var(--gray-10);
}
.card {
  border-top: 0px !important;
  border-right: 1px solid var(--gray-05) !important;
  border-bottom: 1px solid var(--gray-05) !important;
  border-left: 1px solid var(--gray-05) !important;
  color: black !important;
  border-radius: 0px 0px 4px 4px;
  background: transparent;
  flex-direction: column;
  justify-content: center;
}
.relative {
  position: relative;
}
.button-options {
  text-align: center;
  /* width: 100%; */
  padding: 0.5rem;
  border-top: 1px solid var(--gray-10);
}
.button-options:hover {
  background-color: var(--gray-10);
}
.colapse-card {
  position: absolute;
  top: 2.3rem;
}
</style>
