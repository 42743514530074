import GroupService from "../../../services/GroupService";
const groupService = new GroupService();

export default {
  async fetchItems({ state, commit }, namespace) {
    const res = await groupService.getGroups(
      namespace,
      state.currentPage,
      state.limit,
      state.filters,
    );
    const items = res.data.data;
    const totalRows = res.data.data.length;
    commit("SET_ITEMS", items);
    commit("SET_TOTAL_ROWS", totalRows);
  },
};
