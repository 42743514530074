<template>
  <div>
    <NbButton
      @click="isConfirmActionOpen = true"
      variant="secondary"
      class="w-100"
    >
      {{ $t("remove") }}
    </NbButton>

    <Modal v-model="isConfirmActionOpen">
      <ModalHeader>
        <ModalTitle>{{ $t("confirm") }}</ModalTitle>
      </ModalHeader>

      <p class="body-4">{{ $t("usersPage.confirmRemoveUser") }}</p>

      <ModalFooter class="d-flex justify-content-end gap-2">
        <ModalClose />
        <NbButton :disabled="isLoading" @click="archiveUser">
          {{ $t("remove") }}
        </NbButton>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import UsersService from "@/services/UsersService";
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
const usersService = new UsersService();

export default {
  components: {
    NbButton,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isConfirmActionOpen: false,
    };
  },
  methods: {
    async archiveUser() {
      try {
        this.isLoading = true;
        await usersService.updateUser(this.user.id, {
          is_archived: true,
          is_blocked: true,
        });

        this.$emit("archived");
      } catch (error) {
        this.$bvToast.toast(error.response.data.messages[0], {
          title: "Error",
          solid: true,
          variant: "danger",
          autoHideDelay: 2000,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
