import api from "./HttpService";

export default class ReceivedCheckpointService {
  /**
   * Get ReceivedCheckpointService
   *
   * @param {*} id
   * @returns {Promise}
   */
  getReceivedCheckpoint(id = null) {
    return api.get("/v1/received_checkpoints/" + id);
  }

  /**
   * Get ReceivedCheckpointService
   *
   * @param offset = number, track = filters
   * @returns {Promise}
   */
  getTwentyReceivedCheckpoints(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/received_checkpoints`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/received_checkpoints?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  getAllReceivedCheckpoints() {
    return api.get("/v1/received_checkpoints");
  }
}
