<template>
  <span class="i" @click="$emit('click')">
    <span v-html="iconSvg"></span>
  </span>
</template>

<script>
import feather from "feather-icons";

export default {
  name: "NbIcon",
  props: {
    icon: {
      type: String,
      default: "alert-circle",
    },
    attributes: {
      type: Object,
      default: () => {
        return {
          width: ".8rem",
          height: ".8rem",
        };
      },
    },
    size: {
      // size in px
      type: [String, Number],
      default: "",
    },
  },
  computed: {
    iconSvg() {
      /*
      feather.icons[name].toSvg([attrs])

      to check all possibilit for atts
      https://github.com/feathericons/feather#quick-start

      to check all icons
      https://fengyuanchen.github.io/vue-feather/
      Example
      {
        class: "spin",
        "stroke-width": 1,
        color: "red",
        width: 10,
        height: 10,
      }

      */

      const attrs = Object.assign({}, this.attributes);
      const itemSize = this.size && `${this.size}px`;

      attrs.width = this.size ? itemSize : attrs.width;
      attrs.height = this.size ? itemSize : attrs.height;

      return feather.icons[this.icon]?.toSvg(attrs);
    },
  },
};
</script>

<style>
.i {
  transition: all 0.2s ease-in;
}
</style>
