<template>
  <NbModal id="select-columns-download" @close="closeModal" prevent-close>
    <template #header>
      <div class="d-flex flex-column">
        <h4 class="heading-4" id="settingsTitle">
          {{ $t("components.selectTableColumns.title") }}
        </h4>
        <div class="d-flex justify-content-end">
          <NbButton variant="quaternary" @click="clearSelected()">
            {{ $t("clearSelected") }}
          </NbButton>
          <NbButton variant="quaternary" @click="selectAll()" class="ml-2">
            {{ $t("selectAll") }}
          </NbButton>
        </div>
      </div>
    </template>
    <template #body>
      <div>
        <NbCheckbox
          v-for="item in items"
          :key="item.key"
          v-model="item.selected"
          :name="item.label"
          :id="item.key"
        />
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end">
        <NbButton variant="secondary" @click="closeModal()">
          {{ $t("cancel") }}
        </NbButton>
        <NbButton
          @click="handle()"
          class="ml-2"
          :disabled="loading || !selectedColumns.length"
        >
          {{ $t("download") }}
        </NbButton>
      </div>
    </template>
  </NbModal>
</template>
<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";

export default {
  name: "ModalDownloadVolumes",
  components: {
    NbModal,
    NbButton,
    NbCheckbox,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  watch: {
    options() {
      this.setItems();
    },
  },
  computed: {
    selectedColumns() {
      return this.items
        .filter((field) => field.selected === true)
        .map((field) => field.key);
    },
  },
  methods: {
    setItems() {
      this.items = this.options.map((item) => ({
        ...item,
        selected: item.selected ?? false,
      }));
    },
    clearSelected() {
      this.items = this.items.map((item) => ({
        ...item,
        selected: false,
      }));
    },
    selectAll() {
      this.items = this.items.map((item) => ({
        ...item,
        selected: true,
      }));
    },
    closeModal() {
      this.$helpers.closeModal("select-columns-download");
    },
    async handle() {
      this.$emit("selected", this.selectedColumns);
    },
  },
  created() {
    this.setItems();
  },
};
</script>
<style lang="scss" scoped></style>
