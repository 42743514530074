<template>
  <div>
    <h3 class="heading-4 mb-4">
      {{ $t("trackingRuleNotificationsPage.giveRuleName") }}
    </h3>
    <NbTextInput
      id="name"
      :name="$t('trackingRuleNotificationsPage.ruleName')"
      v-model="name"
      required
      :error="errors"
      @input="errors = []"
    />

    <div class="d-flex justify-content-end mt-4">
      <NbButton :disabled="!isValid || loading" @click="validateRuleName()">
        {{ $t("next") }}
      </NbButton>
    </div>
  </div>
</template>

<script>
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import { mapState, mapMutations } from "vuex";
import TrackingRuleNotificationsService from "../../../../services/TrackingRuleNotificationsService";

const trackingRuleNotificationsService = new TrackingRuleNotificationsService();

export default {
  components: {
    NbTextInput,
    NbButton,
  },
  data() {
    return {
      errors: [],
      loading: false,
    };
  },
  inject: ["ruleContext"],
  computed: {
    ...mapState({
      ruleName: (state) => state.notificationRuleSettings.ruleName,
    }),
    name: {
      get() {
        return this.ruleName;
      },
      set(val) {
        this.updateRuleName(val?.trim());
      },
    },
    isValid() {
      return !!this.name.trim();
    },
  },
  methods: {
    ...mapMutations("notificationRuleSettings", {
      updateRuleName: "UPDATE_RULE_NAME",
    }),
    goNext() {
      this.$emit("next");
    },
    async validateRuleName() {
      if (!this.name.trim()) {
        return;
      }

      try {
        this.loading = true;
        const params = {
          name: this.ruleName,
        };

        if (this.ruleContext?.ruleId) {
          params["rule_id"] = this.ruleContext.ruleId;
        }

        const { data } =
          await trackingRuleNotificationsService.checkRuleName(params);
        if (!data.data?.valid) {
          this.errors = [
            this.$t("trackingRuleNotificationsPage.ruleNameAlreadyExists"),
          ];
          return;
        }
        this.goNext();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
