<template>
  <div class="grid grid-cols-2">
    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('name')"
      v-model="volume.order.customer_full_name"
      id="volume.order.customer_full_name"
    />
    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('phone')"
      v-model="volume.order.customer_phone"
      id="volume.order.customer_phone"
    />
    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('email')"
      v-model="volume.order.customer_email"
      id="volume.order.customer_email"
    />
    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('documentNumber')"
      :value="`(${volume.order.customer_document_type}) ${volume.order.customer_document_number}`"
      id="volume.order.cutomer_document"
    />

    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('address')"
      v-model="volume.order.customer_address"
      id="volume.order.customer_address"
      class="col-span-2"
      placeholder="-"
    />

    <div class="col-span-2 grid grid-cols-4 gap-4">
      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('addressNumber')"
        v-model="volume.order.customer_address_number"
        id="volume.order.customer_address_number"
        placeholder="-"
      />

      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('addressComplement')"
        v-model="volume.order.customer_address_complement"
        id="volume.order.customer_address_complement"
        placeholder="-"
      />

      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('addressReference')"
        v-model="volume.order.customer_address_reference"
        id="volume.order.customer_address_reference"
        placeholder="-"
      />

      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('addressQuarter')"
        v-model="volume.order.customer_address_quarter"
        id="volume.order.customer_address_quarter"
        placeholder="-"
      />
    </div>

    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('city')"
      v-model="volume.order.customer_city"
      id="volume.order.customer_city"
    />
    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('postalCode')"
      v-model="volume.order.customer_postal_code"
      id="volume.order.customer_postal_code"
    />
    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('state')"
      v-model="volume.order.customer_state"
      id="volume.order.customer_state"
    />

    <div>
      <label class="input-label">
        {{ $t("country") }}
      </label>
      <div class="input-view d-flex align-items-center gap-2">
        <img
          :src="`https://flagcdn.com/24x18/${volume.order.customer_country.toLowerCase()}.png`"
          alt=""
        />
        {{
          $helpers.getCountryNameByCode(
            volume.order.customer_country,
            $i18n.locale.replace("_", "-"),
          )
        }}
      </div>
    </div>
  </div>
</template>

<script>
import NbTextInput from "@/components/input/text/NbTextInput.vue";

export default {
  props: {
    volume: {
      type: Object,
      required: true,
    },
  },
  components: {
    NbTextInput,
  },
};
</script>

<style lang="scss" scoped></style>
