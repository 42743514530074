<template>
  <div
    v-if="$route.params.modal_notification"
    type="button"
    class="text-primary"
    @click="
      hasHistory()
        ? $router.push(
            '/notifications/user_notifications?modal_notification=' +
              $route.params.modal_notification,
          )
        : $router.push('/')
    "
  >
    <i class="fas fa-chevron-left"></i> Back To Approval
  </div>

  <div
    v-else
    type="button"
    class="go-back text-primary"
    @click="hasHistory() ? $router.go(-1) : $router.push('/')"
  >
    <i class="fas fa-chevron-left"></i>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "BackButton",
  created() {},
  methods: {
    ...mapMutations("shared", {
      setKeepFiltering: "SET_KEEPFILTERING",
    }),
    hasHistory() {
      this.setKeepFiltering(true);
      return window.history.length > 2;
    },
  },
};
</script>
