<template>
  <div>
    <NbPageTitle
      class="pb-3 page-container"
      :title="$t('contractsPage.title')"
      :subTitle="$t('contractsPage.subTitle')"
    >
      <!-- Botoes -->
      <NbButton class="text-left" icon="plus" @click="initializeContract">
        {{ $t("contractsPage.addContracts") }}
      </NbButton>
    </NbPageTitle>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <DatatableRoot
        namespace="contracts"
        ref="contractsTable"
        :columns="tableColumns"
        clickable
        selectable
        :filters="tableFilters"
        :selected.sync="selected"
        @count="totalContracts = $event"
        @rowClick="loadModalData($event.id)"
      >
        <template #flow="{ row }">
          <NbBadge
            class="w-fit"
            :type="row.is_old_flow ? 'warning' : 'info'"
            :text="
              row.is_old_flow
                ? $t('contractsPage.old')
                : $t('contractsPage.new')
            "
          />
        </template>
        <template #airport_to_door="{ row }">
          <NbBadge
            class="w-fit mt-1"
            :type="row.airport_to_door ? 'warning' : 'info'"
            :text="row.airport_to_door ? 'A2D' : 'D2D'"
          />
        </template>
        <template #first_mile_delivery_method_id="{ row }">
          <div>
            <router-link
              class="link-1"
              :to="
                `/contracts/delivery_methods?deliveryMethodId=` +
                row.first_mile_delivery_method_id
              "
            >
              {{ loadDMName(row.first_mile_delivery_method_id) }}
            </router-link>
          </div>
        </template>
        <template #freight_forwarder_delivery_method_id="{ row }">
          <div>
            <router-link
              class="link-1"
              :to="
                `/contracts/delivery_methods?deliveryMethodId=` +
                row.freight_forwarder_delivery_method_id
              "
            >
              {{ loadDMName(row.freight_forwarder_delivery_method_id) }}
            </router-link>
          </div>
        </template>
        <template #custom_clearence_delivery_method_id="{ row }">
          <div>
            <router-link
              class="link-1"
              :to="
                `/contracts/delivery_methods?deliveryMethodId=` +
                row.custom_clearence_delivery_method_id
              "
            >
              {{ loadDMName(row.custom_clearence_delivery_method_id) }}
            </router-link>
          </div>
        </template>
        <template #last_mile_delivery_method_id="{ row }">
          <div>
            <router-link
              class="link-1"
              :to="
                `/contracts/delivery_methods?deliveryMethodId=` +
                row.last_mile_delivery_method_id
              "
            >
              {{ loadDMName(row.last_mile_delivery_method_id) }}
            </router-link>
          </div>
        </template>
        <template #reverse_delivery_method_id="{ row }">
          <div>
            <router-link
              class="link-1"
              :to="
                `/contracts/delivery_methods?deliveryMethodId=` +
                row.reverse_delivery_method_id
              "
            >
              {{ loadDMName(row.reverse_delivery_method_id) }}
            </router-link>
          </div>
        </template>
        <template #price_table="{ row }">
          <span v-if="row.price_table_id" class="dropdown">
            <NbButton data-toggle="dropdown" variant="tertiary" icon="download">
              {{ row.price_table?.name }}
            </NbButton>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <div
                class="link-1 dropdown-item text-center p-3"
                @click="printPricePdf(row.price_table_id, row.id)"
              >
                Download PDF
              </div>
              <div
                class="link-1 dropdown-item text-center p-3"
                @click="
                  printPriceCsv(
                    row.price_table_id,
                    row.price_table?.name,
                    row.id,
                  )
                "
              >
                Download CSV
              </div>
            </div>
          </span>
          <div v-else>-</div>
        </template>
        <template #delivery_time="{ row }">
          <span v-if="row.delivery_time_id" class="dropdown">
            <NbButton data-toggle="dropdown" variant="tertiary" icon="download">
              {{ row.delivery_time?.name }}
            </NbButton>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <div
                class="link-1 dropdown-item text-center p-3"
                @click="printDeliveryPdf(row.delivery_time_id, row.id)"
              >
                Download PDF
              </div>
              <div
                class="link-1 dropdown-item text-center p-3"
                @click="
                  printDeliveryCsv(
                    row.delivery_time_id,
                    row.delivery_time?.name,
                    row.id,
                  )
                "
              >
                Download CSV
              </div>
            </div>
          </span>
          <div v-else>-</div>
        </template>
        <template #automatic_payment="{ row }">
          <IsCheckedIcon :checked="row.automatic_payment" />
        </template>
        <template #additional_services="{ row }">
          <div
            v-if="row.additional_services?.length > 0"
            class="link-1"
            @click="currentAdditionalService(row)"
          >
            {{ $t("contractsPage.showAdditionalServices") }}
          </div>
          <div v-else>-</div>
        </template>

        <template #max_weight="{ row }"> {{ row.max_weight }} kg </template>

        <template #max_width="{ row }"> {{ row.max_width }} cm </template>

        <template #max_height="{ row }"> {{ row.max_height }} cm </template>
        <template #max_length="{ row }"> {{ row.max_length }} cm </template>

        <template #price_limit="{ row }"> {{ row.price_limit }} USD </template>
        <template #is_made_by_seller="{ row }">
          <IsCheckedIcon :checked="row.is_made_by_seller" />
        </template>

        <template #beginning_date="{ row }">
          {{ row.beginning_date | date }}
        </template>
        <template #end_date="{ row }">
          {{ row.end_date | date }}
        </template>
        <template #created_at="{ row }">
          {{ row.created_at | datetime }}
        </template>
      </DatatableRoot>
    </section>

    <NbFooter
      class="page-container"
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />
    <!-- modals -->
    <ModalContractsView
      v-if="modalContractControl === 'ModalContractView'"
      id="ModalContractView"
      :itemId="currentItemId"
    />

    <ModalContractCreate
      v-if="modalContractControl === 'addContract'"
      id="addContract"
      :contract.sync="newContract"
      @reloadContracts="reloadContracts"
    />

    <NbModal id="massive-update-date" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="mb-4 ml-3">
          <div>
            <h5 class="heading-4 mb-1">
              {{ $t("contractsPage.updateValidityDate") }}
            </h5>
          </div>
        </div>
      </template>
      <template #body>
        <div class="d-flex pb-1">
          <NbDatePicker
            id="beginning_date_massive"
            class="my-2 mx-2"
            size="sm"
            :name="$t('beginningDate')"
            v-model="newContract.beginning_date"
          />
          <NbDatePicker
            id="end_date_massive"
            class="my-2 mx-2"
            size="sm"
            :name="$t('endDate')"
            v-model="newContract.end_date"
          />
        </div>
        <div class="W-100 my-3">
          <Alert
            type="warning"
            icon="fas fa-exclamation-circle"
            :message="$t('contractsPage.needSupervisorApproval')"
          />
          <div class="input-label mb-2">
            {{ $t("contractsPage.chooseSendTo") }}:
          </div>
          <NbCheckbox
            v-for="admin in admins"
            :key="admin.id"
            :id="admin.text"
            class="w-100"
            :name="admin.text"
            @input="setSelectedAdmin($event, admin.value)"
            v-model="admin.key"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            data-target="#massive-update-date"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton
            :disabled="selected_admins.length < 1"
            @click.prevent="updateMassiveContract()"
          >
            {{ $t("confirm") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal
      id="showAdditionalService"
      modalConfig="modal-dialog-centered"
      width="76rem"
    >
      <template v-slot:header>
        <div class="mb-4 ml-3">
          <div>
            <h5 class="heading-4 mb-1">
              {{ `Additional Services for ${currentContractAddService}` }}
            </h5>
          </div>
        </div>
      </template>
      <template #body>
        <div
          v-for="additionalService in additionalServices"
          :key="additionalService.id"
          class="d-flex justify-content-between flex-wrap"
        >
          <NbTextInput
            id="additionalService-name"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-24 my-2 mr-1"
            :name="$t('contractsViewPage.name')"
            v-model="additionalService.name"
            disabled
          />
          <NbTextInput
            id="additionalService-external_id"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-24 my-2 mr-1"
            :name="$t('contractsViewPage.externalID')"
            v-model="additionalService.external_id"
            disabled
          />
          <NbTextInput
            id="additionalService-trigger_option"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-24 my-2 mr-1"
            :name="$t('contractsViewPage.triggerOption')"
            v-model="additionalService.trigger_option"
            disabled
          />
          <NbTextInput
            id="additionalService-trigger_conditionm"
            variant="borderless-gray-10"
            :placeholder="showCondictions(additionalService.trigger_condition)"
            class="w-24 my-2 mr-1"
            :name="$t('contractsViewPage.triggerCondition')"
            v-model="emptVal"
            disabled
          />
          <NbTextInput
            id="additionalService-primary_variable"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-48-5 my-2 mr-1"
            :name="$t('contractsViewPage.primaryVariable')"
            v-model="additionalService.primary_variable"
            disabled
          />
          <NbTextInput
            id="additionalService-primary_value"
            variant="borderless-gray-10"
            :placeholder="standardizeValue(additionalService.primary_value)"
            :prepend="additionalService.primary_type === 'flat' ? '$' : ''"
            :append="additionalService.primary_type === 'flat' ? '' : '%'"
            class="w-48-5 my-2 mr-1"
            :name="$t('contractsViewPage.primaryValue')"
            v-model="emptVal"
            disabled
          />
          <NbTextInput
            id="additionalService-secondary_variable"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-48-5 my-2 mr-1"
            :name="$t('contractsViewPage.secondaryVariable')"
            v-model="additionalService.secondary_variable"
            disabled
          />
          <NbTextInput
            id="additionalService-secondary_value"
            variant="borderless-gray-10"
            :placeholder="standardizeValue(additionalService.secondary_value)"
            :prepend="additionalService.secondary_type === 'flat' ? '$' : ''"
            :append="additionalService.secondary_type === 'flat' ? '' : '%'"
            class="w-48-5 my-2 mr-1"
            :name="$t('contractsViewPage.secondaryValue')"
            v-model="emptVal"
            disabled
          />
          <div>
            <div class="input-label mt-2">
              {{ $t("contractsViewPage.hSCodes") }}:
            </div>
            <div class="d-flex">
              <div
                class="mx-1"
                v-for="code in additionalService.hs_codes"
                :key="code.id"
              >
                <NbBadge :text="code" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalContractsView from "@/views/contracts/contracts/components/ModalContractsView.vue";
import ModalContractCreate from "@/views/contracts/contracts/components/ModalContractCreate.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbDatePicker from "@/components/generic/NbDatePicker.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";
import Alert from "@/components/alerts/Alert.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import ContractService from "@/services/ContractService";
import DeliveryMethodsService from "@/services/DeliveryMethodsService";
import PendingActionService from "@/services/PendingActionService";
import PriceTablesService from "@/services/PriceTablesService";
import DeliveryTimesService from "@/services/DeliveryTimesService";
import IsCheckedIcon from "../../../components/IsCheckedIcon.vue";
import moment from "moment";

const contractService = new ContractService();
const deliveryMethodsService = new DeliveryMethodsService();
const pendingActionService = new PendingActionService();
const priceTablesService = new PriceTablesService();
const deliveryTimesService = new DeliveryTimesService();

export default {
  name: "Contracts",
  components: {
    NbPageTitle,
    NbButton,
    NbTabs,
    DatatableRoot,
    NbBadge,
    NbFooter,
    ModalContractsView,
    ModalContractCreate,
    NbModal,
    NbDatePicker,
    NbCheckbox,
    Alert,
    NbTextInput,
    IsCheckedIcon,
  },
  filters: {
    date(val) {
      return val && moment(val).format("DD/MM/YYYY");
    },
    datetime(val) {
      return val && moment(val).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  data: () => {
    return {
      modalContractControl: "",
      emptVal: "",
      currentTable: "all",
      totalContracts: -1,
      currentItemId: "",
      newContract: {
        name: "",
        slug: "",
        freight_forwarder_delivery_method_id: "",
        custom_clearence_delivery_method_id: "",
        first_mile_delivery_method_id: "",
        last_mile_delivery_method_id: "",
        reverse_delivery_method_id: "",
        price_table_id: "",
        delivery_time_id: "",
        logo_url: "",
        destination_country: [],
        origin_country: [],
        taxing_type: "postal",
        incoterm: "BOTH",
        automatic_payment: false,
        max_weight: 0,
        max_width: 0,
        max_height: 0,
        max_length: 0,
        cubage_factor: 0,
        additional_services_attributes: [],
        is_old_flow: false,
        airport_to_door: false,
        generate_payment_link: false,
        dutypay_notification: {
          sms: false,
          whatsapp: false,
          email_seller: false,
          email_customer: false,
        },
        beginning_date: null,
        end_date: null,
        price_limit: 10000,
      },
      deliveryMethods: [],
      allDeliveryMethods: [],

      selected: [],
      filter: null,
      admins: null,
      selected_admins: [],
      additionalServices: [],
      currentContractAddService: "",
    };
  },
  created() {
    this.reloadDeliveryMethods();
    this.getDeliveryMethods();
    pendingActionService.fetchAdminUsers().then((response) => {
      this.admins = response.data.data.map((admin) => ({
        text: admin.name,
        value: admin.id,
      }));
    });
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    downloadPdf(id, contractId, ifPrice) {
      const printService = ifPrice ? priceTablesService : deliveryTimesService;
      printService.downloadPdf(id, contractId).then((response) => {
        if (response.data.data && response.data.data.file) {
          let file = response.data.data.file;
          let pdf = `data:application/pdf;base64,${file}`;
          let download_link = document.createElement("a");
          download_link.href = pdf;
          download_link.download = ifPrice
            ? `price_table_${id}.pdf`
            : `delivery_time_${id}.pdf`;
          download_link.click();
        }
      });
    },
    printPricePdf(documentId, contractId) {
      this.downloadPdf(documentId, contractId, true);
    },
    printDeliveryPdf(documentId, contractId) {
      this.downloadPdf(documentId, contractId, false);
    },
    downloadCsv(id, name, ifPrice) {
      const printService = ifPrice ? priceTablesService : deliveryTimesService;
      const filename = ifPrice
        ? `price_table_${name}.csv`
        : `delivery_time_${name}.csv`;
      printService.downloadCsv(id).then((response) => {
        let universalBOM = "\uFEFF";
        let csv =
          "data:text/csv; charset=utf-8," +
          encodeURIComponent(universalBOM + response.data);
        let anchor = document.createElement("a");
        anchor.href = csv;
        anchor.target = "_blank";
        anchor.download = filename;
        anchor.click();
      });
    },
    printPriceCsv(id, name) {
      this.downloadCsv(id, name, true);
    },
    printDeliveryCsv(id, name) {
      this.downloadCsv(id, name, false);
    },
    standardizeValue(val) {
      const finalVal = parseFloat(val).toFixed(2);
      if (finalVal === "NaN") {
        return "0.00";
      }
      return finalVal;
    },
    showCondictions(text) {
      if (Array.isArray(text)) {
        const finalResult = text.join(", ");
        return finalResult;
      }
      return text;
    },
    setSelectedAdmin(event, adminId) {
      if (event) {
        this.selected_admins.push(adminId);
        return;
      }

      const index = this.selected_admins.indexOf(adminId);
      if (index !== -1) {
        this.selected_admins.splice(index, 1);
      }
    },
    reloadContracts() {
      this.$refs?.contractsTable?.getData();
    },
    massiveUpdateDate() {
      this.$helpers.openModal("massive-update-date");
    },
    optionTook(action) {
      this[action]();
    },
    checkQuery() {
      if (this.$route.query.contractId) {
        //this.$emit("switchTab", "alertLogs"); //emit if have more than one tab
        this.loadModalData(this.$route.query.contractId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.modalContractControl = "ModalContractView";

        if (!this.$route.query.contractId) {
          this.$router.push({ query: { contractId: id } });
        }
        /* this.$router.push({ query: {} }); */
        setTimeout(() => {
          this.currentItemId = id;
          this.$helpers.openModal("ModalContractView");
        }, 200);
        return;
      }
    },
    checkLoadModalData(id) {
      if (id) {
        this.loadModalData(id);
        return;
      }
    },
    switchTab(tab) {
      this.currentTable = tab;
    },
    reloadDeliveryMethods() {
      deliveryMethodsService.getDmNotMadeBySeller().then((response) => {
        this.deliveryMethods = response.data.data.elements;
      });
    },
    getDeliveryMethods() {
      deliveryMethodsService.getAllDeliveryMethods().then((response) => {
        this.allDeliveryMethods = response.data.data.elements;
      });
    },
    loadDMName(id) {
      if (id) {
        const dm = this.allDeliveryMethods.filter((dm) => dm.id === id);
        if (dm[0]) {
          return dm[0].name;
        }
      }
    },
    initializeContract() {
      this.modalContractControl = "addContract";
      (this.newContract.first_mile_delivery_method_id = ""),
        (this.newContract.freight_forwarder_delivery_method_id = ""),
        (this.newContract.custom_clearence_delivery_method_id = ""),
        (this.newContract.last_mile_delivery_method_id = ""),
        (this.newContract.reverse_delivery_method_id = ""),
        (this.newContract.delivery_time_id = ""),
        (this.newContract.price_table_id = ""),
        (this.newContract.delivery_time_id = ""),
        (this.newContract.name = ""),
        (this.newContract.slug = ""),
        (this.newContract.origin_country = []),
        (this.newContract.destination_country = []),
        (this.newContract.incoterm = "BOTH"),
        (this.newContract.taxing_type = "postal"),
        (this.newContract.is_old_flow = false),
        (this.newContract.automatic_payment = false),
        (this.newContract.price_limit = 10000);

      setTimeout(() => {
        this.$helpers.openModal("addContract");
      }, 200);
    },
    currentAdditionalService(data) {
      const services = data.additional_services;
      this.currentContractAddService = data.name;
      this.additionalServices = [];
      services.forEach((service) => {
        if (service.trigger_option && service.trigger_condition) {
          this.additionalServices.push(service);
        }
      });
      this.$helpers.openModal("showAdditionalService");
    },
    updateMassiveContract() {
      const data = {
        ids: this.selected,
        beginning_date: this.newContract.beginning_date,
        end_date: this.newContract.end_date,
        approver_ids: this.selected_admins,
      };
      contractService
        .updateMassiveContracts(data)
        .then(() => {
          this.$helpers.closeModal("massive-update-date");
          this.selected_admins = [];
          this.reloadContracts();
        })
        .catch((error) => {
          const message_error = error.data.messages[0];
          this.$bvToast.toast(message_error, {
            title: "An error has ocurred",
            toaster: "b-toaster-top-right",
            variant: "danger",
            autoHideDelay: 10000,
            appendToast: true,
            solid: true,
          });
        });
    },
  },
  computed: {
    deliveryMethodsOptions() {
      return this.deliveryMethods.map((item) => ({
        value: `${item.id}`,
        text: `(${item.id}) ${item.name}`,
      }));
    },
    buttonOptions() {
      return [
        {
          text: this.$t("contractsPage.updateValidityDate"),
          value: "massiveUpdateDate",
        },
      ];
    },
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("contractsPage.title"),
          found: this.totalContracts,
          current: this.currentTable,
        },
      ];
    },
    tableColumns() {
      return [
        { key: "id", label: "ID" },
        {
          key: "name",
          label: this.$t("contractsPage.fields.name"),
        },
        {
          key: "slug",
          label: this.$t("contractsPage.fields.slug"),
        },
        {
          key: "first_mile_delivery_method_id",
          label: this.$t("contractsPage.fields.firstMileDeliveryMethod"),
        },
        {
          key: "freight_forwarder_delivery_method_id",
          label: this.$t("contractsPage.fields.freightForwarderDeliveryMethod"),
        },
        {
          key: "custom_clearence_delivery_method_id",
          label: this.$t("contractsPage.fields.customClearenceDeliveryMethod"),
        },
        {
          key: "last_mile_delivery_method_id",
          label: this.$t("contractsPage.fields.lastMileDeliveryMethod"),
        },
        {
          key: "reverse_delivery_method_id",
          label: this.$t("contractsPage.fields.reverseDeliveryMethod"),
        },
        {
          key: "price_table",
          label: this.$t("contractsPage.fields.priceTable"),
        },
        {
          key: "delivery_time",
          label: this.$t("contractsPage.fields.deliveryTime"),
        },
        {
          key: "price_limit",
          label: this.$t("contractsPage.fields.priceLmit"),
        },

        {
          key: "beginning_date",
          label: this.$t("beginningDate"),
        },
        { key: "end_date", label: this.$t("endDate"), sortable: false },
        {
          key: "incoterm",
          label: this.$t("contractsPage.fields.incoterm"),
        },
        {
          key: "automatic_payment",
          label: this.$t("contractsPage.fields.automaticPayment"),
        },
        {
          key: "max_weight",
          label: this.$t("contractsPage.fields.maximumWeight"),
        },
        {
          key: "max_width",
          label: this.$t("contractsPage.fields.maximumWidth"),
        },
        {
          key: "max_height",
          label: this.$t("contractsPage.fields.maximumHeight"),
        },
        {
          key: "max_length",
          label: this.$t("contractsPage.fields.maximumLength"),
        },
        {
          key: "additional_services",
          label: this.$t("contractsPage.fields.additionalServices"),
        },
        { key: "flow", label: this.$t("flow") },
        { key: "airport_to_door", label: this.$t("contractsPage.mode") },
        {
          key: "is_made_by_seller",
          label: this.$t("contractsPage.fields.isMadeBySeller"),
        },
        {
          key: "created_at",
          label: this.$t("contractsPage.fields.creationDate"),
          sortable: true,
        },
      ];
    },
    tableFilters() {
      return [
        {
          label: "ID",
          inputs: [{ key: "id" }],
        },
        {
          label: "Name",
          inputs: [{ key: "name" }],
        },
        {
          label: "Slug",
          inputs: [{ key: "slug" }],
        },
        {
          label: this.$t("contractsPage.fields.firstMileDeliveryMethod"),
          inputs: [
            {
              key: "first_mile_delivery_method_id",
              type: "select",
              options: this.deliveryMethodsOptions,
            },
          ],
        },
        {
          label: this.$t("contractsPage.fields.freightForwarderDeliveryMethod"),
          inputs: [
            {
              key: "freight_forwarder_delivery_method_id",
              type: "select",
              options: this.deliveryMethodsOptions,
            },
          ],
        },
        {
          label: this.$t("contractsPage.fields.customClearenceDeliveryMethod"),
          inputs: [
            {
              key: "custom_clearence_delivery_method_id",
              type: "select",
              options: this.deliveryMethodsOptions,
            },
          ],
        },
        {
          label: this.$t("contractsPage.fields.lastMileDeliveryMethod"),
          inputs: [
            {
              key: "last_mile_delivery_method_id",
              type: "select",
              options: this.deliveryMethodsOptions,
            },
          ],
        },
        {
          label: this.$t("contractsPage.fields.reverseDeliveryMethod"),
          inputs: [
            {
              key: "reverse_delivery_method_id",
              type: "select",
              options: this.deliveryMethodsOptions,
            },
          ],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("contractsPage.fields.priceLmit"),
          inputs: [
            {
              key: "start_price_limit",
              placeholder: "Min",
            },
            {
              key: "end_price_limit",
              placeholder: "Max",
            },
          ],
        },
        {
          label: this.$t("contractsPage.mode"),
          inputs: [
            {
              key: "airport_to_door",
              type: "select",
              options: [
                {
                  text: "A2D",
                  value: "true",
                },
                {
                  text: "D2D",
                  value: "false",
                },
              ],
            },
          ],
        },
        {
          label: this.$t("flow"),
          inputs: [
            {
              key: "is_old_flow",
              type: "select",
              options: [
                {
                  text: this.$t("contractsPage.old"),
                  value: "true",
                },
                {
                  text: this.$t("contractsPage.new"),
                  value: "false",
                },
              ],
            },
          ],
        },
        {
          label: this.$t("contractsPage.fields.automaticPayment"),
          inputs: [
            {
              key: "automatic_payment",
              type: "boolean",
            },
          ],
        },
        {
          label: this.$t("contractsPage.fields.incoterm"),
          inputs: [
            {
              key: "incoterm",
              type: "select",
              options: ["DDP", "DDU", "BOTH"].map((value) => ({
                text: value,
                value,
              })),
            },
          ],
        },
        {
          label: this.$t("beginningDate"),
          inputs: [
            {
              key: "start_beginning_date",
              type: "date",
            },
            {
              key: "end_beginning_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("endDate"),
          inputs: [
            {
              key: "start_end_date",
              type: "date",
            },
            {
              key: "end_end_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("weight"),
          inputs: [
            {
              key: "start_max_weight",
              type: "number",
              placeholder: "kg",
            },
            {
              key: "end_max_weight",
              type: "number",
              placeholder: "kg",
            },
          ],
        },
        {
          label: this.$t("contractsPage.fields.isMadeBySeller"),
          inputs: [
            {
              key: "is_made_by_seller",
              type: "boolean",
            },
          ],
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.invalid-feedback {
  display: block;
}
</style>
