<template>
  <div>
    <NbModal
      v-show="visibleAddContractModal"
      :id="id"
      modalConfig="modal-dialog-centered"
      width="80rem"
    >
      <template v-slot:header>
        <div class="d-flex justify-content-between w-100 mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{
              newContract.id
                ? $t("contractsViewPage.editContract")
                : $t("contractsPage.addContracts")
            }}
          </h5>
          <NbButton
            class="d-flex justify-content-start mr-5"
            size="sm"
            @click="showImportContractModal"
            v-b-popover.hover.left
            :title="$t('contractsPage.importContractFromJsonHelpText')"
          >
            <NbIcon icon="plus" class="mr-2" />
            {{ $t("contractsPage.importContract") }}
          </NbButton>
        </div>
      </template>
      <div class="mb-5">
        <NbNumberedAccordion
          id="accordion-wrapper-1"
          classes="bg-gray-00"
          :number="1"
          :title="$t('contractsViewPage.generalData')"
          :subTitle="$t('contractsViewPage.generalDataSubtitle')"
          :showAccordion="internalShowAccordion.generalData"
          @siwtchShowAccordion="siwtchShowAccordion('generalData', $event)"
        >
          <template #afterTitle>
            <span v-if="sectionErros.one" class="nb-badge bg-danger text-white">
              {{ $t("erro") }}
            </span>
          </template>
          <NbCard
            id="name-def-card"
            :title="$t('contractsViewPage.nameDefinitions')"
            class="px-3"
          >
            <template #body>
              <div class="grid grid-cols-3 gap-4">
                <NbTextInput
                  required
                  id="contract-name"
                  :name="$t('contractsViewPage.name')"
                  :placeholder="$t('contractsViewPage.name')"
                  :error="errors.name"
                  v-model="newContract.name"
                  :helpText="$t('contractsPage.nameHelpText')"
                />
                <NbTextInput
                  required
                  id="contract-slug"
                  :name="$t('contractsViewPage.slug')"
                  :placeholder="$t('contractsViewPage.slug')"
                  :error="errors.slug"
                  v-model="newContract.slug"
                  :helpText="$t('contractsPage.slugHelpText')"
                />
                <NbSelectInput
                  id="incoterm"
                  :name="$t('contractsViewPage.incoterm')"
                  :options="incoterms"
                  :error="errors.incoterm"
                  v-model="newContract.incoterm"
                  :helpText="$t('contractsPage.incotermHelpText')"
                />
                <NbRadio
                  id="automatic_payment"
                  :name="$t('contractsViewPage.automatizedPayment') + '?'"
                  :options="automatizedOptions"
                  :error="errors.automatic_payment"
                  v-model="newContract.automatic_payment"
                />
                <NbRadio
                  id="is_old_flow"
                  :name="$t('flow') + '?'"
                  :options="flowOptions"
                  :error="errors.is_old_flow"
                  v-model="newContract.is_old_flow"
                  :helpText="$t('contractsPage.flowHelpText')"
                />
                <NbRadio
                  id="airport_to_door"
                  :name="$t('contractsPage.mode')"
                  :options="modeOptions"
                  :error="errors.airport_to_door"
                  v-model="newContract.airport_to_door"
                  :helpText="$t('contractsPage.modeHelpText')"
                />
                <div class="col-span-3">
                  <!-- <NbSwitch
                    id="generate_payment_link"
                    class="mt-2"
                    :name="$t('contractsPage.botNotifications')"
                    :error="errors.generate_payment_link"
                    v-model="newContract.generate_payment_link"
                  /> -->
                  <div v-if="newContract.generate_payment_link">
                    <NbCheckbox
                      v-for="(value, key) in newContract.dutypay_notification"
                      :key="key"
                      :id="key"
                      class="w-100"
                      :name="transformString(key)"
                      v-model="newContract.dutypay_notification[key]"
                    />
                  </div>
                </div>

                <div class="col-span-3">
                  <label class="input-label w-100 mt-3">Logo*</label>
                  <div class="d-flex flex-column w-100">
                    <NbDropFile
                      :accept="[
                        'application/png',
                        'application/jpg',
                        'application/jpeg',
                      ]"
                      imgHeight="8rem"
                      v-model="chosenFile"
                      class="w-100"
                    >
                    </NbDropFile>
                    <div class="d-flex w-100 justify-content-center">
                      <img
                        v-if="chosenFileUrl"
                        :src="chosenFileUrl"
                        width="92px"
                        height="92px"
                        class="icon"
                        style="object-fit: contain"
                      />
                    </div>
                  </div>
                  <div
                    class="invalid-feedback w-100"
                    v-for="(error, index) in errors.logo"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </template>
          </NbCard>
          <NbCard
            id="term-card"
            :title="$t('contractsViewPage.termContract')"
            class="px-3 mt-3"
          >
            <template #body>
              <div class="d-flex pt-3">
                <NbDatePicker
                  id="beginning_date"
                  class="my-2 mx-2"
                  size="sm"
                  :name="$t('beginningDate')"
                  :error="errors?.beginning_date"
                  v-model="newContract.beginning_date"
                  :helpText="$t('contractsPage.beginAndDateHelpText')"
                />
                <NbDatePicker
                  id="end_date"
                  class="my-2 mx-2"
                  size="sm"
                  :name="$t('endDate')"
                  :error="errors?.end_date"
                  v-model="newContract.end_date"
                  :helpText="$t('contractsPage.beginAndDateHelpText')"
                />
              </div>
            </template>
          </NbCard>
        </NbNumberedAccordion>

        <NbNumberedAccordion
          id="accordion-wrapper-2"
          class="mt-3"
          classes="bg-gray-00"
          :number="2"
          :title="$t('contractsViewPage.deliveryMethods')"
          :subTitle="$t('contractsViewPage.deliveryMethodsSubtitle')"
          :showAccordion="internalShowAccordion.deliveryMethods"
          @siwtchShowAccordion="siwtchShowAccordion('deliveryMethods', $event)"
        >
          <template #afterTitle>
            <span v-if="sectionErros.two" class="nb-badge bg-danger text-white">
              {{ $t("erro") }}
            </span>
          </template>
          <div class="d-flexx">
            <div class="d-flex pb-4">
              <div class="mr-4 dashed-middle-vertically">
                <div class="text-gray-60 icon-stamp">
                  <NbIcon
                    icon="truck"
                    :attributes="{
                      'stroke-width': 3,
                      width: '1.2rem',
                      height: '1.2rem',
                    }"
                  />
                </div>
              </div>
              <NbCard
                id="first-mile-card"
                :title="$t('contractsViewPage.firstMileDM')"
                class="px-3 w-fill"
              >
                <template #body>
                  <div class="d-flex justify-content-between flex-wrap pt-3">
                    <div class="w-48-5 my-2">
                      <NbSelectInput
                        id="first_mile_delivery_method_id"
                        :name="$t('contractsViewPage.firstMileDM')"
                        :options="deliveryMethodsOptions"
                        placeholder="Select First Mile DM"
                        :error="errors.first_mile_delivery_method"
                        v-model="newContract.first_mile_delivery_method_id"
                      />
                      <NbButton
                        v-if="newContract.first_mile_delivery_method_id"
                        variant="tertiary"
                        @click="
                          clearSelectedDeliveryMethod(
                            'first_mile_delivery_method_id',
                          )
                        "
                      >
                        {{ $t("clearSelected") }}
                      </NbButton>
                    </div>
                    <div class="w-100 body-1">
                      <span class="text-gray-60">
                        {{ $t("contractsViewPage.notFoundDM") }}?
                      </span>
                      <a class="link-1" @click="goToDeliveryMethodsPage">
                        {{ $t("contractsViewPage.registerDM") }}>
                      </a>
                    </div>
                    <div class="w-100 mt-3">
                      <label class="input-label">
                        {{ $t("contractsViewPage.uniqueDM") }}
                      </label>
                      <div
                        class="link-1"
                        @click="
                          copyDMForAll(
                            newContract.first_mile_delivery_method_id,
                          )
                        "
                      >
                        {{ $t("contractsViewPage.uniqueDMSubtitle") }}
                      </div>
                    </div>
                  </div>
                </template>
              </NbCard>
            </div>

            <div class="d-flex pb-4">
              <div class="mr-4 dashed-middle-vertically">
                <div class="text-gray-60 icon-stamp">
                  <div class="icon">
                    <Plane />
                  </div>
                </div>
              </div>
              <NbCard
                id="freight-card"
                :title="$t('contractsViewPage.freightForwarderDM')"
                class="px-3 w-fill"
              >
                <template #body>
                  <div class="d-flex justify-content-between flex-wrap pt-3">
                    <div class="w-48-5 my-2">
                      <NbSelectInput
                        id="freight_forwarder_delivery_method_id"
                        :name="$t('contractsViewPage.freightForwarderDM')"
                        :options="deliveryMethodsOptions"
                        placeholder="Select Freight Forwarder DM"
                        :error="errors.freight_forwarder_delivery_method"
                        v-model="
                          newContract.freight_forwarder_delivery_method_id
                        "
                      />
                      <NbButton
                        v-if="newContract.freight_forwarder_delivery_method_id"
                        variant="tertiary"
                        @click="
                          clearSelectedDeliveryMethod(
                            'freight_forwarder_delivery_method_id',
                          )
                        "
                      >
                        {{ $t("clearSelected") }}
                      </NbButton>
                    </div>
                    <div class="w-100 body-1">
                      <span class="text-gray-60">
                        {{ $t("contractsViewPage.notFoundDM") }}?
                      </span>
                      <a class="link-1" @click="goToDeliveryMethodsPage">
                        {{ $t("contractsViewPage.registerDM") }}>
                      </a>
                    </div>
                  </div>
                </template>
              </NbCard>
            </div>

            <div class="d-flex pb-4 w-fill">
              <div class="mr-4 dashed-middle-vertically">
                <div class="text-gray-60 icon-stamp">
                  <NbIcon
                    icon="package"
                    :attributes="{
                      'stroke-width': 3,
                      width: '1.2rem',
                      height: '1.2rem',
                    }"
                  />
                </div>
              </div>
              <NbCard
                id="custom-clearence-card"
                :title="$t('contractsViewPage.customClearenceDM')"
                class="px-3 w-fill"
              >
                <template #body>
                  <div class="d-flex justify-content-between flex-wrap pt-3">
                    <div class="w-48-5 my-2">
                      <NbSelectInput
                        id="custom_clearence_delivery_method_id"
                        :name="$t('contractsViewPage.customClearenceDM')"
                        :options="deliveryMethodsOptions"
                        placeholder="Select Custom Clearence DM"
                        :error="errors.custom_clearence_delivery_method"
                        v-model="
                          newContract.custom_clearence_delivery_method_id
                        "
                        required
                      />
                      <NbButton
                        v-if="newContract.custom_clearence_delivery_method_id"
                        variant="tertiary"
                        @click="
                          clearSelectedDeliveryMethod(
                            'custom_clearence_delivery_method_id',
                          )
                        "
                      >
                        {{ $t("clearSelected") }}
                      </NbButton>
                    </div>
                    <div class="w-100 body-1">
                      <span class="text-gray-60">
                        {{ $t("contractsViewPage.notFoundDM") }}?
                      </span>
                      <a class="link-1" @click="goToDeliveryMethodsPage">
                        {{ $t("contractsViewPage.registerDM") }}>
                      </a>
                    </div>
                  </div>
                </template>
              </NbCard>
            </div>

            <div class="d-flex pb-4 w-fill">
              <div class="mr-4">
                <div class="text-gray-60 icon-stamp">
                  <NbIcon
                    icon="user"
                    :attributes="{
                      'stroke-width': 3,
                      width: '1.2rem',
                      height: '1.2rem',
                    }"
                  />
                </div>
              </div>
              <NbCard
                id="last-mile-card"
                :title="$t('contractsViewPage.lastMileDM')"
                class="px-3 w-fill"
              >
                <template #body>
                  <div class="d-flex justify-content-between flex-wrap pt-3">
                    <div class="w-48-5 my-2">
                      <NbSelectInput
                        id="last_mile_delivery_method_id"
                        :name="$t('contractsViewPage.lastMileDM')"
                        :options="deliveryMethodsOptions"
                        placeholder="Select Last Mile DM"
                        :error="errors.last_mile_delivery_method"
                        v-model="newContract.last_mile_delivery_method_id"
                        required
                      />
                      <NbButton
                        v-if="newContract.last_mile_delivery_method_id"
                        variant="tertiary"
                        @click="
                          clearSelectedDeliveryMethod(
                            'last_mile_delivery_method_id',
                          )
                        "
                      >
                        {{ $t("clearSelected") }}
                      </NbButton>
                    </div>
                    <div class="w-100 body-1">
                      <span class="text-gray-60">
                        {{ $t("contractsViewPage.notFoundDM") }}?
                      </span>
                      <a class="link-1" @click="goToDeliveryMethodsPage">
                        {{ $t("contractsViewPage.registerDM") }}>
                      </a>
                    </div>
                  </div>
                </template>
              </NbCard>
            </div>

            <div class="d-flex pb-4 w-fill">
              <div class="mr-4">
                <div class="text-gray-60 icon-stamp">
                  <NbIcon
                    icon="repeat"
                    :attributes="{
                      'stroke-width': 3,
                      width: '1.2rem',
                      height: '1.2rem',
                    }"
                  />
                </div>
              </div>
              <NbCard
                id="reverse-mile-card"
                :title="$t('contractsViewPage.reverseDM')"
                class="px-3 w-fill"
              >
                <template #body>
                  <div class="d-flex justify-content-between flex-wrap pt-3">
                    <div class="w-48-5 my-2">
                      <NbSelectInput
                        id="reverse_delivery_method_id"
                        :name="$t('contractsPage.reverseDM')"
                        :options="deliveryMethodsOptions"
                        placeholder="Select Reverse DM"
                        :error="errors.reverse_delivery_method"
                        v-model="newContract.reverse_delivery_method_id"
                      />
                      <NbButton
                        v-if="newContract.reverse_delivery_method_id"
                        variant="tertiary"
                        @click="
                          clearSelectedDeliveryMethod(
                            'reverse_delivery_method_id',
                          )
                        "
                      >
                        {{ $t("clearSelected") }}
                      </NbButton>
                    </div>
                    <div class="w-100 body-1">
                      <span class="text-gray-60">
                        {{ $t("contractsViewPage.notFoundDM") }}?
                      </span>
                      <a class="link-1" @click="goToDeliveryMethodsPage">
                        {{ $t("contractsViewPage.registerDM") }}>
                      </a>
                    </div>
                  </div>
                </template>
              </NbCard>
            </div>

            <NbCard
              id="prices-cubage-card"
              :title="$t('contractsViewPage.pricesMeasuresCubage')"
              :subTitle="$t('contractsViewPage.pricesMeasuresCubageSubtitle')"
              class="px-3 w-fill"
            >
              <template #body>
                <div class="d-flex justify-content-between flex-wrap pt-3">
                  <div class="w-32-5 my-2">
                    <NbTextInput
                      required
                      id="max_weight"
                      type="number"
                      :append="weightUnit"
                      :name="`${$t(
                        'contractsViewPage.maximumWeight',
                      )} (${weightUnit})`"
                      :decimals="3"
                      step="0.001"
                      :placeholder="`0.000`"
                      :error="errors.max_weight"
                      v-model="newContract.max_weight"
                    />
                  </div>
                  <div class="w-32-5 my-2">
                    <NbTextInput
                      required
                      id="max_length"
                      type="number"
                      :append="sizeUnit"
                      :name="`${$t(
                        'contractsViewPage.maximumLength',
                      )} (${sizeUnit})`"
                      :decimals="2"
                      step="0.01"
                      :placeholder="`0.00`"
                      :error="errors.max_length"
                      v-model="newContract.max_length"
                    />
                  </div>
                  <div class="w-32-5 my-2">
                    <NbTextInput
                      required
                      id="max_height"
                      type="number"
                      :append="sizeUnit"
                      :name="`${$t(
                        'contractsViewPage.maximumHeight',
                      )} (${sizeUnit})`"
                      :decimals="2"
                      step="0.01"
                      :placeholder="`0.00`"
                      :error="errors.max_height"
                      v-model="newContract.max_height"
                    />
                  </div>
                  <div class="w-32-5 my-2">
                    <NbTextInput
                      required
                      id="max_width"
                      type="number"
                      :append="sizeUnit"
                      :name="`${$t(
                        'contractsViewPage.maximumWidth',
                      )} (${sizeUnit})`"
                      :decimals="2"
                      step="0.01"
                      :placeholder="`0.00`"
                      :error="errors.max_width"
                      v-model="newContract.max_width"
                    />
                  </div>
                  <div class="w-32-5 my-2">
                    <NbTextInput
                      required
                      id="cubage_factor"
                      type="number"
                      :append="weightUnitCubage"
                      :name="`${$t(
                        'contractsViewPage.cubageFactor',
                      )} (${weightUnitCubage})`"
                      :decimals="3"
                      step="0.001"
                      :placeholder="`0.000`"
                      :error="errors.cubage_factor"
                      v-model="newContract.cubage_factor"
                    />
                    <div class="body-1 text-gray-60">
                      {{ $t("contractsViewPage.cubageCalcHelper") }}
                    </div>
                  </div>
                  <div class="w-32-5 my-2"></div>
                </div>
              </template>
            </NbCard>
          </div>
        </NbNumberedAccordion>

        <NbNumberedAccordion
          id="accordion-wrapper-3"
          class="mt-3"
          classes="bg-gray-00"
          :number="3"
          :title="$t('contractsViewPage.priceDeliveryTime')"
          :subTitle="$t('contractsViewPage.priceDeliveryTimeSubtitle')"
          :showAccordion="internalShowAccordion.priceDeliveryTime"
          @siwtchShowAccordion="
            siwtchShowAccordion('priceDeliveryTime', $event)
          "
        >
          <template #afterTitle>
            <span
              v-if="sectionErros.three"
              class="nb-badge bg-danger text-white"
            >
              {{ $t("erro") }}
            </span>
          </template>
          <NbCard
            id="price-deliverytimes-card"
            :title="$t('contractsViewPage.priceDeliveryTime')"
            class="px-3"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap pt-3">
                <NbSelectInput
                  id="price_table_id"
                  class="w-48-5 my-2"
                  :name="$t('contractsPage.priceTable')"
                  :options="priceTablesOptions"
                  placeholder="Select Price Table"
                  :error="errors.price_table"
                  v-model="newContract.price_table_id"
                />
                <NbSelectInput
                  id="delivery_time_id"
                  class="w-48-5 my-2"
                  :name="$t('contractsPage.deliveryTime')"
                  :options="deliveryTimesOptions"
                  placeholder="Select Delivery Time"
                  :error="errors.delivery_time_id"
                  v-model="newContract.delivery_time_id"
                />
                <NbTextInput
                  required
                  id="price_limit"
                  class="w-48-5 my-2"
                  type="money"
                  :prepend="currency"
                  :name="`${$t(
                    'contractsViewPage.maximumPrice',
                  )} (${currency})`"
                  :decimals="2"
                  step="0.01"
                  :placeholder="`0.00`"
                  :error="errors.price_limit"
                  v-model="newContract.price_limit"
                />
              </div>
            </template>
          </NbCard>
        </NbNumberedAccordion>

        <NbNumberedAccordion
          id="accordion-wrapper-4"
          class="mt-3"
          classes="bg-gray-00"
          :number="4"
          :title="$t('contractsViewPage.destinationandOrigin')"
          :subTitle="$t('contractsViewPage.destinationandOriginSubtitle')"
          :showAccordion="internalShowAccordion.destinationOrigin"
          @siwtchShowAccordion="
            siwtchShowAccordion('destinationOrigin', $event)
          "
        >
          <template #afterTitle>
            <span
              v-if="sectionErros.four"
              class="nb-badge bg-danger text-white"
            >
              {{ $t("erro") }}
            </span>
          </template>
          <NbCard
            id="price-deliverytimes-card"
            :title="$t('contractsViewPage.nameDefinitions')"
            class="px-3"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap pt-3">
                <NbSelecMultipletInput
                  id="destination-country"
                  class="w-48-5 my-2"
                  :name="$t('contractsPage.destinationCountry')"
                  :options="countriesOptions"
                  placeholder="Select Destination Country"
                  :error="errors.destination_country"
                  @input="checkAllCountry('destination_country')"
                  v-model="newContract.destination_country"
                />
                <NbSelecMultipletInput
                  id="origin-country"
                  class="w-48-5 my-2"
                  :name="$t('contractsPage.originCountry')"
                  :options="countriesOptions"
                  placeholder="Select Origin Country"
                  :error="errors.origin_country"
                  @input="checkAllCountry('origin_country')"
                  v-model="newContract.origin_country"
                />
              </div>
            </template>
          </NbCard>
        </NbNumberedAccordion>

        <NbNumberedAccordion
          id="accordion-wrapper-5"
          class="mt-3"
          classes="bg-gray-00"
          :number="5"
          :title="$t('contractsViewPage.additionalServices')"
          :subTitle="$t('contractsViewPage.additionalServicesSubtitle')"
          :showAccordion="internalShowAccordion.showAdditionalServices"
          @siwtchShowAccordion="
            siwtchShowAccordion('showAdditionalServices', $event)
          "
        >
          <template #afterTitle>
            <span
              v-if="sectionErros.five"
              class="nb-badge bg-danger text-white"
            >
              {{ $t("erro") }}
            </span>
          </template>
          <NbCard id="addservices-card" class="px-3">
            <template #body>
              <div class="heading-3 mb-2 d-flex justify-content-between">
                <div class="mt-2">
                  {{ $t("contractsViewPage.additional") }}
                </div>
                <NbButton
                  variant="secondary"
                  @click="showNewServiceCreate = !showNewServiceCreate"
                >
                  {{ $t("contractsPage.newService") }}
                </NbButton>
              </div>
              <b-collapse v-model="showNewServiceCreate">
                <div
                  class="d-flex justify-content-between flex-wrap mt-2 border-top border-gray-20"
                >
                  <NbSelectInput
                    id="additional-external_id"
                    class="w-32-5 my-2"
                    :name="`
                      (${$t('contractsViewPage.externalID')})
                      ${$t('contractsViewPage.name')}
                    `"
                    :options="additionalServiceSlugsOptions"
                    placeholder="Select Price Table"
                    v-model="newAdditionalService.external_id"
                    required
                  />
                  <NbTextInput
                    id="additional-hs_codes"
                    class="w-66 my-2"
                    :helpText="$t('contractsViewPage.separeteByCommas')"
                    :name="$t('contractsViewPage.hSCodes')"
                    placeholder="123456, 654321"
                    v-model="newAdditionalService.hs_codes"
                  />

                  <NbSelectInput
                    id="additional-trigger_option"
                    class="w-32-5 my-2"
                    :name="$t('contractsPage.triggerOption')"
                    :options="triggerOptions"
                    :placeholder="$t('selectAnOption')"
                    v-model="newAdditionalService.trigger_option"
                    required
                    disabled
                  />
                  <NbTextInput
                    v-if="newAdditionalService.trigger_option !== 0"
                    id="additional-trigger_condition"
                    class="w-66 my-2"
                    :name="$t('contractsPage.triggerCondition')"
                    v-model="newAdditionalService.trigger_condition"
                    required
                  />
                  <NbSelecMultipletInput
                    v-else
                    id="triggerCondition-newAdditionalService"
                    class="w-66 my-2"
                    :name="$t('contractsPage.triggerCondition')"
                    :options="sortedNobordistCheckpoints"
                    placeholder="Select Checkpoint"
                    v-model="newAdditionalService.trigger_condition"
                    required
                  />

                  <NbSelectInput
                    id="additional-primary_variable"
                    class="w-32-5 my-2"
                    :name="$t('contractsPage.primaryVariable')"
                    :options="variableOptions"
                    :placeholder="$t('selectAnOption')"
                    v-model="newAdditionalService.primary_variable"
                    required
                  />
                  <NbSelectInput
                    id="additional-primary_type"
                    class="w-32-5 my-2"
                    :name="$t('contractsPage.primaryType')"
                    :options="variableTypesOptions"
                    :placeholder="$t('selectAnOption')"
                    v-model="newAdditionalService.primary_type"
                    required
                  />
                  <NbTextInput
                    id="additional-primary_value"
                    class="w-32-5 my-2"
                    :append="
                      newAdditionalService.primary_type === 1 ? '%' : null
                    "
                    :name="$t('contractsPage.primaryValue')"
                    v-model="newAdditionalService.primary_value"
                    required
                  />

                  <NbSelectInput
                    id="additional-secondary_variable"
                    class="w-32-5 my-2"
                    :name="$t('contractsPage.secondaryVariable')"
                    :options="variableOptions"
                    :placeholder="$t('selectAnOption')"
                    v-model="newAdditionalService.secondary_variable"
                  />
                  <NbSelectInput
                    id="additional-secondary_type"
                    class="w-32-5 my-2"
                    :name="$t('contractsPage.secondaryType')"
                    :options="variableTypesOptions"
                    :placeholder="$t('selectAnOption')"
                    v-model="newAdditionalService.secondary_type"
                  />
                  <NbTextInput
                    id="additional-secondary_value"
                    class="w-32-5 my-2"
                    :append="
                      newAdditionalService.secondary_type === 1 ? '%' : null
                    "
                    :name="$t('contractsPage.secondaryValue')"
                    v-model="newAdditionalService.secondary_value"
                  />

                  <AdditionalServiceDescription :data="newAdditionalService" />
                  <div class="w-10"></div>
                  <NbButton
                    variant="secondary"
                    :disabled="!customServiceIsValid"
                    @click="addCustomService"
                  >
                    {{ $t("contractsViewPage.addServices") }}
                  </NbButton>
                </div>
              </b-collapse>
            </template>
          </NbCard>

          <NbCard
            v-if="loadedAdditionalServices.length > 0"
            id="current-services-card"
            :title="$t('contractsViewPage.addedServices')"
            class="px-3 bg-gray-10 mt-3"
          >
            <template #body>
              <div
                v-for="(
                  loadedAdditionalService, index
                ) in loadedAdditionalServices"
                :key="loadedAdditionalService + index"
              >
                <div
                  class="d-flex justify-content-between flex-wrap mt-2 border-top border-gray-20"
                >
                  <NbSelectInput
                    :disabled="true"
                    :id="'additional-external_idcurrent-services' + index"
                    class="w-32-5 my-2"
                    :name="`
                    (${$t('contractsViewPage.externalID')})
                    ${$t('contractsViewPage.name')}
                  `"
                    :options="additionalServiceSlugsOptions"
                    placeholder="Select Price Table"
                    :error="
                      errors[`additional_services[${index}].name`] ||
                      errors[`additional_services[${index}].external_id`]
                    "
                    v-model="loadedAdditionalService.external_id"
                  />
                  <NbTextInput
                    :id="'additional-hs_codescurrent-services' + index"
                    class="w-65 my-2"
                    :helpText="$t('contractsViewPage.separeteByCommas')"
                    :name="$t('contractsViewPage.hSCodes')"
                    placeholder="123456, 654321"
                    :error="errors[`additional_services[${index}].hs_codes`]"
                    v-model="loadedAdditionalService.hs_codes"
                  />
                  <NbSelectInput
                    :id="'additional-trigger_optioncurrent-services' + index"
                    class="w-32-5 my-2"
                    :name="$t('contractsPage.triggerOption')"
                    :options="triggerOptions"
                    :placeholder="$t('selectAnOption')"
                    disabled
                    :error="
                      errors[`additional_services[${index}].trigger_option`]
                    "
                    v-model="loadedAdditionalService.trigger_option"
                  />
                  <NbTextInput
                    v-if="loadedAdditionalService.trigger_option !== 0"
                    :id="'additional-trigger_conditioncurrent-services' + index"
                    class="w-65 my-2"
                    :name="$t('contractsPage.triggerCondition')"
                    :error="
                      errors[`additional_services[${index}].trigger_condition`]
                    "
                    v-model="loadedAdditionalService.trigger_condition"
                  />
                  <NbSelecMultipletInput
                    v-else
                    :id="
                      'triggerCondition-loadedAdditionalServicecurrent-services' +
                      index
                    "
                    class="w-65 my-2"
                    :name="$t('contractsPage.triggerCondition')"
                    :options="sortedNobordistCheckpoints"
                    placeholder="Select Checkpoint"
                    :error="
                      errors[`additional_services[${index}].trigger_condition`]
                    "
                    v-model="loadedAdditionalService.trigger_condition"
                  />

                  <NbSelectInput
                    :id="'additional-primary_variablecurrent-services' + index"
                    class="w-32-5 my-2"
                    :name="$t('contractsPage.primaryVariable')"
                    :options="variableOptions"
                    :placeholder="$t('selectAnOption')"
                    :error="
                      errors[`additional_services[${index}].primary_variable`]
                    "
                    v-model="loadedAdditionalService.primary_variable"
                  />
                  <NbSelectInput
                    :id="'additional-primary_typecurrent-services' + index"
                    class="w-32-5 my-2"
                    :name="$t('contractsPage.primaryType')"
                    :options="variableTypesOptions"
                    :placeholder="$t('selectAnOption')"
                    :error="
                      errors[`additional_services[${index}].primary_type`]
                    "
                    v-model="loadedAdditionalService.primary_type"
                  />
                  <NbTextInput
                    :id="'additional-primary_valuecurrent-services' + index"
                    class="w-32-5 my-2"
                    :append="
                      loadedAdditionalService.primary_type === 1 ? '%' : null
                    "
                    :name="$t('contractsPage.primaryValue')"
                    :error="
                      errors[`additional_services[${index}].primary_value`]
                    "
                    v-model="loadedAdditionalService.primary_value"
                  />

                  <NbSelectInput
                    :id="
                      'additional-secondary_variablecurrent-services' + index
                    "
                    class="w-32-5 my-2"
                    :name="$t('contractsPage.secondaryVariable')"
                    :options="variableOptions"
                    :placeholder="$t('selectAnOption')"
                    :error="
                      errors[`additional_services[${index}].secondary_variable`]
                    "
                    v-model="loadedAdditionalService.secondary_variable"
                  />
                  <NbSelectInput
                    :id="'additional-secondary_typecurrent-services' + index"
                    class="w-32-5 my-2"
                    :name="$t('contractsPage.secondaryType')"
                    :options="variableTypesOptions"
                    :placeholder="$t('selectAnOption')"
                    :error="
                      errors[`additional_services[${index}].secondary_type`]
                    "
                    v-model="loadedAdditionalService.secondary_type"
                  />
                  <NbTextInput
                    :id="'additional-secondary_valuecurrent-services' + index"
                    class="w-32-5 my-2"
                    :append="
                      loadedAdditionalService.secondary_type === 1 ? '%' : null
                    "
                    :name="$t('contractsPage.secondaryValue')"
                    :error="
                      errors[`additional_services[${index}].secondary_value`]
                    "
                    v-model="loadedAdditionalService.secondary_value"
                  />
                  <div class="w-10"></div>
                  <NbButton
                    icon="trash"
                    variant="secondary"
                    @click="removeServiceFromAttributes(index)"
                  >
                    {{ $t("contractsViewPage.removeService") }}
                  </NbButton>
                </div>

                <AdditionalServiceDescription :data="loadedAdditionalService" />
              </div>
            </template>
          </NbCard>
        </NbNumberedAccordion>

        <div v-if="newContract.id" class="W-100 my-3">
          <Alert
            type="warning"
            icon="fas fa-exclamation-circle"
            :message="$t('contractsViewPage.needsSupervisorApproval')"
          />
          <div class="input-label mb-2">
            {{ $t("contractsViewPage.chooseWhoSendRequest") }}:
          </div>
          <NbCheckbox
            v-for="admin in admins"
            :key="admin.id"
            :id="admin.text"
            class="w-100"
            :name="admin.text"
            @input="setSelectedAdmin($event, admin.value)"
            v-model="admin.key"
          />
        </div>
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton :disabled="disableSaveContract" @click="checkContract()">
            {{ newContract.id ? $t("update") : $t("save") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <div>
      <!-- modals -->
      <NbModal
        id="modal-import-contract"
        modalConfig="modal-dialog-centered"
        width="500px"
        @close="closeImportModal()"
      >
        <template v-slot:header>
          <div class="heading-4">{{ $t("upload") }}</div>
        </template>
        <template v-slot:body>
          <NbDropFile
            v-model="jsonFile"
            :accept="['application/json']"
            :label="$t('alertLogsPage.browseFilesUpload')"
          />
          <div v-if="errors.length > 0">
            <p
              class="text-center text-danger"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error }}
            </p>
          </div>
        </template>
        <template v-slot:footer>
          <NbButton class="float-right mr-3" @click="sendFile()">
            {{ $t("upload") }}
          </NbButton>
          <!-- :disabled="loading || !modelValue" -->
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#modal-import-contract`"
            @click="closeImportModal()"
          >
            {{ $t("close") }}
          </NbButton>
        </template>
      </NbModal>
    </div>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbNumberedAccordion from "@/components/generic/NbNumberedAccordion.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";
import NbDatePicker from "@/components/generic/NbDatePicker.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import Plane from "@/components/Plane.vue";
import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";
import NbSelecMultipletInput from "@/components/input/select/NbSelecMultipletInput.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";
import Alert from "@/components/alerts/Alert.vue";

import OrderCostService from "@/services/OrderCostService";
import ContractService from "@/services/ContractService";
import PendingActionService from "@/services/PendingActionService";
import AdditionalServiceDescription from "./AdditionalServiceDescription.vue";
import { Variables, VariableTypes } from "./variables";

const orderCostService = new OrderCostService();
const contractService = new ContractService();
const pendingActionService = new PendingActionService();

export default {
  components: {
    NbModal,
    NbButton,
    NbNumberedAccordion,
    NbCard,
    NbTextInput,
    NbSelectInput,
    NbRadio,
    NbDatePicker,
    NbIcon,
    Plane,
    NbDropFile,
    NbSelecMultipletInput,
    // NbSwitch,
    NbCheckbox,
    Alert,
    AdditionalServiceDescription,
  },
  name: "ModalContractCreate",
  props: {
    id: {
      type: String,
      default: "ModalContractCreate",
    },
    contract: {
      type: Object,
      required: true,
    },
    contractAdditionalServices: {
      type: Array,
      default: () => [],
    },
  },
  provide() {
    const contractContext = {
      getServiceNameByExternalId: (id) => this.getServiceNameByExternalId(id),
    };

    Object.defineProperties(contractContext, {
      variables: {
        get: () => this.variableOptions,
        enumerable: true,
      },
      types: {
        get: () => this.variableTypesOptions,
        enumerable: true,
      },
    });

    return {
      contractContext,
    };
  },
  data() {
    return {
      visibleAddContractModal: true,
      jsonFile: "",
      weightUnit: "kg",
      weightUnitCubage: "km/m³",
      sizeUnit: "cm",
      currency: "$",
      errors: {},
      internalShowAccordion: {
        generalData: true,
        deliveryMethods: false,
        priceDeliveryTime: false,
        destinationOrigin: false,
        showAdditionalServices: false,
      },
      incoterms: [
        { value: "BOTH", text: "Both" },
        { value: "DDP", text: "DDP" },
        { value: "DDU", text: "DDU" },
      ],
      taxing_types: [
        { value: "postal", text: "POSTAL" },
        { value: "courier", text: "COURIER" },
      ],
      url_logo: "",
      chosenFile: "",
      allAdditionalServiceSlugs: [],
      newAdditionalService: {
        name: null,
        trigger_option: 0,
      },
      showNewServiceCreate: false,
      admins: [],
      selected_admins: [],
    };
  },
  created() {
    this.loadTariffsTypes();
    this.$store.dispatch("nobordist_checkpoints/fetchAllItems", "checkpoints");
    this.$store.dispatch("sellers/fetchAllItems", "sellers");
    this.$store.dispatch("delivery_methods/fetchAllItems", "delivery_methods");

    this.$store.dispatch(
      "price_tables/fetchNotBySellerItems",
      "pt_not_made_by_seller",
    );
    this.$store.dispatch(
      "delivery_times/fetchNotBySellerItems",
      "dt_not_made_by_seller",
    );
    this.$store.dispatch("countries/fetchAllItems", "countries");
    this.loadAdmins();
  },
  computed: {
    customServiceIsValid() {
      const {
        external_id,
        trigger_option,
        trigger_condition,
        primary_type,
        primary_variable,
        primary_value,
      } = this.newAdditionalService;

      return [
        external_id,
        trigger_option,
        trigger_condition,
        primary_type,
        primary_variable,
        primary_value,
      ].every((item) => {
        if (typeof item === "string") {
          return !!item.trim();
        }

        if (Array.isArray(item)) {
          return item.length > 0;
        }

        return item >= 0;
      });
    },
    newContract: {
      get() {
        return this.contract;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    sectionErros() {
      const finalResult = {
        one: this.hasErrorone(this.errors),
        two: this.hasErrortwo(this.errors),
        three: this.hasErrorthree(this.errors),
        four: this.hasErrorfour(this.errors),
        five: this.hasErrorfive(this.errors),
      };
      return finalResult;
    },
    loadedAdditionalServices: {
      get() {
        return this.contractAdditionalServices;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    triggerOptions() {
      return [
        { value: null, text: "Select option" },
        { value: 0, text: "Checkpoint" },
        { value: 1, text: "Action" },
        { value: 2, text: "Option" },
      ];
    },
    variableOptions() {
      return [
        { value: Variables.VOLUME, text: "Volume" },
        { value: Variables.ITEM, text: "Item" },
        { value: Variables.DAYS_KG, text: "Days x kg" },
        { value: Variables.KILO, text: "Kilo" },
      ];
    },
    variableTypesOptions() {
      return [
        { value: null, text: "Select option" },
        { value: VariableTypes.FLAT, text: "Flat" },
        { value: VariableTypes.PERCENT, text: "Percent" },
      ];
    },
    chosenFileUrl() {
      if (this.newContract.logo_url) {
        this.setUrlLogo(this.newContract.logo_url);
      }
      if (this.chosenFile) {
        return URL.createObjectURL(this.chosenFile);
      }
      return this.url_logo;
    },
    allPriceTables() {
      return this.$store.state["price_tables"].notBySellerItems;
    },
    priceTablesOptions() {
      const priceTables = this.allPriceTables.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.name}`,
      }));
      return priceTables;
    },
    allDeliveryTimes() {
      return this.$store.state["delivery_times"].notBySellerItems;
    },
    deliveryTimesOptions() {
      const deliveryTimes = this.allDeliveryTimes.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.name}`,
      }));
      return deliveryTimes;
    },
    allDeliveryMethods() {
      return this.$store.state.delivery_methods.all_items;
    },
    deliveryMethodsOptions() {
      const deliveryMethods = this.allDeliveryMethods.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.name}`,
      }));
      return deliveryMethods;
    },
    allCountries() {
      return this.$store.state.countries.all_items;
    },
    countriesOptions() {
      let options = [{ text: this.$t("anyCounties"), value: "all" }];
      this.allCountries.map((country) => {
        options.push({ text: country.name, value: country.alpha2_code });
      });
      return options;
    },
    allNobordistCheckpoints() {
      return this.$store.state["nobordist_checkpoints"].all_items;
    },
    sortedNobordistCheckpoints() {
      const finalResult = this.allNobordistCheckpoints
        .map(({ tracking_code, title }) => ({
          text: `${tracking_code} - ${title}`,
          value: tracking_code,
          isChecked: false,
        }))
        .sort((a, b) => (a.value > b.value ? 1 : -1));

      return finalResult;
    },
    modeOptions() {
      return [
        { text: this.$t("contractsPage.airporttoDoor"), value: true },
        { text: this.$t("contractsPage.doorToDoor"), value: false },
      ];
    },
    automatizedOptions() {
      return [
        { text: this.$t("contractsViewPage.automatized"), value: true },
        { text: this.$t("contractsViewPage.notAutomatized"), value: false },
      ];
    },
    flowOptions() {
      return [
        { text: this.$t("contractsPage.old"), value: true },
        { text: this.$t("contractsPage.new"), value: false },
      ];
    },
    additionalServiceSlugsOptions() {
      const options = this.allAdditionalServiceSlugs.map((item) => ({
        value: item.external_id,
        text: `(${item.external_id}) ${item.text_en}`,
        text_en: item.slug,
      }));
      return options;
    },
    disableSaveContract() {
      if (this.selected_admins.length > 0 || !this.newContract.id) {
        return false;
      }
      return true;
    },
  },
  methods: {
    getServiceNameByExternalId(id) {
      return (
        this.allAdditionalServiceSlugs.find((item) => item.external_id === id)
          ?.text_en || ""
      );
    },
    clearSelectedDeliveryMethod(key) {
      this.newContract[key] = "";
    },
    showImportContractModal() {
      // this.carrierDeliveryMethods = [];
      // this.carrierUsers = [];
      // this.selectedCarrierId = null;
      // this.setQueryParams();
      this.visibleAddContractModal = false;
      this.initializeContract();
      this.$helpers.openModal("modal-import-contract");
    },
    closeImportModal() {
      this.visibleAddContractModal = true;
      this.$helpers.closeModal("modal-import-contract");
      this.$emit("close");
    },
    initializeContract() {
      this.errors = [];
      (this.newContract.first_mile_delivery_method_id = ""),
        (this.newContract.freight_forwarder_delivery_method_id = ""),
        (this.newContract.custom_clearence_delivery_method_id = ""),
        (this.newContract.last_mile_delivery_method_id = ""),
        (this.newContract.reverse_delivery_method_id = ""),
        (this.newContract.delivery_time_id = ""),
        (this.newContract.price_table_id = ""),
        (this.newContract.delivery_time_id = ""),
        (this.newContract.name = ""),
        (this.newContract.slug = ""),
        (this.newContract.origin_country = []),
        (this.newContract.destination_country = []),
        (this.newContract.incoterm = "BOTH"),
        (this.newContract.taxing_type = "postal"),
        (this.newContract.is_old_flow = false),
        (this.newContract.automatic_payment = false),
        (this.newContract.price_limit = 10000);
    },
    sendFile() {
      if (!this.jsonFile) {
        return;
      }
      const reader = new FileReader();
      reader.onload = (data) => {
        if (data.target.result) {
          this.closeImportModal();
          this.importedFile = JSON.parse(data.target.result);
          this.fetchImportedContract();
        }
      };
      reader.readAsText(this.jsonFile);
    },
    fetchImportedContract() {
      this.newContract.name = this.importedFile.name;
      this.newContract.slug = this.importedFile.slug;
      this.newContract.beginning_date =
        this.importedFile.beginning_date.substring(0, 10);
      this.newContract.end_date = this.importedFile.end_date.substring(0, 10);
      this.newContract.incoterm = this.importedFile.incoterm;
      this.newContract.taxing_type = this.importedFile.taxing_type;
      this.newContract.max_height = this.importedFile.max_height;
      this.newContract.max_width = this.importedFile.max_width;
      this.newContract.max_length = this.importedFile.max_length;
      this.newContract.max_weight = this.importedFile.max_weight;
      this.newContract.cubage_factor = this.importedFile.cubage_factor;
      this.newContract.airport_to_door = this.importedFile.airport_to_door;
      this.newContract.automatic_payment = this.importedFile.automatic_payment;

      if (this.importedFile.dutypay_notification) {
        this.newContract.dutypay_notification =
          this.importedFile.dutypay_notification;
      }

      this.newContract.generate_payment_link =
        this.importedFile.generate_payment_link;
      this.newContract.is_old_flow = this.importedFile.is_old_flow;
      this.newContract.price_limit = this.importedFile.price_limit;
      this.setAdditionalServicesImportedFromFile(
        this.importedFile.additional_services,
      );

      this.newContract.destination_country =
        this.importedFile.destination_country;
      this.newContract.origin_country = this.importedFile.origin_country;

      if (this.importedFile?.encoded_logo) {
        const base64String = this.importedFile.encoded_logo.replace(/\n/g, "");

        const fileName = `${this.newContract.name}_logo.png`;
        const startString = "data:image/png;base64,";

        const base64Parse = base64String.startsWith("data:image")
          ? base64String
          : startString.concat(base64String);

        const file = this.base64ToFile(base64Parse, fileName);
        this.chosenFile = file;
      }
    },
    base64ToFile(base64String, filename) {
      const arr = base64String.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    setAdditionalServicesImportedFromFile(data = []) {
      const finalAdidionalServices = data.map((service) => {
        const triggerOptionMatch = new RegExp(service.trigger_option, "i");
        const primaryVariableMatch = new RegExp(service.primary_variable, "i");
        const primaryTypeMatch = new RegExp(service.primary_type, "i");
        const secondaryVariableMatch = new RegExp(
          service.secondary_variable,
          "i",
        );
        const secondaryTypeMatch = new RegExp(service.secondary_type, "i");
        const hsCodeValues = service.hs_codes;

        const triggerOptionValue = this.triggerOptions.find((option) =>
          triggerOptionMatch.test(option.text),
        )?.value;

        const primaryVariableValue = this.variableOptions.find((option) =>
          primaryVariableMatch.test(option.text),
        )?.value;
        const primaryTypeValue = this.variableTypesOptions.find((option) =>
          primaryTypeMatch.test(option.text),
        )?.value;

        const secondaryVariableValue = this.variableOptions.find((option) =>
          secondaryVariableMatch.test(option.text),
        )?.value;
        const secondaryTypeValue = this.variableTypesOptions.find((option) =>
          secondaryTypeMatch.test(option.text),
        )?.value;

        const triggerConditions = service.trigger_condition.map(Number);

        return {
          ...service,
          trigger_option: triggerOptionValue,
          primary_variable: primaryVariableValue,
          primary_type: primaryTypeValue,
          secondary_variable: secondaryVariableValue,
          secondary_type: secondaryTypeValue,
          trigger_condition: triggerConditions,
          hs_codes: hsCodeValues,
        };
      });
      this.loadedAdditionalServices = [];
      finalAdidionalServices.forEach((element) => {
        this.loadedAdditionalServices.push(element);
      });
    },
    hasErrorone(errors) {
      const usedKeys = [
        "name",
        "slug",
        "incoterm",
        "taxing_type",
        "automatic_payment",
        "is_old_flow",
        "airport_to_door",
        "generate_payment_link",
        "logo",
      ];
      return !!Object.keys(errors).find((keyName) =>
        usedKeys.includes(keyName),
      );
    },
    hasErrortwo(errors) {
      const usedKeys = [
        "first_mile_delivery_method",
        "freight_forwarder_delivery_method",
        "custom_clearence_delivery_method",
        "last_mile_delivery_method",
        "max_weight",
        "max_length",
        "max_height",
        "max_width",
        "cubage_factor",
      ];
      return !!Object.keys(errors).find((keyName) =>
        usedKeys.includes(keyName),
      );
    },
    hasErrorthree(errors) {
      const usedKeys = ["price_table", "delivery_time_id", "price_limit"];
      return !!Object.keys(errors).find((keyName) =>
        usedKeys.includes(keyName),
      );
    },
    hasErrorfour(errors) {
      const usedKeys = ["destination_country", "origin_country"];
      return !!Object.keys(errors).find((keyName) =>
        usedKeys.includes(keyName),
      );
    },
    hasErrorfive(errors) {
      const usedKeys = [
        "additional_services.name",
        "additional_services.external_id",
        "additional_services.hs_codes",
        "additional_services.trigger_option",
        "additional_services.trigger_condition",
        "additional_services.trigger_condition",
        "additional_services.primary_variable",
        "additional_services.primary_type",
        "additional_services.primary_value",
        "additional_services.secondary_variable",
        "additional_services.secondary_type",
        "additional_services.secondary_value",
      ];
      return !!Object.keys(errors).find((keyName) =>
        usedKeys.includes(keyName),
      );
    },

    setSelectedAdmin(event, adminId) {
      if (event) {
        this.selected_admins.push(adminId);
        return;
      }

      const index = this.selected_admins.indexOf(adminId);
      if (index !== -1) {
        this.selected_admins.splice(index, 1);
      }
    },
    loadAdmins() {
      pendingActionService.fetchAdminUsers().then((response) => {
        this.admins = response.data.data.map((admin) => ({
          text: admin.name,
          value: admin.id,
        }));
      });
    },
    transformString(str) {
      if (str && typeof str == "string") {
        let words = str.split("_");
        for (let i = 0; i < words.length; i++) {
          words[i] =
            words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
        }
        return words.join(" ");
      }
    },
    setUrlLogo(contractLogo) {
      this.url_logo = contractLogo;
    },
    removeServiceFromAttributes(index) {
      if (index > -1) {
        this.loadedAdditionalServices.splice(index, 1);
      }
    },
    addCustomService() {
      this.setNewAdditionalServiceName();
      this.loadedAdditionalServices.push(this.newAdditionalService);
      this.newAdditionalService = {
        trigger_option: 0,
      };
      //this.showNewServiceCreate = false;
    },
    setNewAdditionalServiceName() {
      this.newAdditionalService.slug = this.additionalServiceSlugsOptions.find(
        (service) => service.value === this.newAdditionalService.external_id,
      )?.text_en;
      this.newAdditionalService.name = this.newAdditionalService.slug;
    },
    loadTariffsTypes() {
      orderCostService.getTariffs().then((response) => {
        this.allAdditionalServiceSlugs = response.data.data;
      });
    },
    checkAllCountry(country_key) {
      if (this.newContract[country_key].includes("all")) {
        this.newContract[country_key].splice(
          0,
          this.newContract[country_key].length,
        );
        this.newContract[country_key].push("all");
      }
    },
    copyDMForAll(id) {
      this.newContract.custom_clearence_delivery_method_id = id;
      this.newContract.freight_forwarder_delivery_method_id = id;
      this.newContract.last_mile_delivery_method_id = id;
    },
    goToDeliveryMethodsPage() {
      this.$helpers.closeModal(this.id);
      this.$router.push("/contracts/delivery_methods");
    },
    siwtchShowAccordion(target, event) {
      this.internalShowAccordion = {
        generalData: false,
        deliveryMethods: false,
        priceDeliveryTime: false,
        destinationOrigin: false,
        showAdditionalServices: false,
      };

      if (target === "closeAll") {
        return;
      }

      this.internalShowAccordion[target] = event;
    },
    hsCodesStringToArray() {
      this.loadedAdditionalServices.forEach((service) => {
        if (typeof service.hs_codes === "string") {
          let hs_codes = service.hs_codes.replaceAll(" ", "");
          hs_codes = hs_codes.split(",");
          service.hs_codes = hs_codes;
        } else {
          service.hs_codes = [];
        }
      });
    },
    checkContract() {
      if (this.newContract.id) {
        this.updateContract();
        return;
      }
      this.saveContract();
    },
    validService(service) {
      return (
        service.trigger_option !== null &&
        service.trigger_condition !== null &&
        service.primary_variable !== null &&
        service.primary_type !== null &&
        service.primary_value !== null
      );
    },
    saveContract() {
      this.hsCodesStringToArray();

      this.newContract.additional_services_attributes = [];
      this.loadedAdditionalServices.forEach((service) => {
        if (this.validService(service)) {
          this.newContract.additional_services_attributes.push(service);
        }
      });

      let newContractData = new FormData();

      if (this.chosenFile) {
        newContractData.append("logo", this.chosenFile, this.chosenFile.name);
      }
      newContractData.append("json", JSON.stringify(this.newContract));

      contractService
        .setContract(newContractData)
        .then(() => {
          this.$emit("reloadContracts");
          this.$helpers.closeModal(this.id);
        })
        .catch((error) => {
          this.siwtchShowAccordion("closeAll");
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
    updateContract() {
      this.hsCodesStringToArray();

      this.newContract.additional_services_attributes =
        this.loadedAdditionalServices;
      this.newContract.approver_ids = this.selected_admins;
      let newContractData = new FormData();

      if (this.chosenFile) {
        newContractData.append("logo", this.chosenFile, this.chosenFile.name);
      }
      newContractData.append("json", JSON.stringify(this.newContract));

      contractService
        .updateContract(this.newContract.id, newContractData)
        .then(() => {
          this.$emit("reloadContract");
          this.$helpers.closeModal(this.id);
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
  },
  watch: {},
};
</script>

<style scoped>
.nb-card-icon-accordion {
  transition: all 0.3s ease;
  background: var(--white);
  border-radius: 0.25rem;
  padding: 2rem 3.12rem 0rem 3.12rem;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.nb-card-icon-accordion-body {
  transition: all 0.3s ease;
  background: var(--white);
  border-radius: 0.25rem;
  padding: 1rem 6rem 1rem 6rem;
}
.icon-stamp {
  background-color: var(--gray-10);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
}
.icon-stamp > .i,
.icon-stamp > .icon {
  margin-left: 1.4rem; /* calc is => (icon-stamp - icon)/2 */
}

.dashed-middle-vertically {
  z-index: 1;
  position: relative;
}

.dashed-middle-vertically:before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 2rem;
  left: 48%;
  border-left: 3px var(--gray-10) dashed;
  height: 100%;
}
</style>
