<template>
  <div class="stepper-item">
    <div class="trigger" :data-state="state" :disabled="disabled">
      <NbIcon v-if="isCompleted" icon="check" :size="16" />
      <NbIcon v-else icon="circle" :size="state === 'active' ? 16 : 10" />
    </div>
    <slot :state="state"></slot>
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
export default {
  props: {
    step: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["stepper"],
  components: {
    NbIcon,
  },
  computed: {
    state() {
      if (this.stepper.currentStep === this.step) {
        return "active";
      }

      if (this.stepper.currentStep > this.step) {
        return "completed";
      }

      return "default";
    },
    isCompleted() {
      return this.state === "completed";
    },
    isActive() {
      return this.state === "active";
    },
  },
  methods: {
    handleChangeStep() {
      this.stepper.setStep(this.step);
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper-item {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  flex: 1;

  .trigger {
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid var(--gray-10);
    background-color: transparent;
    outline-offset: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.25rem;
    width: 2.25rem;
    z-index: 10;
    position: relative;
    &::after {
      content: "";
      height: 2.5rem;
      width: 2.5rem;
      display: none;
      position: absolute;
      border-radius: 50%;
      background-color: var(--primary);
      animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
    }
    &[data-state="active"] {
      &::after {
        display: block;
      }
      background-color: var(--primary);
      color: #fff;
    }
    &[data-state="completed"] {
      outline: none;
      background-color: var(--primary);
      color: #fff;
      border-color: var(--primary);
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

@keyframes ping {
  75%,
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
</style>
