<template>
  <div class="spinner" :data-size="size"></div>
</template>
<script>
export default {
  props: {
    // sm, md, lg
    size: {
      type: String,
      default: "sm",
    },
  },
};
</script>
<style lang="scss" scoped>
.spinner {
  height: 1rem;
  width: 1rem;
  border: 2px solid var(--gray-10);
  border-top-color: var(--primary);
  animation: spin 1s linear infinite;
  border-radius: 50%;
  &[data-size="md"] {
    height: 1.5rem;
    width: 1.5rem;
  }
  &[data-size="lg"] {
    height: 2rem;
    width: 2rem;
    border-width: 4px;
  }
}
@keyframes spin {
  100% {
    transform: rotate(1turn);
  }
}
</style>
