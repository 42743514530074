import api from "./HttpService";

export default class DownloadsService {
  getDownloads() {
    return api.get("/v1/downloaded_files/");
  }
  loadProcessing(data = {}) {
    return api.post("/v1/downloaded_files/process_files", data);
  }
  generateLink(fileId) {
    return api.put("/v1/downloaded_files/" + fileId);
  }
}
