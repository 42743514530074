import Toast from "../components/toast/Toast.vue";

export const ToastPlugin = {
  install(Vue) {
    const ToastConstructor = Vue.extend(Toast);
    const instance = new ToastConstructor();

    document.body.appendChild(instance.$mount().$el);

    Vue.prototype.$toast = {
      show: (data) => {
        instance.show(data);
      },
      info: (message, title) => {
        instance.info(message, title);
      },
      success: (message, title) => {
        instance.success(message, title);
      },
      danger: (message, title) => {
        instance.danger(message, title);
      },
      warning: (message, title) => {
        instance.warning(message, title);
      },
    };
  },
};
