<template>
  <div>
    <header-title :title="$t('externalLogs.title')" />
    <layout-content
      :title="$t('externalLogs.title')"
      :namespace="namespace"
      :items="items"
      :filterable="true"
      :filter-options="filterOptions"
      :all-fields="allFields"
      :fields="fields"
    >
      <template #buttonsTop> </template>
      <template #buttonsAction> </template>
      <template #cell(id)="data">
        <div>
          <router-link :to="`/logs/external/` + data.item.id">{{
            data.value
          }}</router-link>
        </div>
      </template>
    </layout-content>
  </div>
</template>

<script>
import LayoutContent from "@/components/LayoutContent";
import HeaderTitle from "../../../components/HeaderTitle";

export default {
  name: "ExternalLogs",
  components: {
    LayoutContent,
    HeaderTitle,
  },
  data: () => {
    return {
      selectMode: "single",
      allFields: [],
      selected: [],
      perPage: 20,
      externalLogsPage: 1,
      namespace: "external_apis_logs",
    };
  },
  computed: {
    items() {
      const items = this.$store.state[this.namespace].items;
      items.forEach((element, index) => {
        if (items[index].response) {
          let extLogsString = JSON.stringify(items[index].response);
          items[index].response = extLogsString.slice(0, 50) + "...";
        }
      });
      return items;
    },
    fields() {
      return [
        { key: "id", label: "ID", sortable: false, thClass: ["small-content"] },
        {
          key: "api_name",
          label: this.$t("externalLogs.fields.apiName"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "function",
          label: this.$t("externalLogs.fields.function"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "user_id",
          label: this.$t("externalLogs.fields.userID"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "response",
          label: this.$t("externalLogs.fields.response"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "created_at",
          label: this.$t("externalLogs.fields.creationDate"),
          sortable: true,
          thClass: ["medium-content"],
        },
      ];
    },
    filterOptions() {
      return [
        { key: "api_name", label: this.$t("externalLogs.fields.apiName") },
        { key: "function", label: this.$t("externalLogs.fields.function") },
        {
          key: "user_id",
          label: this.$t("externalLogs.fields.userID"),
          type: "number",
        },
        {
          key: "registration_date",
          label: this.$t("externalLogs.fields.creationDate"),
          type: "dates",
        },
      ];
    },
  },
  created() {
    this.allFields = this.fields;
    //this.loadItems();
  },
  methods: {
    loadItems() {
      this.$store.dispatch(this.namespace + "/fetchItems", this.namespace);
    },
  },
};
</script>
