import { render, staticRenderFns } from "./AccordionTrigger.vue?vue&type=template&id=0371b973&scoped=true"
import script from "./AccordionTrigger.vue?vue&type=script&lang=js"
export * from "./AccordionTrigger.vue?vue&type=script&lang=js"
import style0 from "./AccordionTrigger.vue?vue&type=style&index=0&id=0371b973&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0371b973",
  null
  
)

export default component.exports