<template>
  <div>
    <header-title :title="title">
      <template #buttonsTop>
        <b-button
          pill
          variant="primary"
          class="mr-2 ml-2"
          @click="generateVolumeLabel(volume.id)"
        >
          {{ $t("volumesViewPage.label") }}
          <i class="far fa-file-alt fa-lg ml-1"></i>
        </b-button>
        <b-button
          pill
          variant="primary"
          class="mr-2 ml-2"
          @click="generateVolumeFirstMile(volume.id)"
        >
          {{ $t("volumesPage.fields.firstMileLabel") }}
          <i class="far fa-file-alt fa-lg ml-1"></i>
        </b-button>
        <b-button
          pill
          variant="primary"
          class="mr-2 ml-2"
          @click="generatePackingSlip(volume.order.id)"
        >
          {{ $t("volumesViewPage.packingSlip") }}
          <i class="far fa-file fa-lg"></i>
        </b-button>
        <b-button
          pill
          variant="primary"
          class="mr-2 ml-2"
          @click="generateCommercialInvoice(volume.order_id, volume.id)"
        >
          {{ $t("volumesViewPage.commercialInvoice") }}
          <i class="fas fa-file-alt"></i>
        </b-button>
      </template>
    </header-title>
    <div class="card bg-light border-white rounded py-4">
      <div class="row mx-5 my-2">
        <field-view
          :field="$t('volumesViewPage.height')"
          standardize="true"
          :val="volume.height"
        />
        <field-view
          v-if="
            volume.last_volume_dimension &&
            volume.last_volume_dimension.height != volume.height
          "
          :field="$t('volumesViewPage.heightbycurrier')"
          standardize="true"
          :val="volume.last_volume_dimension.height"
        />
        <field-view
          :field="$t('volumesViewPage.width')"
          standardize="true"
          :val="volume.width"
        />
        <field-view
          v-if="
            volume.last_volume_dimension &&
            volume.last_volume_dimension.width != volume.width
          "
          :field="$t('volumesViewPage.widthbycurrier')"
          standardize="true"
          :val="volume.last_volume_dimension.width"
        />
      </div>
      <div class="row mx-5 my-2">
        <field-view
          :field="$t('volumesViewPage.weight')"
          standardize="true"
          :val="volume.weight"
        />
        <field-view
          :field="$t('volumesViewPage.cubed_weight') + ' (kg)'"
          standardize="true"
          :val="volume.cubed_weight"
        />
        <field-view
          v-if="
            volume.last_volume_dimension &&
            volume.last_volume_dimension.weight != volume.weight
          "
          :field="$t('volumesViewPage.weightbycurrier')"
          standardize="true"
          :val="volume.last_volume_dimension.weight"
        />
        <field-view
          :field="$t('volumesViewPage.length')"
          standardize="true"
          :val="volume.length"
        />
        <field-view
          v-if="
            volume.last_volume_dimension &&
            volume.last_volume_dimension.length != volume.length
          "
          :field="$t('volumesViewPage.lengthbycurrier')"
          standardize="true"
          :val="volume.last_volume_dimension.length"
        />
      </div>
      <div class="row mx-5 my-2">
        <field-view
          v-if="volume.updated_weight"
          :field="$t('volumesViewPage.updatedWeight')"
          :val="volume.updated_weight"
        />
        <field-view
          :field="$t('volumesViewPage.orderID')"
          :val="volume.order_id"
          :link="`/shipping/orders?id=${volume.order_id}`"
          link-id=""
        />
      </div>
      <div class="row mx-5 my-2">
        <field-view
          :field="$t('volumesViewPage.creationDate')"
          :val="volume.created_at"
        />
        <field-view
          v-if="volume.current_status"
          :field="$t('volumesViewPage.currentStatus')"
          :val="volume.current_status.title"
        />
      </div>
      <div class="row mx-5 my-2">
        <field-view
          :field="$t('volumesViewPage.reference')"
          :val="volume.reference"
          :copyable="volume.reference ? true : false"
        />
        <field-view
          :field="$t('volumesViewPage.lastMileCarrierNumber')"
          :val="volume.last_mile_carrier_number"
          :copyable="volume.last_mile_carrier_number ? true : false"
        />
      </div>
      <div class="row mx-5 my-2">
        <field-view
          :field="$t('volumesViewPage.lastMileTrackingNumber')"
          :val="volume.last_mile_tracking_number"
          :copyable="volume.last_mile_tracking_number ? true : false"
        />

        <div
          v-if="$store.state.platform === 'total'"
          class="row mx-2 mt-3 d-flex justify-content-center"
        >
          <a
            v-if="$store.state.platform === 'total'"
            data-toggle="modal"
            data-target="#modal-lmtn"
            class="mr-5 my-2 p-2 position-absolute btn btn-primary float-center"
          >
            Edit
          </a>
        </div>

        <field-view
          :field="$t('volumesPage.fields.firstMileTrackingNumber')"
          :val="volume.first_mile_tracking_number"
          :copyable="volume.first_mile_tracking_number ? true : false"
        />

        <field-view
          v-if="volume.dir_number"
          :field="$t('volumesViewPage.dirNumber')"
          :val="volume.dir_number"
          :link="`/finances/fiscal_payments?dir_number=${volume.dir_number}`"
          link-id=""
        />
        <field-view
          v-else
          :field="$t('volumesViewPage.dirNumber')"
          :val="volume.dir_number"
        />
      </div>

      <div class="card border-white rounded my-5">
        <h6 class="ml-5 mb-3">{{ $t("volumesViewPage.valuesSummary") }}</h6>
        <div class="row mx-5 my-1">
          <!-- implementar getCurrency() -->
          <field-view
            :field="$t('volumesViewPage.declaredValue')"
            :val="`${
              getCurrency(volume.currency) +
              standardizeValue(volume.declared_value)
            } (Products ${standardizeValue(
              volume.products_value,
            )} + Freight ${standardizeValue(volume.freight_value)})`"
          />
          <field-view
            :field="$t('volumesViewPage.taxValue')"
            :val="`${
              getCurrency(volume.currency) + standardizeValue(volume.tax_value)
            } (Duties Tax ${standardizeValue(
              volume.duties_tax_value,
            )} + Sales Tax ${standardizeValue(volume.sales_tax_value)})`"
          />
          <field-view
            class="col-2"
            :field="$t('volumesViewPage.total')"
            :val="
              getCurrency(volume.currency) +
              standardizeValue(volume.declared_value + volume.tax_value)
            "
          />
        </div>
        <hr class="mx-5" />
        <div class="row mx-5 my-1">
          <field-view
            :field="$t('volumesViewPage.estimatedTaxValue')"
            :val="`${
              getCurrency(volume.currency) +
              standardizeValue(volume.estimated_tax_value)
            } (Sales Tax ${standardizeValue(
              volume.estimated_sales_tax_value,
            )} + Duties Tax ${standardizeValue(
              volume.estimated_duties_tax_value,
            )})`"
          />
          <field-view
            :field="$t('volumesViewPage.freightCost')"
            :val="
              getCurrency(volume.currency) +
              standardizeValue(volume.freight_cost)
            "
          />
          <field-view
            :field="$t('volumesViewPage.estimatedFreightCost')"
            :val="
              getCurrency(volume.currency) +
              standardizeValue(volume.estimated_freight_cost)
            "
          />
        </div>
        <hr class="mx-5" />
        <div class="row mx-5 my-1">
          <field-view
            :field="$t('volumesPage.fields.collectedIcms')"
            :val="
              getCurrency(volume.order.collected_taxes_currency || 'USD') +
              standardizeValue(volume.collected_icms)
            "
          />
          <field-view
            :field="$t('volumesPage.fields.collectedIi')"
            :val="
              getCurrency(volume.order.collected_taxes_currency || 'USD') +
              standardizeValue(volume.collected_ii)
            "
          />
        </div>
      </div>

      <br />
      <h6 class="mx-1 my-3 font-weight-light text-uppercase">
        {{ $t("volumesViewPage.items") }} ({{
          volume.order_items ? volume.order_items.length : "0"
        }})
      </h6>
      <b-table
        id="my-masterboxes"
        selectable
        select-mode="single"
        :items="volume.order_items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        small
        striped
        responsive="md"
        @row-selected="onRowSelected"
      >
        <template #cell(unit_products_value)="data">
          {{ getCurrency(volume.currency) }}{{ standardizeValue(data.value) }}
        </template>
        <template #cell(unit_freight_value)="data">
          {{ getCurrency(volume.currency) }}{{ standardizeValue(data.value) }}
        </template>
        <template #cell(unit_tax_value)="data">
          {{ getCurrency(volume.currency) }}{{ standardizeValue(data.value) }}
        </template>
      </b-table>

      <h6 class="mx-1 my-3 font-weight-light text-uppercase">
        {{ $t("volumesViewPage.trackinghistory") }}
      </h6>
      <b-table
        id="my-masterboxes"
        selectable
        select-mode="single"
        :items="volume.volume_checkpoints"
        :fields="checkpointFields"
        small
        striped
        responsive="md"
        @row-selected="onRowSelected"
      >
        <template #cell(date_iso)="data">
          {{ data.item.date_iso.slice(0, 10) }}
        </template>
        <template #cell(id)="data">
          <div class="link-1" @click="loadModalVolumeData(data.item.id)">
            {{ data.item.id }}
          </div>
        </template>

        <template #cell(status_code)="data">
          <div
            v-if="
              data.item.received_checkpoint &&
              data.item.received_checkpoint.status_code
            "
            class="link-1"
            @click="loadModalData(data.item.received_checkpoint.id)"
          >
            {{ data.item.received_checkpoint.status_code }}
          </div>
          <div v-else>-</div>
        </template>
      </b-table>
      <br />
      <div class="row justify-content-md-center h6">
        {{ $t("volumesViewPage.pages") }} : {{ currentItems }}
      </div>
      <b-pagination
        v-model="currentPage"
        aria-controls="my-masterboxes"
        align="center"
        size="sm"
        :total-rows="totalRows"
        :per-page="perPage"
        first-text="First"
        prev-text="Prev"
        next-text="Next"
        last-text="Last"
      ></b-pagination>
    </div>
    <!-- modals -->
    <ModalReceivedCheckpointsView :itemId="currentItemId" />

    <ModalVolumeCheckpointsView :itemId="currentVolumeId" />

    <div id="order-item" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content" style="width: 625px">
          <div class="modal-header">
            <h5 id="settingsTitle" class="modal-title">
              {{ $t("volumesViewPage.item") }}#{{ order_item.id }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mx-5 my-2">
              <field-view
                :field="$t('volumesViewPage.name')"
                :val="order_item.name"
              />
              <field-view
                :field="$t('volumesViewPage.value')"
                :val="order_item.value"
              />
            </div>
            <div class="row mx-5 my-2">
              <field-view
                :field="$t('volumesViewPage.hsCode')"
                :val="order_item.hs_code"
              />
              <field-view
                :field="$t('volumesViewPage.quantity')"
                :val="order_item.quantity"
              />
            </div>
            <div class="row mx-5 my-2">
              <field-view
                :field="$t('volumesViewPage.originCountry')"
                :val="order_item.origin_country"
              />
              <field-view
                :field="$t('volumesViewPage.decsription')"
                :val="order_item.description"
              />
            </div>
            <div class="row mx-5 my-2">
              <field-view
                :field="$t('volumesViewPage.weight')"
                :val="order_item.weight"
              />
              <field-view
                :field="$t('volumesViewPage.valueWithTax')"
                :val="order_item.value_with_tax"
              />
            </div>
          </div>
          <div class="modal-footer">
            <a
              id="orderItemClose"
              class="btn btn-secondary float-right ml-3"
              data-dismiss="modal"
              >{{ $t("close") }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- end modal -->

    <!-- modal lmtn -->
    <div class="modal fade" id="modal-lmtn" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content" style="width: 625px">
          <div class="modal-header">
            <h5 class="modal-title" id="lmtnEdit">
              Edit Last Mile Tracking Number
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="last-mile-tracking-number" class="text-danger"
                    >Attention! This action cannot be undone.</label
                  >
                  <input
                    v-model="lastMileTrackingNumber"
                    type="text"
                    class="form-control"
                    id="lmtn"
                    aria-describedby="lmtnHelp"
                    :placeholder="volume.last_mile_tracking_number"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a
              href="#"
              class="btn btn-primary float-right ml-3"
              v-on:click="updateLastMileTrackingNumber(lastMileTrackingNumber)"
            >
              Update
            </a>
            <a
              id="modalLmtnClose"
              href="#"
              class="btn btn-secondary float-right ml-3"
              data-dismiss="modal"
              >{{ $t("close") }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- end modal lmtn -->
  </div>
</template>

<script>
import { BPagination, BTable } from "bootstrap-vue";
import FieldView from "@/components/FieldView";
import ModalReceivedCheckpointsView from "@/views/checkpoints/received_checkpoints/components/ModalReceivedCheckpointsView.vue";
import ModalVolumeCheckpointsView from "@/views/checkpoints/volume_checkpoints/components/ModalVolumeCheckpointsView.vue";
import HeaderTitle from "@/components/HeaderTitle";
import VolumeService from "../../../services/VolumeService";
import OrderLabelService from "../../../services/OrderLabelService";
import PackingSlipService from "../../../services/PackingSlipService";

const volumeService = new VolumeService();
const orderLabelService = new OrderLabelService();
const packingSlipService = new PackingSlipService();

export default {
  name: "VolumeView",

  components: {
    "b-pagination": BPagination,
    "b-table": BTable,
    "field-view": FieldView,
    HeaderTitle,
    ModalReceivedCheckpointsView,
    ModalVolumeCheckpointsView,
  },
  data: () => {
    return {
      volume: {},
      order_item: {},
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      lastMileTrackingNumber: "",
      currentItemId: "",
      currentVolumeId: "",
      errors: [],
    };
  },
  computed: {
    currentItems() {
      let end = this.currentPage * this.perPage;
      let begin = end - (this.perPage - 1);
      return `${begin} - ${end}`;
    },
    title() {
      return this.$t("volumesViewPage.title") + this.$route.params.id;
    },
    fields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "name",
          label: this.$t("volumesViewPage.fields.name"),
          sortable: false,
        },
        {
          key: "quantity",
          label: this.$t("volumesViewPage.fields.quantity"),
          sortable: false,
        },
        {
          key: "hs_code",
          label: this.$t("volumesViewPage.fields.hsCode"),
          sortable: false,
        },
        {
          key: "origin_country",
          label: this.$t("volumesViewPage.fields.originCountry"),
          sortable: false,
        },
        {
          key: "unit_products_value",
          label: this.$t("volumesViewPage.fields.productValue"),
          sortable: false,
        },
        {
          key: "unit_freight_value",
          label: this.$t("volumesViewPage.fields.freightValue"),
          sortable: false,
        },
        {
          key: "unit_tax_value",
          label: this.$t("volumesViewPage.fields.taxValue"),
          sortable: false,
        },
      ];
    },
    checkpointFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "checkpoint.tracking_code",
          label: "Tracking Code",
          sortable: false,
        },
        {
          key: "checkpoint.title",
          label: this.$t("volumesViewPage.checkpointFields.title"),
          sortable: false,
        },
        {
          key: "status_code",
          label: this.$t("volumesViewPage.checkpointFields.statusCode"),
          sortable: false,
        },
        {
          key: "date_iso",
          label: this.$t("volumesViewPage.checkpointFields.dateISO"),
          sortable: false,
        },
        {
          key: "message",
          label: this.$t("volumesViewPage.checkpointFields.message"),
          sortable: false,
        },
        {
          key: "received_checkpoint.tracking_function",
          label: this.$t("volumesViewPage.checkpointFields.trackingFunction"),
          sortable: false,
        },
      ];
    },
  },
  beforeMount() {
    this.reloadVolume();
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    checkQuery() {
      if (this.$route.query.receivedCheckpointId) {
        this.loadModalData(this.$route.query.receivedCheckpointId);
        return;
      }
      if (this.$route.query.volumeCheckpointId) {
        this.loadModalVolumeData(this.$route.query.volumeCheckpointId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;
        if (!this.$route.query.receivedCheckpointId) {
          this.$router.push({ query: { receivedCheckpointId: id } });
        }
        this.$helpers.openModal("ModalReceivedCheckpointsView");
        return;
      }
    },
    loadModalVolumeData(id) {
      if (id) {
        this.currentVolumeId = id;

        if (!this.$route.query.volumeCheckpointId) {
          this.$router.push({ query: { volumeCheckpointId: id } });
        }

        this.$helpers.openModal("ModalVolumeCheckpointsView");
        return;
      }
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    reloadVolume() {
      volumeService.getVolume(this.$route.params.id).then((response) => {
        this.volume = response.data.data;
        let time = this.volume.created_at;
        let date = this.volume.created_at;
        this.volume.created_at = this.formateDate(date, time);
      });
    },
    onRowSelected(item) {
      this.order_item = item[0];
      document.getElementById("orderItemClose").click();
    },
    generateCommercialInvoice(orderId, volumeId = {}) {
      orderLabelService
        .generateCommercialInvoiceLabel(orderId, volumeId)
        .then((response) => {
          if (response.data.data.download_url) {
            window.open(response.data.data.download_url);
          }
        });
    },
    generateVolumeLabel(volumeId) {
      orderLabelService.generateVolumeLabel(volumeId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    generateVolumeFirstMile(volumeId) {
      orderLabelService.generateVolumeFirstMile(volumeId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    generatePackingSlip(orderId) {
      packingSlipService.generate(orderId).then((response) => {
        if (response.data.data.download_url) {
          window.open(response.data.data.download_url);
        }
      });
    },
    standardizeValue(val) {
      const finalVal = parseFloat(val).toFixed(2);
      if (finalVal === "NaN") {
        return "0.00";
      }
      return finalVal;
    },
    getCurrency(currency) {
      if (currency) {
        if (currency.toUpperCase() === "USD") {
          return "$ ";
        }
        return "R$ ";
      }
      return;
    },
    updateLastMileTrackingNumber(lmtn) {
      volumeService
        .updateLastMileTrackingNumber(this.volume.id, lmtn)
        .then(() => {
          location.reload();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
  },
};
</script>
