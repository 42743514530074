<template>
  <div class="">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String, // default | always-open
      default: "default",
    },
  },
  data() {
    return {
      current: "",
    };
  },
  provide() {
    const accordion = {
      set: (data) => this.setCurrent(data),
    };

    Object.defineProperty(accordion, "current", {
      enumerable: true,
      get: () => this.current,
    });

    Object.defineProperty(accordion, "mode", {
      enumerable: true,
      get: () => this.mode,
    });

    return {
      accordion,
    };
  },
  methods: {
    setCurrent(data) {
      this.current = data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
