<template>
  <div>
    <div>
      <!-- Contract Permission -->
      <div v-if="pendingAction.data?.request">
        <div v-if="pendingAction.data?.contract_id">
          <p>
            {{ $t("notificationsPage.contract") }}:
            <a
              target="_blank"
              class="link-1"
              :href="`/contracts/contracts?contractId=${pendingAction.data['contract_id']}`"
            >
              {{
                getItemsInStore("contracts", pendingAction.data["contract_id"])
                  .name
              }}
              ({{ pendingAction.data.contract_id }})
            </a>
          </p>
        </div>
        <div v-if="pendingAction.data?.seller_id">
          <p>
            {{ $t("notificationsPage.seller") }}:
            <a
              target="_blank"
              :href="`/users_management/sellers?id=${pendingAction.data.seller_id}`"
            >
              {{
                getItemsInStore("sellers", pendingAction.data.seller_id).name
              }}
              ({{ pendingAction.data.seller_id }})
            </a>
          </p>
        </div>
      </div>
      <!-- Price Tables -->
      <div
        v-if="pendingAction.data?.price_table_params"
        data-dismiss="modal"
        aria-label="Close"
      >
        <p>
          {{ $t("notificationsPage.name") }}:
          <router-link :to="`/contracts/price_tables/`">
            {{ pendingAction.data?.price_table_params?.name }}
          </router-link>
        </p>
        <p>
          {{ $t("notificationsPage.file") }}:
          {{ `${pendingAction.data?.price_table_params?.name}.csv` }}
          <a id="action" type="button" @click="downloadCsv()">
            <i class="fas fa-external-link-alt"></i>
          </a>
        </p>
      </div>
      <!--  Contract Update  -->
      <div v-if="pendingAction.data?.ids">
        <p>
          {{ $t("notificationsPage.validityDate") }}:
          {{ pendingAction.data?.beginning_date }} -
          {{ pendingAction.data?.end_date }}
        </p>
        <p>{{ $t("notificationsPage.contracts") }}:</p>
        <div v-for="id in pendingAction.data?.ids" :key="id">
          -
          <a
            target="_blank"
            class="link-1"
            :href="`/contracts/contracts?contractId=${id}`"
          >
            {{ getItemsInStore("contracts", id).name }} ({{ id }})
          </a>
        </div>
      </div>
      <div v-if="pendingAction.data?.contract">
        <p>
          {{ $t("notificationsPage.contract") }}:
          <a
            target="_blank"
            class="link-1"
            :href="`/contracts/contracts?contractId=${pendingAction.data?.contract?.id}`"
          >
            {{
              getItemsInStore("contracts", pendingAction.data?.contract?.id)
                .name
            }}
            ({{ pendingAction.data?.contract?.id }})
          </a>
        </p>
        <p
          v-if="
            pendingAction?.status == 'pending' || pendingAction?.status == 0
          "
        >
          {{ $t("notificationsPage.click") }}
          <router-link
            data-dismiss="modal"
            aria-label="Close"
            :to="{
              name: 'contracts_view',
              params: {
                id: pendingAction.data?.contract?.id,
                contract_params: pendingAction?.data,
                isEditable: false,
                modal_notification: 'selectedNotificationId',
              },
            }"
          >
            {{ $t("notificationsPage.hERE") }}
          </router-link>
          {{ $t("notificationsPage.seeDetailedData") }}
        </p>
      </div>
    </div>

    <aside
      v-if="pendingAction.status !== 'pending' || pendingAction.status !== 0"
    >
      <div
        v-if="
          pendingAction.data?.request === pendingAction.data?.message &&
          pendingAction?.status
        "
      >
        {{ $t("notificationsPage.thisRequest") }}

        <span v-if="pendingAction?.status">{{ pendingActionText }}</span>
        <span
          v-if="
            pendingAction.data?.approver_name &&
            (pendingAction?.status == 'approved' ||
              pendingAction?.status == 'denied' ||
              pendingAction?.status == 1 ||
              pendingAction?.status == 2)
          "
        >
          {{ $t("notificationsPage.by") }}
          {{ pendingAction.data.approver_name }}
        </span>
      </div>
      <div
        v-else-if="
          pendingAction.data?.comment &&
          !(pendingAction.status == 'pending' || pendingAction.status == 0)
        "
        class="row mb-3"
      >
        <div class="col">
          {{ $t("notificationsPage.supervisorCommented") }}: "{{
            pendingAction.data?.comment
          }}"
        </div>
      </div>

      <div v-else-if="pendingAction.data?.error_message" class="row mb-3 mt-3">
        <div class="col">
          <p>{{ pendingAction.data?.error_message }}</p>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  props: {
    pendingAction: {
      type: Object,
      required: true,
    },
    notificationId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    pendingActionText() {
      if (
        this.pendingAction?.status == "pending" ||
        this.pendingAction?.status == 0
      ) {
        return this.$t("notificationsPage.is");
      }

      if (
        this.pendingAction.status == "approved" ||
        this.pendingAction.status == 1
      ) {
        return this.$t("notificationsPage.hasBeen");
      }

      if (
        this.pendingAction.status == "denied" ||
        this.pendingAction.status == 2
      ) {
        return this.$t("notificationsPage.hasBeen");
      }

      if (
        this.pendingAction.status == "failed" ||
        this.pendingAction.status == 3
      ) {
        return this.displayStatus(this.pendingAction.status);
      }

      return "";
    },
  },
  methods: {
    displayStatus(status) {
      if (status == 0 || status == "pending") {
        return "PENDING";
      } else if (status == 1 || status == "approved") {
        return "APPROVED";
      } else if (status == 2 || status == "denied") {
        return "DENIED";
      } else {
        return "FAILED";
      }
    },
    downloadCsv() {
      const data = this.generateCsvData(this.pendingAction.data);
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute(
        "download",
        `${this.pendingAction.data["price_table_params"]["name"]}.csv`,
      );
      a.click();
    },
    getItemsInStore(namespace, id) {
      const finalItems = this.$store.state[namespace].all_items.filter(
        (item) => item.id == id,
      );
      if (finalItems.length > 0) {
        return finalItems[0];
      }
      return finalItems;
    },
  },
};
</script>

<style lang="scss" scoped></style>
