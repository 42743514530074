<template>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <NotFoundDraw />
    <h2 class="heading-5 text-gray-10 my-4">
      {{ $t("notFoundPage.pageNotFound") }}
    </h2>
    <NbButton @click="goToHome">
      {{ $t("notFoundPage.goToHome") }}
    </NbButton>
  </div>
</template>

<script>
import NotFoundDraw from "./NotFoundDraw.vue";
import NbButton from "@/components/buttons/NbButton.vue";
export default {
  components: { NotFoundDraw, NbButton },
  methods: {
    goToHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped></style>
