import api from "./HttpService";

export default class GeneralEmailService {
  /**
   * Get emails
   *
   *
   * @returns {Promise}
   */
  getEmails(filters = {}) {
    return api.get("/v1/general_emails", {
      params: filters,
    });
  }

  /**
   * Get email
   *
   *
   * @returns {Promise}
   */
  getEmail(id) {
    return api.get("/v1/general_emails/" + id);
  }

  /**
   * Update log
   *
   *
   * @param id
   * @param data
   * @returns {Promise}
   */
  postEmail(data) {
    return api.post("/v1/general_emails/new", data);
  }

  updateGeneralEmail(id, data) {
    return api.put("/v1/general_emails/" + id, data);
  }

  deleteGeneralEmail(id) {
    return api.delete("/v1/general_emails/" + id);
  }
}
