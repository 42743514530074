import { render, staticRenderFns } from "./PlatformEmailAddressModal.vue?vue&type=template&id=34e2ba10&scoped=true"
import script from "./PlatformEmailAddressModal.vue?vue&type=script&lang=js"
export * from "./PlatformEmailAddressModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34e2ba10",
  null
  
)

export default component.exports