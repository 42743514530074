<template>
  <div>
    <NbPageTitle
      class="pb-3 page-container"
      :title="$t('alertLogsPage.title')"
      :subTitle="$t('alertLogsPage.subTitle')"
    >
      <template #title>
        <NbHelpText
          id="checkpoint-help"
          class="mx-1 text-gray-40"
          size="lg"
          placement="topright"
        >
          {{ $t("alertLogsPage.helpTextTitle") }}
        </NbHelpText>
      </template>

      <div class="d-flex flex-column gap-2">
        <NbButton variant="secondary" @click="handleUploadPdfModal">
          <NbIcon icon="upload-cloud" :size="18" />
          {{ $t("alertLogsPage.importPaymentCertificates") }}
        </NbButton>
        <NbButton variant="secondary" @click="handleImportValueStatus">
          <NbIcon icon="upload-cloud" :size="18" />
          {{ $t("alertLogsPage.importValuesandStatuses") }}
        </NbButton>
      </div>
    </NbPageTitle>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <NbAlertLogs
        ref="content"
        :class="currentTable == 'alertLogs' ? '' : 'display-hidde-absolute'"
        @switchTab="switchTab($event)"
        @total="totalAlertLogs = $event"
      />
    </section>

    <NbFooter
      class="page-container"
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbAlertLogs from "@/views/logs/alert_logs/NbAlertLogs.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

import NbFooter from "@/components/pagescomponents/NbFooter.vue";

export default {
  name: "LogsList",
  components: {
    NbPageTitle,
    NbHelpText,
    NbTabs,
    NbAlertLogs,
    NbFooter,
    NbButton,
    NbIcon,
  },
  data: () => {
    return {
      currentTable: "alertLogs",
      totalAlertLogs: 0,
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "alertLogs",
          label: this.$t("alertLogsPage.alertLogs"),
          found: this.totalAlertLogs || 0,
          current: this.currentTable,
        },
      ];
    },
  },
  created() {},
  methods: {
    switchTab(tab) {
      this.currentTable = tab;
    },
    handleUploadPdfModal() {
      this.$refs?.content?.uploadPdfModal();
    },
    handleImportValueStatus() {
      this.$refs?.content?.importValueStatus();
    },
  },
};
</script>
