import actions from "./actions";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: {
    keepFiltering: false,
    /* manter filtro em todas as paginas
      permanentFilter: false, 
      */
  },
  mutations: mutations,
  actions: actions,
};
