import { addTimeAndDate } from "../../shared/functions";
import GlobalService from "@/services/GlobalService";
const globalService = new GlobalService();

export default {
  async fetchItems({ state, commit }, namespace) {
    const res = await globalService.getTwentyItems(
      namespace,
      state.currentPage,
      state.limit,
      state.filters,
    );
    const items = new addTimeAndDate(res.data.data.elements);
    const totalRows = res.data.data.count;
    commit("SET_ITEMS", items);
    commit("SET_TOTAL_ROWS", totalRows);
  },
  async fetchAllItems({ commit }, namespace) {
    const res = await globalService.getAllItems(namespace);
    const items = res.data.data.elements;
    commit("SET_ALL_ITEMS", items);
  },
  async fetchNotBySellerItems({ state, commit }, namespace) {
    const res = await globalService.getTwentyItems(
      namespace,
      state.currentPage,
      state.limit,
      state.filters,
    );
    const notBySellerItems = new addTimeAndDate(res.data.data.elements);
    const notBySellerTotalRows = res.data.data.count;
    commit("SET_NOT_BY_SELLER_ITEMS", notBySellerItems);
    commit("SET_NOT_BY_SELLER_TOTAL_ROWS", notBySellerTotalRows);
  },
};
