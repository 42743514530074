import api from "./HttpService";

export default class PrintingService {
  getPrinting() {
    return api.get("/v1/printing");
  }
  updatePrinting(data) {
    return api.put("/v1/printing", data);
  }
}
