<template>
  <div :class="['stepper-separator', { completed }]"></div>
</template>

<script>
export default {
  props: {
    completed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper-separator {
  height: 0.125rem;
  background-color: var(--gray-20);
  position: absolute;
  display: block;
  flex-shrink: 0;
  top: 1.1rem;
  right: calc(-50% + 10px);
  left: calc(50% + 20px);
  &.completed {
    background-color: var(--primary);
  }
}
</style>
