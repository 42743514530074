<template>
  <div class="costs">
    <section>
      <h3 class="heading-3 m-0">
        {{ $t("volumesViewPage.exchangeOnCreation") }}
      </h3>
      <div class="info">
        <small>{{ $t("volumesPage.fields.currency") }}</small>
        <span>{{ volume.order.currency }}</span>
      </div>
    </section>

    <section>
      <h3 class="heading-3 m-0">{{ $t("volumesViewPage.freight") }}</h3>
      <div class="grid grid-cols-6 gap-4">
        <div class="info">
          <div>
            <small>{{ $t("volumesViewPage.freightValue") }}</small>
            <NbHelpText
              id="freight-value-popover"
              class="mx-1"
              size="sm"
              placement="topright"
            >
              {{ $t("volumesViewPage.informedBySeller") }}
            </NbHelpText>
          </div>
          <span>{{ volume.currency }} {{ volume.freight_value }}</span>
        </div>

        <div class="info">
          <div>
            <small>{{ $t("volumesViewPage.estimatedFreightCost") }}</small>
            <NbHelpText
              id="estimated-freight-cost-popover"
              class="mx-1"
              size="sm"
              placement="topright"
            >
              {{ $t("volumesViewPage.estimatedFreightCostDescription") }}
            </NbHelpText>
          </div>
          <span>{{ volume.currency }} {{ volume.estimated_freight_cost }}</span>
        </div>

        <div class="info">
          <div>
            <small>{{ $t("volumesViewPage.freightCost") }}</small>
            <NbHelpText
              id="freight-cost-popover"
              class="mx-1"
              size="sm"
              placement="topright"
            >
              {{ $t("volumesViewPage.freightCostDescription") }}
            </NbHelpText>
          </div>
          <span>{{ volume.currency }} {{ volume.freight_cost }}</span>
        </div>
      </div>
    </section>

    <section>
      <h3 class="heading-3 m-0">
        {{ $t("volumesViewPage.estimatedTaxes") }}
        <NbHelpText
          id="estimated-tax-popover"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("volumesViewPage.computedByNbSystem") }}
        </NbHelpText>
      </h3>
      <div class="grid grid-cols-6 gap-4">
        <div class="info">
          <div>
            <small>{{ $t("volumesViewPage.dutiesTax") }}</small>
            <NbHelpText
              id="duties-tax-popover"
              class="mx-1"
              size="sm"
              placement="topright"
            >
              {{ $t("volumesViewPage.dutiesTaxDescription") }}
            </NbHelpText>
          </div>
          <span>
            {{ volume.currency }} {{ volume.estimated_duties_tax_value }}
          </span>
        </div>

        <div class="info">
          <div>
            <small>{{ $t("volumesViewPage.salesTax") }}</small>
            <NbHelpText
              id="sales-tax-popover"
              class="mx-1"
              size="sm"
              placement="topright"
            >
              {{ $t("volumesViewPage.salesTaxDescription") }}
            </NbHelpText>
          </div>
          <span>
            {{ volume.currency }} {{ volume.estimated_sales_tax_value }}
          </span>
        </div>

        <div class="info col-span-4">
          <small>{{ $t("volumesViewPage.totalTax") }}</small>
          <span>
            {{ $t("volumesViewPage.dutiesTax") }}:
            {{ volume.estimated_duties_tax_value }} +
            {{ $t("volumesViewPage.salesTax") }}:
            {{ volume.estimated_sales_tax_value }} =
            {{ $t("volumesViewPage.totalTax") }}: {{ volume.currency }}
            {{ volume.estimated_tax_value }}
          </span>
        </div>
      </div>
    </section>

    <section>
      <h3 class="heading-3 m-0">
        {{ $t("volumesViewPage.actualTaxes") }}
        <NbHelpText
          id="actual-taxes-popover"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("volumesViewPage.informedBySeller") }}
        </NbHelpText>
      </h3>
      <div class="grid grid-cols-6 gap-4">
        <div class="info">
          <div>
            <small>{{ $t("volumesViewPage.dutiesTax") }}</small>
            <NbHelpText
              id="duties-tax-value-popover"
              class="mx-1"
              size="sm"
              placement="topright"
            >
              {{ $t("volumesViewPage.dutiesTaxDescription") }}
            </NbHelpText>
          </div>
          <span> {{ volume.currency }} {{ volume.duties_tax_value }} </span>
        </div>

        <div class="info">
          <div>
            <small>{{ $t("volumesViewPage.salesTax") }}</small>
            <NbHelpText
              id="sales-tax-value-popover"
              class="mx-1"
              size="sm"
              placement="topright"
            >
              {{ $t("volumesViewPage.salesTaxDescription") }}
            </NbHelpText>
          </div>
          <span> {{ volume.currency }} {{ volume.sales_tax_value }} </span>
        </div>

        <div class="info col-span-4">
          <small>{{ $t("volumesViewPage.totalTax") }}</small>
          <span>
            {{ $t("volumesViewPage.dutiesTax") }}:
            {{ volume.duties_tax_value }} +
            {{ $t("volumesViewPage.salesTax") }}: {{ volume.sales_tax_value }} =
            {{ $t("volumesViewPage.totalTax") }}: {{ volume.currency }}
            {{ volume.tax_value }}
          </span>
        </div>
      </div>
    </section>

    <section>
      <h3 class="heading-3 m-0">{{ $t("volumesViewPage.summary") }}</h3>
      <div class="grid grid-cols-6 gap-4">
        <div class="info">
          <small>{{ $t("volumesViewPage.productsValue") }}</small>
          <span> {{ volume.currency }} {{ volume.products_value }} </span>
        </div>

        <div class="info">
          <small>{{ $t("volumesViewPage.freightValue") }}</small>
          <span> {{ volume.currency }} {{ volume.freight_value }} </span>
        </div>

        <div class="info col-span-4">
          <small>{{ $t("volumesViewPage.declaredValue") }}</small>
          <span>
            {{ $t("volumesViewPage.products") }}: {{ volume.products_value }} +
            {{ $t("volumesViewPage.freight") }}: {{ volume.freight_value }} =
            {{ volume.currency }} {{ volume.declared_value }}
          </span>
        </div>

        <div class="info">
          <small>{{ $t("volumesViewPage.taxValue") }}</small>
          <span>{{ volume.currency }} {{ volume.tax_value }}</span>
        </div>

        <div class="info col-span-4">
          <small>{{ $t("volumesViewPage.totalValue") }}</small>
          <span>
            {{ $t("volumesViewPage.declaredValue") }}:
            {{ volume.declared_value }} + {{ $t("volumesViewPage.taxValue") }}:
            {{ volume.tax_value }} =
            {{ volume.currency }}
            {{ volume.total_value }}
          </span>
        </div>
      </div>
    </section>

    <section>
      <h3 class="heading-3 m-0">
        PRC
        <NbHelpText
          id="prc-popover"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("volumesViewPage.prcWarningDescription") }}
        </NbHelpText>
      </h3>

      <div class="grid grid-cols-6 gap-4">
        <div class="info">
          <small>{{ $t("volumesPage.fields.collectedIcms") }}</small>
          <span>
            {{ volume.order?.collected_taxes_currency }}
            {{ volume?.collected_icms || "-" }}
          </span>
        </div>

        <div class="info">
          <small>{{ $t("volumesPage.fields.collectedIi") }}</small>

          <span>
            {{ volume.order?.collected_taxes_currency }}
            {{ volume?.collected_ii || "-" }}
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NbHelpText from "@/components/generic/NbHelpText.vue";

export default {
  props: {
    volume: {
      type: Object,
      required: true,
    },
  },
  components: {
    NbHelpText,
  },
};
</script>

<style lang="scss" scoped>
.costs {
  .info {
    display: flex;
    flex-direction: column;
    small {
      font-weight: 700;
      font-size: 0.75rem;
    }
    span {
      line-height: 1.2;
    }
  }

  section {
    & + section {
      margin-top: 1.5rem;
    }
  }
}
</style>
