<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("trackingRulesPage.addNewTrackingRule") }}
      </ModalTitle>
    </ModalHeader>

    <div class="modal-body mb-5">
      <NbRadio
        id="tracking_mode-tracking-rules"
        :name="$t('trackingRulesPage.chooseTrigger')"
        required
        :options="[
          {
            text: $t('trackingRulesPage.whenSpecificCheckpointOccur'),
            value: 'received_checkpoint',
          },
          {
            text: $t('trackingRulesPage.whenDelayIsTooLong'),
            value: 'time_between_checkpoints',
          },
        ]"
        v-model="newTrackingRule.tracking_mode"
      />
      <div v-if="newTrackingRule.tracking_mode !== null">
        <div class="d-flex justify-content-between flex-wrap">
          <NbTextInput
            id="name-tracking-rules"
            class="w-100 my-2"
            :name="$t('trackingRulesPage.name')"
            :placeholder="$t('trackingRulesPage.giveNameToYourRule')"
            :error="errors.name"
            v-model="newTrackingRule.name"
            required
          />
          <NbSelectInput
            id="seller_id-tracking-rules"
            class="w-48-5 my-2"
            :name="$t('trackingRulesPage.seller')"
            placeholder="Select Seller"
            :options="allSellersOptions"
            :error="errors.seller_id"
            v-model="newTrackingRule.seller_id"
            :helpText="$t('trackingRulesPage.applyRuleToSellersHelpText')"
          />
          <NbSelectInput
            id="contract_id-tracking-rules"
            class="w-48-5 my-2"
            :name="$t('trackingRulesPage.contract')"
            placeholder="Select Contract"
            :options="allContractsOptions"
            :error="errors.contract_id"
            v-model="newTrackingRule.contract_id"
            :helpText="$t('trackingRulesPage.applyRuleToContractsHelpText')"
          />
          <div
            v-if="newTrackingRule.tracking_mode == 'time_between_checkpoints'"
            class="d-flex justify-content-between flex-wrap w-100"
          >
            <NbSelectInput
              id="start_checkpoint_id-tracking-rules"
              class="w-38 my-2"
              :name="$t('trackingRulesPage.startCheckpoint')"
              placeholder="Select Checkpoint"
              :options="allCheckpointsOptions"
              :error="errors.start_checkpoint_id"
              v-model="newTrackingRule.start_checkpoint_id"
            />
            <NbSelectInput
              id="end_checkpoint_id-tracking-rules"
              class="w-38 my-2"
              :name="$t('trackingRulesPage.endCheckpoint')"
              placeholder="Select Checkpoint"
              :options="allCheckpointsOptions"
              :error="errors.end_checkpoint_id"
              v-model="newTrackingRule.end_checkpoint_id"
              :helpText="$t('trackingRulesPage.endCheckpoointHelpText')"
              required
            />
            <NbTextInput
              id="days-tracking-rules"
              class="w-20-5 my-2"
              :name="$t('trackingRulesPage.maximumDays')"
              type="number"
              :placeholder="$t('trackingRulesPage.maximumDays')"
              :error="errors.days"
              v-model="newTrackingRule.days"
              :helpText="
                $t('trackingRulesPage.amountToWaitCheckpointsHelpText')
              "
              required
            />
          </div>
          <div v-else class="d-flex justify-content-between flex-wrap w-100">
            <NbSelectInput
              id="start_checkpoint_id-tracking-rules"
              class="w-100 my-2"
              :name="$t('trackingRulesPage.checkpoint')"
              placeholder="Select Checkpoint"
              :options="allCheckpointsOptions"
              :error="errors.start_checkpoint_id"
              v-model="newTrackingRule.start_checkpoint_id"
            />
          </div>
          <p class="body-4 mt-4 mb-2">
            {{ $t("trackingRulesPage.chooseWhatWillBeSent") }}:
          </p>
          <div class="w-100 bg-gray-10 nb-card-simple p-3 my-1">
            <NbCheckbox
              id="config-log-tracking-rules"
              class="w-100 my-2"
              :name="$t('trackingRulesPage.log')"
              v-model="newTrackingRule.configuration.log"
              :helpText="$t('trackingRulesPage.logHelpText')"
            />
          </div>

          <div class="w-100 bg-gray-10 nb-card-simple p-3 my-1">
            <NbCheckbox
              id="notify_seller-tracking-rules"
              class="w-100 my-2"
              :name="$t('trackingRulesPage.notifySeller')"
              v-model="newTrackingRule.configuration.notify_seller"
              :helpText="$t('trackingRulesPage.notifySellerHelpText')"
            />
            <div
              v-if="newTrackingRule.configuration.notify_seller"
              class="d-flex justify-content-between flex-wrap w-100"
            >
              <NbTextInput
                id="seller_mail_title-tracking-rules"
                class="w-100 mb-2 mt-3"
                name="Title"
                placeholder="Title"
                :error="errors.seller_mail_title"
                v-model="newTrackingRule.configuration.seller_mail_title"
              />
              <NbTextInput
                id="seller_mail_body-tracking-rules"
                class="w-100 my-2"
                :name="$t('body')"
                type="textarea"
                :placeholder="$t('body')"
                :error="errors.seller_mail_body"
                v-model="newTrackingRule.configuration.seller_mail_body"
              />
            </div>
          </div>

          <div class="w-100 bg-gray-10 nb-card-simple p-3 my-1">
            <NbCheckbox
              id="seller_notification-tracking-rules"
              class="w-100 my-2"
              :name="$t('trackingRulesPage.sellerInternalNotification')"
              v-model="newTrackingRule.configuration.seller_notification"
              :helpText="$t('trackingRulesPage.sellerNotificationHelpText')"
            />
            <div
              v-if="newTrackingRule.configuration.seller_notification"
              class="d-flex justify-content-between flex-wrap w-100"
            >
              <NbSelectInput
                id="seller_notification_title-tracking-rules"
                class="w-100 mb-2 mt-3"
                name="Title"
                :options="allSellerNotificationTitles"
                placeholder="Title"
                :error="errors.seller_notification_title"
                v-model="
                  newTrackingRule.configuration.seller_notification_title
                "
              />
              <NbTextInput
                id="seller_notification_body-tracking-rules"
                class="w-100 my-2"
                :name="$t('body')"
                type="textarea"
                :placeholder="$t('body')"
                :error="errors.seller_notification_body"
                v-model="newTrackingRule.configuration.seller_notification_body"
              />
            </div>
          </div>

          <div class="w-100 bg-gray-10 nb-card-simple p-3 my-1">
            <NbCheckbox
              id="notify_customer-tracking-rules"
              class="w-100 my-2"
              :name="$t('trackingRulesPage.notifyCustomer')"
              v-model="newTrackingRule.configuration.notify_customer"
              :helpText="$t('trackingRulesPage.notifyCustomerHelpText')"
            />
            <div
              v-if="newTrackingRule.configuration.notify_customer"
              class="d-flex justify-content-between flex-wrap w-100"
            >
              <NbTextInput
                id="customer_mail_title-tracking-rules"
                class="w-100 mb-2 mt-3"
                name="Title"
                placeholder="Title"
                :error="errors.customer_mail_title"
                v-model="newTrackingRule.configuration.customer_mail_title"
              />
              <NbTextInput
                id="customer_mail_body-tracking-rules"
                class="w-100 my-2"
                :name="$t('body')"
                type="textarea"
                :placeholder="$t('body')"
                :error="errors.customer_mail_body"
                v-model="newTrackingRule.configuration.customer_mail_body"
              />
            </div>
          </div>

          <div class="w-100 bg-gray-10 nb-card-simple p-3 my-1">
            <NbCheckbox
              id="notify_nobordist-tracking-rules"
              class="w-100 my-2"
              :name="$t('trackingRulesPage.notifyNobordist')"
              v-model="newTrackingRule.configuration.notify_nobordist"
              :helpText="$t('trackingRulesPage.notifyNbHelpText')"
            />
            <div
              v-if="newTrackingRule.configuration.notify_nobordist"
              class="d-flex justify-content-between flex-wrap w-100"
            >
              <NbTextInput
                id="nobordist_mail_destination-tracking-rules"
                class="w-48-5 mb-2 mt-3"
                type="email"
                name="Nobordist Email"
                placeholder="destination@nobordist.com"
                :error="errors.destination"
                v-model="newTrackingRule.destination"
              />
              <NbTextInput
                id="nobordist_mail_title-tracking-rules"
                class="w-48-5 mb-2 mt-3"
                name="Title"
                placeholder="Title"
                :error="errors.nobordist_mail_title"
                v-model="newTrackingRule.configuration.nobordist_mail_title"
              />
              <NbTextInput
                id="nobordist_mail_body-tracking-rules"
                class="w-100 my-2"
                :name="$t('body')"
                type="textarea"
                :placeholder="$t('body')"
                :error="errors.nobordist_mail_body"
                v-model="newTrackingRule.configuration.nobordist_mail_body"
              />
            </div>
          </div>

          <div class="w-100 bg-gray-10 nb-card-simple p-3 my-1">
            <NbCheckbox
              id="notify_webhook-tracking-rules"
              class="w-100 my-2"
              :name="$t('trackingRulesPage.webhook')"
              v-model="newTrackingRule.configuration.notify_webhook"
              :helpText="$t('trackingRulesPage.webhookHelpText')"
            />
            <div
              v-if="newTrackingRule.configuration.notify_webhook"
              class="d-flex justify-content-between flex-wrap w-100"
            >
              <NbRadio
                id="webhook-method-radio-tracking-rules"
                class="w-16 my-2"
                :name="$t('trackingRulesPage.method')"
                :options="webhookOptions"
                v-model="newTrackingRule.configuration.webhook.method"
              />
              <NbTextInput
                id="endpoint-tracking-rules"
                class="w-80 my-2"
                name="Endpoint"
                placeholder="Endpoint"
                :error="errors.nobordist_mail_body"
                v-model="newTrackingRule.configuration.webhook.endpoint"
              />

              <div class="d-flex justify-content-between flex-wrap w-100 my-3">
                <div class="w-48-5 border border-gray-20 my-1 py-2 px-3 h-fit">
                  <span class="w-100 heading-1 mb-3">
                    {{ $t("trackingRulesPage.addHeaders") }}:
                  </span>
                  <div class="border-top border-gray-20 mt-2 py-1 w-100">
                    <NbTextInput
                      id="hkey"
                      class="w-fill-available mb-2"
                      :name="$t('trackingRulesPage.key')"
                      :error="errors.headers"
                      v-model="hkey"
                    />
                    <NbTextInput
                      id="hvalue"
                      class="w-fill-available my-2"
                      :name="$t('trackingRulesPage.value')"
                      :error="errors.headers"
                      v-model="hvalue"
                    />
                    <NbButton
                      class="mt-0"
                      variant="primary"
                      icon="plus"
                      @click="addHeaders(hvalue, hkey)"
                    >
                      {{ $t("add") }}
                    </NbButton>
                  </div>
                </div>
                <div class="w-48-5 border border-gray-20 my-1 py-2 px-3 h-fit">
                  <span class="w-100 heading-1 mb-3">
                    {{ $t("trackingRulesPage.headers") }}:
                  </span>
                  <div
                    v-for="(value, key, index) in newTrackingRule.configuration
                      .webhook.headers"
                    :key="index"
                    class="w-100"
                  >
                    <div
                      class="border-top border-gray-20 mt-2 py-1 d-flex w-100"
                    >
                      <NbTextInput
                        :id="`webhook-header-${index}`"
                        class="w-fill-available"
                        :name="key"
                        :error="errors.headers"
                        v-model="
                          newTrackingRule.configuration.webhook.headers[key]
                        "
                      />
                      <NbButton
                        v-if="key !== 'ContentType'"
                        class="mt-4"
                        variant="tertiary"
                        icon="trash"
                        @click="removeHeaders(key)"
                      >
                      </NbButton>
                    </div>
                  </div>
                </div>
              </div>

              <NbTextInput
                id="Webhook-body-tracking-rules"
                class="w-100 my-2"
                name="Webhook body"
                type="textarea"
                placeholder="Webhook body"
                :error="errors.nobordist_mail_body"
                v-model="newTrackingRule.configuration.webhook.body"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalFooter>
      <div class="d-flex justify-content-end gap-2">
        <ModalClose />
        <NbButton @click="checkTrackingRule()">
          {{ newTrackingRule.id ? $t("update") : $t("save") }}
        </NbButton>
      </div>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";

import TrackingRuleService from "@/services/TrackingRuleService";

const trackingRuleService = new TrackingRuleService();

export default {
  components: {
    NbButton,
    NbTextInput,
    NbRadio,
    NbSelectInput,
    NbCheckbox,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
  },
  name: "ModalTrackingRuleCreate",
  props: {
    id: {
      type: String,
      default: "ModalTrackingRuleCreate",
    },
    trackingRule: {
      type: Object,
      default: () => {},
    },
  },
  inject: ["context"],
  data() {
    return {
      hkey: "",
      hvalue: "",
      errors: {},
      newTrackingRule: {
        name: "",
        tracking_mode: null,
        seller_id: null,
        contract_id: null,
        start_checkpoint_id: null,
        end_checkpoint_id: null,
        days: null,
        destination: "",
        configuration: {
          log: false,
          notify_seller: false,
          seller_mail_title: "",
          seller_mail_body: "",
          seller_notification: false,
          seller_notification_title: "",
          seller_notification_body: "",
          notify_customer: false,
          customer_mail_title: "",
          customer_mail_body: "",
          notify_nobordist: false,
          nobordist_mail_title: "",
          nobordist_mail_body: "",
          notify_webhook: false,
          webhook: {
            method: "",
            endpoint: "",
            body: "",
            headers: {
              ContentType: "application/json",
            },
          },
        },
      },
    };
  },
  computed: {
    allContracts() {
      return this.$store.state.contracts.all_items;
    },
    allContractsOptions() {
      const contractOptions = this.allContracts.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.slug}`,
      }));
      return contractOptions;
    },
    allSellers() {
      return this.$store.state.sellers.all_items;
    },
    allSellersOptions() {
      const sellerOptions = this.allSellers.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.name}`,
      }));
      return sellerOptions;
    },
    allCheckpoints() {
      return this.$store.state.nobordist_checkpoints.all_items;
    },
    allCheckpointsOptions() {
      const checkpointOptions = this.allCheckpoints.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.title}`,
      }));
      return checkpointOptions;
    },
    allSellerNotificationTitles() {
      return [
        {
          value: "finance",
          text: this.$t(
            "trackingRulesPage.allSellerNotificationTitles.finance",
          ),
        },
        {
          value: "insufficient_balance",
          text: this.$t(
            "trackingRulesPage.allSellerNotificationTitles.insufficientBalance",
          ),
        },
        {
          value: "tracking_issue",
          text: this.$t(
            "trackingRulesPage.allSellerNotificationTitles.trackingIssue",
          ),
        },
      ];
    },
    webhookOptions() {
      return [
        { text: "POST", value: "POST" },
        { text: "PUT", value: "PUT" },
      ];
    },
  },
  methods: {
    parseBody(data) {
      try {
        if (typeof data === "string") {
          return JSON.parse(data);
        }

        return data;
      } catch {
        return data;
      }
    },
    removeHeaders(key) {
      this.newTrackingRule.configuration.webhook.headers;
      const updatedHeaders = {
        ...this.newTrackingRule.configuration.webhook.headers,
      };
      delete updatedHeaders[key];

      this.newTrackingRule.configuration.webhook.headers = updatedHeaders;
      /* usa o VUE para setar
      this.$set(
        this.newTrackingRule.configuration.webhook,
        "headers",
        updatedHeaders,
      );
      */
    },
    addHeaders(value, key) {
      this.newTrackingRule.configuration.webhook.headers[key] = value;
      this.hkey = "";
      this.hvalue = "";
    },
    deleteObjectProps(obj) {
      let newObj = JSON.parse(JSON.stringify(obj));
      if (newObj.id) delete newObj.id;
      if (newObj.type) delete newObj.type;
      if (newObj.created_at) delete newObj.created_at;
      if (newObj.updated_at) delete newObj.updated_at;
      if (newObj.hierarchy_status) delete newObj.hierarchy_status;
      delete newObj.is_internal;
      delete newObj.final_status;
      return newObj;
    },
    assessWebhook() {
      if (this.newTrackingRule.tracking_mode === "received_checkpoint") {
        let start_checkpoint;
        if (this.newTrackingRule.start_checkpoint_id) {
          start_checkpoint = this.allCheckpoints.find(
            (checkpoint) =>
              checkpoint.id == this.newTrackingRule.start_checkpoint_id,
          );
          this.bodyWebhook = this.deleteObjectProps(start_checkpoint);
        }
      } else if (
        this.newTrackingRule.tracking_mode === "time_between_checkpoints"
      ) {
        let startCheckpoint;
        let endCheckpoint;
        if (this.newTrackingRule.start_checkpoint_id) {
          startCheckpoint = this.allCheckpoints.find(
            (checkpoint) =>
              checkpoint.id == this.newTrackingRule.start_checkpoint_id,
          );
        }
        if (this.newTrackingRule.end_checkpoint_id) {
          endCheckpoint = this.allCheckpoints.find(
            (checkpoint) =>
              checkpoint.id == this.newTrackingRule.end_checkpoint_id,
          );
        }

        if (startCheckpoint && endCheckpoint && this.newTrackingRule.days) {
          this.bodyWebhook = {
            start_checkpoint: this.deleteObjectProps(startCheckpoint),
            end_checkpoint: this.deleteObjectProps(endCheckpoint),
            maximum_days: this.newTrackingRule.days,
          };
        }
      }
    },
    checkTrackingRule() {
      if (this.trackingRule?.id) {
        this.updateTrackingRule();
        return;
      }
      this.addTrackingRule();
    },
    addTrackingRule() {
      const {
        configuration: { webhook },
      } = this.newTrackingRule;

      trackingRuleService
        .setTrackingRule({
          ...this.newTrackingRule,
          configuration: {
            ...this.newTrackingRule.configuration,
            webhook: {
              ...this.newTrackingRule.configuration.webhook,
              body: this.parseBody(webhook?.body),
            },
          },
        })
        .then(() => {
          this.context?.close();
          this.$emit("reloadTrackingRules");
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
    updateTrackingRule() {
      const {
        configuration: { webhook },
      } = this.newTrackingRule;

      trackingRuleService
        .updateTrackingRule(this.trackingRule.id, {
          ...this.newTrackingRule,
          configuration: {
            ...this.newTrackingRule.configuration,
            webhook: {
              ...this.newTrackingRule.configuration.webhook,
              body: this.parseBody(webhook?.body),
            },
          },
        })
        .then(() => {
          this.context?.close();
          this.$emit("updateTrackingRule");
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
  },
  watch: {
    "newTrackingRule.tracking_mode": function () {
      if (this.newTrackingRule.tracking_mode !== "time_between_checkpoints") {
        this.newTrackingRule.end_checkpoint_id = null;
        this.newTrackingRule.days = null;
      }
    },
    "newTrackingRule.start_checkpoint_id": function () {
      this.assessWebhook();
    },
    "newTrackingRule.end_checkpoint_id": function () {
      this.assessWebhook();
    },
    "newTrackingRule.days": function () {
      this.assessWebhook();
    },
    "newTrackingRule.configuration.notify_webhook": function (val) {
      if (val) {
        // // To remove Vue error use line below
        // this.newTrackingRule.configuration.webhook.body = JSON.stringify(this.bodyWebhook)
        this.newTrackingRule.configuration.webhook.body = JSON.stringify(
          this.bodyWebhook,
        );
      } else {
        this.newTrackingRule.configuration.webhook.body = null;
      }
    },
  },
  created() {
    if (this.trackingRule?.id) {
      const { configuration } = this.trackingRule;

      const data = {
        name: this.trackingRule.name,
        tracking_mode: this.trackingRule.tracking_mode,
        seller_id: this.trackingRule.seller_id,
        contract_id: this.trackingRule.contract_id,
        start_checkpoint_id: this.trackingRule.start_checkpoint_id,
        end_checkpoint_id: this.trackingRule.end_checkpoint_id,
        days: this.trackingRule.days,
        destination: this.trackingRule.destination,
        configuration: {
          log: configuration?.log || false,
          notify_seller: configuration?.notify_seller || false,
          seller_mail_title: configuration?.seller_mail_title || "",
          seller_mail_body: configuration?.seller_mail_body || "",
          seller_notification: configuration?.seller_notification || false,
          seller_notification_title:
            configuration?.seller_notification_title || "",
          seller_notification_body:
            configuration?.seller_notification_body || "",
          notify_customer: configuration?.notify_customer || false,
          customer_mail_title: configuration?.customer_mail_title || "",
          customer_mail_body: configuration?.customer_mail_body || "",
          notify_nobordist: configuration?.notify_nobordist || false,
          nobordist_mail_title: configuration?.nobordist_mail_title || "",
          nobordist_mail_body: configuration?.nobordist_mail_body || "",
          notify_webhook: configuration?.notify_webhook || false,
          webhook: {
            method: configuration?.webhook?.method || "",
            endpoint: configuration?.webhook?.endpoint || "",
            body: configuration?.webhook?.body || "",
            headers: configuration?.webhook?.headers || {
              ContentType: "application/json",
            },
          },
        },
      };

      if (typeof configuration?.webhook?.body !== "string") {
        data.configuration.webhook.body = JSON.stringify(
          configuration.webhook.body,
        );
      }

      if (typeof configuration?.seller_mail_body !== "string") {
        data.configuration.seller_mail_body = JSON.stringify(
          configuration.seller_mail_body,
        );
      }

      if (typeof configuration?.customer_mail_body !== "string") {
        data.configuration.customer_mail_body = JSON.stringify(
          configuration.customer_mail_body,
        );
      }

      if (typeof configuration?.nobordist_mail_body !== "string") {
        data.configuration.nobordist_mail_body = JSON.stringify(
          configuration.nobordist_mail_body,
        );
      }

      this.newTrackingRule = data;
    }
  },
};
</script>

<style scoped></style>
