<template>
  <div v-click-outside="closeCollapse" class="mt-0 relative">
    <div v-if="name" class="input-label" :for="id">
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
      </NbHelpText>
    </div>
    <div
      :id="id"
      v-b-toggle="[`collapse-${id}`]"
      :style="customStyle"
      class="button select-button select-multiple"
      :class="
        error[0]
          ? `is-invalid ${filled} ${isDisabled} ${size}`
          : `${filled} ${isDisabled} ${size}`
      "
    >
      <div class="left-div">
        <div
          v-if="optionSelectTexts && optionSelectTexts.length > 0"
          class="options-badge-wrapper"
        >
          <NbBadge
            v-for="(optionSelectText, index) in optionSelectTexts"
            :key="optionSelectText + index"
            :text="optionSelectText"
            class="badge-input options-badge text-normal mb-2"
            text-class="text-ellipsis"
          />
        </div>
        <div v-else>
          {{ placeholder }}
        </div>
      </div>
      <div class="float-right">
        <NbBadge
          v-if="optionSelectTexts && optionSelectTexts.length > 2"
          :text="`+${optionSelectTexts.length - 2}`"
          class="badge-input text-normal"
        />
        <i
          class="float-right fas fa-angle-right ml-2"
          :class="size ? 'mt-2px' : 'mt-1'"
        ></i>
      </div>
    </div>
    <div class="mt-0 relative">
      <b-collapse
        :id="`collapse-${id}`"
        v-model="visible"
        class="mt-0 select-colapse-card"
      >
        <b-card class="p-0 nb-selectinput-card">
          <NbTextInput
            :id="`${id}-textinput`"
            v-model="searchItems"
            :placeholder="$t('search')"
          />
          <div
            v-for="(option, index) in checkBoxList"
            :key="option.id"
            :class="option.disabled ? 'text-disabled' : ''"
          >
            <div v-if="filteredOptions(option)" class="select-options w-100">
              <NbCheckbox
                :id="`${id}-${option.value}-multi-checkbox`"
                v-model="checkBoxList[index].isChecked"
                label-style="padding: 0.56rem 0rem; width: 100%"
                box-style="margin-top: 0.56rem; margin-left: 1.33rem;"
                :disabled="option.disabled"
                :name="option.text"
                @input="updateOptionSelected()"
              />
            </div>
          </div>
        </b-card>
      </b-collapse>
    </div>
    <ErrorFeedback :error="error[0]" />
  </div>
</template>

<script>
import ErrorFeedback from "../../generic/ErrorFeedback.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import { directive } from "v-click-outside";

export default {
  name: "NbSelectMultipleInput",
  directives: { clickOutside: directive },
  components: { ErrorFeedback, NbHelpText, NbCheckbox, NbBadge, NbTextInput },
  props: {
    value: {
      type: [Object, String, Number, Array],
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
    },
    returnCompleteObject: {
      type: Boolean,
      required: false,
    },
    helpText: {
      type: String,
      required: false,
      default: "",
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    startOpen: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select options",
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
    size: {
      type: String,
      default: "",
    },
    customStyle: {
      type: [Object, Array],
      default: () => [],
    },
  },
  data() {
    return {
      optionSelected: [],
      filled: "",
      isDisabled: "",
      visible: this.startOpen,
      searchItems: "",
      checkBoxList: [],
    };
  },
  computed: {
    mapedOptions() {
      const list = Array.from(this.options, (option) => {
        const newOption = {};
        newOption.text = option.text;
        newOption.value = option.value;
        newOption.isChecked = option.isChecked;
        return newOption;
      });
      return list;
    },
    optionSelectTexts() {
      const filteredOptionsConst = this.checkBoxList.filter(
        (checkBox) => checkBox.isChecked,
      );
      return filteredOptionsConst.map((item) => item.text);
    },
    classes() {
      const baseClass = ["form-control"];

      if (this.error.length) {
        baseClass.push("is-invalid");
      }
      return baseClass;
    },
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
  },
  watch: {
    /* optionSelected(newValue) {
      this.$emit('input', newValue);
      this.validateOptionSelected()
    }, */
    options() {
      this.setCheckboxList();
    },
    value(newValue) {
      this.updateCheckBoxList();
      if (newValue !== this.optionSelected) {
        this.optionSelected = newValue;
      }
    },
    disabled() {
      if (this.disabled) {
        this.isDisabled = "disabled";
        return;
      }
      this.isDisabled = "";
    },
  },
  created() {
    this.setCheckboxList();
    this.optionSelected = this.value;
    if (this.optionSelected && this.optionSelected.length > 0) {
      this.updateCheckBoxList();
    }
  },
  methods: {
    setCheckboxList() {
      this.checkBoxList = this.mapedOptions;
    },
    updateCheckBoxList() {
      this.checkBoxList.forEach((box) => {
        if (this.value.includes(box.value)) {
          box.isChecked = true;
          return;
        }
        box.isChecked = false;
      });
    },
    updateOptionSelected() {
      this.filled = "filled";
      const filteredOptions = this.checkBoxList.filter(
        (checkBox) => checkBox.isChecked,
      );
      this.optionSelected = filteredOptions.map((item) => item.value);

      this.$emit("input", this.optionSelected);
      this.validateOptionSelected();
    },
    closeCollapse() {
      this.visible = false;
    },
    validateOptionSelected() {
      const element = document.getElementById(this.id);
      //take care with || this.error.length > 0
      if (this.required && this.optionSelected.length < 1) {
        this.$emit("invalid", {
          id: this.id,
          message: this.$t("errorMessages.required"),
        });
        element.classList.remove("is-valid");
        return;
      }
      setTimeout(() => {
        element.classList.add("is-valid");
      }, 200);
      this.$emit("valid", this.id);
    },
    filteredOptions(option) {
      if (this.searchItems.length == 0) {
        return true;
      }

      return option.text
        .toLowerCase()
        .includes(this.searchItems.toLocaleLowerCase());
    },
  },
};
</script>

<style scoped>
.input-label {
  color: var(--black);
  text-align: left;
  font: normal normal 600 12px/16px var(--font-family-base);
  letter-spacing: 0px;
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: default;
}
.button.select-button {
  position: relative;
  background: var(--gray-05);
  border-radius: 4px 4px 0px 0px;
  border-bottom: 2px solid var(--gray-40);
  color: var(--gray-40);
  text-align: left;
  font: normal normal normal 14px/24px var(--font-family-base);
  letter-spacing: 0px;
  box-shadow: none;
  padding: 9px 12px;
  height: 2.5rem;
  overflow: hidden;
  /* height: calc(2em + 0.75rem + 2px); */
}
.button.select-button.filled {
  color: var(--black);
}
.button.select-button.is-invalid {
  border-bottom: 2px solid var(--error);
}
.button.select-button.is-valid {
  border-bottom: 2px solid var(--success);
}
.form-control.input.is-valid {
  border-bottom: 2px solid var(--success) !important;
  caret-color: var(--success);
  background-image: none;
}
.button.select-button.disabled,
.text-disabled {
  color: var(--gray-20) !important;
}
.button.select-button:hover {
  background: var(--gray-10);
}
.button.select-button.not-collapsed {
  transition: all 0.3s ease;
  border-bottom: 0px !important;
}
.button.select-button.not-collapsed i {
  transition: all 0.3s ease;
  transform: rotate(90deg);
}
.button.select-button i {
  color: var(--gray-60);
  transition: all 0.3s ease;
}
.button.select-button.disabled i {
  color: var(--gray-20);
}

.button.select-button.disabled {
  border-color: var(--gray-20);
}

/* size sm */
.button.select-button.sm {
  background: var(--gray-05);
  border: 1px solid var(--gray-50);
  border-radius: 4px;
  color: var(--gray-50);
  font: normal normal 600 12px/16px Nunito Sans;
  letter-spacing: 0px;
  padding: 0.4rem 0.5rem;
  height: 1.75rem;
}
.button.select-button.sm.filled {
  color: var(--black);
}
.button.select-button.sm.is-invalid {
  border: 1px solid var(--error);
}
.button.select-button.sm.is-valid {
  border: 1px solid var(--success);
}
.button.select-button.sm.disabled {
  color: var(--gray-20);
}
.button.select-button.sm:hover {
  background: var(--gray-10);
}
.button.select-button.sm.not-collapsed {
  transition: all 0.3s ease;
  border-bottom: 0px !important;
}
.button.select-button.sm.not-collapsed i {
  transition: all 0.3s ease;
  transform: rotate(90deg);
}
.button.select-button.sm i {
  color: var(--gray-60);
  transition: all 0.3s ease;
}
.button.select-button.sm.disabled i {
  color: var(--gray-20);
}

.select-multiple {
  display: grid;
  grid-template-columns: 1fr 3.2rem;
}
.options-badge-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.options-badge {
  width: auto !important;
  height: 1.62rem;
  overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-colapse-card {
  position: absolute;
  top: 0px;
  width: 100%;
}
.nb-selectinput-card {
  overflow: auto;
  max-height: 53vh;
}
.card {
  z-index: 4;
  background-color: var(--gray-05);
  border-radius: 0px;
  border: none;
}
.card-body {
  border-radius: 0px !important;
  padding: 0px;
  color: var(--black);
  text-align: left;
  font: normal normal normal 14px/20px var(--font-family-base);
  letter-spacing: 0px;
}
.card-body > div:last-child {
  border-bottom: 2px solid var(--black);
}
.relative {
  position: relative;
}
.select-options {
  /* padding: 0.56rem 1.33rem; */
  border-bottom: 2px solid var(--gray-10);
}
.select-options:hover {
  background-color: var(--gray-10);
}
.text-disabled > .select-options:hover {
  cursor: not-allowed;
  background-color: var(--gray-05);
}

.select-button .badge-input {
  width: fit-content;
  display: inline-block;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  position: relative;
  top: -0.1rem;
}

.mt-2px {
  margin-top: 2px;
}
</style>
