<template>
  <NbButtonLoading
    :variant="variant"
    :loading="loading"
    @click="generateFlightLabel"
  >
    <slot>
      <NbIcon icon="download" />
      {{ $t("download") }}
    </slot>
  </NbButtonLoading>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
import FlightService from "@/services/FlightService";
import NbButtonLoading from "../../../../components/buttons/NbButtonLoading.vue";

const flightService = new FlightService();

export default {
  components: {
    NbIcon,
    NbButtonLoading,
  },
  props: {
    flightId: {
      type: Number,
      required: true,
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async generateFlightLabel() {
      try {
        this.loading = true;
        const { data } = await flightService.generateFlightLabel(this.flightId);

        if (data?.data?.download_url) {
          window.open(data.data.download_url);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.download-button {
  position: relative;
  &.loading {
    .loading {
      position: absolute;
    }
    span {
      opacity: 0;
    }
  }
}
</style>
