const mapCurrencies = {
  R$: "BRL",
  BRL: "BRL",
  USD: "USD",
};

export const currency = {
  symbol(currency = "USD", locale = "en") {
    if ((!currency) in mapCurrencies) {
      return "";
    }

    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    })
      .format(0)
      .replace(/\d/g, "")
      .trim();
  },
  parse(value) {
    const result = Number(value);
    return !isNaN(result) ? result.toFixed(2) : "0.00";
  },
  format(amount = 0, exchange = "BRL") {
    const value = this.parse(amount);

    if ((!exchange) in mapCurrencies) {
      return value;
    }

    const formatter = new Intl.NumberFormat("en", {
      style: "currency",
      currency: mapCurrencies[exchange],
    });

    return formatter.format(value);
  },
};
