import Vue from "vue";
import axios from "axios";
import store from "../store";
import router from "../router";
import { ToastPlugin } from "bootstrap-vue";

Vue.use(ToastPlugin);

const vm = new Vue();
let showToaster = true;

Vue.prototype.$http = axios;
const api = axios.create({
  baseURL: process.env.VUE_APP_LOGISTICS_API_URL,
});

api.interceptors.request.use((config) => {
  store.commit("config/TURN_ON_LOADING_BAR");
  const user = store.state.user;
  const authToken =
    user && Object.prototype.hasOwnProperty.call(user, "auth_token")
      ? user.auth_token
      : process.env.VUE_APP_DEFAULT_AUTH_TOKEN;
  config.headers.Authorization = `Bearer ${authToken}`;
  return config;
});

api.interceptors.response.use(
  (response) => {
    if (response.data.status === "ERROR") {
      return Promise.reject(response);
    }

    return Promise.resolve(response);
  },
  (error) => {
    if (error.response.status === 401 && showToaster) {
      const platform = store.state.platform;
      store.commit("DELETE_LOGGEDIN_USER");
      router.push(`/${platform}-login`);
      showToaster = false;
      vm.$bvToast.toast("Please login again.", {
        title: "Session Expired",
        solid: true,
        variant: "danger",
        autoHideDelay: 2000,
      });
    } else {
      return Promise.reject(error);
    }
  },
);

export default api;

// function toastSuccess (response) {
//     store.commit("config/TURN_OFF_LOADING_BAR");
//     const data = response.data;
//     if (showToast (response.data)) {
//         if(data.messages) {
//             toast(data.messages, "success");
//         }
//     }
// }
