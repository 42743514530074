<template>
  <div>this page is for the Products</div>
</template>

<script>
export default {
  name: "Products",
  data: () => {
    return {};
  },
  beforeMount() {},
  methods: {},
};
</script>
