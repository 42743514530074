import api from "./HttpService";

export default class SalesChannelManagementService {
  /*  constructor() {
    this.httpService = HttpService;
  } */

  /**
   * Get groups
   *
   *
   * @returns {Promise}
   */
  getGroups() {
    return api.get("/v1/sales_channels_groups");
  }

  createGroup(parameters) {
    return api.post("/v1/sales_channels_groups", parameters);
  }

  deleteGroup(parameters) {
    return api.delete("/v1/sales_channels_groups/" + parameters.id);
  }

  updateGroup(parameters) {
    return api.put("/v1/sales_channels_groups/" + parameters.id, parameters);
  }

  ///////////////////////////////////////////////////////////////

  getSalesChannels() {
    return api.get("/v1/sales_channels");
  }

  addSalesChannel(parameters) {
    return api.post("/v1/sales_channels", parameters);
  }

  deleteSalesChannel(parameters) {
    return api.delete("/v1/sales_channels/" + parameters.id);
  }

  updateSalesChannel(parameters) {
    return api.put("/v1/sales_channels/" + parameters.id, parameters);
  }
}
