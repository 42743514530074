import api from "./HttpService";
import apiUntoasted from "./HttpServiceUntoasted";
export default class MasterBoxService {
  /**
   * Get MasterBox
   *
   * @param {*} id
   * @returns {Promise}
   */
  getMasterBox(id = null) {
    return api.get("/v1/masterboxes/" + id + "?source=admin");
  }

  generateMasterBoxLabel(id) {
    return api.get("/v1/masterboxes/" + id + "/cn35_label");
  }

  /**
   * Get Twenty MasterBox
   *
   * @param offset = number, track = filters
   * @returns {Promise}
   */
  getTwentyMasterBoxes(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/masterboxes`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/masterboxes?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  getAllMasterBoxes() {
    return api.get("/v1/masterboxes");
  }

  createMasterbox(data) {
    return apiUntoasted.post("v1/masterboxes?is_for_front=true", {
      ids: data,
    });
  }

  deleteMasterBox(data) {
    return api.delete("/v1/masterboxes?box_number=" + data);
  }

  downloadMasterboxVolumes(data = {}) {
    return api.post("/v1/downloaded_files/process_files", data);
  }

  resetDispatch(data) {
    return apiUntoasted.post("v1/masterbox/fictive", {
      masterbox: data,
    });
  }

  getByReference(reference) {
    return api.get(`/v1/masterboxes?reference=${reference}&source=admin`);
  }
}
