<template>
  <div :id="id">
    <div
      :id="'accordion-wrapper-' + id"
      :class="classes"
      class="nb-card-icon-accordion pointer"
      @click="siwtchShowAccordion"
    >
      <div class="mr-3 mb-2">
        <IconNumberBall width="29" :number="number" />
      </div>
      <div class="w-100">
        <div class="heading-3 mb-1">
          {{ title }}
          <slot name="afterTitle" />
        </div>
        <div class="body-4">{{ subTitle }} <slot name="beforeForm" /></div>
      </div>
      <div class="mt-auto">
        <div class="d-flex">
          <NbHelpText
            v-if="helpText"
            :id="`${id}-popover`"
            class="m-auto text-gray-40"
            :size="helpTextSize"
            placement="topright"
          >
            {{ helpText }}
          </NbHelpText>
          <NbButton class="ml-2" variant="tertiary">
            <i
              class="fa fa-chevron-down p-1 text-gray-40"
              :class="internalShowAccordion ? 'rotate-0' : 'rotate-right-90'"
            ></i>
          </NbButton>
        </div>
      </div>
    </div>
    <div class="nb-card-icon-accordion-body" :class="classes">
      <b-collapse
        :id="id ? `card-icon-accordion-${id}` : `card-icon-accordion-${number}`"
        class="mt-0 steps-card-detail-body"
        v-model="internalShowAccordion"
      >
        <slot />
      </b-collapse>
    </div>
  </div>
</template>

<script>
import IconNumberBall from "@/components/icons/IconNumberBall.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  components: {
    IconNumberBall,
    NbButton,
    NbHelpText,
  },
  props: {
    id: {
      type: [Number, String],
    },
    number: {
      type: Number,
      require: true,
    },
    title: {
      type: String,
      require: false,
    },
    subTitle: {
      type: String,
      require: false,
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "lg",
    },
    showAccordion: {
      type: Boolean,
      required: false,
      default: null,
    },
    classes: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      formToEmit: {},
      internalShowAccordion: true,
      phoneCountryCode: "",
    };
  },
  created() {
    if (this.formInputs) {
      this.loadFormToEmit();
    }
    if (typeof this.showAccordion === "boolean") {
      this.internalShowAccordion = this.showAccordion;
      return;
    }
  },
  methods: {
    //inicializa o form
    loadFormToEmit() {
      let formKeys = {};
      this.formInputs.forEach((formInput) => {
        formKeys[formInput.key] = "";
      });
      this.formToEmit = JSON.parse(JSON.stringify(formKeys));
    },
    updateCountryCode(codeKey, { code }) {
      this.content[codeKey] = code;
    },
    onSelected(selectedCustomer) {
      Object.keys(selectedCustomer).forEach((key) => {
        const transposedKey = "customer_" + key;
        this.content[transposedKey] = selectedCustomer[key];
      });
      this.content.customer_phone_country_code =
        selectedCustomer.phone_number_country;
      this.selectedCustomer = true;

      this.$emit("selected", selectedCustomer);
    },
    updateCustomerFullName(fullName, key) {
      this.content[key] = fullName;
      this.$emit("input", this.content);
    },
    onAddressFound(address) {
      if (!this.isFrom || this.isFromLoaded) {
        this.content.customer_address = "";
        this.content.customer_city = "";
        this.content.customer_state = "";
        if (address.logradouro || address.bairro) {
          this.content.customer_address = `${address.logradouro} - ${address.bairro}`;
        }
        this.content.customer_city = address.localidade;
        this.content.customer_state = address.uf;
      }
      this.isFromLoaded = true;
    },
    siwtchShowAccordion() {
      this.internalShowAccordion = !this.internalShowAccordion;
      this.$emit("siwtchShowAccordion", this.internalShowAccordion);
      setTimeout(() => {
        this.scrollToId(`accordion-wrapper-${this.id}`);
      }, 300);
    },
    scrollToId(id) {
      if (id) {
        const elmnt = document.getElementById(id);
        elmnt.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  computed: {
    content: {
      get() {
        return this.formVal;
      },
      set(val) {
        this.$emit("update:formVal", val);
      },
    },
    isFormDone() {
      let isDone = false;
      let formAttributes = {
        class: "bg-warning-10",
        text: this.$t("incomplete"),
      };

      if (typeof this.hasError === "boolean" && this.hasError) {
        formAttributes = {
          class: "bg-danger text-white",
          text: this.$t("erro"),
        };
        this.$emit("firstFormDone", isDone);
        return formAttributes;
      }
      let count = 0;
      if (this.formInputs) {
        const filteredFormToEmit = this.formInputs.filter((formInput) => {
          if (formInput.required) {
            return formInput;
          }
        });
        filteredFormToEmit.forEach((element) => {
          if (
            this.content[element.key] ||
            typeof this.content[element.key] === "boolean"
          ) {
            count++;
            return;
          }
          isDone = false;
          formAttributes = {
            class: "bg-warning-10",
            text: this.$t("incomplete"),
          };
        });

        if (count >= filteredFormToEmit.length) {
          isDone = true;
          formAttributes = {
            class: "bg-success text-white",
            text: this.$t("complete"),
          };
        }
      } else {
        isDone = true;
        formAttributes = {
          class: "bg-success text-white",
          text: this.$t("complete"),
        };
      }

      if (typeof this.twoPartFormDone === "boolean") {
        //Send if this form is complete or not (second part need to be checked by father element)
        this.$emit("firstFormDone", isDone);

        if (!this.twoPartFormDone) {
          isDone = false;
          formAttributes = {
            class: "bg-warning-10",
            text: this.$t("incomplete"),
          };
        }
      }

      this.$emit("formDoneChange", isDone);
      return formAttributes;
    },
  },
  watch: {
    formVal() {
      this.isFormDone();
    },
    formToEmit: {
      //deep: true,
      handler() {
        this.$emit("formChange", this.formToEmit);
      },
    },
    showAccordion() {
      if (typeof this.showAccordion === "boolean") {
        this.internalShowAccordion = this.showAccordion;
      }
    },
    twoPartFormDone() {
      this.isFormDone();
    },
  },
};
</script>

<style scoped>
.nb-card-icon-accordion {
  transition: all 0.3s ease;
  background: var(--white);
  border-radius: 0.25rem;
  padding: 2rem 3.12rem 0rem 3.12rem;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.nb-card-icon-accordion-body {
  transition: all 0.3s ease;
  background: var(--white);
  border-radius: 0.25rem;
  padding: 1rem 6rem 1rem 6rem;
}

.logo-previsualization {
  text-align: center;
}
.logo-previsualization img {
  object-fit: contain;
  cursor: pointer;
}
</style>
