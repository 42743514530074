import PassThrough from "../../components/PassThrough";
import Users from "../../views/users_management/users/Users";
import Sellers from "../../views/users_management/sellers/Sellers";
import SaldoView from "../../views/users_management/sellers/SaldoView";
import Groups from "../../views/users_management/groups/Groups";
import GroupsView from "../../views/users_management/groups/View";
import Permissions from "../../views/users_management/permissions/Permissions";
import PermissionsView from "../../views/users_management/permissions/View";
import store from "@/store/index";

const isSuper = store.state.user.is_super;

const users_management_users = {
  path: "/users_management/users",
  name: "users_management_users",
  component: Users,
  meta: {
    visible: true,
    label: "Users",
    searchData: "users",
  },
};

const users_management_sellers = {
  path: "/users_management/sellers",
  name: "users_management_sellers",
  component: Sellers,
  meta: {
    visible: true,
    label: "Sellers",
    searchData: "sellers",
  },
};

const users_management_seller_saldo_view = {
  path: "/users_management/sellers/:seller_id/saldo",
  name: "users_management_seller_saldo_view",
  component: SaldoView,
  meta: {
    visible: false,
    label: "Seller view",
  },
};

const users_management_groups = {
  path: "/users_management/groups",
  name: "users_management_groups",
  component: Groups,
  meta: {
    visible: isSuper,
    label: "Groups",
  },
};

const users_management_groups_view = {
  path: "/users_management/groups/:id",
  name: "users_management_groups_view",
  component: GroupsView,
  meta: {
    visible: false,
    label: "Group view",
  },
};

const users_management_permissions = {
  path: "/users_management/permissions",
  name: "users_management_permissions",
  component: Permissions,
  meta: {
    visible: isSuper,
    label: "Permissions",
  },
};

const users_management_permissions_view = {
  path: "/users_management/permissions/:id",
  name: "users_management_permissions_view",
  component: PermissionsView,
  meta: {
    visible: false,
    label: "Permission view",
  },
};

let users_management_children = [
  users_management_users,
  users_management_sellers,
  users_management_seller_saldo_view,
  users_management_groups,
  users_management_groups_view,
  users_management_permissions,
  users_management_permissions_view,
];

if (!isSuper) {
  users_management_children = [
    users_management_users,
    users_management_sellers,
    users_management_seller_saldo_view,
  ];
}

const users_management = {
  path: "/users_management",
  name: "users_management",
  component: PassThrough,
  meta: {
    visible: true,
    label: "Usersmanagement",
    icon: "users",
  },
  children: users_management_children,
};

export default users_management;
