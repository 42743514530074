import api from "./HttpService";

export default class OverviewService {
  /**
   * Get Saldo Correios
   *
   * @param {*} id
   * @returns {Promise}
   */
  getSaldoCorreios() {
    return api.get("/v1/correios_account" /*  + id */);
  }

  /**
   * Get Pending Ceva requests
   *
   * @param {*} id
   * @returns {Promise}
   */
  getPendingCevaRequests() {
    return api.get("/v1/ceva_check_request");
  }

  /**
   * Get Cn23 Info
   *
   * @param {*} code
   * @returns {Promise}
   */
  getCn23Info(code) {
    return api.get("/v1/correios_cn23_info?code=" + code);
  }

  /**
   * Get Cn35 Info
   *
   * @param {*} code
   * @returns {Promise}
   */
  getCn35Info(code) {
    return api.get("/v1/correios_cn35_info?code=" + code);
  }

  /**
   * Delete order
   *
   * @param {*} id
   * @returns {Promise}
   */
  deleteRemoveOverpack(code) {
    return api.delete("/v1/correios_remove_overpack?code=" + code);
  }

  generateRequestId() {
    return api.get("/v1/generate-test-request-id");
  }

  getApiStatus(request_id) {
    return api.get("/v1/get-api-status/" + request_id);
  }
}
