<template>
  <div>
    <header-title :title="title" />
    <div class="col-12">
      <div class="card border-white rounded py-4">
        <div class="row mx-5 my-2">
          <field-view
            :field="$t('carriersViewPage.name')"
            :val="carrier.name"
          />
          <field-view
            :field="$t('carriersViewPage.address')"
            :val="carrier.address"
          />
        </div>

        <div class="row mx-5 my-2">
          <field-view
            :field="$t('carriersViewPage.addressnumber')"
            :val="carrier.address_number"
          />
          <field-view
            :field="$t('carriersViewPage.addresscomplement')"
            :val="carrier.address_complement"
          />
        </div>

        <div class="row mx-5 my-2">
          <field-view
            :field="$t('carriersViewPage.zipcode')"
            :val="carrier.zip_code"
          />
          <field-view
            :field="$t('carriersViewPage.state')"
            :val="carrier.state"
          />
          <field-view
            :field="$t('carriersViewPage.country')"
            :val="carrier.country"
          />
        </div>

        <div class="row mx-5 my-2">
          <field-view
            :field="$t('carriersViewPage.city')"
            :val="carrier.city"
          />
          <field-view
            :field="$t('carriersViewPage.phone')"
            :val="carrier.phone"
          />
        </div>

        <div class="row mx-5 my-2">
          <field-view
            :field="$t('carriersViewPage.site')"
            :val="carrier.site"
          />
          <field-view
            :field="$t('carriersViewPage.email')"
            :val="carrier.email"
          />
        </div>

        <div class="row mx-5 my-2">
          <field-view
            :field="$t('carriersViewPage.taxNumber')"
            :val="carrier.tax_number"
          />
          <field-view
            :field="$t('carriersViewPage.name')"
            :val="carrier.name_reference"
          />
        </div>

        <div class="row mx-5 my-2">
          <field-view
            :field="$t('carriersPage.legalName')"
            :val="carrier.legal_name"
          />
          <field-view
            :field="$t('carriersPage.rfbCode')"
            :val="carrier.rfb_code"
          />
        </div>

        <div class="row mx-5 my-2">
          <field-view
            :field="$t('carriersViewPage.slug')"
            :val="carrier.slug"
          />
          <field-view
            :field="$t('carriersViewPage.trackingfunction')"
            :val="carrier.track_status_function"
          />
          <field-view
            v-if="isSuper"
            :field="$t('carriersViewPage.platform')"
            :val="carrier.platform_name"
          />
        </div>

        <div class="row mx-5 my-2">
          <field-view
            :field="$t('carriersViewPage.subsidiary')"
            :val="carrier.is_subsidiary"
          />
          <field-view
            :field="$t('carriersViewPage.deliveryCenter')"
            :val="carrier.is_delivery_center"
          />
        </div>
        <div class="row mx-5 my-2">
          <field-view
            :field="$t('carriersViewPage.createdDate')"
            :val="carrier.created_at"
          />
          <field-view
            :field="$t('carriersViewPage.airportHub')"
            :val="carrier.airport_code"
          />
        </div>
        <div class="row mx-5 my-2">
          <field-view
            :field="$t('carriersViewPage.activityTypes')"
            :val="carrier.activity_types"
          />

          <field-view
            :field="$t('carriersViewPage.carrierCode')"
            :val="carrier.carrier_code"
          />
        </div>

        <div class="row mx-5 my-2">
          <field-view
            :field="$t('carriersPage.fields.isSeller')"
            :val="carrier.is_seller"
          />
        </div>

        <div class="row mx-5 my-2">
          <div class="col">
            <!-- delivery methods table -->
            <div v-if="carrier.delivery_methods.length > 0" class="card">
              <div class="card-header">
                {{ $t("carriersViewPage.deliverymethods") }}
              </div>
              <div class="card-body">
                <div id="delivery_methods" class="">
                  <b-table
                    id="delivery-methods-table"
                    responsive="md"
                    hover
                    small
                    striped
                    :items="carrier.delivery_methods"
                    :fields="deliveryMethodFields"
                  >
                    <template #cell(name)="data">
                      <div>
                        <router-link
                          :to="
                            `/contracts/delivery_methods?deliveryMethodId=` +
                            data.item.id
                          "
                          >{{ data.value }}</router-link
                        >
                      </div>
                    </template>
                    <template #cell(origin_country)="data">
                      {{ data.item.origin_country.join(",") }}
                    </template>
                    <template #cell(destination_country)="data">
                      {{ data.item.destination_country.join(",") }}
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
            <blockquote v-else class="blockquote text-center">
              <p class="mb-0 mt-2">
                {{ $t("carriersViewPage.nodeliverymethodfoundfor") }}
                {{ carrier.name }}
              </p>
            </blockquote>
            <!-- end - methods table -->
          </div>
        </div>

        <div class="row mx-5 my-2">
          <div class="col">
            <!-- users table -->
            <div v-if="users.length > 0" class="card">
              <div class="card-header">{{ $t("carriersViewPage.users") }}</div>
              <div class="card-body">
                <div id="delivery_methods" class="">
                  <b-table
                    id="delivery-methods-table"
                    responsive="md"
                    hover
                    small
                    striped
                    :items="users"
                    :fields="userFields"
                  >
                    <template #cell(name)="data">
                      <div>
                        <router-link
                          :to="`/users_management/users?id=` + data.item.id"
                          >{{ data.value }}</router-link
                        >
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
            <blockquote v-else class="blockquote text-center">
              <p class="mb-0 mt-2">
                {{ $t("carriersViewPage.nouserfoundfor") }} {{ carrier.name }}
              </p>
            </blockquote>
            <!-- end - users table -->
          </div>
        </div>

        <div class="row mx-5 mt-3 d-flex justify-content-center">
          <a
            data-toggle="modal"
            data-target="#editCarrier"
            class="mt-2 ml-2 mr-2 btn btn-success float-center"
            @click="copyCarrier()"
            >{{ $t("carriersViewPage.editCarrier") }}</a
          >
          <a
            data-toggle="modal"
            data-target="#updateAirportHub"
            class="mt-2 ml-2 mr-2 btn btn-success float-center"
            >{{ $t("carriersViewPage.updateAirportHub") }}</a
          >
        </div>
      </div>
    </div>
    <!-- modals -->
    <div id="updateAirportHub" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="updateAirportHubTitle" class="modal-title">
              {{ $t("carriersViewPage.updateAirportHub") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="airport_code">{{
                    $t("carriersViewPage.airportHub")
                  }}</label>
                  <input
                    id="airport_code"
                    v-model="airport_code"
                    type="text"
                    :class="{ 'is-invalid': errors['airport_code'] }"
                    class="form-control"
                    aria-describedby="airportCodeHelp"
                    placeholder="Airport Hub .."
                  />
                  <div
                    v-for="(error, index) in errors['airport_code']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a
              href="#"
              class="btn btn-primary float-right ml-3"
              @click="updateAirportHub()"
              >{{ $t("update") }}</a
            >
            <a
              id="updateAirportHubClose"
              href="#"
              class="btn btn-secondary float-right ml-3"
              data-dismiss="modal"
              >{{ $t("close") }}</a
            >
          </div>
        </div>
      </div>
    </div>

    <div id="editCarrier" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="settingsTitle" class="modal-title">
              {{ $t("carriersViewPage.editCarrier") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="name">{{ $t("carriersViewPage.name") }}</label>
                  <input
                    id="name"
                    v-model="newCarrier.name"
                    type="text"
                    :class="{ 'is-invalid': errors['name'] }"
                    class="form-control"
                    aria-describedby="nameHelp"
                    placeholder="Name .."
                  />
                  <div
                    v-for="(error, index) in errors['name']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="address">{{
                    $t("carriersViewPage.address")
                  }}</label>
                  <input
                    id="address"
                    v-model="newCarrier.address"
                    type="text"
                    :class="{ 'is-invalid': errors['address'] }"
                    class="form-control"
                    aria-describedby="addressHelp"
                    placeholder="Address .."
                  />
                  <div
                    v-for="(error, index) in errors['address']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="address_number">{{
                    $t("carriersViewPage.addressnumber")
                  }}</label>
                  <input
                    id="address_number"
                    v-model="newCarrier.address_number"
                    type="text"
                    :class="{ 'is-invalid': errors['address_number'] }"
                    class="form-control"
                    aria-describedby="address_numberHelp"
                    placeholder="Address number .."
                  />
                  <div
                    v-for="(error, index) in errors['address_number']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="address_complement">{{
                    $t("carriersViewPage.addresscomplement")
                  }}</label>
                  <input
                    id="address_complement"
                    v-model="newCarrier.address_complement"
                    type="text"
                    :class="{ 'is-invalid': errors['address_complement'] }"
                    class="form-control"
                    aria-describedby="address_complementHelp"
                    placeholder="Address complement .."
                  />
                  <div
                    v-for="(error, index) in errors['address_complement']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="zip_code">{{
                    $t("carriersViewPage.zipcode")
                  }}</label>
                  <input
                    id="zip_code"
                    v-model="newCarrier.zip_code"
                    type="text"
                    :class="{ 'is-invalid': errors['zip_code'] }"
                    class="form-control"
                    aria-describedby="zip_codeHelp"
                    placeholder="Zipcode .."
                  />
                  <div
                    v-for="(error, index) in errors['zip_code']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="state">{{ $t("carriersViewPage.state") }}</label>
                  <input
                    id="state"
                    v-model="newCarrier.state"
                    type="text"
                    :class="{ 'is-invalid': errors['state'] }"
                    class="form-control"
                    aria-describedby="stateHelp"
                    placeholder="State .."
                  />
                  <div
                    v-for="(error, index) in errors['state']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <filter-select
                  :label="$t('carriersViewPage.country')"
                  :items="countries"
                  placehol="Select Country .."
                  error-target="country"
                  item-value="alpha2_code"
                  item-text="name"
                  :shows="['name']"
                  :errors="errors"
                  :val-edit="newCarrier.country"
                  @emitValue="newCarrier.country = $event"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="city">{{ $t("carriersViewPage.city") }}</label>
                  <input
                    id="city"
                    v-model="newCarrier.city"
                    type="text"
                    :class="{ 'is-invalid': errors['city'] }"
                    class="form-control"
                    aria-describedby="cityHelp"
                    placeholder="City .."
                  />
                  <div
                    v-for="(error, index) in errors['city']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="phone">{{ $t("carriersViewPage.phone") }}</label>
                  <input
                    id="phone"
                    v-model="newCarrier.phone"
                    type="text"
                    :class="{ 'is-invalid': errors['phone'] }"
                    class="form-control"
                    aria-describedby="phoneHelp"
                    placeholder="Phone .."
                  />
                  <div
                    v-for="(error, index) in errors['phone']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="site">{{ $t("carriersViewPage.site") }}</label>
                  <input
                    id="site"
                    v-model="newCarrier.site"
                    type="text"
                    :class="{ 'is-invalid': errors['site'] }"
                    class="form-control"
                    aria-describedby="siteHelp"
                    placeholder="Site .."
                  />
                  <div
                    v-for="(error, index) in errors['site']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="email">{{ $t("carriersViewPage.email") }}</label>
                  <input
                    id="email"
                    v-model="newCarrier.email"
                    type="text"
                    :class="{ 'is-invalid': errors['email'] }"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="E-mail .."
                  />
                  <div
                    v-for="(error, index) in errors['email']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="tax_number">{{
                    $t("carriersViewPage.taxnumber")
                  }}</label>
                  <input
                    id="tax_number"
                    v-model="newCarrier.tax_number"
                    type="text"
                    :class="{ 'is-invalid': errors['tax_number'] }"
                    class="form-control"
                    aria-describedby="tax_numberHelp"
                    placeholder="Tax number .."
                  />
                  <div
                    v-for="(error, index) in errors['tax_number']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="name_reference">{{
                    $t("carriersViewPage.namereference")
                  }}</label>
                  <input
                    id="name_reference"
                    v-model="newCarrier.name_reference"
                    type="text"
                    :class="{ 'is-invalid': errors['name_reference'] }"
                    class="form-control"
                    aria-describedby="name_referenceHelp"
                    placeholder="Name reference .."
                  />
                  <div
                    v-for="(error, index) in errors['name_reference']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="legal_name">{{
                    $t("carriersPage.legalName")
                  }}</label>
                  <input
                    id="legal_name"
                    v-model="newCarrier.legal_name"
                    type="text"
                    :class="{ 'is-invalid': errors['legal_name'] }"
                    class="form-control"
                    aria-describedby="legal_nameHelp"
                    placeholder="Tax number .."
                  />
                  <div
                    v-for="(error, index) in errors['legal_name']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="rfb_code">{{ $t("carriersPage.rfbCode") }}</label>
                  <input
                    id="rfb_code"
                    v-model="newCarrier.rfb_code"
                    type="text"
                    :class="{ 'is-invalid': errors['rfb_code'] }"
                    class="form-control"
                    aria-describedby="rfb_codeHelp"
                    placeholder="Name reference .."
                  />
                  <div
                    v-for="(error, index) in errors['rfb_code']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="slug">{{ $t("carriersViewPage.slug") }}</label>
                  <input
                    id="slug"
                    v-model="newCarrier.slug"
                    type="text"
                    :class="{ 'is-invalid': errors['slug'] }"
                    class="form-control"
                    aria-describedby="slugHelp"
                    placeholder="Slug .."
                  />
                  <div
                    v-for="(error, index) in errors['slug']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="track_status_function">{{
                    $t("carriersViewPage.trackstatusfunction")
                  }}</label>
                  <input
                    id="track_status_function"
                    v-model="newCarrier.track_status_function"
                    type="text"
                    :class="{ 'is-invalid': errors['track_status_function'] }"
                    class="form-control"
                    aria-describedby="track_status_functionHelp"
                    placeholder="Track status function .."
                  />
                  <div
                    v-for="(error, index) in errors['track_Status_function']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="carrier_code">{{
                    $t("carriersViewPage.carrierCode")
                  }}</label>
                  <input
                    id="carrier_code"
                    v-model="newCarrier.carrier_code"
                    type="text"
                    :class="{ 'is-invalid': errors['carrier_code'] }"
                    class="form-control"
                    placeholder="Ex: NBX"
                  />
                  <div
                    v-for="(error, index) in errors['carrier_code']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-check form-check-inline">
                  <input
                    id="is_seller"
                    v-model="newCarrier.is_seller"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="is_seller">
                    {{ $t("carriersPage.fields.isSeller") }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a
              href="#"
              class="btn btn-primary float-right ml-3"
              @click="updateCarrier()"
              >{{ $t("update") }}</a
            >
            <a
              id="editCarrierClose"
              href="#"
              class="btn btn-secondary float-right ml-3"
              data-dismiss="modal"
              >{{ $t("cancel") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsersService from "../../../services/UsersService";
import UserService from "../../../services/UserService";
import CarrierService from "../../../services/CarrierService";
import CountryService from "../../../services/CountryService";
import NProgress from "nprogress";
import FieldView from "@/components/FieldView";
import FilterSelect from "@/components/FilterSelect";
import HeaderTitle from "@/components/HeaderTitle";

const usersService = new UsersService();
const userService = new UserService();
const carrierService = new CarrierService();
const countryService = new CountryService();

export default {
  name: "CarrierView",
  components: {
    "field-view": FieldView,
    "filter-select": FilterSelect,
    HeaderTitle,
  },
  data: () => {
    return {
      carrier: {},
      newCarrier: {
        name: "",
        address: "",
        address_number: "",
        address_complement: "",
        zip_code: "",
        state: "",
        city: "",
        phone: "",
        site: "",
        email: "",
        tax_number: "",
        rfb_code: "",
        legal_name: "",
        name_reference: "",
        slug: "",
        track_status_function: "",
        carrier_code: "",
        is_seller: false,
      },
      airport_code: "",
      users: [],
      countries: [],
      logged_in_user: userService.getLoggedInUser(),
      errors: [],
    };
  },
  computed: {
    title() {
      return this.$t("carriersViewPage.title") + this.$route.params.id;
    },
    isSuper() {
      return this.$store.state.user.is_super;
    },
    deliveryMethodFields() {
      return [
        {
          key: "name",
          label: this.$t("carriersViewPage.deliveryMethodFields.name"),
          sortable: false,
        },
        {
          key: "type",
          label: this.$t("carriersViewPage.deliveryMethodFields.type"),
          sortable: false,
        },
        {
          key: "service",
          label: this.$t("carriersViewPage.deliveryMethodFields.service"),
          sortable: false,
        },
        {
          key: "origin_country",
          label: this.$t("carriersViewPage.deliveryMethodFields.originCountry"),
          sortable: false,
        },
        {
          key: "destination_country",
          label: this.$t(
            "carriersViewPage.deliveryMethodFields.destinationCountry",
          ),
          sortable: false,
        },
      ];
    },
    userFields() {
      return [
        {
          key: "name",
          label: this.$t("carriersViewPage.userFields.name"),
          sortable: false,
        },
        {
          key: "email",
          label: this.$t("carriersViewPage.userFields.email"),
          sortable: false,
        },
        {
          key: "group_name",
          label: this.$t("carriersViewPage.userFields.groupName"),
          sortable: false,
        },
      ];
    },
  },
  beforeMount() {
    this.reloadCarrier();
    countryService.getCountries().then((response) => {
      this.countries = response.data.data;
    });
  },
  methods: {
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date) {
      const data = new Date(date);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(data.getHours()) +
        ":" +
        this.addZero(data.getMinutes()) +
        ":" +
        this.addZero(data.getSeconds());
      return formatedDate;
    },
    reloadCarrier() {
      carrierService.getCarrier(this.$route.params.id).then((response) => {
        this.carrier = response.data.data;
        let date = response.data.data.created_at;
        this.carrier.created_at = this.formateDate(date);
        if (this.carrier.users) {
          this.carrier.users.forEach((user) => {
            usersService.getUser(user.id).then((response) => {
              this.users.push(response.data.data);
            });
          });
        }
      });
    },
    updateCarrier() {
      carrierService
        .updateCarrier(this.$route.params.id, this.newCarrier)
        .then(() => {
          document.getElementById("editCarrierClose").click();
          //$('#editCarrier').modal('hide');
          carrierService.getCarrier(this.$route.params.id).then((response) => {
            this.carrier = response.data.data;
            let date = response.data.data.created_at;
            this.carrier.created_at = this.formateDate(date);
          });
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
          NProgress.done();
        });
    },
    updateAirportHub() {
      let body = { airport_code: this.airport_code };
      carrierService
        .updateAirportHub(this.$route.params.id, body)
        .then(() => {
          document.getElementById("updateAirportHubClose").click();
          this.reloadCarrier();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
    initializeNewCarrier() {
      this.newCarrier.name = "";
      this.newCarrier.address = "";
      this.newCarrier.address_number = "";
      this.newCarrier.address_complement = "";
      this.newCarrier.zip_code = "";
      this.newCarrier.state = "";
      this.newCarrier.city = "";
      this.newCarrier.phone = "";
      this.newCarrier.site = "";
      this.newCarrier.email = "";
      this.newCarrier.tax_number = "";
      this.newCarrier.rfb_code = "";
      this.newCarrier.legal_name = "";
      this.newCarrier.name_reference = "";
      this.newCarrier.slug = "";
      this.newCarrier.track_status_function = "";
      this.newCarrier.is_seller = false;
      this.errors = [];
    },
    copyCarrier() {
      this.errors = [];
      this.newCarrier = JSON.parse(JSON.stringify(this.carrier));
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    showArrString(arr) {
      const finalString = arr.join(", ");
      return finalString;
    },
  },
};
</script>
