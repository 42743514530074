<template>
  <div>
    <button
      class="button-card-items"
      data-toggle="modal"
      :data-target="modalTarget"
      @click="$emit('click')"
    >
      <div class="connect-badge" :class="isConnected ? 'active' : ''">
        {{ isConnected ? $t("connected") : $t("disConnected") }}
      </div>
      <div class="row">
        <img class="imagem-card" :src="image" />
      </div>
      <div>
        <hr />
        <h6 class="card-label">
          {{ label }}
        </h6>
        <div class="link-card">
          {{ isConnected ? $t("disConnect") : $t("connect") }} >
        </div>
      </div>
    </button>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "NbCardLinkBrand",
  props: {
    modalTarget: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    isConnected: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped>
.button-card-items {
  transition: all 0.3s ease;
  height: 14.1rem;
  width: 15.9rem;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 8px var(--gray-05);
  border: 1px solid var(--gray-05);
  border-radius: 2px;
}
.button-card-items:hover {
  background: var(--gray-05) 0% 0% no-repeat padding-box;
  border: 1px solid var(--primary);
}
.button-card-items:hover .link-card {
  color: var(--primary);
}
.connect-badge {
  transition: all 0.3s ease;
  position: relative;
  background: var(--gray-10) 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 0.75rem 0.75rem;
  width: 7rem;
  height: 2rem;
  margin-left: calc(50% - 3.5rem);
  color: var(--black);
  text-align: center;
  font: normal normal normal 12px/33px Nunito Sans;
  letter-spacing: 0px;
  top: -1.1rem;
}
.connect-badge.active {
  background: var(--success-10) 0% 0% no-repeat padding-box;
  color: var(--success);
}
.imagem-card {
  padding: 0 2rem;
  object-fit: contain;
  width: 16rem;
  height: 4.5rem;
  margin: auto;
}
.card-label {
  color: var(--black);
  text-align: left;
  font: normal normal bold 16px/20px Nunito Sans;
  letter-spacing: 0px;
  margin-left: 1.5rem;
  margin-bottom: 0.5rem;
}
.link-card {
  color: var(--gray-60);
  text-align: left;
  text-decoration: underline;
  font: normal normal 600 14px/20px Nunito Sans;
  letter-spacing: 0px;
  margin-left: 1.5rem;
  margin-bottom: 0.4rem;
}
</style>
