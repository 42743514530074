<template>
  <div>
    <header-title :title="title" />
    <div class="frame row justify-content-center">
      <div class="col-12 mb-5">
        <div class="card border-white rounded py-4">
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('permissionsViewPage.name')"
              :val="permission.name"
            />
            <field-view
              :field="$t('permissionsViewPage.description')"
              :val="permission.description"
            />
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import PermissionService from "../../../services/PermissionService";
import FieldView from "@/components/FieldView";
import HeaderTitle from "../../../components/HeaderTitle.vue";

const permissionService = new PermissionService();

export default {
  name: "PermissionsView",
  components: {
    HeaderTitle,
    "field-view": FieldView,
  },
  data: () => {
    return {
      permission: {},
    };
  },
  computed: {
    title() {
      return this.$t("permissionsViewPage.title") + this.$route.params.id;
    },
  },
  beforeMount() {
    this.reloadPermission();
  },
  methods: {
    reloadPermission() {
      permissionService
        .getPermission(this.$route.params.id)
        .then((response) => {
          this.permission = response.data.data;
        });
    },
  },
};
</script>
