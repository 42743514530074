import PassThrough from "../../components/PassThrough";
import NobordistLogs from "../../views/logs/nobordist_logs/NobordistLogs";
import NobordistLogView from "../../views/logs/nobordist_logs/View";
import ExternalLogs from "../../views/logs/external_logs/ExternalLogs";
import ExternalLogView from "../../views/logs/external_logs/View";
import Logs from "@/views/logs/alert_logs/NbAlertLogsList.vue";
import AlertLogView from "../../views/logs/alert_logs/View";
import OrdersRejected from "../../views/logs/order_logs/RejectedOrder";
import OrderLogView from "../../views/logs/order_logs/View";
import TrackingRules from "../../views/logs/tracking_rules/TrackingRules";
import TrackingRuleView from "../../views/logs/tracking_rules/View";
import GeneralEmails from "../../views/logs/general_emails/GeneralEmails";
//import Notifications from "../../views/logs/notifications/Notifications.vue";
//import UserNotifications from "../../views/logs/user_notifications/UserNotifications.vue";
import GeneralEmailsView from "../../views/logs/general_emails/View";
import DelayedJobs from "../../views/logs/delayed_jobs/DelayedJobs";
import store from "@/store/index";

const isSuper = store.state.user.is_super;

const logs_list = {
  path: "/logs/alert_logs",
  name: "logs_list",
  component: Logs,
  meta: {
    visible: true,
    label: "AlertLogs",
    searchData: "alertLogs",
  },
};

const logs_alert_log_view = {
  path: "/logs/alert_logs/:id",
  name: "alert_log_view",
  component: AlertLogView,
  meta: {
    visible: false,
    label: "Alert Log View",
  },
};

const logs_nobordist_logs = {
  path: "/logs/nobordist",
  name: "logs_nobordist",
  component: NobordistLogs,
  meta: {
    visible: isSuper,
    label: "NobordistLogs",
    searchData: "nobordistLogs",
  },
};

const logs_nobordist_logs_view = {
  path: "/logs/nobordist/:id",
  name: "logs_nobordist_view",
  component: NobordistLogView,
  meta: {
    visible: false,
    label: "NobordistLog view",
  },
};

const logs_external_logs = {
  path: "/logs/external",
  name: "logs_external",
  component: ExternalLogs,
  meta: {
    visible: isSuper,
    label: "ExternalLogs",
    searchData: "externalLogs",
  },
};

const logs_external_logs_view = {
  path: "/logs/external/:id",
  name: "logs_external_view",
  component: ExternalLogView,
  meta: {
    visible: false,
    label: "ExternalLog view",
  },
};

const logs_tracking_rules = {
  path: "/logs/tracking_rules",
  name: "tracking_rules",
  component: TrackingRules,
  meta: {
    visible: true,
    label: "TrackingRules",
    searchData: "trackingRules",
  },
};

const logs_tracking_rule_views = {
  path: "/logs/tracking_rules/:id",
  name: "tracking_rule_view",
  component: TrackingRuleView,
  meta: {
    visible: false,
    label: "Tracking Rule view",
  },
};

const rejected_order_logs = {
  path: "/orders/rejected",
  name: "orders_rejected",
  component: OrdersRejected,
  meta: {
    visible: true,
    label: "RejectedOrdersLogs",
    searchData: "rejectedOrdersLogs",
  },
};

const rejected_order_log_view = {
  path: "/orders/rejected/:id",
  name: "rejected_order_log_view",
  component: OrderLogView,
  meta: {
    visible: false,
    label: "Rejected order view",
  },
};

const logs_general_emails = {
  path: "/logs/general_emails",
  name: "general_emails",
  component: GeneralEmails,
  meta: {
    visible: true,
    label: "GeneralEmails",
    searchData: "generalEmails",
  },
};

/* const logs_user_notifications = {
  path: "/logs/user_notifications",
  name: "user_notifications",
  component: UserNotifications,
  meta: {
    visible: false,
    label: "UserNotifications",
    searchData: "userNotifications",
  },
}; 

const logs_notifications = {
  path: "/logs/notifications",
  name: "notifications",
  component: Notifications,
  meta: {
    visible: false,
    label: "Notifications",
    searchData: "notifications",
  },
};
*/

const logs_general_emails_views = {
  path: "/logs/general_emails/:id",
  name: "general_emails_view",
  component: GeneralEmailsView,
  meta: {
    visible: false,
    label: "General Emails View",
  },
};

const delayed_jobs_logs = {
  path: "/logs/delayed_jobs",
  name: "delayed_jobs",
  component: DelayedJobs,
  meta: {
    visible: isSuper,
    label: "DelayedJobs",
    searchData: "delayedJobs",
  },
};

let logs_children = [
  logs_alert_log_view,
  logs_list,
  logs_nobordist_logs,
  logs_nobordist_logs_view,
  rejected_order_log_view,
  logs_external_logs,
  logs_external_logs_view,
  logs_tracking_rules,
  logs_tracking_rule_views,
  logs_general_emails,
  rejected_order_logs,
  logs_general_emails_views,
  delayed_jobs_logs,
  //logs_notifications,
  logs_general_emails_views,
];

if (!isSuper) {
  logs_children = [
    logs_alert_log_view,
    logs_list,
    rejected_order_log_view,
    logs_tracking_rules,
    logs_tracking_rule_views,
    logs_general_emails,
    rejected_order_logs,
    logs_general_emails_views,
    //logs_notifications,
    //logs_user_notifications,
  ];
}

const logs = {
  path: "/logs",
  name: "logs",
  component: PassThrough,
  meta: {
    visible: true,
    label: "Logs",
    icon: "activity",
  },
  children: logs_children,
};

export default logs;
