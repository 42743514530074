<template>
  <div v-click-outside="resetSearch" class="searchbar">
    <div class="input-container">
      <i class="icon fas fa-search"></i>
      <input
        type="text"
        v-model="search"
        placeholder="O que você está buscando hoje?"
        @keyup.esc="resetSearch"
      />
    </div>

    <div v-show="matchRoutes.length" class="searchbar-result">
      <div
        @click="redirectTo(item.path)"
        class="item"
        v-for="item in matchRoutes"
        :key="item.path"
      >
        <span v-html="item.highlight"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { directive } from "v-click-outside";
export default {
  name: "NbSearchRoute",
  directives: {
    clickOutside: directive,
  },
  data() {
    return {
      search: "",
      searchableRoutes: [],
    };
  },
  watch: {
    currentLocale() {
      this.buildSearchData();
    },
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
    matchRoutes() {
      if (!this.search.trim()) return [];

      const matcher = new RegExp(this.search, "i");

      return this.searchableRoutes
        .filter((item) => {
          const normalizedOption = item.label
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

          return matcher.test(normalizedOption);
        })
        ?.map((item) => ({
          ...item,
          highlight: item.label.replace(
            matcher,
            `<span class="font-weight-bold">${this.search}</span>`,
          ),
        }));
    },
  },
  methods: {
    buildSearchData() {
      const routerOptions = [];
      const routes = this.$router.options.routes;

      const appendRoute = (route) => {
        if (route.meta.searchData) {
          routerOptions.push({
            label: this.$t(`components.searchData.${route.meta.searchData}`),
            path: route.path,
          });
        }

        if (route?.children?.length) {
          route?.children?.forEach(appendRoute);
        }
      };

      routes.forEach(appendRoute);

      this.searchableRoutes = routerOptions;
    },
    redirectTo(path) {
      this.search = "";
      this.$router.push(path);
    },
    resetSearch() {
      this.search = "";
    },
  },
  created() {
    this.buildSearchData();
  },
};
</script>

<style lang="scss" scoped>
.searchbar {
  position: relative;
  .input-container {
    background-color: var(--gray-05);
    border-radius: 4px;
    padding: 0.5rem 1rem;
    color: var(--gray-60);
    display: flex;
    align-items: center;
    gap: 0.8rem;
    position: relative;
    width: 18rem;
    height: 2.25rem;
    border: 1px solid transparent;
    transition: all 0.3s ease-in;
    &:focus-within {
      border: 1px solid var(--black);
    }
    .icon {
      position: absolute;
    }
    input {
      background: transparent;
      border: none;
      outline: none;
      padding-left: 1.5rem;
      width: 100%;
    }
  }
  &-result {
    max-height: 200px;
    overflow-y: auto;
    background-color: var(--white);
    border: 1px solid var(--gray-10);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    position: absolute;
    left: 0;
    top: 3rem;
    width: 100%;
    z-index: 15;
    .item {
      padding: 0.2rem 0.5rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      font-weight: 400;
      &:hover {
        background-color: var(--gray-05);
      }
      & + .item {
        border-top: 1px solid var(--gray-05);
      }
    }
  }
}
</style>
