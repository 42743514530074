import api from "./HttpService";

export default class ProductDocumentService {
  /**
   * Get product_document
   *
   * @param {*} id
   * @param filters
   * @returns {Promise}
   */
  getProductDocument(id = null, filters = {}) {
    return api.get("/v1/product_documents/" + id, {
      params: filters,
    });
  }

  /**
   * Get product_documents
   *
   * @param filters
   * @returns {Promise}
   */
  getProductDocuments(filters = {}) {
    return api.get("/v1/product_documents", {
      params: filters,
    });
  }

  /**
   * Download product_documents
   *
   * @param {*} filters
   * @returns {Promise}
   */
  downloadProductDocuments(filters = {}) {
    return api.get("/v1/product_documents/download", {
      params: filters,
    });
  }

  /**
   * Download volumes by id and cols
   * @param data = Object, { volumes_id: [], columns: [] }
   * @returns {Promise}
   */
  downloadMsds(order_id) {
    return api.get("/v1/product_documents/download_msds/" + order_id);
  }

  /**
   * Get imported product_document with errors
   *
   * @returns {Promise}
   */
  getRejectedProductDocuments() {
    return api.get("/v1/product_documents/import/errors");
  }

  /**
   * Get product_document contract
   *
   * @param {*} id
   * @returns {Promise}
   */
  getContract(id = null) {
    return api.get("/v1/product_documents/" + id + "/contract");
  }

  /**
   * Get product_document overpack
   *
   * @param {*} id
   * @returns {Promise}
   */
  getOverpack(id = null) {
    return api.get("/v1/product_documents/" + id + "/overpack");
  }

  /**
   * Get product_document overpack
   *
   * @param {*} id
   * @returns {Promise}
   */
  getSalesChannel(id = null) {
    return api.get("/v1/product_documents/" + id + "/saleschannel");
  }

  /**
   * Get product_document checkpoints
   *
   * @param {*} id
   * @returns {Promise}
   */
  getCheckpoints(id = null) {
    return api.get("/v1/product_documents/" + id + "/checkpoints");
  }

  /**
   * Set product_document
   *
   * @param data
   * @returns {Promise}
   */
  createProductDocument(data = {}) {
    return api.post("/v1/product_document", data);
  }

  /**
   * Set product_document
   *
   * @param data
   * @returns {Promise}
   */
  setProductDocument(data = {}) {
    return api.post("/v1/product_documents", data);
  }

  /**
   * Update product_document
   *
   * @param {*} id
   * @param {*} data
   * @returns {Promise}
   */
  updateProductDocument(id = null, data = {}) {
    return api.put("/v1/product_documents/" + id, data);
  }

  /**
   * Delete product_document
   *
   * @param {*} id
   * @returns {Promise}
   */
  removeProductDocument(id = null) {
    return api.delete("/v1/product_documents/" + id);
  }

  /**
   * Import product_documents
   *
   * @param {*} data
   * @returns {Promise}
   */
  importProductDocuments(data) {
    return api.post("/v1/product_documents/import", data);
  }

  addCheckpoint(id = null, params) {
    return api.post("/v1/product_documents/" + id + "/add-checkpoint", params);
  }

  //To print Labels, Commercial Invoice and Packing Slip in one PDF
  printingAll(ids) {
    return api.put("/v1/printings/all", ids);
  }
}
