<template>
  <table>
    <thead :class="thClass">
      <th>{{ $t("carriersPage.userFields.name") }}</th>
      <th>{{ $t("carriersPage.userFields.email") }}</th>
      <th>{{ $t("carriersPage.userFields.groupName") }}</th>
      <th>{{ $t("carriersPage.userFields.status") }}</th>
      <th>{{ $t("carriersPage.fields.creationDate") }}</th>
      <th>{{ $t("actions") }}</th>
    </thead>
    <tbody>
      <tr v-for="item in users" :key="item.id">
        <td>
          <NbButton variant="tertiary" @click="goToUser(item.id)">
            {{ item.name }}
          </NbButton>
        </td>
        <td>
          {{ item?.email || "-" }}
        </td>
        <td>
          {{ item?.group_name || "-" }}
        </td>
        <td>
          <NbBadge
            :text="item.is_blocked ? $t('blocked') : $t('active')"
            :type="item.is_blocked ? 'danger' : 'success'"
          />
        </td>
        <td>
          {{ item?.created_at | formattedDate }}
        </td>

        <td>
          <NbButton variant="tertiary" @click="goToUser(item.id)">
            {{ $t("carriersPage.seeUser") }} >
          </NbButton>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import moment from "moment";
export default {
  name: "CarrierDeliveryMethods",
  components: {
    NbButton,
    NbBadge,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    thClass: {
      type: String,
      default: "",
    },
  },
  filters: {
    formattedDate(value) {
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    goToUser(id) {
      this.$helpers.closeAllModals();
      this.$router.push(`/users_management/users?id=${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1rem;
  thead {
    &::after {
      border: none;
    }
    th {
      text-align: center;
    }
  }

  tr {
    background-color: #e1e1f5;
    td {
      padding: 1rem 0.75rem;
      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
</style>
