<template>
  <div>
    <ModalHeader>
      <ModalTitle>{{ $t("confirm") }}</ModalTitle>
    </ModalHeader>

    <p class="body-4">
      {{ $t("trackingRuleNotificationsPage.wouldYouLikeToRemoveRule") }}
      <strong>{{ rule.name }}</strong
      >?
    </p>

    <p class="body-4">
      {{ $t("trackingRuleNotificationsPage.removalIsFinal") }}
    </p>

    <ModalFooter class="d-flex justify-content-end gap-2">
      <ModalClose>{{ $t("cancel") }}</ModalClose>
      <NbButton :disabled="loading" @click="remove">
        {{ $t("remove") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalHeader.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import TrackingRuleNotificationsService from "@/services/TrackingRuleNotificationsService";

const trackingRuleNotificationsService = new TrackingRuleNotificationsService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
  },
  props: {
    rule: {
      type: Object,
      required: true,
    },
  },
  inject: ["context"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async remove() {
      try {
        this.loading = true;
        await trackingRuleNotificationsService.destroy(this.rule.id);
        this.$emit("success");
        this.context?.close();
      } catch (error) {
        const message =
          error?.response?.data?.messages?.[0] ||
          this.$t("errorMessages.genericError");
        this.$bvToast.toast(message, {
          variant: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
