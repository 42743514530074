<template>
  <div class="grid grid-cols-4 gap-4">
    <div>
      <label class="input-label">
        {{ $t("creationDate") }}
      </label>
      <div class="input-view">
        {{ order.created_at | datetime }}
      </div>
    </div>
    <div>
      <label class="input-label">
        {{ $t("ordersPage.fields.estimatedDeliveryDate") }}
      </label>
      <div class="input-view">
        <span v-if="order?.estimated_delivery_date">
          {{ order.estimated_delivery_date | datetime }}
        </span>
        <span v-else>-</span>
      </div>
    </div>
    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('ordersPage.fields.incoterm')"
      v-model="order.incoterm"
      id="order.incoterm"
    />

    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('ordersPage.fields.financialStatus')"
      v-model="order.financial_status"
      id="order.financial_status"
    />

    <router-link
      v-if="order?.current_status?.title"
      :to="`/checkpoints/checkpoints?nobordistCheckpointId=${order.current_status.id}`"
      class="link-1 col-span-4"
    >
      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('ordersPage.fields.currentStatus')"
        v-model="order.current_status.title"
        id="order.current_status.title"
      />
    </router-link>
    <NbTextInput
      v-else
      disabled
      variant="borderless-gray-60"
      :name="$t('ordersPage.fields.currentStatus')"
      value="-"
      id="order.current_status.title"
      class="col-span-4"
    />

    <div class="info">
      <small>
        {{ $t("ordersViewPage.landedCost") }}
        <NbHelpText
          id="is-landed-popover"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("ordersViewPage.landedCostDescription") }}
        </NbHelpText>
      </small>
      <IsCheckedIcon :checked="order.is_landed_cost" />
    </div>
    <div class="info">
      <small>
        {{ $t("ordersViewPage.dispatched") }}
        <NbHelpText
          id="is-dispatched-popover"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("ordersViewPage.dispatchedDescription") }}
        </NbHelpText>
      </small>
      <IsCheckedIcon :checked="order.is_dispatched" />
    </div>
    <div class="info">
      <small>{{ $t("ordersViewPage.senderIsCpf") }}</small>
      <IsCheckedIcon :checked="order.remetente_is_cpf" />
    </div>
    <div class="info">
      <small>{{ $t("ordersViewPage.receiverIsCommercial") }}</small>
      <IsCheckedIcon :checked="!!order.is_commercial_destination" />
    </div>
  </div>
</template>

<script>
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    IsCheckedIcon,
    NbHelpText,
    NbTextInput,
  },
};
</script>

<style lang="scss" scoped>
.info {
  display: flex;
  flex-direction: column;
  small {
    font-weight: 700;
    font-size: 0.75rem;
  }
  span {
    line-height: 1.2;
  }
}
</style>
