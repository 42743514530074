<template>
  <div>
    <DatatableHeader>
      <DatatableSearch
        v-model="search"
        @submit="onSearch"
        class="flex-grow-1"
      />
      <NbButton
        variant="quaternary"
        v-b-tooltip.hover.left
        title="Refresh"
        class="d-flex gap-2 align-items-center"
        @click="getData()"
        :disabled="loading"
      >
        <NbIcon
          :class="{ refreshing: loading }"
          icon="refresh-ccw"
          :size="16"
        />
      </NbButton>
      <DatatableFilters
        v-if="filters.length"
        :filters="filters"
        :filtered="appliedFilters"
        @filters="onFilters"
        @reset="onReset"
      />
      <DatatableColumns
        v-if="showColumnOptions"
        :columns="tableColumns"
        @update="saveColumnList"
      />
      <slot name="actions">
        <DatatableActions
          v-if="actions?.length"
          :disabled="!selectedItemIds.length"
        >
          <DatatableAction
            v-for="action in actions"
            @click="onAction(action.action)"
            :key="action.action"
          >
            {{ action.label }}
          </DatatableAction>
        </DatatableActions>
      </slot>
    </DatatableHeader>
    <Datatable
      :columns="tableColumns"
      :data="resource.elements"
      :selected.sync="selectedItemIds"
      :selectable="selectable"
      :clickable="clickable"
      :count="resource.count"
      @sort="onSort"
      @rowClick="onRowClick"
    >
      <template #thead>
        <HeaderSkeleton v-if="loadingColumns" :columns="totalVisibleColumns" />
      </template>

      <template #tbody>
        <BodySkeleton
          v-if="loading"
          :columns="totalVisibleColumns"
          :rows="+pagination.limit"
        />
      </template>

      <template v-for="column in columns" v-slot:[column.key]="data">
        <slot :name="column.key" :row="data.row"></slot>
      </template>
    </Datatable>
    <p
      v-if="!loading && !resource.elements.length"
      class="body-4 p-4 text-center"
    >
      {{ $t("notFound") }}
    </p>
    <Pagination
      v-if="resource.elements.length"
      v-bind="pagination"
      :count="resource.count"
      @paginate="onPaginate"
      :limits="limits"
    >
      <div v-if="selectedItemIds.length">
        <DatatableSelectable
          :page-length="resource.elements.length"
          :selected="selectedItemIds"
          :total="resource.count"
          @selectAll="selectAllItems"
          @clear="resetSelectedItems"
        />
      </div>
    </Pagination>
  </div>
</template>

<script>
import DatatableHeader from "@/components/datatable/DatatableHeader.vue";
import DatatableSearch from "@/components/datatable/DatatableSearch.vue";
import DatatableFilters from "@/components/datatable/DatatableFilters.vue";
import DatatableColumns from "@/components/datatable/DatatableColumns.vue";
import Datatable from "@/components/datatable/Datatable.vue";
import Pagination from "@/components/datatable/Pagination.vue";
import api from "../../services/HttpService";
import TablesService from "../../services/TablesService";
import HeaderSkeleton from "./HeaderSkeleton.vue";
import BodySkeleton from "./BodySkeleton.vue";
import DatatableSelectable from "./DatatableSelectable.vue";
import DatatableActions from "./DatatableActions.vue";
import DatatableAction from "./DatatableAction.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

const tableService = new TablesService();

const LOCAL_STORAGE_COLUMNS_KEY = "columns_configuration";

export default {
  components: {
    DatatableHeader,
    DatatableSearch,
    DatatableFilters,
    DatatableColumns,
    Datatable,
    Pagination,
    HeaderSkeleton,
    BodySkeleton,
    DatatableSelectable,
    DatatableActions,
    DatatableAction,
    NbIcon,
    NbButton,
  },
  props: {
    namespace: {
      type: String,
      required: true,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: [Array, undefined],
      default: undefined,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    searchParams: {
      type: Object,
      default: () => {},
    },
    urlState: {
      type: Boolean,
      default: true,
    },
    limits: {
      type: [Array, undefined],
      default: undefined,
    },
    limit: {
      type: [Number, undefined],
      default: undefined,
    },
    name: {
      type: String,
      default: "",
    },
    showColumnOptions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingColumns: false,
      search: this.$route.query.search || "",
      selectedItemIds: [],
      resource: {
        ids: [],
        elements: [],
        count: 0,
      },
      pagination: {
        page: this.$route.query.page || 1,
        limit: this.limit || this.$route.query.limit || 20,
      },
      sort: {
        order_by: "",
        order_dir: "",
      },
      appliedFilters: {},
      tableColumns: this.columns,
    };
  },
  computed: {
    hasFilter() {
      return !!Object.keys(this.appliedFilters).length;
    },
    paginationProps() {
      const offset =
        1 * (this.pagination.page * this.pagination.limit) -
        this.pagination.limit;
      return {
        offset,
        limit: this.pagination.limit,
      };
    },
    totalVisibleColumns() {
      const length = this.tableColumns.filter(
        (item) => item.show !== false,
      )?.length;

      return this.selectable ? length + 1 : length;
    },
    isAllItemsFromCurrentPageSelected() {
      return this.selectedItemIds.length === this.resource.elements.length;
    },
    totalItems() {
      return this.resource.elements.length;
    },
    storageTableKey() {
      return this.name || this.namespace?.replace(/\//g, "_");
    },
  },
  watch: {
    selectedItemIds(val) {
      this.$emit("update:selected", val);
    },
    selected(val) {
      this.selectedItemIds = val;
    },
  },
  methods: {
    updateRowData({ key = "id", value, data }) {
      const elementIndex = this.resource.elements.findIndex(
        (item) => item[key] === value,
      );

      if (elementIndex !== -1) {
        this.resource.elements.splice(elementIndex, 1, {
          ...this.resource.elements[elementIndex],
          ...data,
        });
      }
    },
    onAction(action) {
      this.$emit(action);
    },
    resetSelectedItems() {
      this.selectedItemIds = [];
    },
    selectAllItems() {
      this.selectedItemIds = this.resource.ids;
    },
    buildParams() {
      const params = {
        ...this.appliedFilters,
        ...this.paginationProps,
        order_by: this.sort.key,
        order_direction: this.sort.dir,
        search: this.search,
      };

      return Object.fromEntries(
        Object.entries(params).filter(([, value]) => value !== ""),
      );
    },
    mergeQueryParams(data) {
      if (!this.urlState) {
        return;
      }
      if (JSON.stringify(data) !== JSON.stringify(this.$route.query)) {
        this.$router.replace({
          query: { ...this.$route.query, ...data },
        });
      }
    },
    async getData() {
      try {
        this.loading = true;
        const params = {
          ...this.searchParams,
          ...this.buildParams(),
        };
        const { data } = await api.get(`/v1/${this.namespace}`, {
          params,
        });

        this.resource = data.data;
        this.$emit("count", data?.data?.count);
        this.$emit("ids", data?.data?.ids || []);
      } finally {
        this.loading = false;
      }
    },
    onPaginate(data) {
      this.pagination = data;
      this.getData();
      this.mergeQueryParams(data);
    },
    onSort(data) {
      this.sort = data;
      this.getData();
    },
    onRowClick(data) {
      this.$emit("rowClick", data);
    },
    onReset() {
      this.appliedFilters = {};
      this.$router.replace({
        query: undefined,
      });

      this.getData();
    },
    onFilters(data) {
      this.mergeQueryParams(data);
      this.appliedFilters = Object.fromEntries(
        Object.entries(data).filter(([, value]) => value !== undefined),
      );
      this.getData();
    },
    onSearch() {
      this.getData();
      this.mergeQueryParams({ search: this.search });
    },
    checkUrlSearchParams() {
      const queryParams = this.$route.query;
      const filterKeys = this.filters.reduce((acc, current) => {
        acc.push(...(current?.inputs?.map((item) => item.key) || []));
        return acc;
      }, []);

      const filteredParams = Object.fromEntries(
        Object.entries(queryParams).filter(
          ([key, value]) => filterKeys.includes(key) && !!value,
        ),
      );

      if (Object.entries(filteredParams).length) {
        this.appliedFilters = filteredParams;
      }

      this.getData();
    },
    setTableColumns(data) {
      const mappedVisibleColumns = this.columns.map((column) => {
        let columnIsVisible = true;
        const item = data.find((item) => item.key === column.key);
        if (item) {
          columnIsVisible = item.show;
        }

        item.show = columnIsVisible;
        return column;
      });

      const sortableColumns = mappedVisibleColumns.sort((a, b) => {
        const indexA = data.findIndex((col) => col.key === a.key);
        const indexB = data.findIndex((col) => col.key === b.key);

        return indexA - indexB;
      });

      this.tableColumns = sortableColumns;
    },
    getTableColumnsFromStorage() {
      try {
        const localData = localStorage.getItem(LOCAL_STORAGE_COLUMNS_KEY);
        const parser = JSON.parse(localData);
        return parser || {};
      } catch {
        return {};
      }
    },
    saveColumnList(columnList) {
      const data = this.getTableColumnsFromStorage();
      data[this.storageTableKey] = columnList;

      tableService.setColumns(JSON.stringify(data));
      localStorage.setItem(LOCAL_STORAGE_COLUMNS_KEY, JSON.stringify(data));
      this.setTableColumns(columnList);
    },
    async loadColumnsFromAPI() {
      try {
        this.loadingColumns = true;
        const localData = localStorage.getItem(LOCAL_STORAGE_COLUMNS_KEY);
        if (localData) {
          const parser = JSON.parse(localData);

          if (parser?.[this.storageTableKey]) {
            this.setTableColumns(parser[this.storageTableKey]);
            return;
          }
        }

        const { data } = await tableService.getColumns();

        if (data.data?.[this.storageTableKey]) {
          this.setTableColumns(data.data[this.storageTableKey]);
        }
      } catch {
        this.tableColumns = this.columns;
      } finally {
        this.loadingColumns = false;
      }
    },
  },
  async mounted() {
    this.loadColumnsFromAPI();
    this.checkUrlSearchParams();
  },
};
</script>

<style lang="scss" scoped>
@keyframes spinner {
  to {
    transform: rotate(-360deg);
  }
}

.refreshing {
  animation: spinner 0.8s infinite forwards;
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
</style>
