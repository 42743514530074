<template>
  <div class="">
    <NbButton
      class="relative"
      variant="quaternary"
      data-toggle="modal"
      :data-target="`#modal-${id}`"
    >
      <NbIcon
        class="mr-1"
        icon="list"
        :attributes="{
          width: '1.2rem',
          height: '1.2rem',
        }"
      />
      {{ $t("components.selectTableColumns.button") }}
    </NbButton>
    <!-- modals -->
    <NbModal
      :id="`modal-${id}`"
      modalConfig="modal-dialog-centered"
      :width="size"
    >
      <template v-slot:header>
        <NbPageTitle
          :title="$t('components.nbTable.tableOptions')"
          :subTitle="$t('components.nbTable.changeVisibilityReorderColumns')"
        />
      </template>
      <template>
        <div class="mb-4 d-flex justify-content-between">
          <div class="show-columns w-49">
            <div class="heading-3 mb-2">
              {{ $t("components.selectTableColumns.shownColumns") }}
              <NbButton variant="tertiary" @click="selectAll()">
                {{ $t("components.selectTableColumns.selectAll") }}
              </NbButton>
            </div>
            <draggable
              v-model="contentShowFileds"
              group="fields"
              @start="isDragging = true"
              @end="isDragging = false"
            >
              <div
                v-for="(field, index) in shownFields"
                :key="index"
                :class="`flex-grow-1 ${cols ? cols : 'w-100'}`"
              >
                <NbSelectTableButtonFields
                  :label="field.label"
                  :is-dragging="isDragging"
                  @toggle="toggle(field, false)"
                />
              </div>
            </draggable>
          </div>
          <div class="idden-columns w-49">
            <div class="heading-3 mb-2">
              {{ $t("components.selectTableColumns.hiddenColumns") }}
              <NbButton variant="tertiary" @click="clearSelected()">
                {{ $t("components.selectTableColumns.unSelectAll") }}
              </NbButton>
            </div>
            <div
              v-for="(field, index) in hiddenFields"
              :key="index"
              :class="`flex-grow-1 ${cols ? cols : 'w-100'}`"
            >
              <NbSelectTableButtonFields
                :label="field.label"
                postpendClass="text-gray-40"
                postpendIcon="eye-off"
                @toggle="toggle(field, true)"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal" aria-label="Close">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton @click="saveFieldList">
            {{ $t("confirm") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle";
import draggable from "vuedraggable";
import NbSelectTableButtonFields from "@/components/tables/components/NbSelectTableButtonFields.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

import TablesService from "@/services/TablesService.js";
const tablesService = new TablesService();

export default {
  name: "NbSelectTableColsModal",
  components: {
    NbModal,
    NbPageTitle,
    draggable,
    NbButton,
    NbSelectTableButtonFields,
    NbIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    allFields: {
      type: Array,
      required: true,
    },
    pageName: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "750px",
    },
    cols: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isDragging: false,
      columns: {},
      contentShowFileds: [],
      contentHiddeFileds: [],
      contentNotCustomizableFields: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    toggle(field, val) {
      field.show = val;

      if (val) {
        const hiddenIndex = this.hiddenFields.findIndex(
          (hiddenField) => hiddenField.key === field.key,
        );
        if (hiddenIndex !== -1) {
          this.hiddenFields.splice(hiddenIndex, 1);
        }

        this.shownFields.push(field);
        return;
      }

      const showIndex = this.shownFields.findIndex(
        (showField) => showField.key === field.key,
      );
      if (showIndex !== -1) {
        this.shownFields.splice(showIndex, 1);
      }
      this.hiddenFields.push(field);
    },

    getDataFirstTime() {
      //recupera/check back-end
      tablesService
        .getColumns()
        .then((response) => {
          if (
            response.data.data &&
            response.data.data[`columns${this.pageName}`]
          ) {
            this.columns = response.data.data[
              `columns${this.pageName}`
            ]?.filter((item) => !!item.key && !!item.label);

            this.contentShowFileds = this.columns.filter(
              (column) => column.show,
            );

            this.contentNotCustomizableFields = this.allFields.filter(
              (field) => field.isCustomizable === false,
            );

            this.contentHiddeFileds = this.allFields.filter(
              (field) =>
                !this.contentShowFileds.some(
                  (showField) => showField.key === field.key,
                ) &&
                !this.contentNotCustomizableFields.some(
                  (invisibleField) => invisibleField.key === field.key,
                ),
            );

            this.emitFields();
            return;
          }

          this.loadStandardColumns();
        })
        .catch(() => {
          this.loadStandardColumns();
        });
    },
    loadStandardColumns() {
      this.contentShowFileds = [...this.allFields];

      this.contentShowFileds = this.contentShowFileds.filter(
        (showField) => showField.isCustomizable !== false,
      );

      this.contentShowFileds = this.contentShowFileds.map((showField) => {
        return { ...showField, show: true };
      });

      this.contentNotCustomizableFields = this.allFields.filter(
        (field) => field.isCustomizable === false,
      );
      //
      const dataToSend = this.buildDataToSend();

      let browserStorage = JSON.parse(
        localStorage.getItem(`columns_configuration`),
      );
      browserStorage[`columns${this.pageName}`] = dataToSend;

      //save on localstorage
      localStorage.setItem(
        `columns_configuration`,
        JSON.stringify(browserStorage),
      );
      this.emitFields();
    },
    getData() {
      //check first login without any localStorage
      let browserStorage = JSON.parse(
        localStorage.getItem(`columns_configuration`),
      );

      if (!browserStorage) {
        localStorage.setItem(`columns_configuration`, JSON.stringify({}));
        this.getDataFirstTime();
        return;
      }

      //check if have configuration for this page
      if (browserStorage.hasOwnProperty(`columns${this.pageName}`)) {
        this.columns = browserStorage[`columns${this.pageName}`]?.filter(
          (item) => !!item.key && !!item.label,
        );

        this.contentShowFileds = this.columns.filter((column) => column.show);

        this.contentNotCustomizableFields = this.allFields.filter(
          (field) => field.isCustomizable === false,
        );

        this.contentHiddeFileds = this.allFields.filter(
          (field) =>
            !this.contentShowFileds.some(
              (showField) => showField.key === field.key,
            ) &&
            !this.contentNotCustomizableFields.some(
              (invisibleField) => invisibleField.key === field.key,
            ),
        );

        this.emitFields();
        return;
      }

      this.getDataFirstTime();
    },
    buildDataToSend() {
      const dataToSend = [
        ...this.contentShowFileds,
        ...this.contentNotCustomizableFields,
      ];

      return dataToSend;
    },
    emitFields() {
      let dataToSend = this.buildDataToSend();

      this.$emit("selectedFields", dataToSend);
    },
    saveFieldList() {
      this.emitFields();
      const dataToSend = this.buildDataToSend();

      let browserStorage = JSON.parse(
        localStorage.getItem(`columns_configuration`),
      );
      browserStorage[`columns${this.pageName}`] = dataToSend;

      //save on back-end JSON.stringify(obj);
      tablesService.setColumns(JSON.stringify(browserStorage));

      //save on localstorage
      localStorage.setItem(
        `columns_configuration`,
        JSON.stringify(browserStorage),
      );
      //close modal
      this.$helpers.closeModal(`modal-${this.id}`);
    },
    selectAll() {
      this.contentHiddeFileds.forEach((field) => {
        field.show = true;
      });
      this.contentShowFileds = [
        ...this.contentShowFileds,
        ...this.contentHiddeFileds,
      ];
      this.contentHiddeFileds = [];
    },
    clearSelected() {
      this.contentShowFileds.forEach((field) => {
        field.show = false;
      });
      this.contentHiddeFileds = [
        ...this.contentHiddeFileds,
        ...this.contentShowFileds,
      ];
      this.contentShowFileds = [];
    },
    setContentGenericFields(variable, val) {
      this[variable] = val;
    },
    updateLabels(labels) {
      this.contentShowFileds.forEach((showField) => {
        showField.label = labels.find(
          (label) => label.key === showField.key,
        ).label;
      });

      this.contentHiddeFileds.forEach((hiddeField) => {
        hiddeField.label = labels.find(
          (label) => label.key === hiddeField.key,
        ).label;
      });

      this.contentNotCustomizableFields.forEach((notCustField) => {
        notCustField.label = labels.find(
          (label) => label.key === notCustField.key,
        ).label;
      });

      this.emitFields();
    },
  },
  computed: {
    shownFields: {
      get() {
        return this.contentShowFileds;
      },
      set(val) {
        this.contentShowFileds = val;
      },
    },
    hiddenFields: {
      get() {
        return this.contentHiddeFileds;
      },
      set(val) {
        this.contentHiddeFileds = val;
      },
    },
  },
  watch: {
    allFields(newVal) {
      const labels = newVal.map((field) => {
        return { key: field.key, label: field.label };
      });
      this.updateLabels(labels);
    },
  },
};
</script>

<style scoped></style>
