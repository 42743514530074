<template>
  <Timeline>
    <template #events>
      <TimelineEvent
        v-for="event in timeline"
        :title="event.event"
        :key="event.event"
      />
    </template>
    <template #breakpoints>
      <TimelineBreakpoint
        v-for="event in timeline"
        :key="event.event"
        :circle-size="event?.isStopOver ? 'sm' : 'lg'"
      >
        <template #circle><Plane v-if="!event?.isStopOver" /></template>

        <TimelineContent>
          <strong class="heading-2 mt-2">
            {{ event.airport }}
          </strong>
          <div v-if="!event.isStopOver" class="d-flex">
            <small class="d-flex align-items-center mr-2">
              <NbIcon class="mr-1" icon="calendar" />
              {{ event.date | dateFormat }}
            </small>
            <small class="d-flex align-items-center">
              <NbIcon class="mr-1" icon="clock" />
              <time>{{ event.time | timeFormat }}</time>
            </small>
          </div>
          <small v-else-if="!event.airport">
            {{ $t("flightsPage.noStopOver") }}
          </small>
        </TimelineContent>
      </TimelineBreakpoint>
    </template>
  </Timeline>
</template>

<script>
import Timeline from "@/components/timeline/Timeline.vue";
import TimelineBreakpoint from "@/components/timeline/TimelineBreakpoint.vue";
import TimelineContent from "@/components/timeline/TimelineContent.vue";
import TimelineEvent from "@/components/timeline/TimelineEvent.vue";
import Plane from "@/components/Plane.vue";
import moment from "moment";
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  props: {
    flight: {
      type: Object,
      required: true,
    },
  },
  components: {
    Timeline,
    TimelineBreakpoint,
    TimelineContent,
    TimelineEvent,
    Plane,
    NbIcon,
  },
  filters: {
    dateFormat(value) {
      return value ? moment(value).format("DD/MM/YYYY") : "";
    },
    timeFormat(value) {
      return value?.replace(":", "h");
    },
  },
  computed: {
    timeline() {
      return [
        {
          event: this.$t("flightsPage.departure"),
          airport: this.flight.departure_airport,
          date: this.flight.departure_date,
          time: this.flight.departure_time,
        },
        {
          event: this.$t("flightsPage.stopover"),
          airport: this.flight.stopover_airport,
          isStopOver: true,
        },
        {
          event: this.$t("flightsPage.arrival"),
          airport: this.flight.arrival_airport,
          date: this.flight.arrival_date,
          time: this.flight.arrival_time,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
