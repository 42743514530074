<template>
  <div>
    <header-title
      :title="title"
      :badges="isOldFlow"
      :logo-url="contract.logo_url"
    />
    <!-- {{ $route.params.contract_params.contract }} -->
    <div class="row justify-content-center">
      <NbButton
        class="float-right mr-3"
        @click="downloadContractSettings()"
        :disabled="contractSettingsDownloading"
      >
        {{ $t("contractsViewPage.downloadContractSettings") }}
      </NbButton>
      <div class="col-12 mb-5">
        <div class="card border-white rounded py-4">
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('contractsViewPage.name')"
              :val="contract.name"
            />
            <field-view
              :field="$t('contractsViewPage.ID')"
              :val="contract.id"
            />
          </div>

          <div class="row mx-5 my-2">
            <field-view
              :field="$t('contractsViewPage.slug')"
              :val="contract.slug"
            />
            <field-view
              :field="$t('contractsViewPage.created')"
              :val="contract.created"
            />
          </div>

          <div class="row mx-5 my-2">
            <field-view
              :field="$t('contractsViewPage.firstMileDeliveryMethod')"
              :val="first_mile_delivery_method_name || '...'"
              :link="`/contracts/delivery_methods?deliveryMethodId=`"
              :linkId="contract.first_mile_delivery_method_id"
            />
            <field-view
              :field="$t('contractsViewPage.freightForwarderDeliveryMethod')"
              :val="freight_forwarder_delivery_method_name"
              :link="`/contracts/delivery_methods?deliveryMethodId=`"
              :linkId="contract.freight_forwarder_delivery_method_id"
            />
          </div>

          <div class="row mx-5 my-2">
            <field-view
              :field="$t('contractsViewPage.customClearenceDeliveryMethod')"
              :val="custom_clearence_delivery_method_name"
              :link="`/contracts/delivery_methods?deliveryMethodId=`"
              :linkId="contract.custom_clearence_delivery_method_id"
            />
            <field-view
              :field="$t('contractsViewPage.lastMileDeliveryMethod')"
              :val="last_mile_delivery_method_name"
              :link="`/contracts/delivery_methods?deliveryMethodId=`"
              :linkId="contract.last_mile_delivery_method_id"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('contractsViewPage.isOldFlow')"
              :val="contract.is_old_flow || 'false'"
            />
            <field-view
              :field="$t('contractsViewPage.priceTable')"
              :actions="priceActions"
              @action="action($event)"
              redirectByName="contracts_prices_table"
              namespace="price_tables"
              :startFilter="{ name: price_table.name }"
              :val="price_table.name"
            />
            <field-view
              :field="$t('contractsViewPage.obsolete')"
              :val="contract.is_obsolete"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('contractsViewPage.taxingType')"
              :val="contract.taxing_type"
            />
            <field-view
              :field="$t('contractsViewPage.incoterm')"
              :val="contract.incoterm"
            />
            <field-view
              :field="$t('contractsViewPage.automatizedpayment')"
              :val="contract.automatic_payment || 'false'"
            />
            <field-view
              :field="$t('contractsViewPage.reverseDeliveryMethod')"
              :val="reverse_delivery_method_name || '...'"
              :link="`/contracts/delivery_methods?deliveryMethodId=`"
              :linkId="contract.reverse_delivery_method_id"
            />
          </div>

          <div class="row mx-5 my-2">
            <field-view
              :field="$t('beginningDate')"
              :val="contract.beginning_date"
            />
            <field-view :field="$t('endDate')" :val="contract.end_date" />
            <field-view
              :field="$t('contractsViewPage.deliveryTime')"
              :actions="deliveryActions"
              @action="action($event)"
              redirectByName="contracts_prices_table"
              namespace="delivery_times"
              :startFilter="{ name: delivery_time.name }"
              :val="delivery_time.name"
            />
            <field-view
              :field="$t('contractsViewPage.airporttoDoor')"
              :val="contract.airport_to_door"
            />
          </div>

          <div class="row mx-5 my-2">
            <field-view
              :field="$t('contractsViewPage.maximumWeight')"
              :val="contract.max_weight + ' kg'"
            />
            <field-view
              :field="$t('contractsViewPage.cubageFactor')"
              :val="contract.cubage_factor"
            />
            <field-view
              :field="$t('contractsViewPage.maximumPrice')"
              :val="contract.price_limit"
            />
          </div>

          <div class="row mx-5 my-2">
            <field-view
              :field="$t('contractsViewPage.maximumLength')"
              :val="standardizeValue(contract.max_length) + ' cm'"
            />
            <field-view
              :field="$t('contractsViewPage.maximumHeight')"
              :val="standardizeValue(contract.max_height) + ' cm'"
            />
            <field-view
              :field="$t('contractsViewPage.maximumWidth')"
              :val="standardizeValue(contract.max_width) + ' cm'"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('contractsViewPage.generatePaymentLink')"
              :val="contract.generate_payment_link || 'false'"
            />
            <!-- <field-view

                        /> -->
          </div>
          <fieldset class="mx-5 my-2 border p-2" :style="'solid #1f497d;'">
            <legend style="font-size: 12px; margin-bottom: 0px">
              {{ $t("contractsViewPage.additionalServices") }}
            </legend>
            <div
              v-for="additionalService in contract.additional_services"
              :key="additionalService.id"
            >
              <div class="row mx-5 my-2">
                <field-view
                  :field="$t('contractsViewPage.name')"
                  :val="additionalService.name"
                />
                <field-view
                  :field="$t('contractsViewPage.externalID')"
                  :val="additionalService.external_id"
                />
                <field-view
                  :field="$t('contractsViewPage.triggerOption')"
                  :val="additionalService.trigger_option"
                />
                <field-view
                  :field="$t('contractsViewPage.triggerCondition')"
                  :val="showCondictions(additionalService.trigger_condition)"
                />
              </div>
              <div class="row mx-5 my-2">
                <field-view
                  :field="$t('contractsViewPage.primaryVariable')"
                  :val="additionalService.primary_variable"
                />
                <div v-if="additionalService.primary_type === 'flat'">
                  <field-view
                    :field="$t('contractsViewPage.primaryValue')"
                    :val="
                      '$' + standardizeValue(additionalService.primary_value)
                    "
                  />
                </div>
                <div v-else>
                  <field-view
                    :field="$t('contractsViewPage.primaryValue')"
                    :val="
                      standardizeValue(additionalService.primary_value) + '%'
                    "
                  />
                </div>
                <field-view
                  :field="$t('contractsViewPage.secondaryVariable')"
                  :val="additionalService.secondary_variable"
                />
                <div v-if="additionalService.secondary_type === 'flat'">
                  <field-view
                    :field="$t('contractsViewPage.secondaryValue')"
                    :val="
                      '$' + standardizeValue(additionalService.secondary_value)
                    "
                  />
                </div>

                <div v-else>
                  <field-view
                    :field="$t('contractsViewPage.secondaryValue')"
                    :val="
                      standardizeValue(additionalService.secondary_value) + '%'
                    "
                  />
                </div>
              </div>
              <div v-if="true" class="col-12 mt-2 mb-3">
                {{ $t("contractsViewPage.hSCodes") }}:
                <span
                  class="mx-1"
                  v-for="code in additionalService.hs_codes"
                  :key="code.id"
                >
                  <span class="badge badge-success"> {{ code }} </span>
                </span>
              </div>
            </div>
          </fieldset>
          <fieldset class="mx-5 my-2 border p-2" :style="'solid #1f497d;'">
            <legend style="font-size: 12px; margin-bottom: 0px">
              {{ $t("contractsPage.destinationCountry") }}
            </legend>
            <span
              v-if="
                contract.destination_country &&
                contract.destination_country.length === 1 &&
                contract.destination_country[0] === 'all'
              "
            >
              Any country
            </span>
            <span v-else>
              {{ formatCountryList(contract.destination_country) }}
            </span>
          </fieldset>
          <fieldset class="mx-5 my-2 border p-2" :style="'solid #1f497d;'">
            <legend style="font-size: 12px; margin-bottom: 0px">
              {{ $t("contractsPage.originCountry") }}
            </legend>
            <span
              v-if="
                contract.origin_country &&
                contract.origin_country.length === 1 &&
                contract.origin_country[0] === 'all'
              "
            >
              Any country
            </span>
            <span v-else>
              {{ formatCountryList(contract.origin_country) }}
            </span>
          </fieldset>
          <div class="row mx-5 mt-3 d-flex justify-content-center">
            <a
              v-b-modal.modal-edit-contract
              class="btn btn-success mt-2 text-white"
              @click="copyContract(true)"
              title="Edit Contract"
            >
              {{ $t("contractsViewPage.editContract") }}
            </a>

            <a
              v-b-modal.modal-clone-contract
              class="btn btn-success mt-2 text-white"
              title="Edit Contract"
              @click="copyContract(false)"
              style="margin-left: 20px"
            >
              {{ $t("contractsViewPage.cloneContract") }}
            </a>
          </div>
        </div>
      </div>
      <!-- modals -->

      <b-modal
        size="lg"
        id="modal-clone-contract"
        ok-title="Clone Contract"
        @ok="cloneContract(newContract.slug)"
        title="Clone Contract"
      >
        <div class="form-group">
          <label for="name">{{
            $t("contractsViewPage.slugForNewContract")
          }}</label>
          <input
            v-model="newContract.slug"
            type="text"
            :class="{ 'is-invalid': errors['slug'] }"
            class="form-control"
            id="slug"
            aria-describedby="slugHelp"
            placeholder="slug .."
          />
          <div
            class="invalid-feedback"
            v-for="(error, index) in errors['slug']"
            :key="index"
          >
            {{ error }}
          </div>
        </div>
      </b-modal>
      <b-modal
        size="lg"
        id="modal-edit-contract"
        :ok-title="this.actionIsEdit ? 'Update Contract' : 'Clone Contract'"
        @ok="saveContract"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="settingsTitle">
              {{ actionIsEdit ? "Edit" : "Clone" }} {{ contract.name }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="name">{{ $t("contractsViewPage.name") }}</label>
                  <input
                    v-model="newContract.name"
                    type="text"
                    :class="{ 'is-invalid': errors['name'] }"
                    class="form-control"
                    id="name"
                    aria-describedby="nameHelp"
                    placeholder="Name .."
                  />
                  <div
                    class="invalid-feedback"
                    v-for="(error, index) in errors['name']"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="slug">{{ $t("contractsViewPage.slug") }}</label>
                  <input
                    v-model="newContract.slug"
                    type="text"
                    :class="{ 'is-invalid': errors['slug'] }"
                    class="form-control"
                    id="slug"
                    aria-describedby="slugHelp"
                    placeholder="slug .."
                  />
                  <div
                    class="invalid-feedback"
                    v-for="(error, index) in errors['slug']"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <filter-select
                  :label="$t('contractsViewPage.firstMileDM')"
                  @emitValue="
                    newContract.first_mile_delivery_method_id = $event
                  "
                  :items="deliveryMethods"
                  placehol="Select First Mile DM .."
                  errorTarget="first_mile_delivery_method"
                  itemValue="id"
                  itemText="name"
                  :shows="['id', 'name']"
                  :errors="errors"
                  :valEdit="newContract.first_mile_delivery_method_id"
                />
              </div>
              <div class="col">
                <filter-select
                  :label="$t('contractsViewPage.freightForwarderDM')"
                  @emitValue="
                    newContract.freight_forwarder_delivery_method_id = $event
                  "
                  :items="deliveryMethods"
                  placehol="Select Freight Forwarder DM .."
                  errorTarget="freight_forwarder_delivery_method"
                  itemValue="id"
                  itemText="name"
                  :shows="['id', 'name']"
                  :errors="errors"
                  :valEdit="newContract.freight_forwarder_delivery_method_id"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <filter-select
                  :label="$t('contractsViewPage.customClearenceDM')"
                  @emitValue="
                    newContract.custom_clearence_delivery_method_id = $event
                  "
                  :items="deliveryMethods"
                  placehol="Select Custom Clearence DM .."
                  errorTarget="custom_clearence_delivery_method"
                  itemValue="id"
                  itemText="name"
                  :shows="['id', 'name']"
                  :errors="errors"
                  :valEdit="newContract.custom_clearence_delivery_method_id"
                />
              </div>
              <div class="col">
                <filter-select
                  :label="$t('contractsViewPage.lastMileDM')"
                  @emitValue="newContract.last_mile_delivery_method_id = $event"
                  :items="deliveryMethods"
                  placehol="Select Last Mile DM .."
                  errorTarget="last_mile_delivery_method"
                  itemValue="id"
                  itemText="name"
                  :shows="['id', 'name']"
                  :errors="errors"
                  :valEdit="newContract.last_mile_delivery_method_id"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <filter-select
                  :label="$t('contractsViewPage.priceTable')"
                  @emitValue="newContract.price_table_id = $event"
                  :items="priceTables"
                  placehol="Select Price Table"
                  errorTarget="price_table_id"
                  itemValue="id"
                  itemText="name"
                  :shows="['id', 'name']"
                  :errors="errors"
                  :valEdit="newContract.price_table_id"
                />
              </div>
              <div class="col">
                <label for="beginning_date">{{
                  $t("contractsViewPage.isObsolete")
                }}</label>
                <b-form-select
                  v-model="newContract.is_obsolete"
                  @change="handleObsoleteChange"
                  :options="[true, false]"
                ></b-form-select>
                <div
                  class="invalid-feedback"
                  v-for="(error, index) in errors['beginning_date']"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>
              <div class="col">
                <filter-select
                  :label="$t('contractsViewPage.reverseDM')"
                  @emitValue="newContract.reverse_delivery_method_id = $event"
                  :items="deliveryMethods"
                  placehol="Select Reverse DM .."
                  errorTarget="reverse_delivery_method"
                  itemValue="id"
                  itemText="name"
                  :shows="['id', 'name']"
                  :errors="errors"
                  :valEdit="newContract.reverse_delivery_method_id"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="beginning_date">{{ $t("beginningDate") }}</label>
                  <input
                    v-model="newContract.beginning_date"
                    type="date"
                    :class="{ 'is-invalid': errors['beginning_date'] }"
                    class="form-control"
                    id="beginning_date"
                    aria-describedby="beginningDateHelp"
                    placeholder="Beginning date .."
                  />
                  <div
                    class="invalid-feedback"
                    v-for="(error, index) in errors['beginning_date']"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="end_date">{{ $t("endDate") }}</label>
                  <input
                    v-model="newContract.end_date"
                    type="date"
                    :class="{ 'is-invalid': errors['end_date'] }"
                    class="form-control"
                    id="end_date"
                    aria-describedby="endDateHelp"
                    placeholder="End date .."
                  />
                  <div
                    class="invalid-feedback"
                    v-for="(error, index) in errors['end_date']"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="incoterm">{{
                  $t("contractsViewPage.incoterm")
                }}</label>
                <b-form-select
                  class="form-control"
                  id="incoterm"
                  v-model="newContract.incoterm"
                  :options="incoterms"
                >
                </b-form-select>
              </div>
              <div class="col">
                <label for="taxType">{{
                  $t("contractsViewPage.taxingType")
                }}</label>
                <b-form-select
                  id="taxType"
                  class="form-control"
                  v-model="newContract.taxing_type"
                  :options="taxing_types"
                  autofocus
                >
                </b-form-select>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="maxWeight">{{
                  $t("contractsViewPage.maximumWeight")
                }}</label>
                <input
                  v-model="newContract.max_weight"
                  type="number"
                  :class="{ 'is-invalid': errors['max_weight'] }"
                  class="form-control"
                  id="maxWeight"
                />
                <div
                  class="invalid-feedback"
                  v-for="(error, index) in errors['max_weight']"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>
              <div class="col">
                <label for="cubageFactor">{{
                  $t("contractsViewPage.cubageFactor")
                }}</label>
                <input
                  v-model="newContract.cubage_factor"
                  type="number"
                  :class="{ 'is-invalid': errors['cubage_factor'] }"
                  class="form-control"
                  id="cubageFactor"
                />
                <div
                  class="invalid-feedback"
                  v-for="(error, index) in errors['cubage_factor']"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label for="maxLength">{{
                  $t("contractsViewPage.maximumLength")
                }}</label>
                <input
                  v-model="newContract.max_length"
                  type="number"
                  :class="{ 'is-invalid': errors['max_length'] }"
                  class="form-control"
                  id="maxLength"
                />
                <div
                  class="invalid-feedback"
                  v-for="(error, index) in errors['max_length']"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>
              <div class="col">
                <label for="maxHeight">{{
                  $t("contractsViewPage.maximumHeight")
                }}</label>
                <input
                  v-model="newContract.max_height"
                  type="number"
                  :class="{ 'is-invalid': errors['max_height'] }"
                  class="form-control"
                  id="maxHeight"
                />
                <div
                  class="invalid-feedback"
                  v-for="(error, index) in errors['max_height']"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label for="maxWidth">{{
                  $t("contractsViewPage.maximumWidth")
                }}</label>
                <input
                  v-model="newContract.max_width"
                  type="number"
                  :class="{ 'is-invalid': errors['max_width'] }"
                  class="form-control"
                  id="maxWidth"
                />
                <div
                  class="invalid-feedback"
                  v-for="(error, index) in errors['max_width']"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>

              <div class="col">
                <label for="price_limit">{{
                  $t("contractsViewPage.maximumPrice")
                }}</label>
                <input
                  v-model="newContract.price_limit"
                  type="number"
                  :class="{ 'is-invalid': errors['price_limit'] }"
                  class="form-control"
                  id="price_limit"
                />
                <div
                  class="invalid-feedback"
                  v-for="(error, index) in errors['price_limit']"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <div style="margin: 10px">
                    <img
                      v-if="chosenFile"
                      :src="chosenFileUrl"
                      height="38px"
                      width="38px"
                      class="icon"
                    />
                    <img
                      v-else-if="newContract.logo_url"
                      :src="newContract.logo_url"
                      height="38px"
                      width="38px"
                      class="icon"
                    />
                  </div>
                  <div v-if="chosenFile" for="logo">{{ chosenFile.name }}</div>
                  <div
                    class="custom-file d-flex flex-row justify-content-space-between align-items-center"
                  >
                    <input
                      type="file"
                      :class="{ 'is-invalid': errors['logo'] }"
                      @change="processFile"
                      class="custom-file-input"
                      id="logo"
                    />
                    <div
                      class="invalid-feedback"
                      v-for="(error, index) in errors['logo']"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                    <label class="btn btn-primary btn-sm ml-3 my-2" for="logo">
                      <span>Logo</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div>

            <div class="row">
              <div class="col">
                <div class="form-group">
                  <!-- destination -->
                  <fieldset
                    class="border px-2 mt-3"
                    style="border: 5px solid #1f497d; width: auto"
                  >
                    <legend
                      for="destination_country"
                      style="font-size: 12px; margin-bottom: 15px"
                    >
                      ({{ newContract.destination_country.length }})
                      {{ $t("contractsPage.destinationCountry") }}

                      <b-button
                        class="mx-1"
                        size="sm"
                        @click="selectAll('destination')"
                        :disabled="checkAllCountriesDestination"
                        >{{ $t("selectAll") }}</b-button
                      >
                      <b-button
                        class="mx-1"
                        size="sm"
                        @click="clearSelected('destination')"
                        >{{ $t("clearSelected") }}</b-button
                      >
                    </legend>

                    <!-- TODO: Componentisar esse trem -->
                    <div class="row">
                      <div class="col-6 mb-3">
                        <div class="form-group">
                          <label for="not-chosen-destination">
                            {{ $t("select") }}
                          </label>
                          <select
                            id="not-chosen-destination"
                            class="form-control"
                            style="height: 150px"
                            multiple
                            :disabled="checkAllCountriesDestination"
                            v-model="temp_chosen_countries_destination"
                          >
                            <option
                              v-for="(
                                country, index
                              ) in countries_options_destination"
                              :key="index"
                              :value="country"
                            >
                              {{ country.text }}
                            </option>
                          </select>
                        </div>
                        <button
                          class="float-right btn btn-secondary rounded-pill"
                          @click="
                            addfromselectionToChosenCountriesDestination()
                          "
                        >
                          <i class="fas fa-angle-double-right mt-1"></i>
                        </button>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label for="chosen">
                            {{ $t("selected") }}
                          </label>
                          <select
                            id="chosen"
                            class="form-control"
                            :class="{
                              'is-invalid': errors['destination_country'],
                            }"
                            style="height: 150px"
                            multiple
                            v-model="temp_countries_destination"
                          >
                            <option
                              v-for="(
                                country, index
                              ) in selected_destination_country"
                              :key="index"
                              :value="country"
                            >
                              {{ country.text }}
                            </option>
                          </select>
                        </div>
                        <button
                          class="btn btn-secondary rounded-pill"
                          @click="removeSelectionToCountriesDestination()"
                        >
                          <i class="fas fa-angle-double-left mt-1"></i>
                        </button>

                        <div
                          v-for="(error, index) in errors[
                            'destination_country'
                          ]"
                          class="invalid-feedback my-2"
                          :key="index"
                        >
                          {{ error }}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <!-- origin -->
                <fieldset
                  class="border px-2 mt-3"
                  style="border: 5px solid #1f497d; width: auto"
                >
                  <legend
                    for="origin_country"
                    style="font-size: 12px; margin-bottom: 15px"
                  >
                    ({{ newContract.origin_country.length }})
                    {{ $t("contractsPage.originCountry") }}

                    <b-button
                      class="mx-1"
                      size="sm"
                      @click="selectAll('origin')"
                      :disabled="checkAllCountriesOrigin"
                      >{{ $t("selectAll") }}</b-button
                    >
                    <b-button
                      class="mx-1"
                      size="sm"
                      @click="clearSelected('origin')"
                      >{{ $t("clearSelected") }}</b-button
                    >
                  </legend>

                  <!-- TODO: Componentisar esse trem -->
                  <div class="row">
                    <div class="col-6 mb-3">
                      <div class="form-group">
                        <label for="not-chosen-origin">
                          {{ $t("select") }}
                        </label>
                        <select
                          style="height: 150px"
                          multiple
                          class="form-control"
                          id="not-chosen-origin"
                          :disabled="checkAllCountriesOrigin"
                          v-model="temp_chosen_countries_origin"
                        >
                          <option
                            v-for="(country, index) in countries_options_origin"
                            :key="index"
                            :value="country"
                          >
                            {{ country.text }}
                          </option>
                        </select>
                      </div>
                      <button
                        class="float-right btn btn-secondary rounded-pill"
                        @click="addfromselectionToChosenCountriesOrigin()"
                      >
                        <i class="fas fa-angle-double-right mt-1"></i>
                      </button>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="chosen">
                          {{ $t("selected") }}
                        </label>
                        <select
                          id="chosen"
                          style="height: 150px"
                          multiple
                          class="form-control"
                          :class="{ 'is-invalid': errors['origin_country'] }"
                          v-model="temp_countries_origin"
                        >
                          <option
                            v-for="(country, index) in selected_origin_country"
                            :key="index"
                            :value="country"
                          >
                            {{ country.text }}
                          </option>
                        </select>
                      </div>
                      <button
                        class="btn btn-secondary rounded-pill"
                        @click="removeSelectionToCountriesOrigin()"
                      >
                        <i class="fas fa-angle-double-left mt-1"></i>
                      </button>

                      <div
                        v-for="(error, index) in errors['origin_country']"
                        class="invalid-feedback my-2"
                        :key="index"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <fieldset class="border p-2" style="border: 5px solid #1f497d">
                  <legend
                    for="additionalServices"
                    style="font-size: 12px; margin-bottom: 0px"
                  >
                    {{ $t("contractsViewPage.additionalServices") }}
                  </legend>
                  <!-- STANDARD SERVICES -->
                  <div
                    v-for="(oldService, index) in loadedAdditionalServices"
                    :key="oldService"
                  >
                    <div class="row mb-2">
                      <div class="col-4">
                        <label :for="oldService.name + index"
                          >{{ $t("contractsViewPage.name") }}:</label
                        >
                        <input
                          v-model="oldService.name"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': errors['additional_services.name'],
                          }"
                          disabled
                          :id="oldService.name + index"
                        />
                        <div
                          v-for="(error, index) in errors[
                            'additional_services.name'
                          ]"
                          :key="index"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </div>
                      </div>
                      <div class="col-2">
                        <label :for="oldService.external_id + index"
                          >{{ $t("contractsViewPage.externalID") }}:</label
                        >
                        <input
                          v-model="oldService.external_id"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              errors['additional_services.external_id'],
                          }"
                          disabled
                          :id="oldService.external_id + index"
                        />
                        <div
                          v-for="(error, index) in errors[
                            'additional_services.external_id'
                          ]"
                          :key="index"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </div>
                      </div>
                      <div class="col-6">
                        <label :for="oldService.hs_codes + index"
                          >{{ $t("contractsViewPage.hSCodes") }}:</label
                        >
                        <input
                          v-model="oldService.hs_codes"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              errors['additional_services.hs_codes'],
                          }"
                          v-b-popover.focus.top="
                            'Separate each code with commas'
                          "
                          aria-describedby="hsCodeServiceHelp"
                          :id="oldService.hs_codes + index"
                        />
                        <div
                          v-for="(error, index) in errors[
                            'additional_services.hs_codes'
                          ]"
                          :key="index"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4">
                        <label :for="oldService.trigger_option + index"
                          >{{ $t("contractsViewPage.triggerOption") }} :</label
                        >
                        <b-form-select
                          :options="trigger_options"
                          autofocus
                          v-model="oldService.trigger_option"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              errors['additional_services.trigger_option'],
                          }"
                          :id="oldService.trigger_option + index"
                        >
                        </b-form-select>
                        <div
                          v-for="(error, index) in errors[
                            'additional_services.trigger_option'
                          ]"
                          :key="index"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </div>
                      </div>
                      <div v-if="oldService.trigger_option === 0" class="col-8">
                        <NbSelecMultipletInput
                          id="triggerCondition-oldService"
                          v-model="oldService.trigger_condition"
                          class="mt-1"
                          :name="$t('contractsViewPage.triggerCondition')"
                          :options="sorted_nobordist_checkpoints"
                          placeholder="Select Checkpoint"
                          :error="
                            errors['additional_services.trigger_condition']
                          "
                        />
                      </div>
                      <div v-else class="col-8">
                        <label :for="oldService.trigger_condition + index"
                          >{{
                            $t("contractsViewPage.triggerCondition")
                          }}:</label
                        >
                        <input
                          v-model="oldService.trigger_condition"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              errors['additional_services.trigger_condition'],
                          }"
                          :id="oldService.trigger_condition + index"
                        />
                        <div
                          v-for="(error, index) in errors[
                            'additional_services.trigger_condition'
                          ]"
                          :key="index"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-4">
                        <label :for="oldService.primary_variable + index"
                          >{{ $t("contractsViewPage.primaryVariable") }}:</label
                        >
                        <b-form-select
                          :options="variables"
                          autofocus
                          v-model="oldService.primary_variable"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              errors['additional_services.primary_variable'],
                          }"
                          :id="oldService.primary_variable + index"
                        >
                        </b-form-select>
                        <div
                          v-for="(error, index) in errors[
                            'additional_services.primary_variable'
                          ]"
                          :key="index"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </div>
                      </div>
                      <div class="col-4">
                        <label :for="oldService.primary_type + index"
                          >{{ $t("contractsViewPage.primaryType") }}:</label
                        >
                        <b-form-select
                          :options="variable_types"
                          autofocus
                          v-model="oldService.primary_type"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              errors['additional_services.primary_type'],
                          }"
                          :id="oldService.primary_type + index"
                        >
                        </b-form-select>
                        <div
                          v-for="(error, index) in errors[
                            'additional_services.primary_type'
                          ]"
                          :key="index"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </div>
                      </div>
                      <div class="col-4">
                        <label :for="oldService.primary_value + index"
                          >{{ $t("contractsViewPage.primaryValue") }}:</label
                        >
                        <input
                          v-model="oldService.primary_value"
                          type="number"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              errors['additional_services.primary_value'],
                          }"
                          :id="oldService.primary_value + index"
                        />
                        <div
                          v-for="(error, index) in errors[
                            'additional_services.primary_value'
                          ]"
                          :key="index"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-4">
                        <label :for="oldService.secondary_variable + index"
                          >{{
                            $t("contractsViewPage.secondaryVariable")
                          }}:</label
                        >
                        <b-form-select
                          :options="variables"
                          autofocus
                          v-model="oldService.secondary_variable"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              errors['additional_services.secondary_variable'],
                          }"
                          :id="oldService.secondary_variable + index"
                        >
                        </b-form-select>
                        <div
                          v-for="(error, index) in errors[
                            'additional_services.secondary_variable'
                          ]"
                          :key="index"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </div>
                      </div>
                      <div class="col-4">
                        <label :for="oldService.secondary_type + index"
                          >{{ $t("contractsViewPage.secondaryType") }}:</label
                        >
                        <b-form-select
                          :options="variable_types"
                          autofocus
                          v-model="oldService.secondary_type"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              errors['additional_services.secondary_type'],
                          }"
                          :id="oldService.secondary_type + index"
                        >
                        </b-form-select>
                        <div
                          v-for="(error, index) in errors[
                            'additional_services.secondary_type'
                          ]"
                          :key="index"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </div>
                      </div>
                      <div class="col-4">
                        <label :for="oldService.secondary_value + index"
                          >{{ $t("contractsViewPage.secondaryValue") }}:</label
                        >
                        <input
                          v-model="oldService.secondary_value"
                          type="number"
                          class="form-control"
                          :class="{
                            'is-invalid':
                              errors['additional_services.secondary_value'],
                          }"
                          :id="oldService.secondary_value + index"
                        />
                        <div
                          v-for="(error, index) in errors[
                            'additional_services.secondary_value'
                          ]"
                          :key="index"
                          class="invalid-feedback"
                        >
                          {{ error }}
                        </div>
                      </div>
                    </div>
                    <button
                      @click="removeServiceFromAttributes(index)"
                      class="btn btn-danger btn-sm ml-3 my-2"
                    >
                      {{ $t("contractsViewPage.removeService") }}
                    </button>
                  </div>
                  <!-- END STANDARD -->
                  <!-- CUSTOM SERVICES -->
                  <div v-if="showNewServiceCreate">
                    <hr />
                    <div class="row">
                      <div class="col-4">
                        <label for="newAdditionalServiceName"
                          >{{ $t("contractsViewPage.name") }}:</label
                        >
                        <select
                          class="form-control"
                          id="additionalCostSelect"
                          v-model="newAdditionalService.name"
                        >
                          <option :value="null">
                            {{ $t("contractsViewPage.selecttype") }}
                          </option>
                          <option
                            v-for="(
                              additionalServiceSlug, index
                            ) in additionalServiceSlugs"
                            :key="index"
                            :value="additionalServiceSlug.value.text"
                          >
                            {{
                              `(${additionalServiceSlug.value.value}) ${additionalServiceSlug.value.text}`
                            }}
                          </option>
                        </select>
                        <!-- <input
                                              v-model="newAdditionalService.name"
                                              type="text"
                                              class="form-control"
                                              id="newAdditionalServiceName"
                                              /> -->
                      </div>
                      <div class="col-8">
                        <label for="newAdditionalServiceHsCodes"
                          >{{ $t("contractsViewPage.hSCodes") }}:</label
                        >
                        <input
                          v-model="newAdditionalService.hs_codes"
                          type="text"
                          class="form-control"
                          v-b-popover.focus.top="
                            'Separate each code with commas'
                          "
                          aria-describedby="hsCodeServiceHelp"
                          id="newAdditionalServiceHsCodes"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <label for="newAdditionalServiceTriggerOption"
                          >{{ $t("contractsViewPage.triggerOption") }}:</label
                        >
                        <b-form-select
                          :options="trigger_options"
                          autofocus
                          v-model="newAdditionalService.trigger_option"
                          type="text"
                          class="form-control"
                          id="newAdditionalServiceTriggerOption"
                        >
                        </b-form-select>
                      </div>
                      <div
                        v-if="newAdditionalService.trigger_option === 0"
                        class="col-8"
                      >
                        <NbSelecMultipletInput
                          id="triggerCondition-oldService-clone"
                          v-model="newAdditionalService.trigger_condition"
                          class="mt-1"
                          :name="$t('contractsViewPage.triggerCondition')"
                          :options="sorted_nobordist_checkpoints"
                          placeholder="Select Checkpoint"
                          :error="
                            errors['additional_services.trigger_condition']
                          "
                        />
                      </div>
                      <div v-else class="col-8">
                        <label for="newAdditionalServiceTriggerCondition"
                          >{{
                            $t("contractsViewPage.triggerCondition")
                          }}:</label
                        >
                        <input
                          v-model="newAdditionalService.trigger_condition"
                          type="text"
                          class="form-control"
                          id="newAdditionalServiceTriggerCondition"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <label for="newAdditionalServicePrimaryVariable"
                          >{{ $t("contractsViewPage.primaryVariable") }}:</label
                        >
                        <b-form-select
                          :options="variables"
                          autofocus
                          v-model="newAdditionalService.primary_variable"
                          type="text"
                          class="form-control"
                          id="newAdditionalServicePrimaryVariable"
                        >
                        </b-form-select>
                      </div>
                      <div class="col-4">
                        <label for="newAdditionalServicePrimaryType"
                          >{{ $t("contractsViewPage.primaryType") }}:</label
                        >
                        <b-form-select
                          :options="variable_types"
                          autofocus
                          v-model="newAdditionalService.primary_type"
                          type="text"
                          class="form-control"
                          id="newAdditionalServicePrimaryType"
                        >
                        </b-form-select>
                      </div>
                      <div class="col-4">
                        <label for="newAdditionalServicePrimaryValue"
                          >{{ $t("contractsViewPage.primaryValue") }}:</label
                        >
                        <input
                          v-model="newAdditionalService.primary_value"
                          type="number"
                          class="form-control"
                          id="newAdditionalServicePrimaryValue"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <label for="newAdditionalServiceSecondaryVariable"
                          >{{
                            $t("contractsViewPage.secondaryVariable")
                          }}:</label
                        >
                        <b-form-select
                          :options="variables"
                          autofocus
                          v-model="newAdditionalService.secondary_variable"
                          type="text"
                          class="form-control"
                          id="newAdditionalServiceSecondaryVariable"
                        >
                        </b-form-select>
                      </div>
                      <div class="col-4">
                        <label for="newAdditionalServiceSecondaryType"
                          >{{ $t("contractsViewPage.secondaryType") }}:</label
                        >
                        <b-form-select
                          :options="variable_types"
                          autofocus
                          v-model="newAdditionalService.secondary_type"
                          type="text"
                          class="form-control"
                          id="newAdditionalServiceSecondaryType"
                        >
                        </b-form-select>
                      </div>
                      <div class="col-4">
                        <label for="newAdditionalServiceSecondaryValye"
                          >{{ $t("contractsViewPage.secondaryValue") }}:</label
                        >
                        <input
                          v-model="newAdditionalService.secondary_value"
                          type="number"
                          class="form-control"
                          id="newAdditionalServiceSecondaryValye"
                        />
                      </div>
                    </div>
                    <button
                      @click="addCustomService"
                      class="btn btn-success btn-sm ml-3 my-2"
                    >
                      {{ $t("contractsViewPage.addServices") }}
                    </button>
                  </div>
                  <button
                    v-if="showNewServiceCreate"
                    class="btn btn-light btn-sm ml-3 my-2 float-right"
                    @click="showNewServiceCreate = !showNewServiceCreate"
                  >
                    {{ $t("contractsViewPage.cancelNewService") }}
                  </button>
                  <button
                    v-if="!showNewServiceCreate"
                    class="btn btn-primary btn-sm ml-3 my-2"
                    @click="showNewServiceCreate = !showNewServiceCreate"
                  >
                    {{ $t("contractsViewPage.newService") }}
                  </button>

                  <!-- END CUSTOM SERVICES -->
                </fieldset>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <fieldset
                  class="border p-2 mt-4 mb-2"
                  style="border: 5px solid #1f497d"
                >
                  <legend
                    for="additionalServices"
                    style="font-size: 12px; margin-bottom: 0px"
                  >
                    {{ $t("contractsPage.botNotifications") }}
                  </legend>
                  <div>
                    <b-form-checkbox
                      v-model="newContract.generate_payment_link"
                      name="check-button"
                      switch
                    >
                      <b>Generate Payment Link</b>
                    </b-form-checkbox>
                  </div>
                  <div v-if="newContract.generate_payment_link">
                    <div
                      class="ml-3"
                      v-for="(value, key) in newContract.dutypay_notification"
                      :key="key"
                    >
                      <b-form-checkbox
                        v-model="newContract.dutypay_notification[key]"
                        name="check-button"
                        switch
                      >
                        <b>{{ transformString(key) }}</b>
                      </b-form-checkbox>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="form-group">
                  <div style="margin: 10px">
                    <img
                      v-if="chosenFile"
                      :src="chosenFileUrl"
                      height="38px"
                      width="38px"
                      class="icon"
                    />
                    <img
                      v-else-if="newContract.logo_url"
                      :src="newContract.logo_url"
                      height="38px"
                      width="38px"
                      class="icon"
                    />
                  </div>
                  <div v-if="chosenFile" for="logo">{{ chosenFile.name }}</div>
                  <div
                    class="custom-file d-flex flex-row justify-content-space-between align-items-center"
                  >
                    <input
                      type="file"
                      :class="{ 'is-invalid': errors['logo'] }"
                      @change="processFile"
                      class="custom-file-input"
                      id="logo"
                    />
                    <div
                      class="invalid-feedback"
                      v-for="(error, index) in errors['logo']"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                    <label class="btn btn-primary btn-sm ml-3 my-2" for="logo">
                      <span>Logo</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col"></div>
            </div>
            <div class="row">
              <div class="col mt-2 mr-4">
                <filter-select
                  :label="$t('contractsViewPage.deliveryTime')"
                  @emitValue="newContract.delivery_time_id = $event"
                  :items="deliveryTimes"
                  placehol="Select Delivery Time"
                  errorTarget="delivery_time_id"
                  itemValue="id"
                  itemText="name"
                  :shows="['id', 'name']"
                  :errors="errors"
                  :valEdit="newContract.delivery_time_id"
                />
              </div>
              <div class="col mt-2 mr-4">
                <h6 class="mr-3 ml-1">
                  {{ $t("contractsViewPage.automatizedPayment") }}?
                </h6>
                <div class="form-check form-check-inline mt-1">
                  <input
                    v-model="newContract.automatic_payment"
                    class="form-check-input"
                    type="checkbox"
                    :id="`automaticPaymentCheck`"
                  />
                  <label
                    class="form-check-label"
                    :for="`automaticPaymentCheck`"
                  >
                    {{
                      newContract.automatic_payment
                        ? "Automatized"
                        : "Not Automatized"
                    }}
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col mt-2 mr-4">
                <h6 class="mr-3 ml-1">
                  {{ $t("contractsViewPage.isOldFlow") }}?
                </h6>
                <div class="form-check form-check-inline mt-1">
                  <input
                    v-model="newContract.is_old_flow"
                    class="form-check-input"
                    type="checkbox"
                    :id="`oldFlowCheck`"
                  />
                  <label class="form-check-label" :for="`oldFlowCheck`">
                    {{ newContract.is_old_flow ? "O (Old)" : "N (New)" }}
                  </label>
                </div>
              </div>
              <div class="col mt-2 mr-4">
                <h6 class="mr-3 ml-1">
                  {{ $t("contractsViewPage.airportToDoor") }}?
                </h6>
                <div class="form-check form-check-inline mt-1">
                  <input
                    v-model="newContract.airport_to_door"
                    class="form-check-input"
                    type="checkbox"
                    :id="`oldFlowCheck`"
                  />
                  <label class="form-check-label" :for="`oldFlowCheck`">
                    {{ newContract.airport_to_door ? "YES" : "NO" }}
                  </label>
                </div>
              </div>
              <div
                v-if="admins !== null && admins.length > 0"
                :class="actionIsEdit ? '' : 'display-hidde'"
                class="col mr-4"
              >
                <h5 class="mr-3 ml-1">
                  {{ $t("contractsViewPage.needsSupervisorApproval") }}.
                </h5>
                <p>{{ $t("contractsViewPage.chooseWhoSendRequest") }}:</p>
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="selected_admins"
                    :aria-describedby="ariaDescribedby"
                    :options="admins"
                  >
                  </b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <template #modal-footer="{ ok, cancel }">
          <b-button
            variant="secondary"
            size="sm"
            class="float-right"
            @click="cancel"
          >
            {{ $t("close") }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            :disabled="disableSaveContract"
            @click="ok"
          >
            {{ actionIsEdit ? "Update Contract" : "Clone Contract" }}
          </b-button>
        </template>
      </b-modal>

      <b-modal
        id="warning-is-obsolete"
        title="Are you sure?"
        ok-title="Make this contract obsolete"
        @cancel="revertObsolete"
        ok-variant="danger"
      >
        <p class="text-danger">
          {{ $t("contractsViewPage.wantToMakeContractObsolete") }}?
        </p>

        {{ $t("contractsViewPage.notBePossibleVisualize") }}.
      </b-modal>
      <!-- end modal -->
    </div>
  </div>
</template>

<script>
import NProgress from "nprogress";
import HeaderTitle from "@/components/HeaderTitle";
import FieldView from "@/components/FieldView";
import FilterSelect from "@/components/FilterSelect";
import NbSelecMultipletInput from "@/components/input/select/NbSelecMultipletInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import ContractService from "../../../services/ContractService";
import DeliveryMethodsService from "../../../services/DeliveryMethodsService";
import PriceTablesService from "../../../services/PriceTablesService";
import DeliveryTimesService from "@/services/DeliveryTimesService";
import OrderCostService from "@/services/OrderCostService";
import PendingActionService from "../../../services/PendingActionService";
import { jsonToFile } from "../../../utils/json-to-file";

const contractService = new ContractService();
const deliveryMethodsService = new DeliveryMethodsService();
const priceTablesService = new PriceTablesService();
const deliveryTimesService = new DeliveryTimesService();
const orderCostService = new OrderCostService();
const pendingActionService = new PendingActionService();

export default {
  name: "ContractView",
  components: {
    "field-view": FieldView,
    "filter-select": FilterSelect,
    HeaderTitle,
    NbSelecMultipletInput,
    NbButton,
  },
  data: () => {
    return {
      newContractData: new FormData(),
      chosenFile: null,
      contractSettingsDownloading: false,
      newContract: {
        name: "",
        slug: "",
        freight_forwarder_delivery_method_id: "",
        custom_clearence_delivery_method_id: "",
        first_mile_delivery_method_id: "",
        last_mile_delivery_method_id: "",
        reverse_delivery_method_id: "",
        price_table_id: "",
        delivery_time_id: "",
        taxing_type: "",
        incoterm: "",
        destination_country: [],
        origin_country: [],
        automatic_payment: false,
        max_weight: 0.0,
        max_width: 0.0,
        max_height: 0.0,
        max_length: 0.0,
        cubage_factor: 0,
        additional_services_attributes: [],
        is_old_flow: false,
        airport_to_door: false,
        generate_payment_link: false,
        dutypay_notification: {
          sms: false,
          whatsapp: false,
          email_seller: false,
          email_customer: false,
        },
      },
      actionIsEdit: true,
      incoterms: [
        { value: "BOTH", text: "Both" },
        { value: "DDP", text: "DDP" },
        { value: "DDU", text: "DDU" },
      ],
      taxing_types: [
        { value: "postal", text: "POSTAL" },
        { value: "courier", text: "COURIER" },
      ],
      trigger_options: [
        { value: 0, text: "Checkpoint" },
        { value: 1, text: "Action" },
        { value: 2, text: "Option" },
      ],
      variables: [
        { value: 1, text: "Volume" },
        { value: 2, text: "Item" },
        { value: 3, text: "Days" },
        { value: 4, text: "Kilo" },
      ],
      variable_types: [
        { value: 0, text: "Flat" },
        { value: 1, text: "Percent" },
      ],
      contract: {},
      deliveryMethods: [],
      errors: [],
      first_mile_delivery_method_name: "",
      freight_forwarder_delivery_method_name: "",
      custom_clearence_delivery_method_name: "",
      last_mile_delivery_method_name: "",
      price_table: {},
      delivery_time: {},
      reverse_delivery_method_name: "",
      beginning_date: "",
      end_date: "",
      isEditable: true,
      admins: [],
      selected_admins: [],
      priceActions: [
        {
          actionName: "printPricePdf",
          icon: "fas fa-file-pdf",
          title: "Download in PDF",
        },
        {
          actionName: "printPriceCsv",
          icon: "fas fa-file-csv",
          title: "Download in CSV",
        },
      ],
      deliveryActions: [
        {
          actionName: "printDeliveryPdf",
          icon: "fas fa-file-pdf",
          title: "Download in PDF",
        },
        {
          actionName: "printDeliveryCsv",
          icon: "fas fa-file-csv",
          title: "Download in CSV",
        },
      ],
      showNewServiceCreate: false,
      newAdditionalService: {
        name: null,
      },
      loadedAdditionalServices: [],
      additionalServiceSlugs: [],
      showModalEdit: false,
      temp_chosen_countries_destination: [],
      temp_countries_destination: [],
      countries_options_destination: [],
      selected_destination_country: [],
      temp_chosen_countries_origin: [],
      temp_countries_origin: [],
      countries_options_origin: [],
      selected_origin_country: [],
      possibleDestinationCountries: [
        "Brazil",
        "Mexico",
        "Argentina",
        "Bolivia",
        "Belize",
        "Chile",
        "Colombia",
        "Costa Rica",
        "Cuba",
        "Dominican Republic",
        "Ecuador",
        "Guatemala",
        "Honduras",
        "Haiti",
        "Nicaragua",
        "Panama",
        "Peru",
        "Paraguay",
        "El Salvador",
        "Uruguay",
        "Venezuela, Bolivarian Republic of",
      ],
    };
  },
  beforeMount() {
    this.loadCurrentContract();
    this.reloadPriceTables();
    this.reloadDeliveryTimes();
    this.loadTariffsTypes();
    pendingActionService.fetchAdminUsers().then((response) => {
      this.admins = response.data.data.map((admin) => ({
        text: admin.name,
        value: admin.id,
      }));
    });
    this.setCountriesOptionsDestination(true);
    this.setCountriesOptionsOrigin(true);
  },
  methods: {
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    transformString(str) {
      if (str && typeof str == "string") {
        let words = str.split("_");
        for (let i = 0; i < words.length; i++) {
          words[i] =
            words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
        }
        return words.join(" ");
      }
    },
    async downloadContractSettings() {
      try {
        this.contractSettingsDownloading = true;
        const { data } = await contractService.exportContract(
          this.$route.params.id,
        );
        jsonToFile(data, `contract-${this.$route.params.id}-settings.json`);
      } finally {
        this.contractSettingsDownloading = false;
      }
    },
    processFile(event) {
      this.chosenFile = event.target.files[0];
      const fileType = this.chosenFile.type.replace(/.+\//, "");
      if (!["jpeg", "jpg", "png"].includes(fileType)) {
        this.errors["logo"] = ["only jpeg or png"];
      } else {
        this.newContractData.append(
          "logo",
          this.chosenFile,
          this.chosenFile.name,
        );
      }
    },
    formateDate(date) {
      const data = new Date(date);
      const hours = new Date(date);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    onlyDate(datetime) {
      if (!datetime) {
        return datetime;
      }
      const data = new Date(datetime);
      const date_time = this.addZero(
        data.getFullYear() +
          "-" +
          this.addZero(data.getMonth() + 1).toString() +
          "-" +
          data.getDate().toString(),
      );
      return date_time.split(" ")[0];
    },
    loadCurrentContract() {
      if (typeof this.$route.params.isEditable === "boolean") {
        this.isEditable = this.$route.params.isEditable;
      }
      contractService.getOneContract(this.$route.params.id).then((response) => {
        let contract = response.data.data;
        if (this.$route.params.contract_params) {
          // contract = Object.assign({}, this.$route.params.contract_params.contract, this.$route.params.contract_params.contracts_params)
          contract = this.$route.params.contract_params.contract;
        }
        this.contract = contract;
        let date = this.contract.created_at;
        this.contract.created = this.formateDate(date);
        this.contract.beginning_date = this.onlyDate(
          this.contract.beginning_date,
        );
        this.contract.end_date = this.onlyDate(this.contract.end_date);

        if (this.contract.first_mile_delivery_method_id) {
          deliveryMethodsService
            .getOneDeliveryMethod(this.contract.first_mile_delivery_method_id)
            .then((response) => {
              this.first_mile_delivery_method_name = response.data.data.name;
            });
        }
        if (this.contract.freight_forwarder_delivery_method_id) {
          deliveryMethodsService
            .getOneDeliveryMethod(
              this.contract.freight_forwarder_delivery_method_id,
            )
            .then((response) => {
              this.freight_forwarder_delivery_method_name =
                response.data.data.name;
            });
        }
        if (this.contract.custom_clearence_delivery_method_id) {
          deliveryMethodsService
            .getOneDeliveryMethod(
              this.contract.custom_clearence_delivery_method_id,
            )
            .then((response) => {
              this.custom_clearence_delivery_method_name =
                response.data.data.name;
            });
        }
        if (this.contract.last_mile_delivery_method_id) {
          deliveryMethodsService
            .getOneDeliveryMethod(this.contract.last_mile_delivery_method_id)
            .then((response) => {
              this.last_mile_delivery_method_name = response.data.data.name;
            });
        }
        if (this.contract.reverse_delivery_method_id) {
          deliveryMethodsService
            .getOneDeliveryMethod(this.contract.reverse_delivery_method_id)
            .then((response) => {
              this.reverse_delivery_method_name = response.data.data.name;
            });
        }
        if (this.contract.price_table_id) {
          priceTablesService
            .getOnePriceTable(this.contract.price_table_id)
            .then((response) => {
              this.price_table = response.data.data;
            });
        }
        if (this.contract.delivery_time_id) {
          deliveryTimesService
            .getOne(this.contract.delivery_time_id)
            .then((response) => {
              this.delivery_time = response.data.data;
            });
        }
      });

      deliveryMethodsService.getDmNotMadeBySeller().then((response) => {
        this.deliveryMethods = response.data.data.elements;
      });
    },
    cloneContract(newSlug) {
      this.newContract.slug = newSlug;
      this.$bvModal.show("modal-edit-contract");
      this.actionIsEdit = false;
    },
    copyContract(actionIsEditOrNot) {
      this.actionIsEdit = actionIsEditOrNot;
      this.errors = [];
      this.newContract = Object.assign(this.newContract, this.contract); // JSON.parse(JSON.stringify(this.contract));
      this.newContract.incoterm = this.incoterms.find(
        (incoterm) =>
          incoterm.text.toUpperCase() ===
          this.newContract.incoterm.toUpperCase(),
      ).value;
      this.newContract.taxing_type = this.taxing_types.find(
        (taxing_type) =>
          taxing_type.text.toUpperCase() ===
          this.newContract.taxing_type.toUpperCase(),
      ).value;

      //FILL => newContract.destination_country
      this.temp_chosen_countries_destination =
        this.countries_options_destination.filter((countri) =>
          this.newContract.destination_country.includes(countri.value),
        );
      this.addfromselectionToChosenCountriesDestination();
      //FILL => newContract.origin_country
      this.temp_chosen_countries_origin = this.countries_options_origin.filter(
        (countri) => this.newContract.origin_country.includes(countri.value),
      );
      this.addfromselectionToChosenCountriesOrigin();

      this.loadedAdditionalServices = this.newContract.additional_services;
      this.loadedAdditionalServices.forEach((service) => {
        service.trigger_option = this.trigger_options.find(
          (option) =>
            option.text.toUpperCase() === service.trigger_option.toUpperCase(),
        ).value;

        //turn trigger_condiction into array of numbers
        if (service.trigger_option === 0) {
          service.trigger_condition = service.trigger_condition.map((item) =>
            Number(item),
          );
        }

        service.primary_variable = this.variables.find(
          (variable) =>
            variable.text.toUpperCase() ===
            service?.primary_variable?.toUpperCase(),
        )?.value;
        service.primary_type = this.variable_types.find(
          (type) =>
            type.text.toUpperCase() === service?.primary_type?.toUpperCase(),
        )?.value;
        service.secondary_variable = this.variables.find(
          (variable) =>
            variable.text.toUpperCase() ===
            service?.secondary_variable?.toUpperCase(),
        )?.value;
        service.secondary_type = this.variable_types.find(
          (type) =>
            type.text.toUpperCase() === service?.secondary_type?.toUpperCase(),
        )?.value;
      });

      if (!this.contract.dutypay_notification) {
        this.newContract.dutypay_notification = {
          sms: false,
          whatsapp: false,
          email_seller: false,
          email_customer: false,
        };
      }
    },
    updateContract() {
      this.hsCodesStringToArray();
      this.newContract.additional_services_attributes =
        this.loadedAdditionalServices;
      this.newContract.approver_ids = this.selected_admins;
      this.newContractData.append("json", JSON.stringify(this.newContract));
      contractService
        .updateContract(this.$route.params.id, this.newContractData)
        .then(() => {
          this.loadCurrentContract();
          this.selected_admins = [];

          this.showModalEdit = false;

          this.temp_chosen_countries_origin.forEach((chosenCountri) => {
            this.selected_origin_country.push(chosenCountri);
          });
          this.countries_options_origin = this.countries_options_origin.filter(
            (optionCountri) =>
              !this.selected_origin_country.find(
                (selectedCountri) =>
                  selectedCountri.text === optionCountri.text,
              ),
          );
          this.temp_chosen_countries_origin = [];
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
          NProgress.done();
        });
    },
    saveClonedContract() {
      this.hsCodesStringToArray();
      this.newContract.additional_services_attributes =
        this.loadedAdditionalServices;
      this.newContractData.append("json", JSON.stringify(this.newContract));
      contractService
        .setContract(this.newContractData)
        .then(() => {
          this.$router.push("/contracts/contracts");
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
          NProgress.done();
        });
    },
    saveContract(modalEvent) {
      modalEvent.preventDefault();
      this.actionIsEdit ? this.updateContract() : this.saveClonedContract();
    },
    reloadPriceTables() {
      this.$store.dispatch(
        "price_tables/fetchNotBySellerItems",
        "pt_not_made_by_seller",
      );
    },
    reloadDeliveryTimes() {
      this.$store.dispatch(
        "delivery_times/fetchNotBySellerItems",
        "dt_not_made_by_seller",
      );
    },
    action(event) {
      this[event](this.price_table.id, this.price_table.name);
    },
    printPricePdf() {
      this.downloadPdf(this.contract.price_table_id, true);
    },
    printDeliveryPdf() {
      this.downloadPdf(this.contract.delivery_time_id, false);
    },
    printPriceCsv() {
      this.downloadCsv(
        this.contract.price_table_id,
        this.price_table.name,
        true,
      );
    },
    printDeliveryCsv() {
      this.downloadCsv(
        this.contract.delivery_time_id,
        this.delivery_time.name,
        false,
      );
    },
    downloadPdf(id, ifPrice) {
      const printService = ifPrice ? priceTablesService : deliveryTimesService;
      printService.downloadPdf(id, this.contract.id).then((response) => {
        if (response.data.data && response.data.data.file) {
          let file = response.data.data.file;
          let pdf = `data:application/pdf;base64,${file}`;
          let download_link = document.createElement("a");
          download_link.href = pdf;
          download_link.download = ifPrice
            ? `price_table_${id}.pdf`
            : `delivery_time_${id}.pdf`;
          download_link.click();
        }
      });
    },
    handleObsoleteChange() {
      if (this.newContract.is_obsolete === true) {
        this.$bvModal.show("warning-is-obsolete");
      }
    },
    revertObsolete() {
      this.newContract.is_obsolete = false;
    },
    downloadCsv(id, name, ifPrice) {
      const printService = ifPrice ? priceTablesService : deliveryTimesService;
      const filename = ifPrice
        ? `price_table_${name}.csv`
        : `delivery_time_${name}.csv`;
      printService.downloadCsv(id).then((response) => {
        let universalBOM = "\uFEFF";
        let csv =
          "data:text/csv; charset=utf-8," +
          encodeURIComponent(universalBOM + response.data);
        let anchor = document.createElement("a");
        anchor.href = csv;
        anchor.target = "_blank";
        anchor.download = filename;
        anchor.click();
      });
    },
    // getKeyByValue(object, value){
    //     const rxp = Object.keys(object).find(key => object[key] === value);
    //     if (rxp) {
    //         return rxp
    //     } else {
    //         return 'custom'
    //     }
    // },
    // addService() {
    //   this.contract.additional_services.push(JSON.parse(JSON.stringify(this.newAdditionalService)))
    // },
    loadTariffsTypes() {
      orderCostService.getTariffs().then((response) => {
        this.additionalServiceSlugs = response.data.data.map((costType) => {
          return {
            text: costType.slug,
            value: { value: costType.external_id, text: costType.text_en },
          }; // {value: costType.external_id, text: costType.text_en, slug: costType.slug}
        });
      });
    },
    addCustomService() {
      this.newAdditionalService.slug = this.additionalServiceSlugs.find(
        (service) => service.value.text === this.newAdditionalService.name,
      ).text; // this.getKeyByValue(this.additionalServiceSlugs, this.newAdditionalService.name);
      this.newAdditionalService.external_id = this.additionalServiceSlugs.find(
        (service) => service.value.text === this.newAdditionalService.name,
      ).value.value;
      this.loadedAdditionalServices.push(this.newAdditionalService);
      this.newAdditionalService = {};
      this.showNewServiceCreate = false;
    },
    hsCodesStringToArray() {
      this.loadedAdditionalServices.forEach((service) => {
        if (typeof service.hs_codes === "string") {
          let hs_codes = service.hs_codes.replaceAll(" ", "");
          hs_codes = hs_codes.split(",");
          service.hs_codes = hs_codes;
        }
      });
    },
    removeServiceFromAttributes(index) {
      if (index > -1) {
        this.loadedAdditionalServices.splice(index, 1);
      }
    },
    standardizeValue(val) {
      const finalVal = parseFloat(val).toFixed(2);
      if (finalVal === "NaN") {
        return "0.00";
      }
      return finalVal;
    },
    setCountriesOptionsDestination(addAllOption) {
      this.countries_options_destination = [];
      this.countries_options_destination = this.countries_options.filter(
        (countri) => this.possibleDestinationCountries.includes(countri.text),
      );
      if (addAllOption) {
        this.countries_options_destination.unshift({
          text: this.$t("anyCounties"),
          value: "all",
        });
      }
    },
    setCountriesOptionsOrigin(addAllOption) {
      this.countries_options_origin = [];
      this.countries_options_origin = [...this.countries_options];
      if (addAllOption) {
        this.countries_options_origin.unshift({
          text: this.$t("anyCounties"),
          value: "all",
        });
      }
    },
    addfromselectionToChosenCountriesDestination() {
      if (
        this.temp_chosen_countries_destination.some(
          (selectedCountry) => selectedCountry.value === "all",
        )
      ) {
        this.clearSelected("destination");
        this.selected_destination_country.push({
          text: this.$t("anyCounties"),
          value: "all",
        });
        this.temp_chosen_countries_destination = [];
        return;
      }
      this.temp_chosen_countries_destination.forEach((chosenCountri) => {
        this.selected_destination_country.push(chosenCountri);
      });
      this.countries_options_destination =
        this.countries_options_destination.filter(
          (optionCountri) =>
            !this.selected_destination_country.find(
              (selectedCountri) => selectedCountri.text === optionCountri.text,
            ),
        );
      this.temp_chosen_countries_destination = [];
    },
    removeSelectionToCountriesDestination() {
      this.temp_countries_destination.forEach((chosenCountri) => {
        this.countries_options_destination.push(chosenCountri);
      });
      this.selected_destination_country =
        this.selected_destination_country.filter(
          (optionCountri) =>
            !this.countries_options_destination.find(
              (selectedCountri) => selectedCountri.text === optionCountri.text,
            ),
        );

      /* ADICIONAR OS ISOS NO newContract.destination_country */
      this.temp_countries_destination = [];
    },

    addfromselectionToChosenCountriesOrigin() {
      if (
        this.temp_chosen_countries_origin.some(
          (selectedCountry) => selectedCountry.value === "all",
        )
      ) {
        this.clearSelected("origin");
        this.selected_origin_country.push({
          text: this.$t("anyCounties"),
          value: "all",
        });
        this.temp_chosen_countries_origin = [];
        return;
      }

      this.temp_chosen_countries_origin.forEach((chosenCountri) => {
        this.selected_origin_country.push(chosenCountri);
      });
      this.countries_options_origin = this.countries_options_origin.filter(
        (optionCountri) =>
          !this.selected_origin_country.find(
            (selectedCountri) => selectedCountri.text === optionCountri.text,
          ),
      );
      this.temp_chosen_countries_origin = [];
    },
    removeSelectionToCountriesOrigin() {
      this.temp_countries_origin.forEach((chosenCountri) => {
        this.countries_options_origin.push(chosenCountri);
      });
      this.selected_origin_country = this.selected_origin_country.filter(
        (optionCountri) =>
          !this.countries_options_origin.find(
            (selectedCountri) => selectedCountri.text === optionCountri.text,
          ),
      );
      /* ADICIONAR OS ISOS NO newContract.origin_country */
      this.temp_countries_origin = [];
    },
    selectAll(option) {
      const optionCaptalized = option.charAt(0).toUpperCase() + option.slice(1);
      this[`temp_chosen_countries_${option}`] = [
        ...this[`countries_options_${option}`],
      ];
      this[`addfromselectionToChosenCountries${optionCaptalized}`]();
    },
    clearSelected(option) {
      const optionCaptalized = option.charAt(0).toUpperCase() + option.slice(1);
      this[`temp_countries_${option}`] = [
        ...this[`selected_${option}_country`],
      ];
      this[`removeSelectionToCountries${optionCaptalized}`]();
    },
    formatCountryList(alphaCodes) {
      if (alphaCodes) {
        const countryNames = alphaCodes.map((alpha2Code) => {
          const country = this.countries.find(
            (country) => country.alpha2_code === alpha2Code,
          );
          return country ? country.name : alpha2Code;
        });

        return countryNames.join(", ");
      }
      return "";
    },
    showCondictions(text) {
      if (Array.isArray(text)) {
        const finalResult = text.join(", ");
        return finalResult;
      }
      return text;
    },
  },
  computed: {
    chosenFileUrl() {
      return this.chosenFile ? URL.createObjectURL(this.chosenFile) : "";
    },
    title() {
      return "Contract #" + this.$route.params.id;
    },
    priceTables() {
      return this.$store.state["price_tables"].notBySellerItems;
    },
    nobordist_checkpoints() {
      return this.$store.state["nobordist_checkpoints"].items;
    },
    sorted_nobordist_checkpoints() {
      const finalResult = this.$store.state["nobordist_checkpoints"].all_items
        .map(({ tracking_code, title }) => ({
          text: `${tracking_code} - ${title}`,
          value: tracking_code,
          isChecked: false,
        }))
        .sort((a, b) => (a.value > b.value ? 1 : -1));
      return finalResult;
    },
    deliveryTimes() {
      return this.$store.state["delivery_times"].notBySellerItems;
    },
    countries() {
      return this.$store.state.countries.all_items;
    },
    countries_options() {
      let options = [];
      this.countries.map((country) => {
        options.push({ text: country.name, value: country.alpha2_code });
      });
      return options;
    },
    isOldFlow() {
      let dataToReturn = [];
      let info = {};
      info.value = this.contract.is_old_flow ? "Old Flow" : "New Flow";
      info.variant = this.contract.is_old_flow ? "warning" : "info";
      dataToReturn.push(info);
      if (this.contract.airport_to_door) {
        let infoA2d = {
          value: "Airport to Door",
          variant: "info",
        };
        dataToReturn.push(infoA2d);
      }
      return dataToReturn;
    },
    disableSaveContract() {
      if (this.selected_admins.length > 0 || this.actionIsEdit === false) {
        return false;
      }
      return true;
    },
    checkAllCountriesOrigin() {
      if (
        this.selected_origin_country.some(
          (selectedCountry) => selectedCountry.value === "all",
        )
      ) {
        this.setCountriesOptionsOrigin();
        return true;
      }
      return false;
    },
    checkAllCountriesDestination() {
      if (
        this.selected_destination_country.some(
          (selectedCountry) => selectedCountry.value === "all",
        )
      ) {
        this.setCountriesOptionsDestination();
        return true;
      }
      return false;
    },
  },
  watch: {
    selected_destination_country: {
      deep: true,
      handler(newValue) {
        this.newContract.destination_country = [];

        newValue.map((country) => {
          this.newContract.destination_country.push(country.value);
        });
      },
    },
    selected_origin_country: {
      deep: true,
      handler(newValue) {
        this.newContract.origin_country = [];

        newValue.map((country) => {
          this.newContract.origin_country.push(country.value);
        });
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.invalid-feedback {
  display: block;
}
</style>
