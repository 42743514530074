<template>
  <div>
    <NbButton
      @click="isConfirmActionOpen = true"
      variant="secondary"
      class="w-100"
    >
      {{
        userWillBeBlocked
          ? $t("usersViewPage.blockUser")
          : $t("usersViewPage.unblockUser")
      }}
    </NbButton>

    <Modal v-model="isConfirmActionOpen">
      <ModalHeader>
        <ModalTitle>{{ $t("confirm") }}</ModalTitle>
      </ModalHeader>

      <p class="body-4">
        {{
          userWillBeBlocked
            ? $t("usersPage.confirmBlockUser")
            : $t("usersPage.confirmUnblockUser")
        }}
      </p>

      <ModalFooter class="d-flex justify-content-end gap-4">
        <ModalClose />
        <NbButton :disabled="isLoading" @click="handleAction">
          {{ $t("save") }}
        </NbButton>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import UsersService from "@/services/UsersService";
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
const usersService = new UsersService();

export default {
  components: {
    NbButton,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    isUserBlocked: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isConfirmActionOpen: false,
    };
  },
  computed: {
    userWillBeBlocked() {
      return !this.isUserBlocked;
    },
  },
  methods: {
    async handleAction() {
      try {
        this.isLoading = true;
        await usersService.updateUser(this.id, {
          is_blocked: this.userWillBeBlocked,
        });

        this.$emit("success", this.userWillBeBlocked);
        this.isConfirmActionOpen = false;
      } catch (error) {
        this.$bvToast.toast(error.response.data.messages[0], {
          title: "Error",
          solid: true,
          variant: "danger",
          autoHideDelay: 2000,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
