<template>
  <div>
    <header-title :title="title">
      <template #buttonsTop>
        <a
          data-toggle="modal"
          data-target="#editTrackingRule"
          class="btn btn-success float-right mb-2 text-white"
          title="Edit Tracking Rule"
          @click="copyTrackingRule()"
        >
          {{ $t("trackingRulesViewPage.editTrackingRule") }}
        </a>
      </template>
    </header-title>
    <div class="col-12 mb-5">
      <div class="card bg-light border-white rounded py-4">
        <div class="row mx-5 my-2">
          <field-view
            :field="$t('trackingRulesViewPage.name')"
            :val="trackingRule.name ? trackingRule.name : ''"
          />
          <field-view field="ID" :val="trackingRule.id" />
        </div>

        <div class="row mx-5 my-2">
          <field-view
            :field="$t('trackingRulesViewPage.seller')"
            :val="seller_name"
            :link="`/users_management/sellers?id=${trackingRule.seller_id}`"
            link-id=""
          />
          <field-view
            :field="$t('trackingRulesViewPage.contract')"
            :val="contract_name"
            :link="`/contracts/contracts/`"
            :link-id="trackingRule.contract_id"
          />
        </div>

        <div class="row mx-5 my-2">
          <field-view
            :field="$t('trackingRulesViewPage.startCheckpoint')"
            :val="start_checkpoint_name"
            :link="`/checkpoints/nobordist/`"
            :link-id="trackingRule.start_checkpoint_id"
          />
          <field-view
            :field="$t('trackingRulesViewPage.endCheckpoint')"
            :val="end_checkpoint_name"
            :link="`/checkpoints/nobordist/`"
            :link-id="trackingRule.end_checkpoint_id"
          />
          <field-view
            :field="$t('trackingRulesViewPage.daysLimit')"
            :val="trackingRule.days"
          />
        </div>
        <hr class="mb-4" />

        <div class="row mx-5 my-2">
          <field-view
            :field="$t('trackingRulesViewPage.trackingMode')"
            :val="trackingRule.tracking_mode"
          />
          <field-view
            v-if="trackingRule.configuration"
            :field="$t('trackingRulesViewPage.log')"
            :val="trackingRule.configuration.log"
          />
        </div>
        <hr class="mb-4" />

        <div v-if="trackingRule.configuration" class="row mx-5 my-2">
          <field-view
            :field="$t('trackingRulesViewPage.notifySeller')"
            :val="trackingRule.configuration['notify_seller']"
          />
          <field-view
            :field="$t('trackingRulesViewPage.sellerMailTitle')"
            :val="trackingRule.configuration['seller_mail_title']"
          />
        </div>

        <div v-if="trackingRule.configuration" class="row mx-5 my-2">
          <field-view
            :field="$t('trackingRulesViewPage.sellerMailBody')"
            :val="trackingRule.configuration['seller_mail_body']"
          />
        </div>
        <hr class="mb-4" />

        <div v-if="trackingRule.configuration" class="row mx-5 my-2">
          <field-view
            :field="$t('trackingRulesViewPage.notifyCustomer')"
            :val="trackingRule.configuration['notify_customer']"
          />
          <field-view
            :field="$t('trackingRulesViewPage.customerMailTitle')"
            :val="trackingRule.configuration['customer_mail_title']"
          />
        </div>

        <div v-if="trackingRule.configuration" class="row mx-5 my-2">
          <field-view
            :field="$t('trackingRulesViewPage.customerMailBody')"
            :val="trackingRule.configuration['customer_mail_body']"
          />
        </div>
        <hr class="mb-4" />

        <div v-if="trackingRule.configuration" class="row mx-5 my-2">
          <field-view
            :field="$t('trackingRulesViewPage.notifyNobordist')"
            :val="trackingRule.configuration['notify_nobordist']"
          />
          <field-view
            :field="$t('trackingRulesViewPage.nobordistMailTitle')"
            :val="trackingRule.configuration['nobordist_mail_title']"
          />
          <field-view
            :field="$t('trackingRulesViewPage.destination')"
            :val="trackingRule.destination"
          />
        </div>

        <div v-if="trackingRule.configuration" class="row mx-5 my-2">
          <field-view
            :field="$t('trackingRulesViewPage.nobordistMailBody')"
            :val="trackingRule.configuration['nobordist_mail_body']"
          />
        </div>
        <hr class="mb-4" />

        <div v-if="trackingRule.configuration.webhook" class="row mx-5 my-2">
          <field-view
            :field="$t('trackingRulesViewPage.notifyWebhook')"
            :val="trackingRule.configuration['notify_webhook']"
          />
          <field-view
            :field="$t('trackingRulesViewPage.method')"
            :val="trackingRule.configuration.webhook['method']"
          />
        </div>

        <div v-if="trackingRule.configuration.webhook" class="row mx-5 my-2">
          <field-view
            :field="$t('trackingRulesViewPage.endpoint')"
            :val="trackingRule.configuration.webhook['endpoint']"
          />
        </div>

        <div v-if="trackingRule.configuration.webhook" class="row mx-5 my-2">
          <field-view
            :field="$t('trackingRulesViewPage.headers')"
            :val="trackingRule.configuration.webhook.headers"
          />
        </div>

        <div v-if="trackingRule.configuration.webhook" class="row mx-5 my-2">
          <field-view
            :field="$t('trackingRulesViewPage.body')"
            :val="trackingRule.configuration.webhook['body']"
          />
        </div>

        <div class="row mx-5 mt-3 d-flex justify-content-center">
          <a
            data-toggle="modal"
            data-target="#deleteTrackingRule"
            class="btn btn-danger mt-2 text-white"
            title="Delete Tracking Rule"
          >
            {{ $t("trackingRulesViewPage.deleteTrackingRule") }}
          </a>
        </div>
      </div>
    </div>

    <!-- modals -->
    <div id="editTrackingRule" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="settingsTitle" class="modal-title">
              {{ $t("trackingRulesViewPage.edit") }}
              {{ trackingRule.name ? trackingRule.name : "" }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="name">{{
                    $t("trackingRulesViewPage.name")
                  }}</label>
                  <input
                    id="name"
                    v-model="newTrackingRule.name"
                    type="text"
                    :class="{ 'is-invalid': errors['name'] }"
                    class="form-control"
                    aria-describedby="nameHelp"
                    placeholder="Name .."
                  />
                  <div
                    v-for="(error, index) in errors['name']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <filter-select
                  :label="$t('trackingRulesViewPage.seller')"
                  :items="sellers"
                  placehol="Select Seller .."
                  error-target="seller_id"
                  item-value="id"
                  item-text="name"
                  :shows="['id', 'name']"
                  :errors="errors"
                  :val-edit="newTrackingRule.seller_id"
                  @emitValue="newTrackingRule.seller_id = $event"
                />
              </div>

              <div class="col">
                <label for="Contract">{{
                  $t("trackingRulesViewPage.contract")
                }}</label>
                <select
                  id="contract_idFilter"
                  v-model="newTrackingRule.contract_id"
                  class="form-control"
                  :class="{ 'is-invalid': errors['contract_id'] }"
                >
                  <option value=""></option>
                  <option
                    v-for="(contract, index) in contracts"
                    :key="index"
                    :value="contract.id"
                  >
                    ({{ contract.id }}) - {{ contract.slug }}
                  </option>
                </select>
                <div
                  v-for="(error, index) in errors['contract_id']"
                  :key="index"
                  class="invalid-feedback"
                >
                  {{ error }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label for="Start Checkpoint">{{
                  $t("trackingRulesViewPage.startCheckpoint")
                }}</label>
                <select
                  id="start_checkpoint_idFilter"
                  v-model="newTrackingRule.start_checkpoint_id"
                  class="form-control"
                  :class="{ 'is-invalid': errors['start_checkpoint_id'] }"
                >
                  <option value=""></option>
                  <option
                    v-for="(checkpoint, index) in checkpoints"
                    :key="index"
                    :value="checkpoint.id"
                  >
                    ({{ checkpoint.tracking_code }}) - {{ checkpoint.title }}
                  </option>
                </select>
                <div
                  v-for="(error, index) in errors['start_checkpoint_id']"
                  :key="index"
                  class="invalid-feedback"
                >
                  {{ error }}
                </div>
              </div>

              <div class="col">
                <label for="End Checkpoint">{{
                  $t("trackingRulesViewPage.endCheckpoint")
                }}</label>
                <select
                  id="end_checkpoint_idFilter"
                  v-model="newTrackingRule.end_checkpoint_id"
                  class="form-control"
                  :class="{ 'is-invalid': errors['end_checkpoint_id'] }"
                >
                  <option value=""></option>
                  <option
                    v-for="(checkpoint, index) in checkpoints"
                    :key="index"
                    :value="checkpoint.id"
                  >
                    ({{ checkpoint.tracking_code }}) - {{ checkpoint.title }}
                  </option>
                </select>
                <div
                  v-for="(error, index) in errors['end_checkpoint_id']"
                  :key="index"
                  class="invalid-feedback"
                >
                  {{ error }}
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label for="days">{{
                    $t("trackingRulesViewPage.maximumDays")
                  }}</label>
                  <input
                    id="days"
                    v-model="newTrackingRule.days"
                    type="number"
                    :class="{ 'is-invalid': errors['days'] }"
                    class="form-control"
                    aria-describedby="daysHelp"
                    placeholder="Days .."
                  />
                  <div
                    v-for="(error, index) in errors['days']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <hr class="mb-4" />
            <div class="row mt-2 mb-4">
              <div class="col-4">
                <b-form-checkbox
                  id="notify_log"
                  v-model="newTrackingRule.configuration.log"
                  name="notify_log"
                >
                  {{ $t("trackingRulesViewPage.log") }}
                </b-form-checkbox>
              </div>
            </div>
            <hr class="mb-4" />

            <div class="row mt-2">
              <div class="col-4">
                <label for="textarea-small">
                  <b-form-checkbox
                    id="notify_seller"
                    v-model="newTrackingRule.configuration.notify_seller"
                    name="notify_seller"
                  >
                    {{ $t("trackingRulesViewPage.notifySeller") }}
                  </b-form-checkbox>
                </label>
              </div>
              <div class="form-group col-8">
                <input
                  id="seller-title"
                  v-model="newTrackingRule.configuration.seller_mail_title"
                  type="text"
                  class="form-control"
                  placeholder="Title .."
                />
              </div>
            </div>
            <div class="row mt-0">
              <div class="col-12">
                <b-form-textarea
                  id="textarea-seller"
                  v-model="newTrackingRule.configuration.seller_mail_body"
                  size="sm"
                  placeholder="Seller Mail .."
                />
              </div>
            </div>
            <hr class="mb-4" />

            <div class="row mt-2">
              <div class="col-4">
                <label for="textarea-small">
                  <b-form-checkbox
                    id="notify_customer"
                    v-model="newTrackingRule.configuration.notify_customer"
                    name="notify_customer"
                  >
                    {{ $t("trackingRulesViewPage.notifyCustomer") }}
                  </b-form-checkbox>
                </label>
              </div>
              <div class="form-group col-8">
                <input
                  id="customer-title"
                  v-model="newTrackingRule.configuration.customer_mail_title"
                  type="text"
                  class="form-control"
                  placeholder="Title .."
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b-form-textarea
                  id="textarea-customer"
                  v-model="newTrackingRule.configuration.customer_mail_body"
                  size="sm"
                  placeholder="Customer Mail .."
                />
              </div>
            </div>
            <hr class="mb-4" />

            <div class="row mt-2">
              <div class="col-4">
                <label for="textarea-small">
                  <b-form-checkbox
                    id="notify_nobordist"
                    v-model="newTrackingRule.configuration.notify_nobordist"
                    name="notify_nobordist"
                  >
                    {{ $t("trackingRulesViewPage.notifyNobordist") }}
                  </b-form-checkbox>
                </label>
              </div>
              <div class="form-group col-8">
                <input
                  id="nobordist-title"
                  v-model="newTrackingRule.configuration.nobordist_mail_title"
                  type="text"
                  class="form-control"
                  placeholder="Title .."
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b-form-textarea
                  id="textarea-nobordist"
                  v-model="newTrackingRule.configuration.nobordist_mail_body"
                  size="sm"
                  placeholder="Nobordist Mail .."
                />
              </div>
            </div>
            <div class="row mt-3 mb-0">
              <div class="col-12">
                <div class="form-group">
                  <!-- <label for="nobordist-email">Nobordist Email</label> -->
                  <input
                    id="nobordist-email"
                    v-model="newTrackingRule.destination"
                    type="email"
                    class="form-control"
                    aria-describedby="nobordist-emailHelp"
                    placeholder="destination@nobordist.com"
                  />
                </div>
              </div>
            </div>
            <hr class="mb-4" />
            <div class="row mt-2">
              <div class="col-4">
                <label for="textarea-small">
                  <b-form-checkbox
                    id="notify_webhook"
                    v-model="newTrackingRule.configuration.notify_webhook"
                    name="notify_webhook"
                  >
                    {{ $t("trackingRulesViewPage.webhook") }}
                  </b-form-checkbox>
                </label>
              </div>
              <div class="form-group col-8">
                <label for="tracking_webhook"
                  >{{ $t("trackingRulesViewPage.method") }}:</label
                >
                <select
                  id="tracking_webhook"
                  v-model="newTrackingRule.configuration.webhook.method"
                  name="tracking_webhook"
                  class="form-control"
                >
                  <option value="POST">
                    {{ $t("trackingRulesViewPage.pOST") }}
                  </option>
                  <option value="PUT">
                    {{ $t("trackingRulesViewPage.pUT") }}
                  </option>
                </select>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <input
                    id="webhook_endpoint"
                    v-model="newTrackingRule.configuration.webhook.endpoint"
                    type="text"
                    class="form-control"
                    aria-describedby="webhook_endpoint"
                    placeholder="Endpoint"
                  />
                </div>
              </div>

              <div class="col-12">
                <div class="mb-3">
                  {{ $t("trackingRulesViewPage.headers") }}:
                </div>
                <div
                  v-for="(value, key, index) in newTrackingRule.configuration
                    .webhook.headers"
                  :key="index"
                  class="row"
                >
                  <div class="col-7">
                    <div class="form-group">
                      <label :for="index"> {{ key }}</label>
                      <input
                        :id="index"
                        v-model="
                          newTrackingRule.configuration.webhook.headers[key]
                        "
                        type="text"
                        :class="{ 'is-invalid': errors['headers'] }"
                        class="form-control"
                        :placeholder="key"
                      />
                      <div
                        v-for="(error, errorIndex) in errors['headers']"
                        :key="errorIndex"
                        class="invalid-feedback"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </div>
                  <div class="col-5">
                    <div v-if="key !== 'ContentType'" class="botton-button">
                      <button
                        class="btn btn-danger btn-sm"
                        @click="removeHeaders(key)"
                      >
                        - {{ key }}
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  v-if="!headersShow"
                  class="btn btn-info btn-sm ml-3 mb-2"
                  @click="headersShow = !headersShow"
                >
                  + {{ $t("trackingRulesViewPage.addHeaders") }}
                </button>
                <button
                  v-if="headersShow"
                  class="btn btn-secondary btn-sm ml-3 mb-2"
                  @click="headersShow = !headersShow"
                >
                  - {{ $t("trackingRulesViewPage.addHeaders") }}
                </button>
                <div v-if="headersShow" class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="key">{{
                        $t("trackingRulesViewPage.key")
                      }}</label>
                      <input
                        id="key"
                        v-model="hkey"
                        type="text"
                        class="form-control"
                        placeholder="Key .."
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="value">{{
                        $t("trackingRulesViewPage.value")
                      }}</label>
                      <input
                        id="value"
                        v-model="hvalue"
                        type="text"
                        class="form-control"
                        placeholder="Value .."
                      />
                    </div>
                  </div>
                </div>
                <button
                  v-if="headersShow"
                  class="btn btn-success btn-sm ml-3 mb-2"
                  @click="addHeaders(hvalue, hkey)"
                >
                  {{ $t("trackingRulesViewPage.addHeaders") }}
                </button>
              </div>

              <div class="col-12 mt-4">
                <b-form-textarea
                  id="textarea-webhook-body"
                  v-model="newTrackingRule.configuration.webhook.body"
                  size="sm"
                  placeholder="Webhook body..."
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a
              href="#"
              class="btn btn-primary float-right ml-3"
              @click="updateTrackingRule()"
            >
              {{ $t("update") }}
            </a>
            <a
              id="editTrackingRuleClose"
              href="#"
              class="btn btn-secondary float-right ml-3"
              data-dismiss="modal"
            >
              {{ $t("close") }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div id="deleteTrackingRule" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="settingsTitle" class="modal-title">
              {{ $t("trackingRulesViewPage.deleteTrackingRule") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("trackingRulesViewPage.sureWantDelete") }}?
          </div>
          <div class="modal-footer">
            <a
              id="deleteTrackingRuleClose"
              class="btn btn-secondary float-right ml-3"
              data-dismiss="modal"
              >{{ $t("close") }}</a
            >
            <a
              class="btn btn-danger float-right ml-3"
              data-dismiss="modal"
              @click="deleteTrackingRule()"
              >{{ $t("delete") }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- end modal -->
  </div>
</template>

<script>
// import { CoolSelect } from "vue-cool-select";
import TrackingRuleService from "@/services/TrackingRuleService";
import CheckpointService from "@/services/CheckpointService";
import SellerService from "@/services/SellerService";
import ContractService from "@/services/ContractService";
import NProgress from "nprogress";
import FieldView from "@/components/FieldView";
import FilterSelect from "@/components/FilterSelect";
import HeaderTitle from "@/components/HeaderTitle";

const trackingRuleService = new TrackingRuleService();
const checkpointService = new CheckpointService();
const sellerService = new SellerService();
const contractService = new ContractService();

export default {
  name: "TrackingRuleView",

  components: {
    "field-view": FieldView,
    "filter-select": FilterSelect,
    HeaderTitle,
  },
  data: () => {
    return {
      newTrackingRule: {
        name: null,
        start_checkpoint_id: null,
        end_checkpoint_id: null,
        seller_id: null,
        contract_id: null,
        days: null,
        destination: null,
        configuration: {
          log: false,
          notify_seller: false,
          notify_customer: false,
          notify_nobordist: false,
          notify_webhook: false,
          seller_mail_body: null,
          customer_mail_body: null,
          nobordist_mail_body: null,
          seller_mail_title: null,
          customer_mail_title: null,
          nobordist_mail_title: null,
          webhook: {
            method: null,
            endpoint: null,
            headers: {
              ContentType: "application/json",
            },
            body: null,
          },
        },
      },
      trackingRule: {},
      errors: [],
      checkpoints: [],
      sellers: [],
      contracts: [],
      headersShow: false,
      // ruleActions: ['log', 'notification', 'log/notification'],
      seller_name: "",
      contract_name: "",
      start_checkpoint_name: "",
      end_checkpoint_name: "",
      hkey: "",
      hvalue: "",
    };
  },
  computed: {
    title() {
      return this.$t("trackingRulesViewPage.title") + this.$route.params.id;
    },
  },
  beforeMount() {
    this.loadCurrentTrackingRule();
  },
  methods: {
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date) {
      const data = new Date(date);
      const formatedDate =
        data.getFullYear() +
        "-" +
        this.addZero(data.getMonth() + 1).toString() +
        "-" +
        this.addZero(data.getDate().toString());
      return formatedDate;
    },
    loadCurrentTrackingRule() {
      trackingRuleService
        .getTrackingRule(this.$route.params.id)
        .then((response) => {
          this.trackingRule = response.data.data;

          let date = this.trackingRule.created_at;
          this.trackingRule.created = this.formateDate(date);

          if (this.trackingRule.seller_id) {
            sellerService
              .getOneSeller(this.trackingRule.seller_id)
              .then((response) => {
                this.seller_name = response.data.data.name;
              });
          }
          if (this.trackingRule.contract_id) {
            contractService
              .getOneContract(this.trackingRule.contract_id)
              .then((response) => {
                this.contract_name = response.data.data.name;
              });
          }
          if (this.trackingRule.start_checkpoint_id) {
            checkpointService
              .getCheckpoint(this.trackingRule.start_checkpoint_id)
              .then((response) => {
                this.start_checkpoint_name = response.data.data.name;
              });
          }
          if (this.trackingRule.end_checkpoint_id) {
            checkpointService
              .getCheckpoint(this.trackingRule.end_checkpoint_id)
              .then((response) => {
                this.end_checkpoint_name = response.data.data.name;
              });
          }
        });
      checkpointService.getAllCheckpoints().then((response) => {
        this.checkpoints = response.data.data.elements;
      });
      sellerService.getAllSellers().then((response) => {
        this.sellers = response.data.data.elements;
      });
      contractService.getAllContracts().then((response) => {
        this.contracts = response.data.data.elements;
      });
    },
    copyTrackingRule() {
      this.errors = [];
      this.newTrackingRule = this.trackingRule;
    },
    updateTrackingRule() {
      trackingRuleService
        .updateTrackingRule(this.$route.params.id, this.newTrackingRule)
        .then(() => {
          document.getElementById("editTrackingRuleClose").click();
          //$('#editContract').modal('hide');
          this.loadCurrentTrackingRule();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
          NProgress.done();
        });
    },
    deleteTrackingRule() {
      trackingRuleService.deleteTrackingRule(this.$route.params.id).then(() => {
        document.getElementById("deleteTrackingRuleClose").click();
        this.$router.push("/logs/tracking_rules").then(() => {
          this.$bvToast.toast("Tracking Rule has been deleted", {
            title: "Success",
            toaster: "b-toaster-top-right",
            variant: "success",
            autoHideDelay: 10000,
            appendToast: true,
            solid: true,
          });
        });
      });
    },
    removeHeaders(key) {
      delete this.newTrackingRule.configuration.webhook.headers[key];
      this.headersShow = !this.headersShow;
      this.headersShow = !this.headersShow;
    },
    addHeaders(value, key) {
      this.newTrackingRule.configuration.webhook.headers[key] = value;
      this.hkey = "";
      this.hvalue = "";
    },
  },
};
</script>
