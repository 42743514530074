<template>
  <NbCard
    id="user"
    :title="$t('userProfilePage.userData')"
    :subTitle="$t('userProfilePage.checkYourData')"
  >
    <template #body>
      <form @submit.prevent="updateProfile" class="user-form">
        <div class="group">
          <NbTextInput
            v-model="formData.name"
            :name="$t('fullName')"
            id="name"
            required
            :error="[errors.get('name')]"
            @input="errors.clear('name')"
          />
          <NbTextInput
            v-model="formData.company_name"
            :name="$t('company')"
            id="company_name"
            :error="[errors.get('company_name')]"
            @input="errors.clear('company_name')"
            disabled
          />

          <NbTextInput
            v-model="formData.phone"
            :name="$t('phone')"
            id="phone"
            type="number"
            :error="[errors.get('phone')]"
            @input="errors.clear('phone')"
          />

          <NbTextInput
            v-model="formData.whatsapp_number"
            :name="$t('cellphone')"
            id="whatsapp_number"
            type="number"
            :error="[errors.get('whatsapp_number')]"
            @input="errors.clear('whatsapp_number')"
          />
        </div>

        <NbButton class="d-flex ml-auto mt-4" :disabled="loading">{{
          $t("save")
        }}</NbButton>
      </form>
    </template>
  </NbCard>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import Errors from "@/utils/Errors.js";
import UserService from "@/services/UserService";
import UsersService from "@/services/UsersService";

const userService = new UserService();
const usersService = new UsersService();

export default {
  components: {
    NbCard,
    NbTextInput,
    NbButton,
  },
  data() {
    return {
      errors: new Errors(),
      loading: false,
      formData: {
        name: "",
        company_name: "",
        phone: "",
        whatsapp_number: "",
      },
    };
  },
  methods: {
    validate() {
      const errors = {};
      if (!this.formData.name?.trim()) {
        errors.name = [this.$t("errorMessages.required")];
      }

      if (!this.formData.company_name?.trim()) {
        errors.company_name = [this.$t("errorMessages.required")];
      }

      return errors;
    },
    setUser(data) {
      userService.setLoggedInUser(JSON.stringify(data));
      this.$store.commit("SET_LOGGEDIN_USER", data);
    },
    async updateProfile() {
      try {
        const errors = this.validate();
        if (Object.keys(errors).length) {
          this.errors.record(errors);
          return;
        }

        this.loading = true;
        const data = {
          ...this.$store.state.user,
          ...this.formData,
        };
        const response = await usersService.updateProfile(data);
        this.$bvToast.toast(response.data.messages[0] || "Success", {
          solid: true,
          variant: "success",
        });
        this.setUser(data);
      } catch (error) {
        if (error?.response?.data) {
          this.errors.record(error.response.data.messages[0]);
          return;
        }
        this.$bvToast.toast(this.$t("errorMessages.genericError"), {
          variant: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    const user = JSON.parse(JSON.stringify(this.$store.state.user));
    this.formData = {
      ...this.formData,
      ...user,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-form {
  .group {
    display: grid;
    gap: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .user-form {
    .group {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
