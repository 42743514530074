<template>
  <div class="row justify-content-center">
    <div class="col-12 mb-5">
      <back-button />
      <h5 class="font-weight-light mb-4 text-uppercase">
        {{ $t("alertLogsViewPage.title") }}{{ alertLog.id }}
      </h5>
      <br />
      <div class="card bg-light border-white rounded py-4">
        <div v-if="alertLog.order_data" class="row mx-5 my-2">
          <field-view
            :field="$t('alertLogsViewPage.incoterm')"
            :val="alertLog.incoterm"
            :disabled="true"
          />
          <field-view
            :field="$t('alertLogsViewPage.status')"
            :val="alertLog.status"
            :disabled="true"
          />
        </div>
        <div v-if="alertLog" class="row mx-5 my-2">
          <field-view
            :field="$t('alertLogsViewPage.date')"
            :val="alertLog.created_at"
            :disabled="true"
          />
          <field-view
            :field="$t('alertLogsViewPage.dateIso')"
            :val="alertLog.date_iso"
            :disabled="true"
          />
          <field-view
            :field="$t('alertLogsViewPage.orderID')"
            :val="alertLog.order_id"
            :link="`/shipping/orders?id=${alertLog.order_id}`"
            link-id=""
            :disabled="true"
          />
          <field-view
            :field="$t('alertLogsViewPage.orderNumber')"
            :val="alertLog.order_number"
            :disabled="true"
          />
        </div>
        <div class="row mx-5 my-2">
          <field-view
            v-if="alertLog.order_data"
            :field="$t('alertLogsViewPage.volumeID')"
            :val="alertLog.volume_id"
            :link="`/shipping/volumes?id=`"
            :link-id="alertLog.volume_id"
            :disabled="true"
          />
          <field-view
            :field="$t('alertLogsViewPage.sellerID')"
            :val="alertLog.seller_id"
            :link="`/users_management/sellers?id=${alertLog.seller_id}`"
            link-id=""
            :disabled="true"
          />
        </div>

        <div v-if="alertLog" class="row mx-5 my-2">
          <field-view
            :field="$t('alertLogsViewPage.taxID')"
            :val="alertLog.tax_id"
            :disabled="true"
          />
          <field-view
            :field="$t('alertLogsViewPage.lastMileNumber')"
            :val="alertLog.last_mile_number"
            :disabled="true"
          />
        </div>

        <div v-if="alertLog" class="row mx-5 my-2">
          <field-view
            :field="$t('alertLogsViewPage.productsValue')"
            standardize="true"
            :val="alertLog.products_value"
            :currency="alertLog.currency"
            :disabled="true"
          />
          <field-view
            :field="$t('alertLogsViewPage.freightValue')"
            standardize="true"
            :val="alertLog.freight_value"
            :currency="alertLog.currency"
            :disabled="true"
          />
        </div>

        <div v-if="alertLog" class="row mx-5 my-2">
          <field-view
            :field="$t('alertLogsViewPage.expectedTax')"
            standardize="true"
            :val="alertLog.expected_tax"
            :currency="alertLog.currency"
            :disabled="true"
          />
          <field-view
            :field="$t('alertLogsViewPage.realTax')"
            standardize="true"
            :val="alertLog.real_tax"
            currency="R$"
            :disabled="true"
          />
        </div>
        <hr />
        <div class="row mx-5 my-2">
          <div class="form-group col">
            <label for="charged_amount">{{
              $t("alertLogsViewPage.sellerChargedAmount")
            }}</label>
            <input
              id="charged_amount"
              v-model="alertLog.charged_amount"
              type="number"
              min="0"
              step="0.01"
              :class="{ 'is-invalid': errors['charged_amount'] }"
              class="form-control"
              aria-describedby="charged_amountHelp"
              placeholder="Seller Charged Amount .."
            />
            <div
              v-for="(error, index) in errors['charged_amount']"
              :key="index"
              class="invalid-feedback"
            >
              {{ error }}
            </div>
          </div>

          <div class="form-group col">
            <label for="exchange">{{ $t("alertLogsViewPage.exchange") }}</label>
            <input
              id="exchange"
              v-model="alertLog.exchange"
              type="number"
              min="0"
              step="0.01"
              :class="{ 'is-invalid': errors['exchange'] }"
              class="form-control"
              aria-describedby="exchangeHelp"
              placeholder="Exchange .."
            />
            <div
              v-for="(error, index) in errors['exchange']"
              :key="index"
              class="invalid-feedback"
            >
              {{ error }}
            </div>
          </div>

          <field-view
            :field="$t('alertLogsViewPage.valueR$')"
            standardize="true"
            :val="value_brl"
            :disabled="true"
          />
        </div>

        <div class="row mx-5 my-2">
          <div class="col">
            <a
              class="btn btn-primary mt-2 text-white float-right"
              title="Save Changes"
              @click="updateAlertLog"
            >
              {{ $t("save") }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton";
import AlertLogsService from "@/services/AlertLogsService";
import FieldView from "@/components/FieldView";

const alertLogsService = new AlertLogsService();

export default {
  name: "Alertlogsview",

  components: {
    BackButton,
    "field-view": FieldView,
  },
  data: () => {
    return {
      alertLog: {
        charged_amount: 0,
        exchange: 0,
        order_data: {},
      },
      errors: [],
      watchControl: null,
      value_brl: 0,
    };
  },
  watch: {
    alertLog: {
      deep: true, //Verifica o obj/array
      immediate: true, //Verifica o obj/array logo quando a pag carrega

      handler(val) {
        clearTimeout(this.watchControl);
        this.watchControl = setTimeout(() => {
          this.watchControl = null;
          if (val.charged_amount && val.exchange) {
            val.charged_amount = this.formatMoney(val.charged_amount);
            val.exchange = this.formatMoney(val.exchange);

            this.value_brl = val.charged_amount * val.exchange;
          } else {
            this.value_brl = 0.0;
          }
        }, 2500);
      },
    },
  },
  beforeMount() {
    this.reloadAlertLog();
  },
  methods: {
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    reloadAlertLog() {
      alertLogsService.getLog(this.$route.params.id).then((response) => {
        this.alertLog = response.data.data;
        //formatacao de datas iso e created at
        const time_date_iso = this.alertLog.date_iso;
        const date_date_iso = this.alertLog.date_iso;
        this.alertLog.date_iso = this.formateDate(date_date_iso, time_date_iso);

        const time = this.alertLog.created_at;
        const date = this.alertLog.created_at;
        this.alertLog.created_at = this.formateDate(date, time);
      });
    },
    updateAlertLog() {
      alertLogsService
        .updateLog(this.$route.params.id, this.alertLog)
        .then(() => {
          this.reloadAlertLog();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
          // NProgress.done();
        });
    },

    formatMoney(num) {
      if (!num || num < 0 || num == "") {
        num = 0;
      }
      num = parseFloat(num).toFixed(2);

      return num;
    },
  },
};
</script>

<style></style>
