<template>
  <div class="d-flex gap-2">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
