<template>
  <Datatable :columns="columns" :data="orderCosts">
    <template #cell(payment_date)="data">
      <div>
        {{ paymentDate(data.item.payment_date) }}
      </div>
    </template>
    <template #cell(value)="data">
      <div>
        {{ currency.parse(data.item.value) }}
      </div>
    </template>
  </Datatable>
</template>

<script>
import Datatable from "@/components/datatable/Datatable.vue";
import { currency } from "@/utils/filters/currency";

export default {
  props: {
    orderCosts: {
      type: Array,
      required: true,
    },
  },
  components: {
    Datatable,
  },
  data() {
    return {
      currency,
    };
  },
  computed: {
    columns() {
      return [
        {
          key: "external_id",
          label: this.$t("ordersViewPage.fieldsCurrentOrderCosts.externalID"),
        },
        {
          key: "name",
          label: this.$t("ordersViewPage.fieldsCurrentOrderCosts.type"),
        },
        {
          key: "value",
          label: this.$t("ordersViewPage.fieldsCurrentOrderCosts.value"),
        },
        {
          key: "payment_date",
          label: this.$t("paymentDate"),
        },
      ];
    },
  },
  methods: {
    paymentDate(value) {
      return Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
        timeStyle: undefined,
        timeZone: "UTC",
      }).format(new Date(value));
    },
  },
};
</script>

<style lang="scss" scoped>
.costs {
  .info {
    display: flex;
    flex-direction: column;
    small {
      font-weight: 700;
      font-size: 0.75rem;
    }
    span {
      line-height: 1.2;
    }
  }

  section {
    & + section {
      margin-top: 1.5rem;
    }
  }
}
</style>
