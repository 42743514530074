<template>
  <div>
    <NbButton
      variant="quaternary"
      @click="showModal"
      class="d-flex gap-2 align-items-center"
    >
      <NbIcon icon="list" :size="18" />
      {{ $t("components.selectTableColumns.button") }}
    </NbButton>

    <Modal v-model="isModalOpen">
      <ModalHeader>
        <div>
          <ModalTitle>
            {{ $t("components.nbTable.tableOptions") }}
          </ModalTitle>
          <p class="body-4">
            {{ $t("components.nbTable.changeVisibilityReorderColumns") }}
          </p>
        </div>
      </ModalHeader>

      <div class="grid grid-cols-2 gap-8">
        <div>
          <div class="heading-3 mb-2">
            {{ $t("components.selectTableColumns.shownColumns") }}
            <NbButton variant="tertiary" @click="selectAll()">
              {{ $t("components.selectTableColumns.selectAll") }}
            </NbButton>
          </div>

          <draggable
            class="list-group"
            :list="shownList"
            group="table"
            @end="onDragEnd"
            @start="onDragStart"
            :options="{ animation: 200 }"
          >
            <div
              :class="[
                'list-group-item d-flex align-items-center justify-content-between',
                { 'draggable-grabbing': isDragging },
              ]"
              v-for="element in shownList"
              :key="element.key"
            >
              <div>
                <NbIcon icon="more-vertical" :size="18" />
                {{ element.label }}
              </div>
              <button variant="quaternary" @click="moveToHiddenColumn(element)">
                <NbIcon icon="eye" :size="18" />
              </button>
            </div>
          </draggable>
        </div>

        <div>
          <div class="heading-3 mb-2">
            {{ $t("components.selectTableColumns.hiddenColumns") }}
            <NbButton variant="tertiary" @click="clearSelected()">
              {{ $t("components.selectTableColumns.unSelectAll") }}
            </NbButton>
          </div>

          <draggable
            class="list-group"
            :list="hiddenList"
            group="table"
            @end="onDragEnd"
            @start="onDragStart"
            :options="{ animation: 200 }"
          >
            <div
              :class="[
                'list-group-item d-flex align-items-center justify-content-between',
                { 'draggable-grabbing': isDragging },
              ]"
              v-for="element in hiddenList"
              :key="element.key"
            >
              <div>
                <NbIcon icon="more-vertical" :size="18" />
                {{ element.label }}
              </div>
              <button variant="quaternary" @click="moveToShownColumn(element)">
                <NbIcon icon="eye-off" :size="18" />
              </button>
            </div>
          </draggable>
        </div>
      </div>
      <ModalFooter class="d-flex justify-content-end gap-2">
        <ModalClose />
        <NbButton @click="onSave">
          {{ $t("save") }}
        </NbButton>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import draggable from "vuedraggable";
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  components: {
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    draggable,
    NbIcon,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDragging: false,
      isModalOpen: false,
      shownList: [],
      hiddenList: [],
    };
  },
  watch: {
    columns() {
      this.setList();
    },
    isModalOpen() {
      this.setList();
    },
  },
  methods: {
    onSave() {
      const shown = this.shownList.map((item) => ({
        key: item.key,
        show: true,
      }));
      const hidden = this.hiddenList.map((item) => ({
        key: item.key,
        show: false,
      }));
      this.$emit("update", [...shown, ...hidden]);
      this.isModalOpen = false;
    },
    moveToHiddenColumn(element) {
      const elementIndex = this.shownList.findIndex(
        (item) => item.key === element.key,
      );
      this.hiddenList.push(element);
      this.shownList.splice(elementIndex, 1);
    },
    moveToShownColumn(element) {
      const elementIndex = this.hiddenList.findIndex(
        (item) => item.key === element.key,
      );
      this.shownList.push(element);
      this.hiddenList.splice(elementIndex, 1);
    },
    setList() {
      this.shownList = this.columns.filter((item) => item.show !== false);
      this.hiddenList = this.columns.filter((item) => item.show === false);
    },
    selectAll() {
      this.hiddenList = this.columns;
      this.shownList = [];
    },
    clearSelected() {
      this.shownList = this.columns;
      this.hiddenList = [];
    },
    showModal() {
      this.isModalOpen = true;
    },
    onDragStart() {
      this.isDragging = true;
      document.body.classList.add("draggable-grabbing");
    },
    onDragEnd() {
      this.isDragging = false;
      document.body.classList.remove("draggable-grabbing");
    },
  },
  beforeMount() {
    this.setList();
  },
};
</script>

<style lang="scss" scoped>
.draggable-grab {
  cursor: grab;
}

.draggable-grabbing {
  .list-group-item {
    cursor: grabbing !important;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: 500px;
  &-item {
    border: none;
    cursor: grab;
    background-color: var(--gray-05);
    border-radius: 6px;
    padding: 0.75rem;
    button {
      background-color: transparent;
      border: none;
      outline: none;
      transition: all 0.2s;
      border-radius: 50%;
      &:hover {
        background-color: var(--gray-10);
      }
    }
  }
}
</style>
