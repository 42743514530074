<template>
  <div>
    <div
      class="d-flex justify-content-between flex-wrap pt-4 dashed-middle border-bottom border-gray-20 pb-4 mb-4"
    >
      <!-- :class="
        deliveryMethodNamesIds?.reverse_delivery_method_name
          ? 'border-bottom border-gray-20 pb-4 mb-4'
          : ''
      " -->
      <div
        v-for="deliveryMethod in deliveryMethods"
        :key="deliveryMethod.icon"
        class="w-24"
      >
        <div class="body-2 mb-2 pb-1">
          <div class="mb-1">
            {{ deliveryMethod.type }}
          </div>
          <div>({{ $t("contractsViewPage.deliveryMethod") }})</div>
        </div>
        <div class="text-gray-60 icon-stamp mb-3">
          <NbIcon
            v-if="deliveryMethod.icon !== 'plane'"
            :icon="deliveryMethod.icon"
            :attributes="{
              'stroke-width': 3,
              width: '1.2rem',
              height: '1.2rem',
            }"
          />
          <div v-else class="icon">
            <Plane />
          </div>
        </div>
        <div
          :class="
            deliveryMethod.name ? 'heading-2 mb-2 link-1' : 'heading-2 mb-2'
          "
          @click="
            pushToNewTab(
              `/contracts/delivery_methods?deliveryMethodId=${deliveryMethod.id}`,
            )
          "
        >
          {{ deliveryMethod.name }}
        </div>
        <div class="body-2"></div>
      </div>
    </div>
    <div class="d-flex">
      <div class="text-gray-60 icon-stamp mr-4">
        <NbIcon
          icon="repeat"
          :attributes="{
            'stroke-width': 3,
            width: '1.2rem',
            height: '1.2rem',
          }"
        />
      </div>
      <div>
        <div class="body-2 mb-2">
          {{ $t("contractsViewPage.reverseDeliveryMethod") }}
        </div>
        <div
          :class="
            deliveryMethodNamesIds?.reverse_delivery_method_name
              ? 'heading-2 mb-2 link-1'
              : 'heading-2 mb-2'
          "
          @click="
            pushToNewTab(
              `/contracts/delivery_methods?deliveryMethodId=${deliveryMethodNamesIds?.reverse_delivery_method_id}`,
            )
          "
        >
          {{
            deliveryMethodNamesIds?.reverse_delivery_method_name
              ? deliveryMethodNamesIds?.reverse_delivery_method_name
              : "N/A"
          }}
        </div>
        <div class="body-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
import Plane from "@/components/Plane.vue";

export default {
  name: "ContractDeliveryMethods",
  components: {
    NbIcon,
    Plane,
  },
  props: {
    deliveryMethodNamesIds: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    pushToNewTab(path) {
      window.open(path, "_blank");
    },
  },
  computed: {
    deliveryMethods() {
      return [
        {
          id: this.deliveryMethodNamesIds?.first_mile_delivery_method_id,
          type: this.$t("contractsViewPage.firstMileDeliveryMethod"),
          icon: "truck",
          name:
            this.deliveryMethodNamesIds?.first_mile_delivery_method_name ||
            "N/A",
        },
        {
          id: this.deliveryMethodNamesIds?.freight_forwarder_delivery_method_id,
          type: this.$t("contractsViewPage.freightForwarderDeliveryMethod"),
          icon: "plane",
          name:
            this.deliveryMethodNamesIds
              ?.freight_forwarder_delivery_method_name || "N/A",
        },
        {
          id: this.deliveryMethodNamesIds?.custom_clearence_delivery_method_id,
          type: this.$t("contractsViewPage.customClearenceDeliveryMethod"),
          icon: "package",
          name:
            this.deliveryMethodNamesIds
              ?.custom_clearence_delivery_method_name || "N/A",
        },
        {
          id: this.deliveryMethodNamesIds?.last_mile_delivery_method_id,
          type: this.$t("contractsViewPage.lastMileDeliveryMethod"),
          icon: "user",
          name:
            this.deliveryMethodNamesIds?.last_mile_delivery_method_name ||
            "N/A",
        },
      ];
    },
  },
  watch: {},
};
</script>

<style scoped>
.icon-stamp {
  background-color: var(--gray-10);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
}
.icon-stamp > .i,
.icon-stamp > .icon {
  margin-left: 1.4rem; /* calc is => (icon-stamp - icon)/2 */
}

.dashed-middle {
  z-index: 1;
  width: 100%;
  position: relative;
}

.dashed-middle:before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 6.7rem;
  border-bottom: 3px var(--gray-10) dashed;
  width: 53rem;
}
</style>
