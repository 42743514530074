<template>
  <div>
    <h3 class="heading-4 my-4">
      {{ $t("trackingRuleNotificationsPage.chooseContracts") }}
    </h3>

    <Spinner v-if="loading" class="mx-auto" size="lg" />
    <div v-else-if="!contracts.length" class="mb-4">
      <p class="body-4">
        {{ $t("trackingRuleNotificationsPage.youDontHaveContractAllowed") }}
      </p>

      <NbButton @click="navigateTo('/contracts/contracts')">
        {{ $t("trackingRuleNotificationsPage.gotToContractsPage") }}
      </NbButton>
    </div>

    <div v-else class="my-4">
      <div class="d-flex gap-4 align-items-center mt-4 mb-2">
        <NbButton
          @click="selectAll"
          :variant="isAllSelected ? 'secondary' : 'primary'"
          :disabled="isAllSelected"
        >
          {{
            isAllSelected
              ? $t("trackingRuleNotificationsPage.allSelected")
              : $t("trackingRuleNotificationsPage.selectAll")
          }}
        </NbButton>
        <NbButton
          v-if="
            selectedContractIds.length && !automaticallyAddRuleForNewContracts
          "
          variant="quaternary"
          @click="clear"
        >
          {{ $t("clear") }}
        </NbButton>
      </div>

      <div>
        <NbSelectInput
          v-if="isAllSelected"
          v-model="actionForNewContracts"
          :options="actionForNewContractsOptions"
          id="action-on-contract-creation"
          :name="$t('trackingRuleNotificationsPage.onContractCreation')"
          style="margin-top: -16px"
          required
        />
        <small class="d-block" v-if="automaticallyAddRuleForNewContracts">
          <NbIcon icon="alert-circle" :size="16" class="text-danger" />
          {{ $t("trackingRuleNotificationsPage.toEditContractsList") }}
          <strong>
            "{{ $t("trackingRuleNotificationsPage.notAddThisRule") }}"
          </strong>
        </small>
      </div>

      <div class="my-4">
        <NbTextInput
          v-model="search"
          id="search"
          :placeholder="$t('trackingRuleNotificationsPage.searchByNameOrId')"
        />
        <div>
          <small v-if="selectedContractIds.length">
            <strong class="text-primary">
              {{ selectedContractIds.length }}
            </strong>
            {{ $t("trackingRuleNotificationsPage.contractsSelected") }}
          </small>
          <small v-else>
            {{ $t("trackingRuleNotificationsPage.noContractSelected") }}
          </small>
        </div>

        <div class="contracts grid grid-cols-3 gap-2 mt-2">
          <div
            v-for="contract in filteredContracts"
            :key="contract.id"
            class="d-flex align-items-center gap-2"
          >
            <input
              v-model="selectedContractIds"
              type="checkbox"
              :id="`contract-${contract.id}`"
              :value="contract.id"
              class="nb-checkbox"
              :disabled="automaticallyAddRuleForNewContracts"
            />
            <label
              :for="`contract-${contract.id}`"
              :class="[
                'input-label pointer',
                { disabled: automaticallyAddRuleForNewContracts },
              ]"
            >
              {{ contract.name }} - {{ contract.id }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loading" class="d-flex justify-content-between">
      <NbButton variant="secondary" @click="goBack()">
        {{ $t("back") }}
      </NbButton>
      <NbButton @click="goNext()" :disabled="!isValid">
        {{ $t("next") }}
      </NbButton>
    </div>

    <Modal v-model="isWarningModalOpen" @close="onKeepEditing">
      <ModalHeader>
        <ModalTitle>
          {{ $t("attention") }}
        </ModalTitle>
      </ModalHeader>

      <p class="body-4">
        {{ $t("trackingRuleNotificationsPage.allShipmentWarning") }}
      </p>

      <ModalFooter class="d-flex justify-content-end gap-4">
        <NbButton variant="secondary" @click="onKeepEditing">
          {{ $t("trackingRuleNotificationsPage.keepEditing") }}
        </NbButton>
        <NbButton @click="onGoIt">
          {{ $t("trackingRuleNotificationsPage.goIt") }}
        </NbButton>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import Spinner from "@/components/Spinner.vue";
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";

import ContractService from "@/services/ContractService.js";
const contractService = new ContractService();

import { mapState, mapMutations } from "vuex";

const ADD_RULE_AUTOMATICALLY_ACTION = "auto_add_contract";
const ADD_RULE_MANUALLY_ACTION = "manual_add_contract";

export default {
  components: {
    NbButton,
    NbTextInput,
    NbSelectInput,
    NbIcon,
    Spinner,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
  },
  inject: ["ruleContext"],
  data() {
    return {
      loading: false,
      search: "",
      contracts: [],
      filteredContracts: [],
      searchTimeout: null,
      isWarningModalOpen: false,
    };
  },
  computed: {
    ...mapState({
      contractIds: (state) => state.notificationRuleSettings.contractIds,
      sellerIds: (state) => state.notificationRuleSettings.sellerIds,
      contractCreation: (state) =>
        state.notificationRuleSettings.contractCreation,
      sellerCreation: (state) => state.notificationRuleSettings.sellerCreation,
      isAllSellersSelected: (state) =>
        state.notificationRuleSettings.isAllSellersSelected,
      isAllContractsSelected: (state) =>
        state.notificationRuleSettings.isAllContractsSelected,
    }),
    actionForNewContracts: {
      get() {
        return this.contractCreation;
      },
      set(val) {
        this.updateContractCreation(val);
      },
    },
    automaticallyAddRuleForNewContracts() {
      return this.actionForNewContracts === ADD_RULE_AUTOMATICALLY_ACTION;
    },
    actionForNewContractsOptions() {
      return [
        {
          text: this.$t("trackingRuleNotificationsPage.autoAddRule"),
          value: ADD_RULE_AUTOMATICALLY_ACTION,
        },
        {
          text: this.$t("trackingRuleNotificationsPage.notAddThisRule"),
          value: ADD_RULE_MANUALLY_ACTION,
        },
      ];
    },
    selectedContractIds: {
      get() {
        return this.contractIds;
      },
      set(val) {
        this.updateContractIds(val);
      },
    },
    isAllSelected() {
      return (
        this.contracts.length > 0 &&
        this.selectedContractIds.length === this.contracts.length
      );
    },
    isValid() {
      if (this.isAllSelected) {
        return !!this.contractCreation;
      }

      if (!this.selectedContractIds.length) {
        return false;
      }

      return true;
    },
  },
  watch: {
    search() {
      clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(() => {
        this.setfilteredContracts();
      }, 500);
    },
    actionForNewContracts() {
      this.onActionChange();
    },
    isAllSelected(newValue, oldValue) {
      if (!newValue) {
        this.actionForNewContracts = "";
      }
      if (oldValue !== newValue) {
        this.updateIsAllContractsSelected(newValue);
      }
    },
  },
  methods: {
    ...mapMutations("notificationRuleSettings", {
      updateContractIds: "UPDATE_CONTRACT_IDS",
      updateContractCreation: "UPDATE_CONTRACT_CREATION",
      updateApplyRuleTo: "UPDATE_APPLY_RULE_TO",
      updateIsAllContractsSelected: "UPDATE_IS_ALL_CONTRACTS_SELECTED",
    }),

    goBack() {
      this.$emit("back");
    },
    goNext() {
      this.$emit("next");
    },
    setfilteredContracts() {
      if (!this.search?.trim()) {
        this.filteredContracts = this.contracts;
        return;
      }

      const regex = new RegExp(`${this.search}`, "i");
      this.filteredContracts = this.contracts.filter(
        (contract) => regex.test(contract.name) || regex.test(contract.id),
      );
    },
    onActionChange() {
      if (
        this.isAllSelected &&
        this.isAllSellersSelected &&
        this.contractCreation === ADD_RULE_AUTOMATICALLY_ACTION &&
        this.sellerCreation === "auto_add_seller"
      ) {
        this.isWarningModalOpen = true;
      }
    },
    onKeepEditing() {
      this.actionForNewContracts = ADD_RULE_MANUALLY_ACTION;
      this.isWarningModalOpen = false;
    },
    onGoIt() {
      this.updateApplyRuleTo("all_shipments");
      this.$emit("set-step", 2);
    },
    async loadContracts() {
      try {
        this.loading = true;

        let data = null;
        if (this.sellerIds.length) {
          const response = await contractService.getSellersContracts({
            seller_ids: this.sellerIds,
            values_only: true,
          });
          data = response.data.data;
        } else {
          const response = await contractService.getAllContracts();
          data = response.data.data.elements;
        }

        this.contracts = data;
        this.$store.dispatch(
          "notificationRuleSettings/matchContractIds",
          data.map((item) => item.id),
        );
        this.setfilteredContracts();

        if (this.ruleContext?.ruleId && this.isAllContractsSelected) {
          this.selectAll();
        }
      } finally {
        this.loading = false;
      }
    },
    selectAll() {
      this.selectedContractIds = this.contracts.map((contract) => contract.id);
    },
    clear() {
      this.selectedContractIds = [];
    },
    navigateTo(path) {
      this.$router.push(path);
    },
  },
  activated() {
    this.loadContracts();
  },
};
</script>

<style lang="scss" scoped>
.contracts {
  max-height: 500px;
  overflow-y: auto;
  label {
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
</style>
