<template>
  <div>
    <ModalHeader>
      <ModalTitle>General costs</ModalTitle>
    </ModalHeader>

    <NbTablev2
      class="mt-5"
      tableOf="orderGeneralCosts"
      :hasParentItens="true"
      ref="orderGeneralCostsRef"
      :hiddeTableOptions="true"
      height="fit-content"
      :allFields="fieldsCurrentOrderCosts"
      :fields="fields"
      @reloadFields="fields = $event"
      :startItems="orderCosts"
    >
      <template #cell(created_at)="data">
        <div>
          {{ formateDate(data.item.created_at) }}
        </div>
      </template>
      <template #cell(value)="data">
        <div>
          {{ currency.parse(data.value) }}
        </div>
      </template>
    </NbTablev2>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import moment from "moment";
import { currency } from "@/utils/filters/currency";

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbTablev2,
  },
  props: {
    orderCosts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      currency,
    };
  },
  computed: {
    fieldsCurrentOrderCosts() {
      return [
        {
          key: "external_id",
          label: this.$t("ordersPage.fieldsCurrentOrderCosts.externalID"),
        },
        {
          key: "name",
          label: this.$t("ordersPage.fieldsCurrentOrderCosts.type"),
        },
        {
          key: "value",
          label: this.$t("ordersPage.fieldsCurrentOrderCosts.value"),
        },
        {
          key: "created_at",
          label: this.$t("ordersPage.fieldsCurrentOrderCosts.creationDate"),
        },
      ];
    },
  },
  methods: {
    formateDate(value) {
      return value && moment(value).format("DD/MM/YYYY");
    },
  },
};
</script>

<style lang="scss" scoped></style>
