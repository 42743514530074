<template>
  <div>
    <header-title :title="title" />
    <div class="row justify-content-center">
      <div class="col-12 mb-5">
        <div class="card border-white rounded py-4">
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('rejectedOrderViewPage.seller')"
              :val="api_log.seller_name"
              :link="`/users_management/sellers?id=${api_log.seller_id}`"
              link-id=""
            />
            <field-view
              :field="$t('rejectedOrderViewPage.creationDate')"
              :val="api_log.created_at"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('rejectedOrderViewPage.orderInfo')"
              :val="api_log.content"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('rejectedOrderViewPage.erroInfo')"
              :val="api_log.error_info"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderService from "../../../services/OrderService";
import FieldView from "@/components/FieldView";
import HeaderTitle from "@/components/HeaderTitle";

const orderService = new OrderService();

export default {
  name: "OrderLogView",
  components: {
    "field-view": FieldView,
    HeaderTitle,
  },
  data: () => {
    return {
      isVisible: true,
      api_log: {},
      sellerName: "Seller Not found",
    };
  },
  computed: {
    title() {
      return this.$t("rejectedOrderViewPage.title") + this.$route.params.id;
    },
  },
  beforeMount() {
    this.loadOrderLog();
  },
  methods: {
    addZero(number) {
      if (number <= 9) return "0" + number;
      else return number;
    },
    formateDate(date) {
      const data = new Date(date);
      const hours = new Date(date);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    loadOrderLog() {
      orderService.getOrderLog(this.$route.params.id).then((response) => {
        this.api_log = response.data.data;
        this.api_log.created_at = this.formateDate(this.api_log.created_at);
        this.api_log.seller_name =
          "ID." +
          this.api_log.seller_id +
          " --- Name:" +
          this.api_log.seller_name;
      });
    },
  },
};
</script>
