<template>
  <div>
    <NbModal :id="id" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ $t("deliveryMethodsPage.addCooperations") }}
          </h5>
        </div>
      </template>
      <div class="modal-body mb-5">
        <div class="d-flex justify-content-between flex-wrap">
          <NbTextInput
            id="title-cooperation"
            class="w-100 mb-2 mt-2"
            :name="$t('deliveryMethodsPage.cooperationTitle')"
            :placeholder="$t('deliveryMethodsPage.cooperationTitle')"
            :error="errors.title"
            @input="checkCooperationTitle(newCooperation.title)"
            v-model="newCooperation.title"
          />
          <div class="w-100 bg-gray-10 nb-card-simple p-3 my-1">
            <span
              class="link-1 w-100"
              @click="showCooperation = !showCooperation"
            >
              {{ $t("deliveryMethodsPage.cooperationList") }}
            </span>
            <div v-if="showCooperation">
              <div
                v-for="(cooperation, index) in this.contentCooperations"
                :key="cooperation.id"
              >
                <div class="mt-2 border-top border-gray-20 pt-2">
                  {{ cooperation.title }}

                  <NbButton
                    variant="tertiary"
                    icon="trash"
                    @click="deleteCooperation(cooperation.id, index)"
                  >
                    {{ $t("delete") }}
                  </NbButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton @click="addCooperation()">
            {{ newCooperation.id ? $t("update") : $t("save") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";

import CooperationService from "@/services/CooperationService";
const cooperationService = new CooperationService();

export default {
  components: {
    NbModal,
    NbButton,
    NbTextInput,
  },
  name: "ModalCooperationCreate",
  props: {
    id: {
      type: String,
      default: "ModalCooperationCreate",
    },
    cooperation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contentCooperations: [
        {
          name: null,
          id: null,
          integration_credentials: [
            {
              credential: "",
            },
          ],
        },
      ],
      showCooperation: false,
      errors: {},
    };
  },
  created() {
    this.loadCooperations();
  },
  methods: {
    deleteCooperation(cooperationId, index) {
      cooperationService.deleteCooperation(cooperationId).then(() => {
        this.$helpers.closeModal(this.id);
        this.contentCooperations.splice(index, 1);
        this.$emit("reloadCooperation");
      });
    },
    addCooperation() {
      cooperationService
        .addCooperation(this.newCooperation)
        .then((response) => {
          let newCooperation = {
            id: response.data.data.id,
            title: this.newCooperation.title,
          };
          this.$helpers.closeModal(this.id);
          this.contentCooperations.push(newCooperation);
          this.newCooperation.title = "";
          this.$emit("reloadCooperation");
        })
        .catch((error) => {
          this.errors = error.response.data.messages[0];
        });
    },
    checkCooperationTitle(title) {
      const containTitle = this.contentCooperations.some(
        (cooperation) => cooperation.title === title,
      );
      if (containTitle) {
        this.errors.title = [
          this.$t("deliveryMethodsPage.eachCooperationUniqueTitle"),
        ];
        return;
      }
      delete this.errors.title;
    },
    loadCooperations() {
      cooperationService.getCooperations().then((response) => {
        this.contentCooperations = response.data.data;
      });
    },
  },
  computed: {
    newCooperation: {
      get() {
        return this.cooperation;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {},
};
</script>

<style scoped></style>
