<template>
  <div class="">
    <div class="relative fix-top" style="height: 32px">
      <div>
        <!-- filterbuttons -->
        <a
          href="javascript:void(0);"
          class="nb-button button-option"
          :class="`${size}`"
          :aria-expanded="visible ? 'true' : 'false'"
          :aria-controls="pageName"
          @click="visible = !visible"
        >
          <NbIcon
            icon="filter"
            :attributes="{
              width: '1rem',
              height: '1rem',
            }"
          />
          {{ $t("components.tableFilters.title") }}
        </a>
        <div v-if="visible">
          <b-collapse
            :id="pageName"
            class="mt-0 colapse-card"
            :style="`width: ${width}px;`"
            v-model="visible"
            v-click-outside="closeCollapse"
          >
            <b-card class="p-0">
              <h6 class="title-filter">
                {{ $t("components.tableFilters.subTitle") }}.
              </h6>
              <div
                v-for="(filter, index) in filters"
                :key="filter.id"
                class="nb-flex"
              >
                <div class="flex-grow-1 mr-1 w-45">
                  <NbSelectInput
                    :id="`${pageName}selectCol${index}`"
                    size="sm"
                    :options="columOptions"
                    @change="resetFilter(index)"
                    v-model="filter.col"
                  />
                </div>
                <div class="flex-grow-1 mr-1 w-45">
                  <NbSelectInput
                    v-if="
                      filterOptions.find(
                        (option) => option.key === filter.col,
                      ) &&
                      filterOptions.find((option) => option.key === filter.col)
                        .type === 'select'
                    "
                    :id="`${pageName}selectVal${index}`"
                    size="sm"
                    :options="
                      filterOptions.find(
                        (option) => option.key === filter.col,
                      ) &&
                      filterOptions.find((option) => option.key === filter.col)
                        .options
                        ? filterOptions.find(
                            (option) => option.key === filter.col,
                          ).options
                        : options
                    "
                    v-model="filter.val"
                  />
                  <NbSelectInput
                    v-else-if="
                      filterOptions.find(
                        (option) => option.key === filter.col,
                      ) &&
                      filterOptions.find((option) => option.key === filter.col)
                        .type === 'boolean'
                    "
                    :id="`${pageName}selectVal${index}`"
                    size="sm"
                    :options="options"
                    v-model="filter.val"
                  />
                  <DatePicker
                    v-else-if="
                      filterOptions.find(
                        (option) => option.key === filter.col,
                      ) &&
                      filterOptions.find((option) => option.key === filter.col)
                        .type === 'date'
                    "
                    :id="`${pageName}selectVal${index}`"
                    size="sm"
                    @shortDate="filter.val = $event"
                    v-model="filter.date"
                  />
                  <div
                    v-else-if="
                      filterOptions?.find((option) => option.key === filter.col)
                        ?.type === 'dates'
                    "
                    class="d-inline"
                  >
                    <div class="d-inline-block w-48-5 mr-1">
                      <NbBorderTextInput
                        :id="`${pageName}selectVal${index}-start`"
                        size="sm"
                        type="date"
                        :placeholder="$t('startDate')"
                        v-model="filter[startKey(filter.col, 'startDateKey')]"
                      />
                    </div>
                    <div class="d-inline-block w-48-5">
                      <NbBorderTextInput
                        :id="`${pageName}selectVal${index}-end`"
                        size="sm"
                        type="date"
                        :placeholder="$t('endDate')"
                        v-model="filter[endKey(filter.col, 'endDateKey')]"
                      />
                    </div>
                  </div>

                  <div
                    class="d-inline"
                    v-else-if="
                      filterOptions?.find((option) => option.key === filter.col)
                        ?.type === 'money'
                    "
                  >
                    <div class="d-inline-block w-48-5 mr-1">
                      <InputMoney
                        v-model="filter[startKey(filter.col, 'startKey')]"
                        :prefix="
                          filterOptions?.find(
                            (option) => option.key === filter.col,
                          )?.currency || ''
                        "
                      />
                    </div>
                    <div class="d-inline-block w-48-5">
                      <InputMoney
                        v-model="filter[endKey(filter.col, 'endKey')]"
                        :prefix="
                          filterOptions?.find(
                            (option) => option.key === filter.col,
                          )?.currency || ''
                        "
                      />
                    </div>
                  </div>

                  <NbBorderTextInput
                    v-else
                    :id="`${pageName}selectVal${index}`"
                    size="sm"
                    :type="
                      filterOptions?.find((option) => option.key === filter.col)
                        ?.type || 'text'
                    "
                    :placeholder="
                      filterOptions.find(
                        (option) => option.key === filter.col,
                      ) &&
                      filterOptions.find((option) => option.key === filter.col)
                        .label
                        ? `${
                            $t('components.tableFilters.filterBy') +
                            filterOptions.find(
                              (option) => option.key === filter.col,
                            ).label
                          }`
                        : ''
                    "
                    v-model="filter.val"
                  />
                </div>
                <NbButton
                  class="flex-grow-1 mb-1 w-5"
                  variant="tertiary"
                  icon="trash"
                  @click="deleteFilter(index)"
                ></NbButton>
              </div>
              <hr class="mt-4 mb-2" />
              <div class="d-flex justify-content-between">
                <div class="w-64-5">
                  <NbButton
                    variant="tertiary"
                    icon="plus"
                    @click="addNewFilter()"
                    >{{ $t("components.tableFilters.addNewFilter") }}</NbButton
                  >
                </div>
                <div>
                  <NbButton variant="secondary" @click="emitFilter">{{
                    $t("confirm")
                  }}</NbButton>
                </div>
              </div>
            </b-card>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbBorderTextInput from "@/components/input/text/NbBorderTextInput.vue";
import DatePicker from "@/components/generic/DatePicker.vue";
import { directive } from "v-click-outside";
import InputMoney from "../input/money/InputMoney.vue";

export default {
  name: "TableFilters",
  directives: { clickOutside: directive },
  components: {
    NbIcon,
    NbSelectInput,
    NbButton,
    NbBorderTextInput,
    DatePicker,
    InputMoney,
  },
  props: {
    filterOptions: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      default: 436,
    },
    pageName: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
    },
  },
  data: () => {
    return {
      filters: [{ col: "", val: null }],
      newFilter: { col: "", val: null },
      visible: false,
    };
  },
  created() {
    this.loadFiltersNotNested();
  },
  methods: {
    resetFilter(index) {
      this.filters = this.filters.map((filter, i) => {
        if (i === index) {
          return {
            col: filter.col,
            val: null,
          };
        }
        return filter;
      });
    },
    loadFiltersNotNested() {
      let filter = JSON.parse(JSON.stringify(this.newFilter));
      this.filterOptions.forEach((filterOption) => {
        if (filterOption.initialVal) {
          filter.val = filterOption.initialVal;
          filter.col = filterOption.key;
          this.filters.push(filter);
          delete filterOption.initialVal;
        }
      });

      this.emitFilter();
    },
    dismemberDates(selectedFilters) {
      let finalResult = selectedFilters;
      selectedFilters.forEach((filter) => {
        const filterKeys = Object.keys(filter);

        if (filterKeys.length > 2) {
          const startVal = filter[filterKeys[2]];
          const endVal = filter[filterKeys[3]];

          const startDate = {
            col: filterKeys[2],
            val: startVal,
          };
          const endDate = {
            col: filterKeys[3],
            val: endVal,
          };

          finalResult.push(startDate, endDate);
        }
      });

      return finalResult;
    },
    emitFilter() {
      let selectedFilters = JSON.parse(JSON.stringify(this.filters));
      selectedFilters = this.dismemberDates(selectedFilters);

      const finalFilter = selectedFilters.reduce(
        (obj, item) => ((obj[item.col] = item.val), obj),
        {},
      );
      this.$emit("emitFilter", finalFilter);
      this.closeCollapse();
    },
    addNewFilter() {
      const constFilter = JSON.parse(JSON.stringify(this.newFilter));
      this.filters.push(constFilter);
    },
    deleteFilter(index) {
      this.filters.splice(index, 1);
    },
    closeCollapse() {
      this.visible = false;
    },
    alreadySelected(column) {
      return this.filters.some((filter) => {
        return filter.col == column;
      });

      //return false
    },
    startKey(col, key) {
      return (
        this.filterOptions?.find((option) => option.key === col)?.[key] ||
        `start_${col}`
      );
    },
    endKey(col, key) {
      return (
        this.filterOptions?.find((option) => option.key === col)?.[key] ||
        `end_${col}`
      );
    },
  },
  computed: {
    options() {
      return [
        /* { value: null, text: this.$t('orderStatus.selectOption') }, */
        { value: true, text: this.$t("components.tableFilters.trueText") },
        { value: false, text: this.$t("components.tableFilters.falseText") },
      ];
    },
    columOptions() {
      let finalOptions = [];
      this.filterOptions.forEach((option) => {
        finalOptions.push({
          value: option.key,
          text: option.label,
          disabled: this.alreadySelected(option.key),
        });
      });
      return finalOptions;
    },
  },
};
</script>

<style scoped>
.fix-top {
  margin-top: 0.44rem;
}
.nb-button {
  text-decoration: none;
  font-weight: bolder;
  border-radius: 4px;
  padding: 0.44rem 1.19rem;
  font: normal normal 600 16px/24px Nunito Sans;
  transition: 500ms;
}
.nb-button.button-option {
  border: 1px solid var(--gray-05);
  color: var(--black);
  background-color: var(--gray-05);
  transition: 500ms;
}
.nb-button.button-option:hover {
  border: 1px solid var(--gray-10);
  background-color: var(--gray-10);
  color: var(--black);
}
.nb-button.button-option:focus {
  border: 1px solid var(--gray-10);
  background-color: var(--gray-10);
  color: var(--black);
}
.nb-button.button-icon {
  padding: 0.44rem 0.94rem;
}
.nb-button.sm {
  font: normal normal 600 14px/20px Nunito Sans;
  padding: 0.44rem 0.94rem;
  height: 2.25rem;
}
.nb-button.xs {
  font: normal normal 600 14px/20px Nunito Sans;
  padding: 0.3rem 0.5rem;
}

.button-label {
  color: var(--black);
  text-align: center;
  font: normal normal normal 12px/18px Nunito Sans;
}
.card-body {
  z-index: 4;
  background: var(--gray-05);
  box-shadow: 4px 4px 12px var(--gray-10);
  background: #f0f0fa 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  padding: 1rem;
  border-radius: 4px !important;

  border-top: 1px solid var(--gray-10);
  border-right: 2px solid var(--gray-10);
  border-bottom: 2px solid var(--gray-10);
  border-left: 2px solid var(--gray-10);
}
.title-filter {
  color: var(--black);
  font: normal normal normal 12px/18px Nunito Sans;
  letter-spacing: 0px;
}
.nb-flex {
  flex-wrap: wrap !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.card {
  border-top: 0px !important;
  border-right: 1px solid var(--gray-05) !important;
  border-bottom: 1px solid var(--gray-05) !important;
  border-left: 1px solid var(--gray-05) !important;
  color: black !important;
  border-radius: 0px 0px 4px 4px;
  background: transparent;
  flex-direction: column;
  justify-content: center;
}
.relative {
  position: relative;
}
.button-options {
  text-align: center;
  width: 100%;
  padding: 0.4rem;
  border-top: 1px solid var(--gray-10);
}
.button-options:hover {
  background-color: var(--gray-10);
}
.colapse-card {
  position: absolute;
  top: 2.3rem;
  right: 0;
  width: 100%;
}

.border-top {
  border: 1px solid var(--gray-10);
}
</style>
