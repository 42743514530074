<template>
  <div class="template">
    <side-bar />
    <content-view />
  </div>
</template>

<script>
import ContentView from "../components/ContentView.vue";
import SideBar from "../components/SideBar.vue";

export default {
  name: "BaseTemplate",
  components: { ContentView, SideBar },
  data: function () {
    return {};
  },
};
</script>

<style>
.fc-widget-normal {
  right: 120px !important;
  bottom: 3px !important;
}

.template {
  display: flex;
}
</style>
