<template>
  <Modal v-model="isConfirmModalOpen">
    <ModalHeader>
      <ModalTitle>{{ title }}</ModalTitle>
    </ModalHeader>

    <slot></slot>

    <ModalFooter class="d-flex justify-content-end gap-2">
      <ModalClose />
      <NbButton @click="$emit('action')">
        {{ actionTitle }}
      </NbButton>
    </ModalFooter>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  components: {
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    actionTitle: {
      type: String,
      default: "",
    },
  },
  computed: {
    isConfirmModalOpen: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
