<template>
  <div>
    <Datatable :columns="columns" :data="checkpoints">
      <template #date_iso="{ row }">
        {{ row.date_iso | datetime }}
      </template>
      <template #checkpoint.tracking_code="{ row }">
        <router-link
          v-if="row?.checkpoint?.tracking_code"
          :to="`/checkpoints/checkpoints?volumeCheckpointId=${row.id}`"
          class="link-1"
        >
          {{ row?.checkpoint?.tracking_code }}
        </router-link>
      </template>

      <template #received_checkpoint.tracking_function="{ row }">
        {{
          row?.received_checkpoint?.tracking_function
            | removeTrackingFunctionPrefix
        }}
      </template>

      <template #message="{ row }">
        {{ row?.message || "-" }}
      </template>

      <template #checkpoint.title="{ row }">
        <router-link
          v-if="row.checkpoint?.title"
          :to="`/checkpoints/checkpoints?volumeCheckpointId=${row.id}`"
          class="link-1"
        >
          {{ row.checkpoint.title }}
        </router-link>
      </template>

      <template #status_code="{ row }">
        <router-link
          v-if="row?.received_checkpoint?.status_code"
          :to="`/checkpoints/checkpoints?receivedCheckpointId=${row.received_checkpoint.id}`"
          class="link-1"
        >
          {{ row.received_checkpoint.status_code }}
        </router-link>
      </template>
    </Datatable>
  </div>
</template>

<script>
import Datatable from "@/components/datatable/Datatable.vue";

export default {
  props: {
    checkpoints: {
      type: Array,
      required: true,
    },
  },
  filters: {
    removeTrackingFunctionPrefix(val) {
      if (!val) {
        return "-";
      }

      return val.replace("fetch_", "");
    },
  },
  components: {
    Datatable,
  },
  computed: {
    columns() {
      return [
        {
          key: "date_iso",
          label: this.$t("volumesViewPage.checkpointFields.dateISO"),
        },
        {
          key: "checkpoint.title",
          label: this.$t("volumesViewPage.checkpointFields.title"),
        },
        {
          key: "checkpoint.tracking_code",
          label: "Tracking Code",
        },
        {
          key: "received_checkpoint.tracking_function",
          label: this.$t("volumesViewPage.provider"),
        },
        {
          key: "status_code",
          label: this.$t("volumesViewPage.providerStatus"),
        },

        {
          key: "message",
          label: this.$t("volumesViewPage.providerMessage"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
