<template>
  <NbCard id="review" title="Review">
    <template #body>
      <div class="grid grid-cols-3 gap-4">
        <div
          v-for="option in sellerOptions"
          :key="option.text"
          class="d-flex align-items-center gap-2"
        >
          <NbIcon
            :class="option.iconClass ?? ''"
            size="24"
            :icon="option.icon"
          />
          <div>
            <strong>
              {{ option.label }}
            </strong>
            <span
              v-if="option.value"
              :class="['d-block', option.customClass]"
              >{{ option.value }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-span-4 d-flex justify-content-end">
        <div>
          <strong>{{ $t("sellersPage.fields.creationDate") }}</strong>
          <span class="d-block">
            {{ seller.created_at | dateTime }}
          </span>
        </div>
      </div>
    </template>
  </NbCard>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import { currency } from "@/utils/filters/currency.js";
import moment from "moment";

export default {
  props: {
    seller: {
      type: Object,
      required: true,
    },
  },
  components: {
    NbCard,
    NbIcon,
  },
  filters: {
    dateTime(value) {
      return value && moment(value).format("DD/MM/YYYY HH:mm");
    },
  },
  computed: {
    sellerOptions() {
      return [
        {
          label: this.$t("sellersViewPage.sellerID"),
          value: this.seller.id,
          icon: "user",
        },
        {
          label: this.$t("sellersViewPage.website"),
          value: this.seller.site ?? "-",
          icon: "globe",
        },
        {
          label: this.$t("sellersViewPage.taxNumber"),
          value: this.seller.tax_number || "-",
          icon: "hash",
        },
        {
          label: this.$t("sellersViewPage.currentBalance"),
          value: `${
            this.seller.current_balance > 0 ? "+" : ""
          } ${currency.format(this.seller.current_balance ?? 0)}`,
          customClass:
            this.seller.current_balance > 0 ? "text-success" : "text-danger",
          icon: "dollar-sign",
        },
        {
          label: this.$t("sellersPage.languagePreferred"),
          value: this.seller?.seller_setting?.language || "-",
          icon: "settings",
        },
        {
          label: this.$t("sellersPage.measurementSystem"),
          value: this.seller?.seller_setting?.measure_mode || "-",
          icon: "crop",
        },
        {
          label: this.$t("sellersPage.validateCpf"),
          icon: this.seller?.seller_setting?.cpf_validation ? "check" : "x",
          iconClass: this.seller?.seller_setting?.cpf_validation
            ? "text-success"
            : "text-danger",
        },
        {
          label: this.$t("sellersViewPage.ismultiple"),
          icon: this.seller.is_multiple ? "check" : "x",
          iconClass: this.seller.is_multiple ? "text-success" : "text-danger",
        },
        {
          label: this.$t("sellersPage.fields.isCarrier"),
          icon: this.seller.is_carrier ? "check" : "x",
          iconClass: this.seller.is_carrier ? "text-success" : "text-danger",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0 16px;
  td {
    padding-left: 2rem;
  }
  thead:after {
    border: none;
  }
  & + table {
    margin-top: 2rem;
  }
}
</style>
