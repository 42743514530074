<template>
  <div>
    <button
      class="btn-select-platform d-flex align-items-center"
      @click="$helpers.openModal('selectPlatform')"
    >
      <span class="mr-2">{{ $t("platform") }}:</span>
      <div class="animate-pulse" v-if="loading"></div>
      <strong v-else>{{ currentPlatform }}</strong>
    </button>

    <Teleport to="#teleport">
      <NbModal id="selectPlatform">
        <template #header>
          <NbPageTitle :title="$t('components.topBar.platformList')" />
        </template>
        <template #body>
          <div class="platforms">
            <div v-for="platform in items" :key="platform.name">
              <input
                type="radio"
                :id="`platform-${platform.id}`"
                :value="platform.id"
                v-model="platformId"
              />
              <label :for="`platform-${platform.id}`">
                {{ platform.name }}
              </label>
            </div>
          </div>
        </template>

        <template #footer>
          <div class="d-flex justify-content-between">
            <NbButton
              variant="secondary"
              data-dismiss="modal"
              aria-label="Close"
            >
              {{ $t("cancel") }}
            </NbButton>
            <NbButton
              :disabled="platformId === null"
              @click="changePlatform(platformId)"
            >
              {{ $t("save") }}
            </NbButton>
          </div>
        </template>
      </NbModal>
    </Teleport>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import PlatformService from "@/services/SuperPlatformService";
import Teleport from "./Teleport.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";

const platformsService = new PlatformService();

export default {
  components: {
    NbModal,
    NbButton,
    Teleport,
    NbPageTitle,
  },
  data: () => {
    return {
      namespace: "platforms",
      currentPlatform: null,
      platformId: null,
      loading: false,
    };
  },
  computed: {
    items() {
      let platforms = this.$store.state[this.namespace].all_items.slice();
      let tempPlat = [
        {
          id: 0,
          name: "All",
        },
      ];
      return platforms.concat(tempPlat);
    },
  },
  beforeMount() {
    this.getCurrentPlatform();
  },
  methods: {
    changePlatform(id) {
      platformsService
        .setCurrentPlatform(id)
        .then(() => {
          this.getCurrentPlatform();
          this.$store.dispatch("fetchMainData");
          this.$helpers.closeModal("selectPlatform");
        })
        .catch(() => {});
    },
    getCurrentPlatform() {
      this.loading = true;
      platformsService
        .getCurrentPlatform()
        .then((response) => {
          this.currentPlatform = response.data.data;

          this.platformId = this.items?.find(
            (item) => item.name === response.data.data,
          )?.id;
        })
        .catch(() => {
          this.currentPlatform = "All";
          this.platformId = 0;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-select-platform {
  background-color: transparent;
  outline: none;
  background-color: var(--gray-05);
  color: var(--black);
  padding: 0.5rem;
  border: none;
  height: 32px;
  font-size: 0.75rem;
  transition: all 0.3s ease-in;
  border-radius: 4px;
  &:hover {
    background-color: var(--gray-10);
  }
  strong {
    text-transform: capitalize;
  }
}
.platforms {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  input[type="radio"] {
    display: none;
    pointer-events: none;
    &:checked + label {
      border-color: var(--gray-30);
      color: var(--black);
    }
  }
  label {
    border: 1px solid var(--gray-10);
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 5rem;
    text-transform: capitalize;
    color: var(--gray-30);
    cursor: pointer;
  }
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  width: 4rem;
  height: 1rem;
  background-color: var(--gray-10);
  border-radius: 4px;
}
</style>
