import api from "./HttpService";

export default class VolumeService {
  /**
   * Get volume by id
   *
   * @param {*} id
   * @returns {Promise}
   */
  getVolume(id = null) {
    return api.get("/v1/volumes/" + id);
  }

  /**
   * Get Twenty volumes
   * @param track
   * @param offset = number, track = filters
   * @returns {Promise}
   */

  getTwentyVolumes(offset, limit, track) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
    } else {
      offset = (offset - 1) * 20;
    }
    track["offset"] = offset;
    track["limit"] = limit;
    return api.post("/v1/volumes", track);
  }

  downloadVolumes(data) {
    return api.post("/v1/volumes/download", data);
  }

  /**
   * Download volumes by id and cols
   * @param data = Object, { volumes_id: [], columns: [] }
   * @returns {Promise}
   */
  downloadMsds(order_id) {
    api.get("/v1/volumes/download_msds/" + order_id);
  }

  /**
   * Get volume checkpoint by id
   *
   * @param {*} id
   * @returns {Promise}
   */
  getVolumeCheckpoint(id = null) {
    return api.get("/v1/volume_checkpoints/" + id);
  }

  getVolumeCheckpointTrackingNotifications(id) {
    return api.get(`/v1/volume_checkpoints/${id}/tracking_notifications`);
  }

  updateLastMileTrackingNumber(id, lmtn) {
    return api.put("/v1/volumes/" + id + "/update_lmtn", {
      last_mile_tracking_number: lmtn,
    });
  }

  /**
   * Get Twenty volume checkpoints
   *
   * @param offset = number, track = filters
   * @returns {Promise}
   */
  getTwentyVolumeCheckpoints(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/volume_checkpoints`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/volume_checkpoints?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }
}
