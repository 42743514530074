import { render, staticRenderFns } from "./RatingStars.vue?vue&type=template&id=24f0a412&scoped=true"
import script from "./RatingStars.vue?vue&type=script&lang=js"
export * from "./RatingStars.vue?vue&type=script&lang=js"
import style0 from "./RatingStars.vue?vue&type=style&index=0&id=24f0a412&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24f0a412",
  null
  
)

export default component.exports