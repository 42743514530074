<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("sellersPage.addseller") }}
      </ModalTitle>
    </ModalHeader>

    <div class="grid gap-4">
      <NbCard
        id="carrier"
        :title="$t('sellersPage.carrierSelect')"
        :help-text="$t('sellersPage.fillWithCarrierPopup')"
      >
        <template #body>
          <Spinner size="md" class="mx-auto" v-if="loadingCarriers" />
          <div v-else class="d-flex align-items-end gap-2">
            <NbSelectInput
              :name="$t('sellersPage.carriers')"
              id="carriers"
              v-model="selectedCarrierId"
              :options="carriersOptions"
              class="flex-grow-1"
            />
            <NbButton
              class="mb-1"
              :disabled="!selectedCarrierId"
              @click="fillCarrierToSellerData"
            >
              Fill
            </NbButton>
          </div>
        </template>
      </NbCard>

      <NbCard id="logo" :title="$t('sellersViewPage.includeLogo')">
        <template #body>
          <NbDropFile
            v-model="logo.file"
            :accept="['image/jpg', 'image/png', 'image/jpeg']"
            @input="onInputLogo"
          >
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <div class="file-preview mb-4">
                <div v-if="logo.url">
                  <img :src="logo.url" />
                </div>
                <div class="icon p-4" v-else>
                  <NbIcon icon="plus" :size="64" />
                </div>
              </div>
              <small v-if="logo.url" class="mx-auto">
                {{ logo.file.name }}
              </small>
            </div>

            <div class="text-center p-2">
              <p class="body-4">
                {{ $t("dragDropFile") }}
              </p>
              <small>image/jpg, image/png, image/jpeg</small>
            </div>
          </NbDropFile>
        </template>
      </NbCard>

      <NbCard id="general-data" :title="$t('generalData')">
        <template #body>
          <div class="grid gap-4 grid-cols-2">
            <NbTextInput
              v-model="form.name"
              id="form.name"
              :name="$t('sellersPage.name')"
              :error="[errors.get('name')]"
              required
            />

            <NbTextInput
              v-model="form.phone"
              id="form.phone"
              :name="$t('sellersPage.phone')"
              :error="[errors.get('phone')]"
            />

            <NbTextInput
              v-model="form.email"
              id="form.email"
              :name="$t('sellersPage.email')"
              :error="[errors.get('email')]"
              required
            />

            <NbTextInput
              v-model="form.site"
              id="form.site"
              :name="$t('sellersPage.site')"
              :error="[errors.get('site')]"
              required
            />

            <NbTextInput
              v-model="form.tax_number"
              id="form.tax_number"
              :name="$t('sellersPage.taxnumber')"
              :error="[errors.get('tax_number')]"
            />

            <NbTextInput
              v-model="form.legal_name"
              id="form.legal_name"
              :name="$t('sellersPage.legalName')"
              :error="[errors.get('legal_name')]"
            />

            <NbTextInput
              v-model="form.rfb_code"
              id="form.rfb_code"
              :name="$t('sellersPage.rfbCode')"
              :error="[errors.get('rfb_code')]"
              placeholder="RFB(Receita Federal Brasileira) code .."
            />

            <NbTextInput
              v-model="form.billing_email"
              id="form.billing_email"
              :name="$t('sellersPage.billingEmail')"
              :error="[errors.get('billing_email')]"
              placeholder="RFB(Receita Federal Brasileira) code .."
            />

            <NbTextInput
              v-model="form.responsable_contact"
              id="form.responsable_contact"
              :name="$t('sellersPage.responsableContact')"
              :error="[errors.get('responsable_contact')]"
              class="col-span-2"
            />

            <div class="col-span-2 grid grid-cols-8">
              <NbSwitch
                v-model="form.is_multiple"
                id="form.is_multiple"
                :name="$t('sellersPage.ismultiple')"
              />
              <NbSwitch
                v-model="form.is_carrier"
                id="form.is_carrier"
                :name="$t('sellersPage.fields.isCarrier')"
              />
            </div>

            <!-- <div class="col-span-2">
              <NbDropFile
                v-model="signature"
                :label="$t('sellersPage.signature')"
              />
            </div> -->
          </div>
        </template>
      </NbCard>

      <NbCard id="address" :title="$t('sellersPage.address')">
        <template #body>
          <div class="grid grid-cols-2 gap-4">
            <NbTextInput
              v-model="form.address"
              id="form.address"
              :name="$t('sellersPage.address')"
              :error="[errors.get('address')]"
              required
            />

            <NbTextInput
              v-model="form.address_number"
              id="form.address_number"
              :name="$t('sellersPage.addressnumber')"
              :error="[errors.get('address_number')]"
            />
            <NbTextInput
              v-model="form.address_complement"
              id="form.address_complement"
              :name="$t('sellersPage.addresscomplement')"
              :error="[errors.get('address_complement')]"
            />

            <NbTextInput
              v-model="form.city"
              id="form.city"
              :name="$t('sellersPage.city')"
              :error="[errors.get('city')]"
              required
            />
            <NbTextInput
              v-model="form.zip_code"
              id="form.zip_code"
              :name="$t('sellersPage.postalcode')"
              :error="[errors.get('zip_code')]"
              required
            />

            <NbTextInput
              v-model="form.state"
              id="form.state"
              :name="$t('sellersPage.state')"
              :error="[errors.get('state')]"
            />

            <NbSelectInput
              v-model="form.country"
              :options="countriesOptions"
              :name="$t('sellersPage.country')"
              id="form.country"
              :error="[errors.get('country')]"
              required
            />
          </div>
        </template>
      </NbCard>

      <NbCard
        id="contracts"
        :title="$t('sellersPage.allowedcontracts')"
        :help-text="$t('sellersPage.contractsPopup')"
      >
        <template #body>
          <Spinner size="md" class="mx-auto" v-if="loadingContracts" />
          <template v-else>
            <NbSelecMultipletInput
              v-model="form.allowed_contracts"
              id="selectedContracts"
              :name="$t('components.searchData.contracts')"
              :options="contractsOptions"
            />
          </template>
        </template>
      </NbCard>
    </div>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose class="mr-2" />
      <NbButton
        @click="create"
        :disabled="loading || loadingCarriers || loadingContracts"
      >
        {{ $t("save") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbSwitch from "@/components/buttons/NbSwitch.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbSelecMultipletInput from "@/components/input/select/NbSelecMultipletInput.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import Errors from "@/utils/Errors.js";
import CarrierService from "@/services/CarrierService";
import ContractService from "@/services/ContractService";
import Spinner from "@/components/Spinner.vue";
import SellerService from "@/services/SellerService";
import CountryService from "@/services/CountryService";
import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

const contractService = new ContractService();
const carrierService = new CarrierService();
const sellersService = new SellerService();
const countryService = new CountryService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbCard,
    NbSelectInput,
    NbTextInput,
    NbDropFile,
    NbSwitch,
    NbSelecMultipletInput,
    Spinner,
    NbIcon,
  },
  inject: ["context"],
  data() {
    return {
      selectedCarrierId: null,
      carriersOptions: [],
      countriesOptions: [],
      contractsOptions: [],
      errors: new Errors(),
      signature: "",
      loading: false,
      loadingContracts: false,
      loadingCarriers: false,
      logo: {
        file: "",
        url: "",
      },
      form: {
        address: "",
        address_number: "",
        address_complement: "",
        city: "",
        zip_code: "",
        state: "",
        country: "",
        name: "",
        email: "",
        phone: "",
        site: "",
        tax_number: "",
        rfb_code: "",
        legal_name: "",
        is_active: true,
        is_carrier: false,
        is_multiple: false,
        users: [],
        allowed_contracts: [],
      },
    };
  },
  methods: {
    onInputLogo(file) {
      if (!file) {
        this.logo.url = "";
      }
      const reader = new FileReader();

      reader.onload = (data) => {
        this.logo.url = data.target.result;
      };

      reader.readAsDataURL(file);
    },
    async create() {
      try {
        this.loading = true;
        const { data } = await sellersService.addSeller(this.form);
        if (this.logo.url) {
          await sellersService.uploadCompanyLogo(data.data.id, this.logo.file);
        }
        this.$emit("refresh");
        this.context?.close();
      } catch (error) {
        if (error?.response?.data?.messages?.length) {
          this.errors.record(error.response.data.messages?.[0]);
        }
      } finally {
        this.loading = false;
      }
    },
    async setCountriesOptions() {
      const {
        data: { data },
      } = await countryService.getCountries({
        limit: 0,
        offset: 1000,
      });
      this.countriesOptions =
        data?.map((country) => ({
          text: country.name,
          value: country.alpha2_code,
        })) || [];
    },
    setCarrierOptions() {
      this.carriersOptions =
        this.carriers.map((carrier) => ({
          value: carrier.id,
          text: `(${carrier.id}) ${carrier.name}`,
        })) || [];
    },
    async loadCarriers() {
      try {
        this.loadingCarriers = true;
        const { data } = await carrierService.getCarriers();
        this.carriers = data?.data?.elements || [];
        this.setCarrierOptions();
      } finally {
        this.loadingCarriers = false;
      }
    },
    async loadContracts() {
      try {
        this.loadingContracts = true;
        const { data } = await contractService.getContracts();
        this.contractsOptions = data.data?.map((item) => ({
          text: item.slug,
          value: item.id,
        }));
      } finally {
        this.loadingContracts = false;
      }
    },
    fillCarrierToSellerData() {
      if (this.selectedCarrierId) {
        const carrier = this.carriers.find(
          (carrier) => carrier.id === this.selectedCarrierId,
        );

        this.form = {
          ...this.form,
          address: carrier.address,
          address_number: carrier.address_number,
          address_complement: carrier.address_complement,
          city: carrier.city,
          zip_code: carrier.zip_code,
          state: carrier.state,
          country: carrier.country,
          name: carrier.name,
          email: carrier.email,
          phone: carrier.phone,
          site: carrier.site,
          tax_number: carrier.tax_number,
          rfb_code: carrier.rfb_code,
          legal_name: carrier.legal_name,
          users: carrier.unarchived_users,
          is_active: carrier.is_active,
          is_carrier: true,
        };
      }
    },
    async loadData() {
      this.setCountriesOptions();
      this.loadContracts();
      this.loadCarriers();
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.file-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  border: 1px dashed var(--gray-30);
  height: 250px;
  width: 350px;
  text-align: center;
  object-fit: contain;
  border-radius: 6px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .icon {
    color: var(--gray-60);
  }
}
</style>
