<template>
  <div class="" :class="error[0] ? 'is-invalid' : ''">
    <label v-if="name" class="input-label">
      {{ name }} <span>{{ required && "*" }}</span></label
    >

    <money
      v-bind="money"
      :class="[`form-control input mr-2 ${size}`, { 'is-invalid': !!error[0] }]"
      v-model="modelValue"
    ></money>
    <ErrorFeedback :error="error[0]" />
  </div>
</template>

<script>
import { Money } from "v-money";
import ErrorFeedback from "@/components/generic/ErrorFeedback.vue";

export default {
  props: {
    decimal: {
      type: String,
      default: ",",
    },
    thousands: {
      type: String,
      default: ".",
    },
    prefix: {
      type: String,
      default: "R$ ",
    },
    precision: {
      type: Number,
      default: 2,
    },
    masked: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
    size: {
      type: String,
      default: "sm",
    },
  },
  components: {
    Money,
    ErrorFeedback,
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit("input", data);
      },
    },
    money() {
      return {
        decimal: this.decimal,
        thousands: this.thousands,
        prefix: this.prefix,
        precision: this.precision,
        masked: this.masked,
      };
    },
  },
};
</script>

<style scoped lang="scss">
// .form-control.input:not(:placeholder-shown),
// .form-control.input.is-invalid:not(:placeholder-shown) {
//   border: 1px solid #000000;
// }
.form-control.input {
  //height: calc(2em + 0.75rem + 2px);
  height: 2.5rem;
  box-shadow: none;
  border: 1px solid var(--gray-30);
  border-radius: 4px;
  font: normal normal normal 16px/20px Nunito Sans;
  padding: 9px 12px;
}
.form-control.input:hover {
  background: #f2f2f2 0% 0% no-repeat padding-box;
}
.form-control.input:focus {
  box-shadow: none;
  border: 1px solid #000000;
}
.form-control.input::placeholder {
  font: normal normal normal 16px/28px Nunito Sans;
  color: var(--gray-40);
}

.form-control.input.is-invalid {
  border: 1px solid var(--danger);
  background-image: none;
}
.form-control.input.is-invalid:hover {
  background: #f2f2f2 0% 0% no-repeat padding-box;
}
.form-control.input:disabled,
.form-control.input[disabled] {
  background-color: var(--gray-20) !important;
  color: var(--gray-30) !important;
  border: 1px solid var(--gray-20) !important;
}
.input-label {
  font: normal normal normal 16px/28px Nunito Sans;
}

.form-control.input.sm {
  //height: calc(2em + 0.75rem + 2px);
  height: 1.75rem;
  font: normal normal 600 12px/16px Nunito Sans;
  letter-spacing: 0px;
  padding: 9px 12px;
}
.form-control.input.sm::placeholder {
  font: normal normal 600 12px/16px Nunito Sans;
  color: var(--gray-40);
}
</style>
