<template>
  <div>
    <Spinner v-if="isLoadingContract" class="mx-auto" size="lg" />
    <div v-else class="grid grid-cols-2 gap-4">
      <router-link
        :to="`/users_management/sellers?id=${order.seller_id}`"
        class="link-1"
      >
        <NbTextInput
          disabled
          variant="borderless-gray-60"
          :name="$t('seller')"
          v-model="order.seller_name"
          id="order.seller_name"
        />
      </router-link>
      <router-link
        :to="`/contracts/contracts?contractId=${order.contract_id}`"
        class="link-1"
      >
        <NbTextInput
          disabled
          variant="borderless-gray-60"
          :name="$t('contract')"
          :value="contract?.name"
          id="contract.name"
        />
      </router-link>

      <NbInputView :name="$t('ordersPage.fields.currentStatus')">
        <div>
          <router-link
            v-if="order.current_status?.id"
            :to="`/checkpoints/checkpoints?nobordistCheckpointId=${order.current_status?.id}`"
            class="link-1"
          >
            <span>
              {{ order.current_status.title }}
            </span>
          </router-link>
          <div v-else class="input-view d-flex">
            <span>-</span>
          </div>
        </div>
      </NbInputView>

      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('ordersViewPage.salesChannel')"
        v-model="order.sales_channel_name"
        id="sales_channel"
        placeholder="-"
      />
    </div>
  </div>
</template>

<script>
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbInputView from "@/components/input/text/NbInputView.vue";
import Spinner from "@/components/Spinner.vue";
import ContractService from "@/services/ContractService";

const contractService = new ContractService();

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    NbTextInput,
    NbInputView,
    Spinner,
  },
  data() {
    return {
      contract: {},
      isLoadingContract: false,
    };
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
    async loadContract() {
      try {
        this.isLoadingContract = true;
        if (this.order?.contract_id) {
          const { data } = await contractService.getOneContract(
            this.order.contract_id,
          );
          this.contract = data.data;
        }
      } finally {
        this.isLoadingContract = false;
      }
    },
  },
  created() {
    this.loadContract();
  },
};
</script>

<style lang="scss" scoped></style>
