var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NbPageTitle',{staticClass:"mb-4",attrs:{"title":_vm.$t('downloadsPage.title'),"subTitle":_vm.$t('downloadsPage.subtitle')}}),_c('Datatable',{staticClass:"mt-5",attrs:{"columns":_vm.tableColumns,"data":_vm.downloads},scopedSlots:_vm._u([{key:"file_type",fn:function({ row }){return [(row.data_type == 'order_import')?_c('span',[_vm._v("Upload")]):_c('span',[_vm._v("Download")])]}},{key:"status",fn:function({ row }){return [_c('div',[_c('NbBadge',{staticClass:"fit-content",attrs:{"type":_vm.badgeType(row.status),"text":_vm.badgeText(row.status)}})],1)]}},{key:"file_name",fn:function({ row }){return [_c('span',[_vm._v(_vm._s(row.file_name ? row.file_name : "-"))])]}},{key:"errors",fn:function({ row }){return [(
          row.df_errors.length > 0 &&
          !row.file_name.includes('volumes_mass_update')
        )?_c('div',{staticClass:"link-1",attrs:{"variant":"danger","title":"Show Errors","data-toggle":"modal","data-target":"#showErrors"},on:{"click":function($event){_vm.currentErrors = row.df_errors}}},[_vm._v(" "+_vm._s(_vm.$t("downloadsPage.showErrors"))+" ")]):_vm._e()]}},{key:"download",fn:function({ row }){return [(
          row.status == 'failed' &&
          row.file_name.includes('volumes_mass_update')
        )?_c('NbButton',{staticClass:"d-flex align-items-center gap-1",attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.downloadFiles(row.id)}}},[_c('NbIcon',{attrs:{"icon":"arrow-down","size":18}}),_vm._v(" "+_vm._s(_vm.$t("downloadsPage.downloadReport")))],1):(
          !(row.data_type == 'volumes_mass_update') && row.status == 'done'
        )?_c('NbButton',{staticClass:"d-flex align-items-center gap-1",attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.downloadFiles(row.id)}}},[_c('NbIcon',{attrs:{"icon":"arrow-down","size":18}}),_vm._v(" "+_vm._s(_vm.$t("downloadsPage.downloadReport")))],1):_vm._e()]}}])}),_c('NbModal',{attrs:{"id":"showErrors","modalConfig":"modal-dialog-centered","width":"46rem"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex mb-4"},[_c('h5',{staticClass:"heading-4 ml-3 mb-1"},[_vm._v(_vm._s(_vm.$t("downloadsPage.errors")))])])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('NbButton',{attrs:{"variant":"secondary","data-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")])],1)]},proxy:true}])},[_c('div',_vm._l((_vm.currentErrors),function(currentError){return _c('div',{key:currentError,staticClass:"border-bottom pb-2 mb-2"},[_vm._v(" - "+_vm._s(currentError)+" ")])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }