<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("ordersViewPage.editOrder") }}
      </ModalTitle>
    </ModalHeader>

    <form>
      <div class="grid grid-cols-2 gap-4">
        <NbTextInput
          id="seller_tax_number"
          v-model="form.seller_tax_number"
          :name="$t('ordersPage.fields.sellerTaxNumber')"
          :error="errors.seller_tax_number || errors.shipper_tax_number"
          required
        />
        <NbTextInput
          id="customer_address"
          v-model="form.customer_address"
          :name="$t('ordersViewPage.address')"
          :error="errors.customer_address"
          required
        />
        <NbTextInput
          id="customer_city"
          v-model="form.customer_city"
          :name="$t('ordersViewPage.city')"
          :error="errors.customer_city"
          required
        />
        <NbTextInput
          id="customer_address_complement"
          v-model="form.customer_address_complement"
          :name="$t('ordersViewPage.addressComplement')"
          :error="errors.customer_address_complement"
        />
        <NbTextInput
          id="customer_address_number"
          v-model="form.customer_address_number"
          :name="$t('ordersViewPage.addressNumber')"
          :error="errors.customer_address_number"
        />
        <NbTextInput
          id="customer_postal_code"
          v-model="form.customer_postal_code"
          :name="$t('ordersViewPage.postalCode')"
          :error="errors.customer_postal_code"
          required
        />
        <NbTextInput
          id="customer_state"
          v-model="form.customer_state"
          :name="$t('ordersViewPage.state')"
          :error="errors.customer_state"
          required
        />
      </div>

      <hr class="my-4" />

      <div class="mt-4">
        <h4 class="heading-4">Volumes</h4>
        <span class="text-danger" v-if="errors && errors.volumes_count">
          {{ errors.volumes_count[0] }}
        </span>
        <span class="text-danger" v-if="errors && errors.tracking_code">
          {{ errors.tracking_code[0] }}
        </span>
        <table>
          <thead class="top-auto">
            <tr>
              <th class="p-3">ID</th>
              <th class="p-3">Last Mile Tracking Number</th>
              <th class="p-3">{{ $t("ordersViewPage.freightCost") }}</th>
              <th class="p-3">
                {{ $t("ordersViewPage.volumesFields.Weight") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(volume, index) in form.volumes_attributes">
              <tr :key="index">
                <td>{{ volume.id }}</td>
                <td>{{ volume.last_mile_trackin_number }}</td>
                <td>
                  <NbTextInput
                    id="freight_value"
                    v-model="volume.freight_cost"
                    type="money"
                    :prepend="order.currency"
                    :decimals="2"
                    step="0.01"
                    placeholder="0.00"
                  />
                </td>
                <td>
                  <NbTextInput
                    id="weight"
                    v-model="volume.weight"
                    :error="errors.weight"
                    type="money"
                    :decimals="3"
                    step="0.001"
                    placeholder="0.000"
                  />
                </td>
              </tr>
              <tr :key="`error-${index}`">
                <td colspan="2"></td>
                <td>
                  <span class="text-danger">
                    {{ getError(errors[`volumes[${index}].freight_cost`]) }}
                  </span>
                </td>
                <td>
                  <span class="text-danger">
                    {{ getError(errors[`volumes[${index}].weight`]) }}
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </form>

    <ModalFooter class="d-flex justify-content-end gap-4">
      <ModalClose />
      <NbButton :disabled="loading" @click="updateOrder()">
        {{ $t("save") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import OrderLabelService from "@/services/OrderLabelService";

const orderLabelService = new OrderLabelService();

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbTextInput,
    NbButton,
  },
  data() {
    return {
      loading: false,
      errors: [],
      form: {
        seller_tax_number: "",
        customer_address: "",
        customer_city: "",
        customer_address_complement: "",
        customer_address_number: "",
        customer_postal_code: "",
        customer_state: "",
        volumes_attributes: [],
      },
    };
  },
  methods: {
    getError(data) {
      if (Array.isArray(data)) {
        const flatten = data.flat(Infinity);

        return flatten[0];
      }

      if (typeof data === "string") {
        return data;
      }

      return;
    },
    async updateOrder() {
      try {
        this.loading = true;
        await orderLabelService.updateOrder(this.order.id, this.form);
        this.$emit("success", this.form);
      } catch (error) {
        this.errors = error.response.data.messages[0];
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.form = {
      seller_tax_number: this.order?.seller_tax_number ?? "",
      customer_address: this.order?.customer_address ?? "",
      customer_city: this.order?.customer_city ?? "",
      customer_address_complement:
        this.order?.customer_address_complement ?? "",
      customer_address_number: this.order?.customer_address_number ?? "",
      customer_postal_code: this.order?.customer_postal_code ?? "",
      customer_state: this.order?.customer_state ?? "",
      volumes_attributes:
        this.order?.volumes?.map((volume) => ({
          id: volume.id,
          freight_cost: volume.freight_cost,
          weight: volume.weight,
        })) ?? [],
    };
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}
</style>
