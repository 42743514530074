<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("userProfilePage.currentPass") }}
      </ModalTitle>
    </ModalHeader>
    <div class="mb-4">
      <p>{{ $t("userProfilePage.confirmCurrentPassword") }}</p>
      <NbPassInput
        required
        id="reset-email"
        :name="$t('userProfilePage.currentPass')"
        class="mb-2"
        autocomplete="off"
        :placeholder="$t('userProfilePage.currentPass')"
        :error="[error]"
        v-model="modelValue"
      />
    </div>
    <ModalFooter class="d-flex justify-content-end gap-2">
      <ModalClose />
      <NbButton
        class="d-flex align-items-center gap-2"
        @click="handleSubmit"
        :disabled="loading || !modelValue"
      >
        <Spinner v-if="loading" />
        {{ $t("save") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbPassInput from "@/components/input/text/NbPassInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbPassInput,
    NbButton,
    Spinner,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss" scoped></style>
