<template>
  <div>
    <NbModal
      :id="id"
      modalConfig="modal-dialog-centered"
      @close="$emit('close')"
    >
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ $t("carriersViewPage.updateAirportHub") }}
          </h5>
        </div>
      </template>
      <div class="modal-body mb-4">
        <div class="d-flex justify-content-between flex-wrap">
          <NbTextInput
            id="airportHub-new"
            class="w-100 my-2"
            :name="$t('carriersViewPage.airportHub')"
            :error="errors.airport_code"
            v-model="newBody.airport_code"
          />
        </div>
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
            @click="$emit('close')"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton @click="updateAirportHub()">
            {{ $t("update") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";

import CarrierService from "@/services/CarrierService";

const carrierService = new CarrierService();

export default {
  components: {
    NbModal,
    NbButton,
    NbTextInput,
  },
  name: "ModalUpdateAirportHub",
  props: {
    id: {
      type: String,
      default: "updateAirportHub",
    },
    body: {
      type: Object,
      required: true,
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      errors: {},
    };
  },
  created() {},
  computed: {
    newBody: {
      get() {
        return this.body;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    updateAirportHub() {
      carrierService
        .updateAirportHub(this.itemId, this.newBody)
        .then(() => {
          this.$helpers.closeModal(this.id);
          this.$emit("reloadCarrier");
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
  },
  watch: {},
};
</script>

<style scoped></style>
