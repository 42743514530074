<template>
  <div>
    <header-title :title="title" :badges="badges">
      <template #buttonsTop></template>
    </header-title>
    <div class="row justify-content-center">
      <div class="col-12 mb-5">
        <div class="card border-white rounded py-4">
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('flightsViewPage.airline')"
              :val="flight.airline"
            />
            <field-view
              :field="$t('flightsViewPage.flightNumber')"
              :val="flight.flight_number"
            />
            <field-view
              :field="$t('flightsViewPage.mawbNumber')"
              :val="flight.mawb_number"
            />
            <div class="col">
              <h6>{{ $t("flightsViewPage.mawbLabel") }}</h6>
              <div v-if="flight.mawb_number">
                <a
                  href="#"
                  title="Flight Label"
                  @click="generateFlightLabel(flight.id)"
                >
                  <i class="far fa-file-alt fa-lg text-dark"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('flightsViewPage.greenLight')"
              :val="flight.green_light"
            />
            <field-view
              :field="$t('flightsViewPage.prealertsSend')"
              :val="flight.prealerts_sent"
            />
            <field-view
              :field="$t('flightsViewPage.departureAirport')"
              :val="flight.departure_airport"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('flightsViewPage.arrivalAirport')"
              :val="flight.arrival_airport"
            />
            <field-view
              :field="$t('flightsViewPage.departureDate')"
              :val="flight.departure_date"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('flightsViewPage.arrivalDate')"
              :val="flight.arrival_date"
            />
            <field-view
              :field="$t('flightsViewPage.arrivalTime')"
              :val="flight.arrival_time"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('flightsViewPage.stopoverAirport')"
              :val="flight.stopover_airport"
            />
            <field-view
              :field="$t('flightsViewPage.departureTime')"
              :val="flight.departure_time"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('flightsViewPage.subtotal')"
              :val="flight.sub_total"
            />
            <field-view
              :field="$t('flightsViewPage.chargesKilo')"
              :val="flight.charges_per_kilo"
            />
          </div>

          <div class="row mx-5 my-2">
            <field-view
              :field="$t('ordersViewPage.freightValue')"
              :val="flight.freight_value"
            />
            <field-view
              :field="$t('flightsViewPage.otherCharges')"
              :val="flight.other_charges"
            />
          </div>
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('flightsViewPage.date')"
              :val="flight.created_at"
            />
          </div>
        </div>
        <br />
        <h6 class="mx-1 my-3 font-weight-light text-uppercase">
          {{ $t("flightsViewPage.shipments") }}
        </h6>
        <b-table
          id="my-shipments"
          :items="flight.shipments"
          :per-page="perPage"
          :current-page="currentPage"
          small
          striped
          responsive="md"
        >
          <template #cell(id)="data">
            <div>
              <router-link :to="`/shipping/shipments?id=` + data.item.id">{{
                data.value
              }}</router-link>
            </div>
          </template>
          <template #cell(masterboxes)="data">
            <router-link
              v-for="(masterbox, index) in data.value"
              :key="index"
              :to="`/shipping/masterboxes?id=` + masterbox.id"
            >
              {{
                index + 1 === data.value.length
                  ? masterbox.reference
                  : `${masterbox.reference}, `
              }}
            </router-link>
          </template>
        </b-table>
        <br />
        <div class="row justify-content-md-center h6">
          {{ $t("flightsViewPage.pages") }} : {{ currentItems }}
        </div>
        <b-pagination
          v-model="currentPage"
          aria-controls="my-shipments"
          align="center"
          size="sm"
          :total-rows="totalRows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        ></b-pagination>

        <div class="d-flex justify-content-between mx-5">
          <div></div>
          <div>
            <b-button
              v-if="flight.status === 'open_manifest'"
              class="mx-1"
              variant="danger"
              title="Delete this Flight"
              @click="$bvModal.show('delete-flight')"
              >{{ $t("delete") }}</b-button
            >
            <NbButton
              v-if="flight.status != 'departure_confirmed'"
              class="mx-1"
              variant="primary"
              title="Update this Flight"
              @click="initializeNewFlight()"
            >
              {{ $t("update") }}
            </NbButton>
            <b-button
              v-if="flight.status === 'open_manifest'"
              class="mx-1"
              variant="primary"
              title="Close this Flight Manifest"
              @click="$bvModal.show('close-manifest')"
              >{{ $t("flightsViewPage.closeManifest") }}</b-button
            >
            <b-button
              v-if="flight.is_correios && flight.status === 'manifest_closed'"
              class="mx-1"
              variant="primary"
              title="Confirm Departure"
              @click="$bvModal.show('confirm-departure')"
              >{{ $t("flightsViewPage.confirmDeparture") }}</b-button
            >
          </div>
        </div>

        <!-- modals -->
        <UpdateFlightModal
          :flight="newFlight"
          :has_mawb_file="has_mawb_file"
          @loadCurrentFlight="loadCurrentFlight"
          @generateFlightLabel="generateFlightLabel"
        />

        <b-modal
          id="delete-flight"
          title="Delete Flight"
          no-close-on-esc
          no-close-on-backdrop
          and
          hide-header-close
        >
          <div class="px-4">{{ $t("flightsViewPage.wantRemoveFlight") }}?</div>
          <template #modal-footer>
            <b-button
              variant="secondary"
              @click="closeModal('delete-flight')"
              >{{ $t("close") }}</b-button
            >
            <b-button
              variant="danger"
              title="Delete Flight"
              @click="deleteFlight"
              >{{ $t("delete") }}</b-button
            >
          </template>
        </b-modal>

        <b-modal
          id="close-manifest"
          title="Close Manifest"
          no-close-on-esc
          no-close-on-backdrop
          and
          hide-header-close
        >
          <div class="px-4">
            {{ manifestMessage }}
          </div>
          <div v-if="request_id" class="py-3 px-1">
            {{ $t("flightsViewPage.copyReference_id") }} --><CopyClipboard
              class="ml-2"
              title="Copy reference id to Clipboard"
              :copy-val="request_id"
            />
          </div>
          <template #modal-footer>
            <b-button
              variant="secondary"
              @click="closeModal('close-manifest')"
              >{{ $t("close") }}</b-button
            >
            <b-button
              v-if="!request_id"
              variant="primary"
              title="Close this Flight Manifest Permanently"
              @click="closeManifest"
              >{{ $t("flightsViewPage.closeManifest") }}</b-button
            >
          </template>
        </b-modal>

        <b-modal
          id="confirm-departure"
          title="Confirm Departure"
          no-close-on-esc
          no-close-on-backdrop
          and
          hide-header-close
        >
          <div class="px-4">
            {{ "Do you want to confirm the departure for this flight?" }}
          </div>
          <template #modal-footer>
            <b-button
              variant="secondary"
              @click="closeModal('confirm-departure')"
              >{{ $t("cancel") }}</b-button
            >
            <b-button
              variant="primary"
              title="Confirm Departure"
              @click="confirmDeparture"
              >{{ $t("flightsViewPage.confirmDeparture") }}</b-button
            >
          </template>
        </b-modal>
        <!-- end modal -->
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination, BTable } from "bootstrap-vue";
import FlightService from "../../../services/FlightService";
import FieldView from "@/components/FieldView";
import HeaderTitle from "@/components/HeaderTitle";
import CopyClipboard from "@/components/CopyClipboard";
import UpdateFlightModal from "@/views/shipping/flights/components/UpdateFlightModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";

const flightService = new FlightService();

export default {
  name: "FlightsView",

  components: {
    "b-pagination": BPagination,
    "b-table": BTable,
    "field-view": FieldView,
    HeaderTitle,
    CopyClipboard,
    UpdateFlightModal,
    NbButton,
  },
  data: () => {
    return {
      flight: {},
      perPage: 20,
      totalRows: 1,
      currentPage: 1,
      manifestMessage:
        "Do you want to close this manifest? You will not be able to update its content anymore after this action.",
      request_id: null,

      errors: [],
      newFlight: {
        stopover_airport: "",
        departure_airport: "",
        arrival_airport: "",
        departure_time: "",
        departure_date: "",
        arrival_time: "",
        arrival_date: "",
        mawb_number: "",
        mawb_file: "",
        mawb_file_temp: "",
        mawb_file_format: "",
        airline: "",
        flight_number: "",
        sub_total: "",
        charges_per_kilo: "",
        other_charges: "",
        freight_value: "",
      },
      has_mawb_file: false,
      uploading: false,
    };
  },
  computed: {
    currentItems() {
      let end = this.currentPage * this.perPage;
      let begin = end - (this.perPage - 1);
      return `${begin} - ${end}`;
    },
    title() {
      return this.$t("flightsViewPage.title") + this.$route.params.id;
    },
    badges() {
      let badges = [{ id: 1, variant: "success", value: "Open Manifest" }];
      if (this.flight.status === "departure_confirmed") {
        badges[0].variant = "info";
        badges[0].value = "Departure Confirmed";
      }
      if (this.flight.status === "manifest_closed") {
        badges[0].variant = "warning";
        badges[0].value = "Manifest Closed";
      }
      return badges;
    },
  },
  beforeMount() {
    this.loadCurrentFlight();
  },
  methods: {
    initializeNewFlight() {
      this.newFlight.id = this.flight.id;
      this.newFlight.stopover_airport = this.flight.stopover_airport;
      this.newFlight.departure_airport = this.flight.departure_airport;
      this.newFlight.arrival_airport = this.flight.arrival_airport;
      this.newFlight.departure_time = this.flight.departure_time;
      this.newFlight.departure_date = this.flight.departure_date;
      this.newFlight.arrival_time = this.flight.arrival_time;
      this.newFlight.arrival_date = this.flight.arrival_date;
      this.newFlight.mawb_number = this.flight.mawb_number;
      this.newFlight.airline = this.flight.airline;
      this.newFlight.flight_number = this.flight.flight_number;
      this.newFlight.sub_total = this.flight.sub_total;
      this.newFlight.charges_per_kilo = this.flight.charges_per_kilo;
      this.newFlight.freight_value = this.flight.freight_value;
      this.newFlight.other_charges = this.flight.other_charges;
      this.newFlight.mawb_file_format = "link";
      this.newFlight.mawb_file = this.flight.mawb_file;
      this.newFlight.mawb_file_temp = this.flight.mawb_file;
      this.errors = [];
      this.$helpers.openModal("updateFlightModal");
    },
    loadCurrentFlight() {
      flightService.getFlight(this.$route.params.id).then((response) => {
        this.flight = response.data.data;
        let time = this.flight.created_at;
        let date = this.flight.created_at;

        this.flight.created_at = this.formateDate(date, time);

        // this.flight.time = this.formateTime(time);
        flightService
          .generateFlightLabelUntoasted(this.flight.id)
          .then((response) => {
            if (
              // eslint-disable-next-line no-prototype-builtins
              response.data.hasOwnProperty("data") &&
              // eslint-disable-next-line no-prototype-builtins
              response.data.data.hasOwnProperty("download_url")
            ) {
              this.flight.mawb_file = response.data.data.download_url;
            }
            this.has_mawb_file = true;
          })
          .catch(() => {
            this.has_mawb_file = false;
            this.flight.mawb_file = "";
          });
      });
    },
    generateFlightLabel(id) {
      flightService.generateFlightLabelUntoasted(id).then((response) => {
        if (
          // eslint-disable-next-line no-prototype-builtins
          response.data.hasOwnProperty("data") &&
          // eslint-disable-next-line no-prototype-builtins
          response.data.data.hasOwnProperty("download_url")
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    closeModal(modalId) {
      this.$bvModal.hide(modalId);
    },
    deleteFlight() {
      flightService.deleteFlight(this.flight.id).then(() => {
        this.closeModal("delete-flight");
        if (this.hasHistory()) {
          this.$router.go(-1);
          return;
        }
        this.$router.push("/");
      });
    },
    closeManifest() {
      flightService
        .closeManifest(this.flight.id)
        .then((response) => {
          this.request_id = response.data.data.request_id;
          this.manifestMessage = response.data.messages[0].replace(
            "request_id",
            this.request_id,
          );
          this.loadCurrentFlight();
          /*
                    if(this.hasHistory()) {
                        this.$router.go(-1);
                        return
                    }
                    this.$router.push('/');
                    */
        })
        .catch(() => {
          this.manifestMessage =
            "Do you want to close this manifest? You will not be able to update its content anymore after this action.";
          this.request_id = null;
        });
    },
    confirmDeparture() {
      flightService
        .confirmDeparture(this.flight.id)
        .then(() => {
          this.loadCurrentFlight();
        })
        .catch((error) => {
          this.errors = error.response.data.messages[0];
        });
    },
    hasHistory() {
      return window.history.length > 2;
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
  },
};
</script>
