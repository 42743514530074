// import { addTimeAndDate } from '../../shared/functions' // This is a date processing and it changes the date
import GlobalService from "@/services/GlobalService";
const globalService = new GlobalService();

export default {
  async fetchItems({ state, commit }, namespace) {
    const res = await globalService.getTwentyItems(
      namespace,
      state.currentPage,
      state.limit,
      state.filters,
    );
    // let items = new addTimeAndDate(res.data.data.elements)
    let items = res.data.data.elements;
    const totalRows = res.data.data.count;
    commit("SET_ITEMS", items);
    commit("SET_TOTAL_ROWS", totalRows);
  },
};
