<template>
  <div>
    <DatatableRoot
      ref="table"
      namespace="sellers"
      :columns="tableColumns"
      :clickable="true"
      :filters="tableFilters"
      selectable
      :selected.sync="selectedSellers"
      @count="$emit('count', $event)"
      @rowClick="onSellerClick($event.id)"
      :search-params="searchParams"
      :urlState="false"
    >
      <template #actions>
        <DatatableActions :disabled="!selectedSellers.length">
          <DatatableAction @click="onDownloadSellers">
            {{ $t("sellersPage.downloadSellers") }}
          </DatatableAction>
        </DatatableActions>
      </template>

      <template #country="{ row }">
        {{ extractCountryName(row.country) }}
      </template>
      <template #is_multiple="{ row }">
        <IsCheckedIcon :checked="row.is_multiple" />
      </template>
      <template #current_balance="{ row }">
        R$ {{ row.current_balance }}
      </template>
      <template #is_carrier="{ row }">
        <IsCheckedIcon :checked="row.is_carrier" />
      </template>

      <template #created_at="{ row }">
        <div>
          {{ row.created_at | date }}
          <small class="d-block text-gray-60">{{
            row.created_at | time
          }}</small>
        </div>
      </template>
    </DatatableRoot>

    <Modal v-model="downloadSellersModal">
      <SellersDownload :seller-ids="selectedSellers" />
    </Modal>
  </div>
</template>

<script>
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import Modal from "@/components/modal/Modal.vue";
import SellersDownload from "./SellersDownload.vue";
import queryParamsMixin from "@/mixins/query-params-mixin.js";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";
import DatatableActions from "@/components/datatable/DatatableActions.vue";
import DatatableAction from "@/components/datatable/DatatableAction.vue";
import moment from "moment";

export default {
  mixins: [queryParamsMixin],
  props: {
    searchParams: {
      type: Object,
      default: () => {},
    },
  },
  inject: ["sellersContext"],
  filters: {
    date(val) {
      return val && moment(val).format("DD/MM/YYYY");
    },
    time(val) {
      return val && moment(val).format("HH:mm:ss");
    },
  },
  components: {
    DatatableRoot,
    Modal,
    IsCheckedIcon,
    SellersDownload,
    DatatableActions,
    DatatableAction,
  },
  data() {
    return {
      downloadSellersModal: false,
      sellerCreateModal: false,
      sellerDataModal: false,
      selectedSellers: [],
      countries: [],
      active: false,
    };
  },
  computed: {
    isSuper() {
      return this.$store.state.user.is_super;
    },
    countriesOptions() {
      return this.sellersContext?.countries?.map((country) => ({
        text: country.name,
        value: country.alpha2_code,
      }));
    },
    tableFilters() {
      const options = [
        {
          label: this.$t("sellersPage.fields.name"),
          inputs: [{ key: "name" }],
        },
        {
          label: this.$t("sellersPage.fields.email"),
          inputs: [{ key: "email" }],
        },
        {
          label: this.$t("sellersPage.fields.site"),
          inputs: [{ key: "site" }],
        },
        {
          label: this.$t("sellersPage.fields.billingEmail"),
          inputs: [{ key: "billing_email" }],
        },
        {
          label: this.$t("sellersPage.fields.responsableContact"),
          inputs: [{ key: "responsable_contact" }],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("sellersPage.legalName"),
          inputs: [{ key: "legal_name" }],
        },
        {
          label: this.$t("sellersPage.rfbCode"),
          inputs: [{ key: "rfb_code" }],
        },
        {
          label: this.$t("sellersPage.taxnumber"),
          inputs: [{ key: "tax_number" }],
        },
        {
          label: this.$t("sellersPage.phone"),
          inputs: [{ key: "phone" }],
        },
        {
          label: this.$t("sellersPage.ismultiple"),
          inputs: [{ key: "is_multiple", type: "boolean" }],
        },
        {
          label: this.$t("sellersPage.fields.isCarrier"),
          inputs: [{ key: "is_carrier", type: "boolean" }],
        },
        {
          label: this.$t("sellersPage.balance") + " (Min, Max) ",
          inputs: [
            { key: "balance_gte", type: "money", placeholder: "Min (R$)" },
            { key: "balance_lte", type: "money", placeholder: "Max (R$)" },
          ],
        },
        {
          label: this.$t("sellersPage.address"),
          inputs: [{ key: "address" }],
        },
        {
          label: this.$t("sellersPage.addressnumber"),
          inputs: [{ key: "address_number" }],
        },
        {
          label: this.$t("sellersPage.addresscomplement"),
          inputs: [{ key: "address_complement" }],
        },
        {
          label: this.$t("sellersPage.fields.zipcode"),
          inputs: [{ key: "zip_code" }],
        },
        {
          label: this.$t("sellersPage.fields.city"),
          inputs: [
            {
              key: "city",
            },
          ],
        },
        {
          label: this.$t("sellersPage.state"),
          inputs: [
            {
              key: "state",
            },
          ],
        },
        {
          label: "Country",
          inputs: [
            {
              key: "country",
              type: "select",
              options: this.countriesOptions,
            },
          ],
        },
      ];

      if (!this.searchParams) {
        return options;
      }

      return options.filter(
        (option) =>
          !option.inputs.some((input) => input.key in this.searchParams),
      );
    },
    tableColumns() {
      const data = [
        { key: "id", label: "ID", sortable: true },
        {
          key: "name",
          label: this.$t("sellersPage.fields.name"),
          sortable: true,
        },
        {
          key: "email",
          label: this.$t("sellersPage.fields.email"),
          sortable: true,
        },
        {
          key: "is_multiple",
          label: this.$t("sellersPage.fields.ismultiple"),
          sortable: true,
        },
        {
          key: "current_balance",
          label: this.$t("sellersPage.fields.currentBalance"),
          sortable: true,
        },
        {
          key: "site",
          label: this.$t("sellersPage.fields.site"),
          sortable: true,
        },
        {
          key: "tax_number",
          label: this.$t("sellersPage.taxnumber"),
        },
        {
          key: "phone",
          label: this.$t("sellersPage.phone"),
        },
        {
          key: "billing_email",
          label: this.$t("sellersPage.fields.billingEmail"),
          sortable: true,
        },
        {
          key: "responsable_contact",
          label: this.$t("sellersPage.fields.responsableContact"),
          sortable: true,
        },
        {
          key: "created_at",
          label: this.$t("sellersPage.fields.creationDate"),
          sortable: true,
        },
        {
          key: "rfb_code",
          label: this.$t("sellersPage.rfbCode"),
          sortable: true,
        },
        {
          key: "legal_name",
          label: this.$t("sellersPage.legalName"),
          sortable: true,
        },
        {
          key: "is_carrier",
          label: this.$t("sellersPage.fields.isCarrier"),
        },
        {
          key: "address",
          label: this.$t("sellersPage.address"),
        },
        {
          key: "address_number",
          label: this.$t("sellersPage.addressnumber"),
        },
        {
          key: "address_complement",
          label: this.$t("sellersPage.addresscomplement"),
        },
        {
          key: "city",
          label: this.$t("sellersPage.city"),
        },
        {
          key: "zip_code",
          label: this.$t("sellersPage.fields.zipcode"),
        },
        {
          key: "state",
          label: this.$t("sellersPage.state"),
        },
        {
          key: "country",
          label: this.$t("sellersPage.country"),
        },
      ];

      if (this.isSuper) {
        data.push({
          label: "Platform",
          key: "platform_name",
          sortable: true,
        });
      }
      return data;
    },
  },
  methods: {
    onSellerClick(id) {
      this.$emit("sellerClick", id);
    },
    loadItems() {
      this.$refs.table?.getData();
    },
    updateItem(data) {
      this.$refs.table?.updateRowData({
        key: "id",
        value: data.id,
        data,
      });
    },
    onDownloadSellers() {
      this.downloadSellersModal = true;
    },
    extractCountryName(country) {
      const countryData = this.countries.find(
        (item) => item.alpha2_code === country,
      );

      if (countryData) {
        return countryData?.name;
      }
      return country;
    },
  },
  activated() {
    if (this.active) {
      this.loadItems();
    }
    this.active = true;
  },
};
</script>

<style lang="scss" scoped></style>
