import api from "./HttpService";

export default class GlobalService {
  getItems(namespace, /* offset, limit,  */ filters) {
    if (filters.limit > 0) {
      filters.offset = (filters.offset - 1) * filters.limit;
    } else {
      filters.offset = (filters.offset - 1) * 20;
    }
    return api.get("/v1/" + namespace, {
      params: filters,
    });
  }

  getTwentyItems(namespace, offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
    } else {
      offset = (offset - 1) * 20;
    }
    filters.source = "admin";
    return api.get(
      "/v1/" + namespace + "?offset=" + offset + "&limit=" + limit,
      {
        params: filters,
      },
    );
  }

  getAllItems(namespace) {
    return api.get("/v1/" + namespace + "?limit=1000&offset=0&source=admin");
  }
}
