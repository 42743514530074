<template>
  <div>
    <header-title :title="$t('delayedJobsPage.title')" />
    <layout-content
      :title="$t('delayedJobsPage.title')"
      :namespace="namespace"
      :items="items"
      :all-fields="allFields"
      :fields="fields"
      @selectedRows="selectedRows"
    >
      <template #buttonsTop> </template>
      <template #buttonsAction> </template>
      <template #cell(last_error)="data">
        <button
          :id="`popover-last-error-${data.item.id}`"
          ref="span"
          class="btn btn-a"
          variant="primary"
        >
          {{ data.item.last_error && data.item.last_error.slice(0, 40) }}
        </button>
        <b-popover
          :target="`popover-last-error-${data.item.id}`"
          triggers="click"
        >
          {{ data.item.last_error }}
        </b-popover>
      </template>
      <template #cell(handler)="data">
        <button
          :id="`popover-handler-${data.item.id}`"
          ref="span"
          class="btn btn-a"
          variant="primary"
        >
          {{ data.item.handler && data.item.handler.slice(0, 40) }}
        </button>
        <b-popover :target="`popover-handler-${data.item.id}`" triggers="click">
          {{ data.item.handler }}
        </b-popover>
      </template>
    </layout-content>
  </div>
</template>

<script>
import LayoutContent from "@/components/LayoutContent";
import HeaderTitle from "@/components/HeaderTitle.vue";
/* import LogService from "@/services/LogService";

  const logService = new LogService(); */

export default {
  name: "DelayedJobsLogs",
  components: {
    LayoutContent,
    HeaderTitle,
  },
  data: () => {
    return {
      selectMode: "single",
      /* delayedJobsLogs: [], */
      show: false,
      allFields: [],
      delayedJobsPage: 1,
      selected: [],
      namespace: "delayed_jobs",
    };
  },
  computed: {
    items() {
      return this.$store.state[this.namespace].items;
    },
    fields() {
      return [
        {
          key: "queue",
          label: this.$t("delayedJobsPage.fields.queue"),
          sortable: true,
          thClass: ["medium-content"],
        },
        {
          key: "priority",
          label: this.$t("delayedJobsPage.fields.priority"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "attempts",
          label: this.$t("delayedJobsPage.fields.attempts"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "handler",
          label: this.$t("delayedJobsPage.fields.handler"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "last_error",
          label: this.$t("delayedJobsPage.fields.lastError"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "run_at",
          label: this.$t("delayedJobsPage.fields.runat"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "locked_at",
          label: this.$t("delayedJobsPage.fields.lockedat"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "failed_at",
          label: this.$t("delayedJobsPage.fields.failedat"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "locked_by",
          label: this.$t("delayedJobsPage.fields.lockedby"),
          sortable: false,
          thClass: ["medium-content"],
        },
      ];
    },
  },
  created() {
    this.allFields = this.fields;
    this.loadItems();
  },
  methods: {
    loadItems() {
      this.$store.dispatch(this.namespace + "/fetchItems", this.namespace);
    },
    selectedRows(items) {
      this.selected = items;
    },
    /* 
        delayedJobsLogs() {
          logService.getAllDelayedJobs().then((response)=> {
            this.delayedJobsLogs = response.data.data;
          }
          )
        } 
        */
  },
};
</script>
