export default {
  SET_KEEPFILTERING(state, payload) {
    state.keepFiltering = payload;
  },
  /* manter filtro em todas as paginas
  SET_PERMANENTFILTER (state, payload) {
    state.permanentFilter = payload;
  }, 
  */
};
