<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ `${$t("receivedCheckpointsViewPage.title")} ${itemId}` }}
      </ModalTitle>
    </ModalHeader>
    <div>
      <NbCard
        id="checkpoint_chard"
        :title="$t('generalInformation')"
        class="bg-gray-05"
      >
        <template #body>
          <div class="d-flex justify-content-between flex-wrap">
            <NbTextInput
              id="checkpoint-tracking_number"
              variant="borderless-gray-10"
              :placeholder="$t('notFound')"
              class="w-32-5 my-2"
              :name="$t('receivedCheckpointsViewPage.trackingNumber')"
              v-model="checkpoint.tracking_number"
              disabled
            />
            <NbTextInput
              id="checkpoint-tracking_function"
              variant="borderless-gray-10"
              :placeholder="$t('notFound')"
              class="w-32-5 my-2"
              :name="$t('receivedCheckpointsViewPage.trackingFunction')"
              v-model="checkpoint.tracking_function"
              disabled
            />
            <NbTextInput
              id="checkpoint-status_code"
              variant="borderless-gray-10"
              :placeholder="$t('notFound')"
              class="w-32-5 my-2"
              :name="$t('receivedCheckpointsViewPage.statusCode')"
              v-model="checkpoint.status_code"
              disabled
            />
            <NbTextInput
              id="checkpoint-date_iso"
              variant="borderless-gray-10"
              :placeholder="$t('notFound')"
              class="w-48-5 my-2"
              :name="$t('receivedCheckpointsViewPage.dateIso')"
              v-model="checkpoint.date_iso"
              disabled
            />
            <NbTextInput
              id="checkpoint-created_at"
              variant="borderless-gray-10"
              :placeholder="$t('notFound')"
              class="w-48-5 my-2"
              :name="$t('receivedCheckpointsViewPage.date')"
              v-model="checkpoint.created_at"
              disabled
            />
            <NbTextInput
              id="checkpoint-message"
              variant="borderless-gray-10"
              :placeholder="$t('notFound')"
              class="w-100 my-2"
              :name="$t('receivedCheckpointsViewPage.message')"
              v-model="checkpoint.message"
              disabled
            />
          </div>
        </template>
      </NbCard>

      <NbFooter
        :text="$t('components.footer.checkOurSupport')"
        link="https://nobordistinc.freshdesk.com/support/home"
      />
    </div>
    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ReceivedCheckpointService from "@/services/ReceivedCheckpointService";
const checkpointService = new ReceivedCheckpointService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbTextInput,
    NbCard,
    NbFooter,
  },
  name: "ModalReceivedCheckpointsView",
  props: {
    id: {
      type: String,
      default: "ModalReceivedCheckpointsView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      checkpoint: {},
      notifications: [],
      fields: [],
    };
  },
  created() {},
  computed: {
    allFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "receiver_type",
          label: this.$t("receivedCheckpointsViewPage.fields.receiverType"),
          sortable: false,
        },
        {
          key: "nb_notification_id",
          label: this.$t("receivedCheckpointsViewPage.fields.notificationID"),
          sortable: false,
        },
        {
          key: "volume_id",
          label: this.$t("receivedCheckpointsViewPage.fields.volumeID"),
          sortable: false,
        },
        {
          key: "created_at",
          label: this.$t("receivedCheckpointsViewPage.fields.creationDate"),
          sortable: true,
        },
      ];
    },
  },
  methods: {
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    loadCheckpoint() {
      checkpointService.getReceivedCheckpoint(this.itemId).then((response) => {
        this.checkpoint = response.data.data;

        let date = this.checkpoint.created_at;
        this.checkpoint.created_at = this.formateDate(date, date);

        date = this.checkpoint.date_iso;
        this.checkpoint.date_iso = this.formateDate(date, date);
      });
    },
  },
  mounted() {
    this.loadCheckpoint();
  },
};
</script>

<style scoped></style>
