<template>
  <div>
    <ModalHeader>
      <ModalTitle>Additional Cost</ModalTitle>
    </ModalHeader>

    <div class="grid gap-4">
      <NbSelectInput
        v-model="form.type"
        id="form.type"
        :options="costTypeOptions"
        :name="$t('ordersViewPage.selecttype')"
      />

      <NbDatePicker
        v-model="form.payment_date"
        id="payment_date"
        :name="$t('paymentDate')"
        class="d-flex flex-column"
      />

      <NbTextInput
        v-model="form.value"
        :name="$t('ordersViewPage.value')"
        type="money"
        :decimals="2"
        :min="0.0"
        :prepend="currency"
        id="form.value"
      />
    </div>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
      <NbButton
        class="ml-2"
        :disabled="loading || !formIsValid"
        @click="addOrderCost"
      >
        {{ $t("save") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import OrderCostService from "@/services/OrderCostService";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbDatePicker from "@/components/generic/NbDatePicker.vue";
import Errors from "@/utils/Errors.js";
import moment from "moment";

const orderCostService = new OrderCostService();

export default {
  components: {
    ModalFooter,
    ModalHeader,
    ModalTitle,
    NbButton,
    ModalClose,
    NbSelectInput,
    NbDatePicker,
    NbTextInput,
  },
  inject: ["context"],
  props: {
    orderId: {
      type: [String, Number],
      required: true,
    },
    currency: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      allCostType: [],
      costTypeOptions: [],
      errors: new Errors(),
      form: {
        type: "",
        payment_date: new Date(),
        value: "0",
      },
    };
  },
  computed: {
    formIsValid() {
      return this.form.type && this.form.value >= 0 && this.form.payment_date;
    },
  },
  methods: {
    async addOrderCost() {
      if (!this.formIsValid) return;

      try {
        this.loading = true;
        const orderCost = this.allCostType.find(
          (item) => item.external_id == this.form.type,
        );

        await orderCostService.setOrderCost({
          external_id: orderCost.external_id,
          name: orderCost.text_en,
          order_id: this.orderId,
          payment_date: moment(this.form.payment_date).format("YYYY-MM-DD"),
          slug: orderCost.slug,
          value: this.form.value,
        });
        this.context.close();
        this.$emit("success");
      } finally {
        this.loading = false;
      }
    },
    async loadCostTypes() {
      try {
        this.loading = true;
        const response = await orderCostService.getTariffs();
        this.allCostType = response.data.data;

        this.costTypeOptions = response.data.data.map((costType) => {
          return {
            text: `${costType.external_id} - ${costType.text_en}`,
            value: costType.external_id,
          };
        });
      } finally {
        this.loading = false;
      }
      // this.order_cost.payment_date = this.today();
    },
  },
  created() {
    this.loadCostTypes();
  },
};
</script>

<style lang="scss" scoped></style>
