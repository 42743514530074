<template>
  <div
    v-if="
      !volume.general_description &&
      !volume?.order?.observations &&
      !volumeHasIntegrationData
    "
  >
    {{ $t("none") }}
  </div>
  <div v-else class="grid gap-4">
    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('volumesViewPage.generalDescription')"
      v-model="volume.general_description"
      placeholder="-"
      id="volume.general_description"
      :helpText="$t('volumesViewPage.uniqueExpressionToDescribeItems')"
    />
    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('volumesViewPage.observations')"
      v-model="volume.order.observations"
      placeholder="-"
      id="volume.order.observations"
      :helpText="$t('volumesViewPage.freeFieldCreatedBySeller')"
    />

    <section>
      <h3 class="heading-3 m-0">
        {{ $t("volumesViewPage.integrationData") }}
      </h3>

      <div v-if="volumeHasIntegrationData" class="grid grid-cols-6 gap-4">
        <div
          v-for="(value, key, index) in volume.integration_data"
          class="info"
          :key="index"
        >
          <small>{{ key }}</small>
          <span>{{ value }}</span>
        </div>
      </div>
      <span v-else>{{ $t("none") }}</span>
    </section>
  </div>
</template>

<script>
import NbTextInput from "@/components/input/text/NbTextInput.vue";

export default {
  props: {
    volume: {
      type: Object,
      required: true,
    },
  },
  components: {
    NbTextInput,
  },
  computed: {
    volumeHasIntegrationData() {
      return (
        this.volume.integration_data &&
        !!Object.keys(this.volume.integration_data).length
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
