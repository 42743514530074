<template>
  <div class="timeline-breakpoint">
    <div class="circle-content">
      <div :class="['circle', `circle-${circleSize}`]">
        <slot name="circle"></slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    // sm, lg
    circleSize: {
      type: String,
      default: "lg",
    },
  },
};
</script>

<style lang="scss">
.timeline-breakpoint {
  width: var(--timeline-gap);
  display: flex;
  flex-direction: column;
  align-items: center;
  .circle-content {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    height: 4rem;
    align-items: center;
    .circle {
      z-index: 5;
      height: 4rem;
      width: 4rem;
      background-color: var(--gray-05);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      &-sm {
        height: 2rem;
        width: 2rem;
      }
    }
  }
  & + .timeline-breakpoint {
    .circle-content {
      &::before {
        content: "";
        width: 100%;
        border-top: 2px dashed var(--gray-05);
        position: absolute;
        top: 50%;
        right: 50%;
      }
    }
  }
}
</style>
