<template>
  <div class="grid gap-4">
    <BoxDimensions
      :height="volume.height"
      :width="volume.width"
      :depth="volume.length"
      :weight="volume.weight"
    />
    <div class="grid grid-cols-4 gap-4">
      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('volumesViewPage.updatedWeight')"
        :value="volume.updated_weight ? volume.updated_weight + ' kg' : '-'"
        id="volume.updated_weight"
        :helpText="$t('volumesViewPage.updatedWeightDescription')"
      />
      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('volumesViewPage.cubed_weight')"
        :value="volume.cubed_weight ? volume.cubed_weight + ' kg' : '-'"
        id="volume.cubed_weight"
        :helpText="$t('volumesViewPage.cubedWeightDescription')"
      />
    </div>
  </div>
</template>

<script>
import BoxDimensions from "./BoxDimensions.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
export default {
  props: { volume: { type: Object, required: true } },
  components: {
    BoxDimensions,
    NbTextInput,
  },
};
</script>

<style lang="scss" scoped></style>
