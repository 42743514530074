<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("generalEmailsPage.changeYourConfiguratedSenderAddress") }}
      </ModalTitle>
    </ModalHeader>

    <div>
      <form @submit.prevent="save">
        <NbTextInput
          required
          :name="$t('email')"
          v-model="notificationEmail"
          id="email"
        />
      </form>
    </div>

    <ModalFooter class="d-flex justify-content-end gap-4">
      <ModalClose />
      <NbButton
        @click="save"
        :disabled="loading || !notificationEmail.trim()"
        >{{ $t("save") }}</NbButton
      >
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import PlatformService from "@/services/PlatformService.js";

const platformService = new PlatformService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbTextInput,
  },
  props: {
    platformId: {
      type: Number,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  inject: ["context"],
  data() {
    return {
      loading: false,
      notificationEmail: this.email || "",
    };
  },
  watch: {
    email(value) {
      this.notificationEmail = value;
    },
  },
  methods: {
    async save() {
      try {
        this.loading = true;
        const sanitizedData = this.notificationEmail.trim();
        await platformService.updatePlatform(this.platformId, {
          notification_email: sanitizedData,
        });

        this.$emit("update:email", sanitizedData);
        this.context?.close();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
