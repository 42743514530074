var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NbPageTitle',{staticClass:"pb-3 page-container",attrs:{"title":_vm.$t('trackingRulesPage.title'),"subTitle":_vm.$t('trackingRulesPage.subTitle')}},[_c('div',{staticClass:"btn-groups d-flex flex-column"},[_c('NbButton',{staticClass:"text-left",on:{"click":function($event){_vm.isModalTrackingRuleCreateOpen = true}}},[_c('NbIcon',{staticClass:"mr-2",attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.$t("trackingRulesPage.addTrackingRule"))+" ")],1)],1)]),_c('section',{staticClass:"page-container"},[_c('NbTabs',{staticClass:"mb-4",attrs:{"tabs":_vm.tabsOptions},on:{"switchTab":function($event){return _vm.switchTab($event)}}}),_c('DatatableRoot',{ref:"trackingRulesTable",attrs:{"namespace":"tracking_rules","clickable":"","columns":_vm.tableColumns,"filters":_vm.tableFilters},on:{"count":function($event){_vm.totalTrackingRules = $event},"rowClick":function($event){return _vm.loadModalData($event.id, 'trackingRulesId', 'ModalTrackingRuleView')}},scopedSlots:_vm._u([{key:"start_checkpoint_id",fn:function({ row }){return [(row.start_checkpoint_id)?_c('div',{staticClass:"link-1",on:{"click":function($event){return _vm.loadModalData(
              row.start_checkpoint_id,
              'nobordistCheckpointId',
              'ModalNobordistCheckpointsView',
            )}}},[_vm._v(" "+_vm._s(_vm.loadCheckpointName(row.start_checkpoint_id))+" ")]):_c('span',[_vm._v("-")])]}},{key:"end_checkpoint_id",fn:function({ row }){return [(row.end_checkpoint_id)?_c('div',{staticClass:"link-1",on:{"click":function($event){return _vm.loadModalData(
              row.end_checkpoint_id,
              'nobordistCheckpointId',
              'ModalNobordistCheckpointsView',
            )}}},[_vm._v(" "+_vm._s(_vm.loadCheckpointName(row.end_checkpoint_id))+" ")]):_c('span',[_vm._v("-")])]}},{key:"seller_id",fn:function({ row }){return [(row.seller_id)?_c('div',[_c('router-link',{staticClass:"link-1",attrs:{"to":`/users_management/sellers?id=` + row.seller_id}},[_vm._v(" "+_vm._s(_vm.loadSellerName(row.seller_id))+" ")])],1):_c('span',[_vm._v(" "+_vm._s(_vm.$t("any"))+" ")])]}},{key:"contract_id",fn:function({ row }){return [(row.contract_id)?_c('div',[_c('router-link',{staticClass:"link-1",attrs:{"to":`/contracts/contracts?contractId=${row.contract_id}`}},[_vm._v(" "+_vm._s(_vm.loadContractName(row.contract_id))+" ")])],1):_c('span',[_vm._v(" "+_vm._s(_vm.$t("any"))+" ")])]}},{key:"created_at",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("datetime")(row.created_at))+" ")]}}])})],1),_c('NbFooter',{attrs:{"text":_vm.$t('components.footer.checkOurSupport'),"link":"https://nobordistinc.freshdesk.com/support/home"}}),_c('ModalNobordistCheckpointsView',{attrs:{"itemId":_vm.currentItemId}}),_c('Modal',{attrs:{"size":"lg"},on:{"close":function($event){return _vm.setUrlSearchParam('trackingRulesId')}},model:{value:(_vm.isModalTrackingRuleOpen),callback:function ($$v) {_vm.isModalTrackingRuleOpen=$$v},expression:"isModalTrackingRuleOpen"}},[_c('ModalTrackingRuleView',{attrs:{"itemId":_vm.currentItemId},on:{"reloadTrackingRules":_vm.reloadTrackingRules}})],1),_c('Modal',{attrs:{"size":"lg"},model:{value:(_vm.isModalTrackingRuleCreateOpen),callback:function ($$v) {_vm.isModalTrackingRuleCreateOpen=$$v},expression:"isModalTrackingRuleCreateOpen"}},[_c('ModalTrackingRuleCreate',{on:{"reloadTrackingRules":_vm.reloadTrackingRules}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }