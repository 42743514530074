<template>
  <div v-if="cannot">
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex/dist/vuex.common.js";

export default {
  props: {
    profiles: {
      type: [Array],
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    cannot() {
      return !this.profiles.includes(this.user.profile);
    },
  },
};
</script>

<style lang="scss" scoped></style>
