<template>
  <div class="text-16 mb-0 star-rate">
    <i
      v-for="index in stars"
      :key="index"
      :class="
        rated > index - 1
          ? 'fas rated-color'
          : rate > index - 1
            ? 'fas hover-color'
            : 'far'
      "
      class="fa-star pr-1 fa-4x"
      :style="`font-size: ${size};`"
      @mouseover="changeRate(index)"
      @mouseleave="changeRate(0)"
      @click="changeRated(index)"
    >
    </i>
  </div>
</template>

<script>
export default {
  props: {
    stars: {
      type: Number,
      default: 5,
    },
    ratable: {
      type: Boolean,
      default: false,
    },
    currentRating: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: "16px",
    },
  },
  data() {
    return {
      rate: 0,
      rated: 0,
    };
  },
  created() {
    if (this.currentRating) {
      this.rated = this.currentRating;
    }
  },
  methods: {
    changeRate(num) {
      if (this.ratable) {
        this.rate = num;
      }
    },
    changeRated(num) {
      if (this.ratable) {
        if (this.rated === num) {
          this.rated = 0;
          this.$emit("changeRated", 0);
          return;
        }
        this.rated = num;
        this.$emit("changeRated", num);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.star-rate > i {
  color: var(--gray-30);
}
.rated-color {
  color: #000014 !important;
}
.hover-color {
  color: var(--gray-20) !important;
}
</style>
