<template>
  <div class="box">
    <Skeleton v-if="loading" h="32" w="86" class="mb-2" />
    <h2 v-else class="value">{{ value }}</h2>
    <h5 class="description">{{ description }}</h5>
  </div>
</template>

<script>
import Skeleton from "@/components/Skeleton.vue";
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  components: {
    Skeleton,
  },
};
</script>

<style lang="scss" scoped>
.box {
  background-color: var(--gray-00);
  padding: 2rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .value,
  .description {
    padding: 0;
    margin: 0;
  }
}
</style>
