import api from "./HttpService";

export default class FiscalPaymentService {
  all() {
    return api.get("/v1/fiscal_payments");
  }

  findById(id) {
    return api.get(`/v1/fiscal_payments/${id}`);
  }

  getLabel(id) {
    return api.put(`/v1/fiscal_payments/${id}/gnre_boleto`);
  }
}
