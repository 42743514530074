<template>
  <div v-if="isAutoSaveDisplayVisible" class="text-gray-60">
    <NbIcon
      :icon="isSaving ? 'loader' : 'save'"
      :attributes="{
        width: '1rem',
        height: '1rem',
        class: isSaving ? 'animate-spin' : '',
      }"
    />
    <span class="ml-1">
      {{
        isSaving
          ? $t("components.topBar.savingChanges")
          : $t("components.topBar.lastChangesSaved")
      }}
    </span>
  </div>
</template>

<script>
import { eventBus, Events } from "../utils/event-bus";
import NbIcon from "./icons/NbIcon.vue";

export default {
  components: {
    NbIcon,
  },
  data() {
    return {
      isSaving: false,
      isAutoSaveDisplayVisible: false,
      timeout: null,
    };
  },
  mounted() {
    eventBus.$on(Events.AUTO_SAVE, (event) => {
      clearTimeout(this.timeout);
      if (typeof event !== "boolean") {
        return;
      }

      this.isAutoSaveDisplayVisible = true;
      if (event) {
        this.isSaving = true;
        return;
      }

      this.isSaving = false;
      this.timeout = setTimeout(() => {
        this.isAutoSaveDisplayVisible = false;
      }, 5000);
    });
  },
};
</script>

<style lang="scss">
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1.5s linear infinite;
}

span {
  font-size: 0.75rem;
}
</style>
