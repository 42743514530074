<template>
  <div>
    <NbPageTitle
      class="pb-3 page-container"
      :title="$t('dutiesAndTaxesStatusPage.title')"
      :subTitle="$t('dutiesAndTaxesStatusPage.subTitle')"
    />

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <DatatableRoot
        namespace="duties_and_taxes_status"
        :columns="tableColumns"
        selectable
        :actions="tableActions"
        :filters="tableFilters"
        :selected.sync="selected"
        @count="totalDutiesTaxes = $event"
        @rowClick="loadModalData($event.id)"
        @openDownloadModal="openDownloadModal"
      >
        <template #seller_name="{ row }">
          <router-link
            class="link-1"
            :to="`/users_management/sellers?id=` + row.seller_id"
          >
            {{ row.seller_name }}
          </router-link>
        </template>
        <template #seller_id="{ row }">
          <div>
            <router-link
              class="link-1"
              :to="`/users_management/sellers?id=` + row.seller_id"
              >{{ row.seller_id }}</router-link
            >
          </div>
        </template>
        <template #volume_id="{ row }">
          <div>
            <router-link
              class="link-1"
              :to="`/shipping/volumes?id=` + row.volume_id"
              >{{ row.volume_id }}</router-link
            >
          </div>
        </template>

        <template #volume_last_mile_tracking_number="{ row }">
          <div>
            <router-link
              class="link-1"
              :to="`/shipping/volumes?id=` + row.volume_id"
              >{{ row.volume_last_mile_tracking_number }}</router-link
            >
          </div>
        </template>
        <template #order_number="{ row }">
          <router-link
            class="link-1"
            :to="`/shipping/orders?id=` + row?.volume?.order?.id"
          >
            {{ row?.volume?.order?.order_number }}
          </router-link>
        </template>
        <template #current_status_tracking_code="{ row }">
          {{ row.volume.current_status.tracking_code }}
        </template>
        <template #current_status_title="{ row }">
          {{ row.volume.current_status.title }}
        </template>
        <template #order_created_at="{ row }">
          {{ row.volume.order.created_at | datetime }}
          <span v-if="!row.volume.order.created_at">-</span>
        </template>
        <template #status_payment_done_at="{ row }">
          {{ row.status_payment_done_at | datetime }}
          <span v-if="!row.status_payment_done_at">-</span>
        </template>
        <template #created_at="{ row }">
          {{ row.created_at | datetime }}
        </template>
        <template #real_tax="{ row }">
          {{ row.real_tax ? formatMoney(row.real_tax, "R$") : "-" }}
        </template>
        <template #order_estimated_tax_value="{ row }">
          {{
            formatMoney(
              row.volume.estimated_tax_value,
              row.volume.order.currency,
            )
          }}
        </template>

        <template #order_estimated_duties_tax_value="{ row }">
          {{
            formatMoney(
              row.volume.estimated_duties_tax_value,
              row.volume.order.currency,
            )
          }}
        </template>

        <template #order_estimated_sales_tax_value="{ row }">
          {{
            formatMoney(
              row.volume.estimated_sales_tax_value,
              row.volume.order.currency,
            )
          }}
        </template>

        <template #status="{ row }">
          <NbBadge
            class="w-fit"
            :type="getStatusType(row.status)"
            :text="getStatusText(row.status)"
          />
        </template>

        <template #payment_certificate="{ row }">
          <template v-if="!!row.barcode">
            <a @click="certificate(row.id)">
              <i
                class="far fa-file fa-lg text-dark"
                style="
                  font-size: 18px;
                  position: relative;
                  left: -4px;
                  bottom: 0px;
                "
              ></i>
            </a>
          </template>
          <template v-else> - </template>
        </template>
      </DatatableRoot>
    </section>

    <NbFooter
      class="page-container"
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />
    <!-- modals -->
    <NbModal id="download-modal" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <h5 class="heading-4">Download</h5>
      </template>
      <template v-slot:body>
        {{ $t("dutiesAndTaxesStatusPage.checktheprocessingdownloads") }}
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton
            id="addStatusCheckpointClose"
            class="mx-1"
            variant="secondary"
            data-dismiss="modal"
            @click="$emit('close')"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton class="mx-1" @click="download()">
            {{ $t("start") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <!-- end modals -->
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";

import DutiesAndTaxesStatusService from "@/services/DutiesAndTaxesStatusService";
import DownloadsService from "@/services/DownloadsService";
import CheckpointService from "@/services/CheckpointService";
import SellerService from "@/services/SellerService";

const dutiesAndTaxesStatusService = new DutiesAndTaxesStatusService();
const downloadsService = new DownloadsService();
const checkpointService = new CheckpointService();
const sellerService = new SellerService();

export default {
  name: "DutiesAndTaxes",
  components: {
    NbPageTitle,
    NbTabs,
    DatatableRoot,
    NbBadge,
    NbFooter,
    NbModal,
    NbButton,
  },
  data: () => {
    return {
      selected: [],
      totalDutiesTaxes: -1,
      currentTable: "dutiesTaxes",
      currentItemId: "",
      checkpoints: [],
      sellersOption: [],
    };
  },
  computed: {
    checkpointsOption() {
      return this.checkpoints?.map((checkpoint) => ({
        value: checkpoint.id,
        text: `(${checkpoint.tracking_code}) ${checkpoint.title}`,
      }));
    },
    tabsOptions() {
      return [
        {
          id: "dutiesTaxes",
          label: this.$t("dutiesAndTaxesStatusPage.dutiesTaxes"),
          found: this.totalDutiesTaxes || 0,
          current: this.currentTable,
        },
      ];
    },
    tableActions() {
      return [
        {
          label: this.$t("dutiesAndTaxesStatusPage.download"),
          action: "openDownloadModal",
        },
      ];
    },
    tableColumns() {
      return [
        {
          key: "seller_name",
          label: this.$t("dutiesAndTaxesStatusPage.fields.sellerName"),
        },
        {
          key: "seller_id",
          label: this.$t("dutiesAndTaxesStatusPage.fields.sellerId"),
        },
        {
          key: "volume_id",
          label: this.$t("dutiesAndTaxesStatusPage.fields.volumeId"),
        },
        {
          key: "volume_last_mile_tracking_number",
          label: this.$t(
            "dutiesAndTaxesStatusPage.fields.volumeLastMileTrackingNumber",
          ),
        },
        {
          key: "order_number",
          label: this.$t("dutiesAndTaxesStatusPage.fields.orderNumber"),
        },
        {
          key: "current_status_tracking_code",
          label: this.$t(
            "dutiesAndTaxesStatusPage.fields.currentStatusTrackingCode",
          ),
        },
        {
          key: "current_status_title",
          label: this.$t("dutiesAndTaxesStatusPage.fields.currentStatusTitle"),
        },
        {
          key: "order_created_at",
          label: this.$t("dutiesAndTaxesStatusPage.fields.orderCreatedAt"),
        },
        {
          key: "created_at",
          label: this.$t("dutiesAndTaxesStatusPage.fields.createdAt"),
        },
        {
          key: "status_payment_done_at",
          label: this.$t("dutiesAndTaxesStatusPage.fields.statusPaymentDoneAt"),
        },
        {
          key: "status",
          label: this.$t("dutiesAndTaxesStatusPage.fields.status"),
        },
        {
          key: "payment_certificate",
          label: this.$t("dutiesAndTaxesStatusPage.fields.paymentCertificate"),
        },
        {
          key: "real_tax",
          label: this.$t("dutiesAndTaxesStatusPage.fields.realTax"),
        },
        {
          key: "order_estimated_tax_value",
          label: this.$t(
            "dutiesAndTaxesStatusPage.fields.orderEstimatedTaxValue",
          ),
        },
        {
          key: "order_estimated_duties_tax_value",
          label: this.$t(
            "dutiesAndTaxesStatusPage.fields.orderEstimatedDutiesTaxValue",
          ),
        },
        {
          key: "order_estimated_sales_tax_value",
          label: this.$t(
            "dutiesAndTaxesStatusPage.fields.orderEstimatedSalesTaxValue",
          ),
        },
        { key: "id", label: this.$t("dutiesAndTaxesStatusPage.fields.id") },
      ];
    },
    tableFilters() {
      return [
        {
          label: this.$t("dutiesAndTaxesStatusPage.fields.id"),
          inputs: [
            {
              key: "id",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("dutiesAndTaxesStatusPage.fields.seller"),
          inputs: [
            {
              key: "seller_id",
              type: "select",
              options: this.sellersOption.length
                ? this.sellersOption
                : [
                    {
                      value: null,
                      text: "Loading...",
                      disabled: true,
                    },
                  ],
            },
          ],
        },
        {
          label: this.$t("dutiesAndTaxesStatusPage.fields.orderNumber"),
          inputs: [
            {
              key: "order_number",
            },
          ],
        },

        {
          label: this.$t("dutiesAndTaxesStatusPage.fields.volumeId"),
          inputs: [
            {
              key: "volume_id",
              type: "number",
            },
          ],
        },
        {
          label: this.$t(
            "dutiesAndTaxesStatusPage.fields.volumeLastMileTrackingNumber",
          ),
          inputs: [{ key: "volume_last_mile_tracking_number" }],
        },
        {
          label: this.$t("dutiesAndTaxesStatusPage.fields.currentStatusTitle"),
          inputs: [
            {
              key: "current_status_id",
              type: "select",
              options: this.checkpointsOption,
            },
          ],
        },

        {
          label: this.$t("dutiesAndTaxesStatusPage.fields.realTax"),
          inputs: [
            {
              key: "real_tax",
              type: "number",
            },
          ],
        },

        {
          label: "Status",
          inputs: [
            {
              key: "status",
              type: "select",
              options: [
                { value: null, text: "Select" },
                { value: 0, text: "New" },
                { value: 1, text: "Solved" },
                { value: 2, text: "Priority" },
                { value: 3, text: "In Progress" },
                { value: 4, text: "Waiting for Payment" },
                { value: 5, text: "Sent Again" },
                { value: 6, text: "Payment Done" },
                { value: 7, text: "Pending" },
                { value: 8, text: "Paid Externally" },
                { value: 9, text: "Need Seller Action" },
              ],
            },
          ],
        },
        {
          label: this.$t("dutiesAndTaxesStatusPage.fields.statusPaymentDoneAt"),
          inputs: [
            {
              key: "status_payment_done_at_gte",
              type: "date",
            },
            {
              key: "status_payment_done_at_lte",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("dutiesAndTaxesStatusPage.fields.orderCreatedAt"),
          inputs: [
            {
              key: "order_created_at_gte",
              type: "date",
            },
            {
              key: "order_created_at_lte",
              type: "date",
            },
          ],
        },
        {
          label: this.$t("dutiesAndTaxesStatusPage.fields.createdAt"),
          inputs: [
            {
              key: "created_at_gte",
              type: "date",
            },
            {
              key: "created_at_lte",
              type: "date",
            },
          ],
        },
      ];
    },
  },
  created() {
    if (
      this.$store.state.user.group_name !== "Platform-Finance" &&
      !this.$store.state.user.is_super
    ) {
      this.$router.push("/");
      return;
    }
    this.loadCheckpoints();
  },
  mounted() {
    this.loadSellers();
    this.checkQuery();
  },
  methods: {
    async loadSellers() {
      const { data } = await sellerService.getSellers();

      this.sellersOption = data.data.elements.map((seller) => ({
        text: `(${seller.id}) ${seller.name}`,
        value: seller.id,
      }));
    },
    checkQuery() {
      if (this.$route.query.dutiesTaxes) {
        //this.$emit("switchTab", "alertLogs"); //emit if have more than one tab
        this.loadModalData(this.$route.query.dutiesTaxes);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.dutiesTaxes) {
          this.$router.push({ query: { dutiesTaxes: id } });
        }
        /* this.$router.push({ query: {} }); */
        this.$helpers.openModal("ModalAlertLogsView");
        return;
      }
    },
    switchTab(tab) {
      this.currentTable = tab;
    },
    optionTook(event) {
      this[event]();
    },
    openDownloadModal() {
      this.$helpers.openModal("download-modal");
    },
    loadCheckpoints() {
      checkpointService.getAllCheckpoints().then((response) => {
        this.checkpoints = response.data.data.elements;
      });
    },
    certificate(id) {
      dutiesAndTaxesStatusService.certificate(id).then((response) => {
        window.open(response.data.data.download_url);
      });
    },
    download() {
      downloadsService
        .loadProcessing({
          data_type: "duties_and_taxes_status",
          reference_ids: this.selected,
        })
        .then(() => {
          this.$helpers.closeModal("download-modal");
          this.$router.push("/archives");
        });
    },
    formatTimestamp(string) {
      return this.$helpers.formatTimestamp(string, { hour12: false });
    },
    formatMoney(string, currency) {
      return this.$helpers.formatMoney(string, currency);
    },
    getStatusType(status) {
      if (status == "solved_issue") {
        return "success";
      }
      if (status == "priority_issue") {
        return "danger";
      }
      if (status == "waiting_payment") {
        return "warning";
      }
      if (status == "payment_done") {
        return "success";
      }
      if (status == "pending") {
        return "warning";
      }
      if (status == "paid_external") {
        return "success";
      }
      if (status == "need_seller") {
        return "danger";
      }
      return "";
    },
    getStatusText(status) {
      if (status == "new_issue") {
        return this.$t("dutiesAndTaxesStatusPage.new");
      }
      if (status == "solved_issue") {
        return this.$t("dutiesAndTaxesStatusPage.solved");
      }
      if (status == "priority_issue") {
        return this.$t("dutiesAndTaxesStatusPage.priority");
      }
      if (status == "in_progress_issue") {
        return this.$t("dutiesAndTaxesStatusPage.inProgress");
      }
      if (status == "waiting_payment") {
        return this.$t("dutiesAndTaxesStatusPage.waitingforPayment");
      }
      if (status == "sent_again") {
        return this.$t("dutiesAndTaxesStatusPage.sentAgain");
      }
      if (status == "payment_done") {
        return this.$t("dutiesAndTaxesStatusPage.paymentDone");
      }
      if (status == "pending") {
        return this.$t("dutiesAndTaxesStatusPage.pending");
      }
      if (status == "paid_external") {
        return this.$t("dutiesAndTaxesStatusPage.paidExternally");
      }
      if (status == "need_seller") {
        return this.$t("dutiesAndTaxesStatusPage.needSellerAction");
      }

      return status;
    },
  },
};
</script>
<style scoped></style>
