import api from "./HttpService";

export default class CountryService {
  /**
   * Get countries
   *
   * @param filters
   * @returns {Promise}
   */
  getCountries(filters = {}) {
    return api.get("/v1/countries", {
      params: filters,
    });
  }
}
