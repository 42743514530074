<template>
  <div>
    <ModalHeader>
      <ModalTitle>{{
        $t("ordersPage.removeOrders", { count: orderIds?.length })
      }}</ModalTitle>
    </ModalHeader>

    <p v-if="ordersWithMasterboxes.length === 0">
      {{ $t("wouldYouLikeToRemoveSelectedItems") }}
    </p>

    <p v-if="allOrdersHaveMasterboxes">
      {{ $t("priceTablesPage.alreadyInMasterbox") }}
    </p>
    <div v-else-if="ordersWithMasterboxes.length > 0">
      <p>{{ $t("priceTablesPage.someInMasterbox") }}</p>

      <table width="100%">
        <thead class="top-auto">
          <tr>
            <th class="p-3">ID</th>
            <th>{{ $t("ordersPage.orderNo") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="order in ordersWithMasterboxes"
            :key="order.id"
            class="top-25"
          >
            <td>{{ order.id }}</td>
            <td>{{ order.order_number }}</td>
          </tr>
        </tbody>
      </table>

      <p class="mt-5">
        {{
          $t("ordersPage.toBeRemoved", {
            count: orderIds.length - ordersWithMasterboxes.length,
          })
        }}
      </p>
    </div>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
      <NbButton
        class="ml-2"
        :disabled="loading || allOrdersHaveMasterboxes"
        @click="removeOrders"
      >
        {{ $t("delete") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import OrderLabelService from "@/services/OrderLabelService";

const orderLabelService = new OrderLabelService();

export default {
  components: {
    ModalFooter,
    ModalHeader,
    ModalTitle,
    NbButton,
    ModalClose,
  },
  inject: ["context"],
  props: {
    orderIds: {
      type: Array,
      required: true,
    },
    ordersWithMasterboxes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    allOrdersHaveMasterboxes() {
      return this.orderIds.length === this.ordersWithMasterboxes.length;
    },
  },
  methods: {
    async removeOrders() {
      try {
        this.loading = true;
        await orderLabelService.generateArchivateMultiConfirmation(
          this.orderIds,
        );
        this.$emit("success");
        this.context.close();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
