import api from "./HttpService";

export default class ShipmentService {
  /**
   * Get ShipmentService
   *
   * @param {*} id
   * @returns {Promise}
   */
  getShipment(id = null) {
    return api.get("/v1/shipments/" + id);
  }

  generateShipmentLabel(id) {
    return api.put("/v1/shipments/" + id + "/cn38_label");
  }

  updateActualWeight(id, actual_weight) {
    return api.put(
      "v1/shipments/" + id + "/actual_weight?actual_weight=" + actual_weight,
    );
  }

  /**
   * Get ShipmentService
   *
   * @param offset = number, track = filters
   * @returns {Promise}
   */
  getTwentyShipments(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/shipments`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/shipments?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  getAllShipments() {
    return api.get("/v1/shipments");
  }

  downloadShipments(data) {
    return api.post("/v1/shipments/download", data);
  }

  updateCn38(id, data) {
    return api.put("v1/shipments/" + id + "/cn38", {
      shipment: data,
    });
  }
}
