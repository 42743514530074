<template>
  <div :class="loadingBarClass">
    <div class="loading-bar-base" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LoadingBar",
  computed: {
    ...mapState("config", {
      loadingBar: (state) => state.loadingBar,
    }),
    loadingBarClass: function () {
      return !this.loadingBar ? "loading-bar-off" : this.loadingBar;
    },
  },
};
</script>
