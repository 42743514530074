import api from "./HttpService";

export default class OverpackService {
  /**
   * Set overpack
   *
   * @param data
   * @returns {Promise}
   */
  setOverpack(data = {}) {
    return api.post("/v1/overpacks", data);
  }

  /**
   * Set to dispatch overpack
   *
   * @param data
   * @returns {Promise}
   */
  setToDispatched(id = null) {
    return api.put(`/v1/overpacks/${id}/dispatch_overpack`);
  }

  /**
   * Get overpack
   *
   * @param {*} id
   * @returns {Promise}
   */
  getOverpack(id = null) {
    return api.get("/v1/overpacks/" + id);
  }

  /**
   * Get Twenty overpacks
   *
   * @param offset = number, track = filters
   * @returns {Promise}
   */
  getTwentyOverpacks(offset, track = {}) {
    if (track.limit > 0) {
      offset = (offset - 1) * track.limit;
      return api.get(`/v1/overpacks?&offset=${offset}`, {
        params: track,
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/overpacks?limit=20&offset=" + offset, {
        params: track,
      });
    }
  }

  /**
   * Dispatch overpack
   *
   * @param {*} id
   * @returns {Promise}
   */
  dispatchOverpack(id = null) {
    return api.put("/v1/overpack/sent/" + id);
  }

  /**
   * Get overpacks
   *
   * @param filters
   * @returns {Promise}
   */
  getOverpacks(filters = {}) {
    return api.get("/v1/overpacks", {
      params: filters,
    });
  }

  /**
   * Generate overpack label
   *
   * @param {*} id
   * @returns {Promise}
   */
  generateOverpackLabel(id) {
    return api.put("/v1/overpacks/" + id + "/label");
  }

  /**
   * Delete order
   *
   * @param {*} id
   * @returns {Promise}
   */
  deleteOverpack(id = null) {
    return api.delete("/v1/overpacks/" + id);
  }

  /**
   * Get orders without overpacks
   *
   * @param {*} filters
   * @returns {Promise}
   */
  getOrdersWithoutOverpack(filters = {}) {
    return api.get("/v1/orders-whithout-overpack", {
      params: filters,
    });
  }

  updateOverpack(id = null, data = {}) {
    return api.put("/v1/overpacks/" + id, data);
  }

  /**
   * Download overpacks
   *
   * @param {*} filters
   * @returns {Promise}
   */
  downloadOverpacks(filters = {}) {
    return api.get("/v1/overpacks/download", {
      params: filters,
    });
  }

  /**
   * Get cn23 numbers from Overpack
   *
   * @param {*} id
   * @returns {Promise}
   */
  getCn23Numbers(id) {
    return api.get("/v1/overpacks/" + id + "/cn23_numbers");
  }

  /**
   * Update Many First Mile Information
   * @param {*} data
   * @returns {Promise}
   */
  updateManyOverpacks(data) {
    return api.put("/v1/overpacks/massive_update_first_mile", data);
  }

  /**
   * Update Many First Mile Information
   * @param {*} Object
   * @returns {Promise}
   */
  updateManyStatus(data) {
    return api.put("/v1/overpacks/update_checkpoint", data);
  }
}
