<template>
  <div>
    <header-title :title="$t('platformsPage.title')">
      <template #buttonsTop>
        <b-button
          pill
          variant="primary"
          data-toggle="modal"
          data-target="#addPlatform"
          @click="initializePlatform()"
          >{{ $t("platformsPage.addPlatform") }}</b-button
        >
      </template>
    </header-title>
    <layout-content
      :namespace="namespace"
      :filterable="true"
      :filter-options="filterOptions"
      :all-fields="allFields"
      :fields="fields"
      :items="items"
    >
      <template #cell(actions)="data">
        <span
          role="button"
          class="text-primary"
          data-toggle="modal"
          data-target="#addPlatform"
          :title="`Edit Platform ${data.item.name}`"
          @click="copyPlatform(data.item)"
        >
          <i class="fas fa-edit mx-1 fa-lg"></i>
        </span>
      </template>
    </layout-content>
    <!-- MODALS -->
    <div id="addPlatform" ref="modalRef" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="settingsTitle" class="modal-title">
              {{ $t("platformsPage.AddNewPlatform") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="name"
                    >{{ $t("platformsPage.platformName") }}*</label
                  >
                  <input
                    id="name"
                    v-model="newPlatform.name"
                    type="text"
                    :class="{ 'is-invalid': errors['name'] }"
                    class="form-control"
                    aria-describedby="nameHelp"
                    placeholder="Name"
                  />
                  <div
                    v-for="(error, index) in errors['name']"
                    :key="index"
                    class="invalid-feedback"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="track-credentials mb-3">
                {{ $t("platformsPage.correiosCredentials") }}
              </div>

              <div
                v-for="(value, key, index) in newPlatform.correios_credentials"
                :key="index"
                class="row"
              >
                <div class="col-7">
                  <div class="form-group">
                    <label :for="index"> {{ key }}</label>
                    <input
                      :id="index"
                      v-model="newPlatform.correios_credentials[key]"
                      type="text"
                      :class="{
                        'is-invalid': errors['correios_credentials'],
                      }"
                      class="form-control"
                      :placeholder="key"
                    />
                    <div
                      v-for="(error, errorIndex) in errors[
                        'correios_credentials'
                      ]"
                      :key="errorIndex"
                      class="invalid-feedback"
                    >
                      {{ error }}
                    </div>
                  </div>
                </div>
                <div class="col-5">
                  <div class="botton-button">
                    <button
                      class="btn btn-danger btn-sm"
                      @click="removeDCredential(key)"
                    >
                      - {{ key }}
                    </button>
                  </div>
                </div>
              </div>
              <button
                v-if="!dispachShow"
                class="btn btn-info btn-sm ml-3 mb-2"
                @click="dispachShow = !dispachShow"
              >
                + {{ $t("platformsPage.addDispatch") }}
              </button>
              <button
                v-if="dispachShow"
                class="btn btn-secondary btn-sm ml-3 mb-2"
                @click="dispachShow = !dispachShow"
              >
                - {{ $t("platformsPage.addDispatch") }}
              </button>
              <div v-if="dispachShow" class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="key">{{ $t("platformsPage.key") }}</label>
                    <input
                      id="key"
                      v-model="dkey"
                      type="text"
                      class="form-control"
                      placeholder="Key .."
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="value">{{ $t("platformsPage.value") }}</label>
                    <input
                      id="value"
                      v-model="dvalue"
                      type="text"
                      class="form-control"
                      placeholder="Value .."
                    />
                  </div>
                </div>
              </div>
              <button
                v-if="dispachShow"
                class="btn btn-success btn-sm ml-3 mb-2"
                @click="addDCredential(dvalue, dkey)"
              >
                {{ $t("platformsPage.addDispatch") }}
              </button>
            </div>
          </div>
          <div class="modal-footer">
            <a
              href="#"
              class="btn btn-primary float-right ml-3"
              @click.prevent="addPlatform()"
              >{{ $t("save") }}</a
            >
            <a
              id="addPlatformClose"
              href="#"
              class="btn btn-secondary float-right ml-3"
              data-dismiss="modal"
              @click="resetPlatform()"
              >{{ $t("close") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutContent from "@/components/LayoutContent";
import HeaderTitle from "@/components/HeaderTitle.vue";
import PlatformService from "../../services/PlatformService";
import NProgress from "nprogress";

const platformService = new PlatformService();

export default {
  name: "Platforms",
  components: {
    LayoutContent,
    HeaderTitle,
  },
  data: () => {
    return {
      selectMode: "single",
      newPlatform: {
        name: "",
        correios_credentials: {},
      },
      filter: null,
      selected: [],
      allFields: [],
      isUpdate: false,
      platformsPage: 1,
      namespace: "platforms",
      errors: {},
      dkey: "",
      dvalue: "",
      dispachShow: false,
    };
  },
  computed: {
    items() {
      return this.$store.state[this.namespace].items;
    },
    fields() {
      return [
        { key: "id", label: "ID", sortable: false, thClass: ["small-content"] },
        {
          key: "name",
          label: this.$t("platformsPage.fields.name"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "actions",
          label: this.$t("platformsPage.fields.actions"),
          sortable: false,
          thClass: ["small-content"],
          isCustomizable: false,
          class: "right--20 right-sticky",
        },
      ];
    },
    filterOptions() {
      return [{ key: "name", label: this.$t("platformsPage.fields.name") }];
    },
  },
  created() {
    this.allFields = this.fields;
  },
  methods: {
    loadItems() {
      this.$store.dispatch(this.namespace + "/fetchItems", this.namespace);
    },
    initializePlatform() {
      this.errors = {};
      this.newPlatform.name = "";
      this.isUpdate = false;
    },
    addPlatform() {
      this.errors = {};
      if (this.isUpdate) {
        platformService
          .updatePlatform(this.newPlatform.id, this.newPlatform)
          .then(() => {
            this.loadItems(); //loadItems()  to reload the table
            this.newPlatform = { name: "" };
            document.getElementById("addPlatformClose").click();
          })
          .catch((error) => {
            if (
              Object.prototype.hasOwnProperty.call(error, "response") &&
              Object.prototype.hasOwnProperty.call(error.response, "data") &&
              Object.prototype.hasOwnProperty.call(
                error.response.data,
                "messages",
              )
            ) {
              this.errors = error.response.data.messages[0];
            }
            NProgress.done();
          });
      } else {
        platformService
          .setPlatform(this.newPlatform)
          .then(() => {
            this.loadItems(); //loadItems()  to reload the table
            this.newPlatform = { name: "" };
            document.getElementById("addPlatformClose").click();
          })
          .catch((error) => {
            if (
              Object.prototype.hasOwnProperty.call(error, "response") &&
              Object.prototype.hasOwnProperty.call(error.response, "data") &&
              Object.prototype.hasOwnProperty.call(
                error.response.data,
                "messages",
              )
            ) {
              this.errors = error.response.data.messages[0];
            }
            NProgress.done();
          });
      }
      this.resetPlatform();
    },
    copyPlatform(item) {
      platformService.getPlatform(item.id).then((response) => {
        this.newPlatform = response.data.data;
      });
      this.isUpdate = true;
    },
    removeDCredential(key) {
      delete this.newPlatform.correios_credentials[key];
      this.dispachShow = !this.dispachShow;
      this.dispachShow = !this.dispachShow;
    },
    addDCredential(value, key) {
      this.newPlatform.correios_credentials[key] = value;
      this.dkey = "";
      this.dvalue = "";
    },
    resetPlatform() {
      this.newPlatform = {
        name: "",
        correios_credentials: {},
      };
      this.dispachShow = false;
    },
  },
};
</script>

<style scoped>
.filter-width {
  width: 430px;
}
</style>
