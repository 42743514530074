<template>
  <div class="d-flex align-items-stretch login-page">
    <div class="content-left width-50">
      <div class="d-flex justify-content-center">
        <div class="content-left-wrapper-login">
          <div class="d-flex justify-content-between">
            <div>
              <IconLogo
                v-if="
                  user.platform === 'nobordist' ||
                  user.platform === 'loggi' ||
                  user.platform === 'shipsmart'
                "
                height="2.12rem"
                width="9rem"
                logo-left="#fff"
                logo-right="#fff"
                fill="#fff"
                class="mt-1"
              />
              <AsiaLogoStamp
                v-else-if="user.platform === 'asia'"
                class="mb-3"
                width="210"
                fill="#fff"
                alt="Asia Shipping Logo"
              />
              <LogoStamp
                v-else
                :class="`${user.platform}-logo-login`"
                :image="
                  require(
                    `../../assets/img/PNG/${user.platform}-logo-login.png`,
                  )
                "
                height="auto"
                width="144"
              />
              <div>
                <NbBadge
                  :text="badgeLogoText"
                  :type="site == 'carrier' ? 'gray-50' : 'success-dark'"
                  class="mt-2"
                  text-class="text-ellipsis"
                />
              </div>
            </div>
            <div>
              <LangButton collapse-bg="bg-gray-10" class="mt-1" />
            </div>
          </div>

          <LoginForm
            :errors="errors"
            :loading="loading"
            :platform="user.platform"
            @authenticate="authenticate($event)"
            @openResetPasswordModal="openResetPasswordModal"
          />

          <div>
            <div class="heading-3 mb-1 text-white">
              {{ $t("loginPage.wantoToApp") }}?
            </div>
            <a class="link-2 text-white" target="_blank" :href="appUrl">
              {{ $t("loginPage.goToApp", { val: user.platform }) }}
              >
            </a>
          </div>
        </div>
      </div>
      <footer v-if="user.platform !== 'e-cross'" class="login-footer">
        {{ $t("loginPage.checkOur") }}
        <a
          data-toggle="modal"
          data-target="#modal-agreement"
          class="link-1 text-white"
          >{{ $t("loginPage.generalConditionsService") }}</a
        >
      </footer>
    </div>
    <aside class="content-right width-50" :style="dynamicBackground">
      <div class="d-flex justify-content-center">
        <div v-if="user.platform !== 'e-cross'" class="top-card">
          <div>
            {{ getFirstSlogan() }}
          </div>
          <div>
            {{ getSecondSlogan() }}
          </div>
        </div>
      </div>
      <a
        v-if="user.platform !== 'e-cross'"
        class="support-button"
        href="https://nobordistinc.freshdesk.com/support/home"
      >
        {{ $t("contactUs") }}
        <i class="far fa-comment-alt ml-1"></i>
      </a>
    </aside>
    <div class="modal-wrapper">
      <!-- modals -->
      <NbModal
        id="modal-agreement"
        scrollable
        width="55rem"
        :title="`General Conditions of Service`"
      >
        <template #header>
          <div class="heading-4">{{ `General Conditions of Service` }}</div>
        </template>
        <template #body>
          <Agreement />
        </template>
        <template #footer>
          <form>
            <div>
              <div class="d-flex flex-row-reverse">
                <NbButton data-dismiss="modal" aria-label="Close">
                  {{ $t("loginPage.iAgree") }}
                </NbButton>
              </div>
            </div>
          </form>
        </template>
      </NbModal>
      <NbModal
        id="modalPassword"
        modal-config="modal-dialog-centered"
        width="500px"
      >
        <template #header>
          <div class="heading-4">{{ $t("loginPage.forgotPassword") }}?</div>
        </template>
        <template #body>
          <Alert
            v-for="(error, index) in resetPasswordErrors"
            :key="index"
            class="mb-2"
            type="error"
            icon="far fa-times-circle"
            :message="typeof error === 'string' ? error : error[0]"
          />
          <Alert
            v-if="resetPasswordEmailSent"
            class="mb-2"
            type="success"
            icon="far fa-check-circle"
            :message="`
                ${$t('loginPage.resetPassword')} 
                ${$t('loginPage.checkInbox')}
              `"
          />
          <NbTextInput
            id="reset-email"
            v-model="resetPasswordEmail"
            :name="$t('email')"
            class="mb-2"
            :placeholder="$t('placeholderEmail')"
          />
        </template>
        <template #footer>
          <div class="d-flex justify-content-end">
            <NbButton
              v-if="!resetPasswordEmailSent"
              class="button button-primary float-right"
              @click="resetPassword"
              :disabled="!resetPasswordEmail.trim()"
            >
              <span v-if="loading">
                <span class="spinner-border spinner-border-sm mr-2"></span>
                {{ $t("loading") }}
              </span>
              <span v-if="!loading">{{
                $t("loginPage.sendResetPasswordInstructions")
              }}</span>
            </NbButton>
            <NbButton v-else @click="$helpers.closeModal('modalPassword')">
              {{ $t("close") }}
            </NbButton>
          </div>
        </template>
      </NbModal>
      <!-- end modals -->
    </div>
  </div>
</template>

<script>
const axios = require("axios");

import { mapState } from "vuex";
import IconLogo from "@/components/icons/IconLogo.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import Agreement from "../Agreement";
import PasswordService from "@/services/PasswordService";
import AsiaLogoStamp from "@/components/icons/AsiaLogoStamp.vue";
import LogoStamp from "@/components/icons/LogoStamp.vue";
import LangButton from "@/components/buttons/LangButton.vue";
import LoginForm from "@/components/login/LoginForm.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbModal from "@/components/modal/NbModal.vue";
import Alert from "@/components/alerts/Alert.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";

const passwordService = new PasswordService();

export default {
  name: "Login",
  components: {
    IconLogo,
    NbBadge,
    Agreement,
    AsiaLogoStamp,
    LogoStamp,
    LangButton,
    LoginForm,
    NbButton,
    NbModal,
    Alert,
    NbTextInput,
  },
  data: function () {
    return {
      user: {
        email: "",
        password: "",
        platform: "nobordist",
      },
      errors: [],
      loading: false,
      resetPasswordData: {
        email: "",
        platform: this.setPlatform(),
        url: window.location.href.replace(window.location.pathname, ""),
      },
      resetPasswordEmail: "",
      resetPasswordErrors: [],
      resetPasswordEmailSent: false,
      platformsURL: {
        nobordist: process.env.VUE_APP_NB_URL,
        total: process.env.VUE_APP_TOTAL_URL,
      },
    };
  },
  computed: {
    ...mapState({
      site: (state) => state.site,
    }),
    appUrl() {
      const platform =
        this.user.platform == "nobordist" ? "nb" : this.user.platform;
      const loginPath = `/${platform}-login`;
      if (platform in this.platformsURL) {
        return this.platformsURL[platform].concat(loginPath);
      }

      return this.platformsURL.nobordist.concat(loginPath);
    },
    badgeLogoText() {
      return this.site.toUpperCase();
    },
    dynamicBackground() {
      let background = {
        backgroundImage: `url(${require("@/assets/img/bg-login-nb.png")})`,
      };

      if (this.site === "carrier") {
        background.backgroundImage = `url(${require("@/assets/img/login-banner.jpg")})`;
        return background;
      }
      if (this.user.platform === "e-cross") {
        background.backgroundImage = `url(${require("@/assets/img/bg-login-e-cross.png")})`;
        return background;
      }

      return background;

      /*  return {
        backgroundImage: `url(${
          this.user.platform !== "e-cross"
            ? require("@/assets/img/bg-login-nb.png")
            : require("@/assets/img/bg-login-e-cross.png")
        })`,
      }; */
    },
  },
  beforeMount() {
    this.getSite();
    this.user.platform = this.$helpers.getPlatform();
    this.$store.commit("config/TURN_OFF_LOADING_BAR");
  },
  methods: {
    getSite() {
      const pathName = window.location.pathname;

      if (pathName.includes("-carrier")) {
        this.$store.commit("SET_SITE", "carrier");
        return;
      }
      this.$store.commit("SET_SITE", "admin");
    },
    getFirstSlogan() {
      let finalText = "Ship, track and clear customs.";

      if (this.user.platform === "tiendamia") {
        finalText = "A forma mais barata de comprar";
      }
      if (this.user.platform === "jt") {
        finalText = "Express your online business";
      }

      return finalText;
    },
    getSecondSlogan() {
      let finalText = "Your cross border sales to Latam";
      if (this.user.platform === "e-cross") {
        finalText = "Fell Local, Sell Global";
      }
      if (this.user.platform === "tiendamia" || this.user.platform === "jt") {
        finalText = "";
      }
      return finalText;
    },
    openResetPasswordModal() {
      this.resetPasswordEmail = "";
      this.resetPasswordErrors = [];
      this.resetPasswordEmailSent = false;
    },
    authenticate(user) {
      this.errors = [];
      this.loading = true;
      const platform =
        this.user.platform === "asia" ? "asia_shipping" : this.user.platform;

      axios
        .post(
          process.env.VUE_APP_LOGISTICS_API_URL +
            //`authenticate/${platform}/admin`,
            `authenticate/${platform}/${this.site}`,
          {
            email: user.email,
            password: user.password,
            platform: user.platform,
          },
        )
        .then((response) => {
          const prefix =
            this.user.platform === "nobordist" ? "nb" : this.user.platform;
          localStorage.removeItem("columns_configuration"); //force reset columns_configuration
          this.$store.commit("SET_LOGGEDIN_USER", response.data);
          this.$store.commit("SET_PLATFORM", prefix);
          localStorage.setItem("platform", prefix);
          // axios.defaults.headers.common['Authorization'] = this.$store.state.user.auth_token;
          this.loading = false;
          this.$router.push("/");
          this.$store.dispatch("fetchMainData");
        })
        .catch((error) => {
          this.loading = false;

          if (
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "error",
            ) &&
            Object.prototype.hasOwnProperty.call(
              error.response.data.error,
              "user_authentication",
            )
          ) {
            this.errors.push(error.response.data.error.user_authentication);
          } else {
            this.errors.push(error.message);
          }
        });
    },
    setPlatform() {
      let platform = window.location.pathname
        .replace("/", "")
        .replace("-login", "");
      return platform === "nb" ? "nobordist" : platform;
    },
    resetPassword() {
      this.loading = true;
      this.resetPasswordData.email = this.resetPasswordEmail;
      this.resetPasswordData.platform = this.user.platform;
      this.resetPasswordErrors = [];

      passwordService
        .sendResetPasswordEmail(this.resetPasswordData)
        .then(() => {
          this.loading = false;

          this.resetPasswordEmailSent = true;
        })
        .catch((error) => {
          this.loading = false;

          if (
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.resetPasswordErrors.push(error.response.data.messages);
          } else {
            this.resetPasswordErrors.push(error.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
//new layout
.width-50 {
  width: 50vw;
}
.width-36 {
  width: 36vw;
}
.width-64 {
  width: 64vw;
}

.content-left,
.content-right {
  transition: all 1s ease;
  min-height: 100vh;
  position: relative;
}
//content left
.content-left {
  background-color: var(--backgroundLogin);
  display: flex;
  flex-direction: column;
  & > div {
    flex-grow: 1;
    padding-bottom: 1rem;
  }
}
.content-left-wrapper-login {
  width: 21.5rem;
  margin-top: 2.5rem;
  //background-color: red;
}
.content-left-wrapper-create {
  max-width: 45rem;
  width: 100%;
  padding: 4rem 1rem 0rem 1rem;
}

//content-right
.content-right {
  //background-image: url(../../assets/img/bg-login-nb.png);
  background-size: cover;
}

.form-wrapper {
  margin-top: 5.25rem;
  border-bottom: 2px solid var(--gray-10);
  padding-bottom: 4.56rem;
  margin-bottom: 1.5rem;
}

.login-footer {
  color: #fff;
  text-align: center;
  font: normal normal 600 14px/20px var(--font-family-base);
  letter-spacing: 0px;
  width: 49.7vw;
  height: 2.68rem;
  bottom: 0;
  padding: 0.75rem;
  background: var(--primary-90);
}

.top-card {
  max-width: 21rem;
  padding: 1.5em 3em;
  background-color: var(--backgroundLogin);
  color: var(--white);
  text-align: center;
  font: normal normal normal 16px/24px var(--font-family-base);
  letter-spacing: 0px;
}

.support-button {
  background: var(--primary-70);
  border: 2px solid var(--gray-20);
  color: var(--white);
  border-radius: 20px;
  text-align: left;
  font: normal normal 600 16px/16px var(--font-family-base);
  letter-spacing: 0px;
  padding: 0.56rem 1rem;

  position: absolute;
  bottom: 1.5rem;
  right: 3.9rem;
}
.support-button:hover {
  background: var(--primary-70-hover);
}

//end new layout

.login-banner {
  height: 100vh;
  background-image: url(../../assets/img/login-banner.jpg);
  background-size: cover;
}
.login-wrapper {
  animation: animate-login-screen 2.3s ease-in;
  animation-fill-mode: forwards;
  width: 345px;
}
.login-page {
  min-height: 100vh;
  overflow: auto;
  background: var(--background);
  //background: var(--loginBG); //talvez??
  //color: var(--white);
  //fill: var(--white);

  a {
    margin-top: 0.9rem;
  }
}
@keyframes animate-login-screen {
  0% {
    margin-top: 20%;
    height: 100vh;
    transform: scale(1);
  }

  50% {
    margin-top: 20%;
    height: 100vh;
    transform: scale(1);
  }

  100% {
    margin-top: -5px;
    height: 80px;

    transform: scale(0.5);
  }
}
//old
.login-banner {
  height: 100vh;
  background-image: url(../../assets/img/login-banner.jpg);
  background-size: cover;
}
#app > .container-fluid {
  width: 100% !important;
}
.modal-wrapper {
  height: 0px;
  width: 0px;
  position: absolute;
}

.mirakl-logo-login,
.skypostal-logo-login {
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 5px;
}
.correios-logo-login {
  background-color: #fed525;
  padding: 0.5rem;
  border-radius: 5px;
}
.shopee-logo-login {
  height: 6.25rem;
  width: auto;
  background-color: #ef5436;
  padding: 0.5rem;
  border-radius: 5px;
}
</style>
