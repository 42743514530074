<template>
  <div>
    <ModalHeader>
      <div class="d-flex gap-2">
        <ModalTitle>
          {{ $t("ordersViewPage.title", { id: order?.reference || "-" }) }}
        </ModalTitle>
        <NbBadge
          v-if="order?.current_status?.title"
          :text="order.current_status.title"
        />
        <NbBadge
          v-if="order?.is_dispatched"
          :text="$t('ordersViewPage.dispatched')"
          type="success"
        />
        <NbBadge v-if="order?.prc" text="PRC" type="warning" />
        <NbBadge
          v-if="order?.incoterm"
          :text="order?.incoterm"
          type="warning"
        />
      </div>
    </ModalHeader>
    <Spinner v-if="loading" class="mx-auto" size="md" />
    <div v-if="order">
      <div class="grid grid-cols-3 gap-4">
        <OrderDocuments :order-id="orderId" :volume-id="order?.volume_id" />
        <OrderCardActions
          :order="order"
          @edit="showModalEdit = true"
          @add-order-cost="showAddOrderCost = true"
          @update-financial-status="showUpdateFinancialStatus = true"
          @remove="showConfirmRemoveOrder = true"
        />
      </div>

      <Accordion class="group-data" mode="always-open">
        <AccordionItem class="group-item">
          <AccordionTrigger class="trigger">
            <div>
              {{ $t("ordersViewPage.generalInformationsTitle") }}
              <small class="d-block">
                {{ $t("ordersViewPage.generalInformationsDescription") }}
              </small>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <OrderGeneralInformation :order="order" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem class="group-item">
          <AccordionTrigger class="trigger">
            <div>
              {{ $t("ordersViewPage.numbersAndReferences") }}
              <small class="d-block">
                {{ $t("ordersViewPage.numbersAndReferencesDescription") }}
              </small>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <OrderNumbersAndReference :order="order" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem class="group-item">
          <AccordionTrigger class="trigger">
            <div>
              {{ $t("ordersViewPage.customer") }}
              <small class="d-block">
                {{ $t("ordersViewPage.nameAddressContactInformation") }}
              </small>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <OrderCustomer :order="order" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem class="group-item">
          <AccordionTrigger class="trigger">
            <div>
              {{ $t("ordersViewPage.shipper") }}
              <small class="d-block">
                {{ $t("ordersViewPage.nameAddressContactInformation") }}
              </small>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <OrderShipper :order="order" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem class="group-item">
          <AccordionTrigger class="trigger">
            <div>
              {{ $t("ordersViewPage.items") }} ({{ orderItemsCount }})
              <small class="d-block">
                {{ $t("ordersViewPage.itemsDescription") }}
              </small>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <OrderItemsTable
              :volumes="order.volumes"
              :currency="order.currency"
            />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem class="group-item">
          <AccordionTrigger class="trigger">
            <div>
              {{ $t("ordersViewPage.volumes") }} ({{
                order.volumes?.length || 0
              }})
              <small class="d-block">
                {{ $t("ordersViewPage.volumesDescription") }}
              </small>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <OrderVolumesTable :order="order" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem class="group-item">
          <AccordionTrigger class="trigger">
            <div>
              {{ $t("ordersViewPage.costs") }}
              <small class="d-block">
                {{ $t("ordersViewPage.costsDescription") }}
              </small>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <OrderCostsSummary :order="order" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem class="group-item">
          <AccordionTrigger class="trigger">
            <div>
              {{ $t("ordersViewPage.orderCosts") }}
              <small class="d-block">
                {{ $t("ordersViewPage.orderCostsDescription") }}
              </small>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <OrderGeneralCosts :orderCosts="order.order_costs" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem class="group-item">
          <AccordionTrigger class="trigger">
            <div>
              {{ $t("ordersViewPage.relatedObjects") }}
              <small class="d-block">
                {{ $t("ordersViewPage.relatedObjectsDescription") }}
              </small>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <OrderRelatedObjects :order="order" />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem class="group-item">
          <AccordionTrigger class="trigger">
            <div>
              {{ $t("ordersViewPage.additionalData") }}
              <small class="d-block">
                {{ $t("ordersViewPage.additionalDataDescription") }}
              </small>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <OrderAdditionalData :order="order" />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
    </ModalFooter>

    <Modal v-model="showModalEdit" size="lg">
      <OrderEdit :order="order" @success="updateOrderData" />
    </Modal>

    <Modal v-model="showConfirmRemoveOrder">
      <RemoveOrder :order-id="orderId" @deleted="$emit('load')" />
    </Modal>

    <Modal v-model="showUpdateFinancialStatus">
      <UpdateFinancialStatus
        :order-ids="[orderId]"
        :current-status="order?.financial_status"
        @new-status="setNewStatus"
      />
    </Modal>

    <Modal v-model="showAddOrderCost">
      <AddCosts
        :order-id="orderId"
        :currency="order?.currency"
        @success="onAddGeneralCost"
      />
    </Modal>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import Spinner from "@/components/Spinner.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import { weight as weightFilter } from "@/utils/filters/weight.js";
import { copyClipboard } from "@/directives/copy-clipboard";
import moment from "moment";
import OrderService from "@/services/OrderService";
import OrderDocuments from "./OrderDocuments.vue";
import Modal from "@/components/modal/Modal.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import AccordionTrigger from "@/components/accordion/AccordionTrigger.vue";
import AccordionContent from "@/components/accordion/AccordionContent.vue";
import AccordionItem from "@/components/accordion/AccordionItem.vue";
import RemoveOrder from "./RemoveOrder.vue";
import UpdateFinancialStatus from "./UpdateFinancialStatus.vue";
import AddCosts from "./AddCosts.vue";
import OrderEdit from "./OrderEdit.vue";
import OrderCardActions from "./order/OrderCardActions.vue";
import OrderGeneralInformation from "./order/OrderGeneralInformation.vue";
import OrderNumbersAndReference from "./order/OrderNumbersAndReference.vue";
import OrderCustomer from "./order/OrderCustomer.vue";
import OrderShipper from "./order/OrderShipper.vue";
import OrderItemsTable from "./order/OrderItemsTable.vue";
import OrderVolumesTable from "./order/OrderVolumesTable.vue";
import OrderCostsSummary from "./order/OrderCostsSummary.vue";
import OrderGeneralCosts from "./order/OrderGeneralCosts.vue";
import OrderRelatedObjects from "./order/OrderRelatedObjects.vue";
import OrderAdditionalData from "./order/OrderAdditionalData.vue";

const orderService = new OrderService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    Spinner,
    NbBadge,
    OrderDocuments,
    Modal,
    RemoveOrder,
    UpdateFinancialStatus,
    AddCosts,
    OrderEdit,
    OrderCardActions,
    OrderGeneralInformation,
    Accordion,
    AccordionContent,
    AccordionTrigger,
    AccordionItem,
    OrderNumbersAndReference,
    OrderCustomer,
    OrderShipper,
    OrderItemsTable,
    OrderVolumesTable,
    OrderGeneralCosts,
    OrderCostsSummary,
    OrderRelatedObjects,
    OrderAdditionalData,
  },
  directives: {
    copy: copyClipboard,
  },
  props: {
    orderId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      order: null,
      weight: weightFilter,
      moment,
      showConfirmRemoveOrder: false,
      showUpdateFinancialStatus: false,
      showAddOrderCost: false,
      showModalEdit: false,
    };
  },
  computed: {
    orderItemsCount() {
      return this.order?.volumes?.reduce((count, volume) => {
        count = count + volume.order_items?.length || 0;
        return count;
      }, 0);
    },
  },
  methods: {
    updateOrderData(data) {
      this.showModalEdit = false;
      this.order = {
        ...this.order,
        seller_tax_number: data.seller_tax_number,
        customer_address: data.customer_address,
        customer_city: data.customer_city,
        customer_address_complement: data.customer_address_complement,
        customer_address_number: data.customer_address_number,
        customer_postal_code: data.customer_postal_code,
        customer_state: data.customer_state,
        volumes: data.volumes_attributes.map((item) => {
          const volume = this.order.volumes.find(
            (orderVolume) => orderVolume.id === item.id,
          );
          return {
            ...volume,
            weight: item.weight,
            freight_cost: Number(item.freight_cost),
          };
        }),
      };
    },
    paymentDate(value) {
      return Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
        timeStyle: undefined,
        timeZone: "UTC",
      }).format(new Date(value));
    },
    onAddGeneralCost() {
      this.loadOrder();
      this.$emit("load");
    },
    setNewStatus(newStatus) {
      this.order.financial_status = newStatus;
      this.$emit("load");
    },
    navigateTo(path) {
      this.$router.push(path);
    },
    async loadOrder() {
      try {
        this.loading = true;
        const { data } = await orderService.getOrder(this.orderId);
        this.order = Object.assign(data.data, {
          is_mini: data.data.is_mini ? this.$t("yes") : this.$t("no"),
          is_commercial_destination: data.data.is_commercial_destination
            ? this.$t("yes")
            : this.$t("no"),
        });
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.loadOrder();
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.group-data {
  display: grid;
  gap: 1rem;
  margin-top: 2rem;
  .group-item {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    border: 1px solid var(--gray-05);
  }
}
</style>
