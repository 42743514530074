<template>
  <div>
    <ModalHeader>
      <ModalTitle>Upload Volumes</ModalTitle>
    </ModalHeader>
    <form>
      <NbTextInput
        v-model="form.name"
        id="upload-filename"
        :name="$t('filename')"
        class="mb-4"
      />

      <NbDropFile
        v-model="form.file"
        :accept="['text/csv']"
        id="drop-file"
        label="Upload"
      >
        <div class="text-center">
          <NbIcon icon="upload-cloud" size="36" />
          <p>{{ $t("priceTablesPage.filesUpload") }} volumes.</p>
          <p>{{ $t("priceTablesPage.selectCsv") }}.</p>

          <div class="mt-2">
            <a @click.stop :href="'/files/edit-massive-volumes.csv'">{{
              $t("priceTablesPage.downloadHere")
            }}</a>
            {{ $t("priceTablesPage.ablankTable") }}.
          </div>
        </div>
      </NbDropFile>

      <ModalFooter class="d-flex justify-content-end">
        <ModalClose />
        <NbButton
          :disabled="formIsDisabled"
          type="button"
          @click="upload"
          class="ml-2"
        >
          {{ $t("save") }}
        </NbButton>
      </ModalFooter>
    </form>
  </div>
</template>

<script>
import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

import DownloadsService from "@/services/DownloadsService.js";
const downloadsService = new DownloadsService();
export default {
  components: {
    NbDropFile,
    NbTextInput,
    NbButton,
    ModalFooter,
    ModalClose,
    ModalTitle,
    ModalHeader,
    NbIcon,
  },
  data() {
    return {
      loading: false,
      form: {
        name: "",
        file: "",
      },
    };
  },
  computed: {
    formIsDisabled() {
      return !(this.form.name.trim() && this.form.file) || this.loading;
    },
  },
  methods: {
    async upload() {
      if (this.formIsDisabled) {
        return;
      }
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("file", this.form.file, this.form.file.name);
        formData.append("name", this.form.name);
        // formData.append("item_id", "");
        formData.append("data_type", "volumes_mass_update");
        await downloadsService.loadProcessing(formData);
        this.$router.push("/archives");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
