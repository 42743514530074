export default {
  namespaced: true,
  state: {
    fullScreen: false,
    loadingBar: "loading-bar-off",
  },
  mutations: {
    SET_FULLSCREEN(state, payload) {
      state.fullScreen = payload;
    },
    TURN_OFF_LOADING_BAR(state) {
      state.loadingBar = "loading-bar-off";
    },
    TURN_ON_LOADING_BAR(state) {
      state.loadingBar = "loading-bar-on";
    },
    TURN_ON_LOADING_BAR_SUCCESS(state) {
      state.loadingBar = "loading-bar-success";
      setTimeout(function () {
        state.loadingBar = "loading-bar-off";
      }, 1000);
    },
    TURN_ON_LOADING_BAR_ERROR(state) {
      state.loadingBar = "loading-bar-error";
      setTimeout(function () {
        state.loadingBar = "loading-bar-off";
      }, 3000);
    },
  },
};
