<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("trackingRuleNotificationsPage.sellersPermission") }}
      </ModalTitle>
    </ModalHeader>

    <Spinner v-if="isLoading" size="lg" class="mx-auto" />
    <div v-show="!isLoading" class="grid grid-cols-2 gap-8">
      <div class="heading">
        <h3 class="heading-3 m-0">
          {{
            $t(
              "trackingRuleNotificationsPage.sellersThatCanConfigureTheirOwnRules",
            )
          }}
        </h3>
        <small>
          {{
            $t(
              "trackingRuleNotificationsPage.sellersThatCanConfigureTheirOwnRulesDescription",
            )
          }}
        </small>
      </div>

      <div class="heading">
        <h3 class="heading-3 m-0">
          {{
            $t(
              "trackingRuleNotificationsPage.sellersThatWillUseRulesConfiguredHere",
            )
          }}
        </h3>
        <small>
          {{
            $t(
              "trackingRuleNotificationsPage.sellersThatWillUseRulesConfiguredHereDescription",
            )
          }}
        </small>
      </div>
    </div>

    <div v-show="!isLoading" class="grid grid-cols-2 gap-8">
      <div>
        <draggable
          class="list-group"
          :list="sellersOwnSettingsList"
          group="people"
        >
          <div
            class="list-group-item"
            v-for="element in sellersOwnSettingsList"
            :key="element.name"
          >
            {{ element.name }}
          </div>
        </draggable>
      </div>

      <div>
        <draggable class="list-group" :list="adminSettingsList" group="people">
          <div
            class="list-group-item"
            v-for="element in adminSettingsList"
            :key="element.name"
          >
            {{ element.name }}
          </div>
        </draggable>
      </div>
    </div>
    <ModalFooter class="d-flex justify-content-end gap-2 mt-4">
      <ModalClose />
      <NbButton @click="updatePermissions" :disabled="loading">{{
        $t("save")
      }}</NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import Spinner from "@/components/Spinner.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NotificationPermissionService from "@/services/NotificationPermissionService";

const notificationPermissionService = new NotificationPermissionService();

export default {
  name: "two-lists",
  display: "Two Lists",
  order: 1,
  components: {
    draggable,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    Spinner,
    NbButton,
  },
  inject: ["ruleSettingsContext"],
  data() {
    return {
      sellersOwnSettingsList: [],
      adminSettingsList: [],
      loadingPermissions: false,
      loading: false,
      notificationPermission: {
        seller_settings_ids: [],
        admin_settings_ids: [],
      },
    };
  },
  computed: {
    isLoading() {
      return this.ruleSettingsContext.loading || this.loadingPermissions;
    },
    sellers() {
      return this.ruleSettingsContext.sellers.elements;
    },
  },
  watch: {
    sellers() {
      this.setSellers();
    },
  },
  methods: {
    async updatePermissions() {
      try {
        this.loading = true;
        await notificationPermissionService.update({
          admin_settings_ids: this.adminSettingsList.map((item) => item.id),
          seller_settings_ids: this.sellersOwnSettingsList.map(
            (item) => item.id,
          ),
        });
      } finally {
        this.loading = false;
      }
    },
    setSellers() {
      const ownSettings = this.ruleSettingsContext.sellers.elements.filter(
        (seller) =>
          this.notificationPermission.seller_settings_ids.includes(seller.id),
      );
      const usingAdminSettings =
        this.ruleSettingsContext.sellers.elements.filter(
          (seller) =>
            !this.notificationPermission.seller_settings_ids.includes(
              seller.id,
            ),
        );

      this.sellersOwnSettingsList = ownSettings.map((seller) => ({
        id: seller.id,
        name: `${seller.id} - ${seller.name}`,
      }));
      this.adminSettingsList = usingAdminSettings.map((seller) => ({
        id: seller.id,
        name: `${seller.id} - ${seller.name}`,
      }));
    },
    async loadPermissions() {
      try {
        this.loadingPermissions = true;
        const { data } = await notificationPermissionService.getAll();
        this.notificationPermission = data.data;

        this.setSellers();
      } finally {
        this.loadingPermissions = false;
      }
    },
  },
  mounted() {
    this.loadPermissions();
  },
};
</script>

<style lang="scss" scoped>
.heading {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-color: transparent;
  padding: 1rem;
}
.list-group {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 1rem;
  height: 500px;
  overflow-y: auto;
  &-item {
    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    border-top-width: 1px;
    border-radius: 4px;
    & + .list-group-item {
      margin-top: 0.5rem;
      border-top-width: 1px;
    }
  }
}
</style>
