<template>
  <div>
    <header-title :title="$t('permissionsPage.title')" />
    <layout-content
      :namespace="namespace"
      :filterable="true"
      :filter-options="filterOptions"
      :all-fields="allFields"
      :fields="fields"
      :items="items"
    >
      <template #cell(id)="data">
        <span>
          <router-link
            tag="a"
            :to="`/users_management/permissions/` + data.item.id"
            >{{ data.item.id }}</router-link
          >
        </span>
      </template>
      <template #cell(groups)="data">
        <div v-for="group in data.value" :key="group.id">
          <h6>
            <router-link :to="`/users_management/groups/` + group.id">{{
              group.name
            }}</router-link>
          </h6>
        </div>
      </template>
    </layout-content>
  </div>
</template>

<script>
import LayoutContent from "@/components/LayoutContent";
import HeaderTitle from "@/components/HeaderTitle.vue";

export default {
  name: "Permissions",
  components: {
    LayoutContent,
    HeaderTitle,
  },
  data: () => {
    return {
      selectMode: "single",
      filter: null,
      selected: [],
      allFields: [],

      permissionsPage: 1,
      namespace: "permissions",
    };
  },
  computed: {
    items() {
      return this.$store.state[this.namespace].items;
    },
    fields() {
      return [
        { key: "id", label: "ID", sortable: false, thClass: ["small-content"] },
        {
          key: "name",
          label: this.$t("permissionsPage.fields.name"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "description",
          label: this.$t("permissionsPage.fields.description"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "groups",
          label: this.$t("permissionsPage.fields.groupsName"),
          sortable: false,
          thClass: ["small-content"],
        },
      ];
    },
    filterOptions() {
      return [{ key: "name", label: this.$t("permissionsPage.fields.name") }];
    },
  },
  created() {
    this.allFields = this.fields;
    //this.loadItems();
  },
  methods: {
    loadItems() {
      this.$store.dispatch(this.namespace + "/fetchItems", this.namespace);
    },
  },
};
</script>

<style scoped>
.filter-width {
  width: 430px;
}
</style>
