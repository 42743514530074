import api from "./HttpService";

export default class SuperPlatformService {
  /**
   * Change current platform (super only)
   *
   * @param id
   * @returns {Promise}
   */
  setCurrentPlatform(id) {
    return api.get(`/v1/change_platform/${id}`);
  }

  /**
   * Get current platform (super only)
   *
   * @returns {Promise}
   */
  getCurrentPlatform() {
    return api.get("/v1/active_platform");
  }
}
