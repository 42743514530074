<template>
  <div>
    <ModalHeader>
      <ModalTitle>{{ $t("ordersPage.updateFinancialStatus") }}</ModalTitle>
    </ModalHeader>

    <NbSelectInput
      :options="financialStatuses"
      v-model="newFinancialStatus"
      id="order.financial_status"
    />

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
      <NbButton
        class="ml-2"
        :disabled="loading || !newFinancialStatus"
        @click="updateFinancialStatus"
      >
        {{ $t("save") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import OrderService from "@/services/OrderService";
import NbButton from "@/components/buttons/NbButton.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";

const orderService = new OrderService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbSelectInput,
  },
  inject: ["context"],
  props: {
    orderIds: {
      type: Array,
      required: true,
    },
    currentStatus: {
      type: [String, null],
      default: "",
    },
  },
  data() {
    return {
      newFinancialStatus: "",
      loading: false,
    };
  },
  computed: {
    financialStatuses() {
      return [
        {
          value: "new_order",
          text: this.$t("ordersPage.financial_statuses.new"),
          disabled: this.currentStatus === "new_order",
        },
        {
          value: "to_invoice",
          text: this.$t("ordersPage.financial_statuses.toInvoice"),
          disabled: this.currentStatus === "to_invoice",
        },
        {
          value: "invoiced",
          text: this.$t("ordersPage.financial_statuses.invoiced"),
          disabled: this.currentStatus === "invoiced",
        },
        {
          value: "canceled",
          text: this.$t("ordersPage.financial_statuses.canceled"),
          disabled: this.currentStatus === "canceled",
        },
        {
          value: "not_charged",
          text: this.$t("ordersPage.financial_statuses.notCharged"),
          disabled: this.currentStatus === "not_charged",
        },
      ];
    },
  },
  methods: {
    async updateFinancialStatus() {
      if (
        !this.newFinancialStatus ||
        this.newFinancialStatus === this.currentStatus
      ) {
        return;
      }

      try {
        this.loading = true;
        await orderService.updateFinancialStatus({
          ids: this.orderIds,
          financial_status: this.newFinancialStatus,
        });
        this.$emit("new-status", this.newFinancialStatus);
        this.context.close();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
