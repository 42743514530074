<template>
  <div class="mx-5 mb-5 p-4">
    <div
      class="d-flex flex-row justify-content-center"
      :class="cards.length < 5 ? `main_cards_` + cards.length : 'main_cards'"
    >
      <div v-for="card in cards" :key="card.id" class="card p-4 mt-2">
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <div class="text-center">
            <i class="fa-2x" :class="card.icon" aria-hidden="true"></i>
            <p class="text-primary-p">{{ card.text }}</p>
          </div>
          <div>
            <span class="font-bold text-title" style="font-size: 20px">
              <span v-if="card.currency">R$ </span>
              {{ card.val }}
            </span>
          </div>
        </div>
      </div>
      <!-- 
      <div class="mx-5 mb-5">
        <div class="main_cards">
          <div class="card">
            <i class="fas fa-calculator fa-2x text-lightblue" aria-hidden="true"></i>
            <div class="card_inner">
              <p class="text-primary-p">Saldo Correios</p>
              <span class="font-bold text-title">578</span>
            </div>
          </div>

          <div class="card">
            <i class="fas fa-box fa-2x text-red" aria-hidden="true"></i>
            <div class="card_inner">
              <p class="text-primary-p">Boxed Orders</p>
              <span class="font-bold text-title">2467</span>
            </div>
          </div>

          <div class="card">
            <i class="fas fa-box-open fa-2x text-yellow" aria-hidden="true"></i>
            <div class="card_inner">
              <p class="text-primary-p">Received Orders</p>
              <span class="font-bold text-title">340</span>
            </div>
          </div>

          <div class="card">
            <i
              class="fa fa-thumbs-up fa-2x text-green"
              aria-hidden="true"
            ></i>
            <div class="card_inner">
              <p class="text-primary-p">Number of Likes</p>
              <span class="font-bold text-title">645</span>
            </div>
          </div>
        </div>
      </div>
      -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Cards",

  props: {
    cards: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.text-primary-p {
  color: #a5aaad;
}

.font-bold {
  font-weight: 700;
}

.text-title {
  color: #2e4a66;
}

.text-lightblue {
  color: #469cac;
}

.text-red {
  color: #cc3d38;
}

.text-yellow {
  color: #a98921;
}

.text-green {
  color: #3b9668;
}

.main_cards {
  display: grid; /* flex */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin: 20px 0;
}

.main_cards_1 {
  display: grid; /* flex */
  grid-template-columns: 1fr;
  gap: 20px;
  margin: 20px 0;
}
.main_cards_2 {
  display: grid; /* flex */
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 20px 0;
}
.main_cards_3 {
  display: grid; /* flex */
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin: 20px 0;
}
.main_cards_4 {
  display: grid; /* flex */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin: 20px 0;
}

.card {
  height: 120px;
  width: 250px;
  padding: 15px !important;
  border-radius: 15px;
  background: #ffffff;
  box-shadow:
    3px 3px 9px #dadee2,
    -3px -3px 9px #dadee2;
}

.card_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card_inner > span {
  font-size: $font-size-base + 0.5rem;
}

@media only screen and (max-width: 1600px) {
  .main_cards {
    grid-template-columns: 1fr 1fr;
    gap: 18px;
    margin-bottom: 0;
  }
  .main_cards_2 {
    grid-template-columns: 1fr 1fr;
    gap: 18px;
    margin-bottom: 0;
  }
  .main_cards_3 {
    grid-template-columns: 1fr 1fr;
    gap: 18px;
    margin-bottom: 0;
  }
  .main_cards_4 {
    grid-template-columns: 1fr 1fr;
    gap: 18px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 855px) {
  .main_cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }
  .main_cards_2 {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }
  .main_cards_3 {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }
  .main_cards_4 {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 480px) {
}
</style>
