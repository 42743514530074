<template>
  <div>
    <div
      class="d-flex flex-column align-items-start flex-md-row justify-content-md-between"
    >
      <NbPageTitle :title="$t('sellersPage.title')" />
      <div class="d-flex flex-column">
        <NbButton class="mb-2 text-left" @click="sellerCreateModal = true">
          <NbIcon icon="plus" :size="18" />
          {{ $t("sellersPage.addNewSeller") }}
        </NbButton>
        <NbButton
          class="text-left"
          variant="secondary"
          @click="onOpenImportSellerModal"
        >
          <NbIcon icon="upload" :size="18" />
          {{ $t("sellersPage.importSeller") }}
        </NbButton>
      </div>
    </div>

    <section class="page-container mt-4">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="onSwitchTab" />

      <keep-alive>
        <AllSellersTable
          v-if="currentTab === tabs.all"
          @sellerClick="showSellerData"
          @count="sellersCount.all = $event"
          :ref="tabs.all"
        />
        <MultiplesSellersTable
          v-else-if="currentTab === tabs.multiples"
          :search-params="{ is_multiple: true }"
          @sellerClick="showSellerData"
          @count="sellersCount.multiples = $event"
          :ref="tabs.multiples"
        />
        <SimplesSellersTable
          v-else-if="currentTab === tabs.simples"
          :search-params="{ is_multiple: false }"
          @sellerClick="showSellerData"
          @count="sellersCount.simples = $event"
          :ref="tabs.simples"
        />
      </keep-alive>
    </section>

    <Modal v-model="showImportSellerModal">
      <ImportSeller @success="onImportSeller" />
    </Modal>

    <Modal v-model="sellerCreateModal" size="lg">
      <SellerCreate @refresh="loadItems" />
    </Modal>

    <Modal v-model="sellerDataModal" size="lg" @close="setUrlSearchParam('id')">
      <Seller @updated="updateItem" />
    </Modal>
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import Modal from "@/components/modal/Modal.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import SellerCreate from "./components/SellerCreate.vue";
import Seller from "./components/Seller.vue";
import queryParamsMixin from "@/mixins/query-params-mixin.js";
import AllSellersTable from "./components/SellersTable.vue";
import MultiplesSellersTable from "./components/SellersTable.vue";
import SimplesSellersTable from "./components/SellersTable.vue";
import ImportSeller from "./components/ImportSeller.vue";

import CountryService from "@/services/CountryService.js";
const countryService = new CountryService();

const Tab = {
  ALL: "all",
  MULTIPLES: "multiples",
  SIMPLES: "simples",
};

export default {
  name: "Sellers",
  mixins: [queryParamsMixin],
  components: {
    NbButton,
    NbIcon,
    Modal,
    NbPageTitle,
    NbTabs,
    SellerCreate,
    AllSellersTable,
    MultiplesSellersTable,
    SimplesSellersTable,
    ImportSeller,
    Seller,
  },
  provide() {
    const sellersContext = {};
    Object.defineProperty(sellersContext, "countries", {
      get: () => this.countries,
      enumerable: true,
    });

    return {
      sellersContext,
    };
  },
  data: () => {
    return {
      sellersCount: {
        all: undefined,
        multiples: undefined,
        simples: undefined,
      },
      currentTab: Tab.ALL,
      tabs: {
        all: Tab.ALL,
        multiples: Tab.MULTIPLES,
        simples: Tab.SIMPLES,
      },
      downloadSellersModal: false,
      sellerCreateModal: false,
      sellerDataModal: false,
      showImportSellerModal: false,
      countries: [],
    };
  },
  beforeMount() {
    const { id } = this.$route.query;
    if (!isNaN(Number(id))) {
      this.showSellerData(id);
    }
  },
  methods: {
    onSwitchTab(tab) {
      this.currentTab = tab;
    },
    showSellerData(id) {
      this.setUrlSearchParam("id", id);
      this.sellerDataModal = true;
    },
    optionTook(event) {
      this[event]();
    },
    onDownloadSellers() {
      this.downloadSellersModal = true;
    },
    onOpenImportSellerModal() {
      this.errors = [];
      this.showImportSellerModal = true;
    },
    loadItems() {
      this.$refs?.[this.currentTab]?.loadItems();
    },
    updateItem(data) {
      this.$refs?.[this.currentTab]?.updateItem(data);
    },
    onImportSeller() {
      this.showImportSellerModal = false;
      this.loadItems();
    },
    async loadCountries() {
      const { data } = await countryService.getCountries();
      this.countries = data.data;
    },
  },
  computed: {
    buttonOptions() {
      return [
        {
          text: this.$t("sellersPage.downloadSellers"),
          value: "onDownloadSellers",
        },
      ];
    },
    tabsOptions() {
      return [
        {
          id: Tab.ALL,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.sellersCount.all,
        },
        {
          id: Tab.MULTIPLES,
          label: this.$t("sellersPage.multiples"),
          current: this.currentTab,
          found: this.sellersCount.multiples,
        },
        {
          id: Tab.SIMPLES,
          label: this.$t("sellersPage.simples"),
          current: this.currentTab,
          found: this.sellersCount.simples,
        },
      ];
    },
  },
};
</script>
<style scoped></style>
