import api from "./HttpService";

export default class ExternalApiLog {
  /**
   * Get ExternalApiLog
   *
   * @param {*} id
   * @returns {Promise}
   */
  getExternalApiLog(id = null) {
    return api.get("/v1/external_apis_logs/" + id);
  }

  /**
   * Get ExternalApiLog
   *
   * @param offset = number, track = filters
   * @returns {Promise}
   */
  getTwentyExternalApiLogs(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/external_apis_logs`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/external_apis_logs?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  getAllExternalApiLogs() {
    return api.get("/v1/logs");
  }
}
