<template>
  <div class="relative" :class="`${size}`">
    <div v-click-outside="closeCollapse">
      <a
        class="nb-button notification-button-option"
        :id="`${id}-1`"
        :class="`${size}`"
        v-b-toggle
        :href="`#${id}`"
        @click.prevent
      >
        <span
          v-if="notifications.count > 0"
          class="badge badge-danger notification-badge"
          style="position: absolute; top: -4px; right: 1px; font-size: 0.55rem"
        >
          {{ notifications.count }}
        </span>
        <i v-if="icon" class="mr-2" :class="icon"></i>
        <slot>Options</slot>
      </a>
      <b-collapse
        :id="id"
        class="mt-0 colapse-card-wrapper"
        :style="`width: ${optionsWidth}px; ${expandTo}: 0`"
        v-model="visible"
      >
        <b-card class="notifications-card">
          <div class="d-flex justify-content-between mb-3">
            <div>
              {{ $t("myNotificationsListPage.title") }}
            </div>
            <div></div>
          </div>
          <Spinner v-if="loading" class="mx-auto" size="md" />

          <div v-else>
            <div
              v-if="notifications.count === 0"
              class="notification-button-options d-flex justify-content-start"
              @click="
                closeCollapse();
                goTo({ name: 'user_notifications' });
              "
            >
              <div class="notification-status">
                <NbStatus :type="''" />
              </div>
              <div>
                <span class="notification-message-title">
                  You don't have new notifications</span
                >
              </div>
            </div>
            <div
              v-for="(notification, index) in notifications.elements"
              :key="index + '-notf'"
              :class="notification.disabled ? 'text-disabled' : ''"
              @click="notification.disabled ? null : emitClicked(notification)"
            >
              <div
                class="notification-button-options d-flex justify-content-start"
                @click="
                  closeCollapse();
                  goTo({
                    name: 'user_notifications',
                    query: { userNotificationId: notification.id },
                  });
                "
              >
                <div class="notification-status">
                  <NbStatus :type="getStatusType(notification)" />
                </div>
                <div>
                  <span class="notification-message-title">
                    {{ notificationTitles[notification.title] }}:
                  </span>
                  <span class="notification-message-body">{{
                    notification.body
                  }}</span>
                  <div class="notification-date mt-2">
                    {{
                      $helpers.formatShortTime(notification.created_at, {
                        hour: "numeric",
                        minute: "numeric",
                      })
                    }}
                    •
                    {{
                      $helpers.formatShortDate(notification.created_at, {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                    }}
                    ({{ timeAgo(notification.created_at) }})
                  </div>
                </div>
              </div>
            </div>
            <div
              class="link-2 all-notification-link notification-button-options"
              @click="
                closeCollapse();
                goTo({ name: 'user_notifications' });
              "
            >
              {{ $t("myNotificationsListPage.allNotifications") }} >
            </div>
          </div>
        </b-card>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { directive } from "v-click-outside";
import NbStatus from "@/components/alerts/NbStatus.vue";
import Spinner from "@/components/Spinner.vue";
import NotificationService from "../../services/NotificationsService";

const notificationsService = new NotificationService();

const worker = new Worker("/workers/worker.js");

export default {
  name: "NbNotificationsBtn",
  directives: { clickOutside: directive },
  components: { NbStatus, Spinner },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    optionsWidth: {
      type: [Number, String],
      required: false,
    },
    expandTo: {
      type: String,
      default: "bottom",
    },
  },
  data() {
    return {
      content: this.value,
      visible: false,
      notifications: {
        elements: [],
        count: 0,
      },
      loading: false,
    };
  },
  watch: {
    visible(data) {
      if (data) {
        this.loadNotifications();
      }
    },
  },
  methods: {
    emitClicked(option) {
      this.content = option.value;
      this.$emit("input", option.value);
    },
    closeCollapse() {
      this.visible = false;
    },
    goTo(event) {
      this.$router.push(event);
    },
    getStatusType(notification) {
      if (notification.is_new) {
        if (notification.title === "finance") {
          return "success";
        }
        if (notification.title === "tracking_issue") {
          return "warning";
        }
        if (notification.title === "insufficient_balance") {
          return "danger";
        }
        return null;
      }
      return "";
    },
    capitalizeWords(string) {
      if (typeof string === "string") {
        string = string.replace("_", " ");
        string = string.replace("-", " ");
        return string.replace(/(?:^|\s)\S/g, (stringChar) => {
          return stringChar.toUpperCase();
        });
      }
      return string;
    },
    timeAgo(time) {
      if (typeof time === "string") {
        time = +new Date(time);
      } else if (typeof time === "object") {
        if (time.constructor === Date) time = time.getTime();
      } else {
        time = +new Date();
      }

      const timeFormat = [
        [60, this.$t("userNotificationsPage.seconds"), 1],
        [120, this.$t("userNotificationsPage.minuteAgo"), "1 minute from now"],
        [3600, this.$t("userNotificationsPage.minutes"), 60],
        [7200, this.$t("userNotificationsPage.hourAgo"), "1 hour from now"],
        [86400, this.$t("userNotificationsPage.hours"), 3600],
        [172800, this.$t("userNotificationsPage.yesterday"), "Tomorrow"],
        [604800, this.$t("userNotificationsPage.days"), 86400],
        [1209600, this.$t("userNotificationsPage.lastWeek"), "Next week"],
        [2419200, this.$t("userNotificationsPage.weeks"), 604800],
        [4838400, this.$t("userNotificationsPage.lastMonth"), "Next month"],
        [29030400, this.$t("userNotificationsPage.months"), 2419200],
        [58060800, this.$t("userNotificationsPage.lastYear"), "Next year"],
        [2903040000, this.$t("userNotificationsPage.years"), 29030400],
        [5806080000, "Last century", "Next century"],
        [58060800000, "centuries", 2903040000],
      ];
      let seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      let i = 0;
      let format = 0;
      while ((format = timeFormat[i++]))
        // eslint-disable-line
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
    async loadNotifications() {
      try {
        this.loading = true;
        const response = await notificationsService.getTwentyUserNotifications(
          1,
          10,
          {
            is_new: true,
          },
        );
        this.notifications = {
          elements: response.data.data.elements,
          count: response.data.data.count || 0,
        };
      } finally {
        this.loading = false;
      }
    },
    startWorker() {
      const token = this.$store.state.user?.auth_token;

      worker.postMessage({
        event: "start",
        url:
          process.env.VUE_APP_LOGISTICS_API_URL +
          "/v1/user_notifications?is_new=true&offset=0&limit=10",
        token,
      });

      worker.onmessage = ({ data }) => {
        if (data?.error) {
          return;
        }

        if (data?.data?.count >= 0) {
          if (data.data.count > this.notifications.count) {
            this.$helpers.toast(
              this.$t("myNotificationsListPage.youHaveNewNotification"),
              "success",
            );
          }
          this.notifications = {
            elements: data.data.elements || [],
            count: data.data.count || 0,
          };
        }
      };
    },
    listeners() {
      this.$root.$on("reloadNotifications", () => {
        this.loadNotifications();
      });
    },
  },
  computed: {
    notificationTitles() {
      return {
        0: this.$t("notificationsPage.finance"),
        1: this.$t("notificationsPage.insufficientBalance"),
        2: this.$t("notificationsPage.trackingIssue"),
        3: this.$t("notificationsPage.newTicket"),
        4: this.$t("notificationsPage.dataUpdate"),
        5: this.$t("notificationsPage.contractPermission"),
        6: this.$t("notificationsPage.contractUpdate"),
        7: this.$t("notificationsPage.priceTableUpdate"),
      };
    },
  },
  created() {
    this.listeners();
    this.loadNotifications();
    this.startWorker();
  },
  beforeDestroy() {
    worker.postMessage({ event: "stop" });
    worker?.terminate();
  },
};
</script>

<style scoped>
.notification-badge {
  transition: all 0.3s ease;
  padding: 3px 5px 2px 5px;
}
.fix-top {
  margin-top: 0.44rem;
}
.nb-button {
  text-decoration: none;
  font-weight: bolder;
  border-radius: 4px;
  padding: 0.7rem 1.19rem;
  font: normal normal 600 12px/18px Nunito Sans;
  transition: 500ms;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
}
.nb-button.notification-button-option {
  border: 1px solid var(--gray-05);
  color: var(--black);
  background-color: var(--gray-05);
  transition: 500ms;
}
.nb-button.notification-button-option:hover {
  border: 1px solid var(--gray-10);
  background-color: var(--gray-10);
  color: var(--black);
}
.nb-button.notification-button-option:focus {
  border: 1px solid var(--gray-10);
  background-color: var(--gray-10);
  color: var(--black);
  /* 
  border: 1px solid var(--gray-60);
  background: var(--gray-05);
   */
}
.nb-button.button-icon {
  padding: 0.44rem 0.94rem;
}
.nb-button.sm {
  font: normal normal bold 12px/18px Nunito Sans;
  padding: 0.6rem 0.61rem;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
}
.nb-button.xs {
  font: normal normal 600 14px/20px Nunito Sans;
  padding: 0.3rem 0.5rem;
}
.notification-button-options:hover,
.notification-button-options > .button-label:hover {
  cursor: pointer;
}
.button.select-button.disabled,
.text-disabled {
  color: var(--gray-60);
  background: var(--gray-10) 0% 0% no-repeat padding-box;
}
.text-disabled > .notification-button-options:hover,
.text-disabled > .notification-button-options > .button-label:hover {
  cursor: not-allowed;
  background-color: var(--gray-05);
}

.button-label {
  color: var(--black);
  text-align: center;
  font: normal normal normal 12px/18px Nunito Sans;
}

.notifications-card > .card-body {
  padding: 0.93rem 0.88rem;
  max-height: 80vh;
  overflow-y: auto;
}
.card-body {
  z-index: 3;
  background: var(--gray-05);
  box-shadow: 4px 4px 12px var(--gray-10);
  border-radius: 4px 4px 0px 0px;
  padding: 0px;
  border-radius: 4px !important;

  /* border: 1px solid var(--gray-10); */
  border-top: 1px solid var(--gray-10);
  border-right: 2px solid var(--gray-10);
  border-bottom: 2px solid var(--gray-10);
  border-left: 2px solid var(--gray-10);
}
.card {
  border-top: 0px !important;
  border-right: 1px solid var(--gray-05) !important;
  border-bottom: 1px solid var(--gray-05) !important;
  border-left: 1px solid var(--gray-05) !important;
  color: black !important;
  border-radius: 0px 0px 4px 4px;
  background: transparent;
  flex-direction: column;
  justify-content: center;
}
.relative {
  position: relative;
}
.notification-button-options {
  text-align: left;
  /* width: 100%; */
  padding: 1rem 0.5rem;
  border-top: 1px solid var(--gray-10);
}
.notification-button-options:hover {
  background-color: var(--gray-10);
}

.colapse-card-wrapper {
  position: absolute;
  top: 2.3rem;
  width: 100%;
}
.all-notification-link {
  position: sticky;

  display: block;
  padding: 0.6rem 0.5rem;
  border-top: 0px solid var(--gray-10);
  border-radius: 4px;
  bottom: -16px;
  background: var(--gray-05);
}
.notification-status {
  margin-right: 0.5rem;
  height: 6px;
  width: 6px;
}
.notification-date {
  color: var(--gray-60);
  font: normal normal normal 12px/16px Nunito Sans;
  letter-spacing: 0px;
}
.notification-message-title {
  text-align: left;
  font: normal normal normal 14px/20px Nunito Sans;
  letter-spacing: 0px;
}
.notification-message-body {
  text-align: left;
  font: normal normal bold 14px/20px Nunito Sans;
  letter-spacing: 0px;
}
</style>
