<template>
  <div>
    <NbButton
      variant="secondary"
      class="w-100"
      :disabled="!userCanChangePassword"
      @click="showModalResetPassword()"
    >
      {{ $t("usersViewPage.resetUserPassword") }}
    </NbButton>

    <Modal v-model="isModalResetPasswordOpen">
      <ModalHeader>
        <ModalTitle>
          {{ $t("usersViewPage.resetUserPassword") }}
        </ModalTitle>
      </ModalHeader>

      <div>
        <i18n path="usersViewPage.resetUserPasswordWarning">
          <template #user>
            <strong>{{ user.name }}</strong>
          </template>
        </i18n>
        <form @submit.prevent="onResetPassword()" class="my-4">
          <NbPassInput
            v-model="formData.newPassword"
            :name="$t('password')"
            id="new_password"
            required
            type="password"
            :placeholder="$t('userProfilePage.newPassConfirm')"
            :error="[errors.get('new_password')]"
            @input="validateNewPassword()"
          />
        </form>
      </div>

      <ModalFooter class="d-flex justify-content-end gap-2">
        <ModalClose />
        <NbButton
          @click="onResetPassword()"
          :disabled="loading || !formIsValid"
          class="d-flex gap-2 align-items-center"
        >
          <Spinner v-if="loading" />
          {{ $t("save") }}
        </NbButton>
      </ModalFooter>
    </Modal>

    <Modal v-model="isModalConfirmPasswordOpen">
      <ConfirmPasswordModal
        v-model="formData.currentUserPassword"
        @input="errors.clear('password')"
        :error="errors.get('password')"
        @submit="updatePassword()"
        :loading="loading"
      />
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbPassInput from "@/components/input/text/NbPassInput.vue";
import ConfirmPasswordModal from "../../../account/components/ConfirmPasswordModal.vue";
import Spinner from "@/components/Spinner.vue";
import Errors from "@/utils/Errors.js";
import UsersService from "@/services/UsersService";
import { mapState } from "vuex";

const usersService = new UsersService();

const userGroupsThatAdminCanChange = [
  "Platform-Operation",
  "Platform-Viewer",
  "Seller-Operation",
  "Seller-Viewer",
  "Seller-Admin",
];
const userGroupsThatOperationCanChange = [
  "Seller-Operation",
  "Seller-Viewer",
  "Seller-Admin",
];

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    NbButton,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalClose,
    ModalFooter,
    ConfirmPasswordModal,
    NbPassInput,
    Spinner,
  },
  data() {
    return {
      loading: false,
      errors: new Errors(),
      isModalResetPasswordOpen: false,
      isModalConfirmPasswordOpen: false,
      formData: {
        currentUserPassword: "",
        newPassword: "",
      },
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user,
    }),
    userCanChangePassword() {
      return (
        (this.currentUser.group_name === "Platform-Admin" &&
          userGroupsThatAdminCanChange.includes(this.user.group_name)) ||
        (this.currentUser.group_name === "Platform-Operation" &&
          userGroupsThatOperationCanChange.includes(this.user.group_name))
      );
    },
    formIsValid() {
      return (
        this.formData.newPassword.trim() && !this.errors.has("new_password")
      );
    },
  },
  methods: {
    showModalResetPassword() {
      if (this.userCanChangePassword) {
        this.isModalResetPasswordOpen = true;
      }
    },
    onResetPassword() {
      this.isModalConfirmPasswordOpen = true;
    },
    async updatePassword() {
      if (!this.formData.newPassword.trim()) {
        return;
      }
      try {
        this.loading = true;
        const { data } = await usersService.resetUserPassword(this.user.id, {
          new_password: this.formData.newPassword,
          password: this.formData.currentUserPassword,
        });

        this.$toast.success(data.messages[0] || "Success");

        this.formData = {
          currentUserPassword: "",
          newPassword: "",
        };

        this.isModalConfirmPasswordOpen = false;
        this.isModalResetPasswordOpen = false;
      } catch (error) {
        this.errors.set("password", [
          error.response.data.messages[0] || "Something unexcepted happened.",
        ]);
      } finally {
        this.loading = false;
      }
    },
    validateNewPassword() {
      let errors = [];
      if (this.formData.newPassword.match(/\s/)) {
        errors.push(this.$t("errorMessages.whiteSpace"));
      }
      if (this.formData.newPassword.length < 11) {
        errors.push(this.$t("errorMessages.minLength", { value: 11 }));
      }
      if (!this.formData.newPassword.match(/[a-z]/)) {
        errors.push(this.$t("errorMessages.minLowercase", { value: 1 }));
      }
      if (!this.formData.newPassword.match(/[A-Z]/)) {
        errors.push(this.$t("errorMessages.minUppercase", { value: 1 }));
      }
      if (!this.formData.newPassword.match(/\d/)) {
        errors.push(this.$t("errorMessages.minNumber", { value: 1 }));
      }
      if (!this.formData.newPassword.match(/\W/)) {
        errors.push(this.$t("errorMessages.minSpecialCharacter", { value: 1 }));
      }

      if (errors.length && this.formData.newPassword.length) {
        this.errors.set("new_password", [errors.join(", ")]);
        return;
      }

      this.errors.clear("new_password");
    },
  },
};
</script>

<style lang="scss" scoped></style>
