<template>
  <div>
    <NbPageTitle
      class="pb-3 page-container"
      :title="$t('userNotificationsPage.title')"
      :subTitle="$t('userNotificationsPage.subTitle')"
    >
    </NbPageTitle>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <NbTablev2
        tableOf="userNotificationsTable"
        :namespace="namespace"
        ref="userNotificationsTable"
        :clickable="true"
        :allFields="allFields"
        :fields="fields"
        :filterOptions="filterOptions"
        @reloadFields="fields = $event"
        @total="totalNotifications = $event"
        @clickedRow="showMessage(false, $event)"
      >
        <template #cell(created_at)="data">
          <span
            :id="`usernotification-${data.item.id}`"
            :class="data.item.is_new ? 'font-weight-bold pointer' : 'pointer'"
          >
            {{ data.item.created_at ? timeAgo(data.item.created_at) : "-" }}
          </span>
        </template>
        <template #cell(title)="data">
          <span
            :class="data.item.is_new ? 'font-weight-bold pointer' : 'pointer'"
          >
            {{ userNotificationTitles[data.item.title] }}
          </span>
        </template>
        <template #cell(body)="data">
          <span
            :class="data.item.is_new ? 'font-weight-bold pointer' : 'pointer'"
          >
            {{ displayMaxCharacters(data.item.body, 130) }}
          </span>
        </template>

        <template #cell(is_new)="data">
          <NbBadge
            class="d-flex"
            :text="
              data.item.is_new ? $t('sent') : $t('notificationsPage.readed')
            "
            :type="!data.item.is_new ? 'success' : 'attention-dark'"
          />
        </template>
      </NbTablev2>
    </section>

    <NbFooter
      class="page-container"
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />

    <Modal v-model="isModalMessageOpen">
      <ModalMessage
        @reload="reloadNotifications"
        :notificationData="selectedNotification"
      />
    </Modal>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalMessage from "@/views/notifications/send_notifications/components/ModalMessage.vue";
import Modal from "@/components/modal/Modal.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";

import UntoastedNotificationService from "../../../services/UntoastedNotificationService";
import PendingActionService from "../../../services/PendingActionService";
import { NotificationsTitle } from "../../../constants";

const untoastedNotificationService = new UntoastedNotificationService();
const pendingActionService = new PendingActionService();

export default {
  name: "Notifications",

  components: {
    NbPageTitle,
    NbTabs,
    NbTablev2,
    NbFooter,
    ModalMessage,
    NbBadge,
    Modal,
  },
  data: () => {
    return {
      pendingAction: {
        id: null,
        status: null,
        data: {
          contract: {},
        },
      },
      fields: [],
      namespace: "user_notifications",
      selectedNotification: {},
      totalNotifications: -1,
      currentTable: "all",
      currentItemId: "",
      isModalMessageOpen: false,
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("userNotificationsPage.title"),
          found: this.totalNotifications,
          current: this.currentTable,
        },
      ];
    },
    allFields() {
      return [
        {
          key: "created_at",
          label: this.$t("userNotificationsPage.fields.data"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "title",
          label: this.$t("userNotificationsPage.fields.title"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "body",
          label: this.$t("userNotificationsPage.fields.body"),
          sortable: false,
          thClass: ["large-content"],
        },
        {
          key: "is_new",
          label: this.$t("userNotificationsPage.filterOptions.status"),
          sortable: false,
          thClass: ["large-content"],
        },
      ];
    },
    filterOptions() {
      return [
        {
          key: "title",
          label: this.$t("userNotificationsPage.filterOptions.title"),
          type: "select",
          options: [
            { value: "finance", text: "Finance" },
            { value: "insufficient_balance", text: "Insufficient Balance" },
            { value: "tracking_issue", text: "Tracking Issue" },
          ],
        },
        {
          key: "body",
          label: this.$t("userNotificationsPage.filterOptions.body"),
        },
        {
          key: "is_new",
          label: this.$t("userNotificationsPage.filterOptions.status"),
          type: "select",
          options: [
            {
              value: 1,
              text: this.$t("sent"),
            },
            {
              value: false,
              text: this.$t("userNotificationsPage.filterOptions.read"),
            },
          ],
        },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
      ];
    },
    userNotificationTitles() {
      return {
        [NotificationsTitle.FINANCE]: this.$t(
          "userNotificationsPage.userNotificationTitles.finance",
        ),
        [NotificationsTitle.INSUFFICIENT_BALANCE]: this.$t(
          "userNotificationsPage.userNotificationTitles.insufficientBalance",
        ),
        [NotificationsTitle.TRACKING_ISSUE]: this.$t(
          "userNotificationsPage.userNotificationTitles.trackingIssue",
        ),
        [NotificationsTitle.NEW_TICKET]: this.$t(
          "userNotificationsPage.userNotificationTitles.newTicket",
        ),
        [NotificationsTitle.DATA_UPDATE]: this.$t(
          "userNotificationsPage.userNotificationTitles.dataUpdate",
        ),
        [NotificationsTitle.CONTRACT_PERMISSION]: this.$t(
          "userNotificationsPage.userNotificationTitles.contractPermission",
        ),
        [NotificationsTitle.CONTRACT_UPDATE]: this.$t(
          "userNotificationsPage.userNotificationTitles.contractUpdate",
        ),
        [NotificationsTitle.PRICE_TABLE_UPDATE]: this.$t(
          "userNotificationsPage.userNotificationTitles.priceTableUpdate",
        ),
      };
    },
  },
  created() {
    this.fields = this.allFields;
  },
  mounted() {
    this.checkQuery();
    if (this.$route.query.modal_notification) {
      document
        .getElementById(
          "modal-notification-" + this.$route.query.modal_notification,
        )
        .click();
    }
  },
  methods: {
    checkQuery() {
      if (this.$route.query.userNotificationId) {
        this.openMessageId(this.$route.query.userNotificationId);
        return;
      }
    },
    openMessageId(id) {
      const element = document.getElementById(`usernotification-${id}`);
      if (element) {
        element.click();
      }
    },
    showMessage(bool, notification) {
      this.respondRequest(bool, notification);
      this.selectedNotification = notification;
      this.isModalMessageOpen = true;
    },
    reloadNotifications() {
      this.$refs?.userNotificationsTable?.getData();
    },
    displayMaxCharacters(word, maxChars) {
      if (word && word.length > maxChars) {
        return word.substring(0, maxChars - 6) + " (...)";
      }
      return word;
    },
    respondRequest(bool, notification) {
      if (notification.pending_action_id) {
        pendingActionService.getPendingAction(notification.pending_action_id);
      }
      this.isRead(bool, notification);
    },
    isRead(read, notification) {
      let finalIds = [];
      if (notification.id) {
        finalIds.push(notification.id);
      } else {
        finalIds = this.selectedNotifications;
      }
      let notificationToUpdate;
      if (notification.kind == "seller_notifications") {
        notificationToUpdate = {
          seller_notification: { is_new: read, ids: finalIds },
        };
      }
      if (notification.kind == "user_notifications") {
        notificationToUpdate = {
          user_notification: { is_new: read, ids: finalIds },
        };
      }

      untoastedNotificationService[
        `updateNotificationIsRead${notification.kind}`
      ](notificationToUpdate)
        .then(() => {
          this.reloadNotifications();
          this.$root.$emit("reloadNotifications");
        })
        .catch(() => {
          this.$helpers.toast(
            "Update gone wrong... try again later.",
            "danger",
          );
        });
    },
    timeAgo(time) {
      /* if(typeof time === 'number') {
        continue;
      }
      else */ if (typeof time === "string") {
        time = +new Date(time);
      } else if (typeof time === "object") {
        if (time.constructor === Date) time = time.getTime();
      } else {
        time = +new Date();
      }

      const timeFormat = [
        [60, "seconds", 1],
        [120, "1 minute ago", "1 minute from now"],
        [3600, "minutes", 60],
        [7200, "1 hour ago", "1 hour from now"],
        [86400, "hours", 3600],
        [172800, "yesterday", "Tomorrow"],
        [604800, "days", 86400],
        [1209600, "Last week", "Next week"],
        [2419200, "weeks", 604800],
        [4838400, "Last month", "Next month"],
        [29030400, "months", 2419200],
        [58060800, "Last year", "Next year"],
        [2903040000, "years", 29030400],
        [5806080000, "Last century", "Next century"],
        [58060800000, "centuries", 2903040000],
      ];
      let seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      let i = 0;
      let format = 0;
      //Eslint maldito sacaneando a atribuição do valor em format
      while ((format = timeFormat[i++]))
        // eslint-disable-line
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
  },
};
</script>
