<template>
  <NbBadge :text="flightStatus?.description" :type="flightStatus?.type" />
</template>

<script>
import NbBadge from "@/components/alerts/NbBadge.vue";

export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  components: {
    NbBadge,
  },
  computed: {
    flightStatus() {
      const statuses = {
        open_manifest: {
          type: "info",
          description: "Open Manifest",
        },
        departure_confirmed: {
          type: "success",
          description: "Departure Confirmed",
        },
        manifest_closed: {
          type: "warning",
          description: "Manifest Closed",
        },
      };

      return statuses?.[this.status];
    },
  },
};
</script>

<style lang="scss" scoped></style>
