import api from "./HttpService";

export default class ShippingRulesService {
  getShippingRules(offset = 0, limit = 20, filters = {}) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
    } else {
      offset = (offset - 1) * 20;
    }
    return api.get("/v1/shipping_rules?offset=" + offset + "&limit=" + limit, {
      params: filters,
    });
    // return api.get('/v1/shipping_rules', {
    //   params: filters
    // });
  }

  getShippingRule(id) {
    return api.get("/v1/shipping_rules/" + id);
  }

  setShippingRule(data = {}) {
    return api.post("/v1/shipping_rules", data);
  }

  updateShippingRule(id = null, data = {}) {
    return api.put("/v1/shipping_rules/" + id, data);
  }

  deleteShippingRule(id = null) {
    return api.delete("/v1/shipping_rules/" + id);
  }

  // getShippingRuleCondition (id) {
  //   return api.get('/v1/shipping_rule_condition/' + id);
  // }

  // getShippingRuleAction (id) {
  //   return api.get('/v1/shipping_rule_action/' + id);
  // }

  // setShippingRuleCondition (data = {}) {
  //   return api.post('/v1/shipping_rule_condition', data);
  // }

  // setShippingRuleAction (data = {}) {
  //   return api.post('/v1/shipping_rule_action', data);
  // }

  // updateShippingRuleCondition (id = null, data = {}) {
  //   return api.put('/v1/shipping_rule_condition/' + id, data);
  // }

  // updateShippingRuleAction (id = null, data = {}) {
  //   return api.put('/v1/shipping_rule_action/' + id, data);
  // }

  // deleteShippingRuleCondition (id = null) {
  //   return api.delete('/v1/shipping_rule_condition/' + id);
  // }

  // deleteShippingRuleAction (id = null) {
  //   return api.delete('/v1/shipping_rule_action/' + id);
  // }
}
