<template>
  <div>
    <NbSearchInput
      v-model="modelValue"
      placeholder="Search"
      id="datatable-search"
      @submit="onSubmit"
    />
  </div>
</template>

<script>
import NbSearchInput from "@/components/input/text/NbSearchInput.vue";
export default {
  components: {
    NbSearchInput,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onSubmit(value) {
      this.$emit("submit", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
