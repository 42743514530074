import api from "./HttpService";

export default class PendingActionService {
  fetchAdminUsers() {
    return api.get("/v1/pending-actions/fetch_admins");
  }

  updatePendingAction(id, data) {
    return api.put("/v1/pending_actions/" + id, data);
  }

  getPendingAction(id) {
    return api.get("/v1/pending_actions/" + id);
  }

  // Adapted Index

  getPendingActionsContracts(id) {
    return api.get("/v1/pending-actions/contracts?seller_id=" + id);
  }

  // UNUSED BELOW

  removePendingAction(id) {
    return api.delete("/v1/pending_actions/" + id);
  }

  createPendingAction(data) {
    return api.post("/v1/pending_actions", data);
  }
}
