<template>
  <div>
    <div
      class="d-flex flex-column align-items-start flex-md-row justify-content-md-between"
    >
      <NbPageTitle
        :title="$t('volumesPage.title')"
        :subTitle="$t('volumesPage.subtitle')"
      />
      <div class="d-flex flex-column">
        <NbButton
          @click="showEditMassiveVolumesModal = true"
          v-if="$store.state.platform === 'total'"
          class="d-flex justify-content-start"
          icon="upload"
        >
          {{ $t("volumesPage.editVolumes") }}
        </NbButton>
      </div>
    </div>

    <Modal v-model="showCreateMasterboxModal">
      <CreateMasterbox :volume-ids="volumesSelected" />
    </Modal>
    <Modal v-model="showEditMassiveVolumesModal">
      <EditMassiveVolumes @success="showEditMassiveVolumesModal = false" />
    </Modal>

    <section class="page-container mt-4">
      <NbTabs class="mb-4" :tabs="tabsOptions" />

      <NbTablev2
        tableOf="volumesTable"
        namespace="volumes"
        ref="volumesTableRef"
        :clickable="true"
        :allFields="fields"
        :fields="tableFields"
        :tabs="true"
        :optionsWidth="260"
        :filterOptions="filterOptions"
        @reloadFields="tableFields = $event"
        selectable
        :selectedItems.sync="volumesSelected"
        :buttonOptions="tableOptions"
        @total="totalVolumes = $event"
        @optionTook="optionTook($event)"
        @clickedRow="showVolumeDetails($event.id)"
      >
        <template #cell(weight)="data">
          {{ data.item.weight | numberFormat }}
        </template>
        <template #cell(cubed_weight)="data">
          {{ data.item.cubed_weight | numberFormat }} kg
        </template>
        <!-- volumes values -->
        <template #cell(products_value)="data">
          {{ data.item.products_value | currency(data.item.order.currency) }}
        </template>
        <template #cell(freight_value)="data">
          {{ data.item.freight_value | currency(data.item.order.currency) }}
        </template>
        <template #cell(declared_value)="data">
          {{ data.item.declared_value | currency(data.item.order.currency) }}
        </template>
        <template #cell(duties_tax_value)="data">
          {{ data.item.duties_tax_value | currency(data.item.order.currency) }}
        </template>
        <template #cell(sales_tax_value)="data">
          {{ data.item.sales_tax_value | currency(data.item.order.currency) }}
        </template>
        <template #cell(tax_value)="data">
          {{ data.item.tax_value | currency(data.item.order.currency) }}
        </template>
        <template #cell(collected_icms)="data">
          {{
            data.item.collected_icms
              | currency(data.item.order.collected_taxes_currency, "USD")
          }}
        </template>
        <template #cell(collected_ii)="data">
          {{
            data.item.collected_ii
              | currency(data.item.order.collected_taxes_currency, "USD")
          }}
        </template>
        <template #cell(total_value)="data">
          {{ data.item.total_value | currency(data.item.order.currency) }}
        </template>
        <template #cell(estimated_duties_tax_value)="data">
          {{
            data.item.estimated_duties_tax_value
              | currency(data.item.order.currency)
          }}
        </template>
        <template #cell(estimated_sales_tax_value)="data">
          {{
            data.item.estimated_sales_tax_value
              | currency(data.item.order.currency)
          }}
        </template>
        <template #cell(estimated_tax_value)="data">
          {{
            data.item.estimated_tax_value | currency(data.item.order.currency)
          }}
        </template>
        <template #cell(freight_cost)="data">
          {{ data.item.freight_cost | currency(data.item.order.currency) }}
        </template>
        <template #cell(estimated_freight_cost)="data">
          {{
            data.item.estimated_freight_cost
              | currency(data.item.order.currency)
          }}
        </template>
        <!-- end volumes values -->
        <!-- orders values -->
        <template #[`cell(order.products_value)`]="data">
          {{
            data.item.order.products_value | currency(data.item.order.currency)
          }}
        </template>
        <template #[`cell(order.freight_value)`]="data">
          {{
            data.item.order.freight_value | currency(data.item.order.currency)
          }}
        </template>
        <template #[`cell(order.declared_value)`]="data">
          {{
            data.item.order.declared_value | currency(data.item.order.currency)
          }}
        </template>
        <template #[`cell(order.duties_tax_value)`]="data">
          {{
            data.item.order.duties_tax_value
              | currency(data.item.order.currency)
          }}
        </template>
        <template #[`cell(order.sales_tax_value)`]="data">
          {{
            data.item.order.sales_tax_value | currency(data.item.order.currency)
          }}
        </template>
        <template #[`cell(order.tax_value)`]="data">
          {{ data.item.order.tax_value | currency(data.item.order.currency) }}
        </template>
        <template #[`cell(order.total_value)`]="data">
          {{ data.item.order.total_value | currency(data.item.order.currency) }}
        </template>
        <template #[`cell(order.estimated_duties_tax_value)`]="data">
          {{
            data.item.order.estimated_duties_tax_value
              | currency(data.item.order.currency)
          }}
        </template>
        <template #[`cell(order.estimated_sales_tax_value)`]="data">
          {{
            data.item.order.estimated_sales_tax_value
              | currency(data.item.order.currency)
          }}
        </template>
        <template #[`cell(order.estimated_tax_value)`]="data">
          {{
            data.item.order.estimated_tax_value
              | currency(data.item.order.currency)
          }}
        </template>
        <template #[`cell(order.freight_cost)`]="data">
          {{
            data.item.order.freight_cost | currency(data.item.order.currency)
          }}
        </template>
        <!-- end orders values -->

        <template #cell(current_status)="data">
          <NbButton
            v-if="data.item?.current_status?.id"
            variant="tertiary"
            @click="loadModalData(data.item.current_status.id)"
          >
            {{ data.item?.current_status?.slug || "-" }}
          </NbButton>
          <span v-else>-</span>
        </template>

        <template #[`cell(order.id)`]="data">
          <router-link
            class="link-1"
            :to="'/shipping/orders?id=' + data.item.order.id"
          >
            {{ data.item.order.id }}
          </router-link>
        </template>
        <template #cell(order.prc)="data">
          {{ data.item.order.prc ? "✅" : "❌" }}
        </template>
        <template #[`cell(order.seller_id)`]="data">
          <router-link
            v-if="data.item.order?.seller_id"
            class="link-1"
            :to="'/users_management/sellers?id=' + data.item.order.seller_id"
          >
            {{ data.item.order.seller_id }}
          </router-link>
        </template>
        <template #[`cell(order.contract_id)`]="data">
          <router-link
            v-if="data.item.order?.contract_id"
            class="link-1"
            :to="
              '/contracts/contracts?contractId=' + data.item.order.contract_id
            "
          >
            {{ data.item.order.contract_id }}
          </router-link>
        </template>
        <template #cell(order.customer_full_name)="data">
          {{ data.item.order.customer_full_name || "-" }}
        </template>

        <template #cell(order.customer_postal_code)="data">
          {{ data.item.order.customer_postal_code || "-" }}
        </template>

        <template #cell(order.customer_city)="data">
          {{ data.item.order.customer_city || "-" }}
        </template>

        <template #cell(order.customer_state)="data">
          {{ data.item.order.customer_state || "-" }}
        </template>

        <template #cell(order.customer_address)="data">
          {{ data.item.order.customer_address || "-" }}
        </template>

        <template #cell(order.customer_address_number)="data">
          {{ data.item.order.customer_address_number || "-" }}
        </template>

        <template #cell(order.customer_address_complement)="data">
          {{ data.item.order.customer_address_complement || "-" }}
        </template>

        <template #cell(order.customer_address_reference)="data">
          {{ data.item.order.customer_address_reference || "-" }}
        </template>

        <template #cell(order.customer_address_quarter)="data">
          {{ data.item.order.customer_address_quarter || "-" }}
        </template>

        <template #cell(order.customer_document_type)="data">
          {{ data.item.order.customer_document_type || "-" }}
        </template>

        <template #cell(order.customer_document_number)="data">
          {{ data.item.order.customer_document_number || "-" }}
        </template>
        <template #cell(order.customer_country)="data">
          {{ data.item.order.customer_country || "-" }}
        </template>

        <template #cell(order.customer_email)="data">
          {{ data.item.order.customer_email || "-" }}
        </template>
        <template #cell(order.customer_phone)="data">
          {{ data.item.order.customer_phone || "-" }}
        </template>

        <template #cell(order.currency)="data">
          {{ data.item.order.currency || "-" }}
        </template>

        <template #cell(order.estimated_delivery_date)="data">
          {{ data.item.order.estimated_delivery_date || "-" }}
        </template>

        <template #cell(order.incoterm)="data">
          {{ data.item.order.incoterm || "-" }}
        </template>

        <template #cell(order.order_number)="data">
          {{ data.item.order.order_number || "-" }}
        </template>

        <template #cell(order.sales_channel_name)="data">
          {{ data.item.order.sales_channel_name || "-" }}
        </template>

        <template #cell(order.sales_channel_order_number)="data">
          {{ data.item.order.sales_channel_order_number || "-" }}
        </template>

        <template #cell(order.reference)="data">
          {{ data.item.order.reference || "-" }}
        </template>

        <template #cell(order.is_commercial_destination)="data">
          <IsCheckedIcon
            :checked="data.item?.order?.is_commercial_destination"
          />

          <!-- <NbIcon
            :attributes="iconAttrs"
            :class="
              data.item?.order?.is_commercial_destination
                ? 'text-success'
                : 'text-danger'
            "
            :icon="
              data.item?.order?.is_commercial_destination
                ? 'check-circle'
                : 'x-circle'
            "
          /> -->
        </template>

        <template #cell(order.seller_rfb_code)="data">
          {{ data.item.order.seller_rfb_code || "-" }}
        </template>

        <template #cell(order.seller_legal_name)="data">
          {{ data.item.order.seller_legal_name || "-" }}
        </template>

        <template #[`cell(order.overpack.id)`]="data">
          <router-link
            v-if="data.item.order?.overpack?.id"
            class="link-1"
            :to="'/shipping/overpacks?id=' + data.item.order.overpack.id"
          >
            {{ data.item.order.overpack.id }}
          </router-link>
        </template>

        <template #cell(order.overpack.sent_at)="data">
          {{ data.item.order?.overpack?.sent_at || "-" }}
        </template>

        <template #cell(order.overpack.reference)="data">
          {{ data.item.order?.overpack?.reference || "-" }}
        </template>

        <template #[`cell(masterbox.id)`]="data">
          <router-link
            v-if="data.item?.masterbox?.id"
            class="link-1"
            :to="'/shipping/masterboxes?id=' + data.item.masterbox.id"
          >
            {{ data.item.masterbox.id }}
          </router-link>
          <span v-else>-</span>
        </template>
        <template #[`cell(masterbox.shipment.id)`]="data">
          {{ data.item.masterbox?.shipment?.id || "-" }}
        </template>
        <template #[`cell(masterbox.shipment.flight.id)`]="data">
          <router-link
            v-if="data.item?.masterbox?.shipment?.flight?.id"
            class="link-1"
            :to="
              '/shipping/flights?id=' + data.item.masterbox.shipment.flight.id
            "
          >
            {{ data.item.masterbox.shipment.flight.id }}
          </router-link>
        </template>
        <template #cell(masterbox.shipment.flight.flight_number)="data">
          {{ data.item.masterbox?.shipment?.flight?.flight_number || "-" }}
        </template>
        <template #cell(masterbox.shipment.flight.mawb_number)="data">
          {{ data.item.masterbox?.shipment?.flight?.mawb_number || "-" }}
        </template>
        <template #[`cell(order.financial_status)`]="data">
          <OrderFinancialStatusBadge
            :status="data.item.order.financial_status"
          />
        </template>
        <template #cell(cn23_label)="data">
          <NbButton
            v-b-tooltip.hover
            variant="quaternary"
            :title="$t('clickToDownload')"
            v-if="data.item.last_mile_tracking_number"
            @click="generateBoxLabel(data.item.id)"
            class="d-flex align-items-center"
          >
            <NbIcon :attributes="iconAttrs" icon="download" />
          </NbButton>

          <span v-else>-</span>
        </template>
        <template #cell(first_mile_label)="data">
          <NbButton
            v-b-tooltip.hover
            variant="quaternary"
            :title="$t('clickToDownload')"
            v-if="data.item.first_mile_tracking_number"
            @click="generateVolumeFirstMile(data.item.id)"
            class="d-flex align-items-center"
          >
            <NbIcon :attributes="iconAttrs" icon="download" />
          </NbButton>

          <span v-else>-</span>
        </template>
        <template #cell(cn38_label)="data">
          <NbButton
            v-if="hasCn38Label(data.item?.masterbox?.shipment?.cn38)"
            v-b-tooltip.hover
            variant="quaternary"
            :title="$t('clickToDownload')"
            @click="generateShipmentLabel(data.item.masterbox.shipment.id)"
            class="d-flex align-items-center"
          >
            <NbIcon :attributes="iconAttrs" icon="download" />
          </NbButton>
          <span v-else>-</span>
        </template>
        <template #cell(flight_label)="data">
          <NbButton
            v-if="data.item.masterbox?.shipment?.flight?.mawb_number"
            v-b-tooltip.hover
            variant="quaternary"
            :title="$t('clickToDownload')"
            @click="generateFlightLabel(data.item.masterbox.shipment.flight.id)"
            class="d-flex align-items-center"
          >
            <NbIcon :attributes="iconAttrs" icon="download" />
          </NbButton>
          <span v-else>-</span>
        </template>

        <template #cell(masterbox.shipment.flight.reference)="data">
          {{ data.item?.masterbox?.shipment?.flight?.reference || "-" }}
        </template>

        <template #cell(masterbox.shipment.flight.airline)="data">
          {{ data.item?.masterbox?.shipment?.flight?.airline || "-" }}
        </template>

        <template #[`cell(masterbox.shipment.flight.green_light)`]="data">
          <NbIcon
            v-if="
              typeof data.item?.masterbox?.shipment?.flight?.green_light ===
              'boolean'
            "
            :attributes="iconAttrs"
            :class="
              data.item.masterbox.shipment.flight.green_light
                ? 'text-success'
                : 'text-danger'
            "
            :icon="
              data.item.masterbox.shipment.flight.green_light
                ? 'check-circle'
                : 'x-circle'
            "
          />
        </template>

        <template #cell(masterbox.shipment.flight.prealerts_sent)="data">
          {{ data.item?.masterbox?.shipment?.flight?.prealerts_sent || "-" }}
        </template>

        <template #cell(masterbox.shipment.flight.departure_airport)="data">
          {{ data.item?.masterbox?.shipment?.flight?.departure_airport || "-" }}
        </template>

        <template #cell(masterbox.shipment.flight.arrival_airport)="data">
          {{ data.item?.masterbox?.shipment?.flight?.arrival_airport || "-" }}
        </template>

        <template #cell(masterbox.shipment.flight.departure_date)="data">
          {{ data.item?.masterbox?.shipment?.flight?.departure_date || "-" }}
        </template>

        <template #[`cell(hs_code)`]="data">
          <div v-for="order_item in data.item.order_items" :key="order_item.id">
            {{ order_item.hs_code }}
          </div>
        </template>
        <template #cell(msds)="data">
          <NbButton
            v-if="data.item.order?.id"
            @click="downloadMsds(data.item.order.id)"
            v-b-tooltip.hover
            variant="quaternary"
            title="Check if there are dangerous products"
          >
            <div class="text-center" style="width: 35px; height: 25px">
              <i class="fas fa-fire-alt fa-lg"></i>
            </div>
          </NbButton>

          <span v-else>-</span>
        </template>
        <template #[`cell(description)`]="data">
          <div v-for="order_item in data.item.order_items" :key="order_item.id">
            {{ order_item.description }}
          </div>
        </template>

        <template #cell(first_mile_tracking_number)="data">
          {{ data.item?.first_mile_tracking_number || "-" }}
        </template>

        <template #cell(order.overpack.first_mile_tracking_number)="data">
          {{ data.item.order?.overpack?.first_mile_tracking_number || "-" }}
        </template>

        <template #cell(order.overpack.first_mile_carrier_name)="data">
          {{ data.item.order?.overpack?.first_mile_carrier_name || "-" }}
        </template>

        <template #cell(masterbox.dispatch_id)="data">
          {{ data.item.masterbox?.dispatch_id || "-" }}
        </template>

        <template #cell(masterbox.reference)="data">
          {{ data.item.masterbox?.reference || "-" }}
        </template>

        <template #cell(masterbox.shipment.cn38)="data">
          {{ data.item.masterbox?.shipment?.cn38 || "-" }}
        </template>

        <template #cell(cn35_label)="data">
          <NbButton
            v-if="data.item.masterbox?.reference"
            v-b-tooltip.hover
            variant="quaternary"
            :title="$t('clickToDownload')"
            @click="generateMasterBoxLabel(data.item.masterbox?.id)"
            class="d-flex align-items-center"
          >
            <NbIcon :attributes="iconAttrs" icon="download" />
          </NbButton>
          <span v-else>-</span>
        </template>
      </NbTablev2>
    </section>

    <SelectColumnsDownloadModal
      :loading="isDownloading"
      :options="downloadFields"
      @selected="downloadVolumes"
    />
    <ModalNobordistCheckpointsView :itemId="currentItemId" />

    <Modal
      v-model="isVolumeModalOpen"
      @close="setUrlSearchParam('id')"
      size="lg"
    >
      <VolumeModal v-if="selectedVolumeId" :volumeId="selectedVolumeId" />
    </Modal>
  </div>
</template>

<script>
import OrderLabelService from "@/services/OrderLabelService";
import MasterBoxService from "@/services/MasterBoxService";
import ShipmentService from "@/services/ShipmentService";
import FlightService from "@/services/FlightService";
import ProductDocumentService from "@/services/ProductDocumentService";
import DownloadsService from "@/services/DownloadsService";
import ModalNobordistCheckpointsView from "@/views/checkpoints/nobordist_checkpoints/components/ModalNobordistCheckpointsView.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import OrderFinancialStatusBadge from "@/components/OrderFinancialStatusBadge.vue";
import SelectColumnsDownloadModal from "@/components/SelectColumnsDownloadModal.vue";
import VolumeModal from "./VolumeModal.vue";
import queryParamsMixin from "@/mixins/query-params-mixin";
import CreateMasterbox from "./components/CreateMasterbox.vue";
import Modal from "@/components/modal/Modal.vue";
import EditMassiveVolumes from "./components/EditMassiveVolumes.vue";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";

const orderLabelService = new OrderLabelService();
const masterBoxService = new MasterBoxService();
const shipmentService = new ShipmentService();
const flightService = new FlightService();
const productDocumentService = new ProductDocumentService();
const downloadsService = new DownloadsService();

function parseNumber(value) {
  const parse = Number(value);
  return !isNaN(parse) ? parse.toFixed(2) : "0.00";
}

const availableCurrenciesCode = {
  R$: "BRL",
  USD: "USD",
};
export default {
  name: "Volumes",
  mixins: [queryParamsMixin],
  components: {
    ModalNobordistCheckpointsView,
    NbPageTitle,
    NbTablev2,
    NbButton,
    OrderFinancialStatusBadge,
    NbIcon,
    SelectColumnsDownloadModal,
    NbTabs,
    VolumeModal,
    Modal,
    EditMassiveVolumes,
    CreateMasterbox,
    IsCheckedIcon,
  },
  filters: {
    numberFormat(value) {
      return parseNumber(value);
    },
    currency(value, currencyCode, defaultCurrency = "BRL") {
      const data = parseNumber(value);

      if (!defaultCurrency && !(currencyCode in availableCurrenciesCode)) {
        return data;
      }

      const formatter = new Intl.NumberFormat("en", {
        style: "currency",
        currency:
          availableCurrenciesCode?.[currencyCode?.toUpperCase()] ||
          defaultCurrency,
      });

      return formatter.format(data);
    },
  },
  data: () => {
    return {
      showEditMassiveVolumesModal: false,
      isAlertVisible: false,
      currentItemId: "",
      tableFields: [],
      volumesSelected: [],
      currentTab: "all",
      totalVolumes: 0,
      isDownloading: false,
      iconAttrs: {
        width: "16px",
        height: "16px",
      },
      showCreateMasterboxModal: false,
      isVolumeModalOpen: false,
      selectedVolumeId: null,
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: this.currentTab,
          label: this.$t("all"),
          found: this.totalVolumes,
          current: this.currentTab,
        },
      ];
    },
    tableOptions() {
      return [
        {
          text: this.$t("volumesPage.downloadVolumes"),
          value: "showModalDownloadVolumes",
        },
        {
          text: this.$t("volumesPage.createMasterbox"),
          value: "showModalCreateMasterboxes",
        },
      ];
    },
    items() {
      let testItem = JSON.parse(
        JSON.stringify(this.$store.state[this.namespace].items),
      );
      return testItem;
    },
    fields() {
      return [
        { key: "id", label: "ID" },
        {
          key: "weight",
          label: this.$t("volumesPage.fields.weight"),
        },
        {
          key: "updated_weight",
          label: this.$t("volumesPage.fields.updatedWeight"),
        },
        {
          key: "cubed_weight",
          label: this.$t("volumesPage.fields.cubedWeight"),
        },
        {
          key: "height",
          label: this.$t("volumesPage.fields.height"),
        },
        {
          key: "width",
          label: this.$t("volumesPage.fields.Width"),
        },
        {
          key: "length",
          label: this.$t("volumesPage.fields.length"),
        },
        {
          key: "reference",
          label: this.$t("volumesPage.fields.reference"),
        },
        {
          key: "last_mile_carrier_number",
          label: this.$t("volumesPage.fields.lastMileCarrierNumber"),
        },
        {
          key: "last_mile_tracking_number",
          label: this.$t("volumesPage.fields.lastMileTrackingNumber"),
        },
        {
          key: "first_mile_tracking_number",
          label: this.$t("volumesPage.fields.firstMileTrackingNumber"),
        },
        {
          key: "cn23_label",
          label: this.$t("volumesPage.fields.cN23"),
        },
        {
          key: "first_mile_label",
          label: this.$t("volumesPage.fields.firstMileLabel"),
        },
        {
          key: "created_at",
          label: this.$t("volumesPage.fields.creationDate"),
          sortable: true,
        },
        //detail volume vals
        {
          key: "products_value",
          label: this.$t("volumesPage.fields.volumeProductsValue"),
          sortable: true,
        },
        {
          key: "freight_value",
          label: this.$t("volumesPage.fields.volumeFreightValue"),
          sortable: true,
        },
        {
          key: "declared_value",
          label: this.$t("volumesPage.fields.volumeDeclaredValue"),
          sortable: true,
        },
        {
          key: "duties_tax_value",
          label: this.$t("volumesPage.fields.volumeDutiesTaxValue"),
          sortable: true,
        },
        {
          key: "sales_tax_value",
          label: this.$t("volumesPage.fields.volumeSalesTaxValue"),
          sortable: true,
        },
        {
          key: "tax_value",
          label: this.$t("volumesPage.fields.volumeTaxValue"),
          sortable: true,
        },
        {
          key: "collected_icms",
          label: this.$t("volumesPage.fields.collectedIcms"),
        },
        {
          key: "collected_ii",
          label: this.$t("volumesPage.fields.collectedIi"),
        },
        {
          key: "estimated_duties_tax_value",
          label: this.$t("volumesPage.fields.volumeEstimatedDutiesTaxValue"),
          sortable: true,
        },
        {
          key: "estimated_sales_tax_value",
          label: this.$t("volumesPage.fields.volumeEstimatedSalesTaxValue"),
          sortable: true,
        },
        {
          key: "estimated_tax_value",
          label: this.$t("volumesPage.fields.volumeEstimatedTaxValue"),
          sortable: true,
        },
        {
          key: "freight_cost",
          label: this.$t("volumesPage.fields.volumeFreightCost"),
          sortable: true,
        },
        {
          key: "estimated_freight_cost",
          label: this.$t("volumesPage.fields.volumeEstimatedFreightCost"),
          sortable: true,
        },
        // order items data
        {
          key: "hs_code",
          label: this.$t("volumesPage.fields.hSCode"),
        },
        {
          key: "description",
          label: this.$t("volumesPage.fields.description"),
        },
        {
          key: "current_status",
          label: this.$t("volumesPage.fields.currentStatus"),
        },
        // order data
        {
          key: "order.id",
          label: this.$t("volumesPage.fields.orderID"),
        },
        {
          key: "order.prc",
          label: "PRC",
        },
        {
          key: "msds",
          label: this.$t("volumesPage.fields.mSDS"),
        },
        {
          key: "order.seller_id",
          label: this.$t("volumesPage.fields.sellerID"),
        },
        {
          key: "order.first_mile_number",
          label: this.$t("volumesPage.fields.firstMileN"),
        },
        {
          key: "order.contract_id",
          label: this.$t("volumesPage.fields.contractID"),
        },
        {
          key: "order.customer_full_name",
          label: this.$t("volumesPage.fields.customerName"),
        },
        {
          key: "order.customer_postal_code",
          label: this.$t("volumesPage.fields.cPostalCode"),
        },
        {
          key: "order.customer_city",
          label: this.$t("volumesPage.fields.customerCity"),
        },
        {
          key: "order.customer_state",
          label: this.$t("volumesPage.fields.customerState"),
        },
        {
          key: "order.customer_address",
          label: this.$t("volumesPage.fields.customerAddress"),
        },
        {
          key: "order.customer_address_number",
          label: this.$t("volumesPage.fields.customerAddressNumber"),
        },
        {
          key: "order.customer_address_complement",
          label: this.$t("volumesPage.fields.customerAddressComplement"),
        },
        {
          key: "order.customer_address_reference",
          label: this.$t("volumesPage.fields.customerAddressReference"),
        },
        {
          key: "order.customer_address_quarter",
          label: this.$t("volumesPage.fields.customerAddressQuarter"),
        },
        {
          key: "order.customer_document_type",
          label: this.$t("volumesPage.fields.customerDocumentType"),
        },
        {
          key: "order.customer_document_number",
          label: this.$t("volumesPage.fields.customerDocumentNumber"),
        },
        {
          key: "order.customer_country",
          label: this.$t("volumesPage.fields.customerCountry"),
        },
        {
          key: "order.customer_email",
          label: this.$t("volumesPage.fields.customerEmail"),
        },
        {
          key: "order.customer_phone",
          label: this.$t("volumesPage.fields.customerPhone"),
        },
        {
          key: "order.currency",
          label: this.$t("volumesPage.fields.currency"),
        },
        {
          key: "order.estimated_delivery_date",
          label: this.$t("volumesPage.fields.estimatedDeliveryDate"),
        },
        {
          key: "order.incoterm",
          label: this.$t("volumesPage.fields.incoterm"),
        },
        {
          key: "order.order_number",
          label: this.$t("volumesPage.fields.orderNumber"),
        },
        {
          key: "order.sales_channel_name",
          label: this.$t("volumesPage.fields.salesChannel"),
        },
        {
          key: "order.sales_channel_order_number",
          label: this.$t("volumesPage.fields.salesChOrderNumber"),
        },
        {
          key: "total_value",
          label: this.$t("volumesPage.fields.totalValue"),
        },
        {
          key: "order.reference",
          label: this.$t("volumesPage.fields.orderReference"),
        },
        {
          key: "order.financial_status",
          label: this.$t("volumesPage.fields.financialStatus"),
          sortable: true,
        },
        {
          key: "order.is_commercial_destination",
          label: this.$t("volumesPage.fields.orderIsCommercialDestination"),
          sortable: true,
        },
        {
          key: "order.seller_rfb_code",
          label: this.$t("volumesPage.fields.orderSellerRfbCode"),
          sortable: true,
        },
        {
          key: "order.seller_legal_name",
          label: this.$t("volumesPage.fields.orderSellerLegalName"),
          sortable: true,
        },
        //detail order vals
        {
          key: "order.products_value",
          label: this.$t("volumesPage.fields.orderProductsValue"),
          sortable: true,
        },
        {
          key: "order.freight_value",
          label: this.$t("volumesPage.fields.orderFreightValue"),
          sortable: true,
        },
        {
          key: "order.declared_value",
          label: this.$t("volumesPage.fields.orderDeclaredValue"),
          sortable: true,
        },
        {
          key: "order.duties_tax_value",
          label: this.$t("volumesPage.fields.orderDutiesTaxValue"),
          sortable: true,
        },
        {
          key: "order.sales_tax_value",
          label: this.$t("volumesPage.fields.orderSalesTaxValue"),
          sortable: true,
        },
        {
          key: "order.tax_value",
          label: this.$t("volumesPage.fields.orderTaxValue"),
          sortable: true,
        },
        {
          key: "order.estimated_duties_tax_value",
          label: this.$t("volumesPage.fields.orderEstimatedDutiesTaxValue"),
          sortable: true,
        },
        {
          key: "order.estimated_sales_tax_value",
          label: this.$t("volumesPage.fields.orderEstimatedSalesTaxValue"),
          sortable: true,
        },
        {
          key: "order.estimated_tax_value",
          label: this.$t("volumesPage.fields.orderEstimatedTaxValue"),
          sortable: true,
        },
        {
          key: "order.freight_cost",
          label: this.$t("volumesPage.fields.orderFreightCost"),
          sortable: true,
        },
        // overpack data
        {
          key: "order.overpack.id",
          label: this.$t("volumesPage.fields.overpackID"),
        },
        {
          key: "order.overpack.sent_at",
          label: this.$t("volumesPage.fields.overpackSentDate"),
        },
        {
          key: "order.overpack.reference",
          label: this.$t("volumesPage.fields.overpackReference"),
        },
        {
          key: "order.overpack.first_mile_carrier_name",
          label: this.$t("volumesPage.fields.firstMileCompany"),
        },
        //Descobrir como est'a vindo
        // masterbox data
        {
          key: "masterbox.id",
          label: this.$t("volumesPage.fields.masterboxID"),
        },
        {
          key: "masterbox.dispatch_id",
          label: this.$t("volumesPage.fields.dispatchID"),
        },
        {
          key: "masterbox.reference",
          label: this.$t("volumesPage.fields.cN35"),
        },
        {
          key: "cn35_label",
          label: this.$t("volumesPage.fields.cN35Label"),
        },
        // shipment data
        {
          key: "masterbox.shipment.id",
          label: this.$t("volumesPage.fields.shipmentID"),
        },
        {
          key: "masterbox.shipment.category",
          label: this.$t("volumesPage.fields.shipmentCategory"),
        },
        {
          key: "masterbox.shipment.cn38",
          label: this.$t("volumesPage.fields.cN38"),
        },
        {
          key: "cn38_label",
          label: this.$t("volumesPage.fields.cN38Label"),
        },
        // flight data
        {
          key: "masterbox.shipment.flight.id",
          label: this.$t("volumesPage.fields.flightID"),
        },
        {
          key: "masterbox.shipment.flight.flight_number",
          label: this.$t("volumesPage.fields.flightN"),
        },
        {
          key: "masterbox.shipment.flight.mawb_number",
          label: this.$t("volumesPage.fields.flightMAWB"),
        },
        {
          key: "flight_label",
          label: this.$t("volumesPage.fields.mAWBLabel"),
        },
        {
          key: "masterbox.shipment.flight.reference",
          label: this.$t("volumesPage.fields.flightReference"),
        },
        {
          key: "masterbox.shipment.flight.airline",
          label: this.$t("volumesPage.fields.airline"),
        },
        {
          key: "masterbox.shipment.flight.green_light",
          label: this.$t("volumesPage.fields.greenLight"),
        },
        {
          key: "masterbox.shipment.flight.prealerts_sent",
          label: this.$t("volumesPage.fields.prealertsSent"),
        },
        {
          key: "masterbox.shipment.flight.departure_airport",
          label: this.$t("volumesPage.fields.departureAirport"),
        },
        {
          key: "masterbox.shipment.flight.arrival_airport",
          label: this.$t("volumesPage.fields.arrivalAirport"),
        },
        {
          key: "masterbox.shipment.flight.departure_date",
          label: this.$t("volumesPage.fields.departureStartDate"),
        },
      ];
    },
    filterOptions() {
      return [
        // volume filters
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
        {
          key: "volume.id",
          label: this.$t("volumesPage.filterOptions.volumeID"),
          type: "number",
        },
        {
          key: "volume.reference",
          label: this.$t("volumesPage.filterOptions.reference"),
        },
        {
          key: "volume.last_mile_tracking_number",
          label: this.$t("volumesPage.filterOptions.lastMileTrackingNumber"),
          popoverText: this.$t("volumesPage.filterOptions.lmtnPopover"),
        },
        {
          key: "volume.last_mile_tracking_number_null",
          label: this.$t(
            "volumesPage.filterOptions.withoutLastMileTrackingNumber",
          ),
          type: "boolean",
        },
        {
          key: "volume.last_mile_carrier_number",
          label: this.$t("volumesPage.filterOptions.lastMileCarrierNumber"),
        },
        {
          key: "volume.first_mile_tracking_number",
          label: this.$t("volumesPage.fields.firstMileTrackingNumber"),
        },
        {
          key: "volume.estimated_freight_cost",
          label: this.$t("volumesPage.filterOptions.estimatedFreightCost"),
        },
        {
          key: "volume.collected_icms",
          label: this.$t("volumesPage.fields.collectedIcms"),
          type: "number",
        },
        {
          key: "volume.collected_ii",
          label: this.$t("volumesPage.fields.collectedIi"),
          type: "number",
        },
        // order filters
        {
          key: "order.registration_date",
          label: this.$t("volumesPage.filterOptions.orderDate"),
          type: "dates",
          startDateKey: "order.start_registration_date",
          endDateKey: "order.end_registration_date",
        },
        {
          key: "order.id",
          label: this.$t("volumesPage.filterOptions.orderID"),
          type: "number",
        },
        {
          key: "order.prc",
          label: "PRC",
          type: "boolean",
        },
        {
          key: "order.contract_id",
          label: this.$t("volumesPage.filterOptions.contractID"),
          type: "number",
        },
        {
          key: "order.seller_id",
          label: this.$t("volumesPage.filterOptions.sellerID"),
          type: "number",
          initialVal: this.$route.query.sellerId || "",
        },
        {
          key: "order.freight_cost",
          label: this.$t("volumesPage.filterOptions.freightCost"),
        },
        {
          key: "order.first_mile_number",
          label: this.$t("volumesPage.filterOptions.firstMileN"),
        },
        {
          key: "order.last_mile_number_father",
          label: this.$t("volumesPage.filterOptions.lastMileN"),
        },
        {
          key: "order.order_number",
          label: this.$t("volumesPage.filterOptions.orderNumber"),
        },
        {
          key: "order.sales_channel_name",
          label: this.$t("volumesPage.filterOptions.salesChannel"),
        },
        {
          key: "order.sales_channel_order_number",
          label: this.$t("volumesPage.filterOptions.salesChOrderNumber"),
        },
        {
          key: "order.reference",
          label: this.$t("volumesPage.filterOptions.orderReference"),
        },
        {
          key: "order.incoterm",
          label: this.$t("volumesPage.filterOptions.incoterm"),
        },
        {
          key: "order.customer_full_name",
          label: this.$t("volumesPage.filterOptions.customerName"),
        },
        {
          key: "order.customer_address",
          label: this.$t("volumesPage.filterOptions.customerAddress"),
        },
        {
          key: "order.customer_address_number",
          label: this.$t("volumesPage.filterOptions.customerAddressNumber"),
        },
        {
          key: "order.customer_address_complement",
          label: this.$t("volumesPage.filterOptions.customerAddressComplement"),
        },
        {
          key: "order.customer_address_reference",
          label: this.$t("volumesPage.filterOptions.customerAddressReference"),
        },
        {
          key: "order.customer_address_quarter",
          label: this.$t("volumesPage.filterOptions.customerAddressQuarter"),
        },
        {
          key: "order.customer_document_type",
          label: this.$t("volumesPage.filterOptions.customerDocumentType"),
        },
        {
          key: "orders.customer_document_number",
          label: this.$t("volumesPage.filterOptions.customerDocumentNumber"),
        },
        {
          key: "order.customer_country",
          label: this.$t("volumesPage.filterOptions.customerCountry"),
        },
        {
          key: "order.customer_email",
          label: this.$t("volumesPage.filterOptions.customerEmail"),
        },
        {
          key: "order.customer_phone",
          label: this.$t("volumesPage.filterOptions.customerPhone"),
        },
        {
          key: "order.financial_status",
          label: this.$t("volumesPage.filterOptions.financialStatus"),
          type: "select",
          options: this.financial_statuses,
        },
        {
          key: "order.is_commercial_destination",
          label: this.$t("volumesPage.fields.orderIsCommercialDestination"),
          type: "boolean",
        },
        {
          key: "order.seller_rfb_code",
          label: this.$t("volumesPage.fields.orderSellerRfbCode"),
        },
        {
          key: "order.seller_legal_name",
          label: this.$t("volumesPage.fields.orderSellerLegalName"),
        },
        // overpack filters
        {
          key: "overpack.registration_date",
          label: this.$t("volumesPage.filterOptions.overpackDate"),
          type: "dates",
          startDateKey: "overpack.start_registration_date",
          endDateKey: "overpack.end_registration_date",
        },
        {
          key: "overpack.send_date",
          label: this.$t("volumesPage.filterOptions.overpackSendDate"),
          type: "dates",
          startDateKey: "overpack.start_send_date",
          endDateKey: "overpack.end_send_date",
        },
        {
          key: "overpack.id",
          label: this.$t("volumesPage.filterOptions.overpackID"),
          type: "number",
        },
        {
          key: "overpack.reference",
          label: this.$t("volumesPage.filterOptions.overpackReference"),
        },
        {
          key: "overpack.bag_number",
          label: this.$t("volumesPage.filterOptions.bagNumber"),
        },
        {
          key: "overpack.first_mile_tracking_number",
          label: this.$t("volumesPage.filterOptions.firstMileTrackingN"),
        },
        {
          key: "overpack.first_mile_carrier_name",
          label: this.$t("volumesPage.filterOptions.firstMileCarrierName"),
        },
        // masterbox filters
        {
          key: "masterbox.registration_date",
          label: this.$t("volumesPage.filterOptions.masterboxDate"),
          type: "dates",
          startDateKey: "masterbox.start_registration_date",
          endDateKey: "masterbox.end_registration_date",
        },
        {
          key: "masterbox.id",
          label: this.$t("volumesPage.filterOptions.masterboxID"),
          type: "number",
        },
        {
          key: "masterbox.reference",
          label: this.$t("volumesPage.filterOptions.cn35"),
        },
        {
          key: "masterbox.dispatch_id",
          label: this.$t("volumesPage.filterOptions.dispatchID"),
          type: "number",
        },
        // shipment filters
        {
          label: this.$t("volumesPage.filterOptions.shipmentDate"),
          type: "dates",
          startDateKey: "shipment.start_registration_date",
          endDateKey: "shipment.end_registration_date",
        },
        {
          key: "shipment.id",
          label: this.$t("volumesPage.filterOptions.shipmentID"),
          type: "number",
        },
        {
          key: "shipment.cn38",
          label: this.$t("volumesPage.filterOptions.cN38"),
        },
        {
          key: "shipment.category",
          label: this.$t("volumesPage.filterOptions.shipmentCategory"),
        },
        // flight filters
        {
          key: "flight.registration_date",
          label: this.$t("volumesPage.filterOptions.flightDate"),
          type: "dates",
          startDateKey: "flight.start_registration_date",
          endDateKey: "flight.end_registration_date",
        },
        {
          key: "flight.id",
          label: this.$t("volumesPage.filterOptions.flightID"),
          type: "number",
        },
        {
          key: "flight.mawb_number",
          label: this.$t("volumesPage.filterOptions.flightMAWB"),
        },
        {
          key: "flight.flight_number",
          label: this.$t("volumesPage.filterOptions.flightN"),
        },
        {
          key: "flight.reference",
          label: this.$t("volumesPage.filterOptions.flightreference"),
        },
        {
          key: "flight.airline",
          label: this.$t("volumesPage.filterOptions.airline"),
        },
        {
          key: "flight.green_light",
          label: this.$t("volumesPage.filterOptions.greenLight"),
          type: "boolean",
        },
        {
          key: "flight.departure_airport",
          label: this.$t("volumesPage.filterOptions.departureAirport"),
        },
        {
          key: "flight.arrival_airport",
          label: this.$t("volumesPage.filterOptions.arrivalAirport"),
        },
        {
          key: "flight.departure_date",
          label: this.$t("volumesPage.filterOptions.flightDepartureDate"),
          type: "dates",
          startDateKey: "flight.start_departure_date",
          endDateKey: "flight.end_departure_date",
        },
      ];
    },
    downloadFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "weight",
          label: this.$t("volumesPage.downloadFields.weight"),
        },
        {
          key: "height",
          label: this.$t("volumesPage.downloadFields.height"),
        },
        {
          key: "width",
          label: this.$t("volumesPage.downloadFields.width"),
        },
        {
          key: "length",
          label: this.$t("volumesPage.downloadFields.length"),
        },
        {
          key: "reference",
          label: this.$t("volumesPage.downloadFields.reference"),
        },
        {
          key: "last_mile_tracking_number",
          label: this.$t("volumesPage.downloadFields.lastMileTrackingNumber"),
        },
        {
          key: "first_mile_tracking_number",
          label: this.$t("volumesPage.fields.firstMileTrackingNumber"),
        },
        {
          key: "created_at",
          label: this.$t("volumesPage.downloadFields.creationDate"),
          sortable: true,
        },
        //detail volume vals
        {
          key: "products_value",
          label: this.$t("volumesPage.downloadFields.volumeProductsValue"),
          sortable: true,
        },
        {
          key: "freight_value",
          label: this.$t("volumesPage.downloadFields.volumeFreightValue"),
          sortable: true,
        },
        {
          key: "declared_value",
          label: this.$t("volumesPage.downloadFields.volumeDeclaredValue"),
          sortable: true,
        },
        {
          key: "duties_tax_value",
          label: this.$t("volumesPage.downloadFields.volumeDutiesTaxValue"),
          sortable: true,
        },
        {
          key: "sales_tax_value",
          label: this.$t("volumesPage.downloadFields.volumeSalesTaxValue"),
          sortable: true,
        },
        {
          key: "tax_value",
          label: this.$t("volumesPage.downloadFields.volumeTaxValue"),
          sortable: true,
        },
        {
          key: "total_value",
          label: this.$t("volumesPage.downloadFields.volumeTotalValue"),
          sortable: true,
        },
        {
          key: "estimated_duties_tax_value",
          label: this.$t(
            "volumesPage.downloadFields.volumeEstimatedDutiesTaxValue",
          ),
          sortable: true,
        },
        {
          key: "estimated_sales_tax_value",
          label: this.$t(
            "volumesPage.downloadFields.volumeEstimatedSalesTaxValue",
          ),
          sortable: true,
        },
        {
          key: "estimated_tax_value",
          label: this.$t("volumesPage.downloadFields.volumeEstimatedTaxValue"),
          sortable: true,
        },
        {
          key: "freight_cost",
          label: this.$t("volumesPage.downloadFields.volumeFreightCost"),
          sortable: true,
        },
        {
          key: "estimated_freight_cost",
          label: this.$t(
            "volumesPage.downloadFields.volumeEstimatedFreightCost",
          ),
          sortable: true,
        },
        {
          key: "collected_icms",
          label: this.$t("volumesPage.fields.collectedIcms"),
          sortable: true,
        },
        {
          key: "collected_ii",
          label: this.$t("volumesPage.fields.collectedIi"),
          sortable: true,
        },
        // order items data
        {
          key: "hs_code",
          label: this.$t("volumesPage.downloadFields.hSCode"),
        },
        {
          key: "description",
          label: this.$t("volumesPage.downloadFields.description"),
        },
        {
          key: "current_status",
          label: this.$t("volumesPage.downloadFields.currentStatus"),
        },
        // order data
        {
          key: "order.id",
          label: this.$t("volumesPage.downloadFields.orderID"),
        },
        {
          key: "order.seller_id",
          label: this.$t("volumesPage.downloadFields.sellerID"),
        },
        {
          key: "order.contract_id",
          label: this.$t("volumesPage.downloadFields.contractID"),
        },
        {
          key: "order.customer_full_name",
          label: this.$t("volumesPage.downloadFields.customerName"),
        },
        {
          key: "order.customer_postal_code",
          label: this.$t("volumesPage.downloadFields.cPostalCode"),
        },
        {
          key: "order.customer_city",
          label: this.$t("volumesPage.downloadFields.customerCity"),
        },
        {
          key: "order.customer_state",
          label: this.$t("volumesPage.downloadFields.customerState"),
        },
        {
          key: "order.customer_address",
          label: this.$t("volumesPage.downloadFields.customerAddress"),
        },
        {
          key: "order.customer_address_number",
          label: this.$t("volumesPage.downloadFields.customerAddressNumber"),
        },
        {
          key: "order.customer_address_complement",
          label: this.$t(
            "volumesPage.downloadFields.customerAddressComplement",
          ),
        },
        {
          key: "order.customer_address_reference",
          label: this.$t("volumesPage.downloadFields.customerAddressReference"),
        },
        {
          key: "order.customer_address_quarter",
          label: this.$t("volumesPage.downloadFields.customerAddressQuarter"),
        },
        {
          key: "order.customer_document_type",
          label: this.$t("volumesPage.downloadFields.customerDocumentType"),
        },
        {
          key: "order.customer_document_number",
          label: this.$t("volumesPage.downloadFields.customerDocumentNumber"),
        },
        {
          key: "order.customer_country",
          label: this.$t("volumesPage.downloadFields.customerCountry"),
        },
        {
          key: "order.customer_email",
          label: this.$t("volumesPage.downloadFields.customerEmail"),
        },
        {
          key: "order.customer_phone",
          label: this.$t("volumesPage.downloadFields.customerPhone"),
        },
        {
          key: "order.currency",
          label: this.$t("volumesPage.downloadFields.currency"),
        },
        {
          key: "order.freight_cost",
          label: this.$t("volumesPage.downloadFields.freightCost"),
        },
        {
          key: "order.freight_value",
          label: this.$t("volumesPage.downloadFields.freigtValue"),
        },
        {
          key: "order.estimated_delivery_date",
          label: this.$t("volumesPage.downloadFields.estimatedDeliveryDate"),
        },
        {
          key: "order.incoterm",
          label: this.$t("volumesPage.downloadFields.incoterm"),
        },
        {
          key: "order.order_number",
          label: this.$t("volumesPage.downloadFields.orderNumber"),
        },
        {
          key: "order.sales_channel_name",
          label: this.$t("volumesPage.downloadFields.salesChannel"),
        },
        {
          key: "order.sales_channel_order_number",
          label: this.$t("volumesPage.downloadFields.salesChOrderNumber"),
        },
        {
          key: "order.total_value",
          label: this.$t("volumesPage.downloadFields.totalValue"),
        },
        {
          key: "order.reference",
          label: this.$t("volumesPage.downloadFields.orderReference"),
        },
        {
          key: "order.financial_status",
          label: this.$t("volumesPage.downloadFields.financialStatus"),
          sortable: true,
        },
        {
          key: "order.is_commercial_destination",
          label: this.$t("volumesPage.fields.orderIsCommercialDestination"),
          sortable: true,
        },
        {
          key: "order.seller_rfb_code",
          label: this.$t("volumesPage.fields.orderSellerRfbCode"),
          sortable: true,
        },
        {
          key: "order.seller_legal_name",
          label: this.$t("volumesPage.fields.orderSellerLegalName"),
          sortable: true,
        },
        // overpack data
        {
          key: "order.overpack.id",
          label: this.$t("volumesPage.downloadFields.overpackID"),
        },
        {
          key: "order.overpack.sent_at",
          label: this.$t("volumesPage.downloadFields.overpackSentDate"),
        },
        {
          key: "order.overpack.reference",
          label: this.$t("volumesPage.downloadFields.overpackReference"),
        },
        {
          key: "order.overpack.first_mile_tracking_number",
          label: this.$t("volumesPage.downloadFields.fMTrackingNumber"),
        },
        {
          key: "order.overpack.first_mile_carrier_name",
          label: this.$t("volumesPage.downloadFields.firstMileCompany"),
        },
        // masterbox data
        {
          key: "masterbox.id",
          label: this.$t("volumesPage.downloadFields.masterboxID"),
        },
        {
          key: "masterbox.dispatch_id",
          label: this.$t("volumesPage.downloadFields.dispatchID"),
        },
        {
          key: "masterbox.reference",
          label: this.$t("volumesPage.downloadFields.cN35"),
        },
        // shipment data
        {
          key: "masterbox.shipment.id",
          label: this.$t("volumesPage.downloadFields.shipmentID"),
        },
        {
          key: "masterbox.shipment.category",
          label: this.$t("volumesPage.downloadFields.shipmentCategory"),
        },
        {
          key: "masterbox.shipment.cn38",
          label: this.$t("volumesPage.downloadFields.cN38"),
        },
        // flight data
        {
          key: "masterbox.shipment.flight.id",
          label: this.$t("volumesPage.downloadFields.flightID"),
        },
        {
          key: "masterbox.shipment.flight.flight_number",
          label: this.$t("volumesPage.downloadFields.flightN"),
        },
        {
          key: "masterbox.shipment.flight.mawb_number",
          label: this.$t("volumesPage.downloadFields.mAWBLabel"),
        },
        {
          key: "masterbox.shipment.flight.reference",
          label: this.$t("volumesPage.downloadFields.flightReference"),
        },
        {
          key: "masterbox.shipment.flight.airline",
          label: this.$t("volumesPage.downloadFields.airline"),
        },
        {
          key: "masterbox.shipment.flight.green_light",
          label: this.$t("volumesPage.downloadFields.greenLight"),
        },
        {
          key: "masterbox.shipment.flight.prealerts_sent",
          label: this.$t("volumesPage.downloadFields.prealertsSent"),
        },
        {
          key: "masterbox.shipment.flight.departure_airport",
          label: this.$t("volumesPage.downloadFields.departureAirport"),
        },
        {
          key: "masterbox.shipment.flight.arrival_airport",
          label: this.$t("volumesPage.downloadFields.arrivalAirport"),
        },
        {
          key: "masterbox.shipment.flight.departure_date",
          label: this.$t("volumesPage.downloadFields.departureStartDate"),
        },
      ];
    },
    financial_statuses() {
      return [
        {
          value: "new_order",
          text: this.$t("volumesPage.financialStatuses.nNew"),
        },
        {
          value: "to_invoice",
          text: this.$t("volumesPage.financialStatuses.toInvoice"),
        },
        {
          value: "invoiced",
          text: this.$t("volumesPage.financialStatuses.invoiced"),
        },
        {
          value: "canceled",
          text: this.$t("volumesPage.financialStatuses.canceled"),
        },
        {
          value: "not_charged",
          text: this.$t("volumesPage.financialStatuses.notCharged"),
        },
      ];
    },
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    optionTook(event) {
      this[event]();
    },
    hasCn38Label(cn38) {
      return cn38 && cn38?.substring(0, 9) != "TEMPORARY";
    },
    showModalCreateMasterboxes() {
      this.showCreateMasterboxModal = true;
    },
    showModalDownloadVolumes() {
      this.$helpers.openModal("select-columns-download");
    },
    showVolumeDetails(id) {
      this.setUrlSearchParam("id", id);
      this.selectedVolumeId = id;
      this.isVolumeModalOpen = true;
    },
    checkQuery() {
      const { id, nobordistCheckpointId } = this.$route.query;

      if (nobordistCheckpointId) {
        this.loadModalData(nobordistCheckpointId);
        return;
      }

      if (id) {
        this.showVolumeDetails(id);
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.nobordistCheckpointId) {
          this.$router.push({ query: { nobordistCheckpointId: id } });
        }

        this.$helpers.openModal("ModalNobordistCheckpointsView");
        return;
      }
    },
    generateBoxLabel(volumeId) {
      orderLabelService.generateBoxLabel(volumeId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    generateVolumeFirstMile(volumeId) {
      orderLabelService.generateVolumeFirstMile(volumeId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    generateMasterBoxLabel(MasterboxId) {
      masterBoxService.generateMasterBoxLabel(MasterboxId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    generateShipmentLabel(ShipmentId) {
      shipmentService.generateShipmentLabel(ShipmentId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    generateFlightLabel(FlightId) {
      flightService.generateFlightLabel(FlightId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    standardizeValue(val) {
      const finalVal = parseFloat(val).toFixed(2);
      if (finalVal === "NaN") {
        return "0.00";
      }
      return finalVal;
    },
    getCurrency(currency) {
      if (currency) {
        if (currency.toUpperCase() === "USD") {
          return "$ ";
        }
        return "R$ ";
      }
      return;
    },
    downloadVolumes(columns) {
      this.isDownloading = true;

      downloadsService
        .loadProcessing({
          data_type: "volumes",
          reference_ids: this.volumesSelected,
          columns: columns,
        })
        .then(() => {
          this.$helpers.closeModal("select-columns-download");
          this.$router.push("/archives");
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
    downloadMsds(orderId) {
      this.isAlertVisible = true;
      productDocumentService.downloadMsds(orderId).then((response) => {
        const file_path = response.data.data;
        this.isAlertVisible = false;
        if (file_path) window.open(response.data.data);
        else {
          this.$toasted.show("Nothing to download for this order.", {
            type: "warning",
            theme: "bubble",
            duration: 4000,
          });
        }
      });
    },
  },
};
</script>
