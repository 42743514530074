<template>
  <div>
    <div
      class="has-min-height filters-and-table"
      :class="maxWidth ? 'width-45' : ''"
    >
      <div class="filters-background">
        <div class="filters-wrapper">
          <div
            class="filters-options d-flex justify-content-between align-items-end"
          >
            <table-filters
              v-if="filterable"
              :namespace="namespace"
              :filter-options="filterOptions"
              @emitFilter="reloadFilters($event)"
            />
            <div v-if="tabs && tabs.length > 0" class="buttons-action-wrapper">
              <div
                v-for="(tab, index) in tabs"
                :key="index"
                class="position-relative"
              >
                <slot name="buttonsAction"></slot>
              </div>
            </div>

            <div v-else class="buttons-action-wrapper">
              <div class="position-relative">
                <slot name="buttonsAction"></slot>
              </div>
            </div>
          </div>
          <div
            class="filters-options d-flex justify-content-between align-items-end"
          >
            <div v-if="namespace">
              <b-button
                v-if="selectable"
                pill
                variant="secondary"
                class="btn-sm d-inline-block"
                type="button"
                @click="showSelectOptions = !showSelectOptions"
                >{{ $t("select") }}
                <i
                  :class="
                    showSelectOptions
                      ? 'fas fa-angle-left'
                      : 'fas fa-angle-right'
                  "
                ></i
              ></b-button>
              <b-button
                v-if="showSelectOptions && selectable && selectAllFromAPI"
                pill
                variant="secondary"
                class="btn-sm d-inline-block"
                type="button"
                @click="selectAllItems()"
                >{{ $t("selectAll") }}
                {{ renameNamespace(namespace) }}</b-button
              >
              <b-button
                v-if="showSelectOptions && selectable"
                pill
                variant="secondary"
                class="btn-sm d-inline-block"
                type="button"
                @click="selectAllRows()"
                >{{ $t("selectAll") }}</b-button
              >
              <b-button
                v-if="showSelectOptions && selectable"
                pill
                variant="secondary"
                class="btn-sm d-inline-block"
                type="button"
                @click="clearSelected"
              >
                {{ $t("clearSelected") }}
              </b-button>

              <!-- <select-table-columns
                v-if="fields"
                :all-fields="allFields"
                :modal-key="namespace"
                only-icon="fas fa-table"
                @initialFields="setInitialFields($event)"
                @selectedFields="reloadFields($event)"
              /> -->
            </div>
            <div v-if="tabs && tabs.length > 0" class="buttons-action-wrapper">
              <div
                v-for="(tab, index) in tabs"
                :key="index"
                class="position-relative"
              >
                <items-per-page
                  v-if="namespace && hasLimit && currentTab(tab.label)"
                  :per-page="limit"
                  save-to="orders"
                  @emitValue="setFilterTrackLimit"
                />
              </div>
            </div>

            <div v-else class="buttons-action-wrapper d-flex">
              <div class="position-relative ml-2">
                <items-per-page
                  v-if="namespace && hasLimit"
                  :per-page="limit"
                  save-to="orders"
                  @emitValue="setFilterTrackLimit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <NbSelectTableColumnsModal
        v-if="fields"
        :id="`table-columns-options-${namespace}`"
        :width="460"
        icon="list"
        :all-fields="allFields"
        :page-name="`${namespace}`"
        @selectedFields="reloadFields($event)"
      />
      <div>
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          class="button-tab"
          :class="tab.label == tab.current ? 'selected' : ''"
          @click="switchTab(tab.label)"
        >
          {{ tab.label }} <span class="badge badge-light">{{ tab.found }}</span>
        </button>
      </div>
      <div
        v-if="namespace"
        class="d-flex justify-content-between align-items-end mt-4 table-items-info-wrapper"
      >
        <div class="table-items-info w-100">
          <div v-if="totalRows > 0" class="ml-2">
            {{ $t("found") }} {{ totalRows }}
            <span v-if="selected.length > 0" class="ml-3 badge badge-info"
              >{{ selected.length }} {{ $t("selected") }}
            </span>
            <slot name="foundInfo"></slot>
          </div>
        </div>
      </div>
      <b-table
        :id="'table-' + namespace"
        ref="selectableTable"
        :class="tabs ? 'mt-1' : 'mt-0'"
        :select-mode="selectMode"
        :filter="filter"
        :selectable="selectable"
        :items="items"
        :fields="selectedTableColumns"
        :per-page="limit"
        :current-page="totalRows === items.length ? currentPage : 1"
        stacked="md"
        sort-icon-left
        @row-selected="onRowSelected"
      >
        <template v-for="(_, slotName) of $scopedSlots" #[slotName]="scope">
          <slot :name="slotName" v-bind="scope" />
        </template>
      </b-table>
    </div>
    <div class="pagination-wrapper mt-4" :class="maxWidth ? 'width-45' : ''">
      <pagination-custom v-if="namespace" :namespace="namespace" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ItemsPerPage from "@/components/ItemsPerPage.vue";
import PaginationCustom from "@/components/PaginationCustom";
import { BTable } from "bootstrap-vue";
import TableFilters from "@/components/tablefilters/TableFilters.vue";
import NbSelectTableColumnsModal from "@/components/tables/NbSelectTableColumnsModal.vue";

export default {
  name: "LayoutContent",
  components: {
    "b-table": BTable,
    ItemsPerPage,
    TableFilters,
    PaginationCustom,
    NbSelectTableColumnsModal,
  },
  props: {
    namespace: {
      type: String,
      default: "",
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
    allFields: {
      type: Array,
      default: () => [],
    },
    selectable: {
      type: String,
      default: "",
    },
    selectMode: {
      type: String,
      default: "multi",
    },
    selectAllFromAPI: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Function,
      default: null,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    hasLimit: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      required: false,
      default: "",
    },
    tabs: {
      type: Array,
      required: false,
      default: () => [],
    },
    currentTable: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: () => {
    return {
      selected: [],
      selectedTableColumns: [],
      showSelectOptions: false,
    };
  },
  computed: {
    totalRows() {
      return this.$store.state[this.namespace].totalRows;
    },
    ids() {
      return this.$store.state[this.namespace].ids;
    },
    limit() {
      return this.$store.state[this.namespace].limit;
    },
    currentPage() {
      return this.$store.state[this.namespace].currentPage;
    },
    ...mapState("config", {
      fullScreen: (state) => state.fullScreen,
    }),
  },
  created() {
    this.selectedTableColumns = this.allFields;
  },
  methods: {
    reloadFields(event) {
      this.selectedTableColumns = event;
    },
    setInitialFields(event) {
      this.selectedTableColumns = event;
    },
    reloadFilters(event) {
      this.$store.commit(this.namespace + "/SET_FILTERS", event);
    },
    setFilterTrackLimit(value) {
      this.$store.commit(this.namespace + "/SET_LIMIT", value);
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    async selectAllItems() {
      await this.selectAllRows();
      this.selected = this.ids;
      this.$emit("selectedRows", this.selected);
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
      this.selected = [];
    },
    onRowSelected(items) {
      this.selected = items.map((a) => a.id);
      this.$emit("selectedRows", this.selected);
    },
    switchTab(tab) {
      this.$emit("switchTab", tab);
    },
    currentTab(label) {
      if (this.currentTable == label) {
        return true;
      }
      return false;
    },
    renameNamespace(namespace) {
      return namespace.replaceAll("_", " ");
    },
  },
};
</script>

<style scoped>
.width-45 {
  width: 45vw !important;
  min-width: 45vw !important;
}
.button-tab {
  transition: all 0.8s;
  background: transparent 0% 0% no-repeat padding-box;
  border: 0px;
  border-bottom: 2px solid #f7f7fc;
  padding: 0.69rem 1rem;
  color: #6e6e82;
}
.button-tab:hover {
  text-decoration: underline;
}
.button-tab.selected {
  transition: all 0.8s;
  border-bottom: 2px solid #000014;
  color: black;
}
.button-tab.selected:hover {
  text-decoration: none;
}
.badge.badge-light {
  background: #f0f0fa;
  border-radius: 4px;
  padding: 4px 8px;
}
</style>
