<template>
  <button class="option" @click="onClick">
    <slot></slot>
  </button>
</template>

<script>
export default {
  inject: ["datatableOptionsContext"],
  methods: {
    onClick() {
      this.$emit("click");
      this.datatableOptionsContext.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.option {
  border: none;
  background-color: var(--gray-05);
  outline: none;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
  font-size: 0.75rem;

  &:hover {
    background-color: var(--gray-10);
  }
  & + .option {
    border-top: 1px solid var(--gray-10);
  }
}
</style>
