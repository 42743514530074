<template>
  <div
    v-if="isAllItemsFromCurrentPageSelected"
    class="d-flex total-of justify-content-center"
  >
    <div class="mr-1">
      <span class="mr-1">{{ $t("components.nbTable.all") }}</span>
      <span class="font-weight-bold">{{ totalSelectedItems }}</span>
      {{ $t(`items`) }}
      <span class=" ">{{ $t("components.nbTable.onThisPage") }}</span>
      {{ $tc("components.nbTable.isSelected", totalSelectedItems) }}.
    </div>
    <a href="" class="link-1" @click.prevent="onSelectAllItems">
      {{ $t("selectAll") }} {{ total }} {{ $t("items") }}
    </a>
  </div>
  <div v-else>
    <span class="font-weight-bold">{{ totalSelectedItems }}</span>
    {{ $t("items") }} {{ $t("selected") }}.
    <a href="#" class="link-1" @click.prevent="onClear">
      {{ $t("clear") }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    pageLength: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isAllItemsFromCurrentPageSelected() {
      return this.pageLength === this.selected.length;
    },
    totalSelectedItems() {
      return this.selected.length;
    },
  },
  methods: {
    onSelectAllItems() {
      this.$emit("selectAll");
    },
    onClear() {
      this.$emit("clear");
    },
  },
};
</script>

<style lang="scss" scoped></style>
