<template>
  <div class="stepper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    currentStep: {
      get() {
        return this.value;
      },
      set(stepIndex) {
        this.$emit("input", stepIndex);
      },
    },
  },
  provide() {
    const vm = this;
    const context = {
      setStep: (stepIndex) => vm.setStep(stepIndex),
    };

    Object.defineProperty(context, "currentStep", {
      get: () => this.currentStep,
      enumerable: true,
    });
    return {
      stepper: context,
    };
  },
  methods: {
    setStep(stepIndex) {
      this.currentStep = stepIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  align-items: flex-start;
}
</style>
