import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state: {
    items: [],
    filters: [],
    currentPage: 1,
    totalRows: 1,
    limit: 20,
  },
  mutations: mutations,
  actions: actions,
};
