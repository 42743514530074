import api from "./HttpService";

export default class CheckpointService {
  /**
   * Get Checkpoint
   *
   * @returns {Promise}
   */
  getCheckpoints(offset, limit, filters = {}) {
    if (limit > 0) {
      filters.offset = (offset - 1) * limit;
    } else {
      filters.offset = (offset - 1) * 20;
    }
    filters.source = "admin";
    filters.limit = limit;
    return api.get("/v1/checkpoints", {
      params: filters,
    });
  }
  /**
   * Get allCheckpoint
   *
   * @returns {Promise}
   */
  getAllCheckpoints() {
    return api.get("/v1/checkpoints?limit=99999");
  }
  /**
   * Get Checkpoint by id
   *
   * @returns {Promise}
   */
  getCheckpoint(id) {
    return api.get("/v1/checkpoints/" + id);
  }
}
