<template>
  <NbCard id="contracts" :title="$t('components.searchData.contracts')">
    <template #body>
      <div class="grid gap-4">
        <div>
          <h2 class="heading-2">
            {{ $t("sellersViewPage.allowedContracts") }}
          </h2>

          <span v-if="!contracts.length">-</span>

          <Datatable :columns="tableColumns" :data="contracts">
            <template #slug="{ row }">
              <router-link
                :to="`/contracts/contracts?contractId=` + row.id"
                class="link-1"
              >
                {{ row.slug }}
              </router-link>
            </template>
            <template #start_validity_date="{ row }">
              {{ row.start_validity_date | date }}
            </template>
            <template #end_validity_date="{ row }">
              {{ row.end_validity_date | date }}
            </template>
          </Datatable>
        </div>

        <div>
          <h2 class="heading-2">
            {{ $t("sellersViewPage.contractOperations") }}
          </h2>

          <span v-if="!operations.length">-</span>

          <div class="d-flex gap-4 flex-wrap">
            <NbBadge
              v-for="operation in operations"
              :key="operation"
              type="success"
              :text="operation"
            />
          </div>
        </div>
      </div>
    </template>
  </NbCard>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import Datatable from "@/components/datatable/Datatable.vue";
import moment from "moment";

export default {
  props: {
    contracts: {
      type: Array,
      required: true,
    },
    operations: {
      type: Array,
      required: true,
    },
  },
  components: {
    NbCard,
    NbBadge,
    Datatable,
  },
  filters: {
    date(val) {
      return val && moment(val).format("DD/MM/YYYY");
    },
  },
  computed: {
    tableColumns() {
      return [
        {
          label: this.$t("name"),
          key: "name",
        },
        {
          label: this.$t("slug"),
          key: "slug",
        },
        {
          label: this.$t("sellersViewPage.startValidityDate"),
          key: "start_validity_date",
        },
        {
          label: this.$t("sellersViewPage.endValidityDate"),
          key: "end_validity_date",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
