<template>
  <h4 class="heading-4">
    <slot>
      {{ title }}
    </slot>
  </h4>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped></style>
