<template>
  <div class="listbox">
    <form @submit.prevent="handleSearch">
      <NbSearchInput
        v-model="search"
        id="search"
        :placeholder="placeholder"
        :name="label"
        @focus="handleFocus"
      />

      <div v-if="isOpen" class="results">
        <Spinner v-if="loading" size="md" class="mx-auto" />
        <p v-if="fetched && !options.length" class="body-4">
          {{ $t("notFound") }}
        </p>

        <button
          v-for="option in options"
          type="button"
          :key="option[keyValue]"
          :disabled="isSelected(option[keyValue])"
          @click="selectItem(option)"
        >
          {{ option[keyLabel] }}
        </button>
      </div>
      <slot></slot>
    </form>
  </div>
</template>

<script>
import NbSearchInput from "@/components/input/text/NbSearchInput.vue";
import Spinner from "../Spinner.vue";

export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    keyLabel: {
      type: String,
      default: "name",
    },
    keyValue: {
      type: String,
      default: "id",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NbSearchInput,
    Spinner,
  },
  data() {
    return {
      search: "",
      isOpen: false,
      fetched: false,
    };
  },
  watch: {
    search(val) {
      if (!val) {
        this.isOpen = false;
      }
    },
    loading(isLoading, oldValue) {
      if (isLoading) {
        this.fetched = false;
        this.isOpen = true;
        return;
      }

      if (oldValue) {
        this.fetched = true;
      }
    },
  },
  methods: {
    handleSearch() {
      if (!this.search.trim()) {
        return;
      }

      this.$emit("search", this.search);
    },
    handleFocus() {
      if (this.search && this.options.length) {
        this.isOpen = true;
      }
    },
    selectItem(value) {
      if (!this.selected.includes(value)) {
        this.close();
        this.search = "";
        this.$emit("select", value);
      }
    },
    isSelected(value) {
      return this.selected.includes(value);
    },
    close() {
      this.isOpen = false;
      this.fetched = false;
    },
  },
};
</script>

<style scoped lang="scss">
.listbox {
  form {
    position: relative;
    .results {
      position: absolute;
      top: 2.5rem;
      width: 100%;
      padding: 1rem;
      border-radius: 4px;
      background-color: var(--white);
      border: 1px solid var(--gray-05);
      left: 0;
      max-height: 250px;
      z-index: 10;
      overflow-y: auto;
      button {
        border: none;
        display: block;
        background-color: transparent;
        width: 100%;
        text-align: left;
        padding: 0.5rem 1rem;
        &:hover {
          background-color: var(--gray-05);
        }
      }
    }
  }
}
</style>
