export function jsonToFile(data, filename) {
  const chain = {
    generateLink() {
      const jsonParse = JSON.stringify(data);
      const blob = new Blob([jsonParse], { type: "application/json" });
      const link = window.URL.createObjectURL(blob);
      this.createTarget(link);
    },
    createTarget(link) {
      const target = document.createElement("a");
      target.download = filename;
      target.href = link;
      target.target = "_blank";
      this.dispatch(target);
      this.revoke(target, link);
    },
    dispatch(target) {
      const clickEvent = new MouseEvent("click", {
        bubbles: false,
        cancelable: false,
      });
      target.dispatchEvent(clickEvent);
    },
    revoke(target, link) {
      window.URL.revokeObjectURL(link);
      target.remove();
    },
  };
  chain.generateLink();
}
