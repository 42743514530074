<template>
  <div class="grid grid-cols-4 gap-4">
    <div>
      <label class="input-label">
        {{ $t("volumesViewPage.creationDate") }}
      </label>
      <div class="input-view">
        {{ volume.created_at | datetime }}
      </div>
    </div>
    <div>
      <label class="input-label">
        {{ $t("volumesPage.fields.estimatedDeliveryDate") }}
      </label>
      <div class="input-view">
        <span v-if="volume.order?.estimated_delivery_date">
          {{ volume.order.estimated_delivery_date | datetime }}
        </span>
        <span v-else>-</span>
      </div>
    </div>
    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('volumesPage.fields.incoterm')"
      v-model="volume.order.incoterm"
      id="volume.order.incoterm"
    />

    <NbTextInput
      disabled
      variant="borderless-gray-60"
      :name="$t('volumesPage.fields.financialStatus')"
      v-model="volume.order.financial_status"
      id="volume.order.financial_status"
    />

    <router-link
      v-if="volume?.current_status?.title"
      :to="`/checkpoints/checkpoints?nobordistCheckpointId=${volume.current_status.id}`"
      class="link-1 col-span-4"
    >
      <NbTextInput
        disabled
        variant="borderless-gray-60"
        :name="$t('volumesPage.fields.currentStatus')"
        v-model="volume.current_status.title"
        id="volume.current_status.title"
      />
    </router-link>
    <NbTextInput
      v-else
      disabled
      variant="borderless-gray-60"
      :name="$t('volumesPage.fields.currentStatus')"
      value="-"
      id="volume.current_status.title"
      class="col-span-4"
    />

    <div class="info">
      <small>
        {{ $t("volumesViewPage.landedCost") }}
        <NbHelpText
          id="is-landed-popover"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("volumesViewPage.landedCostDescription") }}
        </NbHelpText>
      </small>
      <IsCheckedIcon :checked="volume.order.is_landed_cost" />
    </div>
    <div class="info">
      <small>
        {{ $t("volumesViewPage.dispatched") }}
        <NbHelpText
          id="is-dispatched-popover"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("volumesViewPage.dispatchedDescription") }}
        </NbHelpText>
      </small>
      <IsCheckedIcon :checked="volume.order.is_dispatched" />
    </div>
    <div class="info">
      <small>{{ $t("volumesViewPage.senderIsCpf") }}</small>
      <IsCheckedIcon :checked="volume.order.remetente_is_cpf" />
    </div>
    <div class="info">
      <small>{{ $t("volumesViewPage.receiverIsCommercial") }}</small>
      <IsCheckedIcon :checked="!!volume.order.is_commercial_destination" />
    </div>
  </div>
</template>

<script>
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";

export default {
  props: {
    volume: {
      type: Object,
      required: true,
    },
  },
  components: {
    IsCheckedIcon,
    NbHelpText,
    NbTextInput,
  },
};
</script>

<style lang="scss" scoped></style>
