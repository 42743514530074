<template>
  <NbCard
    id="login"
    :title="$t('userProfilePage.loginInformation')"
    :subTitle="$t('userProfilePage.loginInformationSubtitle')"
  >
    <template #body>
      <form @submit.prevent="confirmPasswordModal" class="login-form">
        <div class="group">
          <NbTextInput
            v-model.trim="formData.new_email"
            :name="$t('email')"
            id="email"
            class="email"
            :error="[errors.get('new_email')]"
            @input="validateEmail()"
          />

          <NbPassInput
            v-model="formData.new_password"
            :name="$t('password')"
            id="new_password"
            required
            type="password"
            :placeholder="$t('userProfilePage.newPassConfirm')"
            :error="[errors.get('new_password')]"
            @input="validateNewPassword()"
          />
        </div>

        <NbButton class="d-flex ml-auto mt-4" :disabled="loading">
          {{ $t("save") }}
        </NbButton>
      </form>
    </template>

    <Modal v-model="isModalConfirmPasswordOpen">
      <ConfirmPasswordModal
        v-model="formData.password"
        @input="errors.clear('password')"
        :error="errors.get('password')"
        @submit="updateEmailPassword"
        :loading="loading"
      />
    </Modal>
  </NbCard>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbPassInput from "@/components/input/text/NbPassInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import Errors from "@/utils/Errors.js";
import UserService from "@/services/UserService";
import UsersService from "@/services/UsersService";
import ConfirmPasswordModal from "./ConfirmPasswordModal.vue";

const userService = new UserService();
const usersService = new UsersService();

export default {
  components: {
    NbCard,
    NbTextInput,
    NbButton,
    ConfirmPasswordModal,
    NbPassInput,
    Modal,
  },
  data() {
    return {
      errors: new Errors(),
      loading: false,
      isModalConfirmPasswordOpen: false,
      formData: {
        new_email: this.$store.state.user.email,
        new_password: "",
        password: "",
      },
    };
  },
  methods: {
    validateNewPassword() {
      let errors = [];
      if (this.formData.new_password.match(/\s/)) {
        errors.push(this.$t("errorMessages.whiteSpace"));
      }
      if (this.formData.new_password.length < 11) {
        errors.push(this.$t("errorMessages.minLength", { value: 11 }));
      }
      if (!this.formData.new_password.match(/[a-z]/)) {
        errors.push(this.$t("errorMessages.minLowercase", { value: 1 }));
      }
      if (!this.formData.new_password.match(/[A-Z]/)) {
        errors.push(this.$t("errorMessages.minUppercase", { value: 1 }));
      }
      if (!this.formData.new_password.match(/\d/)) {
        errors.push(this.$t("errorMessages.minNumber", { value: 1 }));
      }
      if (!this.formData.new_password.match(/\W/)) {
        errors.push(this.$t("errorMessages.minSpecialCharacter", { value: 1 }));
      }

      if (errors.length && this.formData.new_password.length) {
        this.errors.set("new_password", [errors.join(", ")]);
        return;
      }

      this.errors.clear("new_password");
    },
    validateEmail() {
      if (
        !this.formData.new_email?.trim() ||
        this.formData.new_email.length < 8 ||
        !this.formData.new_email.match(/.*@.*\..+/)
      ) {
        this.errors.set("new_email", [this.$t("errorMessages.invalidEmail")]);
        return;
      }

      this.errors.clear("new_email");
    },
    confirmPasswordModal() {
      if (this.errors.has("new_password") || this.errors.has("new_email")) {
        return;
      }
      this.formData.password = "";
      this.errors.clear("password");
      this.isModalConfirmPasswordOpen = true;
    },
    closeModal() {
      this.isModalConfirmPasswordOpen = false;
    },
    updateEmail() {
      return usersService.updateEmailPassword({
        new_email: this.formData.new_email,
        password: this.formData.password,
      });
    },
    updatePassword() {
      if (this.formData.new_password.trim()) {
        return usersService.updateEmailPassword({
          new_password: this.formData.new_password,
          password: this.formData.password,
        });
      }
    },
    clearPassword() {
      this.formData.password = "";
      this.formData.new_password = "";
    },
    async updateEmailPassword() {
      try {
        this.errors.clear();
        this.loading = true;

        const [response] = await Promise.all([
          this.updateEmail(),
          this.updatePassword(),
        ]);
        userService.setLoggedInUser(JSON.stringify(this.user));
        this.$bvToast.toast(response.data.messages[0] || "Success", {
          solid: true,
          variant: "success",
        });
        this.isModalConfirmPasswordOpen = false;
        this.clearPassword();
      } catch (error) {
        this.errors.set("password", [
          error.response.data.messages[0] || "Something unexcepted happened.",
        ]);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  .group {
    display: grid;
    gap: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .login-form {
    .group {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
