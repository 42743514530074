<template>
  <NbBadge :type="badgeData.variant" :text="badgeData.title" />
</template>

<script>
import NbBadge from "@/components/alerts/NbBadge.vue";
export default {
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  components: {
    NbBadge,
  },
  inject: ["alertLogContext"],
  computed: {
    badgeData() {
      const statuses = {
        new_issue: {
          title: this.$t("alertLogsPage.new"),
          variant: "success",
        },
        solved_issue: {
          title: this.$t("alertLogsPage.solved"),
          variant: "success",
        },
        priority_issue: {
          title: this.$t("alertLogsPage.priority"),
          variant: "danger",
        },
        in_progress_issue: {
          title: this.$t("alertLogsPage.inProgress"),
          variant: "warning",
        },
        waiting_payment: {
          title: this.$t("alertLogsPage.waitingforPayment"),
          variant: "warning",
        },
        sent_again: {
          title: this.$t("alertLogsPage.sentAgain"),
          variant: "warning",
        },
        payment_done: {
          title: this.$t("alertLogsPage.paymentDone"),
          variant: "success",
        },
        pending: {
          title: this.$t("alertLogsPage.pending"),
          variant: "warning",
        },
        paid_external: {
          title: this.$t("alertLogsPage.paidExternally"),
          variant: "success",
        },
        need_seller: {
          title: this.$t("alertLogsPage.needSellerAction"),
          variant: "danger",
        },
      };

      return (
        statuses?.[this.data] || {
          title: this.data,
          variant: "success",
        }
      );
    },
    normalizedData() {
      return this.data
        .split("_")
        .map((item) => item?.charAt(0)?.toUpperCase().concat(item.substring(1)))
        .join(" ");
    },
  },
};
</script>

<style scoped></style>
