<template>
  <div>
    <div class="d-flex">
      <BackButton />
      <div class="d-flex flex-column gap-2">
        <h4 class="heading-4 m-0">
          {{ seller.name || "-" }}
        </h4>
        <p class="body-4">
          {{ $t("saldoViewPage.currentBalance") }}:
          <strong :class="balance >= 0 ? 'text-success' : 'text-danger'">
            {{ balance | amount }}
          </strong>
        </p>
      </div>
    </div>

    <section class="page-container mt-4">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="onSwitchTab" />

      <DatatableRoot
        name="seller_account_events"
        :namespace="`sellers/${sellerId}/seller_account_events`"
        :columns="tableColumns"
        @count="totalEvents = $event"
        :showColumnOptions="false"
      >
        <template #value="{ row }">
          <strong :class="row.value >= 0 ? 'text-success' : 'text-danger'">
            {{ row.value | amount }}
          </strong>
        </template>
        <template #description="{ row }">
          <div>
            <div class="ellipsis">
              <span v-b-popover.hover.top :title="row.description">
                {{ row.description || "-" }}
              </span>
            </div>
          </div>
        </template>

        <template #created_at="{ row }">
          {{ row.created_at | datetime }}
        </template>
      </DatatableRoot>
    </section>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import SellerAccountService from "@/services/SellerAccountService";
import SellerService from "@/services/SellerService";
import NbTabs from "@/components/tables/NbTabs.vue";
import moment from "moment";

const sellerAccountService = new SellerAccountService();
const sellerService = new SellerService();

export default {
  name: "SaldoView",
  components: {
    BackButton,
    DatatableRoot,
    NbTabs,
  },
  filters: {
    amount(val) {
      return (
        val &&
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(val)
      );
    },
    datetime(val) {
      return val && moment(val).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  data: () => {
    return {
      accountEvents: [],
      sellerId: "",
      balance: 0,
      seller: {},
      currentTab: "all",
      totalEvents: 0,
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("saldoViewPage.events"),
          current: this.currentTab,
          found: this.totalEvents,
        },
      ];
    },
    tableColumns() {
      return [
        {
          key: "created_at",
          label: this.$t("registrationDate"),
        },
        {
          key: "value",
          label: this.$t("saldoViewPage.fields.value"),
        },
        {
          key: "description",
          label: this.$t("saldoViewPage.fields.description"),
          tdClassName: "w-80",
        },
      ];
    },
  },
  methods: {
    async getSeller() {
      const { data } = await sellerService.getOneSeller(this.sellerId);
      this.seller = data.data;
    },
    getBalance() {
      sellerAccountService
        .getBalance(this.$route.params.seller_id)
        .then((response) => {
          this.balance = response.data.balance;
        });
    },
    loadAccountEvents() {
      sellerAccountService.getEvents(this.sellerId).then((response) => {
        this.accountEvents = response.data.seller_account_events;
      });
    },
  },
  beforeMount() {
    this.sellerId = this.$route.params.seller_id;
    this.getSeller();
    this.getBalance();
    this.loadAccountEvents();
  },
};
</script>

<style scoped lang="scss">
.ellipsis {
  position: relative;
}

.ellipsis:before {
  content: "&nbsp;";
  visibility: hidden;
}

.ellipsis span {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: fit-content;
}
</style>
