import api from "./HttpService";

export default class DutiesAndTaxesStatusService {
  certificate(id) {
    return api.get("/v1/duties_and_taxes_status/" + id + "/certificate");
  }

  get(id) {
    return api.get("/v1/duties_and_taxes_status/" + id);
  }
}
