<template>
  <div>
    <div class="d-md-flex justify-content-md-between">
      <NbPageTitle :title="$t('sellerAccountEventsPage.title')" />
      <NbButton @click="handleAddCreditModal">
        {{ $t("sellerAccountEventsPage.addCredit") }}
      </NbButton>
    </div>

    <section class="page-container mt-4">
      <NbTabs class="mb-4" :tabs="tabsOptions" />
      <DatatableRoot
        namespace="seller_account_events"
        ref="sellerAccountsEventsTableRef"
        :columns="tableColumns"
        :filters="tableFilters"
        @count="totalEvents = $event"
        selectable
        :selected.sync="selected"
      >
        <template #actions>
          <DatatableActions :disabled="!selected.length">
            <DatatableAction @click="onDownloadFinances">
              {{ $t("download") }}
            </DatatableAction>
          </DatatableActions>
        </template>

        <template #seller_name="{ row }">
          <router-link
            style="width: 30px; height: 30px"
            class="link-1"
            :to="`/users_management/sellers?id=${row.seller_id}`"
          >
            {{ row.seller_name }}
          </router-link>
        </template>
        <template #category="{ row }">
          <UpcaseString :string="row.category" />
        </template>
        <template #value="{ row }">
          <div v-if="row.value > 0" style="color: #00cb8b">
            + R$ {{ parseFloat(row.value).toFixed(2) }}
          </div>
          <div v-else style="color: #fb1f1f">
            - R$ {{ Math.abs(row.value).toFixed(2) }}
          </div>
        </template>

        <template #created_at="{ row }">
          {{ row.created_at | dateTime }}
        </template>
      </DatatableRoot>
    </section>

    <Modal v-model="showDownloadFinancesModal">
      <DownloadFinances :reference-ids="selected" />
    </Modal>

    <Modal v-model="showAddCreditModal">
      <AddCredit @success="$refs?.sellerAccountsEventsTableRef?.getData()" />
    </Modal>
  </div>
</template>

<script>
import SellerAccountService from "@/services/SellerAccountService";
import UpcaseString from "@/components/UpcaseString";
import NbTabs from "@/components/tables/NbTabs.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import DatatableActions from "@/components/datatable/DatatableActions.vue";
import DatatableAction from "@/components/datatable/DatatableAction.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import moment from "moment";
import DownloadFinances from "./components/DownloadFinances.vue";
import Modal from "@/components/modal/Modal.vue";
import AddCredit from "./components/AddCredit.vue";

const sellerAccountService = new SellerAccountService();

export default {
  name: "SellerAccountEvents",
  components: {
    UpcaseString,
    NbTabs,
    DatatableRoot,
    NbPageTitle,
    NbButton,
    DownloadFinances,
    Modal,
    AddCredit,
    DatatableActions,
    DatatableAction,
  },
  filters: {
    dateTime(value) {
      return value && moment(value).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  data: () => {
    return {
      seller: {},
      selected: [],
      categories: {},
      currentTab: "all",
      totalEvents: 0,
      showDownloadFinancesModal: false,
      showAddCreditModal: false,
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: this.currentTab,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.totalEvents,
        },
      ];
    },
    sellers() {
      return this.$store.state.sellers.all_items;
    },
    tableColumns() {
      return [
        { key: "id", label: "ID" },
        {
          key: "seller_name",
          label: this.$t("sellerAccountEventsPage.seller"),
        },
        {
          key: "category",
          label: this.$t("sellerAccountEventsPage.fields.category"),
        },
        {
          key: "event_attachment",
          label: this.$t("sellerAccountEventsPage.fields.attachment"),
        },
        {
          key: "value",
          label: this.$t("sellerAccountEventsPage.fields.value"),
        },
        {
          key: "description",
          label: this.$t("sellerAccountEventsPage.fields.description"),

          thClass: ["large-content"],
        },
        {
          key: "created_at",
          label: this.$t("sellerAccountEventsPage.fields.creationDate"),
        },
      ];
    },
    tableFilters() {
      return [
        {
          label: this.$t("sellerAccountEventsPage.seller"),
          inputs: [
            {
              key: "seller_id",
              type: "select",
              options: this.sellers.map((item) => ({
                text: item?.name?.concat(` (${item.id})`),
                value: item.id,
              })),
            },
          ],
        },
        {
          label:
            this.$t("sellerAccountEventsPage.fields.value") + ` (Min and Max)`,
          inputs: [
            {
              key: "start_value",
              type: "money",
              currency: "R$",
            },
            {
              key: "end_value",
              type: "money",
              currency: "R$",
            },
          ],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
      ];
    },
  },
  methods: {
    handleAddCreditModal() {
      this.showAddCreditModal = true;
    },
    onDownloadFinances() {
      this.showDownloadFinancesModal = true;
    },
    getCategories() {
      sellerAccountService.getCategories(this.seller_id).then((response) => {
        this.categories = response.data.data;
      });
    },
  },
  created() {
    this.getCategories();
  },
};
</script>

<style scoped>
.seller-link {
  width: 10px;
  height: 10px;
  cursor: pointer;
  color: var(--secondary);
}
</style>
