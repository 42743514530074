<template>
  <div class="nb-status" :class="`nb-status-${type}`">
    <i class="status-icon fas fa-circle" :class="`status-text-${type}`"></i>
    <span :class="`status-text ${classes}`">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: "NbStatus",
  props: {
    text: {
      type: String,
      default: "",
      require: true,
    },
    type: {
      type: String,
      default: "",
      require: true,
    },
    classes: {
      type: String,
      default: "",
      require: false,
    },
  },
};
</script>

<style scoped>
.nb-status {
  position: relative;
  color: var(--black);
  text-align: left;
  font: normal normal normal 14px/20px var(--font-family-base);
  letter-spacing: 0px;
}
.link .status-text {
  color: var(--gray-60);
  text-decoration: underline;
  font: normal normal 600 14px/20px var(--font-family-base);
}
.status-icon {
  font-size: 0.5em;
  position: absolute;
  top: 45%;
  left: 0px;
  transform: translateY(-50%);
}
.status-text {
  margin-left: 0.9rem;
}
.status-text- {
  color: var(--gray-20) !important;
}
.status-text-danger {
  color: var(--error) !important;
}
.status-text-warning {
  color: var(--attention) !important;
}
.status-text-success {
  color: var(--success) !important;
}
</style>
