import api from "./HttpService";

export default class GroupService {
  /**
   * Get groups
   *
   * @returns {Promise}
   */
  getGroups() {
    return api.get("/v1/groups");
  }
  /**
   * Get group by id
   *
   * @returns {Promise}
   */
  getGroup(id) {
    return api.get("/v1/groups/" + id);
  }
  /**
   * Get group permissions
   *
   * @returns {Promise}
   */
  getGroupPermissions(id) {
    return api.get("/v1/groups-permissions/" + id);
  }
}
