<template>
  <div>
    <header-title :title="title" />
    <div class="row justify-content-center">
      <div class="col-12 mb-5">
        <div class="card border-white rounded py-4">
          <div class="row mx-5 my-2">
            <div class="col">
              <h6>{{ $t("groupsViewPage.permissions") }}</h6>
              <div class="list-group" style="width: 50%">
                <router-link
                  v-for="permission in group.permissions"
                  :key="permission.id"
                  class="my-1 mx-2"
                  :to="`/users_management/permissions/` + permission.id"
                >
                  <span class="bg-secondary text-white px-2 py-1 float-left">{{
                    getField(permission.name)
                  }}</span>
                  <span class="bg-white px-2 py-1 float-left">{{
                    getValue(permission.name)
                  }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import GroupService from "../../../services/GroupService";
import HeaderTitle from "@/components/HeaderTitle.vue";

const groupService = new GroupService();

export default {
  name: "GroupsView",

  components: {
    HeaderTitle,
  },
  data: () => {
    return {
      group: {},
    };
  },
  computed: {
    title() {
      return this.$t("groupsViewPage.title") + this.$route.params.id;
    },
  },
  beforeMount() {
    this.reloadGroup();
  },
  methods: {
    reloadGroup() {
      groupService
        .getGroupPermissions(this.$route.params.id)
        .then((response) => {
          this.group = response.data.data;
        });
    },
    getField(str) {
      const arr = str.split(":");
      return arr[0];
    },
    getValue(str) {
      const arr = str.split(":");
      return arr[1];
    },
  },
};
</script>
