import { app } from "@/main";
function handleClick(el) {
  navigator.clipboard.writeText(el.dataset.value);

  app.$toast.show({
    message: ["Copied"],
    duration: 2000,
    type: "success",
  });
}

export const copyClipboard = {
  bind(el, binding) {
    el.dataset.value = binding.value;
    el.handler = function () {
      handleClick(el);
    };

    el.addEventListener("click", el.handler);
  },
  update(el, binding) {
    el.dataset.value = binding.value;
    el.removeEventListener("click", el.handler);
    el.addEventListener("click", el.handler);
  },
  unbind(el) {
    el.removeEventListener("click", el.handler);
  },
};
