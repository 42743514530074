<template>
  <div>
    <ModalHeader>
      <div>
        <ModalTitle v-if="!rule?.id" class="m-0">
          {{ $t("trackingRuleNotificationsPage.createRule") }}
        </ModalTitle>
        <div v-else>
          <div class="d-flex gap-2">
            <ModalTitle class="m-0">
              {{ $t("trackingRuleNotificationsPage.updateRule") }}
            </ModalTitle>
            <NbBadge
              v-if="rule"
              :text="rule.active ? $t('active') : $t('inactive')"
              :type="rule.active ? 'success' : 'warning'"
            >
            </NbBadge>
          </div>
          <small class="text-gray">{{ rule.name }}</small>
        </div>
      </div>
    </ModalHeader>

    <Stepper v-model="stepIndex">
      <StepperItem
        v-for="step in steps"
        :key="step.step"
        :step="step.step"
        v-slot="{ state }"
      >
        <StepperSeparator
          v-if="step.step !== steps[steps.length - 1].step"
          :completed="state === 'completed'"
        />
      </StepperItem>
    </Stepper>

    <div class="m-4">
      <Spinner v-if="loading" size="lg" class="mx-auto" />
      <keep-alive v-show="!loading">
        <component
          :is="currentStep.component"
          @next="setNextStep"
          @back="setPreviousStep"
          @set-step="stepIndex = $event"
        />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import StepperItem from "@/components/stepper/StepperItem.vue";
import Stepper from "@/components/stepper/Stepper.vue";
import StepperSeparator from "@/components/stepper/StepperSeparator.vue";
import RuleNameStep from "./steps/RuleNameStep.vue";
import ApplyRuleToStep from "./steps/ApplyRuleToStep.vue";
import CreateOrUseExistingRuleStep from "./steps/CreateOrUseExistingRuleStep.vue";
import CheckpointStep from "./steps/CheckpointStep.vue";
import SelectSellersStep from "./steps/SelectSellersStep.vue";
import SelectContractsStep from "./steps/SelectContractsStep.vue";
import CreateRuleStep from "./steps/CreateRuleStep.vue";
import UpdateRuleStep from "./steps/UpdateRuleStep.vue";
import Spinner from "@/components/Spinner.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import { mapState } from "vuex";
import TrackingRuleNotificationsService from "@/services/TrackingRuleNotificationsService";

const trackingRuleNotificationsService = new TrackingRuleNotificationsService();

const firstSteps = [
  {
    step: 1,
    component: "RuleNameStep",
  },
  {
    step: 2,
    component: "ApplyRuleToStep",
  },
];

export default {
  components: {
    ModalHeader,
    ModalTitle,
    Stepper,
    StepperItem,
    StepperSeparator,
    RuleNameStep,
    ApplyRuleToStep,
    CreateOrUseExistingRuleStep,
    CheckpointStep,
    SelectSellersStep,
    SelectContractsStep,
    CreateRuleStep,
    UpdateRuleStep,
    Spinner,
    NbBadge,
  },
  props: {
    rule: {
      type: [Object, undefined],
      default: undefined,
    },
  },
  inject: ["context"],
  data() {
    return {
      stepIndex: 1,
      steps: [...firstSteps],
      loading: false,
    };
  },
  provide() {
    const ruleContext = {
      success: () => {
        this.context?.close();
        this.$emit("success");
      },
    };

    Object.defineProperty(ruleContext, "ruleId", {
      enumerable: false,
      get: () => this.rule?.id,
    });

    return {
      ruleContext,
    };
  },
  computed: {
    ...mapState({
      applyRuleTo: (state) => state.notificationRuleSettings.applyRuleTo,
    }),
    currentStep() {
      const step = this.steps.find((step) => step.step === this.stepIndex);
      return step;
    },
    isAllShipments() {
      return this.applyRuleTo === "all_shipments";
    },
    isUpdateRule() {
      return this.rule?.id;
    },
  },
  methods: {
    addNewSteps() {
      const SELECT_SELLERS_STEP = "SelectSellersStep";
      const SELECT_CONTRACTS_STEP = "SelectContractsStep";
      const CREATE_OR_USE_EXISTING_RULE_STEP = "CreateOrUseExistingRuleStep";
      const CHECKPOINT_STEP = "CheckpointStep";

      const CREATE_OR_UPDATE_RULE_STEP = this.isUpdateRule
        ? "UpdateRuleStep"
        : "CreateRuleStep";

      const allShipmentsSteps = [
        CREATE_OR_USE_EXISTING_RULE_STEP,
        CHECKPOINT_STEP,
        CREATE_OR_UPDATE_RULE_STEP,
      ];

      const someShipmentsSteps = [
        SELECT_SELLERS_STEP,
        SELECT_CONTRACTS_STEP,
        CREATE_OR_USE_EXISTING_RULE_STEP,
        CHECKPOINT_STEP,
        CREATE_OR_UPDATE_RULE_STEP,
      ];

      let allSteps = this.isAllShipments
        ? allShipmentsSteps
        : someShipmentsSteps;

      if (this.isUpdateRule) {
        allSteps = allSteps.filter(
          (step) => step !== CREATE_OR_USE_EXISTING_RULE_STEP,
        );
      }

      const nextSteps = allSteps.map((component, index) => ({
        step: this.stepIndex + (index + 1),
        component,
      }));

      this.steps = [...firstSteps, ...nextSteps];
    },
    setNextStep() {
      const currentStepIndex = this.currentStep.step;

      if (currentStepIndex === 2) {
        this.addNewSteps();
      }

      this.stepIndex = currentStepIndex + 1;
    },
    setPreviousStep() {
      const currentStepIndex = this.currentStep.step;

      this.stepIndex = currentStepIndex - 1;
    },
    setRule(data) {
      this.$store.dispatch("notificationRuleSettings/setRule", data);
    },
    async loadRule() {
      try {
        this.loading = true;
        const { data } = await trackingRuleNotificationsService.getById(
          this.rule.id,
        );

        this.setRule(data.data);
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    this.$store.dispatch("notificationRuleSettings/reset");
    if (this.rule?.id) {
      this.loadRule();
    }
  },
};
</script>

<style lang="scss" scoped></style>
