<template>
  <div v-if="hasLimit">
    <b-pagination
      v-model="currentPage"
      aria-controls="my-table"
      align="center"
      size="sm"
      :total-rows="totalRows"
      :per-page="limit"
      :first-text="$t('components.pagination.first')"
      :prev-text="$t('components.pagination.prev')"
      :next-text="$t('components.pagination.next')"
      :last-text="$t('components.pagination.last')"
      first-number
      last-number
    ></b-pagination>
  </div>
</template>

<script>
import { BPagination } from "bootstrap-vue";

export default {
  name: "PaginationCustom",
  components: {
    "b-pagination": BPagination,
  },
  props: {
    namespace: {
      type: String,
      required: true,
    },
    hasLimit: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    currentItems() {
      let end = this.currentPage * this.limit;
      let begin = end - (this.limit - 1);
      return `${begin} - ${end}`;
    },
    totalRows() {
      return this.$store.state[this.namespace].totalRows;
    },
    limit() {
      return this.$store.state[this.namespace].limit;
    },
    currentPage: {
      get() {
        return this.$store.state[this.namespace].currentPage;
      },
      set(value) {
        this.$store.commit(this.namespace + "/SET_CURRENT_PAGE", value);
      },
    },
  },
};
</script>
