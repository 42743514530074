<template>
  <div>
    <Datatable :data="items" :columns="columns">
      <template #name="{ row }">
        <span
          class="item-name truncate d-block max-w-10"
          v-b-tooltip.hover
          :title="row.name"
        >
          {{ row.name }}
        </span>
      </template>

      <template #origin_country="{ row }">
        <img :src="$helpers.getContryFlagURL(row.origin_country)" alt="" />
        {{
          $helpers.getCountryNameByCode(
            row.origin_country,
            $i18n.locale.replace("_", "-"),
          )
        }}
      </template>

      <template #value="{ row }"> {{ currency }} {{ row.value }} </template>
      <template #actions="{ row }">
        <div class="d-flex gap-2">
          <NbButton
            variant="quaternary"
            v-b-tooltip.bottom.hover
            :title="$t('ordersViewPage.seeDimensions')"
            @click="showDimensionsModal(row)"
          >
            <NbIcon icon="package" />
          </NbButton>
          <NbButton
            variant="quaternary"
            v-b-tooltip.bottom.hover
            :title="$t('ordersViewPage.seeCosts')"
            @click="showCostsModal(row)"
          >
            <NbIcon icon="dollar-sign" />
          </NbButton>
          <NbButton
            variant="quaternary"
            v-b-tooltip.bottom.hover
            :title="$t('ordersViewPage.seeFullDescription')"
            @click="showFullDescriptionModal(row)"
          >
            <NbIcon icon="info" />
          </NbButton>
          <NbButton
            variant="quaternary"
            v-b-tooltip.bottom.hover
            :title="$t('ordersViewPage.seeVolume')"
            @click="navigateTo(`/shipping/volumes?id=${row.volume_id}`)"
          >
            <NbIcon icon="database" />
          </NbButton>
        </div>
      </template>
    </Datatable>
    <Modal v-model="isDimensionsModalOpen">
      <ModalHeader>
        <ModalTitle>{{ $t("ordersViewPage.dimensions") }}</ModalTitle>
      </ModalHeader>

      <BoxDimensions
        :height="selectedItem.height"
        :width="selectedItem.width"
        :depth="selectedItem.length"
        :weight="selectedItem.weight"
        :description="$t('ordersViewPage.informedBySeller')"
      />

      <ModalFooter class="d-flex justify-content-end">
        <ModalClose />
      </ModalFooter>
    </Modal>

    <Modal v-model="isCostsModalOpen">
      <ModalHeader>
        <ModalTitle>{{ $t("ordersViewPage.costs") }}</ModalTitle>
      </ModalHeader>

      <div class="costs grid grid-cols-3 gap-4">
        <div class="info">
          <small>Unit value</small>
          <span>
            {{ currency }} {{ selectedItem?.unit_products_value || "-" }}
          </span>
        </div>
        <div class="info">
          <small>Unit tax value</small>
          <span>{{ currency }} {{ selectedItem?.unit_tax_value || "-" }}</span>
        </div>
        <div class="info">
          <small>Unit freight value</small>
          <span>
            {{ currency }} {{ selectedItem?.unit_freight_value || "-" }}
          </span>
        </div>
      </div>

      <ModalFooter class="d-flex justify-content-end">
        <ModalClose />
      </ModalFooter>
    </Modal>

    <Modal v-model="isFullDescriptionModalOpen">
      <ModalHeader>
        <ModalTitle>{{ $t("ordersViewPage.itemDescription") }}</ModalTitle>
      </ModalHeader>
      <p class="body-04">{{ selectedItem.description }}</p>
      <ModalFooter class="d-flex justify-content-end">
        <ModalClose />
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import Datatable from "@/components/datatable/Datatable.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import BoxDimensions from "../../../../../components/BoxDimensions.vue";

export default {
  props: {
    volumes: {
      type: Array,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  components: {
    Datatable,
    NbButton,
    NbIcon,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    BoxDimensions,
  },
  data() {
    return {
      isDimensionsModalOpen: false,
      isCostsModalOpen: false,
      isFullDescriptionModalOpen: false,
      selectedItem: {},
    };
  },
  computed: {
    items() {
      const orderItems = this.volumes.map((volume) => volume.order_items);
      return orderItems?.flat() || [];
    },
    columns() {
      return [
        {
          label: this.$t("sku"),
          key: "sku",
        },
        {
          label: this.$t("name"),
          key: "name",
        },
        {
          label: this.$t("quantity"),
          key: "quantity",
        },
        {
          label: this.$t("volumesPage.fields.hSCode"),
          key: "hs_code",
        },
        {
          label: this.$t("country"),
          key: "origin_country",
        },
        {
          label: this.$t("value"),
          key: "value",
        },
        {
          label: "",
          key: "actions",
        },
      ];
    },
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
    showDimensionsModal(item) {
      this.selectedItem = item;
      this.isDimensionsModalOpen = true;
    },
    showCostsModal(item) {
      this.selectedItem = item;
      this.isCostsModalOpen = true;
    },
    showFullDescriptionModal(item) {
      this.selectedItem = item;
      this.isFullDescriptionModalOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.costs {
  .info {
    display: flex;
    flex-direction: column;
    small {
      font-weight: 700;
      font-size: 0.75rem;
    }
    span {
      font-size: 0.875rem;
      line-height: 1.2;
    }
  }
}
.truncate.item-name {
  max-width: 10rem;
}
</style>
