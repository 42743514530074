<template>
  <NbBadge :type="variant" :text="normalizedData" />
</template>

<script>
import NbBadge from "@/components/alerts/NbBadge.vue";
export default {
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  components: {
    NbBadge,
  },
  inject: ["alertLogContext"],
  computed: {
    variant() {
      const { alertLogTypes } = this.alertLogContext;

      const types = alertLogTypes.reduce((acc, current) => {
        acc[current] = current;
        return acc;
      }, {});

      const variants = {
        [types.normal_type]: "success",
        [types.payment_for_the_seller]: "danger",
        [types.received_checkpoint]: "attention",
        [types.time_between_checkpoints]: "default",
        [types.duties_payment]: "attention",
      };

      return variants?.[this.data] || "success";
    },
    normalizedData() {
      return this.data
        .split("_")
        .map((item) => item?.charAt(0)?.toUpperCase().concat(item.substring(1)))
        .join(" ");
    },
  },
};
</script>

<style scoped></style>
