import { render, staticRenderFns } from "./ExternalLogs.vue?vue&type=template&id=1ffb27e0"
import script from "./ExternalLogs.vue?vue&type=script&lang=js"
export * from "./ExternalLogs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports