var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nb-input",class:_vm.errorsFeedback ? `is-invalid ${_vm.variant}` : `${_vm.variant}`},[(_vm.name)?_c('label',{staticClass:"input-label",class:_vm.disabled ? 'not-allowed' : '',attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.configuredName)+" "),(_vm.helpText)?_c('NbHelpText',{staticClass:"mx-1",attrs:{"id":`${_vm.id}-popover`,"size":_vm.helpTextSize,"placement":"topright"}},[_vm._v(" "+_vm._s(_vm.helpText)+" "),(_vm.helpTextLink)?_c('a',{staticClass:"link-2",staticStyle:{"color":"white !important"},attrs:{"href":_vm.helpTextLink.href,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.helpTextLink.text)+" ")]):_vm._e()]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex input-wrapper",class:_vm.wrapperClasses,attrs:{"id":`${_vm.id}-input-wrapper`}},[(_vm.prepend)?_c('div',{staticClass:"fake-input pointer prepend",class:`
        ${_vm.textContent && !_vm.disabled ? 'text-black ' : ''}
        ${_vm.errorsFeedback ? 'is-invalid ' : ''}
        ${_vm.disabled ? 'disabled' : ''}
      `},[_vm._v(" "+_vm._s(_vm.prepend)+" ")]):_vm._e(),_c('div',{staticClass:"nb form-control input",class:`
        ${_vm.textarea ? 'text-area' : ''}
        ${
          _vm.errorsFeedback
            ? _vm.disabled
              ? 'is-invalid not-allowed'
              : 'is-invalid'
            : _vm.disabled
              ? 'not-allowed'
              : ''
        }`,attrs:{"id":_vm.id,"disabled":_vm.disabled}},[_vm._t("default",function(){return [_c('span',{class:_vm.configuredText != _vm.placeholder ? '' : 'text-gray-60'},[_vm._v(" "+_vm._s(_vm.configuredText)+" ")])]})],2),(_vm.append)?_c('div',{staticClass:"fake-input pointer append",class:` 
        ${_vm.textContent ? 'text-black ' : ''}
        ${_vm.errorsFeedback ? 'is-invalid' : ''}
      `},[_vm._v(" "+_vm._s(_vm.append)+" ")]):_vm._e()]),_c('ErrorFeedback',{attrs:{"error":_vm.errorsFeedback}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }