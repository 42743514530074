<template>
  <div>
    <div v-for="(item, index) in result" class="hscode-card" :key="index">
      <div class="info">
        <span class="label">HS Code</span>
        <strong>{{ item.hscode }}</strong>
      </div>
      <div class="info">
        <span class="label">{{ $t("searchHsCodePage.category") }}</span>
        <strong>{{ item.category || "---" }}</strong>
      </div>

      <div class="result">
        <IsCheckedIcon :checked="item.success" size="32" />
        <small>{{ item?.success ? $t("allowed") : $t("invalid") }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import IsCheckedIcon from "./IsCheckedIcon.vue";
export default {
  props: {
    result: {
      type: Array,
      required: true,
    },
  },
  components: {
    IsCheckedIcon,
  },
};
</script>

<style lang="scss" scoped>
.hscode-card {
  background-color: var(--gray-05);
  border: 1px solid var(--gray-10);
  padding: 1.5rem;
  width: 100%;
  border-radius: 4px;
  display: flex;
  gap: 2rem;
  & + .hscode-card {
    margin-top: 0.5rem;
  }
  .info {
    strong {
      display: block;
    }
    &:nth-child(2) {
      flex-grow: 1;
    }
  }
  .result {
    display: flex;
    align-items: center;
    flex-direction: column;
    .icon {
      color: var(--success);
      &.invalid {
        color: var(--error);
      }
    }
  }
}
</style>
