import PassThrough from "../../components/PassThrough";
import Contracts from "../../views/contracts/contracts/Contracts";
import ContractsView from "../../views/contracts/contracts/View";
import DeliveryMethods from "../../views/contracts/delivery_methods/DeliveryMethods";
import DeliveryMethodView from "../../views/contracts/delivery_methods/View";
import PriceTables from "../../views/contracts/price_tables/PriceTables";
import Carriers from "../../views/contracts/carriers/Carriers.vue";
import CarrierView from "../../views/contracts/carriers/View";

const contracts_contracts = {
  path: "/contracts/contracts",
  name: "contracts_contracts",
  component: Contracts,
  meta: {
    visible: true,
    label: "Contracts",
    searchData: "contracts",
  },
};

const contracts_contracts_view = {
  path: "/contracts/contracts/:id",
  name: "contracts_view",
  component: ContractsView,
  meta: {
    visible: false,
    label: "Contracts view",
  },
};

const contracts_delivery_methods = {
  path: "/contracts/delivery_methods",
  name: "contracts_delivery_methods",
  component: DeliveryMethods,
  meta: {
    visible: true,
    label: "Deliverymethods",
    searchData: "deliveryMethods",
  },
};

const contracts_delivery_method_view = {
  path: "/contracts/delivery_methods/:id",
  name: "contracts_delivery_method_view",
  component: DeliveryMethodView,
  meta: {
    visible: false,
    label: "DeliveryMethod view",
  },
};

/* 
const delivery_time = {
  path: '/contracts/price_tables',
  name: 'contracts_delivery_time',
  component: PriceTables,
  meta: {
    visible: true,
  }
} 
*/

const prices_table = {
  path: "/contracts/price_tables",
  name: "contracts_prices_table",
  component: PriceTables,
  meta: {
    visible: true,
    label: "PriceTablesDeliveryTime",
    searchData: "priceTableAndDeliveryTime",
  },
};

const contracts_carriers = {
  path: "/contracts/carriers",
  name: "contracts_carriers",
  component: Carriers,
  meta: {
    visible: true,
    label: "Carriers",
    searchData: "carriers",
  },
};

const contracts_carrier_view = {
  path: "/contracts/carriers/:id",
  name: "contracts_carrier_view",
  component: CarrierView,
  meta: {
    visible: false,
    label: "Carrier view",
  },
};

const contracts_children = [
  contracts_contracts,
  contracts_carriers,
  contracts_carrier_view,
  contracts_contracts_view,
  contracts_delivery_methods,
  contracts_delivery_method_view,
  prices_table,
];

const contracts = {
  path: "/contracts",
  name: "contracts",
  component: PassThrough,
  meta: {
    visible: true,
    label: "Contracts",
    icon: "truck",
  },
  children: contracts_children,
};

export default contracts;
