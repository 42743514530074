import api from "./HttpService";

export default class BoxService {
  getBoxes() {
    return api.get("/v1/boxes");
  }

  getBoxesFromSellers(param = null) {
    return api.get(
      param ? `/v1/shipping_boxes?seller_ids=${param}` : "/v1/shipping_boxes",
    );
  }

  getBox(id) {
    return api.get("/v1/boxes/" + id);
  }

  updateBox(id, data) {
    return api.put("/v1/boxes/" + id, data);
  }

  removeBox(id) {
    return api.delete("/v1/boxes/" + id);
  }

  createBox(data) {
    return api.post("/v1/boxes", data);
  }
}
