var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-gray-05 p-2 my-1 radius grab"},[_c('NbIcon',{class:`${_vm.prependClass}`,attrs:{"icon":_vm.prependIcon,"attributes":{
      width: '1.2rem',
      height: '1.2rem',
    }}}),_c('span',{staticClass:"body-3"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('NbIcon',{class:`${_vm.postpendClass} mr-1 float-right pointer`,attrs:{"icon":_vm.postpendIcon,"attributes":{
      width: '1.2rem',
      height: '1.2rem',
    }},on:{"click":function($event){return _vm.$emit('toggle')}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }