<template>
  <div class="preview">
    <div
      v-for="file in files"
      :key="file.name"
      :class="['preview-card', { multiple: files.length > 1 }]"
    >
      <div class="file-info">
        <small>{{ file.name }}</small>
        <small>{{ formatBytes(file.size) }}</small>
      </div>
      <button
        class="btn ml-2"
        type="button"
        @click.stop="onRemove(files.indexOf(file))"
        title="Remove file"
      >
        <NbIcon
          icon="x"
          :attributes="{
            'stroke-width': 3,
            width: '1rem',
            height: '1rem',
          }"
        />
      </button>
    </div>
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  name: "PreviewFiles",
  components: { NbIcon },
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onRemove(index) {
      this.$emit("remove", index);
    },
    formatBytes(bytes) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes === 0) return "0 Byte";
      const sizeIndex = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return (
        Math.round(bytes / Math.pow(1024, sizeIndex), 2) +
        " " +
        sizes[sizeIndex]
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1rem;
  &-card {
    border: 1px dashed var(--gray-40);
    padding: 0.475rem 1rem;
    position: relative;
    border-radius: 4px;
    width: 100%;
    &.multiple {
      width: 100px;
    }
    .file-info {
      small {
        display: block;
        white-space: nowrap;
        &:first-child {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    button {
      position: absolute;
      top: 0;
      right: 4px;
      padding: 0;
    }
  }
}
</style>
