<template>
  <aside
    :class="['sidebar', { expanded }]"
    @mouseover="expanded = true"
    @mouseleave="expanded = false"
  >
    <div class="top-sidebar">
      <SidebarLogo v-show="expanded" class="mb-2" />
      <button v-show="!expanded" class="btn btn-primary">
        <NbIcon icon="sidebar" :attributes="{ height: 32, width: 32 }" />
      </button>
    </div>
    <nav>
      <ul>
        <li v-for="(route, index) in visibleRoutes" :key="index">
          <NavItem :route="route" :expanded="expanded" />
        </li>
      </ul>
      <MenuSpecialLinks :expanded="expanded" />
    </nav>
    <div
      v-show="expanded"
      :class="[
        'powered-by  justify-content-center mt-4 text-center w-100',
        { expanded },
      ]"
    >
      Powered by <strong>Nobordist</strong>
    </div>
  </aside>
</template>

<script>
import NavItem from "./NavItem.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import SidebarLogo from "./SidebarLogo.vue";
import MenuSpecialLinks from "./MenuSpecialLinks.vue";

export default {
  components: {
    NavItem,
    SidebarLogo,
    NbIcon,
    MenuSpecialLinks,
  },
  data() {
    return {
      expanded: false,
    };
  },
  watch: {
    $route() {
      this.expanded = false;
    },
  },
  computed: {
    visibleRoutes() {
      return this.$router.options.routes?.filter((item) => item.meta.visible);
    },
  },
};
</script>

<style lang="scss" scoped>
$width: 20rem;

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
  width: $width;
}
.sidebar {
  background-color: var(--primary);
  width: 64px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 10;
  transition: width 0.3s;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  &.expanded {
    width: $width;
  }
  .top-sidebar {
    height: 116px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 100%;
      font-size: 24px;
    }
  }
}

nav {
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  ul {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  li {
    button,
    a {
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      border: none;
      background-color: transparent;
      padding: 0;
      text-decoration: none;
      color: var(--white);
      & > div {
        min-width: 64px;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.powered-by {
  background-color: var(--bg-side-colapse);
  bottom: 0;
  width: 100%;
  overflow: hidden;
  padding: 0.5rem 1.5rem;
  min-width: $width;
  color: var(--gray-20);
  opacity: 0;
  transition: opacity 1.5s forward;
  &.expanded {
    opacity: 1;
  }
}
</style>
