<template>
  <div>
    <ModalHeader>
      <ModalTitle>Downloads</ModalTitle>
    </ModalHeader>

    <p>
      {{ $t("ordersPage.checkDownloadsAt") }}
    </p>

    <div>
      <strong class="text-danger" v-for="(error, index) in errors" :key="index">
        {{ error }}
      </strong>
    </div>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
      <NbButton class="ml-2" :disabled="loading" @click="downloadOrders">
        {{ $t("download") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import DownloadsService from "@/services/DownloadsService";

const downloadsService = new DownloadsService();

export default {
  components: {
    ModalFooter,
    ModalHeader,
    ModalTitle,
    NbButton,
    ModalClose,
  },
  props: {
    orderIds: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: "orders",
    },
  },
  data() {
    return {
      loading: false,
      errors: [],
    };
  },
  methods: {
    async downloadOrders() {
      try {
        this.errors = [];
        this.loading = true;
        await downloadsService.loadProcessing({
          data_type: this.type,
          reference_ids: this.orderIds,
        });
        this.$router.push("/archives");
      } catch (error) {
        if (error.response.data?.messages?.length) {
          this.errors = error?.response?.data?.messages;
          return;
        }

        this.$bvToast.toast(this.$t("errorMessages.genericError"), {
          variant: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
