import DownloadsService from "@/services/DownloadsService";

const downloadsService = new DownloadsService();

export default {
  async fetchItems({ commit }) {
    const res = await downloadsService.getDownloads();
    const items = res.data.data;
    const totalRows = res.data.data.length;
    commit("SET_ITEMS", items);
    commit("SET_TOTAL_ROWS", totalRows);
  },
};
