<template>
  <div class="grid grid-cols-4 gap-4">
    <div>
      <label class="input-label">
        {{ $t("ordersPage.fields.orderNumber") }}
        <NbHelpText
          id="order-number-tooltip"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("ordersViewPage.orderNumberDescription") }}
        </NbHelpText>
      </label>
      <div class="input-view d-flex justify-content-between align-items-center">
        <span>{{ order.order_number || "-" }}</span>
        <NbButton
          v-if="order.order_number"
          v-copy="order.order_number"
          v-b-tooltip.left.hover
          :title="$t('ordersViewPage.copyReference')"
          variant="tertiary"
        >
          <NbIcon icon="copy" />
        </NbButton>
      </div>
    </div>

    <div>
      <label class="input-label">
        {{ $t("ordersPage.fields.salesChannelOrderNumber") }}
        <NbHelpText
          id="sales-channel-order-number-tooltip"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("ordersViewPage.salesChannelOrderNumberDescription") }}
        </NbHelpText>
      </label>
      <div class="input-view d-flex justify-content-between align-items-center">
        <span>{{ order.sales_channel_order_number || "-" }}</span>
        <NbButton
          v-if="order?.sales_channel_order_number"
          v-copy="order.sales_channel_order_number"
          v-b-tooltip.left.hover
          :title="$t('ordersViewPage.copyReference')"
          variant="tertiary"
          placeholder="-"
        >
          <NbIcon icon="copy" />
        </NbButton>
      </div>
    </div>

    <div>
      <label class="input-label">
        {{ $t("ordersPage.fields.orderReference") }}
        <NbHelpText
          id="order-reference-tooltip"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("ordersViewPage.orderReferenceDescription") }}
        </NbHelpText>
      </label>
      <div class="input-view d-flex justify-content-between align-items-center">
        <span>{{ order.reference || "-" }}</span>
        <NbButton
          v-if="order?.reference"
          v-copy="order.reference"
          v-b-tooltip.left.hover
          :title="$t('ordersViewPage.copyReference')"
          variant="tertiary"
        >
          <NbIcon icon="copy" />
        </NbButton>
      </div>
    </div>

    <div>
      <label class="input-label">
        {{ $t("ordersViewPage.internalNBID") }}
        <NbHelpText
          id="internal-nb-id-tooltip"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("ordersViewPage.internalNBIDDescription") }}
        </NbHelpText>
      </label>
      <div class="input-view d-flex justify-content-between align-items-center">
        <span>{{ order.id }}</span>
        <NbButton
          v-copy="order.id"
          v-b-tooltip.left.hover
          :title="$t('ordersViewPage.copyReference')"
          variant="tertiary"
        >
          <NbIcon icon="copy" />
        </NbButton>
      </div>
    </div>
  </div>
</template>

<script>
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import { copyClipboard } from "@/directives/copy-clipboard.js";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    NbHelpText,
    NbButton,
    NbIcon,
  },
  directives: {
    copy: copyClipboard,
  },
};
</script>

<style lang="scss" scoped></style>
