<template>
  <div size="lg">
    <ModalHeader>
      <div>
        <ModalTitle>
          {{ $t("notificationsPage.notification") }}
        </ModalTitle>
        <p class="body-4">
          {{ $t("notificationsPage.seeSendedNotification") }}
        </p>
      </div>
    </ModalHeader>

    <div>
      <NbCard id="general-data" :title="$t('notificationsPage.message')">
        <template #body>
          <div class="d-flex alin-items-center my-2">
            <strong class="mr-2"
              >{{ $t("notificationsPage.fields.status") }}:</strong
            >
            <NbBadge
              :text="
                notification?.is_new
                  ? $t('new')
                  : $t('notificationsPage.readed')
              "
              :type="!notification?.is_new ? 'success' : 'attention-dark'"
            />
          </div>
          <form class="mb-2">
            <div class="group">
              <NbTextInput
                variant="borderless-gray-10"
                id="to"
                :name="$t('notificationsPage.type')"
                :placeholder="$t('notificationsPage.selectRecipientType')"
                v-model="formData.recipient"
                disabled
                required
              />
              <NbTextInput
                variant="borderless-gray-10"
                id="recipient-id"
                :name="$t('notificationsPage.recipient')"
                placeholder=""
                v-model="formData.recipientName"
                disabled
                required
              />
            </div>

            <NbTextInput
              variant="borderless-gray-10"
              :name="$t('notificationsPage.subject')"
              id="subject"
              placeholder=""
              v-model="formData.subject"
              disabled
              required
            />

            <NbTextInput
              variant="borderless-gray-10"
              :placeholder="$t('notFound')"
              id="message"
              v-model="formData.message"
              :name="$t('notificationsPage.message')"
              disabled
              required
            />
          </form>

          <MessageDetails
            v-if="notification?.id"
            :notificationId="notification.id"
            :pendingAction="pendingAction"
          />
        </template>
      </NbCard>

      <NbCard
        :title="$t('notificationsPage.respondApprovalRequest')"
        id="card-comment"
        class="mt-2"
        v-if="pendingAction.status == 'pending' || pendingAction.status == 0"
      >
        <template #body>
          <form>
            <NbTextInput
              :name="$t('notificationsPage.addCommentResponse')"
              v-model="pendingAction.data.comment"
              id="comment"
            />
          </form>
          <div class="d-flex justify-content-end mt-2">
            <NbButton @click="approveRequest()">
              {{ $t("notificationsPage.approve") }}
            </NbButton>

            <NbButton variant="secondary" @click="denyRequest()" class="ml-2">
              {{ $t("notificationsPage.deny") }}
            </NbButton>
          </div>
        </template>
      </NbCard>
    </div>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
    </ModalFooter>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import notificationMixin from "../mixins/notification-mixin";

import PendingActionService from "@/services/PendingActionService";
import MessageDetails from "./MessageDetails.vue";
const pendingActionService = new PendingActionService();

export default {
  name: "ModalSendNotification",
  mixins: [notificationMixin],
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbCard,
    NbButton,
    NbTextInput,
    NbBadge,
    MessageDetails,
  },
  props: {
    notificationType: {
      type: [String, undefined],
      default: "",
    },
    notificationData: {
      type: Object,
      required: true,
    },
  },
  inject: ["context"],
  data() {
    return {
      notification: {},
      formData: {
        recipientName: "",
        recipient: "",
        subject: "",
        message: "",
      },
      pendingAction: {
        id: null,
        status: null,
        data: {},
      },
      approverData: {
        name: "",
        id: null,
      },
    };
  },
  computed: {
    getSubjectDescription() {
      return this.userNotificationTitles[this.notification.title];
    },
  },
  methods: {
    approveRequest() {
      this.pendingAction.status = "approved";
      this.pendingAction.data["approver_id"] = this.approverData.id;
      this.pendingAction.data["approver_name"] = this.approverData.name;
      pendingActionService
        .updatePendingAction(this.pendingAction.id, this.pendingAction)
        .then(() => {
          this.$emit("reload");
          this.close();
        });
      this.clearPendingActionData();
    },
    denyRequest() {
      this.pendingAction.status = "denied";
      this.pendingAction.data["approver_id"] = this.approverData.id;
      this.pendingAction.data["approver_name"] = this.approverData.name;
      pendingActionService
        .updatePendingAction(this.pendingAction.id, this.pendingAction)
        .then(() => {
          this.$emit("reload");
          this.close();
        });
      this.clearPendingActionData();
    },
    clearPendingActionData() {
      this.pendingAction = {
        id: null,
        comment: null,
        request: null,
        approver_id: null,
        approver_name: null,
        status: null,
      };
    },
    respondRequest() {
      this.clearPendingActionData();
      if (this.notification.pending_action_id) {
        pendingActionService
          .getPendingAction(this.notification.pending_action_id)
          .then((response) => {
            this.pendingAction = response.data.data;
            this.pendingAction.data["request"] = this.notification.body;
            this.approverData.name = this.notification.name;
            this.approverData.id = this.notification.member_id;
          });
      }
    },
    fillForm() {
      this.respondRequest();
      this.formData = {
        recipientName: this.notification?.name || "",
        recipient:
          this.notification?.kind === "seller_notifications"
            ? this.$t("notificationsPage.seller")
            : this.$t("notificationsPage.user"),
        message: this.notification?.body || "",
        subject: this.getSubjectDescription,
      };
    },
    resetForm() {
      this.notification = {};
      this.formData = {
        recipientName: "",
        recipient: "",
        message: "",
        subject: "",
      };
    },
    open() {
      this.notification = this.notificationData;
      this.fillForm();
    },
    close() {
      this.context?.close();
    },
  },
  created() {
    this.open();
  },
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
</style>
