<template>
  <NbCard id="documents" :title="$t('documentsAndLabels')">
    <template #body>
      <div class="grid gap-2">
        <NbButton
          @click="generateOrderLabel"
          variant="secondary"
          class="align-items-center d-flex"
          :disabled="loadings.label"
        >
          <NbIcon icon="download" :size="18" class="mr-2" />
          {{ $t("ordersViewPage.lastMileLabel") }}
        </NbButton>
        <NbButton
          @click="generatePackingSlip"
          variant="secondary"
          class="align-items-center d-flex"
          :disabled="loadings.packingSlip"
        >
          <NbIcon icon="download" :size="18" class="mr-2" />
          {{ $t("ordersViewPage.packingSlip") }}
        </NbButton>
        <NbButton
          @click="generateFirstMile"
          variant="secondary"
          class="align-items-center d-flex"
          :disabled="loadings.firstMile"
        >
          <NbIcon icon="download" :size="18" class="mr-2" />
          {{ $t("ordersPage.fields.firstMileLabel") }}
        </NbButton>
        <NbButton
          @click="generateCommercialInvoice"
          :disabled="loadings.invoice"
          variant="secondary"
          class="align-items-center d-flex"
        >
          <NbIcon icon="download" :size="18" class="mr-2" />
          {{ $t("ordersViewPage.commercialInvoice") }}
        </NbButton>
      </div>
    </template>
  </NbCard>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import OrderLabelService from "@/services/OrderLabelService";
import PackingSlipService from "@/services/PackingSlipService";

const orderLabelService = new OrderLabelService();
const packingSlipService = new PackingSlipService();

export default {
  props: {
    orderId: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    NbCard,
    NbButton,
    NbIcon,
  },
  data() {
    return {
      loadings: {
        label: false,
        firstMile: false,
        packingSlip: false,
        invoice: false,
      },
    };
  },
  methods: {
    async generateOrderLabel() {
      try {
        this.loadings.label = true;
        const { data } = await orderLabelService.generateOrderLabel(
          this.orderId,
        );

        window.open(data?.data?.download_url);
      } finally {
        this.loadings.label = false;
      }
    },
    async generateFirstMile() {
      try {
        this.loadings.firstMile = true;
        const { data } = await orderLabelService.generateFirstMile(
          this.orderId,
        );

        window.open(data?.data?.download_url);
      } finally {
        this.loadings.firstMile = false;
      }
    },
    async generateCommercialInvoice(orderId, volumeId = {}) {
      try {
        this.loadings.invoice = true;
        const { data } = await orderLabelService.generateCommercialInvoiceLabel(
          this.orderId,
          volumeId,
        );

        window.open(data?.data?.download_url);
      } finally {
        this.loadings.invoice = false;
      }
    },
    async generatePackingSlip() {
      try {
        this.loadings.packingSlip = true;
        const { data } = await packingSlipService.generate(this.orderId);

        window.open(data?.data?.download_url);
      } finally {
        this.loadings.packingSlip = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
