import { addTimeAndDate } from "../../shared/functions";
import GlobalService from "@/services/GlobalService";
const globalService = new GlobalService();

export default {
  async fetchItems({ state, commit }, namespace) {
    const res = await globalService.getTwentyItems(
      namespace,
      state.currentPage,
      state.limit,
      state.filters,
    );
    let items = new addTimeAndDate(res.data.data.elements);
    items = adjustData(items);
    const totalRows = res.data.data.count;
    commit("SET_ITEMS", items);
    commit("SET_TOTAL_ROWS", totalRows);
  },
};

function adjustData(payload) {
  payload.forEach((element, index) => {
    if (payload[index].log) {
      let logsString = JSON.stringify(payload[index].log);
      payload[index].log = logsString.slice(0, 50) + "...";
    }
  });
  return payload;
}
