import api from "./HttpService";

export default class PricesTable {
  getPriceTables() {
    return api.get("/v1/price_tables");
  }

  getOnePriceTable(id) {
    return api.get("/v1/price_tables/" + id);
  }

  setPriceTable(data = {}) {
    return api.post("/v1/price_tables", data);
  }

  updatePriceTable(id, data = {}) {
    return api.put("/v1/price_tables/" + id, data);
  }

  delete(id) {
    return api.delete(`/v1/price_tables/${id}`);
  }

  getNotMadeBySeller() {
    return api.get("/v1/pt_not_made_by_seller");
  }

  downloadPdf(id, contract_id = null) {
    if (contract_id) {
      return api.get(
        `/v1/price_tables/${id}/download_pdf?contract_id=${contract_id}`,
      );
    } else {
      return api.get(`/v1/price_tables/${id}/download_pdf`);
    }
  }

  downloadCsv(id) {
    return api.get(`/v1/price_tables/${id}/download_csv`);
  }
}
