<template>
  <div>
    <header-title :title="title" />
    <hr />
    <div class="row justify-content-center">
      <div class="col-12 mb-5">
        <h5 class="font-weight-light mb-4 text-uppercase"></h5>
        <br />
        <div class="card border-white rounded py-4">
          <div class="row mx-5 my-2">
            <field-view
              :field="$t('gGeneralEmailsViewPage.iD')"
              :val="generalEmail.id"
            />
            <field-view
              :field="$t('gGeneralEmailsViewPage.sent')"
              :val="generalEmail.sent"
            />
          </div>

          <div class="row mx-5 my-2">
            <field-view
              :field="$t('gGeneralEmailsViewPage.titleGeneral')"
              :val="generalEmail.title"
            />
          </div>

          <div class="row mx-5 my-2">
            <field-view
              :field="$t('gGeneralEmailsViewPage.destination')"
              :val="generalEmail.destination"
            />
          </div>

          <div class="row mx-5 my-2">
            <field-view
              :field="$t('gGeneralEmailsViewPage.body')"
              :val="generalEmail.body"
            />
          </div>

          <div class="row mx-5 mt-3 d-flex justify-content-center"></div>
        </div>
      </div>
      <!-- modals -->
      <div id="editGeneralEmail" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="settingsTitle" class="modal-title">
                {{ $t("edit") }} {{ generalEmail.title }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="title">{{
                      $t("gGeneralEmailsViewPage.titleGeneral")
                    }}</label>
                    <input
                      id="title"
                      v-model="generalEmail.title"
                      type="text"
                      :class="{ 'is-invalid': errors['title'] }"
                      class="form-control"
                      aria-describedby="nameHelp"
                      placeholder="Title .."
                    />
                    <div
                      v-for="(error, index) in errors['title']"
                      :key="index"
                      class="invalid-feedback"
                    >
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="destination">{{
                      $t("gGeneralEmailsViewPage.destination")
                    }}</label>
                    <input
                      id="destination"
                      v-model="generalEmail.destination"
                      type="text"
                      :class="{ 'is-invalid': errors['destination'] }"
                      class="form-control"
                      aria-describedby="nameHelp"
                      placeholder="Destination .."
                    />
                    <div
                      v-for="(error, index) in errors['destination']"
                      :key="index"
                      class="invalid-feedback"
                    >
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="body">{{
                      $t("gGeneralEmailsViewPage.body")
                    }}</label>
                    <input
                      id="body"
                      v-model="generalEmail.body"
                      type="text"
                      :class="{ 'is-invalid': errors['body'] }"
                      class="form-control"
                      aria-describedby="nameHelp"
                      placeholder="Body .."
                    />
                    <div
                      v-for="(error, index) in errors['body']"
                      :key="index"
                      class="invalid-feedback"
                    >
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a
                href="#"
                class="btn btn-primary float-right ml-3"
                @click="updateGeneralEmail()"
                >{{ $t("update") }}</a
              >
              <a
                id="editGeneralEmailClose"
                href="#"
                class="btn btn-secondary float-right ml-3"
                data-dismiss="modal"
                >{{ $t("close") }}</a
              >
            </div>
          </div>
        </div>
      </div>

      <div id="deleteGeneralEmail" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="settingsTitle" class="modal-title">
                {{ $t("gGeneralEmailsViewPage.deleteGeneralEmail") }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ $t("gGeneralEmailsViewPage.sureWantDelete") }}?
            </div>
            <div class="modal-footer">
              <a
                id="deleteGeneralEmailClose"
                class="btn btn-secondary float-right ml-3"
                data-dismiss="modal"
                >{{ $t("close") }}</a
              >
              <a
                class="btn btn-danger float-right ml-3"
                data-dismiss="modal"
                @click="deleteGeneralEmail()"
                >{{ $t("delete") }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- end modal -->
    </div>
  </div>
</template>

<script>
import GeneralEmailService from "../../../services/GeneralEmailService";
import NProgress from "nprogress";
import HeaderTitle from "@/components/HeaderTitle";
import FieldView from "@/components/FieldView";

const generalEmailService = new GeneralEmailService();

export default {
  name: "GeneralEmailView",

  components: {
    "field-view": FieldView,
    HeaderTitle,
  },
  data: () => {
    return {
      newGeneralEmail: {
        id: null,
        title: null,
        destination: null,
        body: null,
        sent: false,
      },
      generalEmail: {},
      errors: [],
    };
  },
  computed: {
    title() {
      return this.$t("gGeneralEmailsViewPage.title") + this.$route.params.id;
    },
  },
  beforeMount() {
    this.loadCurrentGeneralEmail();
  },
  methods: {
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date) {
      const data = new Date(date);
      const formatedDate =
        data.getFullYear() +
        "-" +
        this.addZero(data.getMonth() + 1).toString() +
        "-" +
        this.addZero(data.getDate().toString());
      return formatedDate;
    },
    loadCurrentGeneralEmail() {
      generalEmailService.getEmail(this.$route.params.id).then((response) => {
        this.generalEmail = response.data.data;
        let date = this.generalEmail.created_at;
        this.generalEmail.created = this.formateDate(date);
      });
    },
    copyGeneralEmail() {
      this.errors = [];
      this.newGeneralEmail = this.generalEmail;
    },
    updateGeneralEmail() {
      generalEmailService
        .updateGeneralEmail(this.$route.params.id, this.newGeneralEmail)
        .then(() => {
          document.getElementById("editGeneralEmailClose").click();
          //$('#editContract').modal('hide');
          this.loadCurrentGeneralEmail();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
          NProgress.done();
        });
    },
    deleteGeneralEmail() {
      generalEmailService.deleteGeneralEmail(this.$route.params.id).then(() => {
        document.getElementById("deleteGeneralEmailClose").click();
        this.$router.push("/logs/general_emails").then(() => {
          this.$bvToast.toast("General Email has been deleted", {
            title: "Success",
            toaster: "b-toaster-top-right",
            variant: "success",
            autoHideDelay: 10000,
            appendToast: true,
            solid: true,
          });
        });
      });
    },
  },
};
</script>
