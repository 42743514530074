<template>
  <div>
    <ModalHeader>
      <div>
        <ModalTitle>{{ $t("masterboxesPage.masterboxData") }}</ModalTitle>
        <p>{{ masterbox?.reference }}</p>
      </div>
    </ModalHeader>

    <div>
      <div class="grid grid-cols-3">
        <NbCard
          id="documents"
          :title="$t('masterboxesPage.documentsAndLabels')"
        >
          <template #body>
            <div class="grid">
              <NbButton
                variant="secondary"
                v-b-tooltip.hover
                :title="$t('clickToDownload')"
                @click="generateLabel"
                :disabled="isDownloading"
              >
                {{ $t("masterboxesPage.cN35Label") }}
              </NbButton>
            </div>
          </template>
        </NbCard>
        <NbCard id="actions" :title="$t('actions')">
          <template #body>
            <div class="grid">
              <NbButton
                v-if="masterbox?.shipment?.flight_id"
                variant="secondary"
                @click="
                  navigateTo(
                    `/shipping/flights?id=${masterbox.shipment.flight_id}`,
                  )
                "
              >
                {{ $t("masterboxesPage.goToFlight") }}
              </NbButton>
              <NbButton variant="secondary" @click="isDeleteModalOpen = true">
                {{ $t("delete") }}
              </NbButton>
            </div>
          </template>
        </NbCard>
      </div>

      <div class="cards my-4">
        <div class="d-flex flex-column">
          <label for="" class="input-label">{{
            $t("masterboxesPage.creationDate")
          }}</label>
          <small>{{ masterbox.created_at | dateFormat }}</small>
        </div>
        <NbCardBox>
          <div class="d-flex align-items-center justify-content-between">
            <h3 class="heading-3">
              {{ $t("masterboxesPage.volumes") }} ({{
                masterbox.volumes?.length
              }})
            </h3>
            <NbButton
              class="d-flex ml-auto"
              @click="downloadMasterboxVolumes"
              >{{ $t("download") }}</NbButton
            >
          </div>
          <NbTablev2
            class="mt-5"
            tableOf="volumesMasterbox"
            :hasParentItens="true"
            :hiddeTableOptions="true"
            height="fit-content"
            :allFields="volumesAllFields"
            :fields="volumesTableFields"
            @reloadFields="volumesTableFields = $event"
            :startItems="masterbox.volumes"
          >
            <template #cell(weight)="data">
              {{ standardizeValue(data.value) }}
            </template>
            <template #cell(cubed_weight)="data">
              {{ standardizeValue(data.value) }} kg
            </template>
            <template #cell(order_items)="data">
              {{ data.item.items_count }}
            </template>
            <template #cell(last_mile_carrier_number)="data">
              {{ data.item.last_mile_carrier_number }}
            </template>
            <template #cell(actions)="data">
              <NbButton
                @click="navigateTo(`/shipping/volumes?id=` + data.item.id)"
                variant="tertiary"
              >
                {{ $t("seeDetails") }} >
              </NbButton>
            </template>
          </NbTablev2>
        </NbCardBox>
      </div>

      <ModalFooter class="d-flex justify-content-end">
        <ModalClose />
      </ModalFooter>
    </div>

    <Modal v-model="isDeleteModalOpen">
      <ModalTitle>
        {{ $t("masterboxesPage.deleteMasterbox") }}
      </ModalTitle>
      <div>
        <p class="body-4">{{ deleteMessage }}</p>

        <NbButton v-if="requestId" v-copy="requestId">
          {{ $t("clickToCopy") }}
        </NbButton>
      </div>
      <ModalFooter>
        <NbButton
          v-if="requestId"
          class="d-flex ml-auto"
          variant="secondary"
          @click="backToMasterboxes"
        >
          {{ $t("close") }}
        </NbButton>
        <div v-else class="d-flex justify-content-between">
          <NbButton variant="secondary" @click="isDeleteModalOpen = false">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton :disabled="loading" @click="deleteMasterbox">
            {{ $t("delete") }}
          </NbButton>
        </div>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import { copyClipboard } from "@/directives/copy-clipboard";
import moment from "moment";
import MasterboxService from "../../../../services/MasterBoxService";
import NbCardBox from "@/components/cards/NbCardBox.vue";
import Modal from "@/components/modal/Modal.vue";
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";

const masterboxService = new MasterboxService();

export default {
  components: {
    NbCard,
    NbButton,
    NbTablev2,
    NbCardBox,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
  },
  directives: {
    copy: copyClipboard,
  },
  props: {
    masterboxId: {
      type: [Number, String],
      required: true,
    },
  },
  filters: {
    dateFormat(value) {
      return value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "";
    },
  },
  inject: ["context"],
  data() {
    return {
      loading: false,
      isDownloading: false,
      requestId: null,
      deleteMessage: this.$t("masterboxesPage.confirmDeleteMasterbox"),
      modalActive: true,
      masterbox: {
        dispatch_id: null,
        platform_id: null,
        reference: "",
        shipment_id: null,
        shipment_sequence_number: null,
        volumes: [],
        overpacks: [],
        created_at: "",
      },
      volumesTableFields: [],
      isDeleteModalOpen: false,
    };
  },
  computed: {
    volumesAllFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "weight",
          label: this.$t("masterboxesPage.weight"),
        },
        {
          key: "cubed_weight",
          label: this.$t("masterboxesPage.cubedWeight"),
        },
        {
          key: "last_mile_tracking_number",
          label: this.$t("masterboxesPage.lastMileTrackingNumber"),
        },
        {
          key: "last_mile_carrier_number",
          label: this.$t("masterboxesPage.lastMileCarrierNumber"),
        },
        {
          key: "order_items",
          label: this.$t("masterboxesPage.totalItems"),
        },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ];
    },
  },
  methods: {
    downloadMasterboxVolumes() {
      const data = {
        item_id: this.masterboxId,
        data_type: "masterbox_volumes",
      };
      masterboxService.downloadMasterboxVolumes(data).then(() => {
        this.$router.push("/archives");
      });
    },
    navigateTo(path) {
      this.$router.push(path);
    },
    standardizeValue(val) {
      const finalVal = parseFloat(val).toFixed(2);
      if (finalVal === "NaN") {
        return "0.00";
      }
      return finalVal;
    },
    async loadMasterbox() {
      try {
        this.modalActive = true;
        this.loading = true;
        const { data } = await masterboxService.getMasterBox(this.masterboxId);
        this.masterbox = data.data;
      } catch (error) {
        this.$bvToast.toast(this.$t("errorMessages.genericError"), {
          variant: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    async generateLabel() {
      try {
        this.isDownloading = true;
        const { data } = await masterboxService.generateMasterBoxLabel(
          this.masterboxId,
        );
        if (data?.data?.download_url) {
          window.open(data.data.download_url);
        }
      } finally {
        this.isDownloading = false;
      }
    },
    async deleteMasterbox() {
      try {
        this.loading = true;
        const { data } = await masterboxService.deleteMasterBox(
          this.masterbox.reference,
        );

        this.requestId = data.data.request_id;
        this.deleteMessage = data.messages[0].replace(
          "request_id",
          this.requestId,
        );
      } catch (error) {
        this.deleteMessage = this.$t("masterboxesPage.confirmDeleteMasterbox");
        this.requestId = null;
      } finally {
        this.loading = false;
      }
    },
    backToMasterboxes() {
      this.isDeleteModalOpen = false;
      this.$emit("refresh");
      this.context?.close();
    },
  },
  mounted() {
    this.$helpers.openModal("masterbox-modal");
    this.loadMasterbox();
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  gap: 1rem;
  &.grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  &.grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.cards {
  display: grid;
  gap: 1rem;
}
</style>
