<template>
  <div class="toast-list-wrapper">
    <transition-group name="list" tag="div" class="toast-list">
      <div
        v-for="item in items"
        v-bind:key="item.id"
        class="toast-item"
        :data-type="item.type"
      >
        <div :class="['message', { 'align-items-start': !!item.title }]">
          <div v-if="item.type" class="icon">
            <NbIcon
              :icon="getIconByType(item.type)"
              :size="12"
              class="icon-item"
            />
          </div>
          <div>
            <strong>{{ item.title }}</strong>
            <p v-for="(message, index) in item.messages" v-bind:key="index">
              {{ message }}
            </p>
            <div v-if="item.actions?.length" class="mt-2">
              <button
                v-for="action in item.actions"
                @click="action.action"
                :key="action.text"
                class="action"
              >
                {{ action.text }}
              </button>
            </div>
          </div>
        </div>
        <div>
          <button
            v-if="item.showCloseButton"
            @click="close(item.id)"
            class="close d-flex"
          >
            <NbIcon icon="x" :size="16" />
          </button>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import NbIcon from "../icons/NbIcon.vue";

export default {
  data() {
    return {
      id: 0,
      items: [],
    };
  },
  components: {
    NbIcon,
  },
  methods: {
    getIconByType(type) {
      const types = {
        success: "check",
        warning: "activity",
        danger: "x",
      };

      return types[type];
    },
    dispatchCloseToast(id, duration) {
      const idx = this.items.findIndex((item) => item.id === id);

      this.items[idx].timeout = setTimeout(() => {
        this.items.splice(
          this.items.findIndex((item) => item.id === id),
          1,
        );
      }, duration);
    },
    show(data) {
      const duration = data?.duration || 5000;
      const autoClose = data?.autoClose ?? true;
      const id = this.id++;
      this.items.splice(0, 0, {
        ...data,
        id,
        title: data?.title || "",
        messages: Array.isArray(data?.message)
          ? data.message
          : [data.message || ""],
        type: data?.type || "",
        duration,
        showCloseButton: data?.showCloseButton ?? true,
        autoClose,
        timeout: null,
      });

      if (autoClose) {
        this.dispatchCloseToast(id, duration);
      }
    },
    info(message = "", title = "") {
      this.show({ title, message });
    },
    success(message = "", title = "") {
      this.show({ title, message, type: "success" });
    },
    danger(message = "", title = "") {
      this.show({ title, message, type: "danger" });
    },
    warning(message = "", title = "") {
      this.show({ title, message, type: "warning" });
    },
    close(id) {
      const idx = this.items.findIndex((item) => item.id === id);
      if (idx !== -1) {
        clearTimeout(this.items[idx].timeout);
        this.items.splice(idx, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-list-wrapper {
  position: fixed;
  top: 3rem;
  right: 0;
  padding-right: 1rem;
  z-index: 1000000000000;
  list-style: none;
  gap: 1rem;
  transition: all 0.5s;

  .toast-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .toast-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    min-width: 22rem;
    color: #09090b;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1) /* #0000001a */,
      0 2px 4px -2px rgb(0 0 0 / 0.1) /* #0000001a */;
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
      0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    strong {
      font-size: 0.875rem;
    }
    p {
      font-size: 0.75rem;
      padding: 0;
      margin: 0;
    }

    .message {
      display: flex;
      gap: 0.5rem;
      align-items: flex-start;
      &:not(.align-items-start) {
        align-items: center;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem;
      border-radius: 8px;
    }

    &[data-type="success"] {
      .icon {
        background-color: #22c55e;

        .icon-item {
          border-radius: 50%;
          height: 1rem;
          width: 1rem;
          background-color: #dcfce7;
          color: #22c55e;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &[data-type="warning"] {
      .icon {
        background-color: #f97316;
        .icon-item {
          border-radius: 50%;
          height: 1rem;
          width: 1rem;
          background-color: #ffedd5;
          color: #f97316;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &[data-type="danger"] {
      .icon {
        background-color: #ef4444;
        .icon-item {
          border-radius: 50%;
          height: 1rem;
          width: 1rem;
          background-color: #fee2e2;
          color: #ef4444;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.toast-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter {
  opacity: 0;
  transform: translateX(30px);
}

.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

button.action {
  background-color: #18181b;
  color: #f2f2f2;
  border: none;
  border-radius: 4px;
  font-size: 0.75rem;
  padding: 0.2rem 1rem;
  transition: opacity 0.3s;
  &:hover {
    opacity: 80%;
  }
}
</style>
