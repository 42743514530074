import apiUntoasted from "./HttpServiceUntoasted";

export default class MetricsService {
  getFreightAverageCost({ startDate, endDate }) {
    return apiUntoasted.get("/v1/freight/average_cost", {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  getFlightCountClosed({ startDate, endDate }) {
    return apiUntoasted.get("/v1/flights/count_closed", {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  getOrdersAverageCost({ startDate, endDate }) {
    return apiUntoasted.get("/v1/orders/average_cost", {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  getOrdersCountCompleted({ startDate, endDate }) {
    return apiUntoasted.get("/v1/orders/count_completed", {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  getCreatedOrdersCount({ startDate, endDate }) {
    return apiUntoasted.get("/v1/orders/count_created", {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  getSellersRankingByDeliveredOrders({ startDate, endDate }) {
    return apiUntoasted.get("/v1/sellers/ranking", {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  getSellersRankingByCreatedOrders({ startDate, endDate }) {
    return apiUntoasted.get("/v1/sellers/ranking/created_orders", {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  }
}
