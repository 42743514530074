<template>
  <div>
    <ModalHeader>
      <ModalTitle>{{ seller?.name }}</ModalTitle>
    </ModalHeader>

    <Spinner v-if="loading" size="lg" class="mx-auto" />

    <div v-else class="grid gap-4">
      <div class="grid grid-cols-3 gap-4">
        <div class="py-4 seller-logo">
          <img
            v-if="seller.company_logo"
            :src="seller.logo_url"
            alt="Logo Company"
            v-image-fallback="'/logo-nobordist.png'"
          />

          <img v-else src="/logo-nobordist.png" alt="Logo Nobordist" />
        </div>
        <NbCard id="actions" :title="$t('actions')">
          <template #body>
            <div class="grid gap-2">
              <NbButton
                class="text-left"
                variant="secondary"
                @click="showEditModal = true"
              >
                <NbIcon icon="edit" size="18" />
                {{ $t("edit") }}
              </NbButton>
              <NbButton
                @click="downloadSellerSettings()"
                :disabled="sellerSettingsDownloading"
                variant="secondary"
                class="text-left"
                v-b-popover.hover.bottom
                :title="$t('sellersPage.downloadUserSettingsPopup')"
              >
                <NbIcon icon="download" size="18" />
                {{ $t("sellersViewPage.downloadSellerSettings") }}
              </NbButton>
            </div>
          </template>
        </NbCard>

        <NbCard id="suggested" :title="$t('suggestedActions')">
          <template #body>
            <div class="grid gap-2">
              <NbButton
                class="text-left"
                @click="goToWebsite"
                :disabled="!seller.site"
              >
                <NbIcon icon="external-link" size="18" />
                {{ $t("sellersViewPage.accessWebsite") }}
              </NbButton>
              <NbButton
                class="text-left"
                @click="
                  navigateTo(`/users_management/sellers/${seller.id}/saldo`)
                "
              >
                <NbIcon icon="external-link" size="18" />
                {{ $t("sellersViewPage.understandCurrentBalance") }}
              </NbButton>
              <NbButton
                @click="navigateTo(`/shipping/volumes?sellerId=${seller.id}`)"
                class="text-left"
              >
                <NbIcon icon="link" size="18" />
                {{ $t("sellersPage.seeSellerVolumes") }}
              </NbButton>

              <NbButton
                @click="navigateTo(`/shipping/orders?sellerId=${seller.id}`)"
                class="text-left"
              >
                <NbIcon icon="link" size="18" />
                {{ $t("sellersPage.seeSellerOrders") }}
              </NbButton>
            </div>
          </template>
        </NbCard>
      </div>

      <SellerReviewCard :seller="seller" />

      <NbCard id="general-data" :title="$t('generalData')">
        <template #body>
          <div>
            <div class="grid grid-cols-2 gap-4">
              <NbTextInput
                v-model="seller.name"
                :name="$t('sellersPage.name')"
                id="seller.name"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />

              <NbTextInput
                v-model="seller.email"
                :name="$t('sellersPage.email')"
                id="seller.email"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />
            </div>

            <Transition name="slide-fade">
              <div v-show="expandGeneralData" class="grid grid-cols-2 gap-4">
                <NbTextInput
                  v-model="seller.phone"
                  :name="$t('sellersPage.phone')"
                  id="seller.phone"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />

                <NbTextInput
                  v-model="seller.rfb_code"
                  :name="$t('sellersPage.rfbCode')"
                  id="seller.rfb_code"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />

                <NbTextInput
                  v-model="seller.legal_name"
                  :name="$t('sellersPage.legalName')"
                  id="seller.legal_name"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />

                <NbTextInput
                  v-model="seller.contract_link"
                  :name="$t('sellersViewPage.contractLink')"
                  id="seller.contract_link"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />

                <NbTextInput
                  v-model="seller.billing_email"
                  :name="$t('sellersPage.billingEmail')"
                  id="seller.billing_email"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />
                <NbTextInput
                  v-model="seller.responsable_contact"
                  :name="$t('sellersPage.responsableContact')"
                  id="seller.responsable_contact"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />
                <NbTextInput
                  v-if="isSuper"
                  v-model="seller.platform_name"
                  :name="$t('sellersPage.platform')"
                  id="seller.platform_name"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                  class="col-span-2"
                />
              </div>
            </Transition>

            <NbButton
              variant="tertiary"
              @click="expandGeneralData = !expandGeneralData"
            >
              {{ !expandGeneralData ? $t("showMoreData") : $t("collapseData") }}
            </NbButton>
          </div>
        </template>
      </NbCard>

      <NbCard id="address" :title="$t('sellersPage.address')">
        <template #body>
          <div>
            <div class="grid grid-cols-2 gap-4">
              <NbTextInput
                v-model="seller.address"
                :name="$t('sellersPage.address')"
                id="seller.address"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />

              <NbTextInput
                v-model="seller.address_number"
                :name="$t('sellersPage.addressnumber')"
                id="seller.address_number"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />

              <NbTextInput
                v-model="seller.address_complement"
                :name="$t('sellersPage.addresscomplement')"
                id="seller.address_complement"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />

              <NbTextInput
                v-model="seller.city"
                :name="$t('sellersPage.city')"
                id="seller.city"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />
            </div>

            <Transition name="slide-fade">
              <div v-show="expandAddress" class="grid grid-cols-2 gap-4">
                <NbTextInput
                  v-model="seller.zip_code"
                  :name="$t('sellersPage.postalcode')"
                  id="seller.zip_code"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />
                <NbTextInput
                  v-model="seller.state"
                  :name="$t('sellersPage.state')"
                  id="seller.state"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />
                <NbTextInput
                  v-model="seller.country"
                  :name="$t('sellersPage.country')"
                  id="seller.country"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />
              </div>
            </Transition>

            <NbButton
              variant="tertiary"
              @click="expandAddress = !expandAddress"
            >
              {{ !expandAddress ? $t("showMoreData") : $t("collapseData") }}
            </NbButton>
          </div>
        </template>
      </NbCard>

      <NbCard id="devolution" :title="$t('sellersViewPage.devolutionAddress')">
        <template #body>
          <div>
            <div class="grid grid-cols-2 gap-4">
              <NbTextInput
                v-model="seller.devolution_name"
                :name="$t('sellersPage.name')"
                id="seller.devolution_name"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />

              <NbTextInput
                v-model="seller.devolution_address"
                :name="$t('sellersViewPage.address')"
                id="seller.devolution_address"
                variant="borderless-gray-60"
                placeholder="-"
                disabled
              />
            </div>

            <Transition name="slide-fade">
              <div
                v-show="expandDevolutionAddress"
                class="grid grid-cols-2 gap-4"
              >
                <NbTextInput
                  v-model="seller.devolution_address_number"
                  :name="$t('sellersViewPage.addressNumber')"
                  id="seller.devolution_address_number"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />

                <NbTextInput
                  v-model="seller.devolution_address_quarter"
                  :name="$t('sellersViewPage.addressQuarter')"
                  id="seller.devolution_address_quarter"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />

                <NbTextInput
                  v-model="seller.devolution_address_complement"
                  :name="$t('sellersViewPage.addressComplement')"
                  id="seller.devolution_address_complement"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />

                <NbTextInput
                  v-model="seller.devolution_address_reference"
                  :name="$t('sellersViewPage.addressReference')"
                  id="seller.devolution_address_reference"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />

                <NbTextInput
                  v-model="seller.devolution_city"
                  :name="$t('sellersViewPage.city')"
                  id="seller.devolution_city"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />

                <NbTextInput
                  v-model="seller.devolution_country"
                  :name="$t('sellersViewPage.country')"
                  id="seller.devolution_country"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />

                <NbTextInput
                  v-model="seller.devolution_postal_code"
                  :name="$t('sellersViewPage.postalCode')"
                  id="seller.devolution_postal_code"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />

                <NbTextInput
                  v-model="seller.devolution_state"
                  :name="$t('sellersViewPage.state')"
                  id="seller.devolution_state"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />

                <NbTextInput
                  v-model="seller.devolution_ddd"
                  name="DDD"
                  id="seller.devolution_ddd"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />

                <NbTextInput
                  v-model="seller.devolution_phone"
                  :name="$t('sellersViewPage.phone')"
                  id="seller.devolution_phone"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />

                <NbTextInput
                  v-model="seller.devolution_email"
                  :name="$t('sellersViewPage.phone')"
                  id="seller.devolution_email"
                  variant="borderless-gray-60"
                  placeholder="-"
                  disabled
                />
              </div>
            </Transition>

            <NbButton
              variant="tertiary"
              @click="expandDevolutionAddress = !expandDevolutionAddress"
            >
              {{
                !expandDevolutionAddress
                  ? $t("showMoreData")
                  : $t("collapseData")
              }}
            </NbButton>
          </div>
        </template>
      </NbCard>

      <SellerContractsCard
        :contracts="seller.allowed_contracts"
        :operations="seller.contract_operations"
      />

      <SellerUsersCard :users="seller.users" />
    </div>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
    </ModalFooter>

    <Modal v-model="showEditModal" size="lg">
      <SellerUpdate :seller="seller" @updated="onUpdateSeller" />
    </Modal>
  </div>
</template>

<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import Modal from "@/components/modal/Modal.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import Spinner from "@/components/Spinner.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import SellerUpdate from "./SellerUpdate.vue";
import { jsonToFile } from "@/utils/json-to-file";
import SellerService from "@/services/SellerService";
import PrintingService from "@/services/PrintingService";
import SellerReviewCard from "./SellerReviewCard.vue";
import SellerUsersCard from "./SellerUsersCard.vue";
import SellerContractsCard from "./SellerContractsCard.vue";
import imageFallback from "@/directives/image-fallback";

const sellerService = new SellerService();
const printingService = new PrintingService();

export default {
  components: {
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    Spinner,
    NbCard,
    NbIcon,
    NbTextInput,
    SellerReviewCard,
    SellerUsersCard,
    SellerContractsCard,
    SellerUpdate,
  },
  directives: {
    imageFallback,
  },
  data() {
    return {
      loading: false,
      sellerId: null,
      sellerSettingsDownloading: false,
      expandGeneralData: false,
      expandDevolutionAddress: false,
      expandAddress: false,
      showEditModal: false,
      seller: {
        address: "",
        address_number: "",
        address_complement: "",
        city: "",
        zip_code: "",
        state: "",
        country: "",
        name: "",
        email: "",
        phone: "",
        site: "",
        tax_number: "",
        rfb_code: "",
        legal_name: "",
        is_active: true,
        is_carrier: false,
        is_subsidiary: false,
        is_distribution_center: false,
        is_multiple: false,
        current_balance: 0,
        use_seller_devolution_address: false,
        users: [],
        allowed_contracts: [],
        contract_operations: [],
        company_logo: false,
        logo_url: "",
      },
    };
  },
  computed: {
    isSuper() {
      return this.$store.state.user.is_super;
    },
  },
  methods: {
    onUpdateSeller(data) {
      this.seller = {
        ...this.seller,
        ...data,
      };

      this.$emit("updated", this.seller);
    },
    goToWebsite() {
      if (this.seller.site) {
        let url = this.seller.site;
        if (url.startsWith("http")) {
          window.open(url, "_blank");
        } else {
          window.open(`https://${url}`, "_blank");
        }
      }
    },
    navigateTo(path) {
      this.$router.push(path);
    },
    async downloadSellerSettings() {
      try {
        this.sellerSettingsDownloading = true;
        const { data } = await sellerService.exportSeller(this.sellerId);
        jsonToFile(data, `seller-${this.sellerId}-settings.json`);
      } finally {
        this.sellerSettingsDownloading = false;
      }
    },
    async updateDevolutionAddressSwitch() {
      printingService
        .updatePrinting({
          printing: {
            seller: {
              id: this.sellerId,
              use_seller_devolution_address:
                this.seller.use_seller_devolution_address,
            },
          },
        })
        .catch((error) => {
          if (error.response.data.messages[0]) {
            this.seller.use_seller_devolution_address =
              !this.use_seller_devolution_address;
          }
        });
    },
    async loadSeller() {
      try {
        this.loading = true;
        const response = await sellerService.getOneSeller(this.sellerId);
        this.seller = response.data.data;
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    const { id } = this.$route.query;
    this.sellerId = id;
    this.loadSeller();
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.seller-logo {
  height: 250px;
  img {
    object-fit: fill;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
