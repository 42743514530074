<template>
  <div>
    <header-title :title="$t('nobordistLogsPage.title')" />
    <layout-content
      :title="$t('nobordistLogsPage.title')"
      :namespace="namespace"
      :items="items"
      :filterable="true"
      :filter-options="filterOptions"
      :all-fields="allFields"
      :fields="fields"
    >
      <template #buttonsTop> </template>
      <template #buttonsAction> </template>
      <template #cell(id)="data">
        <div>
          <router-link :to="`/logs/nobordist/` + data.item.id">{{
            data.value
          }}</router-link>
        </div>
      </template>
    </layout-content>
  </div>
</template>

<script>
import LayoutContent from "@/components/LayoutContent";
import HeaderTitle from "@/components/HeaderTitle.vue";
import LogService from "@/services/LogService";

const logService = new LogService();

export default {
  name: "NobordistLogs",
  components: {
    LayoutContent,
    HeaderTitle,
  },
  data: () => {
    return {
      selectMode: "single",
      allFields: [],
      logsPage: 1,
      selected: [],
      namespace: "logs",
    };
  },
  computed: {
    items() {
      const items = this.$store.state[this.namespace].items;
      items.forEach((element, index) => {
        if (items[index].log) {
          let logsString = JSON.stringify(items[index].log);
          items[index].log = logsString.slice(0, 50) + "...";
        }
      });
      return items;
    },
    fields() {
      return [
        { key: "id", label: "ID", sortable: false, thClass: ["small-content"] },
        {
          key: "action",
          label: this.$t("nobordistLogsPage.fields.action"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "action_type",
          label: this.$t("nobordistLogsPage.fields.actionType"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "user_id",
          label: this.$t("nobordistLogsPage.fields.userID"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "log",
          label: this.$t("nobordistLogsPage.fields.logs"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "created_at",
          label: this.$t("nobordistLogsPage.fields.creationDate"),
          sortable: true,
          thClass: ["medium-content"],
        },
      ];
    },
    filterOptions() {
      return [
        { key: "user_id", label: this.$t("nobordistLogsPage.fields.userID") },
        {
          key: "date",
          label: this.$t("nobordistLogsPage.fields.creationDate"),
          type: "dates",
        },
        {
          key: "log_action",
          label: this.$t("nobordistLogsPage.fields.action"),
        },
        {
          key: "log_action_type",
          label: this.$t("nobordistLogsPage.fields.actionType"),
          type: "select",
          options: [],
        },
      ];
    },
  },
  created() {
    this.allFields = this.fields;
    //this.loadItems();
    logService.getLogsActionTypes().then((response) => {
      const variavel = response.data.data;
      let array = Object.keys(variavel).map(function (key) {
        return { value: variavel[key], text: key };
      });
      this.insertFilterOptions("log_action_type", array);
    });
  },
  methods: {
    loadItems() {
      this.$store.dispatch(this.namespace + "/fetchItems", this.namespace);
    },
    reloadFields(event) {
      this.fields = event;
    },
    onRowSelected(items) {
      this.selected = items.map((a) => a.id);
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    insertFilterOptions(key, options) {
      this.filterOptions.find(
        (filterOption) => filterOption.key === key,
      ).options = options;
    },
  },
};
</script>
