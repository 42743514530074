<template>
  <div class="px-3">
    <div class="text-center nb-footer">
      <div>
        <div class="body-4">{{ $t("components.footer.needHelp") }}?</div>
        <a
          v-if="text"
          :href="link ? link : '#'"
          class="body-4 ml-3 mb-0"
          :class="link ? 'link-1' : ''"
          >{{ text }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      require: false,
    },
    link: {
      type: String,
      require: false,
    },
  },
};
</script>

<style scoped>
.nb-footer {
  margin-top: 5.3rem;
  padding: 1.5rem;
  border-top: 1px solid var(--gray-10);
}
</style>
