<template>
  <div>
    <NbButton
      variant="quaternary"
      @click="isFiltersOpen = !isFiltersOpen"
      :class="['d-flex gap-2 align-items-center', { ping: isFiltered }]"
    >
      <NbIcon icon="filter" :size="18" />
      {{ $t("components.tableFilters.title") }}
    </NbButton>
    <div class="datatable-filters-wrapper">
      <transition name="top-down" mode="out-in">
        <div
          v-if="isFiltersOpen"
          class="datatable-filters"
          v-click-outside="closeFilters"
        >
          <h6>{{ $t("components.tableFilters.subTitle") }}.</h6>

          <div>
            <div
              v-for="(option, index) in options"
              :key="index"
              class="d-flex align-items-center"
            >
              <div class="grid grid-cols-2 gap-2 flex-grow-1">
                <NbSelectInput
                  v-model="option.type"
                  :options="filterOptions"
                  :id="`key-${index}`"
                  size="sm"
                  @change="setFilter(index, $event)"
                  class="w-full"
                />

                <div
                  v-if="option.components.length"
                  :class="[
                    `w-full grid gap-1 grid-cols-${option.components.length}`,
                  ]"
                >
                  <div
                    v-for="(component, current) in option.components"
                    :key="current"
                    class="w-full"
                  >
                    <component
                      :is="component.name"
                      v-bind="component.attrs"
                      v-model="component.data.value"
                    />
                  </div>
                </div>
              </div>
              <button class="btn" @click="removeFilter(index)">
                <NbIcon icon="trash" />
              </button>
            </div>
          </div>

          <hr />

          <div class="d-flex justify-content-between">
            <NbButton
              :disabled="!canAddNewFilter"
              variant="tertiary"
              icon="plus"
              @click="appendFilter()"
            >
              {{ $t("components.tableFilters.addNewFilter") }}
            </NbButton>
            <div>
              <NbButton variant="secondary" @click="onReset" class="mr-2">
                {{ $t("clear") }}
              </NbButton>
              <NbButton @click="onFilter">
                {{ $t("filter") }}
              </NbButton>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbBorderTextInput from "@/components/input/text/NbBorderTextInput.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbInputMoney from "@/components/input/money/NbInputMoney.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import { directive } from "v-click-outside";

export default {
  directives: { clickOutside: directive },
  components: {
    NbSelectInput,
    NbTextInput,
    NbBorderTextInput,
    NbButton,
    NbIcon,
    NbInputMoney,
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    filtered: {
      type: [Object, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      changeRef: null,
      isFiltersOpen: false,
      options: [
        {
          type: "",
          components: [
            {
              name: "",
              data: {
                key: "",
                value: "",
              },
            },
          ],
        },
      ],
    };
  },
  computed: {
    isFiltered() {
      return !!Object.values(this.filtered).length;
    },
    canAddNewFilter() {
      return this.options.length !== this.filters.length;
    },
    filterOptions() {
      return this.filters.map((filter, index) => ({
        text: filter.label,
        value: index + 1,
        disabled: this.options.find((option) => option.type === index + 1),
      }));
    },
  },
  watch: {
    filters(newValue, oldValue) {
      clearTimeout(this.changeRef);
      this.changeRef = setTimeout(() => {
        if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
          return;
        }
        for (let index = 0; index < this.options.length; index++) {
          const hasOptionWithKey = this.options[index].components.some(
            (component) => !!component.data.key,
          );

          if (hasOptionWithKey) {
            this.setFilter(index, this.options[index].type);
          }
        }

        this.checkQueryParams();
      }, 500);
    },
    filtered() {
      this.checkQueryParams();
    },
  },
  methods: {
    closeFilters() {
      this.isFiltersOpen = false;
    },
    removeFilter(index) {
      this.options.splice(index, 1);
    },
    appendFilter(type = "") {
      this.options.push({
        type,
        components: [
          {
            name: "",
            data: {
              key: "",
              value: "",
            },
          },
        ],
      });
    },
    findFilterIndexByKey(key) {
      return this.filters.findIndex((filter) => {
        const keys = filter.inputs?.map((input) => input.key) || [];

        return keys.includes(key);
      });
    },
    onReset() {
      this.options = [];
      this.appendFilter();
      this.isFiltersOpen = false;
      this.$emit("reset");
    },
    onFilter() {
      const keys = this.filters.flatMap((filter) =>
        filter.inputs.map((input) => input.key),
      );

      const optionFilters = this.options.reduce((acc, current) => {
        current.components.forEach((component) => {
          const key = component?.data?.key;
          if (key && !acc.hasOwnProperty(key) && component?.data?.value) {
            acc[key] = component.data.value;
          }
        });
        return acc;
      }, {});

      const filters = keys.reduce((acc, key) => {
        acc[key] = optionFilters.hasOwnProperty(key)
          ? optionFilters[key]
          : undefined;
        return acc;
      }, {});

      this.$emit("filters", filters);
      this.isFiltersOpen = false;
    },
    generateUniqueId() {
      return (
        "id-" +
        Date.now().toString(36) +
        "-" +
        Math.random().toString(36).substr(2, 9)
      );
    },
    getComponent(input) {
      const placeholder = input.placeholder || `Filter by: ${input.key}`;
      const attrs = {
        size: "sm",
        id: this.generateUniqueId(),
        placeholder,
      };

      const components = {
        text: {
          name: "NbBorderTextInput",
          attrs: {
            ...attrs,
            type: "text",
          },
        },
        number: {
          name: "NbBorderTextInput",
          attrs: {
            ...attrs,
            type: "number",
          },
        },
        money: {
          name: "NbInputMoney",
          attrs: {
            ...attrs,
            prefix: input.currency?.prefix || "R$ ",
            decimal: input.currency?.decimal || ",",
            thousands: input.currency?.thousands || ".",
          },
        },
        select: {
          name: "NbSelectInput",
          attrs: {
            ...attrs,
            options: input.options || [],
          },
        },
        date: {
          name: "NbBorderTextInput",
          attrs: {
            ...attrs,
            type: "date",
          },
        },
        boolean: {
          name: "NbSelectInput",
          attrs: {
            ...attrs,
            options: [
              {
                text: this.$t("components.tableFilters.trueText"),
                value: "true",
              },
              {
                text: this.$t("components.tableFilters.falseText"),
                value: "false",
              },
            ],
          },
        },
      };

      if (input.type in components) {
        return {
          ...components[input.type],
          key: input.key,
        };
      }

      return {
        ...components.text,
        key: input.key,
      };
    },
    setFilter(optionIndex, filterIndex) {
      const filter = this.filters[filterIndex - 1];
      const components = [];
      filter.inputs?.forEach((input) => {
        const component = this.getComponent(input);
        components.push(component);
      });

      this.options[optionIndex].components = components.map((item, index) => {
        const value =
          this.options[optionIndex]?.components?.[index]?.value || "";

        return {
          name: item.name,
          attrs: item.attrs,
          data: {
            key: item.key,
            value,
          },
        };
      });
    },
    checkQueryParams() {
      if (!Object.values(this.filtered).length) {
        return false;
      }

      this.options = [];
      Object.entries(this.filtered).forEach(([key, value]) => {
        const filterIndex = this.findFilterIndexByKey(key);
        const optionType = filterIndex + 1;
        let optionIndex = this.options.findIndex(
          (option) => option.type === optionType,
        );

        if (optionIndex === -1) {
          optionIndex = this.options.length;
          this.appendFilter(optionType);
          this.setFilter(optionIndex, optionType);
        }
        this.options[optionIndex].components = this.options[
          optionIndex
        ].components.map((component) => {
          if (component.data.key === key) {
            component.data.value = !isNaN(Number(value))
              ? Number(value)
              : value;
          }

          return component;
        });
      });
    },
  },
  beforeMount() {
    this.checkQueryParams();
  },
};
</script>

<style lang="scss" scoped>
.datatable-filters-wrapper {
  position: relative;

  .datatable-filters {
    position: absolute;
    right: 0;
    top: 0.5rem;
    padding: 1rem;
    z-index: 5;
    min-width: 36rem;

    background: var(--gray-05);
    box-shadow: 4px 4px 12px var(--gray-10);
    background: #f0f0fa 0% 0% no-repeat padding-box;
    border-radius: 4px 4px 0px 0px;
    padding: 1rem;
    border-radius: 4px !important;

    border-top: 1px solid var(--gray-10);
    border-right: 2px solid var(--gray-10);
    border-bottom: 2px solid var(--gray-10);
    border-left: 2px solid var(--gray-10);
  }
}

.top-down-enter-active {
  transition: all 0.3s ease-out;
}

.top-down-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.top-down-enter,
.top-down-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

@keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.ping {
  position: relative;
  &::after,
  &::before {
    content: "";
    position: absolute;
    display: block;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    top: -4px;
    right: 4px;
    background-color: var(--primary);
  }
  &::before {
    width: 0.5rem;
    height: 0.5rem;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
}
</style>
