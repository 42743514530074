<template>
  <div>
    <DatatableRoot
      ref="table"
      namespace="users"
      :columns="tableColumns"
      :filters="filterOptions"
      clickable
      selectable
      :selected.sync="rowSelection"
      :urlState="false"
      @count="$emit('count', $event)"
      @rowClick="onUserClick($event.id)"
      :search-params="{ profile }"
    >
      <template #actions>
        <DatatableActions :disabled="!rowSelection.length">
          <DatatableAction @click="showModalDownloadUsers">
            {{ $t("usersPage.downloadUsers") }}
          </DatatableAction>
        </DatatableActions>
      </template>
      <template #company_name="{ row }">
        <template v-if="row.profile === 'carrier'">
          <router-link :to="`/contracts/carriers?id=` + row.carrier_id">
            {{ row.company_name || "-" }}
          </router-link>
        </template>
        <template v-else-if="row.profile === 'seller'">
          <router-link :to="`/users_management/sellers?id=` + row.seller_id">
            {{ row.company_name || "-" }}
          </router-link>
        </template>
        <template v-else>
          {{ row.company_name || "-" }}
        </template>
      </template>
      <template #is_blocked="{ row }">
        <template v-if="!row.is_blocked">
          <NbBadge
            :text="$t('usersPage.allowed')"
            type="success"
            class="d-flex"
          />
        </template>
        <template v-else>
          <NbBadge
            :text="$t('usersPage.blocked')"
            type="danger"
            class="d-flex"
          />
        </template>
      </template>
      <template #group_name="{ row }">
        <template v-if="row.group_name == 'Admin'">
          <router-link :to="`/users_management/groups/` + row.group_id">
            <NbBadge
              :text="row.group_name?.replace(/(.*\-)/, '')"
              class="d-flex"
            />
          </router-link>
        </template>
        <template v-else>
          <router-link :to="`/users_management/groups/` + row.group_id">
            <NbBadge
              :text="row.group_name?.replace(/(.*\-)/, '')"
              type="warning"
              class="d-flex"
            />
          </router-link>
        </template>
      </template>

      <template #profile="{ row }">
        <UserProfileBadge :profile="row.profile" />
      </template>

      <template #is_supervisor="{ row }">
        <IsCheckedIcon :checked="row.is_supervisor" />
      </template>

      <template #created_at="{ row }">
        {{ row.created_at | dateTime }}
      </template>
    </DatatableRoot>

    <ConfirmModal
      v-model="isDownloadModalOpen"
      :title="$t('usersPage.downloadUsers')"
      :actionTitle="$t('usersPage.downloadUsers')"
      @action="downloadUsers"
    >
      <p>{{ $t("usersPage.processingDownloads") }}</p>
    </ConfirmModal>
  </div>
</template>

<script>
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import DatatableActions from "@/components/datatable/DatatableActions.vue";
import DatatableAction from "@/components/datatable/DatatableAction.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import UserProfileBadge from "./UserProfileBadge.vue";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";
import ConfirmModal from "@/components/modal/ConfirmModal.vue";
import UsersService from "@/services/UsersService";
import DownloadsService from "@/services/DownloadsService";

import queryParamsMixin from "@/mixins/query-params-mixin.js";
import moment from "moment";

const usersService = new UsersService();
const downloadsService = new DownloadsService();

export default {
  components: {
    DatatableRoot,
    NbBadge,
    UserProfileBadge,
    IsCheckedIcon,
    ConfirmModal,
    DatatableActions,
    DatatableAction,
  },
  filters: {
    dateTime(value) {
      return value && moment(value).format("DD/MM/YYYYY HH:mm:ss");
    },
  },
  mixins: [queryParamsMixin],
  props: {
    profile: {
      type: String,
      default: "",
    },
  },
  inject: ["userContext"],
  data() {
    return {
      rowSelection: [],
      fields: [],
      user: {},
      isUserModalOpen: false,
      isDownloadModalOpen: false,
      tableRef: this.profile ? this.profile.concat("Ref") : "tableRef",
      active: false,
    };
  },
  computed: {
    filterOptions() {
      const options = [
        {
          label: this.$t("usersPage.fields.name"),
          inputs: [
            {
              key: "name",
            },
          ],
        },
        {
          label: this.$t("usersPage.group"),
          inputs: [
            {
              key: "group_id",
              type: "select",
              options: this.userContext?.groupOptions,
            },
          ],
        },
        {
          label: this.$t("usersPage.fields.company"),
          inputs: [
            {
              key: "company",
              type: "select",
              options: this.userContext?.companyOptions || [],
            },
          ],
        },
        {
          label: this.$t("usersPage.fields.profile"),
          inputs: [
            {
              key: "profile",
              type: "select",
              options: [
                { value: null, text: "Select" },
                { value: "admin", text: "Platform Admin" },
                { value: "seller", text: "Seller" },
                { value: "carrier", text: "Carrier" },
              ],
            },
          ],
        },
        {
          label: this.$t("usersPage.fields.email"),
          inputs: [{ key: "email" }],
        },
        {
          label: this.$t("usersPage.phone"),
          inputs: [{ key: "phone" }],
        },
        {
          label: this.$t("usersPage.whatsappNumber"),
          inputs: [{ key: "whatsapp_number" }],
        },
        {
          label: this.$t("downloadsPage.fields.status"),
          inputs: [
            {
              key: "is_blocked",
              type: "select",
              options: [
                { value: true, text: this.$t("usersPage.blocked") },
                { value: false, text: this.$t("usersPage.allowed") },
              ],
            },
          ],
        },
        {
          label: this.$t("usersPage.fields.supervisor"),
          inputs: [{ key: "is_supervisor", type: "boolean" }],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
      ];

      if (!this.profile) {
        return options;
      }

      return options.filter(
        (option) => !option.inputs.some((input) => input.key === "profile"),
      );
    },
    isSuper() {
      return this.$store.state.user.is_super;
    },
    tableColumns() {
      const fields = [
        {
          key: "id",
          label: "Id",
          sortable: false,
        },
        {
          key: "name",
          label: this.$t("usersPage.fields.name"),
          sortable: false,
        },
        {
          key: "company_name",
          label: this.$t("usersPage.fields.company"),
          sortable: false,
        },
        {
          key: "is_blocked",
          label: this.$t("usersPage.fields.status"),
          sortable: false,
        },
        {
          key: "email",
          label: this.$t("usersPage.fields.email"),
          sortable: false,
        },
        {
          key: "phone",
          label: this.$t("usersPage.phone"),
          sortable: false,
        },
        {
          key: "whatsapp_number",
          label: this.$t("usersPage.whatsappNumber"),
          sortable: false,
        },
        {
          key: "profile",
          label: this.$t("usersPage.fields.profile"),
          sortable: false,
        },
        {
          key: "is_supervisor",
          label: this.$t("usersPage.fields.supervisor") + "?",
          sortable: false,
        },
        {
          key: "group_name",
          label: this.$t("usersPage.fields.group"),
          sortable: false,
        },
        {
          key: "created_at",
          label: this.$t("usersPage.fields.creationDate"),
          sortable: true,
        },
      ];

      if (this.isSuper) {
        fields.push({
          key: "platform_name",
          label: this.$t("usersViewPage.platform"),
        });
      }

      return fields;
    },
  },
  methods: {
    onUserClick(id) {
      this.$emit("userClick", id);
    },
    loadItems() {
      this.$refs.table?.getData();
    },
    updateItem(data) {
      this.$refs.table?.updateRowData({
        key: "id",
        value: data.id,
        data,
      });
    },
    showModalDownloadUsers() {
      this.isDownloadModalOpen = true;
    },
    showEditUserModal(data) {
      this.setUrlSearchParam("id", data.id);
      this.user = data;
      this.isUserModalOpen = true;
    },

    optionTook(event) {
      this[event]();
    },
    getUser(id) {
      usersService.getUser(id).then((response) => {
        this.showEditUserModal(response.data.data);
      });
    },
    downloadUsers() {
      downloadsService
        .loadProcessing({
          data_type: "users",
          reference_ids: this.rowSelection,
        })
        .then(() => {
          this.isDownloadModalOpen = false;
          this.$router.push("/archives");
        });
    },
  },
  activated() {
    if (this.active) {
      this.loadItems();
    }

    this.active = true;
  },
};
</script>
