<template>
  <NbCard id="labels" :title="$t('documentsAndLabels')">
    <template #body>
      <div class="grid gap-4">
        <NbButton
          variant="secondary"
          @click="generateVolumeLabel()"
          class="text-left"
        >
          <NbIcon icon="download" :size="18" class="mr-2" />
          {{ $t("volumesViewPage.lastMileLabel") }}
        </NbButton>
        <NbButton
          variant="secondary"
          @click="generateCommercialInvoice()"
          class="text-left"
        >
          <NbIcon icon="download" :size="18" class="mr-2" />
          {{ $t("volumesViewPage.invoice") }}
        </NbButton>
        <NbButton
          variant="secondary"
          @click="generatePackingSlip()"
          class="text-left"
        >
          <NbIcon icon="download" :size="18" class="mr-2" />
          {{ $t("volumesViewPage.packingSlip") }}
        </NbButton>
        <NbButton
          variant="secondary"
          @click="generateVolumeFirstMile()"
          class="text-left"
        >
          <NbIcon icon="download" :size="18" class="mr-2" />
          {{ $t("volumesPage.fields.firstMileLabel") }}
        </NbButton>

        <!-- <NbButton
          v-if="reference"
          variant="secondary"
          v-copy="reference"
          v-b-tooltip.hover
          :title="$t('clickToCopy')"
        >
          {{ $t("volumesViewPage.copyReference") }}
        </NbButton> -->
      </div>
    </template>
  </NbCard>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import OrderLabelService from "@/services/OrderLabelService";
import PackingSlipService from "@/services/PackingSlipService";
import { copyClipboard } from "@/directives/copy-clipboard";

const orderLabelService = new OrderLabelService();
const packingSlipService = new PackingSlipService();

export default {
  props: {
    volumeId: {
      type: [Number, String],
      required: true,
    },
    orderId: {
      type: [Number, String],
      required: true,
    },
    reference: {
      type: String,
      default: "",
    },
  },
  directives: {
    copy: copyClipboard,
  },
  components: {
    NbButton,
    NbCard,
    NbIcon,
  },
  methods: {
    generateCommercialInvoice() {
      orderLabelService
        .generateCommercialInvoiceLabel(this.orderId, this.volumeId)
        .then((response) => {
          if (response.data.data.download_url) {
            window.open(response.data.data.download_url);
          }
        });
    },
    generateVolumeLabel() {
      orderLabelService.generateVolumeLabel(this.volumeId).then((response) => {
        Object.prototype.hasOwnProperty.call(response.data, "data");
        if (
          Object.prototype.hasOwnProperty.call(response.data, "data") &&
          Object.prototype.hasOwnProperty.call(
            response.data.data,
            "download_url",
          )
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
    generateVolumeFirstMile() {
      orderLabelService
        .generateVolumeFirstMile(this.volumeId)
        .then((response) => {
          Object.prototype.hasOwnProperty.call(response.data, "data");
          if (
            Object.prototype.hasOwnProperty.call(response.data, "data") &&
            Object.prototype.hasOwnProperty.call(
              response.data.data,
              "download_url",
            )
          ) {
            window.open(response.data.data.download_url);
          }
        });
    },
    generatePackingSlip() {
      packingSlipService.generate(this.orderId).then((response) => {
        if (response.data.data.download_url) {
          window.open(response.data.data.download_url);
        }
      });
    },
  },
};
</script>
