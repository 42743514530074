import api from "./HttpService";

export default class PlatformService {
  /**
   * Get allPlatforms
   *
   * @returns {Promise}
   */
  getAllPlatforms() {
    return api.get("/v1/platforms");
  }

  /**
   * Update Platforms
   *
   * @param id
   * @param data
   * @returns {Promise}
   */
  updatePlatform(id, data = {}) {
    return api.put(`/v1/platforms/${id}`, data);
  }

  setPlatform(data = {}) {
    return api.post("/v1/platforms", data);
  }

  /**
   * Get Platform
   *
   * @param id
   * @returns {Promise}
   */
  getPlatform(id) {
    return api.get(`/v1/platforms/${id}`);
  }
}
