<template>
  <i
    title="Copy To Clipboard"
    class="fas fa-copy pointer"
    @click="copyClipboard()"
  ></i>
</template>

<script>
export default {
  props: {
    copyVal: {
      type: [String, Array],
      required: true,
    },
  },
  methods: {
    async copyClipboard() {
      let stringifyValue;
      if (Array.isArray(this.copyVal)) {
        stringifyValue = JSON.stringify(this.copyVal);
      } else {
        stringifyValue = this.copyVal;
      }
      await navigator.clipboard.writeText(stringifyValue);
      this.$toasted.show(
        `${stringifyValue} is copied to your clipboard. Use CTRL+V to paste.`,
        {
          type: "success",
          theme: "bubble",
          duration: 4000,
        },
      );
    },
  },
};
</script>
