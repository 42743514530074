<template>
  <div>
    <!-- pagina deve ser removida -->
    <NbPageTitle
      class="pb-3"
      :title="`
        ${$t('nobordistCheckpointsViewPage.title')} ${
          this.itemId ? this.itemId : this.$route.params.id
        }`"
      :subTitle="$t('checkpointsPage.subTitleView')"
    >
    </NbPageTitle>

    <NbCard
      id="checkpoint_chard"
      :title="$t('generalInformation')"
      class="bg-gray-05"
    >
      <template #body>
        <div class="d-flex justify-content-between flex-wrap">
          <NbTextInput
            id="checkpoint_type"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-48-5 my-2"
            :name="$t('nobordistCheckpointsViewPage.type')"
            v-model="checkpoint.type"
            disabled
            required
          />
          <NbTextInput
            id="checkpoint_slug"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-48-5 my-2"
            :name="$t('nobordistCheckpointsViewPage.slug')"
            v-model="checkpoint.slug"
            disabled
            required
          />
          <NbTextInput
            id="tcheckpoint_itle"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-48-5 my-2"
            :name="$t('nobordistCheckpointsViewPage.titleField')"
            v-model="checkpoint.title"
            disabled
            required
          />
          <NbTextInput
            id="titlcheckpoint_e_pt"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-48-5 my-2"
            :name="$t('nobordistCheckpointsViewPage.titlePt')"
            v-model="checkpoint.title_pt"
            disabled
            required
          />
          <NbTextInput
            id="checkpoint_name"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-48-5 my-2"
            :name="$t('nobordistCheckpointsViewPage.name')"
            v-model="checkpoint.name"
            disabled
            required
          />
          <NbTextInput
            id="tracking_checkpoint_code"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-48-5 my-2"
            :name="$t('nobordistCheckpointsViewPage.trackingCode')"
            v-model="checkpoint.tracking_code"
            disabled
            required
          />
          <NbTextInput
            id="descripcheckpoint_tion"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-100 my-2"
            :name="$t('nobordistCheckpointsViewPage.description')"
            v-model="checkpoint.description"
            disabled
            required
          />
          <NbTextInput
            id="descriptiocheckpoint_n_pt"
            variant="borderless-gray-10"
            :placeholder="$t('notFound')"
            class="w-100 my-2"
            :name="$t('nobordistCheckpointsViewPage.descriptionPt')"
            v-model="checkpoint.description_pt"
            disabled
            required
          />
        </div>
      </template>
    </NbCard>
    <NbFooter
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import CheckpointService from "../../../services/CheckpointService";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";

const checkpointService = new CheckpointService();

export default {
  name: "NobordistCheckpointView",
  props: {
    itemId: {
      type: [Number, String],
      required: false,
    },
  },
  components: {
    NbPageTitle,
    NbTextInput,
    NbCard,
    NbFooter,
  },
  data: () => {
    return {
      checkpoint: {},
    };
  },
  computed: {},
  beforeMount() {
    this.reloadCheckpoints();
  },
  methods: {
    reloadCheckpoints() {
      const id = this.itemId || this.$route.params.id;
      if (id) {
        checkpointService.getCheckpoint(id).then((response) => {
          this.checkpoint = response.data.data;
        });
      }
    },
  },
  watch: {
    itemId() {
      this.reloadCheckpoints();
    },
  },
};
</script>
