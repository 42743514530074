<template>
  <div class="invalid-feedback">{{ error }}</div>
</template>

<script>
export default {
  name: "ErrorFeedback",
  props: {
    error: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
