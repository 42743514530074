import api from "./HttpService";

export default class DeliveryTimesService {
  getIndex() {
    return api.get("/v1/delivery_times");
  }

  getOne(id) {
    return api.get("/v1/delivery_times/" + id);
  }

  createOne(data = {}) {
    return api.post("/v1/delivery_times", data);
  }

  update(id, data = {}) {
    return api.put("/v1/delivery_times/" + id, data);
  }

  delete(id) {
    return api.delete(`/v1/delivery_times/${id}`);
  }

  getNotMadeBySeller() {
    return api.get("/v1/dt_not_made_by_seller");
  }

  downloadPdf(id) {
    return api.get(`/v1/delivery_times/${id}/download_pdf`);
  }

  downloadCsv(id) {
    return api.get(`/v1/delivery_times/${id}/download_csv`);
  }
}
