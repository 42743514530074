<template>
  <NbIcon class="text-success" icon="check" v-if="checked" :size="size" />
  <NbIcon v-else icon="x" class="text-danger" :size="size" />
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  props: {
    checked: {
      type: [Boolean, undefined, null],
      default: true,
    },
    size: {
      type: [String, Number],
      default: 16,
    },
  },
  components: {
    NbIcon,
  },
};
</script>

<style lang="scss" scoped></style>
