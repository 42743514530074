export default class PasswordService {
  constructor() {
    this.api = require("axios");
  }

  /**
   * Send reset password email
   *
   * @param data
   * @returns {Promise}
   */
  sendResetPasswordEmail(data = {}) {
    return this.api.post(
      process.env.VUE_APP_LOGISTICS_API_URL + "v1/password/forgot",
      data,
    );
  }

  /**
   * Reset password
   *
   * @param data
   * @returns {Promise}
   */
  resetPassword(data = {}) {
    return this.api.post(
      process.env.VUE_APP_LOGISTICS_API_URL + "v1/password/reset",
      data,
    );
  }
}
