import api from "./HttpService";

export default class OrderLabelService {
  /**
   * Generate box label
   *
   * @param orderId
   * @returns {Promise}
   */
  generateBoxLabel(volumeId) {
    // return api.put('/v1/orders/' + orderId + '/cn23-merged');
    return api.put("/v1/volumes/" + volumeId + "/cn23");
  }

  generateVolumeFirstMile(volumeId) {
    return api.put("/v1/volumes/" + volumeId + "/first-mile");
  }

  generateOrderLabel(orderId) {
    return api.put("/v1/orders/" + orderId + "/cn23-merged");
  }

  generateFirstMile(orderId) {
    return api.put("/v1/orders/" + orderId + "/first-mile-merged");
  }

  generateArchivingConfirmation(orderId) {
    return api.put("/v1/orders/archivate", { order_id: orderId });
  }

  generateArchivateMultiConfirmation(orderIds) {
    return api.put("/v1/orders/archivate_multi", { order_ids: orderIds });
  }

  /**
   * Generate Cn15 label
   *
   * @param orderId
   * @returns {Promise}
   */
  generateShipperConfirmation(orderId) {
    return api.put("/v1/orders/" + orderId + "/shipper_confirmation_pdf");
  }

  generateVolumeLabel(volumeId) {
    return api.put("/v1/volumes/" + volumeId + "/cn23");
  }

  /**
   * Generate Cn15 label
   *
   * @param orderId
   * @returns {Promise}
   */
  generateCn15Label(orderId) {
    return api.put("/v1/orders/" + orderId + "/cn15");
  }

  /**
   * Generate commercial invoice label
   *
   * @param orderId
   * @returns {Promise}
   */
  generateCommercialInvoiceLabel(orderId, volumeId = {}) {
    return api.put("/v1/orders/" + orderId + "/commercial-invoice", {
      volume_id: volumeId,
    });
  }

  updateOrder(id = null, data = {}) {
    return api.put("/v1/orders/" + id + "/admin", data);
  }
}
