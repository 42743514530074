import api from "./HttpService";

export default class TablesService {
  createColumns(data) {
    return api.post("/v1/table_columns", data);
  }
  getColumns() {
    return api.get("/v1/table_columns/");
  }
  setColumns(data) {
    const dataToSend = { user: { column_settings: data } };
    return api.put("/v1/table_columns/", dataToSend);
  }
  deleteColumns(id) {
    return api.delete("/v1/table_columns/" + id);
  }
}
