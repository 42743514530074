<template>
  <div class="modal-wrapper">
    <button
      :id="`${id}-open`"
      class="invisible position-absolute btn-modal-action"
      data-toggle="modal"
      :data-target="`#${id}`"
    >
      open modal anchor
    </button>
    <div
      class="modal fade"
      :id="id"
      :data-backdrop="preventClose ? 'static' : true"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :style="zindex ? `z-index: ${zindex}` : ''"
    >
      <div
        class="modal-dialog"
        :class="modalConfig"
        role="document"
        :style="`max-width: ${width};`"
      >
        <div
          class="modal-content"
          :style="`width: ${width};`"
          ref="modalContent"
        >
          <div class="modal-header">
            <slot name="header"></slot>
            <div>
              <slot name="header-right"></slot>
              <button
                v-if="!hiddenCloseBtn"
                type="button"
                :id="`${id}-close`"
                class="close"
                :class="preventClose ? 'visibility-hidden' : ''"
                data-toggle="modal"
                :data-target="`#${id}`"
                @click="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <slot>
            <div class="modal-body">
              <slot name="body"></slot>
            </div>
          </slot>
          <div>
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NbModal",
  props: {
    id: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
    },
    modalConfig: {
      type: String,
      required: false,
    },
    preventClose: {
      type: Boolean,
      default: false,
    },
    hiddenCloseBtn: {
      type: Boolean,
      default: false,
    },
    zindex: {
      type: String,
      required: false,
    },
  },
  data: () => ({}),
  created() {
    this.isOpen = this.value;
  },
  watch: {
    value() {
      if (this.value !== this.isOpen) this.isOpen = this.value;
    },
    isOpen() {
      this.$emit("input", this.isOpen);
    },
  },
  methods: {
    handleClickOutside(event) {
      if (
        this.$el.contains(event.target) &&
        !event.target.closest(".modal-content") &&
        !event.target.dataset.toggle &&
        !this.preventClose
      ) {
        this.close();
      }
    },
    handleEscape(event) {
      if (event?.key === "Escape") {
        if (document.getElementById(`${this.id}`)?.classList.contains("show")) {
          this.close();
        }
      }
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    document.body.addEventListener("click", this.handleClickOutside);
    document.body.addEventListener("keydown", this.handleEscape);
  },
  beforeUnmount() {
    document.body.removeEventListener("click", this.handleClickOutside);
    document.body.removeEventListener("keydown", this.handleEscape);
  },
};
</script>
<style scoped>
.modal-wrapper {
  position: absolute;
  top: 0;
}
.modal-content {
  padding: 3.12rem 2.5rem 2.5rem 2.5rem;
}
.close {
  color: var(--black) !important;
}
.modal-header {
  border-bottom: 0px;
  padding: 0rem;
  /* backdrop-filter: blur(20px); */
}
.visibility-hidden {
  visibility: hidden;
}
.modal-body {
  padding: 2rem 0px;
}
</style>
