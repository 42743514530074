<template>
  <div class="relative lang-button-wrapper">
    <button v-b-toggle.collapse-lang class="button lang-button">
      <img
        :id="`${flagChoosen.iso}-id`"
        class="lang-flag mr-1"
        :src="getFlag(flagChoosen.iso)"
        :alt="flagChoosen.iso"
        @error="tryAlternativeFlag(flagChoosen.iso)"
      /><span class="lang-label">{{ getFlagLabel(flagChoosen.iso) }}</span>
      <i :class="`fas fa-caret-right ml-${ml}`"></i>
    </button>
    <b-collapse id="collapse-lang" class="mt-0 colapse-card">
      <b-card class="p-0">
        <div
          v-for="flag in computedFlags"
          :key="flag.lang"
          @click="switchLocale(flag)"
        >
          <div v-b-toggle.collapse-lang class="lang-options col-12 pt-1">
            <img
              :id="`${flag.iso}-id`"
              class="lang-flag mr-1"
              :src="getFlag(flag.iso)"
              :alt="flag.iso"
              @error="tryAlternativeFlag(flag.iso)"
            />
            <span class="lang-label">{{ getFlagLabel(flag.iso) }}</span>
          </div>
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import UsersService from "@/services/UsersService";

const userService = new UserService();
const usersService = new UsersService();

export default {
  name: "LangButton",
  props: {
    ml: {
      type: [String, Number],
      default: "4",
    },
    user: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      flagChoosen: { iso: "GB", lang: "en", number: 0, text: "english" },
      flags: [
        { iso: "GB", lang: "en", number: 0, text: "english" },
        { iso: "CN", lang: "zh_CN", number: 1, text: "chinese" },
        { iso: "BR", lang: "pt_BR", number: 2, text: "portuguese" },
        { iso: "ES", lang: "es", number: 3, text: "spanish" },
      ],
    };
  },
  computed: {
    computedFlags() {
      let flagues = JSON.parse(JSON.stringify(this.flags));
      const index = flagues.findIndex(
        (flague) => flague.lang === this.$i18n.locale,
      );

      flagues.splice(index, 1);

      return flagues;
    },
  },
  created() {
    this.flagChoosen = this.flags[0];
    this.checkLangConfig();
  },
  methods: {
    getFlag(iso) {
      if (iso) {
        return `https://flagcdn.com/24x18/${iso.toLowerCase()}.png`;
      }
    },
    getFlagLabel(iso) {
      if (iso === "GB") {
        return "EN";
      }
      if (iso === "CN") {
        return "ZH";
      }
      if (iso === "ES") {
        return "ES";
      }
      return "PT";
    },
    tryAlternativeFlag(iso) {
      document.getElementById(`${iso}-id`).src =
        `http://purecatamphetamine.github.io/country-flag-icons/3x2/${iso}.svg`;
    },
    switchLocale(flag) {
      const data = { language: flag.number };
      this.flagChoosen = flag;
      this.$i18n.locale = flag.lang;
      localStorage.setItem("savedLang", JSON.stringify(this.flagChoosen));
      if (this.user && this.user.language) {
        usersService.updateUserLanguage(data).then(() => {
          let user = userService.getLoggedInUser();
          user.language = flag.text;
          userService.setLoggedInUser(JSON.stringify(user));
          this.loadSearchData();
        }); /* .catch(() => {
          this.$helpers.toast('Something gone wrong... try again.', 'danger', 4000);
        }) */
      }
    },
    loadSearchData() {
      this.$emit("loadSearchData");
    },

    checkLangConfig() {
      //search in user
      const user = userService.getLoggedInUser();
      if (user && user.language) {
        const currentFlag = this.flags.find(
          (flag) =>
            flag.number === user.language || flag.text === user.language,
        );
        this.$i18n.locale = currentFlag.lang;
        this.switchLocale(currentFlag);
        return;
      }
      //search in localstorage
      if (JSON.parse(localStorage.getItem("savedLang"))) {
        this.switchLocale(JSON.parse(localStorage.getItem("savedLang")));
        return;
      }
      //search in browser
      let browserLang = navigator.language || navigator.userLanguage;
      if (browserLang == "pt-BR") {
        browserLang = browserLang.replaceAll("-", "_");
      }
      const flag = this.flags.find((flag) => flag.lang === browserLang);
      this.switchLocale(flag);
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-button-wrapper {
  display: inline-block;
}

.button {
  text-decoration: none;
  font-weight: bolder;
  border-radius: 4px;
  border: 0px solid var(--primary);
  padding-top: 0.25rem;
}

.button.lang-button {
  border: 0px solid var(--gray-05);
  background-color: var(--gray-05);
  color: var(--black);
}
.button.lang-button.not-collapsed {
  transition: all 0.3s ease;
  border-bottom: 0px !important;
  border-radius: 4px 4px 0px 0px !important;
}
.button.lang-button.not-collapsed > i {
  transform: rotate(90deg);
}
.button.lang-button > i {
  transition: all 0.3s ease;
  color: var(--gray-30);
}
.lang-options {
  cursor: pointer;
}
.lang-options:hover {
  //removeu? -.-
  //text-decoration: underline;
  background-color: var(--gray-05);
}
.lang-flag {
  width: 1.25rem;
  padding-bottom: 1px;
  cursor: pointer;
  transition: all 0.4s ease;
}
.lang-flag:hover {
  width: 1.25rem;
}
.lang-label {
  font: normal normal normal 16px/16px var(--font-family-base);
}
.card-body {
  padding: 0px;
  border-radius: 4px !important;
}
.card {
  border-top: 0px !important;
  border-right: 1px solid var(--gray-05) !important;
  border-bottom: 1px solid var(--gray-05) !important;
  border-left: 1px solid var(--gray-05) !important;
  color: black !important;
  border-radius: 0px 0px 4px 4px;
  background: transparent;
  flex-direction: column;
  justify-content: center;
}
.relative {
  position: relative;
}
.colapse-card {
  position: absolute;
  top: 2rem;
  width: 100%;
}
</style>
