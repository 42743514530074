import api from "./HttpService";

export default class AlertLogsService {
  /**
   * Get logs
   *
   * @param data
   * @returns {Promise}
   */
  getLogs(offset, track = {}) {
    if (track.limit > 0) {
      offset = (offset - 1) * track.limit;
      return api.get(`/v1/tracking_alert_logs?&offset=${offset}`, {
        params: track,
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/tracking_alert_logs?limit=20&offset=" + offset, {
        params: track,
      });
    }
  }

  //TODO: backend...?
  getLog(id = null) {
    return api.get("/v1/tracking_alert_logs/" + id);
  }

  downloadLogs(data) {
    return api.post("/v1/tracking_alert_logs/download_logs", data);
  }
  /**
   * Download volumes by id and cols
   * @param {*} volumes_id
   * @returns {Promise}
   */
  downloadPaymentCerti(volumes_id) {
    return api.put("/v1/tracking_alert_logs/" + volumes_id + "/payment_label");
  }

  sendPaymentPdf(data = {}) {
    return api.post("/v1/tracking_alert_logs/payment_pdf", data);
  }

  /**
   * Update log
   *
   *
   * @param id
   * @param data
   * @returns {Promise}
   */
  updateLog(id, data) {
    return api.put("/v1/tracking_alert_logs/" + id, data);
  }

  updateMassiveLog(data) {
    return api.put("/v1/alert_logs_massive_update", data);
  }
}
