<template>
  <div>
    <p v-if="data?.trigger_condition?.length">
      {{ $t("contractsPage.ifOrderReceives") }}:
      <strong>
        {{ data?.trigger_condition?.join(", ") || "-" }}
      </strong>
    </p>
    <p v-if="data?.trigger_condition?.length && data?.hs_codes?.trim()">
      {{ $t("contractsPage.andTheItemHasOneOfHscodes") }}:
      <strong>{{ data.hs_codes }}</strong>
    </p>
    <p v-if="data?.trigger_condition?.length && data?.external_id">
      {{ $t("contractsPage.sellerWillBeBilled") }}:
      <strong>
        {{
          contractContext?.getServiceNameByExternalId(data.external_id) || "-"
        }}
      </strong>
    </p>
    <p>
      {{ firstVariableDataDescription }}
      {{ secondVariableDataDescription }}
    </p>
  </div>
</template>

<script>
import { Variables, VariableTypes } from "./variables";

const { VOLUME, ITEM, KILO, DAYS_KG } = Variables;
const { FLAT, PERCENT } = VariableTypes;

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  inject: ["contractContext"],
  computed: {
    firstVariableDataDescription() {
      if (
        !(
          this.data?.primary_variable &&
          this.data?.primary_type >= 0 &&
          this.data?.primary_value?.trim()
        )
      ) {
        return "";
      }

      const { primary_value: value } = this.data;

      const defaultMessageFlat = (type) => {
        return this.$t("contractsPage.valueBilledFlat", {
          value: `USD ${value}`,
          type,
        });
      };

      const defaultMessagePercent = (type) => {
        return this.$t("contractsPage.valueBilledPercent", {
          value: `${value}%`,
          type,
        });
      };

      const messages = {
        [`${VOLUME}_${FLAT}`]: defaultMessageFlat(
          this.$t("contractsPage.volume"),
        ),
        [`${ITEM}_${FLAT}`]: defaultMessageFlat(
          this.$t("contractsPage.volume"),
        ),
        [`${KILO}_${FLAT}`]: defaultMessageFlat("kg"),
        [`${DAYS_KG}_${FLAT}`]: this.$t("contractsPage.valueBilledFlatType", {
          value: `USD ${value}`,
          type: "kg",
          type2: this.$t("contractsPage.day"),
        }),

        [`${VOLUME}_${PERCENT}`]: defaultMessagePercent(
          this.$t("contractsPage.volume"),
        ),
        [`${ITEM}_${PERCENT}`]: defaultMessagePercent(
          this.$tc("contractsPage.item", 2),
        ),
        [`${KILO}_${PERCENT}`]: this.$t(
          "contractsPage.valueBilledPercentType1",
          {
            type: this.$t("contractsPage.volume"),
            type2: "kg",
            value: `${value}%`,
          },
        ),
        [`${DAYS_KG}_${PERCENT}`]: this.$t(
          "contractsPage.valueBilledPercentType2",
          {
            type: this.$t("contractsPage.volume"),
            type2: "kg",
            type3: this.$t("contractsPage.day"),
            value: `${value}%`,
          },
        ),
      };

      const key = `${this.data.primary_variable}_${this.data.primary_type}`;
      return messages[key];
    },
    secondVariableDataDescription() {
      if (
        !(
          this.data?.secondary_variable &&
          this.data?.secondary_type >= 0 &&
          this.data?.secondary_value?.trim() &&
          this.firstVariableDataDescription
        )
      ) {
        return "";
      }

      const { secondary_value: value } = this.data;

      const defaultMessageFlat = (type) => {
        return this.$t("contractsPage.andValueBilledFlat", {
          value: `USD ${value}`,
          type,
        });
      };

      const defaultMessagePercent = (type) => {
        return this.$t("contractsPage.andValueBilledPercent", {
          value: `${value}%`,
          type,
        });
      };

      const messages = {
        [`${VOLUME}_${FLAT}`]: defaultMessageFlat(
          this.$t("contractsPage.volume"),
        ),
        [`${ITEM}_${FLAT}`]: defaultMessageFlat(
          this.$t("contractsPage.volume"),
        ),
        [`${KILO}_${FLAT}`]: defaultMessageFlat("kg"),
        [`${DAYS_KG}_${FLAT}`]: this.$t(
          "contractsPage.andValueBilledFlatType",
          {
            value: `USD ${value}`,
            type1: "kg",
            type2: this.$t("contractsPage.day"),
          },
        ),

        [`${VOLUME}_${PERCENT}`]: defaultMessagePercent(
          this.$t("contractsPage.volume"),
        ),
        [`${ITEM}_${PERCENT}`]: defaultMessagePercent(
          this.$tc("contractsPage.item", 2),
        ),
        [`${KILO}_${PERCENT}`]: this.$t(
          "contractsPage.andValueBilledPercentType1",
          {
            type1: this.$t("contractsPage.volume"),
            type2: "kg",
            value: `${value}%`,
          },
        ),
        [`${DAYS_KG}_${PERCENT}`]: this.$t(
          "contractsPage.andValueBilledPercentType2",
          {
            type1: this.$t("contractsPage.volume"),
            type2: "kg",
            type3: this.$t("contractsPage.day"),
            value: `${value}%`,
          },
        ),
      };

      const key = `${this.data.secondary_variable}_${this.data.secondary_type}`;
      return messages[key];
    },
  },
};
</script>

<style scoped></style>
