import api from "./HttpService";
import apiUntoasted from "./HttpServiceUntoasted";

export default class FlightService {
  /**
   * Get FlightService
   *
   * @param {*} id
   * @returns {Promise}
   */
  getFlight(id = null) {
    return api.get("/v1/flights/" + id + "?source=admin");
  }

  generateFlightLabel(id) {
    return api.put("/v1/flights/" + id + "/mawb_label");
  }
  generateFlightLabelUntoasted(id) {
    return apiUntoasted.put("/v1/flights/" + id + "/mawb_label");
  }

  /**
   * Get FlightService
   *
   * @param offset = number, track = filters
   * @returns {Promise}
   */
  getTwentyFlights(offset, limit, filters) {
    if (limit > 0) {
      offset = (offset - 1) * limit;
      return api.get(`/v1/flights`, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    } else {
      offset = (offset - 1) * 20;
      return api.get("/v1/flights?limit=20&offset=" + offset, {
        params: {
          offset,
          limit,
          filters,
        },
      });
    }
  }

  getAllFlights() {
    return api.get("/v1/flights");
  }

  /**
   * Set Flight
   *
   * @param data
   * @returns {Promise}
   */
  setFlight(data = {}) {
    return api.post("/v1/flights", data);
  }

  /**
   * Set prealert
   *
   * @param {*} id
   * @returns {Promise}
   */

  sendPrealert(id /* , emails */) {
    return api.put(
      "/v1/flights/" + id + "/close_manifest" /* , { emails: emails } */,
    );
  }

  updateFlightInfo(id, data = {}) {
    return api.put("/v1/flights/" + id, data);
  }

  cn38Retry(id) {
    return api.put("/v1/flights/" + id + "/cn38_retry");
  }
  deleteFlight(id) {
    return api.delete("/v1/flights/" + id);
  }

  closeManifest(id) {
    return api.put(`/v1/flights/${id}/close_manifest`);
  }

  confirmDeparture(id) {
    return api.put(`/v1/flights/${id}/confirm_departure`);
  }
}
