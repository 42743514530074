<template>
  <!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 270.33 63.67"
    style="enable-background: new 0 0 270.33 63.67"
    xml:space="preserve"
  >
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="1.8333"
      y1="32"
      x2="64.0318"
      y2="32"
    >
      <stop offset="0" :style="`stop-color: ${logoLeft}`" />
      <stop offset="1" :style="`stop-color: ${logoRight}`" />
    </linearGradient>
    <path
      class="st0"
      d="M32.93,9.89c12.19,0,22.11,9.92,22.11,22.11c0,12.19-9.92,22.11-22.11,22.11c-12.19,0-22.11-9.92-22.11-22.11
	C10.82,19.81,20.74,9.89,32.93,9.89 M32.93,0.9C15.76,0.9,1.83,14.82,1.83,32c0,17.18,13.92,31.1,31.1,31.1
	c17.18,0,31.1-13.92,31.1-31.1C64.03,14.82,50.11,0.9,32.93,0.9L32.93,0.9z"
    />
    <g data-name="Grupo 2" :fill="fill">
      <path
        d="M98.5,30.63v13.76h-2.88V31.07c0-5.36-3.05-8.01-6.95-8.01c-4.16,0-7.3,2.97-7.3,8.01v13.32h-2.88V28.42
		c-0.04-2.97-0.35-6.2-0.49-6.99l2.88-0.35c0.09,0.44,0.27,2.52,0.27,4.56c0,0.18,0,0.4,0,0.57c1.59-3.5,4.73-5.62,8.45-5.62
		C94.65,20.58,98.5,24.26,98.5,30.63z"
      />
      <path
        d="M114.48,20.58c6.46,0,11.42,5.18,11.42,12.21c0,6.99-4.96,12.21-11.42,12.21c-6.46,0-11.37-5.22-11.37-12.21
		C103.1,25.76,108.02,20.58,114.48,20.58z M114.48,42.49c4.82,0,8.36-3.98,8.36-9.69c0-5.71-3.54-9.74-8.36-9.74
		c-4.82,0-8.32,4.03-8.32,9.74C106.16,38.51,109.65,42.49,114.48,42.49z"
      />
      <path
        d="M141.65,20.58c5.66,0,10.4,4.73,10.4,12.21c0,8.05-5.71,12.21-12.97,12.21c-2.79,0-5.44-0.53-8.14-1.59V12.71h2.88v13.1
		C135.46,22.27,138.51,20.58,141.65,20.58z M139.18,42.49c5.4,0,9.82-2.88,9.82-9.69c0-6.15-3.36-9.74-7.74-9.74
		c-4.12,0-7.44,3.1-7.44,8.01v10.58C135.59,42.18,137.45,42.49,139.18,42.49z"
      />
      <path
        d="M166.8,20.58c6.46,0,11.42,5.18,11.42,12.21c0,6.99-4.96,12.21-11.42,12.21c-6.46,0-11.37-5.22-11.37-12.21
		C155.42,25.76,160.33,20.58,166.8,20.58z M166.8,42.49c4.82,0,8.36-3.98,8.36-9.69c0-5.71-3.54-9.74-8.36-9.74
		c-4.82,0-8.32,4.03-8.32,9.74C158.47,38.51,161.97,42.49,166.8,42.49z"
      />
      <path
        d="M192.95,20.58c1.28,0,2.57,0.35,3.72,1.06c-0.4,0.88-0.84,1.73-1.28,2.61c-1.02-0.57-2.12-0.88-3.23-0.88
		c-3.05,0-5.89,2.48-5.97,7.74v13.28h-2.88V31.6c0-3.41-0.35-9.16-0.49-10.18l2.88-0.35c0.09,0.44,0.27,2.52,0.27,4.56
		c0,0.18,0,0.4,0,0.57C187.16,22.4,190.03,20.58,192.95,20.58z"
      />
      <path
        d="M208.49,44.97c-5.66,0-10.36-4.78-10.36-12.17c0-7.79,5.75-12.21,12.57-12.21c1.99,0,3.81,0.31,5.66,0.93v-8.81h2.88v23.32
		c0,2.88,0.31,7.04,0.49,8.1l-2.88,0.4c-0.09-0.49-0.31-3.01-0.27-5.13C214.86,43.24,211.68,44.97,208.49,44.97z M210.83,23.06
		c-5.66,0-9.65,3.41-9.65,9.74c0,6.11,3.5,9.69,7.92,9.69c3.63,0,7.26-2.7,7.26-8.01V23.95C214.46,23.37,212.74,23.06,210.83,23.06z
		"
      />
      <path
        d="M228.9,17.31h-3.05v-4.6h3.05V17.31z M228.81,44.39h-2.88V21.16h2.88V44.39z"
      />
      <path
        d="M241.51,45.01c-2.83,0-5.66-0.8-7.74-2.17l1.24-2.21c1.73,1.15,4.2,1.9,6.64,1.9c3.05,0,5.89-1.28,5.89-4.56
		c0-5.49-13.1-2.12-13.1-10.89c0-4.51,4.2-6.5,8.36-6.5c2.43,0,4.96,0.66,6.68,1.86l-1.24,2.08c-1.46-0.93-3.58-1.51-5.49-1.51
		c-2.88,0-5.44,1.2-5.44,4.07c0,6.11,13.1,2.57,13.1,10.89C250.4,42.98,246.07,45.01,241.51,45.01z"
      />
      <path
        d="M267.67,43.38c-1.64,1.02-3.5,1.59-5.27,1.59c-3.81,0-6.86-2.17-6.86-7.66V23.5h-3.63v-2.08l3.63-0.27l0.13-6.24h2.74v6.24
		h8.01v2.35h-8.01v13.32c0,4.12,2.12,5.66,4.56,5.66c1.28,0,2.7-0.53,3.81-1.28C267.09,41.96,267.4,42.62,267.67,43.38z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "IconLogo",
  props: {
    logoLeft: {
      type: String,
      default: "#2b3990",
    },
    logoRight: {
      type: String,
      default: "#006838",
    },
    fill: {
      type: String,
      default: "#000",
    },
  },
};
</script>

<style scoped>
.st0 {
  fill: url(#SVGID_1_);
}
svg {
  min-width: 10px;
}
</style>
