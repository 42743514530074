<template>
  <div class="grid grid-cols-5 gap-4">
    <div>
      <label class="input-label">
        {{ $t("volumesViewPage.reference") }}
        <NbHelpText
          id="reference-tooltip"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("volumesViewPage.referenceDescription") }}
        </NbHelpText>
      </label>
      <div class="input-view d-flex justify-content-between align-items-center">
        <span>{{ volume.reference || "-" }}</span>
        <NbButton
          v-copy="volume.reference"
          v-b-tooltip.left.hover
          :title="$t('volumesViewPage.copyReference')"
          variant="tertiary"
          v-if="volume.reference"
        >
          <NbIcon icon="copy" />
        </NbButton>
      </div>
    </div>

    <div>
      <label class="input-label">
        {{ $t("volumesViewPage.lastMileTrackingNumber") }}
        <NbHelpText
          id="last-mile-tooltip"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("volumesViewPage.lastMileTrackingNumberDescription") }}
        </NbHelpText>
      </label>
      <div class="input-view d-flex justify-content-between align-items-center">
        <span>{{ volume.last_mile_tracking_number || "-" }}</span>
        <NbButton
          v-if="volume.last_mile_tracking_number"
          v-copy="volume.last_mile_tracking_number"
          v-b-tooltip.left.hover
          :title="$t('volumesViewPage.copyReference')"
          variant="tertiary"
        >
          <NbIcon icon="copy" />
        </NbButton>
      </div>
    </div>

    <div>
      <label class="input-label">
        {{ $t("volumesPage.fields.firstMileTrackingNumber") }}
        <NbHelpText
          id="first-mile-tooltip"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("volumesViewPage.firstMileTrackingNumberDescription") }}
        </NbHelpText>
      </label>
      <div class="input-view d-flex justify-content-between align-items-center">
        <span>{{ volume.first_mile_tracking_number || "-" }}</span>
        <NbButton
          v-if="volume.first_mile_tracking_number"
          v-copy="volume.first_mile_tracking_number"
          v-b-tooltip.left.hover
          :title="$t('volumesViewPage.copyReference')"
          variant="tertiary"
        >
          <NbIcon icon="copy" />
        </NbButton>
      </div>
    </div>

    <div>
      <label class="input-label">
        {{ $t("volumesViewPage.lastMileCarrierNumber") }}
        <NbHelpText
          id="last-mile-carrier-tooltip"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("volumesViewPage.lastMileCarrierNumberDescription") }}
        </NbHelpText>
      </label>
      <div class="input-view d-flex justify-content-between align-items-center">
        <span>{{ volume.last_mile_carrier_number || "-" }}</span>
        <NbButton
          v-if="volume.last_mile_carrier_number"
          v-copy="volume.last_mile_carrier_number"
          v-b-tooltip.left.hover
          :title="$t('volumesViewPage.copyReference')"
          variant="tertiary"
        >
          <NbIcon icon="copy" />
        </NbButton>
      </div>
    </div>

    <div>
      <label class="input-label">
        {{ $t("volumesPage.fields.orderNumber") }}
        <NbHelpText
          id="order-number-tooltip"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("volumesViewPage.orderNumberDescription") }}
        </NbHelpText>
      </label>
      <div class="input-view d-flex justify-content-between align-items-center">
        <span>{{ volume.order_number || "-" }}</span>
        <NbButton
          v-if="volume.order_number"
          v-copy="volume.order_number"
          v-b-tooltip.left.hover
          :title="$t('volumesViewPage.copyReference')"
          variant="tertiary"
        >
          <NbIcon icon="copy" />
        </NbButton>
      </div>
    </div>

    <div>
      <label class="input-label">
        {{ $t("volumesPage.fields.salesChOrderNumber") }}
        <NbHelpText
          id="sales-channel-order-number-tooltip"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("volumesViewPage.salesChannelOrderNumberDescription") }}
        </NbHelpText>
      </label>
      <div class="input-view d-flex justify-content-between align-items-center">
        <span>{{ volume.order.sales_channel_order_number || "-" }}</span>
        <NbButton
          v-if="volume?.order?.sales_channel_order_number"
          v-copy="volume.order.sales_channel_order_number"
          v-b-tooltip.left.hover
          :title="$t('volumesViewPage.copyReference')"
          variant="tertiary"
          placeholder="-"
        >
          <NbIcon icon="copy" />
        </NbButton>
      </div>
    </div>

    <div>
      <label class="input-label">
        {{ $t("volumesPage.fields.orderReference") }}
        <NbHelpText
          id="order-reference-tooltip"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("volumesViewPage.orderReferenceDescription") }}
        </NbHelpText>
      </label>
      <div class="input-view d-flex justify-content-between align-items-center">
        <span>{{ volume.order.reference || "-" }}</span>
        <NbButton
          v-if="volume?.order?.reference"
          v-copy="volume.order.reference"
          v-b-tooltip.left.hover
          :title="$t('volumesViewPage.copyReference')"
          variant="tertiary"
        >
          <NbIcon icon="copy" />
        </NbButton>
      </div>
    </div>

    <div>
      <label class="input-label">
        {{ $t("volumesViewPage.dirNumber") }}
        <NbHelpText
          id="dir-number-tooltip"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("volumesViewPage.dirNumberDescription") }}
        </NbHelpText>
      </label>
      <div class="input-view d-flex justify-content-between align-items-center">
        <span>{{ volume.dir_number || "-" }}</span>
        <NbButton
          v-if="volume.dir_number"
          v-copy="volume.dir_number"
          v-b-tooltip.left.hover
          :title="$t('volumesViewPage.copyReference')"
          variant="tertiary"
        >
          <NbIcon icon="copy" />
        </NbButton>
      </div>
    </div>

    <div>
      <label class="input-label">
        {{ $t("volumesViewPage.internalNBID") }}
        <NbHelpText
          id="internal-nb-id-tooltip"
          class="mx-1"
          size="sm"
          placement="topright"
        >
          {{ $t("volumesViewPage.internalNBIDDescription") }}
        </NbHelpText>
      </label>
      <div class="input-view d-flex justify-content-between align-items-center">
        <span>{{ volume.id }}</span>
        <NbButton
          v-copy="volume.id"
          v-b-tooltip.left.hover
          :title="$t('volumesViewPage.copyReference')"
          variant="tertiary"
        >
          <NbIcon icon="copy" />
        </NbButton>
      </div>
    </div>
  </div>
</template>

<script>
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import { copyClipboard } from "@/directives/copy-clipboard.js";

export default {
  props: {
    volume: {
      type: Object,
      required: true,
    },
  },
  components: {
    NbHelpText,
    NbButton,
    NbIcon,
  },
  directives: {
    copy: copyClipboard,
  },
};
</script>

<style lang="scss" scoped></style>
