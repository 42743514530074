<template>
  <div>
    <div
      class="nb-badge d-flex justify-content-center"
      :class="`nb-badge-${type} ${classes}`"
    >
      <i v-if="icon" class="alert-icon" :class="`${icon} text-${type}`"></i>
      <slot>
        <div :class="textClass">
          {{ text }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "NbBadge",
  props: {
    text: {
      type: [String, Number, Boolean],
      default: "",
    },
    classes: {
      type: String,
      required: false,
      default: "",
    },
    textClass: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style scoped>
.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nb-badge {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;

  text-align: center;
  font: normal normal bold 14px/20px Nunito Sans;
  letter-spacing: 0px;

  color: var(--black);
  background: var(--gray-10);
}
.text-normal .nb-badge {
  font: normal normal normal 12px/18px Nunito Sans;
}
/* .nb-badge:hover > span {
  text-decoration: underline;
} */

.nb-badge.nb-badge-attention {
  color: var(--black);
  background: var(--attention-10);
}
.nb-badge.nb-badge-success {
  color: var(--success);
  background: var(--success-10);
}
.nb-badge.nb-badge-success-dark {
  color: #fff;
  background: var(--success);
  font: normal normal bold 12px/16px var(--font-family-base);
}
.nb-badge.nb-badge-green {
  color: var(--success-10);
  background: var(--success);
}
.nb-badge.nb-badge-warning {
  color: var(--black);
  background: var(--attention-10);
}
.nb-badge.nb-badge-danger {
  color: var(--error);
  background: var(--error-10);
}
.nb-badge.nb-badge-green {
  color: var(--success-10);
  background: var(--success);
}
.nb-badge.nb-badge-gray-50 {
  color: #fff;
  background: var(--gray-50);
}

.nb-badge.nb-badge-error {
  color: var(--error);
  background: var(--error-10);
}
.alert-icon {
  vertical-align: middle;
  height: 1.06rem;
  margin-top: 0.2rem;
  margin-right: 0.68rem;
}
</style>
