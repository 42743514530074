<template>
  <div class="d-inline-block">
    <button
      class="dropdown-toggle button-rows"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ $t("components.maxRows.button") }}:
      <span class="ml-2">{{ val }} <i class="fas fa-angle-down"></i></span>
    </button>
    <div class="dropdown-menu nb-rows">
      <a
        v-for="(rowsOption, index) in rowsOptions"
        :key="index"
        class="dropdown-item button-options"
        href="javascript:void(0)"
        @click="
          val = rowsOption;
          saveItemsPerPage();
        "
      >
        {{ rowsOption }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "NbMaxRows",

  props: {
    perPage: {
      type: Number,
      required: true,
    },
    saveTo: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      val: "",
      itemsPerPage: {},
      rowsOptions: [10, 20, 50, 100],
    };
  },
  watch: {},
  beforeMount() {
    let storage = {};
    if (localStorage.getItem("itemsPerPage")) {
      storage = JSON.parse(localStorage.getItem("itemsPerPage"));
    }
    if (storage[this.saveTo]) {
      this.itemsPerPage[this.saveTo] = storage[this.saveTo];
    } else if (this.perpage) {
      this.itemsPerPage[this.saveTo] = this.perpage;
    } else {
      this.itemsPerPage[this.saveTo] = 20;
    }
    this.val = this.itemsPerPage[this.saveTo];
    //this.emitVal();
  },
  methods: {
    saveItemsPerPage() {
      let storage = {};
      if (localStorage.getItem("itemsPerPage")) {
        storage = JSON.parse(localStorage.getItem("itemsPerPage"));
      }
      storage[this.saveTo] = this.val;
      this.itemsPerPage[this.saveTo] = this.val;
      localStorage.setItem("itemsPerPage", JSON.stringify(storage));
      this.emitVal();
    },
    emitVal() {
      if (this.val <= 0) {
        this.val = 20;
      }
      this.$emit("emitValue", this.val);
    },
  },
};
</script>

<style scoped>
.m-left {
  margin-left: 32px !important;
  margin-top: 8px !important;
}
.button-rows {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  color: var(--black);
  text-align: left;
  font: normal normal normal 14px/20px Nunito Sans;
  letter-spacing: 0px;
}
.dropdown-toggle::after {
  display: none;
}
.nb-rows.dropdown-menu.show {
  background: var(--gray-05);
  box-shadow: 2px 2px 6px var(--gray-10);
  background: #f0f0fa 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  padding: 0px;
  border-radius: 4px !important;

  /* border: 2px solid var(--gray-10); */
  border-top: 1px solid var(--gray-10);
  border-right: 2px solid var(--gray-10);
  border-bottom: 2px solid var(--gray-10);
  border-left: 2px solid var(--gray-10);
}
.button-options {
  text-align: center !important;
}
.button-options:hover {
  background-color: var(--gray-10);
}
</style>
