<template>
  <div>
    <NbPageTitle :title="$t('overpacksPage.title')" />

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" />

      <DatatableRoot
        namespace="overpacks"
        ref="ovepacksTableRef"
        clickable
        :columns="tableColumns"
        :filters="tableFilters"
        @count="totalOverpacks = $event"
        :selected.sync="selected"
        @rowClick="showOverpackDetails($event.id)"
      >
        <template #masterbox_id="{ row }">
          <router-link
            v-if="row.masterbox_id"
            class="link-1"
            :to="`/shipping/masterboxes?id=` + row.masterbox_id"
          >
            {{ row.masterbox_id }}
          </router-link>
          <span v-else>-</span>
        </template>
        <template #sent_at="{ row }">
          <div>
            {{ row.sent_at ? formateDate(row.sent_at) : "NEW" }}
            {{ row.sent_at ? formateTime(row.sent_at) : "" }}
          </div>
        </template>
        <template #is_dispatched="{ row }">
          <IsCheckedIcon :checked="row.is_dispatched" />
        </template>
        <template #created_at="{ row }">
          <div>
            {{ row.created_at ? formateDate(row.created_at) : "ERROR" }}
            {{ row.created_at ? formateTime(row.created_at) : "" }}
          </div>
        </template>
      </DatatableRoot>
    </section>

    <Modal v-model="openUpdateTrackingStatus">
      <UpdateMultipleTrackingStatus
        :overpack-ids="selected"
        @success="loadItems"
      />
    </Modal>

    <Modal v-model="openUpdateFirstMile">
      <UpdateFirstMile :overpack-ids="selected" @success="loadItems" />
    </Modal>

    <Modal v-model="openOverpackData" size="lg" @close="onCloseOverpackData">
      <Overpack :overpack-id="selectedOverpackId" />
    </Modal>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import Modal from "../../../components/modal/Modal.vue";
import UpdateMultipleTrackingStatus from "./components/UpdateMultipleTrackingStatus.vue";
import UpdateFirstMile from "./components/UpdateFirstMile.vue";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";
import Overpack from "./components/Overpack.vue";
import queryParamsMixin from "@/mixins/query-params-mixin.js";

export default {
  name: "Overpacks",
  mixins: [queryParamsMixin],
  components: {
    NbPageTitle,
    DatatableRoot,
    NbTabs,
    Modal,
    UpdateMultipleTrackingStatus,
    UpdateFirstMile,
    Overpack,
    IsCheckedIcon,
  },
  data: () => {
    return {
      currentTab: "all",
      totalOverpacks: 0,
      openUpdateTrackingStatus: false,
      openUpdateFirstMile: false,
      openOverpackData: false,
      allFields: [],
      selected: [],
      selectedOverpackId: null,
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: this.currentTab,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.totalOverpacks,
        },
      ];
    },
    items() {
      return this.$store.state[this.namespace].items;
    },
    tableColumns() {
      return [
        {
          key: "reference",
          label: this.$t("overpacksPage.fields.trackingNumber"),
        },
        {
          key: "masterbox_id",
          label: this.$t("overpacksPage.fields.masterboxID"),
        },
        {
          key: "first_mile_tracking_number",
          label: this.$t("overpacksPage.fields.fMTrackingN"),
        },
        {
          key: "first_mile_carrier_name",
          label: this.$t("overpacksPage.fields.firstMileCompany"),
        },
        {
          key: "is_dispatched",
          label: this.$t("overpacksViewPage.dispatched"),
        },
        {
          key: "created_at",
          label: this.$t("overpacksPage.fields.creationDate"),
          sortable: true,
        },
      ];
    },
    tableOptions() {
      return [
        {
          label: this.$t("overpacksPage.updateTrackingStatus"),
          action: "showModalUpdateTrackingStatus",
        },
        {
          label: this.$t("overpacksPage.updateOverpacks"),
          action: "showModalUpdateOverpacks",
        },
      ];
    },
    tableFilters() {
      return [
        {
          label: "Tracking Number",
          inputs: [
            {
              key: "reference",
            },
          ],
        },
        { label: "Masterbox ID", inputs: [{ key: "masterbox_id" }] },
        {
          label: "FM Tracking Number",
          inputs: [
            {
              key: "first_mile_tracking_number",
            },
          ],
        },
        {
          label: "First Mile Company",
          inputs: [{ key: "first_mile_carrier_name" }],
        },
        {
          label: this.$t("overpacksViewPage.dispatched"),
          inputs: [
            {
              key: "is_dispatched",
              type: "boolean",
            },
          ],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
      ];
    },
    trackingOptions() {
      return [
        {
          code: 617,
          description: this.$t(
            "overpacksPage.trackingOptions.forwardedlocalhub",
          ),
        },
        {
          code: 602,
          description: this.$t(
            "overpacksPage.trackingOptions.arrivedHubSorting",
          ),
        },
        {
          code: 605,
          description: this.$t(
            "overpacksPage.trackingOptions.clearanceCompleted",
          ),
        },
        {
          code: 607,
          description: this.$t(
            "overpacksPage.trackingOptions.shipmentArrivedDestinationCountry",
          ),
        },
        {
          code: 608,
          description: this.$t(
            "overpacksPage.trackingOptions.handedCustomsCustomsClearance",
          ),
        },
        {
          code: 610,
          description: this.$t(
            "overpacksPage.trackingOptions.customsClearanceCompleted",
          ),
        },
        {
          code: 616,
          description: this.$t(
            "overpacksPage.trackingOptions.arrivedHubSortingCenter",
          ),
        },
      ];
    },
  },
  methods: {
    onCloseOverpackData() {
      this.setUrlSearchParam("id");
      this.selectedOverpackId = null;
    },
    optionTook(event) {
      this[event]();
    },
    showOverpackDetails(id) {
      this.setUrlSearchParam("id", id);
      this.selectedOverpackId = id;
      this.openOverpackData = true;
    },
    showModalUpdateTrackingStatus() {
      this.openUpdateTrackingStatus = true;
    },
    showModalUpdateOverpacks() {
      this.openUpdateFirstMile = true;
    },
    loadItems() {
      this.$refs?.ovepacksTableRef?.getData();
    },
    selectedRows(items) {
      this.selected = items;
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date) {
      const data = new Date(date);
      const formatedDate = this.addZero(
        data.getDate().toString() +
          "/" +
          this.addZero(data.getMonth() + 1).toString() +
          "/" +
          data.getFullYear(),
      );
      return formatedDate;
    },
    formateTime(time) {
      const hours = new Date(time);
      const formatedHours =
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedHours;
    },
  },
  mounted() {
    const { id: overpackId } = this.$route.query;
    if (overpackId) {
      this.showOverpackDetails(Number(overpackId));
    }
  },
};
</script>
