var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NbButton',{staticClass:"d-flex gap-2 align-items-center",attrs:{"variant":"quaternary"},on:{"click":_vm.showModal}},[_c('NbIcon',{attrs:{"icon":"list","size":18}}),_vm._v(" "+_vm._s(_vm.$t("components.selectTableColumns.button"))+" ")],1),_c('Modal',{model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c('ModalHeader',[_c('div',[_c('ModalTitle',[_vm._v(" "+_vm._s(_vm.$t("components.nbTable.tableOptions"))+" ")]),_c('p',{staticClass:"body-4"},[_vm._v(" "+_vm._s(_vm.$t("components.nbTable.changeVisibilityReorderColumns"))+" ")])],1)]),_c('div',{staticClass:"grid grid-cols-2 gap-8"},[_c('div',[_c('div',{staticClass:"heading-3 mb-2"},[_vm._v(" "+_vm._s(_vm.$t("components.selectTableColumns.shownColumns"))+" "),_c('NbButton',{attrs:{"variant":"tertiary"},on:{"click":function($event){return _vm.selectAll()}}},[_vm._v(" "+_vm._s(_vm.$t("components.selectTableColumns.selectAll"))+" ")])],1),_c('draggable',{staticClass:"list-group",attrs:{"list":_vm.shownList,"group":"table","options":{ animation: 200 }},on:{"end":_vm.onDragEnd,"start":_vm.onDragStart}},_vm._l((_vm.shownList),function(element){return _c('div',{key:element.key,class:[
              'list-group-item d-flex align-items-center justify-content-between',
              { 'draggable-grabbing': _vm.isDragging },
            ]},[_c('div',[_c('NbIcon',{attrs:{"icon":"more-vertical","size":18}}),_vm._v(" "+_vm._s(element.label)+" ")],1),_c('button',{attrs:{"variant":"quaternary"},on:{"click":function($event){return _vm.moveToHiddenColumn(element)}}},[_c('NbIcon',{attrs:{"icon":"eye","size":18}})],1)])}),0)],1),_c('div',[_c('div',{staticClass:"heading-3 mb-2"},[_vm._v(" "+_vm._s(_vm.$t("components.selectTableColumns.hiddenColumns"))+" "),_c('NbButton',{attrs:{"variant":"tertiary"},on:{"click":function($event){return _vm.clearSelected()}}},[_vm._v(" "+_vm._s(_vm.$t("components.selectTableColumns.unSelectAll"))+" ")])],1),_c('draggable',{staticClass:"list-group",attrs:{"list":_vm.hiddenList,"group":"table","options":{ animation: 200 }},on:{"end":_vm.onDragEnd,"start":_vm.onDragStart}},_vm._l((_vm.hiddenList),function(element){return _c('div',{key:element.key,class:[
              'list-group-item d-flex align-items-center justify-content-between',
              { 'draggable-grabbing': _vm.isDragging },
            ]},[_c('div',[_c('NbIcon',{attrs:{"icon":"more-vertical","size":18}}),_vm._v(" "+_vm._s(element.label)+" ")],1),_c('button',{attrs:{"variant":"quaternary"},on:{"click":function($event){return _vm.moveToShownColumn(element)}}},[_c('NbIcon',{attrs:{"icon":"eye-off","size":18}})],1)])}),0)],1)]),_c('ModalFooter',{staticClass:"d-flex justify-content-end gap-2"},[_c('ModalClose'),_c('NbButton',{on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }