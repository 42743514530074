import PassThrough from "../../components/PassThrough";
/* import NobordistCheckpoints from "../../views/checkpoints/nobordist_checkpoints/NobordistCheckpoints"; */
import NobordistCheckpointsView from "../../views/checkpoints/nobordist_checkpoints/View";
/* import ReceivedCheckpoints from "../../views/checkpoints/received_checkpoints/ReceivedCheckpoints"; */
import ReceivedCheckpointsView from "../../views/checkpoints/received_checkpoints/View";
/* import StatusCheckpoints from "../../views/checkpoints/status_checkpoints/StatusCheckpoints"; */
/* import VolumeCheckpoints from "../../views/checkpoints/volume_checkpoints/VolumeCheckpoints.vue"; */
import VolumeCheckpointsView from "../../views/checkpoints/volume_checkpoints/View.vue";
/* import TrackingNotifications from "../../views/checkpoints/tracking_notifications/TrackingNotifications.vue"; */
import Checkpoints from "@/views/checkpoints/Checkpoints.vue";

/* const checkpoints_nobordist = {
  path: "/checkpoints/nobordist",
  name: "checkpoints_nobordist",
  component: NobordistCheckpoints,
  meta: {
    visible: true,
    label: "NobordistCheckpoints",
    searchData: "nobordistCheckpoints",
  },
}; */

const checkpoints_nobordist_view = {
  path: "/checkpoints/nobordist/:id",
  name: "checkpoints_nobordist_view",
  component: NobordistCheckpointsView,
  meta: {
    visible: false,
    label: "Nobordist Checkpoints view",
  },
};

/* const checkpoints_received_checkpoints = {
  path: "/checkpoints/received_checkpoints",
  name: "received_checkpoints",
  component: ReceivedCheckpoints,
  meta: {
    visible: true,
    label: "ReceivedCheckpoints",
    searchData: "receivedCheckpoints",
  },
}; */

const checkpoints_received_checkpoints_view = {
  path: "/checkpoints/received_checkpoints/:id",
  name: "received_checkpoints_view",
  component: ReceivedCheckpointsView,
  meta: {
    visible: false,
    label: "Received Checkpoints view",
  },
};

/* const checkpoints_status_checkpoints = {
  path: "/checkpoints/status_checkpoints",
  name: "status_checkpoints",
  component: StatusCheckpoints,
  meta: {
    visible: true,
    label: "StatusCheckpoints",
    searchData: "statusCheckpoints",
  },
}; */

/* const checkpoints_volume_checkpoints = {
  path: "/checkpoints/volume_checkpoints",
  name: "volume_checkpoints",
  component: VolumeCheckpoints,
  meta: {
    visible: true,
    label: "VolumeCheckpoints",
    searchData: "volumeCheckpoints",
  },
}; */

const checkpoints_volume_checkpoints_view = {
  path: "/checkpoints/volume_checkpoints/:id",
  name: "volume_checkpoints_view",
  component: VolumeCheckpointsView,
  meta: {
    visible: false,
    label: "Volume Checkpoints view",
  },
};

/* const checkpoints_tracking_notifications = {
  path: "/checkpoints/tracking_notifications",
  name: "tracking_notifications",
  component: TrackingNotifications,
  meta: {
    visible: true,
    label: "TrackingNotifications",
    searchData: "trackingNotifications",
  },
}; */

const checkpoints_list = {
  path: "/checkpoints/checkpoints",
  name: "checkpoints_list",
  component: Checkpoints,
  meta: {
    visible: true,
    label: "Checkpoints",
    searchData: "checkpoints",
  },
};

const checkpoints_children = [
  checkpoints_list,
  /* checkpoints_nobordist, */
  checkpoints_nobordist_view,
  /* checkpoints_received_checkpoints, */
  checkpoints_received_checkpoints_view,
  /* checkpoints_status_checkpoints, */
  /* checkpoints_volume_checkpoints, */
  checkpoints_volume_checkpoints_view,
  /* checkpoints_tracking_notifications, */
];

const checkpoints = {
  path: "/checkpoints",
  name: "checkpoints",
  component: PassThrough,
  meta: {
    visible: true,
    label: "Checkpoints",
    icon: "check-circle",
  },
  children: checkpoints_children,
};

export default checkpoints;
