import api from "./HttpService";

export default class NotificationPermissionService {
  getAll() {
    return api.get("/v1/notifications_permission");
  }

  update(data) {
    return api.put(`/v1/notifications_permission`, data);
  }
}
