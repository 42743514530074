<template>
  <div class="timeline-content"><slot></slot></div>
</template>

<script>
export default {
  props: {
    // sm, lg
    circleSize: {
      type: String,
      default: "lg",
    },
  },
};
</script>

<style lang="scss">
.timeline-content {
  width: var(--timeline-gap);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
</style>
