import Vue from "vue";
import axios from "axios";
import store from "../store";
import { app } from "@/main";
import router from "../router";
import { ToastPlugin } from "bootstrap-vue";

Vue.use(ToastPlugin);

const vm = new Vue();
let showToaster = true;

Vue.prototype.$http = axios;
let request = {};
const api = axios.create({
  baseURL: process.env.VUE_APP_LOGISTICS_API_URL,
});

api.interceptors.request.use((config) => {
  store.commit("config/TURN_ON_LOADING_BAR");
  const user = store.state.user;
  const authToken =
    user && Object.prototype.hasOwnProperty.call(user, "auth_token")
      ? user.auth_token
      : process.env.VUE_APP_DEFAULT_AUTH_TOKEN;
  config.headers.Authorization = `Bearer ${authToken}`;
  request = config;
  return config;
});

api.interceptors.response.use(
  (response) => {
    if (response.data?.status === "ERROR") {
      const error = {
        response: {
          data: {
            messages: response.data.messages,
          },
        },
      };
      toastFailed(error);
      return Promise.reject(response);
    }
    toastSuccess(response);
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response?.status === 401 && showToaster) {
      const platform = store.state.platform;
      store.commit("DELETE_LOGGEDIN_USER");
      router.push(`/${platform}-login`);
      showToaster = false;
      vm.$bvToast.toast("Please login again.", {
        title: "Session Expired",
        solid: true,
        variant: "danger",
        autoHideDelay: 2000,
      });
    } else {
      toastFailed(error);
      return Promise.reject(error);
    }
  },
);

export default api;

function toastSuccess(response) {
  store.commit("config/TURN_OFF_LOADING_BAR");
  const data = response.data;
  if (showToast(response.data)) {
    if (Array.isArray(data.messages)) {
      for (const message of data.messages) {
        toast(message, "success");
      }
      return;
    }

    toast(data.messages, "success");
  }
}

function toastFailed(error) {
  store.commit("config/TURN_ON_LOADING_BAR_ERROR");
  if (error?.response?.data?.messages?.length) {
    const data = error.response.data;
    if (showToast(data)) {
      if (data?.status === "WARNING") {
        toast(data.messages[0], "warning", 7000);
      } else {
        toast(data.messages[0], "danger", 7000);
      }
    }
  } else if (error?.response?.data?.error) {
    const messages = Object.entries(error?.response?.data?.error).map(
      (k) => `${k[0]}: ${k[1].join(",")}`,
    );
    toast(messages, "warning", 7000);
  } else if (error?.response?.headers["content-type"]?.includes("json")) {
    toast(JSON.stringify(error?.response?.data), "warning", 7000);
  } else {
    toast(error.response.statusText, "warning", 7000);
  }
}

function toast(message, type = "info", duration = 2000) {
  app.$toast.show({
    message,
    type,
    duration,
  });
}

function showToast(data) {
  if (!(data.messages && data.messages[0] instanceof Object)) {
    const url = request.url.split("?")[0].split("/")[2];
    if (request.method !== "get" && url !== "downloaded_files") {
      return true;
    }
  }
  return false;

  // return (
  //   request.method !== "get" &&
  //   !["success", "ok"].includes(data.status.toString().toLowerCase()) &&
  //   data.messages
  // );
}
