<template>
  <NbModal id="volume-reverse-modal" width="1024px" @close="closeModal">
    <template #header>
      <NbPageTitle
        :title="`${$t('volumeReverseViewPage.title')}${
          volumeReverse?.id || ''
        }`"
      />
    </template>
    <Spinner v-if="loading" size="md" class="m-auto" />
    <template v-if="volumeReverse?.id" #body>
      <div class="cards grid grid-cols-2 mb-4">
        <NbCard id="actions" :title="$t('actions')">
          <template #body>
            <div class="grid grid-cols-2">
              <NbButton
                variant="secondary"
                @click="
                  navigateTo(
                    `/shipping/orders?id=${volumeReverse.volume.order.id}`,
                  )
                "
              >
                Ir para o pedido
              </NbButton>
              <NbButton
                variant="secondary"
                @click="
                  navigateTo(`/shipping/volumes?id=${volumeReverse.volume.id}`)
                "
              >
                Ir para o volume
              </NbButton>
            </div>
          </template>
        </NbCard>
      </div>

      <form>
        <NbCard id="general-data" title="Dados gerais">
          <template #body>
            <div class="grid grid-cols-2">
              <NbTextInput
                :name="$t('volumeReverseViewPage.reverseNumber')"
                v-model="volumeReverse.reverse_number"
                disabled
                variant="borderless-gray-60"
                id="reverse_number"
              />

              <NbTextInput
                :name="$t('volumeReverseViewPage.name')"
                v-model="volumeReverse.name"
                disabled
                variant="borderless-gray-60"
                id="name"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.address')"
                v-model="volumeReverse.address"
                disabled
                variant="borderless-gray-60"
                id="address"
              />

              <NbTextInput
                :name="$t('volumeReverseViewPage.addressNumber')"
                v-model="volumeReverse.address_number"
                disabled
                variant="borderless-gray-60"
                id="address_number"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.addressComplement')"
                v-model="volumeReverse.address_complement"
                disabled
                variant="borderless-gray-60"
                id="address_complement"
              />

              <NbTextInput
                :name="$t('volumeReverseViewPage.state')"
                v-model="volumeReverse.state"
                disabled
                variant="borderless-gray-60"
                id="state"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.postalCode')"
                v-model="volumeReverse.postal_code"
                disabled
                variant="borderless-gray-60"
                id="postal_code"
              />

              <NbTextInput
                :name="$t('volumeReverseViewPage.country')"
                v-model="volumeReverse.country"
                disabled
                variant="borderless-gray-60"
                id="country"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.city')"
                v-model="volumeReverse.city"
                disabled
                variant="borderless-gray-60"
                id="city"
              />
            </div>

            <div class="d-flex justify-content-end">
              <div>
                <label for="" class="input-label">{{
                  $t("volumeReverseViewPage.createdDate")
                }}</label>
                <small class="d-block">{{
                  volumeReverse.created_at | formatedDate
                }}</small>
              </div>
            </div>
          </template>
        </NbCard>

        <NbCard id="order" title="Dados do pedido">
          <template #body>
            <div class="grid grid-cols-2">
              <NbTextInput
                :name="$t('volumeReverseViewPage.orderID')"
                v-model="volumeReverse.volume.order.id"
                disabled
                variant="borderless-gray-60"
                id="volume.order.id"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.orderNumber')"
                v-model="volumeReverse.volume.order.order_number"
                disabled
                variant="borderless-gray-60"
                id="volume.order.order_number"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.salesChannelOrderNumber')"
                v-model="volumeReverse.volume.order.sales_channel_order_number"
                disabled
                variant="borderless-gray-60"
                id="volume.order.sales_channel_order_number"
              />
              <!-- <NbTextInput
              :name="$t('volumeReverseViewPage.totalValue')"
              v-model="
              disabled
              variant="borderless-gray-60"
              id=""
                volumeReverse.volume.order.currency +
                ' ' volume.order.currency +
                standardizeValue(volumeReverse.volume.order.total_value)
              volume.order.total_value"
            /> -->

              <NbTextInput
                :name="$t('volumeReverseViewPage.trackingNumber')"
                v-model="volumeReverse.volume.order.reference"
                disabled
                variant="borderless-gray-60"
                id="volume.order.reference"
              />
            </div>
          </template>
        </NbCard>

        <NbCard id="volume" title="Volume">
          <template #body>
            <div class="grid grid-cols-2">
              <NbTextInput
                :name="$t('volumeReverseViewPage.volumeID')"
                v-model="volumeReverse.volume.id"
                disabled
                variant="borderless-gray-60"
                id="volume.id"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.height')"
                v-model="volumeReverse.volume.height"
                disabled
                variant="borderless-gray-60"
                id="volume.height"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.width')"
                v-model="volumeReverse.volume.width"
                disabled
                variant="borderless-gray-60"
                id="volume.width"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.length')"
                v-model="volumeReverse.volume.length"
                disabled
                variant="borderless-gray-60"
                id="volume.length"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.weight')"
                v-model="volumeReverse.volume.weight"
                disabled
                variant="borderless-gray-60"
                id="volume.weight"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.lastMileTrackingNumber')"
                v-model="volumeReverse.volume.last_mile_tracking_number"
                disabled
                variant="borderless-gray-60"
                id="volume.last_mile_tracking_number"
              />
            </div>
          </template>
        </NbCard>

        <NbCard id="customer" title="Customer">
          <template #body>
            <div class="grid grid-cols-2">
              <NbTextInput
                :name="$t('volumeReverseViewPage.name')"
                v-model="volumeReverse.volume.order.customer_full_name"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_full_name"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.documentType')"
                v-model="volumeReverse.volume.order.customer_document_type"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_document_type"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.documentNumber')"
                v-model="volumeReverse.volume.order.customer_document_number"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_document_number"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.address')"
                v-model="volumeReverse.volume.order.customer_address"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_address"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.addressNumber')"
                v-model="volumeReverse.volume.order.customer_address_number"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_address_number"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.addressComplement')"
                v-model="volumeReverse.volume.order.customer_address_complement"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_address_complement"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.addressReference')"
                v-model="volumeReverse.volume.order.customer_address_reference"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_address_reference"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.postalCode')"
                v-model="volumeReverse.volume.order.customer_postal_code"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_postal_code"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.addressQuarter')"
                v-model="volumeReverse.volume.order.customer_address_quarter"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_address_quarter"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.city')"
                v-model="volumeReverse.volume.order.customer_city"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_city"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.state')"
                v-model="volumeReverse.volume.order.customer_state"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_state"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.country')"
                v-model="volumeReverse.volume.order.customer_country"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_country"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.phone')"
                v-model="volumeReverse.volume.order.customer_phone"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_phone"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.email')"
                v-model="volumeReverse.volume.order.customer_email"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_email"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.mailbox')"
                v-model="volumeReverse.volume.order.customer_mailbox"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_mailbox"
              />
              <NbTextInput
                :name="$t('volumeReverseViewPage.phoneCountryCode')"
                v-model="volumeReverse.volume.order.customer_phone_country_code"
                disabled
                variant="borderless-gray-60"
                id="volume.order.customer_phone_country_code"
              />
            </div>
          </template>
        </NbCard>

        <NbCard
          :title="`${$t('volumeReverseViewPage.items')} (${
            volumeReverse.volume?.order_items?.length || 0
          })`"
          id="items"
        >
          <template #body>
            <NbTablev2
              class="mt-5"
              tableOf="itemsVolumeReversePage"
              :hasParentItens="true"
              ref="itemsVolumeReversePage"
              :hiddeTableOptions="true"
              height="fit-content"
              :allFields="itemsAllFields"
              :fields="itemsFields"
              @reloadFields="itemsFields = $event"
              :startItems="volumeReverse.volume.order_items || []"
            />
          </template>
        </NbCard>
      </form>
    </template>
    <template #footer>
      <NbButton class="d-flex ml-auto" data-dismiss="modal" aria-label="close">
        {{ $t("close") }}
      </NbButton>
    </template>
  </NbModal>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import VolumeReverseService from "@/services/VolumeReverseService";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import moment from "moment";
import Spinner from "@/components/Spinner.vue";

const volumeReverseService = new VolumeReverseService();

export default {
  components: {
    NbModal,
    NbButton,
    NbPageTitle,
    NbCard,
    NbTablev2,
    NbTextInput,
    Spinner,
  },
  filters: {
    formatedDate(value) {
      return value && moment(value).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  data() {
    return {
      loading: false,
      itemsFields: [],
      volumeReverse: {},
    };
  },
  computed: {
    itemsAllFields() {
      return [
        { key: "id", label: "ID" },
        {
          key: "name",
          label: this.$t("volumeReverseViewPage.fields.name"),
        },
        {
          key: "value",
          label: this.$t("volumeReverseViewPage.fields.value"),
        },
        {
          key: "hs_code",
          label: this.$t("volumeReverseViewPage.fields.hsCode"),
        },
        {
          key: "quantity",
          label: this.$t("volumeReverseViewPage.fields.Quantity"),
        },
        {
          key: "origin_country",
          label: this.$t("volumeReverseViewPage.fields.originCountry"),
        },
      ];
    },
  },
  methods: {
    navigateTo(path) {
      this.closeModal();
      this.$router.push(path);
    },
    closeModal() {
      this.$helpers.closeModal("volume-reverse-modal");
      this.$emit("close");
    },
    async loadVolumeReverse() {
      try {
        this.loading = true;
        const response = await volumeReverseService.getVolumeReverse(
          this.$route.query?.id,
        );
        this.volumeReverse = response.data.data;
      } catch (error) {
        this.$bvToast.toast(this.$t("errorMessages.genericError"), {
          variant: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    showModal() {
      if (this.volumeReverse != this.$route.query?.id) {
        this.loadVolumeReverse();
      }
      this.$helpers.openModal("volume-reverse-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  gap: 1rem;
  &.grid-cols-2 {
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

form {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
}
</style>
